import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Web3Service } from './web3.service';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import {
  PriceService,
  OpportunitiesHolder,
  Dexchanges,
  CrowdToken,
  Conversion,
  MainNetworksById,
  MulticallService,
  YOUR_INVEST,
  TokensHolder
} from '@crowdswap/constant';
import { OpportunityType } from '../views/pages/opportunity/model/opportunity-state.enum';
import { UtilsService } from '../services';
import { NgxMaskService } from 'ngx-mask';
import { BehaviorSubject } from 'rxjs';
import { BigNumber } from 'ethers';
import { TimeService } from './time.service';
import { IETFOpportunityItem } from '../views/pages/etf-opportunity/model/etf.model';

const baseUrl = environment.Opportunity_BASEURL || '';
const ELIGIBLE_BEFORE_START_TIME = 1;

enum OPPORTUNITY_FETCH_TYPE {
  EXISIST,
  USER_DATA
}

@Injectable()
export class OpportunitiesService {
  public currentTime;
  private investmentList;
  private loaded: boolean = false;
  public _investmentInfoSubject: BehaviorSubject<{
    loading: boolean;
    totalInvestment: number;
    opportunities: any[];
  }>;
  constructor(
    private http: HttpClient,
    private priceService: PriceService,
    private utilsService: UtilsService,
    private maskService: NgxMaskService,
    private web3Service: Web3Service,
    private multicallService: MulticallService,
    protected timeService: TimeService
  ) {
    this._investmentInfoSubject = new BehaviorSubject<{
      loading: boolean;
      totalInvestment: number;
      opportunities: any[];
    }>({
      loading: true,
      totalInvestment: NaN,
      opportunities: []
    });
  }

  public async getOpportunities(): Promise<void> {
    const url = `${baseUrl}/api/v1/opportunity/getOpportunities`;
    const data = <any[]>await this.http.get(url).toPromise();

    const etfUrl = `${baseUrl}/api/v1/etf/plans`;
    let etfData: IETFOpportunityItem[] = <any[]>(
      await this.http.get(etfUrl).toPromise()
    );

    etfData = etfData.filter((plan) => plan.active); // todo deactive for test

    etfData.map((plan) => {
      plan.totalTVLToDisplay = formatNumber(
        plan.totalTVL,
        NumberType.FiatTokenPrice
      );

      return plan;
    });

    const etfMetaData = [{ data: etfData, name: 'etf' }];

    const that = this;
    let mappedOpportunities = Object.keys(data).map(function (index) {
      data[index]['isUserEligible'] = false;
      data[index]['stopOppLoading'] = false;
      data[index]['stopLoading'] = false;
      data[index]['eligibleStartTime'] =
        data[index]['startTime'] - ELIGIBLE_BEFORE_START_TIME;
      data[index]['dexchange'] = Dexchanges[data[index].dexchange];
      data[index]['tokenA'] = that.getToken(data[index].tokenA);
      data[index]['tokenB'] = that.getToken(data[index].tokenB);
      data[index]['wToken'] = that.getToken(data[index].wToken);
      data[index]['middleToken'] = that.getToken(data[index].middleToken);
      data[index]['rewardToken'] = that.getToken(data[index].rewardToken);
      const tvl = parseFloat(data[index].tvl);
      const apy = parseFloat(data[index].apy);
      const dailyInterest = parseFloat(data[index].dailyInterest);
      const apr = parseFloat(data[index].apr);
      data[index]['TVL'] = tvl;
      data[index]['displayTvl'] = formatNumber(
        !tvl ? 0 : tvl,
        NumberType.NFTTokenFloorPriceTrailingZeros
      );
      data[index]['APY'] = apy;
      data[index]['displayApy'] = formatNumber(
        !apy ? 0 : apy,
        NumberType.TokenNonTx
      );
      data[index]['displayDailyInterest'] = Conversion.adjustFraction(
        !dailyInterest ? 0 : dailyInterest,
        4
      );
      data[index]['Daily Interest'] = dailyInterest;
      data[index]['Network'] =
        that.web3Service.networkSpec[data[index].chainId].title;
      data[index]['apr'] = Conversion.adjustFraction(apr, 2);
      data[index]['duration'] = data[index].duration
        ? Conversion.replaceSeparator(
            Conversion.adjustFraction(data[index].duration)
          ).replace('.00', '')
        : '0';
      data[index]['endDateUI'] = data[index].endDate
        ? new Date(data[index].endDate).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
        : '-';
      data[index]['remainingRewardsUI'] = data[index].remainingRewards
        ? formatNumber(
            data[index].remainingRewards,
            NumberType.NFTTokenFloorPriceTrailingZeros
          ).replace('.00', '')
        : '0';
      data[index]['totalRewardUI'] = data[index].totalReward
        ? formatNumber(
            data[index].totalReward,
            NumberType.NFTTokenFloorPriceTrailingZeros
          ).replace('.00', '')
        : '0';
      data[index]['remainingPercent'] =
        data[index].remainingRewards && data[index].totalReward
          ? Conversion.adjustFraction(
              (data[index].remainingRewards * 100) / data[index].totalReward,
              2
            )
          : 0;
      data[index]['reserveAUI'] = formatNumber(
        data[index].reserveA === '-' ? 0 : data[index].reserveA,
        NumberType.NFTTokenFloorPriceTrailingZeros
      );
      data[index]['reserveBUI'] = formatNumber(
        data[index].reserveB === '-' ? 0 : data[index].reserveB,
        NumberType.NFTTokenFloorPriceTrailingZeros
      );
      data[index]['stopOppLoading'] = 'true';
      if (
        data[index]['opportunityType'] === OpportunityType.TypeStaking &&
        data[index]['version'] === 'V2' &&
        data[index].plans
      ) {
        data[index]['lockedStaking'] = true;
        data[index]['plans'] = data[index].plans;
        for (let plan of data[index].plans) {
          plan.duration = parseFloat(
            Conversion.adjustFraction(parseFloat(plan.duration) / 86400)
          );
          plan.id = parseFloat(plan.id);
          plan.apy = parseFloat(plan.apy);
        }
        data[index].apr = 1;
      }
      return data[index];
    });

    mappedOpportunities = [...mappedOpportunities, ...etfMetaData];

    OpportunitiesHolder.FillAllOpportunities(mappedOpportunities);
    this.loaded = true;
  }

  public async ensureOpportunitiesLoaded() {
    if (
      !this.loaded ||
      Object.keys(OpportunitiesHolder.Opportunities).length < 4
    ) {
      await this.getOpportunities();
    }
  }

  public async getInvestmentList(opportunities: any, reload: boolean = false) {
    if (!this.currentTime) {
      this.currentTime = this.timeService.getCurrentTime();
    }
    if (this.investmentList === undefined || reload) {
      this.investmentList = await this.getInvestments(opportunities);
    }
    return this.investmentList;
  }

  public prepareMulticallData(opportunities, type) {
    const data: any[] = [];
    for (let i = 0; i < opportunities.length; i++) {
      switch (opportunities[i].opportunityType) {
        case OpportunityType.TypeCrowd:
          data.push(
            ...this.crowdOpportunityMulticallBuilder(opportunities[i], type)
          );
          break;
        case OpportunityType.TypeStaking:
          if (opportunities[i].lockedStaking) {
            data.push(
              ...this.lockedStakingMulticallBuilder(opportunities[i], type)
            );
          } else {
            data.push(...this.stakeMulticallBuilder(opportunities[i], type));
          }
          break;
        case OpportunityType.TypeBeefy:
          data.push(...this.beefyMulticallBuilder(opportunities[i], type));
          break;
        case OpportunityType.TypePancake:
          data.push(...this.pancakeMulticallBuilder(opportunities[i], type));
          break;
      }
    }
    return data;
  }

  private async getInvestments(opportunities: any) {
    if (!this.web3Service.isConnected()) {
      return;
    }
    let data = this.prepareMulticallData(
      opportunities,
      OPPORTUNITY_FETCH_TYPE.EXISIST
    );

    let results = await this.multicallOnDifferentNetworks(data);
    const investedOpportunities = this.filterInvestedOpportunities(
      opportunities,
      results,
      data
    );

    data = this.prepareMulticallData(
      investedOpportunities,
      OPPORTUNITY_FETCH_TYPE.USER_DATA
    );
    results = await this.multicallOnDifferentNetworks(data);

    let totalInvestment = 0;
    for (let i = 0; i < investedOpportunities.length; i++) {
      const opportunity = investedOpportunities[i];
      try {
        if (
          investedOpportunities[i].opportunityType === OpportunityType.TypeCrowd
        ) {
          await this.fillCrowdOpportunityInvestData(results, opportunity, data);
        } else if (
          investedOpportunities[i].opportunityType ===
          OpportunityType.TypeStaking
        ) {
          if (investedOpportunities[i].lockedStaking) {
            await this.fillLockedStakingRewardInvestData(
              results,
              opportunity,
              data
            );
          } else {
            await this.fillStakingRewardInvestData(results, opportunity, data);
          }
        } else if (
          investedOpportunities[i].opportunityType ===
          OpportunityType.TypePancake
        ) {
          await this.fillPancakeOpportunityInvestData(
            results,
            opportunity,
            data
          );
        } else if (
          investedOpportunities[i].opportunityType === OpportunityType.TypeBeefy
        ) {
          await this.fillBeefyOpportunityInvestData(results, opportunity, data);
        }
        if (
          !isNaN(parseFloat(investedOpportunities[i]['stakedBalanceInUSDT']))
        ) {
          totalInvestment += parseFloat(
            investedOpportunities[i]['stakedBalanceInUSDT']
          );
        }
        investedOpportunities[i]['time'] = this.getStartTime(
          investedOpportunities[i]
        );
        investedOpportunities[i]['eligibleTime'] = this.getTime(
          parseFloat(investedOpportunities[i].startTime) -
            ELIGIBLE_BEFORE_START_TIME
        );
      } catch (error) {
        console.error(`Getting received tokens problem by error: ${error}`);
      }
    }

    this._investmentInfoSubject.next({
      loading: false,
      totalInvestment: totalInvestment,
      opportunities: investedOpportunities
    });
    return investedOpportunities;
  }

  private async fillCrowdOpportunityInvestData(results, opportunity, data) {
    const index = this.getNetworkIndex(opportunity.chainId);
    const stakeBalanceIndex = this.getBalanceIndex(opportunity, data);
    const [stakeBalance] = results[index][stakeBalanceIndex];
    opportunity['stakedBalance'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        stakeBalance,
        opportunity.LPToken.decimals
      ),
      6
    );
    const tokenPrice = Conversion.convertStringToDecimal(
      await this.priceService.getPrice(
        opportunity.LPToken,
        this.web3Service.getNetworkProvider(opportunity.chainId)
      ),
      opportunity.LPToken.decimals
    );
    opportunity['stakedBalanceInUSDT'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        tokenPrice.mul(stakeBalance).toString(),
        opportunity.LPToken.decimals * 2
      )
    );
    const rewardIndex = this.getDetailIndex(data, opportunity);
    opportunity['reward'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        results[index][rewardIndex],
        opportunity.rewardToken.decimals
      ),
      6
    );

    const [totalSupply] = this.getTotalSupply(
      results,
      index,
      data,
      opportunity
    );

    const reservesIndex = this.getReserves(data, opportunity);
    let [reserve0, reserve1] = results[index][reservesIndex];
    [reserve0, reserve1] = this.sortReserves(
      reserve0,
      reserve1,
      opportunity.tokenA,
      opportunity.tokenB
    );

    opportunity['receivedTokenB'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        stakeBalance.mul(reserve0).div(totalSupply),
        opportunity.tokenB.decimals
      ),
      4
    );

    opportunity['receivedTokenA'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        stakeBalance.mul(reserve1).div(totalSupply),
        opportunity.tokenA.decimals
      ),
      4
    );
    opportunity['yourShare'] = opportunity.stakedBalanceInUSDT
      ? formatNumber(
          (parseFloat(opportunity.stakedBalanceInUSDT) /
            parseFloat(opportunity.tvl)) *
            100
        )
      : null;
    opportunity['stopLoading'] = 'true';
  }

  private async fillStakingRewardInvestData(results, opportunity, data) {
    const index = this.getNetworkIndex(opportunity.chainId);
    const stakeBalanceIndex = this.getBalanceIndex(opportunity, data);
    const [stakeBalance] = results[index][stakeBalanceIndex];
    opportunity['stakedBalance'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        stakeBalance,
        opportunity.tokenA.decimals
      ),
      6
    );
    const tokenPrice = Conversion.convertStringToDecimal(
      await this.priceService.getPrice(
        opportunity.tokenA,
        this.web3Service.getNetworkProvider(opportunity.chainId)
      ),
      opportunity.tokenA.decimals
    );
    opportunity['stakedBalanceInUSDT'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        tokenPrice.mul(stakeBalance).toString(),
        opportunity.tokenA.decimals * 2
      )
    );
    const rewardIndex = this.getDetailIndex(data, opportunity);
    opportunity['reward'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        results[index][rewardIndex],
        opportunity.rewardToken.decimals
      ),
      6
    );
    opportunity['yourShare'] = opportunity.stakedBalanceInUSDT
      ? formatNumber(
          (parseFloat(opportunity.stakedBalanceInUSDT) /
            parseFloat(opportunity.tvl)) *
            100
        )
      : null;
    const getStakeTimeIndex = this.getMulticallResultIndex(
      data,
      opportunity.chainId,
      opportunity.name,
      'getStakeTime'
    );
    const [getStakeTime] = results[index][getStakeTimeIndex];
    opportunity['stakeTime'] = getStakeTime;

    const rewardPerHourIndex = this.getMulticallResultIndex(
      data,
      opportunity.chainId,
      opportunity.name,
      'rewardPerHour'
    );
    const [rewardPerHour] = results[index][rewardPerHourIndex];

    let convertedStakedBalance = parseFloat(
      Conversion.convertStringFromDecimal(stakeBalance.toString())
    );
    opportunity['nextReward'] = Conversion.adjustFraction(
      +Conversion.convertStringFromDecimal(rewardPerHour.toString()) *
        convertedStakedBalance,
      6
    );

    opportunity['stopLoading'] = 'true';
  }

  private async fillLockedStakingRewardInvestData(results, opportunity, data) {
    const index = this.getNetworkIndex(opportunity.chainId);
    const rewardIndex = this.getDetailIndex(data, opportunity);
    const stakedList = results[index][rewardIndex];
    let lockedList: any[] = [];
    stakedList.map((records) => {
      for (let record of records) {
        if (!record.archived) {
          for (let plan of opportunity.plans) {
            if (plan.id === parseFloat(record.planId.toString())) {
              try {
                const stakeItem = {
                  stakeId: record.id.toString(),
                  planId: record.planId.toString(),
                  amount: Conversion.adjustFraction(
                    Conversion.convertStringFromDecimal(record.amount, 18),
                    6
                  ),
                  duration: plan.duration,
                  endDate: record.endTime.toString(),
                  displayEndDate: record.endTime
                    ? new Date(
                        parseFloat(record.endTime.toString()) * 1000
                      ).toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      })
                    : '-',
                  rewards: Conversion.adjustFraction(
                    Conversion.convertStringFromDecimal(record.reward, 18),
                    6
                  )
                };
                lockedList.push(stakeItem);
              } catch (e) {
                console.log('Getting locked staking records have an error:', e);
              }
            }
          }
        }
      }
    });
    opportunity['lockedList'] = lockedList.sort((a, b) => {
      return parseFloat(a.endDate) - parseFloat(b.endDate);
    });
    opportunity['totalRewards'] = 0;
    for (let i = 0; i < opportunity.lockedList.length; i++) {
      opportunity.totalRewards += parseFloat(opportunity.lockedList[i].rewards);
    }
    opportunity.totalRewards = Conversion.adjustFraction(
      opportunity.totalRewards,
      6
    );
    try {
      const totalBalanceIndex = this.getTotalBalanceIndex(opportunity, data);
      const [totalBalance] = results[index][totalBalanceIndex];
      opportunity.reserveA = Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(
          totalBalance,
          opportunity.tokenA.decimals
        ),
        6
      );
      opportunity['reserveAUI'] = formatNumber(
        opportunity.reserveA === '-' ? 0 : opportunity.reserveA,
        NumberType.NFTTokenFloorPriceTrailingZeros
      );
      const stakeBalanceIndex = this.getBalanceIndex(opportunity, data);
      const [stakeBalance] = results[index][stakeBalanceIndex];
      opportunity['stakedBalance'] = Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(
          stakeBalance,
          opportunity.tokenA.decimals
        ),
        6
      );
      opportunity['yourShare'] = opportunity.stakedBalance
        ? formatNumber(
            (parseFloat(opportunity.stakedBalance) /
              parseFloat(opportunity.reserveA)) *
              100
          )
        : null;

      const tokenPrice = Conversion.convertStringToDecimal(
        await this.priceService.getPrice(
          opportunity.tokenA,
          this.web3Service.getNetworkProvider(opportunity.chainId)
        ),
        opportunity.tokenA.decimals
      );
      opportunity['stakedBalanceInUSDT'] = Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(
          tokenPrice.mul(stakeBalance).toString(),
          opportunity.tokenA.decimals * 2
        )
      );
    } catch (e) {
      console.log(
        'Getting locked staking fields have an error:',
        opportunity.tokenA.price,
        e
      );
    } finally {
      opportunity['stopLoading'] = 'true';
    }
  }

  private async fillPancakeOpportunityInvestData(results, opportunity, data) {
    const index = this.getNetworkIndex(opportunity.chainId);
    const stakeBalanceIndex = this.getBalanceIndex(opportunity, data);
    const [stakeBalance] = results[index][stakeBalanceIndex];
    opportunity['stakedBalance'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        stakeBalance,
        opportunity.LPToken.decimals
      ),
      6
    );
    opportunity['stakedBalanceInUSDT'] = Conversion.adjustFraction(
      parseFloat(opportunity['stakedBalance']) *
        parseFloat(
          await this.priceService.getPrice(
            opportunity.LPToken,
            this.web3Service.getNetworkProvider(opportunity.chainId)
          )
        )
    );
    const rewardIndex = this.getDetailIndex(data, opportunity);
    opportunity['reward'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        results[index][rewardIndex][1],
        opportunity.rewardToken.decimals
      ),
      6
    );

    const [totalSupply] = this.getTotalSupply(
      results,
      index,
      data,
      opportunity
    );

    const reservesIndex = this.getReserves(data, opportunity);
    let [reserve0, reserve1] = results[index][reservesIndex];
    [reserve0, reserve1] = this.sortReserves(
      reserve0,
      reserve1,
      opportunity.tokenA,
      opportunity.tokenB
    );

    opportunity['receivedTokenB'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        stakeBalance.mul(reserve0).div(totalSupply),
        opportunity.tokenB.decimals
      ),
      4
    );

    opportunity['receivedTokenA'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        stakeBalance.mul(reserve1).div(totalSupply),
        opportunity.tokenA.decimals
      ),
      4
    );
    opportunity['yourShare'] = opportunity.stakedBalanceInUSDT
      ? formatNumber(
          (parseFloat(opportunity.stakedBalanceInUSDT) /
            parseFloat(opportunity.tvl)) *
            100
        )
      : null;
    opportunity['stopLoading'] = 'true';
  }

  private async fillBeefyOpportunityInvestData(results, opportunity, data) {
    const index = this.getNetworkIndex(opportunity.chainId);
    const stakeBalanceIndex = this.getBalanceIndex(opportunity, data);
    const [mooBalance] = results[index][stakeBalanceIndex];
    const [totalSupply] = this.getTotalSupply(
      results,
      index,
      data,
      opportunity
    );

    const balanceIndex = this.getMulticallResultIndex(
      data,
      +opportunity.chainId,
      opportunity.name,
      'balance'
    );
    const [balance] = results[index][balanceIndex];

    const stakedBalance = mooBalance.mul(balance).div(totalSupply);

    opportunity['stakedBalance'] = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        stakedBalance,
        opportunity.LPToken.decimals
      ),
      6
    );
    opportunity['stakedBalanceInUSDT'] = Conversion.adjustFraction(
      parseFloat(opportunity['stakedBalance']) *
        parseFloat(
          await this.priceService.getPrice(
            opportunity.LPToken,
            this.web3Service.getNetworkProvider(opportunity.chainId)
          )
        )
    );
    opportunity['yourShare'] = opportunity.stakedBalanceInUSDT
      ? formatNumber(
          (parseFloat(opportunity.stakedBalanceInUSDT) /
            parseFloat(opportunity.tvl)) *
            100
        )
      : null;
    opportunity['stopLoading'] = 'true';
  }

  private sortReserves(reserve0, reserve1, tokenA, tokenB) {
    const tokenAAddress = TokensHolder.getWrappedToken(tokenA).address;
    const tokenBAddress = TokensHolder.getWrappedToken(tokenB).address;
    if (BigNumber.from(tokenAAddress).lt(tokenBAddress)) {
      return [reserve1, reserve0];
    } else {
      return [reserve0, reserve1];
    }
  }

  private getReserves(data: any[], opportunity) {
    if (
      opportunity.opportunityType === OpportunityType.TypeStaking ||
      opportunity.opportunityType === OpportunityType.TypeBeefy
    ) {
      return -1;
    }
    return this.getMulticallResultIndex(
      data,
      +opportunity.chainId,
      opportunity.name,
      'getReserves' // for Crwod and Pancake
    );
  }

  private getTotalSupply(results: any, index, data: any[], opportunity) {
    const resultIndex = this.getMulticallResultIndex(
      data,
      +opportunity.chainId,
      opportunity.name,
      'totalSupply'
    );
    return results[index][resultIndex];
  }

  private getDetailIndex(data: any[], opportunity) {
    let methodName;
    if (opportunity.opportunityType === OpportunityType.TypeCrowd) {
      methodName = 'earned';
    } else if (opportunity.opportunityType === OpportunityType.TypePancake) {
      methodName = 'getUserInfo';
    } else if (opportunity.opportunityType === OpportunityType.TypeStaking) {
      methodName = opportunity.lockedStaking
        ? 'getUserStakingRecords'
        : 'getReward';
    } else {
      return -1; // for Pancake and Beefy
    }
    const rewardIndex = this.getMulticallResultIndex(
      data,
      +opportunity.chainId,
      opportunity.name,
      methodName
    );
    return rewardIndex;
  }

  private getBalanceIndex(opportunity: any, data: any[]) {
    let methodName = 'balanceOf'; // for Crowd and Beefy
    if (opportunity.opportunityType === OpportunityType.TypePancake) {
      methodName = 'getUserInfo';
    } else if (opportunity.opportunityType === OpportunityType.TypeStaking) {
      methodName = opportunity.lockedStaking
        ? 'getUserTotalStakedAmount'
        : 'getBalance';
    }
    return this.getMulticallResultIndex(
      data,
      +opportunity.chainId,
      opportunity.name,
      methodName
    );
  }

  private getTotalBalanceIndex(opportunity: any, data: any[]) {
    return this.getMulticallResultIndex(
      data,
      +opportunity.chainId,
      opportunity.name,
      'balanceOf'
    );
  }

  async multicallOnDifferentNetworks(data) {
    const promises: any[] = [];
    for (const network of Object.keys(MainNetworksById).filter((chainId) =>
      environment.ACTIVE_NETWORK.includes(chainId)
    )) {
      promises.push(
        this.multicallService.call(
          this.web3Service.getNetworkProvider(+network),
          YOUR_INVEST,
          data.filter((item) => {
            return item.chainId === +network;
          })
        )
      );
    }

    let results;
    await Promise.all(promises).then((items) => {
      results = items;
    });
    return results;
  }

  crowdOpportunityMulticallBuilder(opportunity, type) {
    const data: any[] = [];
    if (type === OPPORTUNITY_FETCH_TYPE.EXISIST) {
      const lpStakeholders = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.stakingLPContractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'lpStakeholders'
      };
      data.push(lpStakeholders);
      return data;
    } else {
      const objectLPBalance = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.stakingLPContractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'balanceOf'
      };
      data.push(objectLPBalance);

      const objectReward = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.stakingLPContractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'earned'
      };
      data.push(objectReward);

      const objectReserves = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.poolAddress,
        params: [],
        method: 'getReserves'
      };
      data.push(objectReserves);

      const objectEligibel = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.stakingLPContractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'eligibleUsers'
      };
      data.push(objectEligibel);

      const objectTotalSupply = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.poolAddress,
        params: [],
        method: 'totalSupply'
      };
      data.push(objectTotalSupply);
      return data;
    }
  }

  stakeMulticallBuilder(opportunity, type) {
    const data: any[] = [];
    if (type === OPPORTUNITY_FETCH_TYPE.EXISIST) {
      const stakeholders = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'stakeholders'
      };
      data.push(stakeholders);
      return data;
    } else {
      const objectStakedBalance = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'getBalance'
      };
      data.push(objectStakedBalance);

      const objectReward = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'getReward'
      };
      data.push(objectReward);

      const objectStakeTime = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'getStakeTime'
      };
      data.push(objectStakeTime);

      const objectRewardPerHour = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [],
        method: 'rewardPerHour'
      };
      data.push(objectRewardPerHour);

      return data;
    }
  }

  lockedStakingMulticallBuilder(opportunity, type) {
    const data: any[] = [];
    if (type === OPPORTUNITY_FETCH_TYPE.EXISIST) {
      const stakeholders = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'hasStaked'
      };
      data.push(stakeholders);
      return data;
    } else {
      const objectStakedBalance = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'getUserTotalStakedAmount'
      };
      data.push(objectStakedBalance);

      const objectUserStakingRecords = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'getUserStakingRecords'
      };
      data.push(objectUserStakingRecords);

      const objectTotalBalance = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.tokenA.address,
        params: [opportunity.contractAddress],
        method: 'balanceOf'
      };
      data.push(objectTotalBalance);

      return data;
    }
  }

  pancakeMulticallBuilder(opportunity, type) {
    const data: any[] = [];
    if (type === OPPORTUNITY_FETCH_TYPE.EXISIST) {
      const userInfo = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'userInfo'
      };
      data.push(userInfo);
      return data;
    } else {
      const objectLPBalance = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.contractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'getUserInfo'
      };
      data.push(objectLPBalance);

      const objectReserves = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.poolAddress,
        params: [],
        method: 'getReserves'
      };
      data.push(objectReserves);

      const objectTotalSupply = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.poolAddress,
        params: [],
        method: 'totalSupply'
      };
      data.push(objectTotalSupply);

      return data;
    }
  }

  beefyMulticallBuilder(opportunity, type) {
    const data: any[] = [];
    if (type === OPPORTUNITY_FETCH_TYPE.EXISIST) {
      const objectLPBalance = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.stakingLPContractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'balanceOf'
      };
      data.push(objectLPBalance);
    } else {
      const objectLPBalance = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.stakingLPContractAddress,
        params: [this.web3Service.getWalletAddress()],
        method: 'balanceOf'
      };
      data.push(objectLPBalance);

      const objectTotalSupply = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.stakingLPContractAddress,
        params: [],
        method: 'totalSupply'
      };
      data.push(objectTotalSupply);

      const objectBalance = {
        oppName: opportunity.name,
        chainId: opportunity.chainId,
        target: opportunity.stakingLPContractAddress,
        params: [],
        method: 'balance'
      };
      data.push(objectBalance);
    }
    return data;
  }

  filterInvestedOpportunities(opportunities, results, data) {
    const filteredData = opportunities.filter((opportunity) => {
      try {
        const index = this.getMulticallResultIndex(
          data,
          opportunity.chainId,
          opportunity.name,
          this.getOpportunityExistMethodByType(opportunity)
        );
        const networkIndex = this.getNetworkIndex(opportunity.chainId);
        if (index !== -1 && networkIndex !== -1) {
          if (opportunity.lockedStaking && results[networkIndex][index][0]) {
            return opportunity;
          }
          if (results[networkIndex][index]['exist']) {
            return opportunity;
          }
          if (opportunity.opportunityType === OpportunityType.TypeBeefy) {
            if (results[networkIndex][index][0].gt(0)) {
              return opportunity;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
    return filteredData;
  }

  private getStartTime(opportunity) {
    const startTime = opportunity.startTime;
    const isStarted = startTime <= this.currentTime;
    if (!isStarted && !opportunity.isUserEligible) {
      return this.getTime(startTime);
    }
  }

  public getTime(timeStamp: number) {
    if (timeStamp > this.currentTime) {
      const { daysLeft, hoursLeft, minutesLeft, secondsLeft } =
        UtilsService.getRemainingTime(+timeStamp.toString(), this.currentTime);
      return {
        timeStamp: timeStamp,
        currentTime: this.currentTime,
        d:
          daysLeft < 100
            ? this.maskService.applyMask(daysLeft.toString(), '00')
            : daysLeft.toString(),
        h: this.maskService.applyMask(hoursLeft.toString(), '00'),
        m: this.maskService.applyMask(minutesLeft.toString(), '00')
      };
    }
  }

  public getToken(token: CrowdToken) {
    if (token) {
      return new CrowdToken(
        token.chainId,
        token.address,
        token.decimals,
        token.symbol,
        token.name
      );
    }
    return undefined;
  }

  getMulticallResultIndex(
    data: any[],
    chainId: number,
    opportunityName: string,
    methodName: string
  ) {
    const index = data
      .filter((item) => {
        return item.chainId === chainId;
      })
      .findIndex((item) => {
        return item.oppName === opportunityName && item.method === methodName;
      });
    return index;
  }

  getOpportunityExistMethodByType(opportunity: any) {
    switch (opportunity.opportunityType) {
      case OpportunityType.TypeCrowd:
        return 'lpStakeholders';
      case OpportunityType.TypeStaking:
        return opportunity.lockedStaking ? 'hasStaked' : 'stakeholders';
      case OpportunityType.TypePancake:
        return 'userInfo';
      case OpportunityType.TypeBeefy:
        return 'balanceOf';
    }
    return '';
  }

  getNetworkIndex(_chainId) {
    for (const [index, chainId] of Object.keys(MainNetworksById)
      .filter((chainId) => environment.ACTIVE_NETWORK.includes(chainId))
      .entries()) {
      if (_chainId === +chainId) {
        return index;
      }
    }
    return -1;
  }
}
