<app-dialog>
  <div class="flex justify-start">
    <label class="title dark:text-textWhite"> Confirmation </label>
    <div class="absolute right-5 mr-1 dark:text-textWhite">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="close()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
  </div>
  <div class="dark:divide-appLightBackground">
    <div>
      <div *ngIf="data?.unusualMode" class="flex flex-col items-center mt-3">
        <div class="bg-[#FBD127] rounded p-2 w-full">
          <div class="flex justify-start align-items-center">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.975 2.75H14.025C14.3 2.75 14.4833 2.84167 14.6667 3.025L18.975 7.33333C19.1583 7.51667 19.25 7.7 19.25 7.975V14.025C19.25 14.3 19.1583 14.4833 18.975 14.6667L14.6667 18.975C14.4833 19.1583 14.3 19.25 14.025 19.25H7.975C7.7 19.25 7.51667 19.1583 7.33333 18.975L3.025 14.6667C2.84167 14.4833 2.75 14.3 2.75 14.025V7.975C2.75 7.7 2.84167 7.51667 3.025 7.33333L7.33333 3.025C7.51667 2.84167 7.7 2.75 7.975 2.75Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0013 7.33203V10.9987"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0006 14.6654H11.0098"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="from-title">&nbsp;Attention</span>
          </div>
          <div class="detail">
            Because of the current state of the pools, if you confirm this
            transaction, you may get about %{{ data?.differenceInPercent }}
            less than the entered amount.
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="bg-[#FAFAFA] dark:bg-dialogBoxBackground flex flex-col items-center rounded mt-3 p-2 border"
      >
        <div class="bg-[#FFFFFF] dark:bg-dialogBoxBackground rounded w-full">
          <div class="flex justify-start align-items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.9987 4.16797V15.8346"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.3333 7.5013L10 4.16797"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.66797 7.5013L10.0013 4.16797"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="from-title dark:text-darkGrayText"> You invest: </span>
          </div>
          <div class="grid grid-cols-2 align-items-center gap-4">
            <div class="flex justify-start align-items-center">
              <div>
                <img
                  class="rounded-full"
                  [src]="
                    baseLogoIconsUrl +
                    data?.sourceTokenSymbol?.toLowerCase() +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                />
              </div>
              <div class="ml-3 from-token dark:text-white">
                {{ data?.sourceTokenSymbol }}
              </div>
              <div
                *ngIf="data?.targetNetworkName"
                class="ml-3 from-token detail dark:text-white"
              >
                on {{ data?.sourceNetworkName }}
              </div>
            </div>
            <div
              id="confirmDialogAmountOut"
              class="flex flex-col blue-text items-end amount dark:text-white"
            >
              {{ data?.amountIn }}
              <span class="text-gray-400 float-right text-xs-2"
                >~${{ (+data?.sourceValue).toFixed(2) }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-[#FAFAFA] dark:bg-dialogBoxBackground flex flex-col items-center rounded mt-3 p-2 border"
      >
        <div class="bg-[#FFFFFF] dark:bg-dialogBoxBackground rounded w-full">
          <div class="flex justify-start align-items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.9987 4.16797V15.8346"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.3333 12.5L10 15.8333"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.66797 12.5L10.0013 15.8333"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="to-title dark:text-darkGrayText"> You gain: </span>
          </div>
          <div class="grid grid-cols-2 align-items-center gap-4">
            <div class="flex justify-start align-items-center">
              <div>
                <img
                  src="{{ baseUrl }}/api/v1/image/{{
                    data?.activeOpportunity.presaleName.toLowerCase()
                  }}.png"
                  style="width: 30px"
                />
              </div>
              <div class="ml-3 to-token dark:text-white">
                {{ data?.destTokenSymbol }}
              </div>
              <div
                *ngIf="data?.targetNetworkName"
                class="ml-3 from-token detail dark:text-white"
              >
                on {{ data?.targetNetworkName }}
              </div>
            </div>
            <div
              class="flex flex-col blue-text items-end amount dark:text-white"
            >
              {{ data?.amountOut }}
              <span class="text-gray-400 float-right text-xs-2"
                >~${{ (+data?.destValue).toFixed(2) }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col items-center mt-3">
        <div
          class="bg-[#FAFAFA] dark:bg-dialogBoxBackground rounded p-2 w-full border dark:text-textWhite"
        >
          <div class="flex justify-start align-items-center">
            <span class="from-title dark:text-darkGrayText">Details</span>
          </div>
          <div class="grid grid-cols-3">
            <div class="col-span-2 detail">Minimum investment amount</div>
            <div class="flex flex-col items-end detail">
              ${{ data?.minAmountOut }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col mt-3 mb-3">
        <button
          id="btnConfirmSwapDialog"
          (click)="confirm()"
          class="blue-button py-2 px-4 rounded-md h-52 flex justify-center text-white text-sm items-center text-center hover:bg-blue-700"
        >
          <span>Confirm</span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
