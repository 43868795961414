<div class="d-flex flex-column">
  <div
    class="modal-body dark:text-fff text-000 text-center"
    [innerHTML]="nzModalData.modalBody"
  ></div>

  <div
    class="d-flex align-items-center w-full border-2 rounded-sm px-2 mt-3 border-radius-6px"
    [ngClass]="
      email.value ? 'border-blue-500' : 'border-gray-300 dark:border-darkBorder'
    "
  >
    <input
      [formControl]="email"
      id="email"
      class="font-bold font-14 h-42px blue-color p-1 ml-2 w-full focus:border-none placeholder-gray-300 dark:placeholder-gray-700 focus:outline-none bg-transparent"
      placeholder="Your email"
    />
  </div>

  <span *ngIf="submited && email.invalid" class="font-12 text-red-400 mt-1">
    Invalid email address
  </span>

  <div class="flex flex-col mt-3">
    <button
      id="btnCloseTxSubmitDialog"
      class="border-solid border-radius-6px border-2 py-2 px-4 d-flex justify-content-center align-items-center text-center dark:text-fff text-000"
      (click)="confirm()"
    >
      <span>Send the email address</span>
    </button>
  </div>
</div>
