<div [ngClass]="isDarkMode ? 'dark' : ''" class="app-container">
  <div class="app-section bg-FAFBFC xs:bg-fff dark:bg-070707 overflow-hidden">
    <div app-christmas></div>

    <app-header-banner #headerBanner></app-header-banner>

    <app-listing-banner></app-listing-banner>

    <app-header></app-header>

    <div
      id="contentSection"
      class="content-section overflow-y-auto d-flex flex-column justify-content-between position-relative"
      [ngStyle]="{
        height:
          windowWidth <= 1024
            ? 'calc(100vh - var(--sab) - var(--sat) - 102px - ' +
              (headerBanner?.headerBanner?.nativeElement?.offsetHeight ?? 0) +
              'px)'
            : 'calc(100vh - var(--sab) - var(--sat) - 62px - ' +
              (headerBanner?.headerBanner?.nativeElement?.offsetHeight ?? 0) +
              'px)'
      }"
    >
      <div>
        <app-caption
          *ngIf="navigatorService.getActiveMenu() === ActiveMenu.Portfolio"
        ></app-caption>

        <router-outlet></router-outlet>
        <app-help-circle
          *ngIf="enableHelpCircle"
          class="help-center"
        ></app-help-circle>
      </div>

      <app-footer></app-footer>
    </div>
  </div>

  <div *ngIf="false" class="zerro-fee-section position-fixed right-0">
    <div
      *ngIf="!showZeroFeeContent"
      (click)="showZeroFeeContent = !showZeroFeeContent"
      class="zerro-fee-trigger position-absolute rotate--90 text-0027A2 no-text-wrap px-10px py-2 font-openSans-bold border-top-radius-4px cursor-pointer"
    >
      Zero Fee
    </div>

    <div
      *ngIf="showZeroFeeContent"
      class="zerro-fee-content d-flex flex-column align-items-end position-relative pr-3"
      [ngClass]="{ 'show-zerro-fee-content': showZeroFeeContent }"
    >
      <span
        class="text-323232 dark:text-fff cursor-pointer"
        (click)="showZeroFeeContent = !showZeroFeeContent"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_18099_25218)">
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_18099_25218">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>

      <img
        (click)="changePage()"
        routerLink="/opportunity/BSC_ETF"
        src="assets/media/zero-fee/divider-zero-fee.jpg"
        class="border-radius-6px cursor-pointer"
      />
    </div>
  </div>
</div>
