import {
  ConnectWalletService,
  CrowdSwapService,
  NDDClientInfoServiceImpl,
  NotificationService,
  PortfolioService,
  StorageService,
  TagManagerService,
  ThemeService,
  TokensService,
  Web3Service
} from 'src/app/services';
import { BaseSwapComponent } from '../base-swap.component';
import {
  CrossAndSameChainEstimateTrade,
  CrossChainSwapState,
  EstimateTrade,
  ICostAnalyze,
  IExecuteReqMdl,
  SearchParamModel,
  SmartWalletConfirmation
} from 'src/app/model';
import { EMPTY, catchError, map } from 'rxjs';
import {
  Conversion,
  CrowdToken,
  PriceService,
  UNUSUAL_MODE_PERCENTAGE
} from '@crowdswap/constant';
import { ModalService } from 'src/app/views/modal/modal.service';
import { TransactionSubmittedDialogComponent } from 'src/app/views/modal/dialogs/transaction-submitted-dialog/transaction-submitted-dialog.component';
import { BigNumber } from 'ethers';
import { WaitingDialogComponent } from 'src/app/views/modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ConfirmSwapDialogComponent } from 'src/app/views/modal/dialogs/confirm-swap-dialog/confirm-swap-dialog.component';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import { FeedbackService } from '../../../../services/feedback.service';
import { environment } from '../../../../../environments/environment';
import { Token } from '@crowdswap/sdk';
import { IConnectWalletRespMdl } from '../../../modal/dialogs/connect-wallet-dialog/connect-wallet-dialog.component';
import { SmartWalletConfirmationDialogComponent } from '../../../modal/dialogs/smart-wallet-confirmation/smart-wallet-confirmation.component';

export class SameChainSwapComponent extends BaseSwapComponent {
  public confirmModal!: import('ng-zorro-antd/modal').NzModalRef<
    SmartWalletConfirmationDialogComponent,
    any
  >;

  constructor(
    _state,
    _status,
    _setting,
    web3Service: Web3Service,
    themeService: ThemeService,
    tagManagerService: TagManagerService,
    waitingDialogService: ModalService<WaitingDialogComponent>,
    txSubmittedDialogService: ModalService<TransactionSubmittedDialogComponent>,
    notificationService: NotificationService,
    priceService: PriceService,
    confirmDialogService: ModalService<ConfirmSwapDialogComponent>,
    clientInfoServiceImpl: NDDClientInfoServiceImpl,
    storageService: StorageService,
    protected connectWalletService: ConnectWalletService,
    private portfolioService: PortfolioService,
    private crowdSwapService: CrowdSwapService,
    tokensService: TokensService,
    feedbackService: FeedbackService
  ) {
    super(
      web3Service,
      themeService,
      tagManagerService,
      waitingDialogService,
      txSubmittedDialogService,
      notificationService,
      priceService,
      confirmDialogService,
      clientInfoServiceImpl,
      storageService,
      tokensService,
      feedbackService,
      connectWalletService
    );
    this.state = _state;
    this.status = _status;
    this.setting = _setting;
  }

  public override getEstimation(searchParam: SearchParamModel) {
    return this.crowdSwapService.getAllSwapEstimation(searchParam).pipe(
      catchError((err) => {
        this.setBestEstimation(
          BaseSwapComponent.GetNotFoundEstimate(searchParam)
        );
        console.log(err);
        return EMPTY;
      }),
      map(async (estimateTradeList: CrossAndSameChainEstimateTrade[]) => {
        const estimation = await this.setBestEstimation(estimateTradeList[0]);

        await this.tagManagerService.sendCrossChainTags(
          'exchange_search',
          estimation,
          this.web3Service.getWalletAddress()
        );

        estimation.amountInDisplay = this.getAmount(
          estimation.amountIn,
          estimation.fromToken.decimals
        );
        estimation.amountOutDisplay = this.getAmount(
          estimation.amountOut,
          estimation.toToken.decimals
        );

        estimation.routeList = [];
        for (let index = 0; index < estimation.routes?.length; index++) {
          const element = estimation.routes[index];

          if (element) {
            estimation.routeList.push(this.getToken(element));
          } else {
            if (index === 0) {
              estimation.routeList.push(estimation.fromToken);
            } else if (index === estimation.routes.length - 1) {
              estimation.routeList.push(estimation.toToken);
            }
          }
        }

        const costAnalyze = this.analyzeEstimationCost(estimation);
        estimation.costAnalyze = costAnalyze;

        if (
          this.state.activeEstimationTrade.minAmountOut &&
          this.state.activeEstimationTrade.toToken?.decimals
        ) {
          const minAmountOut = this.getAmount(
            this.state.activeEstimationTrade.minAmountOut,
            this.state.activeEstimationTrade.toToken.decimals
          );
          this.state.activeEstimationTrade.minAmountOutToDisplay = minAmountOut;

          this.state.activeEstimationTrade.minAmountOutInUSDTToDisplay =
            formatNumber(
              parseFloat(minAmountOut) *
                parseFloat(this.state.activeEstimationTrade.toToken.price),
              NumberType.FiatTokenPrice
            );
        } else {
          this.state.hasPreviousMinAmountOut = false;
        }
      })
    );
  }

  public override analyzeEstimationCost(
    trade: CrossAndSameChainEstimateTrade
  ): ICostAnalyze {
    if (
      [CrossChainSwapState.NotFound, CrossChainSwapState.Expired].indexOf(
        trade.crossChainSwapState
      ) > -1
    ) {
      return { isUnusualAmountOut: false, isUnusualFee: false };
    }
    let sourceValue: number;
    let destinationValue: number;

    const valueFrom = this.priceService.getTokenValueByAmount(
      trade.fromToken,
      trade.amountIn
    );
    sourceValue = valueFrom === undefined ? 0 : parseFloat(valueFrom);

    const valueTo = this.priceService.getTokenValueByAmount(
      trade.toToken,
      trade.amountOut
    );
    destinationValue = valueTo === undefined ? 0 : parseFloat(valueTo);

    const priceImpact = parseFloat(
      (((destinationValue - sourceValue) / sourceValue) * 100).toFixed(2)
    );

    return {
      confirmed: false,
      amount: formatNumber(
        sourceValue - destinationValue,
        NumberType.FiatTokenPrice
      ),
      percent: priceImpact,
      isUnusualAmountOut:
        destinationValue <
        sourceValue - (sourceValue * UNUSUAL_MODE_PERCENTAGE) / 100,
      isUnusualFee: false
    };
  }

  public override async getSwapTransaction(
    userAddress,
    estimation: CrossAndSameChainEstimateTrade
  ) {
    try {
      await this.tagManagerService.sendCrossChainTags(
        'exchange_start',
        estimation,
        this.state.receiverAddress || this.walletAddress
      );

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        if (
          this.status.isAddressValid &&
          this.setting.isReceiverWalletSelected
        ) {
          // this.loggingService.log(
          //   `Sender address is : ${userAddress} & Receiver address is : ${this.state.receiverAddress} for ${estimation}`
          // );
        }
        let swapTx: any;
        swapTx = !estimation.middleToken
          ? await this.crowdSwapService.getSwapTransaction(
              estimation.dex.name,
              estimation.fromToken,
              estimation.toToken,
              estimation.amountIn.toString(),
              userAddress,
              estimation.delegatedDex,
              this.setting.slippage,
              this.setting.deadline,
              this.status.isAddressValid &&
                this.setting.isReceiverWalletSelected
                ? this.state.receiverAddress
                : userAddress,
              false
            )
          : await this.crowdSwapService.getCrossDexSwapTransaction(
              estimation.firstDex!,
              estimation.secondDex!,
              estimation.fromToken,
              estimation.middleToken,
              estimation.toToken,
              estimation.amountIn.toString(),
              estimation.amountInSecondSwap!.toString(),
              userAddress,
              estimation.delegatedDex,
              this.setting.slippage,
              this.setting.deadline,
              this.status.isAddressValid &&
                this.setting.isReceiverWalletSelected
                ? this.state.receiverAddress
                : userAddress
            );

        if (!swapTx) {
          await this.tagManagerService.sendCrossChainTags(
            'exchange_error',
            estimation,
            this.state.receiverAddress || this.web3Service.getWalletAddress(),
            'tx_not_found'
          );
          this.notificationService.error({
            title: 'Error',
            content: 'Something went wrong, try again!'
          });

          this.crowdswapCallback(false, 'the transaction has been rejected!');

          this.changeCrossChainSwapState(
            estimation,
            CrossChainSwapState.FailedPostCCStep
          );
          // this.setConsoleLog(this.swapState, ' Getting transaction failed. ');

          await this.waitingDialogService.close();
          return;
        }
        if (swapTx.state === '508') {
          await this.tagManagerService.sendCrossChainTags(
            'exchange_error',
            estimation,
            this.state.receiverAddress || this.web3Service.getWalletAddress(),
            'error_508'
          );
          this.notificationService.error({
            title: 'Error',
            content:
              'This transaction carries the risk of assets lost and its\n' +
              '                execution is not supported by CrowdSwap.',
            options: {
              closeButton: true,
              tapToDismiss: false,
              progressBar: true,
              positionClass: 'custom-toast-top-right',
              timeOut: 15000,
              messageClass: 'errorClass-unusual'
            }
          });

          this.crowdswapCallback(false, 'the transaction has been rejected!');

          this.changeCrossChainSwapState(
            estimation,
            CrossChainSwapState.FailedPostCCStep
          );

          // this.setConsoleLog(
          //   this.swapState,
          //   ' Getting transaction failed.This transaction carries the risk of assets lost and its\n' +
          //     '                execution is not supported by CrowdSwap. '
          // );
          await this.waitingDialogService.close();
          return;
        }
        delete swapTx.state;
        return swapTx;
      }
    } catch (e) {
      console.log(e);
      this.changeCrossChainSwapState(
        estimation,
        CrossChainSwapState.FailedPostCCStep
      );
      await this.waitingDialogService.close();
    }
  }

  public override async confirmSwapTransaction(
    estimation: CrossAndSameChainEstimateTrade
  ) {
    const crossAndSameChainEstimation = <CrossAndSameChainEstimateTrade>(
      estimation
    );
    try {
      await this.tagManagerService.sendCrossChainTags(
        'exchange_confirmation_view',
        crossAndSameChainEstimation,
        this.state.receiverAddress || this.walletAddress
      );

      let currentTransaction: any;

      if (this.web3Service.usingCrowdWallet) {
        const executeTx: IExecuteReqMdl = {
          email: '',
          passwordHash: '',
          transactions: crossAndSameChainEstimation.request?.transactions,
          chainId: crossAndSameChainEstimation.fromToken.chainId,
          gasLimit: crossAndSameChainEstimation.request.gasLimit,
          gasPrice: crossAndSameChainEstimation.request.gasPrice
        };

        this.confirmModal = this.connectWalletService.openConfirmModal(
          this.isDarkMode,
          {
            executeTx: executeTx,
            type: SmartWalletConfirmation.Swap,
            fees: crossAndSameChainEstimation.fee,
            fromToken: crossAndSameChainEstimation.fromToken,
            toToken: crossAndSameChainEstimation.toToken,
            amount: crossAndSameChainEstimation.amountInDisplay,
            amountOut: crossAndSameChainEstimation.amountOutDisplay
          }
        );

        this.confirmModal.afterClose.subscribe(async (resp: boolean) => {
          if (resp) {
            currentTransaction = await this.connectWalletService?.execute(
              executeTx
            );

            if (crossAndSameChainEstimation.swapTx) {
              crossAndSameChainEstimation.swapTx['hash'] =
                currentTransaction.hash;
            }

            this.addToPendingTradeList(crossAndSameChainEstimation);
            await this.waitForTransaction(
              crossAndSameChainEstimation,
              currentTransaction.hash,
              crossAndSameChainEstimation.fromToken.chainId
            );
          }

          await this.waitingDialogService.close();
        });
      } else {
        currentTransaction = await this.web3Service
          .sendTransaction({
            ...crossAndSameChainEstimation?.swapTx,
            ...(crossAndSameChainEstimation?.swapTx?.gasLimit
              ? {
                  gas: crossAndSameChainEstimation?.swapTx?.gasLimit?.toString(),
                  gasLimit:
                    crossAndSameChainEstimation?.swapTx?.gasLimit?.toString()
                }
              : {})
          })
          .then(async (data) => {
            if (!data) {
              this.changeCrossChainSwapState(
                crossAndSameChainEstimation,
                CrossChainSwapState.FailedPreCCStep
              );
              this.setConsoleLog(
                crossAndSameChainEstimation,
                ' Failed current transaction swap. Hash is Empty or null.'
              );
              return;
            }
            await this.tagManagerService.sendCrossChainTags(
              'exchange_confirmation_view_confirm',
              crossAndSameChainEstimation,
              this.state.receiverAddress || this.walletAddress
            );

            await this.waitingDialogService.close();

            this.changeCrossChainSwapState(
              crossAndSameChainEstimation,
              CrossChainSwapState.StartPreCCStep
            );

            crossAndSameChainEstimation.swapTx!.hash = data;
            this.addToPendingTradeList(crossAndSameChainEstimation);

            document.getElementById('contentSection')?.scrollTo({
              top: 0,
              behavior: 'smooth'
            });

            return data;
          })
          .catch(async (e) => {
            await this.tagManagerService.sendCrossChainTags(
              'exchange_confirmation_view_reject',
              crossAndSameChainEstimation,
              this.state.receiverAddress || this.walletAddress
            );

            console.log(e.message);
            this.notificationService.error({
              title: 'Error',
              content: 'Swap rejected!'
            });

            this.crowdswapCallback(true, 'the transaction has been completed');

            this.changeCrossChainSwapState(
              crossAndSameChainEstimation,
              CrossChainSwapState.FailedPreCCStep
            );
            await this.waitingDialogService.close();
          });
      }

      if (currentTransaction) {
        await this.showSuccessfulDialog(
          crossAndSameChainEstimation,
          currentTransaction
        );

        // Observe swap tx status
        await this.observeSwapTransactionStatus(crossAndSameChainEstimation);
      }
    } catch (e) {
      console.log(e);
      this.changeCrossChainSwapState(
        crossAndSameChainEstimation,
        CrossChainSwapState.FailedPostCCStep
      );
      await this.waitingDialogService.close();
    }
  }

  public override async observeSwapTransactionStatus(
    crossAndSameChainEstimation: CrossAndSameChainEstimateTrade,
    index?: number
  ) {
    if (
      !crossAndSameChainEstimation.swapTx ||
      !crossAndSameChainEstimation.swapTx.hash
    ) {
      console.log('observeSwapTransactionStatus: swapTx does not exist');
      return;
    }
    await this.waitForTransaction(
      crossAndSameChainEstimation,
      crossAndSameChainEstimation.swapTx.hash,
      crossAndSameChainEstimation.fromToken.chainId,
      index
    );
  }

  private async waitForTransaction(
    crossAndSameChainEstimation: CrossAndSameChainEstimateTrade,
    txHash: string = '',
    chainId?: number,
    index?: number
  ) {
    if (!this.web3Service.usingCrowdWallet) {
      if (
        !crossAndSameChainEstimation.swapTx ||
        !crossAndSameChainEstimation.swapTx.hash
      ) {
        return;
      } else {
        txHash = crossAndSameChainEstimation?.swapTx?.hash;
      }
    }

    await this.web3Service
      .waitForTransaction(
        txHash,
        1,
        this.web3Service.getNetworkProvider(
          crossAndSameChainEstimation.fromToken.chainId
        )
      )
      .then(async (data) => {
        this.portfolioService.tokenBalanceChangeSubject.next([
          crossAndSameChainEstimation.fromToken,
          crossAndSameChainEstimation.toToken
        ]);
        if (data?.status === 1) {
          if (
            crossAndSameChainEstimation.toToken.symbol === 'CROWD' &&
            parseFloat(crossAndSameChainEstimation.amountInInUSDT) >=
              environment.airdrop.minimumSwapAmount
          ) {
            this.runGleamScript('buyCrowd');
          }
          this.changeCrossChainSwapState(
            crossAndSameChainEstimation,
            CrossChainSwapState.PassedPostCCStep
          );
        } else {
          this.changeCrossChainSwapState(
            crossAndSameChainEstimation,
            CrossChainSwapState.FailedPreCCStep
          );
        }

        let pendingTrade = this.findFromPendingList(txHash, index);
        if (!pendingTrade) {
          return;
        }
        crossAndSameChainEstimation.scanTransactionUrl =
          this.web3Service.getScanTransactionUrl(
            txHash,
            chainId ?? crossAndSameChainEstimation.fromToken.chainId
          );

        switch (crossAndSameChainEstimation.crossChainSwapState) {
          case CrossChainSwapState.PassedPostCCStep: {
            await this.tagManagerService.sendCrossChainTags(
              'exchange_done',
              crossAndSameChainEstimation,
              this.state.receiverAddress || this.web3Service.getWalletAddress(),
              'success'
            );

            this.notificationService.success({
              title: `Swap exactly ${crossAndSameChainEstimation.amountInDisplay} ${crossAndSameChainEstimation.fromToken.symbol} to ${crossAndSameChainEstimation.amountOutDisplay} ${crossAndSameChainEstimation.toToken.symbol}`,
              content: `<a href='${crossAndSameChainEstimation.scanTransactionUrl}' target='_blank'>View in explorer</a>`
            });

            this.crowdswapCallback(false, 'the transaction has been rejected!');
            break;
          }
          case CrossChainSwapState.FailedPreCCStep:
          case CrossChainSwapState.FailedPostCCStep: {
            await this.tagManagerService.sendCrossChainTags(
              'exchange_error',
              crossAndSameChainEstimation,
              this.state.receiverAddress || this.web3Service.getWalletAddress(),
              'something went wrong'
            );
            this.notificationService.error({
              title: `Swap failed`,
              content: `<a href='${crossAndSameChainEstimation.scanTransactionUrl}' target='_blank'>View in explorer</a>`,
              options: {
                closeButton: true,
                tapToDismiss: false,
                progressBar: true,
                positionClass: 'custom-toast-top-right',
                enableHtml: true,
                timeOut: 10000,
                messageClass: 'failedClass'
              }
            });

            this.crowdswapCallback(false, 'the transaction has been rejected!');
            return;
          }
        }
        return data;
      })
      .catch((e) => {
        console.log(e);
        this.changeCrossChainSwapState(
          crossAndSameChainEstimation,
          CrossChainSwapState.FailedPreCCStep
        );
      });
  }

  public override async checkAllowance(
    estimation: CrossAndSameChainEstimateTrade
  ) {
    try {
      if (
        this.web3Service.usingCrowdWallet ||
        estimation.loading ||
        this.isCoin(estimation.fromToken)
      ) {
        return;
      }

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        const allowance: BigNumber = await this.web3Service
          .getAllowanceBySpender(
            estimation.approveAddress,
            estimation.fromToken.address,
            userAddress,
            this.web3Service.getNetworkProvider(estimation.fromToken.chainId)
          )
          .catch((e) => {
            console.log(e);
            this.notificationService.error({
              title: 'Error',
              content: 'Failed to retrieve allowance.'
            });
          });
        if (!allowance) {
          console.log('Allowance retrieval returned undefined.');
          return;
        }
        const allowanceValue = Conversion.toSafeBigNumberByRemovingFraction(
          allowance.toString()
        );
        const amountInValue = Conversion.toSafeBigNumberByRemovingFraction(
          estimation.amountIn
        );
        if (allowanceValue.lt(amountInValue)) {
          this.changeCrossChainSwapState(
            estimation,
            CrossChainSwapState.ApprovalNeeded
          );
        }
      }
    } catch (e) {
      console.log(e);
      this.notificationService.error({
        title: 'Error',
        content: 'Failed to retrieve allowance.'
      });
    }
  }

  public override async getApprovalTransaction(estimation: EstimateTrade) {
    return this.web3Service.getApprovalTransactionBySpender(
      estimation.approveAddress,
      // estimation.dex.name,
      // estimation.delegatedDex,
      estimation.fromToken.address,
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
      this.web3Service.getNetworkProvider(estimation.fromToken.chainId)
    );
  }

  public override async sendApprovalTransaction(estimation, approvalTx) {
    return super.sendApprovalTransaction(estimation, approvalTx);
  }

  protected crowdswapCallback(status: boolean, message: string): void {}

  public getToken(token: CrowdToken) {
    return new CrowdToken(
      token?.chainId,
      token?.address,
      token?.decimals,
      token?.symbol,
      token?.name
    );
  }
  protected approvePatchNewAmount(
    pendingTrade: CrossAndSameChainEstimateTrade
  ) {
    throw new Error(
      'Method not implemented yet.maybe have patch for samechain in the future'
    );
  }

  protected preparePatchNewAmount(
    pendingTrade: CrossAndSameChainEstimateTrade
  ) {
    throw new Error(
      'Method not implemented yet.maybe have patch for samechain in the future'
    );
  }
}
