<app-dialog>
  <div class="absolute right-5 mr-1 dark:text-textWhite">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 h-5 cursor-pointer"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      (click)="close()"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  </div>
  <div class="flex justify-start"></div>
  <div class="divide-y">
    <div>
      <div class="flex justify-center mt-10">
        <img
          *ngIf="!data?.isDarkMode"
          src="assets/media/icons/success-check-black.svg"
        />
        <img
          *ngIf="data?.isDarkMode"
          src="assets/media/icons/success-check-white.svg"
        />
      </div>
      <div class="flex justify-center mt-5">
        <p class="successfully-complete font-openSans-medium dark:text-white">
          Successfully completed
        </p>
      </div>
      <div class="flex justify-center mt-3 dark:text-white">
        <span class=""> You {{ data?.stakeState }} </span>
      </div>
      <div class="flex items-center justify-center mt-2">
        <div class="relative grid scale-50">
          <img
            class="rounded-full"
            [src]="
              baseLogoIconsUrl +
              data?.withdrawTokensIcon[0]?.toLowerCase() +
              '.png'
            "
            (error)="updateUrl($event)"
          />
          <div class="absolute right-5">
            <img
              class="rounded-full"
              [src]="
                baseLogoIconsUrl +
                data?.withdrawTokensIcon[1]?.toLowerCase() +
                '.png'
              "
              (error)="updateUrl($event)"
            />
          </div>
        </div>
        <span class="ml-2 flex dark:text-white">
          {{ data?.stakeAmount }} {{ data?.tokenSymbol }}
        </span>
      </div>
      <div class="flex flex-col mt-5 mb-3">
        <button
          (click)="close()"
          class="border-solid rounded-xl border-2 py-2 px-4 h-52 flex justify-center items-center text-center dark:border dark:border-darkGrayText dark:text-white"
        >
          <span>Close</span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
