<div class="flip-card hover:z-10">
  <div class="flip-card-inner">
    <div class="flip-card-front">
      <div class="vip-box bg-white dark:bg-darkBlack">
        <div class="vip-box-header flex items-center">
          <div>
            <img alt="vip-avatar" src="{{ vip.avatar }}" />
          </div>
          <div
            class="{{
              vip.colorClass
            }} text-2xl font-openSans-semiBold relative "
          >
            {{ vip.name }}&nbsp;{{ vip.stage === 0 ? "" : vip.stage }}
            <div
              *ngIf="vip.stage === userStatus.stage && isUserWalletConnected"
              class="absolute text-xs rounded-sm px-1 text-black you-are-bg font-openSans-regular -bottom-5 left-0"
            >
              <div class="flex items-center">
                You&nbsp;are&nbsp;here
                <svg
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.745928 0.843584C1.60508 -0.0155697 2.99804 -0.0155697 3.8572 0.843584L4.50156 1.48795L5.14593 0.843584C6.00508 -0.0155697 7.39804 -0.0155697 8.2572 0.843584C9.11635 1.70274 9.11635 3.0957 8.2572 3.95485L4.50156 7.71049L0.745928 3.95485C-0.113226 3.0957 -0.113226 1.70274 0.745928 0.843584Z"
                    fill="#2D2D2D"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            *ngFor="let item of vip.benefits; let i = index"
            class="px-3 py-2"
          >
            <div
              class="flex items-center h-8"
              [ngClass]="vip.stage > 0 ? 'font-bold benefits' : 'pt-4'"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flip-card-back">
      <div class="vip-box bg-white dark:bg-darkBlack">
        <div class="vip-box-header flex">
          <div>
            <img alt="vip-avatar" src="{{ vip.avatar }}" />
          </div>
          <div
            class="{{ vip.colorClass }}  relative "
            [ngClass]="vip.stage !== 0 ? '' : 'text-2xl font-openSans-semiBold'"
          >
            {{
              vip.stage !== 0 ? "How to reach " + vip.name : "" + vip.name
            }}&nbsp;{{ vip.stage === 0 ? "" : vip.stage }}
            <div
              *ngIf="vip.stage === userStatus.stage && isUserWalletConnected"
              class="absolute text-xs rounded-sm px-1 text-black you-are-bg font-openSans-regular -bottom-5 left-0"
            >
              <div class="flex items-center">
                You&nbsp;are&nbsp;here
                <svg
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.745928 0.843584C1.60508 -0.0155697 2.99804 -0.0155697 3.8572 0.843584L4.50156 1.48795L5.14593 0.843584C6.00508 -0.0155697 7.39804 -0.0155697 8.2572 0.843584C9.11635 1.70274 9.11635 3.0957 8.2572 3.95485L4.50156 7.71049L0.745928 3.95485C-0.113226 3.0957 -0.113226 1.70274 0.745928 0.843584Z"
                    fill="#2D2D2D"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full">
          <div class="px-3 py-2">
            <div class="w-full">
              <div *ngIf="vip.stage !== 0">
                You need to earn
                {{ userStatus?.rules[vip.stage - 1]?.upperBound }} points to
                reach Stage {{ vip.stage }}
              </div>
              <div
                *ngIf="vip.stage !== 0"
                class="px-1 text-xs my-2 font-openSans-semiBold border rounded-md"
              >
                {{
                  userStatus.score < userStatus.rules[vip.stage - 1]?.upperBound
                    ? "You are " +
                      (userStatus.rules[vip.stage - 1]?.upperBound -
                        userStatus.score) +
                      " points away for this stage!"
                    : "You reached this stage!"
                }}
              </div>
              <div class="p-2 rounded-md text-black green-color">
                <div class="font-bold text-center mb-3">
                  {{
                    vip.stage !== 0
                      ? "Points are calculated based on"
                      : "Just a few steps before you are a VIP Club Member"
                  }}
                </div>
                <ul class="list-disc pl-4 text-left">
                  <li>CROWDs in wallet x1</li>
                  <li>CROWDs in staking x1</li>
                  <li>CROWDs in liquidity pools x5</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
