import { Injectable } from '@angular/core';
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, timeout } from 'rxjs/operators';
import { AFFILIATE_FEE, Conversion, RevenueDto } from '@crowdswap/constant';
import { LoggingService } from './log';
import { UserInfo } from '../model/user-info';
import { CookieService } from 'ngx-cookie-service';
import { CustomException } from '../exception/custom.exception';
import { NetworksService } from './networks.service';
import { BigNumber, ethers } from 'ethers';
import { Web3Service } from './web3.service';

const baseUrl = environment.AFFILIATE_BASEURL || '';

@Injectable()
export class AffiliateService {
  public static readonly AUTH_KEY = 'Authorization';
  public static REQUEST_TIMEOUT = 1350000;
  public static readonly AffliateFeeContractAddress =
    '0x502BE36bfE654c41E8ECd3d2054CBB6EAfD7A8D5';

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private cookieService: CookieService,
    private networkService: NetworksService,
    private web3Service: Web3Service
  ) {}

  public async saveAffiliate(
    affiliateId: string,
    walletAddress: string
  ): Promise<any> {
    this.logger.debug(
      `Start saving the user wallet address for affiliateId ${affiliateId}`
    );
    const url = `${baseUrl}/api/v1/affiliate/${affiliateId}/wallets/${walletAddress}`;
    return this.http.post(url, {}).toPromise();
  }

  public async saveUserInfo(userInfo: UserInfo): Promise<any> {
    this.logger.debug(
      `Start saving the user wallet address for affiliateId ${userInfo.affiliateId}`
    );
    const url = `${baseUrl}/api/v1/affiliate/addUserInfo`;
    let params = {
      name: userInfo.name,
      email: userInfo.email,
      walletAddress: userInfo.walletAddress,
      affiliateId: userInfo.affiliateId
    };

    return this.http
      .post(url, params)
      .pipe(
        timeout(AffiliateService.REQUEST_TIMEOUT),
        map((response: any) => {
          if (response.body) {
            response.body = AffiliateService._mapResponseToUserInfo(
              response.body
            );
          }
          return response;
        })
      )
      .toPromise();
  }

  public async getRevenueOfWalletAddress(walletAddress: string) {
    if (!this.cookieService?.get(AffiliateService.AUTH_KEY)) {
      throw new CustomException('Unauthorized', HttpStatusCode.Unauthorized);
    }

    const url = `${baseUrl}/api/v1/affiliate/${walletAddress}/revenue/`;
    return this.http
      .get(url)
      .pipe(
        timeout(AffiliateService.REQUEST_TIMEOUT),
        map((response: any) => {
          return this._mapResponseToRevenueDto(response);
        })
      )
      .toPromise();
  }

  public async getUserInfo(walletAddress: string) {
    const url = `${baseUrl}/api/v1/affiliate/${walletAddress}/userInfo/`;
    return this.http
      .get(url)
      .pipe(
        timeout(AffiliateService.REQUEST_TIMEOUT),
        map((response: any) => {
          return AffiliateService._mapResponseToUserInfo(response);
        })
      )
      .toPromise();
  }

  public async isAffiliate(walletAddress: string) {
    const url = `${baseUrl}/api/v1/affiliate/${walletAddress}/exist/`;
    return this.http
      .get(url)
      .pipe(timeout(AffiliateService.REQUEST_TIMEOUT))
      .toPromise();
  }

  public sendVerificationToken(walletAddress: string) {
    const url = `${baseUrl}/api/v1/affiliate/${walletAddress}/send/verificationToken/`;
    return this.http
      .post(url, {})
      .pipe(timeout(AffiliateService.REQUEST_TIMEOUT))
      .toPromise();
  }

  public verifyEmail(walletAddress: string, token: string): Promise<boolean> {
    const url = `${baseUrl}/api/v1/affiliate/${walletAddress}/verify/${token}`;
    return this.http
      .post(url, {})
      .pipe(
        timeout(AffiliateService.REQUEST_TIMEOUT),
        map((response: any) => {
          return response.status;
        })
      )
      .toPromise();
  }

  public setDefaultValuesForRevenueDto(revenueDto: RevenueDto) {
    const defaultValue: number = 0;
    const defaultValueInString: string = defaultValue.toString();
    revenueDto.totalVolume = defaultValueInString;
    revenueDto.currentMonthVolume = defaultValueInString;
    revenueDto.totalFee = defaultValueInString;
    revenueDto.currentMonthFee = defaultValueInString;
    revenueDto.totalRegistration = defaultValue;
    revenueDto.currentMonthRegistration = defaultValue;
    revenueDto.runningMonthPercentage = defaultValue;
    revenueDto.followingMonthPercentage = defaultValue;
  }

  private _mapResponseToRevenueDto(response: any): RevenueDto {
    const NUM_OF_DECIMALS: number = 6;
    const revenueDto: RevenueDto = new RevenueDto();
    this.setDefaultValuesForRevenueDto(revenueDto);

    if (response.walletAddress) {
      revenueDto.walletAddress = response.walletAddress;
    }

    if (response.totalVolume) {
      revenueDto.totalVolume = Conversion.convertStringFromDecimal(
        response.totalVolume,
        NUM_OF_DECIMALS
      );
    }
    if (response.currentMonthVolume) {
      revenueDto.currentMonthVolume = Conversion.convertStringFromDecimal(
        response.currentMonthVolume,
        NUM_OF_DECIMALS
      );
    }
    if (response.totalFee) {
      revenueDto.totalFee = Conversion.convertStringFromDecimal(
        response.totalFee,
        NUM_OF_DECIMALS
      );
    }
    if (response.currentMonthFee) {
      revenueDto.currentMonthFee = Conversion.convertStringFromDecimal(
        response.currentMonthFee,
        NUM_OF_DECIMALS
      );
    }
    if (response.totalRegistration) {
      revenueDto.totalRegistration = response.totalRegistration;
    }
    if (response.currentMonthRegistration) {
      revenueDto.currentMonthRegistration = response.currentMonthRegistration;
    }
    revenueDto.runningMonthPercentage = response.runningMonthPercentage || 20;
    revenueDto.followingMonthPercentage =
      response.followingMonthPercentage || 20;

    return revenueDto;
  }

  static _mapResponseToUserInfo(response: any): UserInfo {
    const result: UserInfo = new UserInfo();
    if (response.name) {
      result.name = response.name;
    }
    if (response.email) {
      result.email = response.email;
    }
    if (response.walletAddress) {
      result.walletAddress = response.walletAddress;
    }
    if (response.affiliateId) {
      result.affiliateId = response.affiliateId;
    }
    return {
      name: result.name,
      email: result.email,
      walletAddress: result.walletAddress,
      affiliateId: result.affiliateId,
      isEmailVerified: response.isEmailVerified,
      displayEmailVerificationNotify: !response.isEmailVerified
    };
  }

  public async getReleasable(): Promise<string> {
    const contract = new ethers.Contract(
      AffiliateService.AffliateFeeContractAddress,
      AFFILIATE_FEE,
      this.getProvider(137)
    );
    try {
      const result = await contract.getReleasable(
        this.web3Service.getWalletAddress()
      );
      return (+Conversion.convertStringFromDecimal(result)).toFixed(7);
    } catch (e) {
      return '0';
    }
  }

  public async withdraw() {
    try {
      const contract = new ethers.Contract(
        AffiliateService.AffliateFeeContractAddress,
        AFFILIATE_FEE,
        this.getProvider(137)
      );
      const releasable = await this.getReleasable();
      if (+releasable > 0) {
        return contract.populateTransaction.withdraw(
          releasable,
          this.web3Service.getWalletAddress()
        );
      } else {
        throw new Error('Releasable amount is not enough');
      }
    } catch (e) {
      throw new Error('Unable to withdraw');
    }
  }

  private getProvider(chainId) {
    return this.networkService.getNetworkProvider(chainId).getProvider();
  }
}
