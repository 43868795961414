export const environment = {
  production: true,
  version: 'v18.0.3',
  qa: true,
  enableVip: true,
  enableHelpCircle: false,
  enablePushNotification: true,
  apiBaseUrl: 'https://demo-api.crowdswap.org',
  gtm: 'GTM-59FWW36',
  infura: '30133584cbf74667a3b221408d65b35f',
  walletConnectProjectId: '9608b5a3646c2bb6933303348abba577',
  LOG_COLLECTOR_CONFIG: {
    ENABLE: false
  },
  SmartWallet: false,
  airdrop: {
    minimumSwapAmount: 20,
    minimumAddLiquidityAmount: 20,
    minimumLockedStakingInvestAmount: 20,
    minimumEtfInvestAmount: 50
  },
  WARNING_PRICE_IMPACT_PERCENT: -5,
  HIGH_PRICE_IMPACT_PERCENT: -10,
  ETH_APIKEYS_MAINNET: 'https://rpc.ankr.com/eth',
  ETH_APIKEYS_ROPSTEN:
    'https://ropsten.infura.io/v3/793054a908974c48841f0baf6858971a',
  ETH_APIKEYS_RINKEBY:
    'https://rinkeby.infura.io/v3/793054a908974c48841f0baf6858971a',
  ETH_APIKEYS_GOERLI:
    'https://goerli.infura.io/v3/793054a908974c48841f0baf6858971a',
  ETH_APIKEYS_KOVAN:
    'https://kovan.infura.io/v3/793054a908974c48841f0baf6858971a',
  BSCMAIN_PROVIDER_ADDRESS: 'https://bsc-dataseed.binance.org/',
  BSCTEST_PROVIDER_ADDRESS: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  POLYGON_MAINNET_PROVIDER_ADDRESS: 'https://polygon-rpc.com/',
  POLYGON_MUMBAI_PROVIDER_ADDRESS:
    'https://rpc-mumbai.maticvigil.com/v1/ca597f2e23b8592e939722d68dcf82ad569205e9',
  AVALANCHE_PROVIDER_ADDRESS: 'https://rpc.ankr.com/avalanche',
  AVALANCHE_FUJI_PROVIDER_ADDRESS: 'https://api.avax-test.network/ext/bc/C/rpc',
  APEX_PROVIDER_ADDRESS: 'https://rpc.omniaverse.io',
  ARBITRUM_PROVIDER_ADDRESS: 'https://rpc.ankr.com/arbitrum',
  ZKSYNC_PROVIDER_ADDRESS: 'https://mainnet.era.zksync.io',
  ZKSYNCTEST_PROVIDER_ADDRESS: 'https://testnet.era.zksync.dev',
  OPTIMISM_PROVIDER_ADDRESS: 'https://mainnet.optimism.io',
  LINEA_PROVIDER_ADDRESS: 'https://linea-mainnet.public.blastapi.io',
  BASE_PROVIDER_ADDRESS: 'https://mainnet.base.org',
  DEFI_PROVIDER_ADDRESS: 'https://dmc.mydefichain.com/mainnet',
  ROOTSTOCK_PROVIDER_ADDRESS: 'https://public-node.rsk.co',
  ACTIVE_NETWORK: ['1', '56', '137', '43114', '42161', '324', '10', '1130'],
  API_KEY: '8f2fb789-f240-4ae4-a9d4-fe386f16cc3e',
  SENTRY_DSN:
    'https://86b682537439adadb993bddc34fde5cd@o4506321445650432.ingest.sentry.io/4506321461706752',
  PriceUrl: 'https://demo-api.crowdswap.org/price',
  PairInfoUrl: 'https://info.crowdswap.org/coingecko',
  NETWORK_SERVICE_BASEURL: 'https://demo-api.crowdswap.org/network',
  Opportunity_BASEURL: 'https://demo-api.crowdswap.org/opportunity',
  SWAP_BASEURL: 'https://demo-api.crowdswap.org/swap',
  AFFILIATE_BASEURL: 'https://demo-api.crowdswap.org/affiliate',
  ExchangeInfoUrl: 'https://info.crowdswap.org/exchange-info',
  BaseLogoIconsUrl: 'https://asset.crowdswap.org/',
  PORTFOLIO_BASEURL: 'https://demo-api.crowdswap.org/portfolio',
  ADMIN_BASEURL: 'https://test-api.crowdswap.org/admin',
  SCANNER_BASEURL: 'https://scanner.crowdswap.org'
};
