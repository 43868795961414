<div class="flex justify-content-center container">
  <div
    class="w-full p-2 mt-4 rounded-md bg-white dark:bg-darkBlack relative"
  >
    <div class="flex space-x-2">
      <div class="relative bg-gray-200 rounded ml-2 p-2 px-3 w-1/2">
        <div>
          <div class="flex text-xl text-gray-700">
            <span>Liquidity</span>
          </div>
        </div>
        <div class="flex font-openSans-regular text-lg">
          <div class="flex">
            <div
              class="font-openSans-semiBold pl-2"
              [ngClass]="{ 'animate-pulse': loading }"
            >
              <span
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': loading
                }"
                >${{ dex.totalLiquidityInUsd }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="relative bg-gray-200 rounded ml-2 p-2 px-3 w-1/2">
        <div>
          <div class="flex text-xl text-gray-700" (click)="loading = !loading">
            <div><span>Volume 24H</span></div>
          </div>
        </div>
        <div class="flex font-openSans-regular text-lg">
          <div class="flex">
            <div
              class="font-openSans-semiBold pl-2"
              [ngClass]="{ 'animate-pulse': loading }"
            >
              <span
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': loading
                }"
                >${{ dex.total24hoursVolumeInUsd }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex pt-3 justify-center">
      <nz-table
        #pairsTable
        [nzData]="dex.pairs"
        [nzTotal]="dex.pairs.length"
        [nzShowPagination]="false"
        class="w-full no-text-wrap"
        [nzScroll]="{ x: '680px' }"
      >
        <thead>
          <tr>
            <th class="font-14 text-6b7280" [nzLeft]="true">Pair</th>
            <th class="font-14 text-6b7280">Network</th>
            <th class="font-14 text-6b7280 text-right">Volume 24H</th>
            <th class="font-14 text-6b7280 text-right">Liquidity</th>
          </tr>
        </thead>

        <tbody>
          <ng-container>
            <ng-container *ngFor="let item of dex.pairs">
              <tr *ngIf="!loading">
                <td [nzLeft]="true">
                  <div class="d-flex text-374151 align-items-center">
                    <div
                      id="divFromPairName"
                      class="font-openSans-semiBold font-w-500 ml-2 font-14"
                    >
                      {{ item["name"] }}
                    </div>
                  </div>
                </td>

                <td>
                  <div
                    class="d-flex align-items-center  font-14"
                  >
                    <div class="font-bold font-openSans-bold font-14">
                      {{ item["networkName"] }}
                    </div>
                  </div>
                </td>

                <td>
                  <div
                    class="d-flex align-items-center justify-content-end font-14"
                  >
                    <div class="font-bold font-openSans-bold font-14">
                      ${{ item["24hoursVolumeInUsd"] }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-right justify-content-end">
                    ${{ item["liquidityInUsd"] }}
                  </div>
                </td>
              </tr>

              <tr *ngIf="loading">
                <td *ngFor="let item of [1, 2, 3]">
                  <nz-skeleton-element
                    nzType="button"
                    [nzActive]="true"
                    [nzSize]="'small'"
                  ></nz-skeleton-element>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>
