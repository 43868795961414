<app-dialog class="w-20" [closable]="false">
  <div class="flex justify-start items-center mb-4">
    <div class="cursor-pointer" (click)="closeDialog()">
      <img
        *ngIf="!data?.isDarkMode"
        src="assets/media/icons/back-logo-black.png"
        height="14"
        width="16"
      />
      <img
        *ngIf="data?.isDarkMode"
        src="assets/media/icons/back-logo-white.png"
        height="14"
        width="16"
      />
    </div>
    <div class="ml-8 mx-sm-auto">
      <label class="text-sm font-openSans-regular dark:text-white">
        Transaction Settings
      </label>
    </div>
    <div class="absolute right-5 mr-2">
      <label
        class="text-sm font-openSans-semiBold cursor-pointer dark:text-white"
        (click)="resetSettings()"
      >
        Reset
      </label>
    </div>
  </div>
  <!--  <div>-->
  <!--    <label class="text-sm text-gray-400"> Unit </label>-->
  <!--  </div>-->
  <!--  <div *ngIf="!unitTokenDollar" class="flex justify-start mb-4">-->
  <!--    <button-->
  <!--      aria-expanded="true"-->
  <!--      aria-haspopup="true"-->
  <!--      class="inline-flex justify-center w-full rounded-md border border-gray-300 dark:border-darkBlueButton shadow-sm bg-gray-300 dark:bg-dialogBackground text-sm font-medium text-gray-700 dark:text-white hover:bg-gray-200"-->
  <!--      type="button"-->
  <!--    >-->
  <!--      <span class="w-1/2 py-2" (click)="setUnitTokenDollar(true)">Token</span>-->
  <!--      <label-->
  <!--        class="inline-flex justify-center w-1/2 rounded-md border border-gray-300 shadow-sm py-2 bg-white dark:bg-darkBlueButton text-sm font-medium text-gray-700 dark:text-white in-button cursor-pointer"-->
  <!--      >-->
  <!--        <span class="w-1/2">Dollar</span>-->
  <!--      </label>-->
  <!--    </button>-->
  <!--  </div>-->
  <!--  <div *ngIf="unitTokenDollar" class="flex justify-start mb-4">-->
  <!--    <button-->
  <!--      aria-expanded="true"-->
  <!--      aria-haspopup="true"-->
  <!--      class="inline-flex justify-center w-full rounded-md border border-gray-300 dark:border-darkBlueButton shadow-sm bg-gray-300 dark:bg-dialogBackground text-sm font-medium text-gray-700 dark:text-white hover:bg-gray-200"-->
  <!--      type="button"-->
  <!--    >-->
  <!--      <label-->
  <!--        class="inline-flex justify-center w-1/2 rounded-md border border-gray-300 shadow-sm py-2 bg-white dark:bg-darkBlueButton text-sm font-medium text-gray-700 dark:text-white in-button cursor-pointer"-->
  <!--      >-->
  <!--        <span class="w-1/2">Token</span>-->
  <!--      </label>-->
  <!--      <span class="w-1/2 py-2" (click)="setUnitTokenDollar(false)">Dollar</span>-->
  <!--    </button>-->
  <!--  </div>-->

  <div class="mb-3">
    <div class="flex justify-start">
      <div>
        <label class="text-sm text-gray-400">
          <b>Swap</b> slippage ({{ defaultSlippage }}-49)
        </label>
      </div>
      <div class="right-5 ml-1 mt-1">
        <!--<a href="https://crowdswap.org/faq/#what-is-crowdswap-beta-phase" target="_blank">
        <img src="assets/media/icons/info-logo.svg" height="14" width="14">
      </a>-->
      </div>
    </div>
    <div class="flex justify-start">
      <div
        class="w-8/12 md:w-9/12 input-symbol-percent"
        [ngClass]="{
          'input-warning': warningSlippage && !transactionRedError,
          'input-error': warningSlippage && transactionRedError
        }"
      >
        <input
          [(ngModel)]="slippage"
          type="text"
          (keyup)="changeSlippage()"
          (focusout)="setSlippage()"
          class="lightgray border-gray-200 dark:border-2D2D2D py-2 px-1 rounded h-37 flex justify-center w-full font-openSans-regular text-sm items-center text-gray-400 hover:text-gray-600 dark:hover:text-gray-100 dark:bg-1E1E1E"
          [ngClass]="{ 'ps-4': warningSlippage }"
          maxlength="4"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
        />
      </div>
      <div class="absolute right-5 mr-1">
        <button
          (click)="setSwapAuto()"
          class="py-2 px-4 rounded h-37 flex justify-center text-white text-sm items-center blue-button hover:bg-blue-700"
        >
          <span>Auto</span>
        </button>
      </div>
    </div>
    <div *ngIf="warningSlippage" class="flex justify-start">
      <span class="text-base text-xs-2 error-message">
        It means you may receive {{ slippage }}% less
      </span>
    </div>
  </div>
  <div>
    <div class="flex justify-start">
      <div>
        <label class="text-sm text-gray-400">
          <b>Cross-Chain</b> slippage ({{ defaultCrossChainSlippage }}-49)
        </label>
      </div>
      <div class="right-5 ml-1 mt-1">
        <!--<a href="https://crowdswap.org/faq/#what-is-crowdswap-beta-phase" target="_blank">
        <img src="assets/media/icons/info-logo.svg" height="14" width="14">
      </a>-->
      </div>
    </div>
    <div class="flex justify-start">
      <div
        class="w-8/12 md:w-9/12 input-symbol-percent"
        [ngClass]="{
          'input-warning': warningCrossChainSlippage && !transactionRedError,
          'input-error': warningCrossChainSlippage && transactionRedError
        }"
      >
        <input
          [(ngModel)]="crossChainSlippage"
          type="text"
          (keyup)="changeCrossChainSlippage()"
          (focusout)="setCrossChainSlippage()"
          class="lightgray border-gray-200 dark:border-2D2D2D py-2 px-1 rounded h-37 flex justify-center w-full font-openSans-regular text-sm items-center text-gray-400 dark:bg-1E1E1E"
          [ngClass]="{ 'ps-4': warningCrossChainSlippage }"
          maxlength="4"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          disabled
        />
      </div>
      <div class="absolute right-5 mr-1">
        <button
          (click)="setCrossChainAuto()"
          class="py-2 px-4 rounded h-37 flex justify-center text-white text-sm items-center blue-button hover:bg-blue-700"
        >
          <span>Auto</span>
        </button>
      </div>
    </div>
    <div *ngIf="warningCrossChainSlippage" class="flex justify-start">
      <span
        *ngIf="warningCrossChainSlippage"
        class="text-base text-xs-2 error-message"
      >
        It means you may receive {{ crossChainSlippage }}% less
      </span>
    </div>
  </div>
  <div class="flex justify-start mt-3">
    <div>
      <label class="text-sm text-gray-400"> Transaction deadline </label>
    </div>
    <div class="right-5 ml-1 mt-1">
      <!--<a href="https://crowdswap.org/faq/#what-is-crowdswap-beta-phase" target="_blank">
        <img src="assets/media/icons/info-logo.svg" height="14" width="14">
      </a>-->
    </div>
  </div>
  <div class="flex justify-start mb-2">
    <div class="mb-3 w-1/3">
      <input
        [(ngModel)]="deadline"
        type="text"
        class="lightgray border-gray-200 dark:border-2D2D2D py-2 px-1 rounded h-37 flex justify-center w-full font-openSans-regular text-sm items-center text-gray-400 hover:text-gray-600 dark:hover:text-gray-100 dark:bg-1E1E1E"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
      />
    </div>
    <div class="ml-1 mt-1">
      <label
        class="text-sm font-openSans-regular text-xs-2 pt-2 dark:text-darkGrayText"
      >
        minutes
      </label>
    </div>
  </div>
  <!-- <div class="flex justify-start">
    <div class="mr-1">
      <label
        class="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in"
      >
        <input
          type="checkbox"
          (click)="expertModeDialog()"
          [(ngModel)]="expertMode"
          name="toggle"
          id="toggle"
          class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white appearance-none cursor-pointer"
        />
        <div
          for="toggle"
          class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
        ></div>
      </label>
    </div>
    <div>
      <label class="text-sm text-gray-400"> Toggle expert mode </label>
    </div>
    <div class="right-5 ml-1 mt-1"></div>
  </div> -->
</app-dialog>
