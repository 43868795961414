<div class="m-1 px-md-3 feedback-form">
  <section class="modal-section rounded-md feedback fixed z-40 overflow-y-auto">
    <div
      class="modal-content p-3 flex items-end"
      [ngClass]="{
        'bg-1E1E1E': isDarkMode,
        'bg-fff': !isDarkMode
      }"
    >
      <!--  Feedback form-->
      <div *ngIf="feedbackFormFlag" class="dialogContent">
        <!-- <div> -->
        <!--  Close cross-->
        <div>
          <div class="flex justify-end mb-4">
            <div
              class="absolute right-5 dark:text-textWhite"
              [ngClass]="{
                'text-textWhite': isDarkMode
              }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                (click)="close()"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div
          class="font-14 mb-2 pt-2"
          [ngClass]="{
            'text-textWhite': isDarkMode
          }"
        >
          How does CrowdSwap make you feel?
        </div>
        <div><br /></div>
        <div class="mb-3 moodicons">
          <!-- <div class="font-16 mb-2" [ngClass]="{
            'text-textWhite': isDarkMode
           }">How does CrowdSwap make you feel?</div> -->
          <div class="flex justify-content-between moodicons">
            <img
              [hidden]="feedbackData.feelLevel === Feedback.Awful"
              (click)="selectFeelLevel(Feedback.Awful)"
              [src]="
                'assets/media/icons/feedback/' +
                (isDarkMode ? 'awfulDark.svg' : 'awful.svg')
              "
              alt="Awful"
            />
            <img
              [hidden]="feedbackData.feelLevel !== Feedback.Awful"
              (click)="selectFeelLevel(Feedback.Awful)"
              src="assets/media/icons/feedback/awful-selected.svg"
              alt="Awful-selected"
            />
            <img
              [hidden]="feedbackData.feelLevel === Feedback.Bad"
              (click)="selectFeelLevel(Feedback.Bad)"
              [src]="
                'assets/media/icons/feedback/' +
                (isDarkMode ? 'badDark.svg' : 'bad.svg')
              "
              alt="Bad"
            />
            <img
              [hidden]="feedbackData.feelLevel !== Feedback.Bad"
              (click)="selectFeelLevel(Feedback.Bad)"
              src="assets/media/icons/feedback/bad-selected.svg"
              alt="Bad-selected"
            />
            <img
              [hidden]="feedbackData.feelLevel === Feedback.Meh"
              (click)="selectFeelLevel(Feedback.Meh)"
              [src]="
                'assets/media/icons/feedback/' +
                (isDarkMode ? 'mehDark.svg' : 'meh.svg')
              "
              alt="Meh"
            />
            <img
              [hidden]="feedbackData.feelLevel !== Feedback.Meh"
              (click)="selectFeelLevel(Feedback.Meh)"
              src="assets/media/icons/feedback/meh-selected.svg"
              alt="Meh-selected"
            />
            <img
              [hidden]="feedbackData.feelLevel === Feedback.Good"
              (click)="selectFeelLevel(Feedback.Good)"
              [src]="
                'assets/media/icons/feedback/' +
                (isDarkMode ? 'goodDark.svg' : 'good.svg')
              "
              alt="Good"
            />
            <img
              [hidden]="feedbackData.feelLevel !== Feedback.Good"
              (click)="selectFeelLevel(Feedback.Good)"
              src="assets/media/icons/feedback/good-selected.svg"
              alt="Good-selected"
            />
            <img
              [hidden]="feedbackData.feelLevel === Feedback.Great"
              (click)="selectFeelLevel(Feedback.Great)"
              [src]="
                'assets/media/icons/feedback/' +
                (isDarkMode ? 'greatDark.svg' : 'great.svg')
              "
              alt="Great"
            />
            <img
              [hidden]="feedbackData.feelLevel !== Feedback.Great"
              (click)="selectFeelLevel(Feedback.Great)"
              src="assets/media/icons/feedback/great-selected.svg"
              alt="Great-selected"
            />
          </div>
        </div>

        <div
          *ngIf="
            feedbackData.feelLevel < Feedback.Great &&
            feedbackData.feelLevel !== Feedback.NotSelected
          "
        >
          <div>
            <div class="mt-2 text-0066FF justify-center dark:text-white">
              <div
                class="font-12"
                [ngClass]="{
                  'text-white': isDarkMode
                }"
              >
                Write your feedback to help us improve the app and win CROWD
                (optional)
              </div>
              <div class="w-full">
                <textarea
                  [(ngModel)]="feedbackData.description"
                  [ngClass]="{
                    'border-0 bg-2D2D2D text-white placeholder-FFFFFF':
                      isDarkMode,
                    'border-323232 border-1': !isDarkMode
                  }"
                  type="text"
                  id="description"
                  name="description"
                  class="rounded-md w-full"
                  placeholder="Describe how you're feeling"
                ></textarea>
              </div>
            </div>
          </div>

          <div
            [ngClass]="
              isWrongEmail ? 'text-FF0000' : 'text-0066FF dark:text-3385FF'
            "
            class="flex-column mt-3"
          >
            <div
              class="font-12 mt-2"
              [ngClass]="{
                'text-textWhite': isDarkMode
              }"
            >
              <label>Email for update (optional)</label>
            </div>
            <input
              [(ngModel)]="feedbackData.email"
              id="Email"
              [ngClass]="{
                'bg-2D2D2D text-white placeholder-FFFFFF border-0': isDarkMode,
                'border border-1 placeholder-000000 border-323232': !isDarkMode
              }"
              (ngModelChange)="emailValidation()"
              class="p-2 rounded-md w-full"
              placeholder="Email for update"
              type="text"
            />
            <div *ngIf="isWrongEmail">Please enter a valid email address</div>
          </div>
          <!--  Confirm in wallet-->
        </div>
        <div
          *ngIf="feedbackData.feelLevel !== Feedback.NotSelected"
          class="flex justify-center mt-4"
        >
          <button
            (click)="submitFeedback()"
            class="blue-button w-full p-2 rounded-md"
          >
            Submit <span *ngIf="isWrongEmail">&nbsp;Anyway</span>
          </button>
        </div>
      </div>

      <!--  Success feedback-->
      <div
        *ngIf="!feedbackFormFlag && isSuccess"
        class="flex justify-center text center"
      >
        <div>
          <div class="flex justify-center">
            <svg
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5117_159109)">
                <path
                  d="M44.4825 11.0921L47.6325 14.2421L18.9675 42.9071L6.3675 30.3071L9.5175 27.1571L18.9675 36.6071L44.4825 11.0921ZM44.4825 4.72461L18.9675 30.2396L9.5175 20.7896L0 30.3071L18.9675 49.2746L54 14.2421L44.4825 4.72461Z"
                  fill="#1BD37F"
                />
              </g>
              <defs>
                <clipPath id="clip0_5117_159109">
                  <rect width="54" height="54" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div
            class="font-20 flex justify-center font-bold text-center"
            [ngClass]="{
              'text-white': isDarkMode
            }"
          >
            Thank you. You've earned 50 CROWD. <br class="d-md-none" />
          </div>
          <div
            class="font-12 font-openSans-bold line-height-18 flex justify-center text-center"
            [ngClass]="{
              'text-white': isDarkMode
            }"
          >
            <p><br /></p>
          </div>
          <div
            class="font-12 font-openSans-bold line-height-18 flex justify-center text-center"
            [ngClass]="{
              'text-white': isDarkMode
            }"
          >
            <p>Your reward will be sent to your wallet within 4 weeks.</p>
          </div>
          <div
            class="font-12 font-openSans-bold line-height-18 flex justify-center text-center"
            [ngClass]="{
              'text-white': isDarkMode
            }"
          >
            <p><br /></p>
          </div>
          <div
            class="font-14 mb-2 flex justify-center text-center"
            [ngClass]="{
              'text-white': isDarkMode
            }"
          >
            We appreciate your feedback and will update you on the results
            shortly.
          </div>
          <div class="flex justify-center mt-4">
            <button (click)="close()" class="blue-button w-full p-2 rounded-md">
              Done
            </button>
          </div>
        </div>
      </div>

      <!--  failed feedback-->
      <div
        *ngIf="!feedbackFormFlag && !isSuccess"
        class="flex justify-center text center"
      >
        <div
          [ngClass]="{
            'text-white': isDarkMode
          }"
        >
          <div class="flex">
            <div class="flex justify-end mb-4">
              <div class="absolute right-5 mr-1 dark:text-textWhite">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 cursor-pointer"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  (click)="close()"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </div>
            </div>
          </div>

          <div class="flex justify-center">
            <svg
              width="54"
              height="54"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5117_159244)">
                <path
                  d="M42.75 14.4225L39.5775 11.25L27 23.8275L14.4225 11.25L11.25 14.4225L23.8275 27L11.25 39.5775L14.4225 42.75L27 30.1725L39.5775 42.75L42.75 39.5775L30.1725 27L42.75 14.4225Z"
                  fill="#FF0000"
                />
              </g>
              <defs>
                <clipPath id="clip0_5117_159244">
                  <rect width="54" height="54" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div
            class="font-20 flex justify-center font-bold text-center"
            [ngClass]="{
              'text-white': isDarkMode
            }"
          >
            Something went wrong.
          </div>

          <div
            class="font-14 mb-2 flex justify-center text-center"
            [ngClass]="{
              'text-white': isDarkMode
            }"
          >
            Apologies, your feedback wasn't sent. Please try again in a moment.
          </div>
          <div class="flex justify-center mt-4">
            <button
              (click)="tryAgain()"
              class="blue-button w-full p-2 rounded-md"
            >
              Try again
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
