import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  commonConfig,
  Conversion,
  CrowdToken,
  TokensHolder,
  Networks,
  PriceService,
  NetworksByName,
  CrosschainFeeType
} from '@crowdswap/constant';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Constants } from '../../../../constants';
import {
  CrossChainSwapService,
  CrowdSwapService,
  DeviceType,
  NDDClientInfoServiceImpl,
  PortfolioService,
  StorageService,
  Web3Service
} from '../../../../services';
import { ModalService } from '../../../modal/modal.service';
import { Builder } from 'builder-pattern';
import {
  CrossAndSameChainEstimateTrade,
  CrossChainSwapState,
  DialogDataModel,
  SearchParamModel
} from '../../../../model';
import { BaseComponent } from '../../base.component';
import {
  TagManagerService,
  ThemeService,
  TokensService,
  UtilsService
} from '../../../../services';
import { CrossChainSelectOptionDialogComponent } from '../../../modal/dialogs/cross-chain-select-option-dialog/cross-chain-select-option-dialog.component';
import {
  CrossChainTokensHelper,
  TokensHelperInput
} from './cross-chain-tokens-helper';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import { NgxMaskService } from 'ngx-mask';
import { BigNumber } from 'ethers';
import { ExchangeType } from '../model/cross-chain-state.enum';
import { TradeType } from '@crowdswap/sdk';

@Component({
  selector: 'app-cross-chain-token-selection',
  templateUrl: './cross-chain-token-selection.component.html',
  styleUrls: ['./cross-chain-token-selection.component.scss']
})
export class CrossChainTokenSelectionComponent
  extends BaseComponent
  implements OnInit
{
  tokenList: CrowdToken[] = [];
  fromTokenList: CrowdToken[] = [];
  toTokenList: CrowdToken[] = [];
  fromToken!: CrowdToken;
  toToken!: CrowdToken;
  fromBalance: any;
  toBalance: any;
  swapSearchValue = '0';
  slippage = CrowdSwapService.SLIPPAGE;
  crossChainSlippage = commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE;
  deadline = CrowdSwapService.DEADLINE;
  swapAmount = 1;
  swapAmountOut: string | undefined = '11111111';
  amountOutUSDT: string | undefined = '1111111';
  totalDestinationPaid: string | undefined = '0';
  swapAmountInPerAmountOutRatio: string | undefined = '';
  swapAmountOutPerAmountInRatio: string | undefined = '';
  searchNotifier = new Subject<number>();
  swapAmountOutChangeNotifier = new Subject<string | undefined>();
  _estimation: CrossAndSameChainEstimateTrade | undefined = undefined;
  dialogOpened: boolean = false;
  loadingAmountOut: boolean = true;
  isSwitchTokenClicked: boolean = false;

  public isSelected: boolean;
  public isOpenSrcTokenSelection: boolean;
  public Networks = Networks;
  public CrossChainSwapState = CrossChainSwapState;

  private readonly hasSevenDecimalLength = /^\d+(\.\d{7,})$/;

  public network = {
    from: {
      toggle: false,
      selectedChainId: '137',
      availableNetworks: ['']
    },
    to: {
      toggle: false,
      selectedChainId: '137',
      availableNetworks: ['']
    }
  };

  private tokenNoCrossChainList: ((
    fromToken: CrowdToken,
    toToken: CrowdToken
  ) => boolean)[] = [
    (fromToken: CrowdToken, toToken: CrowdToken) =>
      fromToken.symbol === 'TMNG' || toToken.symbol === 'TMNG',
    (fromToken: CrowdToken, toToken: CrowdToken) =>
      toToken.chainId !== Networks.POLYGON_MAINNET &&
      toToken.symbol === 'CROWD' &&
      fromToken.symbol !== 'CROWD',
    (fromToken: CrowdToken, toToken: CrowdToken) => fromToken.symbol === 'EDAT'
  ];

  public crosschainFeeType = CrosschainFeeType;
  public unusualFeeTooltip: boolean = false;
  public lostPercentage: number | undefined;
  public ExchangeType = ExchangeType;
  public limitAmount = 0;
  public limitAmountDifference = 0;
  public limitFrom = true;
  public customeLimit = false;
  public marketPriceFromAmount = 0;
  public marketPriceToAmount = 0;

  @Output()
  onPairSelected = new EventEmitter<SearchParamModel>();

  @Output()
  stopRefresh = new EventEmitter();

  @Input()
  set estimation(estimation: CrossAndSameChainEstimateTrade | undefined) {
    this._estimation = estimation;

    this.totalDestinationPaid = estimation?.totalPaidInUSDT;
    this.swapAmountInPerAmountOutRatio = estimation?.amountInPerAmountOutRatio;
    this.swapAmountOutPerAmountInRatio = estimation?.amountOutPerAmountInRatio;
    this.loadingAmountOut = estimation ? estimation.loading : true;

    this.swapSearchValue = estimation?.loading
      ? '0'
      : estimation?.amountInInUSDTToDisplay &&
        estimation?.amountInInUSDTToDisplay !== '0'
      ? estimation?.amountInInUSDTToDisplay
      : this.fromToken.price
      ? formatNumber(
          parseFloat(Conversion.adjustFraction(this.fromToken.price, 8)) *
            this.swapAmount,
          NumberType.FiatTokenPrice
        )
      : 'NaN';

    if (this._estimation && !estimation?.loading) {
      this.swapAmount =
        this.calculateSwapAmountIfExactOutputNotLoading(this._estimation) ??
        this.swapAmount;
    }

    this.amountOutUSDT = estimation?.amountOutInUSDTToDisplay;
    if (
      !estimation?.loading &&
      // if is market mode
      (this.realExchangeType === ExchangeType.Market ||
        // if amount out is null or empty
        !this.swapAmountOut ||
        isNaN(
          parseInt(this.swapAmountOut?.toString()?.replaceAll(/\s/g, '') ?? '')
        ) ||
        (this.swapAmountOut && !this.swapAmountOut.toString().match(/\d/)))
    ) {
      this.swapAmountOut = this.maskService.applyMask(
        estimation?.amountOutDisplay ?? '',
        'separator'
      );

      if (
        estimation?.crossChainSwapState !== CrossChainSwapState.NotFound &&
        !estimation?.loading &&
        estimation?.amountOut
      ) {
        const amountOut = Conversion.adjustFraction(
          Conversion.convertStringFromDecimal(
            estimation.amountOut.toString(),
            estimation.toToken.decimals
          ),
          6
        );

        this.swapAmountOut = this.maskService.applyMask(amountOut, 'separator');
      }
    }

    if (!estimation?.loading) {
      this.calculateLimitAmount(true);
    }

    this.lostPercentage = estimation?.costAnalyze?.percent;
  }

  get estimation() {
    return this._estimation;
  }

  searchParam: SearchParamModel | undefined;
  @Input() set parentSearchParam(data: SearchParamModel | undefined) {
    if (!data) {
      return;
    }

    this.searchParam = data;

    if (
      data.exchangeType !== this.searchParam?.exchangeType &&
      data.exchangeType === ExchangeType.Market
    ) {
      this.setToMarket();
    }

    if (
      this.searchParam?.exchangeType === ExchangeType.Market &&
      data.exchangeType === ExchangeType.Limit &&
      (this.searchParam?.fromToken.symbol !== data.fromToken.symbol ||
        this.searchParam?.fromToken.chainId !== data.fromToken.chainId ||
        this.searchParam?.toToken.symbol !== data.toToken.symbol ||
        this.searchParam?.toToken.chainId !== data.toToken.chainId)
    ) {
      this.selectTokens(data.fromToken, data.toToken, true, false);
    }
  }

  exchangeType: ExchangeType = ExchangeType.Market;
  realExchangeType: ExchangeType = ExchangeType.Market;
  @Input() set _exchangeType(type: ExchangeType) {
    if (isNaN(type)) {
      return;
    }

    this.exchangeType = type;
    if (
      type === ExchangeType.Market &&
      this.realExchangeType === ExchangeType.Limit
    ) {
      this.realExchangeType = ExchangeType.Market;
      this.resetSearch();
    }
  }

  private cacheApproximateOperatingExpense = {};
  private CACHE_APPROXIMATE_DEADLINE = 5; //second //2 * 60;

  private sourceTransactionGasLimit = {
    10: '2000000',
    56: '800000',
    137: '900000',
    43114: '700000',
    42161: '4906862'
  };

  constructor(
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    public priceService: PriceService,
    public activatedRoute: ActivatedRoute,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    private portfolioService: PortfolioService,
    private crossChainSelectOptionDialogComponentModalService: ModalService<CrossChainSelectOptionDialogComponent>,
    private tokensService: TokensService,
    private crossChainSwapService: CrossChainSwapService,
    private maskService: NgxMaskService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    private utilsService: UtilsService,
    private storageService: StorageService
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
    this.isOpenSrcTokenSelection = false;
    this.isSelected = false;
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit(undefined, undefined, async (address) => {
      if (this.fromToken && this.toToken) {
        await this.changeTokens(false, 0);
        if (this.dialogOpened) {
          await this.crossChainSelectOptionDialogComponentModalService.close();
        }
      }
    });

    this.portfolioService.tokenBalanceChangeSubject.subscribe(
      async (crowdTokens: CrowdToken[]) => {
        if (
          this.fromToken &&
          this.toToken &&
          crowdTokens.some((crowdToken) => {
            return (
              crowdToken.symbol === this.fromToken.symbol ||
              crowdToken.symbol === this.toToken.symbol
            );
          })
        ) {
          await this.changeTokens(false, 0);
        }
      }
    );

    this.searchNotifier
      .pipe(
        debounceTime(CrowdSwapService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.realExchangeType = ExchangeType.Market;

        this.changeSwapAmount(value.toString());
      });

    this.swapAmountOutChangeNotifier
      .pipe(
        debounceTime(CrowdSwapService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async (value) => {
        if (value && this.exchangeType === ExchangeType.Limit) {
          this.calculateLimitAmount();
          this.realExchangeType = ExchangeType.Limit;
          await this.resetSearch();
        }
      });

    this.updateState(this.web3Service.getCurrentMainChainId());
    await this.selectTokens(this.fromToken, this.toToken);
  }

  async resetSearch(isMax: boolean = false) {
    if (this.swapAmount === 0) {
      return;
    }
    await this.updateAmountInUSDT();
    this.loadingAmountOut = true;

    if (isMax) {
      const balance = await this.web3Service.getBalance(
        this.fromToken,
        this.web3Service.getNetworkProvider(this.fromToken.chainId)
      );

      this.fromToken.balance = balance
        ? Conversion.convertStringFromDecimal(
            balance.toString(),
            this.fromToken.decimals
          )?.toString()
        : this.swapAmount.toString();
    }

    const swapParameter: SearchParamModel = Builder<SearchParamModel>()
      .fromToken(this.fromToken)
      .toToken(this.toToken)
      .swapValue(
        isMax && this.estimation && this.isEstimationCrossChain(this.estimation)
          ? this.fromToken.balance
          : this.swapAmount.toString()
      )
      .swapAmountOut(this.swapAmountOut?.toString())
      .exchangeType(this.realExchangeType)
      .slippage(this.slippage)
      .deadline(this.deadline)
      .lastUserChangeTime(Date.now())
      .isMax(isMax)
      .build();

    this.onPairSelected.emit(swapParameter);
  }

  public setToMarket() {
    this.realExchangeType = ExchangeType.Market;
    this.resetSearch();
  }

  notifySearch(event) {
    this.setting.showMaxTooltip = false;

    this.searchNotifier.next(event);
  }

  async changeSwapAmount(amount: string, isMax: boolean = false) {
    this.swapAmount = parseFloat(amount);

    if (this.hasSevenDecimalLength.test(amount.toString())) {
      this.swapAmount = parseFloat(Conversion.adjustFraction(amount, 7));
    }

    await this.resetSearch(isMax);
  }

  async switchTokens() {
    try {
      if (!this.switchTokenVisibility()) {
        return;
      }

      UtilsService._clearQueryString();

      const tempNetwork = this.network.from;
      this.network.from = this.network.to;
      this.network.to = tempNetwork;

      const tempToken = this.fromToken;
      this.fromToken = this.toToken;
      this.toToken = tempToken;

      const tempList = this.fromTokenList;
      this.fromTokenList = this.toTokenList;
      this.toTokenList = tempList;
      this.isSwitchTokenClicked = true;

      this.swapAmount = parseFloat(
        this.swapAmountOut
          ? this.swapAmountOut.toString().replace(/\s/g, '')
          : '1'
      );

      // this.web3Service.currentNetworkChangeSubject.next(
      //   new CurrentNetwork(this.fromToken.chainId, this.fromToken, this.toToken)
      // );

      await this.updateAmountInUSDT();
      await this.changeTokens(true);
    } catch (e) {
      console.log(e);
    }
  }

  async setMaxBalance() {
    let amount = await this.web3Service.getBalance(
      this.fromToken,
      this.web3Service.getNetworkProvider(this.fromToken.chainId)
    );
    if (amount) {
      if (
        this.fromToken.chainId === this.toToken.chainId &&
        TokensHolder.isBaseToken(this.fromToken.chainId, this.fromToken.address)
      ) {
        amount = amount.sub(
          Conversion.convertStringToDecimal(
            Constants.REDUCE_MAX_AMOUNT_IN.toString(),
            this.fromToken.decimals
          )
        );
      }
      if (amount.isNegative()) {
        amount = BigNumber.from(0);
      }
      this.setting.showMaxTooltip = amount.gt(0);
      let maxAmountIn = Conversion.convertStringFromDecimal(
        amount.toString(),
        this.fromToken.decimals
      );

      await this.changeSwapAmount(
        Conversion.adjustFraction(maxAmountIn, 7),
        true
      );
    }
  }

  async changeTokens(callUpdate = false, tokenType = 0) {
    if (tokenType <= 0) {
      this.fromBalance = await this.getTokenBalance(this.fromToken);
    }
    if (tokenType >= 0) {
      this.toBalance = await this.getTokenBalance(this.toToken);
    }
    if (callUpdate) {
      await this.changeSwapAmount(this.swapAmount.toString());
    }
    this.ref.detectChanges();
  }

  async updateAmountInUSDT(): Promise<any> {
    if (!this.fromToken || !this.toToken) return;

    this.fromToken.price = await this.priceService.getPrice(
      this.fromToken,
      this.web3Service.getNetworkProvider(this.fromToken.chainId)
    );
    this.toToken.price = await this.priceService.getPrice(
      this.toToken,
      this.web3Service.getNetworkProvider(this.toToken.chainId)
    );
    this.swapSearchValue = this._calculateSwapSearchValue();

    this.ref.detectChanges();
  }

  private _calculateSwapSearchValue(): string {
    if (this.fromToken.price) {
      return formatNumber(
        parseFloat(Conversion.adjustFraction(this.fromToken.price, 8)) *
          this.swapAmount,
        NumberType.FiatTokenPrice
      );
    } else {
      return 'NaN';
    }
  }

  async getTokenBalance(token: CrowdToken) {
    try {
      const tokenBalance = await this.web3Service.getBalance(
        token,
        this.web3Service.getNetworkProvider(token.chainId)
      );
      if (tokenBalance) {
        return Conversion.adjustFraction(
          Conversion.convertStringFromDecimal(
            tokenBalance.toString(),
            token.decimals
          ),
          3
        );
      }
    } catch (e) {
      console.log(
        `An error occurred while getting balance of ${token.symbol} token on ${token.chainId}`,
        e
      );
    }
    return null;
  }

  /**
   * When select option notify items this function get it
   */
  public async selectedSourceToken(event: any) {
    this.fromToken = event;
    this.network.from.selectedChainId = event.chainId;
    await this.changeTokens(true, -1);
    UtilsService._clearQueryString();
  }

  private checkUrlParams(sourceChainId, targetChainId) {
    let tokenIn, tokenOut;
    this.activatedRoute.queryParams.forEach((params) => {
      const sourceUrlToken = params['source'] && params['source'].toUpperCase();
      const targetUrlToken = params['target'] && params['target'].toUpperCase();
      if (
        !TokensHolder.ObservableTokenListBySymbol[Networks[sourceChainId]][
          sourceUrlToken
        ] ||
        !TokensHolder.ObservableTokenListBySymbol[Networks[targetChainId]][
          targetUrlToken
        ] ||
        sourceUrlToken === targetUrlToken ||
        sourceUrlToken === 'W' + targetUrlToken ||
        'W' + sourceUrlToken === targetUrlToken
      ) {
        tokenIn = undefined;
        tokenOut = undefined;
      }

      tokenIn =
        TokensHolder.ObservableTokenListBySymbol[Networks[sourceChainId]][
          sourceUrlToken
        ];
      tokenOut =
        TokensHolder.ObservableTokenListBySymbol[Networks[targetChainId]][
          targetUrlToken
        ];
    });
    return { tokenIn, tokenOut };
  }

  toggleNetwork(network: {
    availableNetworks: string[];
    toggle: boolean;
    selectedChainId: string;
  }) {
    network.toggle = !network.toggle;
  }

  close(network: {
    availableNetworks: string[];
    toggle: boolean;
    selectedChainId: string;
  }) {
    network.toggle = false;
  }

  async selectTokens(
    fromToken: CrowdToken | undefined,
    toToken: CrowdToken | undefined,
    refreshSearch: boolean = true,
    checkCurrentChainId: boolean = true
  ) {
    if (this.web3Service.getCurrentChainId() > 0 && checkCurrentChainId) {
      let { tokenIn, tokenOut } = await this.checkUrlParams(
        this.web3Service.getCurrentChainId(),
        this.web3Service.getCurrentChainId()
      );
      fromToken = tokenIn;
      toToken = tokenOut;
    }

    if (fromToken) {
      fromToken.price = Conversion.replaceSeparator(
        Conversion.adjustFraction(
          await this.priceService.getPrice(
            fromToken,
            this.web3Service.getNetworkProvider(fromToken.chainId)
          )
        )
      );

      this.fromToken = fromToken;
      this.network.from.selectedChainId = this.fromToken.chainId.toString();
      this.network.from.toggle = false;

      this.fromTokenList = this.getTokens(
        undefined,
        this.toToken,
        this.fromToken.chainId
      );
    }

    if (toToken) {
      toToken.price = await this.priceService.getPrice(
        toToken,
        this.web3Service.getNetworkProvider(toToken.chainId)
      );

      this.toToken = toToken;
      this.network.to.selectedChainId = this.toToken.chainId.toString();
      this.network.to.toggle = false;

      this.toTokenList = this.getTokens(
        this.fromToken,
        undefined,
        this.toToken.chainId
      );
    }

    if (this.toToken) {
      this.network.from.availableNetworks = this.excludeNetwork(
        this.toToken.chainId.toString()
      );
    }

    if (this.fromToken) {
      this.network.to.availableNetworks = this.excludeNetwork(
        this.fromToken.chainId.toString()
      );
    }

    this.swapAmount = parseFloat(this.searchParam?.swapValue ?? '1');
    if (refreshSearch) {
      await this.resetSearch();
    }
    await this.updateAmountInUSDT();
    await this.changeTokens(false);
    // this.ref.detectChanges();
  }

  private excludeNetwork(toBeExcludedChainId: string): string[] {
    // return Constants.CROSSCHAIN_NETWORKS_BY_ID;
    return Object.keys(Constants.CROSSCHAIN_NETWORKS_BY_ID).filter(
      (chainId: string) => {
        return chainId;
      }
    );
  }

  async openSelectOptionDialog(
    fromToken: CrowdToken,
    toToken: CrowdToken,
    type: 'from' | 'to'
  ) {
    if (this.dialogOpened) {
      return;
    }

    const allTokens = this.tokensService.getAllTokens();

    let limitTokens;
    if (this.exchangeType === ExchangeType.Limit) {
      limitTokens = this.tokensService.LimitOrderTokensList;
    }

    const helperInput: TokensHelperInput = {
      allTokens: allTokens,
      fromToken: fromToken,
      toToken: toToken,
      type: type,
      tradeType: this.getTradeType(),
      mode: this.exchangeType,
      limitTokens: limitTokens
    };

    const validTokens = CrossChainTokensHelper.FilterViewTokens(helperInput);

    this.dialogOpened = true;
    let currentToken = type == 'from' ? fromToken : toToken;

    const data: DialogDataModel = {
      filteredTokenList: validTokens,
      toggle: true,
      chainId: -1,
      className: 'cross-chain-token-selection-dialog',
      currentNetwork: currentToken.chainId.toString(),
      currentTokenSymbol: currentToken.symbol,
      title: type,
      destValue: type == 'from' ? toToken : fromToken,
      mode: this.exchangeType
    };

    let selectOptionDialogRef =
      await this.crossChainSelectOptionDialogComponentModalService.open(
        CrossChainSelectOptionDialogComponent,
        data
      );

    const sub: Subscription = (<CrossChainSelectOptionDialogComponent>(
      (<any>selectOptionDialogRef!.instance)
    )).confirmed.subscribe(async (token: CrowdToken) => {
      await this.crossChainSelectOptionDialogComponentModalService.close();

      this.dialogOpened = false;

      if (token) {
        switch (type) {
          case 'from': {
            if (
              token.chainId === NetworksByName.ZKSYNC &&
              token.chainId !== this.toToken.chainId &&
              token.symbol !== 'CROWD'
            ) {
              await this.selectToNetwork(
                NetworksByName.ZKSYNC.toString(),
                false
              );
            }
            this.toTokenList = this.getTokens(
              token,
              undefined,
              this.toToken.chainId
            );
            await this.selectedSourceToken(token);

            break;
          }

          case 'to': {
            this.toToken = token;
            this.network.to.selectedChainId = token.chainId.toString();
            await this.changeTokens(true, 1);

            UtilsService._clearQueryString();

            this.fromTokenList = this.getTokens(
              undefined,
              this.toToken,
              this.fromToken.chainId
            );
            break;
          }
        }

        // if is swap remomve limit type
        if (
          !this.utilsService.isSwapParamCrossChain(
            this.fromToken,
            this.toToken
          ) ||
          this.utilsService.isBridge(this.fromToken, this.toToken)
        ) {
          this.exchangeType = ExchangeType.Market;
        }
      }
    });

    selectOptionDialogRef!.onDestroy(() => {
      this.dialogOpened = false;
      sub.unsubscribe();
    });
  }

  /**
   * Returns a token list after removing duplicate tokens and excluding noCrossChain token list
   * If the fromToken is passed, toTokenList will be returned based on selectedChainId
   * If the toToken is passed, fromTokenList will be returned based on selectedChainId
   * @param fromToken
   * @param toToken
   * @param selectedChainId
   * @returns
   */
  private getTokens(
    fromToken: CrowdToken | undefined,
    toToken: CrowdToken | undefined,
    selectedChainId: number
  ) {
    const chainId =
      (fromToken && fromToken.chainId) || (toToken && toToken.chainId);
    if (!chainId) {
      throw new Error(
        'CrossChainTokenSelection component: Neither fromToken nor toToken is provided'
      );
    }

    //Get all removed duplicate tokens
    const duplicateRemovedList = this.tokensService.getAllTokens();

    let allTokens: CrowdToken[] = [];
    duplicateRemovedList.forEach((token) => {
      let exclude = false;
      for (const tokenNoCrossChain of this.tokenNoCrossChainList) {
        if (typeof tokenNoCrossChain === 'function') {
          exclude = tokenNoCrossChain(fromToken || token, toToken || token);
          if (exclude) {
            break;
          }
        }
      }
      if (!exclude) {
        allTokens.push(token);
      }
    });
    return allTokens;
  }

  public async selectFromNetwork(
    chainId: string,
    refreshSearch: boolean = true
  ) {
    const defaultFromToken: CrowdToken =
      TokensHolder.ObservableTokenListBySymbol[Networks[chainId]][
        Constants.CROSSCHAIN_DEFAULT_PAIRS[+chainId].fromToken
      ];
    try {
      await this.selectTokens(defaultFromToken, undefined, refreshSearch);
      UtilsService._clearQueryString();
      this.web3Service.mismatchNetworkSubject.next(false);
    } catch (e) {
      console.log(e);
    }
  }

  public async selectToNetwork(chainId: string, refreshSearch: boolean = true) {
    const defaultToToken: CrowdToken =
      TokensHolder.ObservableTokenListBySymbol[Networks[chainId]][
        Constants.CROSSCHAIN_DEFAULT_PAIRS[+chainId].toToken
      ];
    try {
      await this.selectTokens(undefined, defaultToToken, refreshSearch);
      UtilsService._clearQueryString();
    } catch (e) {
      console.log(e);
    }
  }

  updateState(chainId) {
    if (!this.isSwitchTokenClicked) {
      [this.fromToken, this.toToken] = this.searchParam
        ? [this.searchParam.fromToken, this.searchParam.toToken]
        : CrossChainTokenSelectionComponent._getDefaultTokens(chainId);

      this.network.from.selectedChainId =
        this.searchParam && this.searchParam.fromToken?.chainId
          ? this.searchParam.fromToken.chainId.toString()
          : chainId;

      this.network.from.toggle = false;

      this.network.to.selectedChainId =
        this.searchParam && this.searchParam.toToken?.chainId
          ? this.searchParam.toToken.chainId.toString()
          : Constants.CROSSCHAIN_INITIAL_CHAIN_ID.toString();

      this.network.to.toggle = false;
    }

    this.isSwitchTokenClicked = false;
  }

  async updateCacheApproximateOperatingExpense(
    fromToken: CrowdToken,
    toToken: CrowdToken
  ): Promise<number> {
    if (
      this.cacheApproximateOperatingExpense[fromToken.chainId] &&
      this.cacheApproximateOperatingExpense[fromToken.chainId][
        fromToken.address
      ] &&
      this.cacheApproximateOperatingExpense[fromToken.chainId][
        fromToken.address
      ][toToken.chainId] &&
      this.cacheApproximateOperatingExpense[fromToken.chainId][
        fromToken.address
      ][toToken.chainId][toToken.address] &&
      this.cacheApproximateOperatingExpense[fromToken.chainId][
        fromToken.address
      ][toToken.chainId][toToken.address].deadline >
        parseInt((Date.now() / 1000).toFixed())
    ) {
      return this.cacheApproximateOperatingExpense[fromToken.chainId][
        fromToken.address
      ][toToken.chainId][toToken.address].amount;
    }

    const params = {
      fromToken: fromToken,
      toToken: toToken
    };

    const result = (
      await this.crossChainSwapService.getApproximateOperatingExpenses(params)
    )?.body;

    let amountInWei = result?.['operatingExpenses'];
    if (this.isCoin(fromToken)) {
      amountInWei = BigNumber.from(amountInWei)
        .add(result?.['fixFee'])
        .toString();
    }

    let amount = +Conversion.convertStringFromDecimal(
      amountInWei,
      params.fromToken.decimals
    );

    if (TokensHolder.isBaseToken(fromToken.chainId, fromToken.address)) {
      amount += await this.getCrosschainTransactionFee(fromToken);
    }
    const approximateOperatingExpense = {
      amount: amount,
      deadline:
        parseInt((Date.now() / 1000).toFixed()) +
        this.CACHE_APPROXIMATE_DEADLINE
    };

    this.cacheApproximateOperatingExpense = {
      ...this.cacheApproximateOperatingExpense,
      [fromToken.chainId]: {
        [fromToken.address]: {
          [toToken.chainId]: { [toToken.address]: approximateOperatingExpense }
        }
      }
    };

    return amount;
  }

  async getCrosschainTransactionFee(fromToken: CrowdToken) {
    if (this.sourceTransactionGasLimit[fromToken.chainId]) {
      const gasPrice = await this.web3Service.getGasPrice(
        this.web3Service.getNetworkProvider(fromToken.chainId)
      );
      return +Conversion.convertStringFromDecimal(
        BigNumber.from(gasPrice)
          .mul(this.sourceTransactionGasLimit[fromToken.chainId])
          .toString()
      );
    }
    return Constants.REDUCE_MAX_AMOUNT_IN;
  }

  // limit order methods
  public changeLimitType(limitFrom: boolean) {
    this.limitFrom = !limitFrom;
    this.calculateLimitAmount();
  }

  public calculateLimitAmount(fromEstimation?: boolean) {
    if (this.swapAmountOut && this.swapAmount) {
      const limitFromAmount = parseFloat(
        Conversion.adjustFraction(
          parseFloat(this.swapAmountOut.toString().replace(/\s/g, '')) /
            this.swapAmount,
          6
        )
      );

      const limitToAmount = parseFloat(
        Conversion.adjustFraction(
          this.swapAmount /
            parseFloat(this.swapAmountOut.toString().replace(/\s/g, '')),
          6
        )
      );

      if (
        this.estimation?.marketAmountOut &&
        this.estimation.marketAmountOutToDisplay
      ) {
        this.marketPriceFromAmount = parseFloat(
          Conversion.adjustFraction(
            parseFloat(this.estimation.marketAmountOutToDisplay) /
              this.swapAmount,
            6
          )
        );
        this.marketPriceToAmount = parseFloat(
          Conversion.adjustFraction(
            this.swapAmount /
              parseFloat(this.estimation.marketAmountOutToDisplay),
            6
          )
        );
      } else {
        this.marketPriceFromAmount = limitFromAmount;
        this.marketPriceToAmount = limitToAmount;
      }

      this.limitAmount = this.limitFrom ? limitFromAmount : limitToAmount;

      if (!fromEstimation) {
        this.stopRefresh.emit();
      }

      if (fromEstimation && this.searchParam)
        this.searchParam.limitAmountDifference = this.limitFrom
          ? parseFloat(
              (
                ((this.limitAmount - this.marketPriceFromAmount) * 100) /
                this.marketPriceFromAmount
              ).toFixed(2)
            )
          : parseFloat(
              (
                ((this.limitAmount - this.marketPriceToAmount) * 100) /
                this.marketPriceToAmount
              ).toFixed(2)
            );
    }
  }

  public limitAmountChanged(value: number) {
    if (this.limitFrom) {
      this.swapAmountOut = this.maskService.applyMask(
        Conversion.adjustFraction(value * this.swapAmount, 6),
        'separator'
      );

      if (this.searchParam)
        this.searchParam.limitAmountDifference = parseFloat(
          (
            ((this.limitAmount - this.marketPriceFromAmount) /
              this.marketPriceFromAmount) *
            100
          ).toFixed(2)
        );
    } else {
      this.swapAmountOut = this.maskService.applyMask(
        Conversion.adjustFraction(this.swapAmount / value, 6),
        'separator'
      );

      if (this.searchParam)
        this.searchParam.limitAmountDifference = parseFloat(
          (
            ((this.limitAmount - this.marketPriceToAmount) /
              this.marketPriceToAmount) *
            100
          ).toFixed(2)
        );
    }

    this.realExchangeType = ExchangeType.Limit;

    this.swapAmountOutChangeNotifier.next(this.swapAmountOut);
  }

  public switchTokenVisibility(): boolean {
    return (
      (this.exchangeType === ExchangeType.Limit &&
        this.toToken.symbol &&
        ['CROWD', 'TMNG'].indexOf(this.toToken.symbol) === -1) ||
      this.exchangeType === ExchangeType.Market
    );
  }

  protected calculateSwapAmountIfExactOutputNotLoading(
    estimation: CrossAndSameChainEstimateTrade
  ): number | undefined {
    if (this.isEstimationCrossChain(estimation)) {
      const amount =
        this.searchParam?.isMax && estimation.amountInDisplay
          ? parseFloat(estimation.amountInDisplay)
          : parseFloat(Conversion.adjustFraction(this.swapAmount, 6));

      if (this.searchParam) {
        this.searchParam.swapValue = amount.toString();
        this.storageService.setItemToLocalStorage(
          'searchParam',
          this.searchParam
        );
      }

      return amount;
    }
  }

  protected getTradeType(): TradeType {
    return TradeType.EXACT_INPUT;
  }
}
