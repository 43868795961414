<div class="absolute w-2/3 top-1/2 md:top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
  <div class="rounded-md shadow-md bg-white dark:bg-darkBlack dark:text-white space-y-3 p-4">
    <div *ngIf="notificationData.closable" class="absolute right-5 mr-1 dark:text-textWhite">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="onClose()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
    <div class="font-openSans-semiBold">{{ notificationData.title }}</div>
    <div>
      {{ notificationData.subTitle }}
    </div>
    <div class="flex justify-end">
      <button type="button" (click)="onClick()"
        class="rounded-sm px-2 py-1 blue-button dark:bg-darkBlueButton hover:bg-blue-700">
        <span>{{ notificationData.buttonTitle }}</span>
      </button>
    </div>
  </div>
</div>