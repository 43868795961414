<div class="m-2 push-notification">
  <section class="modal-section fixed z-40 overflow-y-auto">
    <div class="modal-content p-2 flex items-end text-center">
      <div
        class="modal-content-detail dark:bg-122a4e inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-lg transform max-width transition-all"
        (click)="$event.stopPropagation()"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="dark:bg-121212">
          <div class="p-2">
            <div class="absolute right-1 mr-1 mt-1 dark:text-textWhite">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                (click)="close()"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
            <div class="flex justify-center mb-2">
              <img src="assets/media/icons/reminder.svg" />
            </div>
            <div class="font-16 flex font-openSans-semiBold justify-center">
              Opportunity reminder
            </div>
            <div class="font-12 flex text-center justify-center">
              Would you like to see our latest investment opportunities by
              Allowing to send you notification?
            </div>
            <div
              class="flex justify-content-center align-items-center space-x-1"
            >
              <button
                class="blue-button rounded-sm mt-2 py-1 px-2"
                (click)="confirm()"
              >
                Allow
              </button>
            </div>

            <div
              class="mt-2 flex justify-center text-lg font-bold text-center dark:text-white"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
