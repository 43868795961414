<div
  class="w-full opportunity_page_items h-100"
  [ngClass]="{ 'p-2': selectedActionTab === undefined }"
>
  <div
    *ngIf="!detailView || !loading"
    class="w-full d-flex flex-column p-2 border-radius-6px border-2 h-100"
    [ngClass]="{
      'animate-pulse':
        loading && selectedTab === selectedOpportunityTab.InvestedOpportunities,
      'bg-1E1E1E border-2D2D2D': isDarkMode && itemsPage,
      'border-1E1E1E': isDarkMode && !itemsPage,
      'border-00000010': !isDarkMode
    }"
  >
    <div class="w-full d-flex flex-column">
      <div
        class="title-section w-full d-flex justify-content-between font-12 px-2"
        *ngIf="
          !(
            loading &&
            selectedTab === selectedOpportunityTab.InvestedOpportunities
          )
        "
      >
        <div
          *ngIf="!setting.isMobile || itemsPage"
          class="d-flex w-full align-items-center"
        >
          <div class="d-flex align-items-center scale-60 mx--14">
            <img
              class="z-1 rounded-full"
              [ngClass]="{ 'mr-2': !opportunity.tokenB }"
              [src]="
                baseLogoIconsUrl +
                opportunity.tokenA.symbol?.toLowerCase() +
                '.png'
              "
              (error)="updateUrl($event)"
            />
            <img
              class="rounded-full ml--10"
              *ngIf="opportunity.tokenB"
              [src]="
                baseLogoIconsUrl +
                opportunity.tokenB.symbol?.toLowerCase() +
                '.png'
              "
              (error)="updateUrl($event)"
            />
          </div>

          <div class="d-flex flex-column ml-8px">
            <div class="d-flex align-items-center">
              <div class="font-16 font-w-800">
                {{ opportunity.displayName }}
              </div>

              <div
                *ngIf="opportunity.type"
                class="rounded-full font-openSans-semiBold font-w-500 px-2 py-1 font-11 ml-1"
                [ngClass]="
                  opportunity.type === 'NEW'
                    ? 'text-000 bg-DEF7EC'
                    : 'text-fff bg-005374'
                "
              >
                {{ opportunity.type }}
              </div>
            </div>

            <div class="d-flex align-items-center py-1">
              <img
                width="16"
                *ngIf="
                  !(
                    loading &&
                    selectedTab === selectedOpportunityTab.InvestedOpportunities
                  )
                "
                class="rounded-full"
                [src]="web3Service.networkSpec[opportunity.chainId]?.coinIcon"
              />

              <span class="font-thin ml-1">
                {{ web3Service.networkSpec[opportunity.chainId]?.title }}
              </span>
            </div>
          </div>

          <div
            *ngIf="!itemsPage && false"
            class="absolute right-5 rounded p-1 mr-3 bg-262626"
          >
            <span class="p-1 text-sm text-FBD127B2">
              {{
                !opportunity.investorsCount || +opportunity.investorsCount < 100
                  ? "<100"
                  : opportunity.investorsCount
              }}
              Invests on this farm</span
            >
          </div>
        </div>

        <div
          *ngIf="
            opportunity.stopLoading &&
            !opportunity.isComingSoon &&
            opportunity.eligibleStartTime > currentTime &&
            currentTime < opportunity.startTime
          "
          class="d-none d-xl-block"
        >
          <ng-container *ngTemplateOutlet="eligibleBtn"></ng-container>
        </div>
      </div>

      <nz-skeleton-element
        nzType="button"
        [nzActive]="true"
        [nzSize]="'small'"
        *ngIf="
          loading &&
          selectedTab === selectedOpportunityTab.InvestedOpportunities
        "
      ></nz-skeleton-element>

      <div
        *ngIf="opportunity.lockedStaking && !itemsPage"
        class="font-12 font-w-800 mt-4 mb-2 text-000 dark:text-fff"
      >
        Staking Information
      </div>

      <div
        class="w-full align-items-center justify-content-between border-1px border-00000010 dark:border-00000012 border-radius-4px bg-F7F7F7 dark:bg-121212 text-000 dark:text-fff d-flex mt-3 flex-sm-row flex-column font-12"
      >
        <div
          class="d-flex align-items-center justify-content-between flex-basis-100% w-full flex-grow-1"
        >
          <div class="d-flex flex-column p-2">
            <div class="text-6b7280">TVL</div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="!opportunity.displayTvl"
            ></nz-skeleton-element>

            <div
              class="font-bold font-14"
              *ngIf="opportunity.displayTvl"
              [ngClass]="{ 'pl-2': opportunity.displayTvl === '-' }"
            >
              {{ opportunity.displayTvl }}
            </div>
          </div>

          <div class="d-flex flex-column align-items-center p-2">
            <div class="text-6b7280">APY</div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="!opportunity.displayApy"
            ></nz-skeleton-element>

            <div
              class="d-flex items-center font-bold justify-content-center"
              *ngIf="opportunity.displayApy"
              nz-tooltip
              [nzTooltipTitle]="APYTooltip"
            >
              <span>
                {{
                  opportunity.displayApy !== "-"
                    ? opportunity.displayApy + "%"
                    : opportunity.displayApy
                }}
              </span>
            </div>

            <ng-template #APYTooltip>
              <span
                *ngIf="
                  opportunity.opportunityType === opportunityType.TypePancake
                "
              >
                Min
              </span>

              <span>
                APR:
                {{ opportunity.apr + "%" }}
              </span>
            </ng-template>
          </div>

          <div class="d-flex flex-column align-items-end p-2">
            <div class="text-6b7280">
              {{
                opportunity.lockedStaking ? "Total invested Amount" : "Daily"
              }}
            </div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="!opportunity.displayDailyInterest"
            ></nz-skeleton-element>

            <div
              *ngIf="
                opportunity.displayDailyInterest && !opportunity.lockedStaking
              "
              class="font-bold"
              [ngClass]="{ 'mr-3': !opportunity.displayDailyInterest }"
            >
              {{
                !opportunity.displayDailyInterest
                  ? "-"
                  : opportunity.displayDailyInterest + "%"
              }}
            </div>
            <div *ngIf="opportunity.lockedStaking" class="font-bold font-14">
              {{ opportunity.reserveAUI + " " + opportunity.tokenA?.symbol }}
            </div>
          </div>
        </div>

        <nz-divider
          *ngIf="
            opportunity.opportunityType !== opportunityType.TypePancake &&
            opportunity.opportunityType !== opportunityType.TypeBeefy &&
            opportunity.opportunityType !== opportunityType.TypeStaking
          "
          nzType="vertical"
          class="opportunities-divider dark:border-fff border-000 opacity-10 py-2 m-0 hidden d-sm-block"
        ></nz-divider>

        <div
          *ngIf="
            opportunity.opportunityType !== opportunityType.TypePancake &&
            opportunity.opportunityType !== opportunityType.TypeBeefy &&
            opportunity.opportunityType !== opportunityType.TypeStaking
          "
          class="d-flex align-items-center w-full justify-content-between w-full flex-grow-1"
        >
          <div class="d-flex flex-column p-2">
            <div class="text-6b7280">Duration</div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="!opportunity.duration"
            ></nz-skeleton-element>

            <div
              *ngIf="opportunity.duration"
              class="font-bold"
              [ngClass]="{ 'pl-2': !opportunity.duration }"
            >
              {{ opportunity.duration }}
              {{ opportunity.duration !== null ? "days" : "-" }}
            </div>
          </div>

          <div
            *ngIf="
              opportunity.opportunityType !== opportunityType.TypePancake &&
              opportunity.opportunityType !== opportunityType.TypeStaking
            "
            class="d-flex flex-column align-items-end p-2"
          >
            <div class="text-6b7280">Reward Reset Date</div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="!opportunity.endDate"
            ></nz-skeleton-element>

            <div
              *ngIf="opportunity.endDate"
              class="font-bold"
              [ngClass]="{ 'mr-3': !opportunity.endDate }"
            >
              {{ opportunity.endDateUI }}
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          selectedTab === selectedOpportunityTab.AllOpportunities &&
          itemsPage &&
          opportunity.lockedStaking
        "
        class="w-full my-auto"
      >
        <ul class="w-full rounded-lg flex mt-3 mb-4 pb-1 dark:text-white">
          <li *ngFor="let plan of activePlans" class="w-full">
            <div class="flex sm:ml-3 sm:mr-3">
              <span
                class="w-full py-1 my-1 font-openSans-bold btn-swap-allow items-center justify-center text-center cursor-pointer hover:bg-gray-300 hover:text-white"
                (click)="selectPlan(plan.id)"
                [ngClass]="
                  plan.enable
                    ? 'border-1px border-0066FF dark:bg-3385ff26 dark:border-3385ff border-radius-4px'
                    : 'border-1px border-00000010 border-radius-4px'
                "
                >{{ plan.duration }} Days</span
              >
            </div>
          </li>
        </ul>
      </div>

      <ng-container
        *ngIf="
          selectedTab === selectedOpportunityTab.AllOpportunities &&
          opportunity.yourShare &&
          opportunity.lockedStaking &&
          +opportunity.stakedBalance > 0
        "
      >
        <ng-container
          *ngTemplateOutlet="lockedBalance; context: { inDetail: false }"
        ></ng-container>
      </ng-container>

      <ng-container
        *ngIf="
          selectedTab === selectedOpportunityTab.InvestedOpportunities &&
          opportunity.yourShare &&
          opportunity.lockedStaking &&
          +opportunity.stakedBalance > 0
        "
      >
        <ng-container
          *ngTemplateOutlet="lockedBalance; context: { inDetail: true }"
        ></ng-container>
      </ng-container>

      <ng-container
        *ngIf="
          selectedTab === selectedOpportunityTab.AllOpportunities &&
          !opportunity.lockedStaking
        "
      >
        <ng-container
          *ngTemplateOutlet="rewardsProgress; context: { inDetail: false }"
        ></ng-container>
      </ng-container>

      <ng-container
        *ngIf="selectedTab === selectedOpportunityTab.InvestedOpportunities"
      >
        <ng-container
          *ngTemplateOutlet="
            balanceSection;
            context: {
              tokenVisible:
                selectedTab === selectedOpportunityTab.AllOpportunities
            }
          "
        ></ng-container>
      </ng-container>
    </div>

    <div class="w-full d-flex flex-column">
      <div
        *ngIf="itemsPage"
        class="d-flex flex-wrap w-full align-items-center mt-3 font-12"
      >
        <div class="col-12 flex items-center justify-center">
          <ng-container *ngIf="!opportunity.stopOppLoading; else comingSoon">
            <button
              nz-button
              nzType="default"
              [disabled]="true"
              [nzSize]="'default'"
              class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            >
              <span class="px-1"> Funding </span>
            </button>
          </ng-container>

          <ng-template class="col-12 flex" #comingSoon>
            <div
              *ngIf="opportunity.isComingSoon"
              class="w-full px-2 py-1 bg-000 border-radius-4px font-openSans-semiBold d-flex flex-wrap align-items-center justify-content-center line-height-18"
            >
              <span class="font-14 pt-1 text-fff"> Coming Soon ... </span>
            </div>

            <ng-container
              *ngIf="
                !opportunity.isComingSoon &&
                  opportunity.eligibleStartTime > currentTime;
                else afterStart
              "
            >
              <div
                class="w-full px-2 py-1 bg-000 border-radius-4px font-openSans-semiBold d-flex flex-wrap align-items-center justify-content-center line-height-18"
              >
                <span class="font-12 text-fff">Starting In: </span>
                <div class="d-flex align-items-center pl-2 py-1">
                  <span class="font-20 text-FBD127 font-openSans-bold">
                    {{ opportunity.eligibleTime?.d }}
                  </span>
                  <span class="font-12 text-FBD127 pl-1"> Days </span>
                </div>
                <div class="d-flex align-items-center pl-2 py-1">
                  <span class="font-20 text-FBD127 font-openSans-bold">
                    {{ opportunity.eligibleTime?.h }}
                  </span>

                  <span class="font-12 text-FBD127 pl-1"> Hours </span>
                </div>
                <div class="d-flex align-items-center pl-2 py-1">
                  <span class="font-20 text-FBD127 font-openSans-bold">
                    {{ opportunity.eligibleTime?.m }}
                  </span>
                  <span class="font-12 text-FBD127 pl-1"> Minutes </span>
                </div>
                <a
                  class="d-flex align-items-center pl-5 py-1 cursor-pointer d-none"
                  href="https://crowdswap.org/crowdswap-ultimate-defi-journey/"
                  target="_blank"
                >
                  <br class="d-md-none" />
                  <span class="text-fff font-16 font-openSans-bold px-1">
                    5 Days later for public
                  </span>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.41536 5.83398H5.2487C4.32822 5.83398 3.58203 6.58018 3.58203 7.50065V15.0007C3.58203 15.9211 4.32822 16.6673 5.2487 16.6673H12.7487C13.6692 16.6673 14.4154 15.9211 14.4154 15.0007V10.834"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.58203 11.6673L16.9154 3.33398"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.75 3.33398H16.9167V7.50065"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </ng-container>
            <ng-template class="col-12 flex" #afterStart>
              <ng-container
                *ngIf="currentTime > opportunity.startTime; else betweenTime"
                class="col-12"
              >
                <button
                  (click)="invest(opportunity)"
                  nz-button
                  nzType="default"
                  [nzSize]="'default'"
                  class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
                >
                  <span class="px-1"> Funding </span>
                  <span
                    *ngIf="false"
                    class="d-flex flex-column align-items-end opacity-75"
                    >{{
                      !opportunity.investorsCount ||
                      +opportunity.investorsCount < 100
                        ? "<100"
                        : opportunity.investorsCount
                    }}
                    INVESTS</span
                  >
                </button>
              </ng-container>
              <ng-template class="col-12 flex" #betweenTime>
                <div
                  (click)="invest(opportunity)"
                  class="w-full px-2 pt-1 pb-2 border-radius-4px font-openSans-semiBold d-flex flex-wrap align-items-center justify-content-center line-height-18"
                  [ngClass]="
                    opportunity.isUserEligible
                      ? 'cursor-pointer button-0066FF'
                      : 'bg-000 pointer-events-none'
                  "
                >
                  <span class="font-14 pt-1 text-fff">
                    {{ "Opened for Qualified Users" }}
                  </span>
                  <div *ngIf="!opportunity.isUserEligible">
                    <ng-container
                      *ngTemplateOutlet="eligibleBtn"
                    ></ng-container>
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </ng-template>
        </div>
      </div>

      <nz-collapse nzGhost class="opportunities-detail" *ngIf="!itemsPage">
        <nz-collapse-panel [nzActive]="!itemsPage">
          <div
            *ngIf="!itemsPage"
            class="w-full d-flex flex-column align-items-baseline mt-3"
          >
            <ng-container
              *ngIf="opportunity.yourShare && !opportunity.lockedStaking"
            >
              <span class="font-12 font-w-800 text-000 dark:text-fff">
                {{
                  opportunity.opportunityType === "staking"
                    ? "Staked Balance (incl. rewards)"
                    : "Balance"
                }}
              </span>

              <ng-container
                *ngTemplateOutlet="
                  balanceSection;
                  context: {
                    tokenVisible:
                      selectedTab === selectedOpportunityTab.AllOpportunities
                  }
                "
              ></ng-container>
            </ng-container>

            <ng-container
              *ngIf="
                selectedTab === selectedOpportunityTab.InvestedOpportunities &&
                !opportunity.lockedStaking
              "
            >
              <ng-container
                *ngTemplateOutlet="rewardsProgress; context: { inDetail: true }"
              ></ng-container>
            </ng-container>

            <div
              *ngIf="
                opportunity.opportunityType !== opportunityType.TypeCrowd &&
                opportunity.opportunityType !== opportunityType.TypeStaking
              "
              class="dark:text-darkGrayText mt-3"
            >
              <div class="font-openSans-bold mt-2">Asset Details</div>
              <div>
                <ul class="ml-3 pl-3">
                  <li>
                    {{ opportunity.tokenA.symbol }}
                    <a
                      class="max-color ml-5 pl-5"
                      href="{{ opportunity.hrefTokenA }}"
                      target="_blank"
                    >
                      Website
                    </a>
                    <a
                      class="max-color ml-3"
                      href="{{
                        'https://' +
                          web3Service.networkSpec[opportunity.chainId].scanUrl +
                          '/token/' +
                          opportunity.tokenA.address
                      }}"
                      target="_blank"
                    >
                      Token Contract
                    </a>
                    <p>
                      {{ tokenDescription[opportunity.tokenA.symbol] }}
                    </p>
                  </li>
                  <li class="mt-2">
                    {{ opportunity.tokenB?.symbol }}
                    <a
                      class="max-color ml-5 pl-5"
                      href="{{ opportunity.hrefTokenB }}"
                      target="_blank"
                    >
                      Website
                    </a>
                    <a
                      class="max-color ml-3"
                      href="{{
                        'https://' +
                          web3Service.networkSpec[opportunity.chainId].scanUrl +
                          '/token/' +
                          opportunity.tokenB.address
                      }}"
                      target="_blank"
                    >
                      Token Contract
                    </a>
                    <p>
                      {{ tokenDescription[opportunity.tokenB?.symbol] }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Remove this part of details-->
            <div
              *ngIf="
                opportunity.opportunityType !== opportunityType.TypeStaking
              "
              class="dark:text-darkGrayText mt-3 d-none"
            >
              <div class="font-openSans-bold mt-2">Safety notes</div>
              <div>
                <ul class="ml-3 pl-3">
                  <li>
                    &bull; Strategy behind this opportunity:
                    <span
                      *ngIf="
                        opportunity.opportunityType ===
                        opportunityType.TypeCrowd
                      "
                      >simple
                      <span class="text-xs"
                        >(There is no active strategy involved in this
                        opportunity)</span
                      ></span
                    >
                    <span
                      *ngIf="
                        opportunity.opportunityType !==
                        opportunityType.TypeCrowd
                      "
                      >Low complexity
                      <span class="text-xs"
                        >(Strategies have few, if any, moving parts and their
                        code is easy to read and debug. There is a direct
                        correlation between code complexity and inherent risk. A
                        simple strategy effectively mitigates implementation
                        risks.)</span
                      ></span
                    >
                  </li>
                  <li>
                    &bull; TVL:
                    {{
                      opportunity.displayTvl === "-"
                        ? "-"
                        : "$" + opportunity.displayTvl
                    }}
                    <span class="text-xs"
                      >(There used to be risk, when the TVL of an opportunity is
                      still low after some weeks. Medium risk is lower than 500K
                      USD, TVL looks good over 1M USD)</span
                    >
                  </li>
                  <li>
                    &bull; Platform risk: low
                    <span class="text-xs">
                      <span
                        *ngIf="
                          opportunity.opportunityType ===
                          opportunityType.TypeCrowd
                        "
                      >
                        (Platform is CrowdSwap and no exploits have been done,
                        Team is KYCed)</span
                      >
                      <span
                        *ngIf="
                          opportunity.opportunityType ===
                          opportunityType.TypeBeefy
                        "
                        >(Besides CrowdSwap, the involved protocols are
                        Quickswap and Beefy finance which are known
                        platforms)</span
                      >
                      <span
                        *ngIf="
                          opportunity.opportunityType ===
                          opportunityType.TypePancake
                        "
                        >(Besides CrowdSwap, the involved protocol is
                        PancakeSwap which is a known platform)</span
                      >
                    </span>
                  </li>
                  <li>
                    &bull; Fund distribution:
                    <span
                      *ngIf="
                        opportunity.opportunityType ===
                        opportunityType.TypeCrowd
                      "
                    >
                      none
                      <span class="text-xs"
                        >(Your funds stay on CrowdSwap)</span
                      >
                    </span>
                    <span
                      *ngIf="
                        opportunity.opportunityType ===
                        opportunityType.TypeBeefy
                      "
                    >
                      low
                      <span class="text-xs"
                        >(Your fund are sent to
                        <a
                          class="max-color"
                          href="https://app.beefy.com/vault/mai-usdc-mimatic"
                          target="_blank"
                          >Beefy</a
                        >
                        protocols)</span
                      >
                    </span>
                    <span
                      *ngIf="
                        opportunity.opportunityType ===
                        opportunityType.TypePancake
                      "
                    >
                      low
                      <span class="text-xs"
                        >(Your fund are sent to
                        <a
                          class="max-color mt-2"
                          href="https://pancakeswap.finance/"
                          target="_blank"
                          >PancakeSwap</a
                        >
                        protocols)</span
                      >
                    </span>
                  </li>
                </ul>
              </div>
              <div
                *ngIf="
                  opportunity.opportunityType === opportunityType.TypeCrowd
                "
                class="mt-2"
              >
                Your funds stay on this platform. The team is working hard on
                every opportunity added. Every opportunity can and will be
                paused if the need arises given even suspicious activity to
                protect our users funds.
              </div>
            </div>
            <div
              *ngIf="
                opportunity.opportunityType === opportunityType.TypeStaking
              "
              class="dark:text-darkGrayText"
            >
              By staking your {{ opportunity.tokenA?.symbol }} , you can earn
              more {{ opportunity.tokenA?.symbol }} effortlessly while you go
              about your daily life. When you decide to unstake your
              {{ opportunity.tokenA?.symbol }} , you'll receive the originally
              staked amount plus any additional interest accrued over time ,
              minus a small fee.
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
</div>

<ng-template #rewardsProgress let-inDetail="inDetail">
  <div
    class="remaining-reward-section d-flex w-full align-items-center border-1px border-00000010 dark:border-00000012 border-radius-4px bg-F7F7F7 dark:bg-1E1E1E text-000 dark:text-fff px-2 py-1"
    [ngClass]="{
      'mt-3': !inDetail,
      'flex-column':
        opportunity.opportunityType !== opportunityType.TypePancake &&
        opportunity.opportunityType !== opportunityType.TypeBeefy &&
        opportunity.opportunityType !== opportunityType.TypeStaking
    }"
  >
    <nz-skeleton-element
      nzType="button"
      [nzActive]="true"
      [nzSize]="'small'"
      class="w-full mt-1"
      *ngIf="!opportunity.remainingRewardsUI && !opportunity.totalRewardUI"
    ></nz-skeleton-element>

    <ng-container
      *ngIf="opportunity.remainingRewardsUI && opportunity.totalRewardUI"
    >
      <div
        *ngIf="
          opportunity.opportunityType !== opportunityType.TypePancake &&
          opportunity.opportunityType !== opportunityType.TypeBeefy &&
          opportunity.opportunityType !== opportunityType.TypeStaking
        "
        class="w-full d-flex justify-content-center flex-column flex-sm-row no-text-wrap"
      >
        <span class="font-14 p-1 text-center">
          Available Rewards:

          <!--          <span class="d-inline d-sm-none">-->
          <!--            {{ opportunity.rewardToken?.symbol }}-->
          <!--          </span>-->
        </span>

        <div class="d-flex align-items-center justify-content-center p-1">
          <span class="font-14">
            {{ opportunity.remainingRewardsUI }}

            <!--            <span class="d-sm-inline d-none">-->
            <!--              {{ opportunity.rewardToken?.symbol }}-->
            <!--            </span>-->
          </span>

          <span class="font-14 text-000000B2 dark:text-FFFFFFB2 ml-1"> / </span>

          <span class="font-14 ml-1">
            {{ opportunity.totalRewardUI }}

            <span class="d-sm-inline">
              {{ opportunity.rewardToken?.symbol }}
            </span>
          </span>
        </div>
      </div>

      <div
        *ngIf="
          opportunity.opportunityType !== opportunityType.TypePancake &&
          opportunity.opportunityType !== opportunityType.TypeBeefy &&
          opportunity.opportunityType !== opportunityType.TypeStaking
        "
        class="w-full d-flex justify-content-center position-relative mt-1"
      >
        <nz-progress
          [nzPercent]="opportunity.remainingPercent"
          [nzShowInfo]="false"
          nzStatus="active"
          [nzStrokeColor]="
            opportunity.remainingPercent < 40
              ? '#FF0000'
              : opportunity.remainingPercent < 60
              ? '#FBD127'
              : '#1DD976'
          "
          class="w-full"
        ></nz-progress>

        <span
          class="absolute-y-center w-full text-center font-9 line-height-full text-000"
        >
          %
          {{ opportunity.remainingPercent }}
          Rewards Remaining
        </span>
      </div>

      <div
        class="w-full justify-content-center flex-column flex-sm-row no-text-wrap"
        *ngIf="
          opportunity.opportunityType === opportunityType.TypePancake ||
          opportunity.opportunityType === opportunityType.TypeBeefy ||
          opportunity.opportunityType === opportunityType.TypeStaking
        "
      >
        <div class="text-6b7280">Total invested Amount:</div>
        <div class="font-bold font-14">
          {{
            opportunity.reserveAUI +
              " " +
              opportunity.tokenA?.symbol +
              (opportunity.tokenB
                ? " + " +
                  opportunity.reserveBUI +
                  " " +
                  opportunity.tokenB?.symbol
                : "")
          }}
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #lockedBalance let-inDetail="inDetail">
  <div
    *ngIf="inDetail || !itemsPage"
    class="font-12 font-w-800 total-balance-margin text-000 dark:text-fff"
  >
    Total Balance
  </div>
  <div
    *ngIf="inDetail || !itemsPage"
    class="balance-section w-full d-flex flex-column align-items-baseline"
  >
    <div
      class="w-full d-flex align-items-center no-text-wrap flex-column flex-md-row flex-xxl-row flex-lg-column"
    >
      <div class="w-full pe-md-2 pe-lg-0 pe-xxl-2">
        <div
          class="w-full align-items-center justify-content-between border-1px border-00000010 dark:border-00000012 border-radius-4px bg-F7F7F7 dark:bg-121212 text-000 dark:text-fff d-flex flex-sm-row flex-column"
        >
          <div
            class="d-flex align-items-center justify-content-between flex-basis-100% w-full"
          >
            <div class="d-flex flex-column p-1">
              <div class="text-6b7280 font-12">Amount</div>

              <nz-skeleton-element
                nzType="button"
                [nzActive]="true"
                [nzSize]="'small'"
                *ngIf="!opportunity.stakedBalance"
              ></nz-skeleton-element>

              <div
                class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                *ngIf="opportunity.stakedBalance"
              >
                {{ opportunity.stakedBalance }} {{ opportunity.asset }}
              </div>
            </div>

            <div class="d-flex flex-column p-1 justify-content-end text-right">
              <div class="text-6b7280 font-12">Your share</div>

              <nz-skeleton-element
                nzType="button"
                [nzActive]="true"
                [nzSize]="'small'"
                *ngIf="!opportunity.yourShare"
              ></nz-skeleton-element>

              <div
                class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                *ngIf="opportunity.yourShare"
              >
                {{ opportunity.yourShare }}%
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="reward-section w-full xxl:w-2/3 align-items-center justify-content-between d-flex ps-0 ps-md-2 ps-lg-0 ps-xxl-2"
      >
        <div
          class="d-flex align-items-center justify-content-center flex-basis-100% w-full border-1px border-0000000f border-radius-4px bg-BBF2D9"
        >
          <div class="d-flex flex-column p-1">
            <div class="text-000 font-12 text-center">Rewards</div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="!opportunity.totalRewards"
            ></nz-skeleton-element>

            <div
              class="font-bold font-14 text-000 text-center"
              *ngIf="opportunity.totalRewards"
            >
              {{ opportunity.totalRewards }}
              {{ opportunity.rewardToken?.symbol }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="!itemsPage"
    class="font-12 font-w-800 mt-3 text-000 dark:text-fff"
  >
    Detail Balance
  </div>
  <div *ngIf="!itemsPage">
    <nz-table
      class="w-full bg-white dark:bg-121212 no-text-wrap"
      [nzScroll]="{ y: tableScrollY }"
    >
      <div
        class="balance-section w-full d-flex flex-column align-items-baseline"
        *ngFor="let item of opportunity.lockedList"
      >
        <div
          class="w-full d-flex no-text-wrap flex-column flex-md-row flex-xxl-row flex-lg-column"
        >
          <div class="w-full pe-md-2 pe-lg-0 pe-xxl-2">
            <div
              class="w-full align-items-center justify-content-between border-1px border-00000010 dark:border-00000012 border-radius-4px bg-F7F7F7 dark:bg-121212 text-000 dark:text-fff d-flex flex-sm-row flex-column"
            >
              <div
                class="d-flex align-items-center justify-content-between flex-basis-100% w-full"
              >
                <div class="d-flex flex-column p-1">
                  <div class="text-6b7280 font-12">
                    <nz-skeleton-element
                      nzType="button"
                      [nzActive]="true"
                      [nzSize]="'small'"
                      *ngIf="loading || !opportunity.stopLoading"
                    ></nz-skeleton-element>

                    <span *ngIf="!loading && opportunity.stopLoading">
                      {{ opportunity.asset }}
                    </span>
                  </div>

                  <nz-skeleton-element
                    nzType="button"
                    [nzActive]="true"
                    [nzSize]="'small'"
                    *ngIf="!item.amount"
                  ></nz-skeleton-element>

                  <div
                    class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                    *ngIf="item.amount"
                  >
                    {{ item.amount }}
                  </div>
                </div>

                <div
                  class="d-flex flex-column p-1 justify-content-center text-center"
                >
                  <div class="text-6b7280 font-12">Duration</div>

                  <nz-skeleton-element
                    nzType="button"
                    [nzActive]="true"
                    [nzSize]="'small'"
                    *ngIf="!item.duration"
                  ></nz-skeleton-element>

                  <div
                    class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                    *ngIf="item.duration"
                  >
                    {{ item.duration }} Days
                  </div>
                </div>

                <div
                  class="d-flex flex-column p-1 justify-content-end text-right"
                >
                  <div class="text-6b7280 font-12">End Date</div>

                  <nz-skeleton-element
                    nzType="button"
                    [nzActive]="true"
                    [nzSize]="'small'"
                    *ngIf="!item.endDate"
                  ></nz-skeleton-element>

                  <div
                    class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                    *ngIf="item.endDate"
                  >
                    {{ item.displayEndDate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="reward-section w-full xxl:w-2/3 align-items-center justify-content-between d-flex ps-0 ps-md-2 ps-lg-0 ps-xxl-2"
          >
            <div
              class="d-flex align-items-center justify-content-center flex-basis-100% mb-2 w-full border-1px border-0000000f border-radius-4px bg-BBF2D9"
            >
              <div class="d-flex flex-column p-1">
                <div class="text-000 font-12 text-center">Rewards</div>

                <nz-skeleton-element
                  nzType="button"
                  [nzActive]="true"
                  [nzSize]="'small'"
                  *ngIf="!item.rewards"
                ></nz-skeleton-element>

                <div
                  class="font-bold font-14 text-000 text-center"
                  *ngIf="item.rewards"
                >
                  {{ item.rewards }} {{ opportunity.rewardToken?.symbol }}
                </div>
                <div
                  *ngIf="+item.endDate < rightNowDate"
                  class="w-full d-flex justify-content-center flex-column flex-sm-row"
                >
                  <div
                    class="font-bold font-14 mx-3 text-0066FF dark:text-3385FF text-center cursor-pointer"
                    (click)="extendStaking(item)"
                  >
                    Extend
                  </div>
                  <div
                    class="font-bold font-14 mx-3 text-0066FF dark:text-3385FF text-center cursor-pointer"
                    (click)="withdrawStaking(item)"
                  >
                    Withdraw
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nz-table>
  </div>
</ng-template>

<ng-template #balanceSection let-tokenVisible="tokenVisible">
  <div
    *ngIf="!opportunity.lockedStaking"
    class="balance-section w-full d-flex flex-column align-items-baseline"
    [ngClass]="{ 'mt-2': !tokenVisible }"
  >
    <div
      class="w-full d-flex align-items-center no-text-wrap flex-column flex-md-row flex-xxl-row flex-lg-column"
      [ngClass]="{ 'mt-2': !tokenVisible }"
    >
      <div class="w-full py-2 pe-md-2 pe-lg-0 pe-xxl-2">
        <div
          class="w-full align-items-center justify-content-between border-1px border-00000010 dark:border-00000012 border-radius-4px bg-F7F7F7 dark:bg-070707 text-000 dark:text-fff d-flex flex-sm-row flex-column"
        >
          <div
            class="d-flex align-items-center justify-content-between flex-basis-100% w-full"
          >
            <div class="d-flex flex-column p-1">
              <div class="text-6b7280 font-12">
                <span *ngIf="!loading && opportunity.stopLoading">
                  {{
                    opportunity.opportunityType === "staking"
                      ? opportunity.asset
                      : "LP&nbsp;" + opportunity.asset
                  }}
                </span>

                <nz-skeleton-element
                  nzType="button"
                  [nzActive]="true"
                  [nzSize]="'small'"
                  *ngIf="loading || !opportunity.stopLoading"
                ></nz-skeleton-element>
              </div>

              <nz-skeleton-element
                nzType="button"
                [nzActive]="true"
                [nzSize]="'small'"
                *ngIf="!opportunity.stakedBalance"
              ></nz-skeleton-element>

              <div
                class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                *ngIf="opportunity.displayTvl"
              >
                {{ opportunity.stakedBalance }}
              </div>
            </div>

            <div class="d-flex flex-column p-1 justify-content-end text-right">
              <div class="text-6b7280 font-12">Your share</div>

              <nz-skeleton-element
                nzType="button"
                [nzActive]="true"
                [nzSize]="'small'"
                *ngIf="!opportunity.yourShare"
              ></nz-skeleton-element>

              <div
                class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                *ngIf="opportunity.yourShare"
              >
                {{ opportunity.yourShare }}%
              </div>
            </div>
          </div>

          <nz-divider
            nzType="vertical"
            class="opportunities-divider dark:border-fff border-000 opacity-10 py-2 m-0 hidden d-sm-block"
          ></nz-divider>

          <div
            class="d-flex align-items-center w-full justify-content-between w-full"
          >
            <div class="d-flex flex-column p-1">
              <div
                *ngIf="
                  opportunity.tokenB &&
                  opportunity.opportunityType !== opportunityType.TypeStaking
                "
                class="text-6b7280 font-12"
              >
                {{ opportunity.tokenA.symbol }}
              </div>

              <nz-skeleton-element
                nzType="button"
                [nzActive]="true"
                [nzSize]="'small'"
                *ngIf="
                  !opportunity.receivedTokenA &&
                  opportunity.opportunityType !== opportunityType.TypeStaking
                "
              ></nz-skeleton-element>

              <div
                *ngIf="
                  opportunity.tokenB &&
                  opportunity.opportunityType !== opportunityType.TypeStaking
                "
                class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
              >
                {{ opportunity.receivedTokenA }}
              </div>
            </div>

            <div
              class="d-flex flex-column p-1 justify-content-center text-center"
              *ngIf="
                opportunity.tokenB &&
                opportunity.opportunityType !== opportunityType.TypeStaking
              "
            >
              <div class="text-6b7280 font-12">
                {{ opportunity.tokenB?.symbol }}
              </div>

              <nz-skeleton-element
                nzType="button"
                [nzActive]="true"
                [nzSize]="'small'"
                *ngIf="!opportunity.receivedTokenB"
              ></nz-skeleton-element>

              <div
                class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
              >
                {{ opportunity.receivedTokenB }}
              </div>
            </div>

            <div
              *ngIf="
                opportunity.opportunityType !== opportunityType.TypeStaking
              "
              class="d-flex flex-column p-1 justify-content-end text-right"
            >
              <div class="text-6b7280 font-12">Total</div>

              <nz-skeleton-element
                nzType="button"
                [nzActive]="true"
                [nzSize]="'small'"
                *ngIf="!opportunity.stakedBalanceInUSDT"
              ></nz-skeleton-element>

              <div
                class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                *ngIf="opportunity.stakedBalanceInUSDT"
              >
                ${{ opportunity.stakedBalanceInUSDT }}
              </div>
            </div>

            <div
              *ngIf="
                opportunity.opportunityType === opportunityType.TypeStaking
              "
              class="d-flex flex-column p-1 w-40 justify-content-end text-right"
            >
              <div
                *ngIf="this.timeLeft$ | async as t"
                class="opacity-50 flex justify-center font-openSans-regular dark:text-white"
              >
                {{ t.minutesToNextReward }}m {{ t.secondsToNextReward }}s to
                receive
              </div>

              <nz-skeleton-element
                nzType="button"
                [nzActive]="true"
                [nzSize]="'small'"
                *ngIf="!opportunity.nextReward"
              ></nz-skeleton-element>

              <div
                *ngIf="opportunity.nextReward"
                class="flex justify-center font-openSans-semiBold dark:text-white"
              >
                {{ opportunity.nextReward }} {{ opportunity.tokenA?.symbol }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="reward-section w-full align-items-center justify-content-between d-flex py-2 ps-0 ps-md-2 ps-lg-0 ps-xxl-2"
      >
        <div
          class="d-flex align-items-center justify-content-center flex-basis-100% w-full border-1px border-0000000f border-radius-4px bg-BBF2D9"
        >
          <div class="d-flex flex-column p-1">
            <div class="text-000 font-12">Rewards you received</div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="!opportunity.reward"
            ></nz-skeleton-element>

            <div
              class="font-bold font-14 text-000 text-center"
              *ngIf="opportunity.reward"
            >
              {{ opportunity.reward }} {{ opportunity.rewardToken?.symbol }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #eligibleBtn>
  <a
    class="eligible-section d-flex align-items-center text-323232 bg-000 border-radius-4px w-md-auto w-full justify-content-center mt-10px ml-5 mt-xl-0 cursor-pointer"
    href="https://crowdswap.org/crowdswap-ultimate-defi-journey/"
    target="_blank"
  >
    <span class="font-14 text-fff"> Public Opening: </span>

    <div class="d-flex align-items-center ms-1">
      <div class="d-flex align-items-center ms-1 font-12">
        <span class="font-14 text-FBD127 me-1">
          {{ opportunity.time?.d }}
        </span>
        <span class="font-12 text-FBD127 pl-1"> D </span>
      </div>

      <div class="d-flex align-items-center ms-1 font-12">
        <span class="font-14 text-FBD127 me-1">
          {{ opportunity.time?.h }}
        </span>
        <span class="font-12 text-FBD127 pl-1"> H </span>
      </div>

      <div class="d-flex align-items-center ms-1 font-12">
        <span class="font-14 text-FBD127 me-1">
          {{ opportunity.time?.m }}
        </span>
        <span class="font-12 text-FBD127 pl-1"> M </span>
      </div>
    </div>

    <span class="ms-1 cursor-pointer">
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.41536 5.83398H5.2487C4.32822 5.83398 3.58203 6.58018 3.58203 7.50065V15.0007C3.58203 15.9211 4.32822 16.6673 5.2487 16.6673H12.7487C13.6692 16.6673 14.4154 15.9211 14.4154 15.0007V10.834"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.58203 11.6673L16.9154 3.33398"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.75 3.33398H16.9167V7.50065"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
  </a>
</ng-template>
