import { Component, HostListener, OnInit } from '@angular/core';
import { TimeService } from '../../../services/time.service';

@Component({
  selector: 'app-listing-banner',
  templateUrl: './listing-banner.component.html',
  styleUrls: ['./listing-banner.component.scss']
})
export class ListingBannerComponent implements OnInit {
  defaultOptions = {
    loop: true,
    autoplay: true,
    path: '',
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      className: 'lottie-svg-class'
    }
  };

  public currentTime;
  public startTime = 1702490881; //start 1th 12 at 08:00
  public finishTime = 1703160000; //finish 7th 12 at 18:00
  public isClosed: boolean = false;
  public bannerLink = 'https://crowdswap.org/buy-crowd/';

  constructor(protected timeService: TimeService) {}

  ngOnInit(): void {
    this.currentTime = this.timeService.getCurrentTime();
    this.checkWidth(window.innerWidth);
  }

  close() {
    this.isClosed = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWidth(event.target.innerWidth);
  }

  checkWidth(width: number) {
    if (
      width <= 430 &&
      this.defaultOptions.path !==
        '../../../../assets/media/banners/430-50-MOB.json'
    ) {
      this.defaultOptions = {
        ...this.defaultOptions,
        path: '../../../../assets/media/banners/430-50-MOB.json'
      };
    } else if (
      width > 430 &&
      width <= 1024 &&
      this.defaultOptions.path !==
        '../../../../assets/media/banners/1024-60-TAB.json'
    ) {
      this.defaultOptions = {
        ...this.defaultOptions,
        path: '../../../../assets/media/banners/1024-60-TAB.json'
      };
    } else if (
      width > 1024 &&
      this.defaultOptions.path !==
        '../../../../assets/media/banners/1920-80-WEB.json'
    ) {
      this.defaultOptions = {
        ...this.defaultOptions,
        path: '../../../../assets/media/banners/1920-80-WEB.json'
      };
    }
  }
}
