import { Component, NgModule, ChangeDetectorRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { FormsModule } from '@angular/forms';
import { ExpertModeDialogComponent } from '../expert-mode-dialog/expert-mode-dialog.component';
import { CrowdSwapService } from '../../../../services/crowdswap.service';
import { ModalService } from '../../modal.service';
import { Web3Service } from '../../../../services/web3.service';
import { commonConfig } from '@crowdswap/constant';
import { SettingModel } from './setting-models';

@Component({
  selector: 'app-setting-dialog',
  templateUrl: './setting-dialog.component.html',
  styleUrls: ['./setting-dialog.component.scss']
})
export class SettingDialogComponent extends DialogsComponent implements OnInit {
  unitTokenDollar: boolean = true;
  expertMode: boolean = false;
  warningSlippage: boolean = false;
  warningCrossChainSlippage: boolean = false;
  transactionRedError: boolean = false;
  defaultSlippage = CrowdSwapService.SLIPPAGE;
  defaultCrossChainSlippage = commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE;
  public slippage: string = CrowdSwapService.SLIPPAGE;
  public crossChainSlippage: string = commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE;
  public deadline: string = CrowdSwapService.DEADLINE;
  private oldSetting?: SettingModel;

  constructor(
    private expertDialogService: ModalService<ExpertModeDialogComponent>,
    private web3Service: Web3Service,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.slippage = this.defaultSlippage =
      this.data?.slippage || CrowdSwapService.SLIPPAGE;
    this.crossChainSlippage = this.defaultCrossChainSlippage =
      this.data?.crossChainSlippage ||
      commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE;
    this.loadSetting();
    this.changeSlippage();
  }

  loadSetting() {
    let settingJson = localStorage.getItem('user-setting');
    const setting = settingJson
      ? JSON.parse(settingJson)
      : this.getDefaultSetting();

    if (!settingJson) {
      localStorage.setItem('user-setting', JSON.stringify(setting));
    }

    this.oldSetting = setting;
    this.setFromSetting(setting);
  }

  resetSettings() {
    let setting = this.getDefaultSetting();
    this.setFromSetting(setting);
    this.changeSlippage();
  }

  getDefaultSetting(): SettingModel {
    return {
      unitTokenDollar: true,
      expertMode: false,
      slippage: this.defaultSlippage,
      crossChainSlippage: this.defaultCrossChainSlippage,
      deadline: CrowdSwapService.DEADLINE
    };
  }

  setFromSetting(setting: SettingModel) {
    this.unitTokenDollar = setting.unitTokenDollar;
    this.slippage = setting.slippage;
    this.crossChainSlippage = setting.crossChainSlippage;
    this.deadline = setting.deadline;
    this.expertMode = setting.expertMode;
  }

  changeSlippage() {
    const pointIndex = this.slippage.indexOf('.');
    if (this.slippage == '.' || pointIndex == this.slippage.length - 1) {
      return;
    }
    this.slippage = (+this.slippage).toString();
    if (+this.slippage >= 50) {
      this.slippage = this.slippage.substring(0, 1);
    }
    this.warningSlippage = +this.slippage > 10;
    this.ref.detectChanges();
  }

  changeCrossChainSlippage() {
    const pointIndex = this.crossChainSlippage.indexOf('.');
    if (
      this.crossChainSlippage == '.' ||
      pointIndex == this.crossChainSlippage.length - 1
    ) {
      return;
    }
    this.crossChainSlippage = (+this.crossChainSlippage).toString();
    if (+this.crossChainSlippage >= 50) {
      this.crossChainSlippage = this.crossChainSlippage.substring(0, 1);
    }
    this.warningCrossChainSlippage = +this.crossChainSlippage > 10;
    this.ref.detectChanges();
  }

  setSlippage() {
    if (
      this.slippage == '.' ||
      !+this.slippage ||
      +this.slippage < +this.defaultSlippage
    ) {
      this.slippage = this.defaultSlippage;
      this.warningSlippage = false;
    }
    this.slippage = (+this.slippage).toString();
    this.ref.detectChanges();
  }

  setCrossChainSlippage() {
    if (
      this.crossChainSlippage == '.' ||
      !+this.crossChainSlippage ||
      +this.crossChainSlippage < +this.defaultCrossChainSlippage
    ) {
      this.crossChainSlippage = this.defaultCrossChainSlippage;
      this.warningCrossChainSlippage = false;
    }
    this.crossChainSlippage = (+this.crossChainSlippage).toString();
    this.ref.detectChanges();
  }

  setSwapAuto() {
    this.slippage = this.defaultSlippage;
    this.changeSlippage();
    this.setSlippage();
  }

  setCrossChainAuto() {
    this.crossChainSlippage = this.defaultCrossChainSlippage;
    this.changeCrossChainSlippage();
    this.setCrossChainSlippage();
  }

  closeDialog() {
    const setting = {
      unitTokenDollar: this.unitTokenDollar,
      expertMode: this.expertMode,
      slippage: this.slippage,
      crossChainSlippage: this.crossChainSlippage,
      deadline: this.deadline
    };
    localStorage.setItem('user-setting', JSON.stringify(setting));

    const isChanged =
      this.oldSetting!.slippage !== setting.slippage ||
      this.oldSetting!.crossChainSlippage !== setting.crossChainSlippage ||
      this.oldSetting!.deadline !== setting.deadline;

    this.closed.emit(isChanged);
  }

  async expertModeDialog() {
    if (this.expertMode) {
      this.expertMode = false;
      return;
    }
    const data = { isDarkMode: this.data?.isDarkMode };
    const expertDialogRef = await this.expertDialogService.open(
      ExpertModeDialogComponent,
      data
    );
    this.expertMode = false;

    (<ExpertModeDialogComponent>(
      (<any>expertDialogRef!.instance)
    )).confirmed.subscribe(async (event) => {
      await this.expertDialogService.close();
      this.expertMode = event;
    });
    this.ref.detectChanges();
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule],
  declarations: [SettingDialogComponent]
})
export class SettingDialogComponentModule {}
