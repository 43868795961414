import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class OneInchService {
  private readonly oneInchURL = 'https://api.1inch.io/v4.0';
  private readonly baseTokenAddress =
    '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';

  constructor(private http: HttpClient) {}

  public async getOneInchQuote(
    chainId: number,
    fromTokenAddress: string,
    toTokenAddress: string,
    amount: string,
    fromAddress: string,
    reqTimeout: number = 1350000
  ): Promise<any> {
    try {
      fromTokenAddress = this.isBaseToken(fromTokenAddress)
        ? this.baseTokenAddress
        : fromTokenAddress;
      toTokenAddress = this.isBaseToken(toTokenAddress)
        ? this.baseTokenAddress
        : toTokenAddress;
      let swapParams = {
        fromTokenAddress: fromTokenAddress,
        toTokenAddress: toTokenAddress,
        amount: amount,
        fromAddress: fromAddress,
        slippage: 1,
        disableEstimate: false,
        allowPartialFill: false
      };
      return await this.http
        .get(`${this.oneInchURL}/${chainId}/quote`, { params: swapParams })
        .toPromise();
    } catch (err) {
      console.error(`Error message: ${JSON.stringify(err)}`);
    }
    throw new Error('Unable to get oneInch transaction');
  }

  public async getOneInchTransaction(
    chainId: number,
    fromTokenAddress: string,
    toTokenAddress: string,
    amount: string,
    fromAddress: string,
    reqTimeout: number = 1350000
  ): Promise<any> {
    try {
      fromTokenAddress = this.isBaseToken(fromTokenAddress)
        ? this.baseTokenAddress
        : fromTokenAddress;
      toTokenAddress = this.isBaseToken(toTokenAddress)
        ? this.baseTokenAddress
        : toTokenAddress;
      let swapParams = {
        fromTokenAddress: fromTokenAddress,
        toTokenAddress: toTokenAddress,
        amount: amount,
        fromAddress: fromAddress,
        slippage: 1,
        disableEstimate: false,
        allowPartialFill: false
      };
      return await this.http
        .get(`${this.oneInchURL}/${chainId}/swap`, { params: swapParams })
        .toPromise();
    } catch (err) {
      console.error(`Error message: ${JSON.stringify(err)}`);
    }
    throw new Error('Unable to get oneInch transaction');
  }

  isBaseToken(address: string) {
    return (
      address === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' ||
      address === '0x0000000000000000000000000000000000001010'
    );
  }
}
