import { IPatchState } from './cross-chain-estimate-trade.model';
import { CrowdToken } from '@crowdswap/constant';

export interface ITxHistoryToDisplay {
  txType: TxType;
  timeStamp: string;
  date: string;
  time: string;
  hash: string;
  'fromAddress?': string;
  'toAddress?': string;
  chainId: string;
  destChainId?: string;
  tokensTransferred: ITokensTransferred[];
  fees: [
    {
      token: {
        decimals: number;
        symbol: string;
        name: string;
        chainId: number;
        address: string;
        price: string;
      };
      amount: string;
      type: string;
    }
  ];
  txStatus: TxStatus;
  from: string;
  to: string;
  destTxStatus?: DestTxStatus;
  destTxHash?: string;
  patchState?: IPatchState;
  newAmountOut?: number;
  newAmountOutToDisplay?: number;
  newAmountOutVisibility?: boolean;
  newAmountOutUsdt?: string;
  patchTx?: any;
  fromTokensTransferred?: ITokensTransferred;
  toTokensTransferred?: ITokensTransferred;
  opportunity?: { name?: string; tokenA?: CrowdToken; tokenB?: CrowdToken };
  expand?: boolean;
  etf?: {
    etfName: string;
    planName: string;
  };
  isPatchable?: boolean;
  isCancelable?: boolean;
}

export interface ITokensTransferred {
  token: {
    decimals: number;
    symbol: string;
    name: string;
    chainId: number;
    address: string;
    price: string;
  };
  amount: string;
  transferType: string;
}

export enum DestTxStatus {
  Completed = 'completed',
  Canceled = 'canceled',
  Pending = 'pending',
  Unknown = 'unknown',
  Failed = 'failed',
  CancelPending = 'cancelPending'
}

export enum TxStatus {
  TXFailed,
  TransactionConfirmed,
  TXUnConfirmed,
  Cancelling
}

export class TxHistoryOrg {
  all: ITxHistoryToDisplay[] = [];
  inProgress: ITxHistoryToDisplay[] = [];
}

export enum TxType {
  Swap = 'Swap',
  CrossChain = 'Cross-Chain',
  CrossChainLimitOrder = 'Cross-Chain-LimitOrder',
  WithdrawLP = 'Withdraw-LP',
  WithdrawReward = 'Withdraw-Reward',
  Invest = 'Invest',
  undefined = 'Undefined',
  Stake = 'Stake',
  UnStake = 'Unstake',
  Send = 'Send',
  Receive = 'Receive',
  Farming = 'Farming',
  InvestEtf = 'InvestEtf',
  WithdrawEtf = 'WithdrawEtf'
}
