import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './views/pages/main/main.component';
import { CrossAndSameChainSwapComponent } from './views/pages/cross-and-same-chain-swap/cross-and-same-chain-swap.component';
import { StakeComponent } from './views/pages/stake/stake.component';
import { OpportunityComponent } from './views/pages/opportunity/opportunity.component';
import { OpportunityStakeComponent } from './views/pages/opportunity/stake/opportunity-stake.component';
import { InvestPrivateSaleComponent } from './views/pages/private-sale/invest-private-sale/invest-private-sale.component';
import { DetailsPrivateSaleComponent } from './views/pages/private-sale/details-private-sale/details-private-sale.component';
import { UserPortfolioComponent } from './views/pages/user-portfolio/user-portfolio.component';
import { FormPrivateSaleComponent } from './views/pages/private-sale/form-private-sale/form-private-sale.component';
import { LiquidityComponent } from './views/pages/liquidity/liquidity.component';
import { AffiliateTermsOfServiceComponent } from './views/pages/affiliate/affiliate-terms-of-service/affiliate-terms-of-service.component';
import { AffiliateComponent } from './views/pages/affiliate/affiliate.component';
import { VipRankingComponent } from './views/pages/user-portfolio/vip-ranking/vip-ranking.component';
import { FiatOnRampComponent } from './views/pages/fiat-on-ramp/fiat-on-ramp.component';
import { DexInfoComponent } from './views/pages/dex-info/dex-info.component';
import { TransferAssetsComponent } from './views/pages/transfer-assets/transfer-assets.component';
import { HistoryComponent } from './views/pages/user-portfolio/history/history.component';
import { ButtonComponent } from './share/components/button-component/button-component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'exchange',
    pathMatch: 'full'
  },
  {
    path: 'classicSwap',
    component: MainComponent
  },
  {
    path: 'exchange',
    children: [{ path: '**', component: CrossAndSameChainSwapComponent }]
  },
  {
    path: 'affiliate',
    children: [
      { path: 'revenue', component: AffiliateComponent },
      { path: 'profile', component: AffiliateComponent },
      {
        path: 'terms-of-service',
        component: AffiliateTermsOfServiceComponent
      },
      { path: '**', redirectTo: 'revenue' }
    ]
  },
  {
    path: 'portfolio',
    component: UserPortfolioComponent
  },
  {
    path: 'dex-info',
    component: DexInfoComponent
  },
  {
    path: 'opportunity/private-sale',
    component: InvestPrivateSaleComponent
  },
  {
    path: 'opportunity/private-sale-details',
    component: DetailsPrivateSaleComponent
  },
  {
    path: 'opportunity/form',
    component: FormPrivateSaleComponent
  },
  {
    path: 'opportunity',
    children: [{ path: '**', component: OpportunityComponent }]
  },
  // {
  //   path: 'liquidity',
  //   component: LiquidityComponent,
  // },
  {
    path: 'vip',
    component: VipRankingComponent
  },
  {
    path: 'fiat',
    component: FiatOnRampComponent
  },
  {
    path: 'transfer',
    component: TransferAssetsComponent
  },
  {
    path: 'history',
    component: HistoryComponent
  },
  {
    path: 'sample-button',
    component: ButtonComponent
  },
  { path: '**', redirectTo: 'exchange' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
