import { BigNumber } from 'ethers';
import { Token } from '@crowdswap/sdk';
import {
  Conversion,
  CrowdToken,
  Dexchanges,
  Networks,
  TokensHolder
} from '@crowdswap/constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, throttleTime } from 'rxjs/operators';
import { CurrentNetwork } from '../../model/current-network';
import { asyncScheduler, Subscription } from 'rxjs';
import { Constants } from '../../constants';
import {
  ConnectWalletService,
  DeviceType,
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../services';
import { environment } from '../../../environments/environment';
import {
  CrossAndSameChainEstimateTrade,
  SmartWalletConfirmation
} from 'src/app/model';
import {
  IConnectWalletRespMdl,
  ConnectWalletDialogComponent
} from '../modal/dialogs/connect-wallet-dialog/connect-wallet-dialog.component';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  template: ''
})
export abstract class BaseComponent implements OnInit, OnDestroy {
  public highPriceImpactPercent = environment.HIGH_PRICE_IMPACT_PERCENT;
  public warningPriceImpactPercent = environment.WARNING_PRICE_IMPACT_PERCENT;
  public scannerUrl = environment.SCANNER_BASEURL;
  public setting: {
    zeroAddress: string;
    isDarkMode;
    baseLogoIconsUrl;
    isMobile: boolean;
    showMaxTooltip?: boolean;
  } = {
    zeroAddress: '0x0000000000000000000000000000000000000000',
    isDarkMode: false,
    baseLogoIconsUrl: environment.BaseLogoIconsUrl,
    isMobile: false,
    showMaxTooltip: false
  };

  public status: {
    chainId;
    supportedNetwork: boolean;
    walletAddress: string;
    isUserWalletConnected: boolean;
    isWrongNetwork: boolean;
    incorrectNetworkMessage: string;
  } = {
    chainId: -1,
    supportedNetwork: false,
    walletAddress: '',
    isUserWalletConnected: false,
    isWrongNetwork: false,
    incorrectNetworkMessage: ''
  };

  protected subscriptionList: Subscription[] = [];
  connectWalletDialog!: import('ng-zorro-antd/modal').NzModalRef<
    ConnectWalletDialogComponent,
    any
  >;

  public get zeroAddress() {
    return this.setting.zeroAddress;
  }

  public get isDarkMode() {
    return this.setting.isDarkMode;
  }

  public changeDarkModeStatus() {
    if (localStorage.getItem('darkMode') === 'true') {
      localStorage.setItem('darkMode', 'false');
      this.isDarkMode = false;
    } else {
      localStorage.setItem('darkMode', 'true');
      this.isDarkMode = true;
    }

    this.themeService.darkModeChangeSubject.next(this.isDarkMode);
  }

  public set isDarkMode(_isDarkMode: boolean) {
    this.setting.isDarkMode = _isDarkMode;
  }

  public get baseLogoIconsUrl() {
    return this.setting.baseLogoIconsUrl;
  }

  public get chainId() {
    return this.status.chainId;
  }

  public set chainId(_chainId: number) {
    this.status.chainId = _chainId;
  }

  public get supportedNetwork() {
    return this.status.supportedNetwork;
  }

  public get walletAddress() {
    return this.status.walletAddress;
  }

  public set walletAddress(_walletAddress: string) {
    this.status.walletAddress = _walletAddress;
  }

  public get isUserWalletConnected() {
    return this.status.isUserWalletConnected;
  }

  public get isWrongNetwork() {
    return this.status.isWrongNetwork;
  }

  public set isWrongNetwork(_isWrongNetwork: boolean) {
    this.status.isWrongNetwork = _isWrongNetwork;
  }

  public get incorrectNetworkMessage() {
    return this.status.incorrectNetworkMessage;
  }

  public set incorrectNetworkMessage(_incorrectNetworkMessage: string) {
    this.status.incorrectNetworkMessage = _incorrectNetworkMessage;
  }

  protected constructor(
    protected web3Service: Web3Service,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    protected connectWalletService?: ConnectWalletService
  ) {
    this.setting.isMobile =
      [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
        this.clientInfoServiceImpl.getDeviceType()
      ) > -1;
  }

  public ngOnInit(
    onCurrentNetworkChange: ((currentNetwork) => void) | undefined = undefined,
    onWalletConnectionChange: ((connection) => void) | undefined = undefined,
    onAccountChange: ((address) => Promise<void>) | undefined = undefined
  ): void {
    this.status.isUserWalletConnected = this.web3Service.isConnected() || false;
    this.status.walletAddress = this.web3Service.getWalletAddress() || '';

    this.themeService.darkModeChangeSubject.subscribe((isDark) => {
      this.setting.isDarkMode = isDark;
    });

    // On network change
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          this.status.supportedNetwork = Dexchanges.Crowdswap.networks[
            currentNetwork.chainId
          ]
            ? true
            : false;
          this.status.chainId = currentNetwork.chainId;

          this.executeAction(onCurrentNetworkChange, currentNetwork);
        })
    );

    // On wallet connect/disconnect
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe(
        async (connection) => {
          this.status.isUserWalletConnected = connection;
          this.executeAction(onWalletConnectionChange, connection);
        }
      )
    );

    //The selected network in the wallet is not supported by our app
    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        (isWrongNetwork: boolean) => {
          this.status.isWrongNetwork = isWrongNetwork;
          if (this.status.isWrongNetwork) {
            this.status.incorrectNetworkMessage = 'Wrong network';
          }
        }
      )
    );

    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async (address) => {
        this.status.walletAddress = address;
        this.executeAction(onAccountChange, address);
      })
    );
  }

  public static _getDefaultTokens(fromChainId: number): CrowdToken[] {
    let toChainId: number = fromChainId;

    let _fromToken =
      TokensHolder.ObservableTokenListBySymbol[Networks[fromChainId]][
        Constants.DEFAULT_PAIRS[fromChainId].fromToken
      ];
    let _toToken =
      TokensHolder.ObservableTokenListBySymbol[Networks[toChainId]][
        Constants.DEFAULT_PAIRS[toChainId].toToken
      ];

    return [_fromToken, _toToken];
  }

  private async defiConnectWallet() {
    await this.tagManagerService.sendWalletConnectButtonPushedTag();
    await this.web3Service.openModal().catch(console.log);
  }

  private smartConnectWallet(address: string) {
    this.web3Service.openCrowdWallet(address);
  }

  public async connectWallet() {
    if (this.web3Service.isConnected() || !this.connectWalletService) {
      return;
    }

    if (!environment.SmartWallet) {
      this.defiConnectWallet();
      return;
    }

    this.connectWalletService
      .openConnectModal(this.isDarkMode)
      .afterClose.subscribe((resp: IConnectWalletRespMdl) => {
        switch (resp.type) {
          case 'DefiWallet':
            this.defiConnectWallet();
            break;

          case 'SmartWallet':
            if (resp.address) {
              this.smartConnectWallet(resp.address);
            }
            break;
        }
      });
  }

  public ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  //Remove duplicate
  public static removeDuplicateTokens(
    observableTokens,
    localStoredTokens: CrowdToken[]
  ): CrowdToken[] {
    const allTokens: CrowdToken[] = Object.values(observableTokens);
    localStoredTokens.forEach((localStoredToken) => {
      if (!localStoredToken.symbol) {
        return;
      }
      const foundToken = observableTokens[localStoredToken.symbol];
      if (!foundToken || foundToken.chainId !== localStoredToken.chainId) {
        allTokens.push(localStoredToken);
      }
    });
    return allTokens.sort(
      (token0, token1) =>
        parseFloat(token1.balance || '0') - parseFloat(token0.balance || '0')
    );
  }

  public updateUrl(el: any) {
    el.target.src =
      'https://raw.githubusercontent.com/Crowdswap/assets/master/icons/00-none.png';
  }

  public scrollTopContentSection(toInvestmentList?: boolean) {
    const contentSection = document.querySelector('.content-section');
    const investmentList = document.getElementById('investmentList');
    const top =
      toInvestmentList && investmentList ? investmentList.offsetTop - 60 : 0;

    if (contentSection) {
      setTimeout(() => {
        contentSection.scrollTo({
          behavior: 'smooth',
          top: top
        });
      }, 100);
    }
  }

  protected isCoin(token: Token): boolean {
    return TokensHolder.isBaseToken(token.chainId, token.address);
  }

  protected async checkIfAllowanceIsNeeded(
    token: Token,
    spender,
    amount: string,
    userAddress: string | undefined
  ) {
    try {
      if (userAddress) {
        if (!this.isCoin(token)) {
          let allowance: BigNumber = await this.web3Service
            .getAllowanceBySpender(spender, token.address, userAddress)
            .catch((e) => {
              console.log(e);
              return;
            });
          let allowanceValue = Conversion.toSafeBigNumberByRemovingFraction(
            allowance.toString()
          );
          let amountInValue = Conversion.toSafeBigNumberByRemovingFraction(
            Conversion.convertStringToDecimal(amount, token.decimals).toString()
          );
          if (allowanceValue.lt(amountInValue)) {
            return true;
          }
        }
      }
    } catch (e) {
      console.log(e);
      return true;
    }
    return false;
  }

  protected async changeNetworkTo(newChainId: number) {
    if (!this.status.isUserWalletConnected) {
      return;
    }
    if (newChainId !== this.web3Service.getWalletChainId()) {
      await this.web3Service.changeNetwork(newChainId);
    }
  }

  private executeAction(action: ((param) => void) | undefined, param) {
    if (action && typeof action === 'function') {
      try {
        action(param);
      } catch (err) {
        console.log(err);
      }
    }
  }

  protected openScanner(transaction: CrossAndSameChainEstimateTrade) {
    if (!transaction.swapTx || !transaction.swapTx.hash) {
      return;
    }

    const tx_hash = transaction.swapTx?.hash;
    const url = `${this.scannerUrl}/scanner/${tx_hash}`;
    window.open(url, '_blank');
  }

  runGleamScript(scriptKey: string) {
    // Check if Gleam script is already loaded
    if ((window as any).Gleam) {
      // Run the second script
      (window as any).Gleam.push([scriptKey, this.walletAddress]);
    } else {
      // If Gleam script is not loaded, handle it (optional)
      console.error('Gleam script not loaded!');
    }
  }

  public isEstimationCrossChain(estimation: CrossAndSameChainEstimateTrade) {
    return estimation.fromToken.chainId !== estimation.toToken.chainId;
  }
}
