import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { asyncScheduler, interval, Subject, Subscription } from 'rxjs';
import {
  Conversion,
  CrowdToken,
  MainNetworksById,
  Networks,
  NetworksById,
  OpportunitiesHolder,
  OpportunityInvestmentType,
  PriceService,
  TokensHolder
} from '@crowdswap/constant';
import { ModalService } from '../../../modal/modal.service';
import { Constants } from '../../../../constants';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  throttleTime
} from 'rxjs/operators';

import { WaitingDialogComponent } from '../../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { BigNumber } from 'ethers';
import { Router } from '@angular/router';
import {
  ConnectWalletService,
  CrowdSwapService,
  LoggingService,
  OpportunityService,
  TagManagerService,
  ThemeService,
  TokensService,
  UtilsService,
  Web3Service
} from '../../../../services';
import { CurrentNetwork, DialogDataModel } from '../../../../model';
import { CrossChainSelectOptionDialogComponent } from '../../../modal/dialogs/cross-chain-select-option-dialog/cross-chain-select-option-dialog.component';
import { OpportunityType } from '../../opportunity/model/opportunity-state.enum';
import { BaseEtfComponent } from '../base-etf.component';
import { EtfOpportunitiesService } from '../../../../services/etf-opportunities.service';
import { EtfOpportunityState } from '../model/etf-opportunity-state.enum';
import { NDDClientInfoServiceImpl } from 'src/app/services/client-info';
import { formatNumber, NumberType } from '@uniswap/conedison/format';
import { ConfirmEtfOpportunityDialogComponent } from '../../../modal/dialogs/confirm-etf-opportunity-dialog/confirm-etf-opportunity-dialog.component';
import { EtfInvestDisplayAmount } from '../model/etf.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-etf-invest',
  templateUrl: './etf-invest.component.html',
  styleUrls: ['./etf-invest.component.scss']
})
export class EtfInvestComponent
  extends BaseEtfComponent
  implements OnInit, OnDestroy
{
  public opportunityType = OpportunityType;
  public EtfOpportunityState = EtfOpportunityState;
  public InvestmentType = OpportunityInvestmentType;
  public lpDetails: boolean = false;
  public dialogOpened: boolean = false;
  public closedWaitingDialog: boolean = false;
  public subscriptionList: Subscription[] = [];
  public currentTokenList: CrowdToken[] = [];
  public opportunityTokenList: CrowdToken[] = [];
  public token!: CrowdToken;
  public tokenAmount = '0';
  public tokenAmountInUsd = '0';
  public etfOpportunityTx: any;
  public tokenInputNotifier = new Subject<number>();
  private readonly hasSevenDecimalLength = /^\d+(\.\d{7,})$/;
  public readonly MAX_ETF_INVEST_SLIPPAGE =
    CrowdSwapService.MAX_ETF_INVEST_SLIPPAGE;
  public readonly MIN_ETF_INVEST_SLIPPAGE =
    CrowdSwapService.MIN_ETF_INVEST_SLIPPAGE;
  public readonly DEFAULT_SLIPPAGE = +CrowdSwapService.OPPORTUNITY_SLIPPAGE;
  public slippage: string = CrowdSwapService.OPPORTUNITY_SLIPPAGE;
  public deadline: string = CrowdSwapService.DEADLINE;
  public loadingAmount: boolean = true;
  public disableConfirm: boolean = true;
  public currentChainId: number = Networks.POLYGON_MAINNET;
  public etfOpportunityState: EtfOpportunityState = EtfOpportunityState.Init;
  public selectedOpportunity;
  public detailsMsg: string = '';
  public investedTokens: any[] = [];
  public Networks = Networks;
  public slippages = ['0.5', '1', '2', '3'];
  private etfInvestFee = 0.0025;
  private crowdInvestFee = 0.001 / 0.999;
  private etfWithdrawFee = 0.005;
  public displayAmounts = new EtfInvestDisplayAmount();
  public customPatterns = { '00.00': { pattern: new RegExp('[0-9]{1}') } };
  _opportunity;
  private calculateExpireTimeSubscriber: any;
  @Input()
  set opportunity(opportunity: any) {
    this._opportunity = opportunity;
  }
  @Output()
  showSteps = new EventEmitter();

  @Output()
  updateDetails = new EventEmitter();

  get opportunity() {
    return this._opportunity;
  }

  constructor(
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    protected themeService: ThemeService,
    private router: Router,
    private toastr: ToastrService,
    private priceService: PriceService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private confirmEtfOppDialogService: ModalService<ConfirmEtfOpportunityDialogComponent>,
    private crossChainSelectOptionDialogComponentModalService: ModalService<CrossChainSelectOptionDialogComponent>,
    private utilsService: UtilsService,
    public logger: LoggingService,
    private tokensService: TokensService,
    protected tagManagerService: TagManagerService,
    private etfOpportunitiesService: EtfOpportunitiesService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    protected connectWalletService: ConnectWalletService
  ) {
    super(
      web3Service,
      themeService,
      tagManagerService,
      logger,
      clientInfoServiceImpl
    );
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    // this.tagManagerService.sendStartViewTag('ETF_opportunity_invest');
    this.selectedOpportunity =
      this._opportunity || (await this.checkUrlParams());
    this.etfOpportunityState = this.web3Service.isConnected()
      ? EtfOpportunityState.Init
      : EtfOpportunityState.WalletNotConnected;

    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe(
        async (connection) => {
          this.etfOpportunityState = EtfOpportunityState.Init;
          if (!connection) {
            this.hidingSteps();
            await this.router.navigate(['opportunity']);
            this.etfOpportunityState = EtfOpportunityState.WalletNotConnected;
          }
          this.ref.detectChanges();
        }
      )
    );
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          this.hidingSteps();
          this.currentChainId = currentNetwork.chainId;
          this.isWrongNetwork = false;

          this.ref.detectChanges();
        })
    );
    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        async (isWrongNetwork: boolean) => {
          if (isWrongNetwork) {
            this.hidingSteps();
            this.etfOpportunityState = EtfOpportunityState.WrongNetwork;
            this.isWrongNetwork = true;
            this.ref.detectChanges();
          }
        }
      )
    );
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        await this.updateBalances();
        await this.changeAmount(this.tokenAmount);

        if (
          this.etfOpportunityState !==
          EtfOpportunityState.InsufficientTokenBalance
        ) {
          this.hidingSteps();
        }
      })
    );

    this.tokenInputNotifier
      .pipe(
        debounceTime(OpportunityService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async (value) => {
        this.loadingAmount = true;
        this.tokenAmount = this.getCorrectAmount(value);
        await this.changeAmount(this.tokenAmount);
      });
    this.currentTokenList = this.tokensService.getAllTokens();
    this.opportunityTokenList = this.currentTokenList.filter(
      (item) =>
        item.chainId === this.selectedOpportunity.chainId &&
        this.symbolsToInclude.includes(item.symbol!)
    );
    this.token =
      TokensHolder.ObservableTokenListBySymbol[
        Networks[this.selectedOpportunity.chainId]
      ][Constants.DEFAULT_PAIRS[this.selectedOpportunity.chainId].fromToken];
    for (let item of this.selectedOpportunity.tokenShares) {
      let token = { symbol: '', amountOut: '' };

      token.symbol = item.token.symbol;
      token.amountOut = '-';
      this.investedTokens.push(token);
    }

    await this.initialTokens();
    this.ref.detectChanges();
  }

  async ngOnDestroy() {
    if (this.calculateExpireTimeSubscriber) {
      this.calculateExpireTimeSubscriber.unsubscribe();
    }
  }

  getCorrectAmount(amount: number) {
    let result = amount;
    if (!result) {
      result = 0;
    }
    if (this.hasSevenDecimalLength.test(amount.toString())) {
      result = parseFloat(Conversion.adjustFraction(amount, 7));
    }
    return result.toString();
  }

  async updateBalances(withRefresh: boolean = false) {
    this.loadingAmount = true;
    this.currentTokenList = await this.addBalanceToTokens(
      this.currentTokenList
    );
    this.opportunityTokenList = this.currentTokenList.filter(
      (item) =>
        item.chainId === this.selectedOpportunity.chainId &&
        this.symbolsToInclude.includes(item.symbol!)
    );

    this.token.balance = this.currentTokenList.filter(
      (token) =>
        token.chainId === this.token.chainId &&
        token.address.toLowerCase() === this.token.address.toLowerCase()
    )[0].balance;
    if (withRefresh) {
      this.tokenAmount = '1';
      await this.changeAmount(this.tokenAmount);
    }
    this.disableConfirm = false;
    this.ref.detectChanges();
  }

  async initialTokens() {
    await this.updateBalances();
    this.tokenAmount = '1';
    await this.changeAmount(this.tokenAmount);
  }

  async approve(): Promise<any> {
    try {
      if (this.currentChainId != this.token.chainId) {
        await this.web3Service.changeNetwork(this.token.chainId);
      }

      await this.getApprove(this.token);
    } catch (error) {
      console.log(error);
    }
  }

  async getApprove(token: CrowdToken): Promise<any> {
    // this.tagManager('invest_approve');

    let readyToApprove = true;
    try {
      this.logger.info(
        `Start getting approve for token [${token.symbol}], opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${EtfOpportunityState.ApprovalNeeded}]`
      );
      const userAddress = this.web3Service.getWalletAddress();
      if (!userAddress) {
        return;
      }

      const data = {
        hasWaitingText: true,
        hasTransaction: true,
        text1: `Authorize CrowdSwap to utilize your ${this.token.symbol} to proceed with the transaction.`,
        text3: `(Open Your Wallet)`
      };
      await this.showWaitingDialog(data);

      // this.tagManager(
      //   'invest_approve_waiting',
      //   this.tokenAmount,
      //   this.tokenAmountInUsd
      // );

      // Get approval tx
      let contractAddress = this._opportunity.contractAddress;

      let approvalTx = await this.web3Service.getApprovalTransactionBySpender(
        contractAddress,
        token.address,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      );
      // Send approval tx
      try {
        approvalTx = await this.web3Service.sendTransaction(approvalTx);
      } catch (error) {
        this.changeEtfState(EtfOpportunityState.ApprovalNeeded);
        this.showErrorToaster('Error', 'Approval rejected!');
        readyToApprove = false;
        this.setConsoleLog(
          this.selectedOpportunity,
          EtfOpportunityState.ApprovalNeeded,
          'Approval transaction is not submitted',
          error
        );

        return false;
      }
      if (!approvalTx) {
        this.changeEtfState(EtfOpportunityState.ApprovalNeeded);
        this.showErrorToaster('Error', 'Something went wrong!');
        this.setConsoleLog(
          this.selectedOpportunity,
          EtfOpportunityState.ApprovalNeeded,
          'Approval transaction is empty'
        );
        return false;
      }
      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.ApprovalNeeded,
        'Approval transaction is submitted'
      );
      // Wait for approval tx confirmation
      await this.web3Service
        .waitForTransaction(approvalTx, 1)
        .then(async (data) => {
          if (data?.status === 1) {
            this.changeEtfState(EtfOpportunityState.ApprovalConfirmed);
            this.setConsoleLog(
              this.selectedOpportunity,
              EtfOpportunityState.ApprovalConfirmed,
              'Approval transaction is confirmed'
            );
            await this.checkAllowance();
          } else {
            this.showErrorToaster('Error', 'Something went wrong!');
            this.changeEtfState(EtfOpportunityState.ApprovalNeeded);
            this.setConsoleLog(
              this.selectedOpportunity,
              EtfOpportunityState.ApprovalNeeded,
              'Approval transaction is not confirmed'
            );
          }
        })
        .catch((error) => {
          this.showErrorToaster('Error', 'Something went wrong!');
          this.changeEtfState(EtfOpportunityState.ApprovalNeeded);
          readyToApprove = false;
          this.setConsoleLog(
            this.selectedOpportunity,
            EtfOpportunityState.ApprovalNeeded,
            'Approval transaction is not confirmed',
            error
          );
        });
      this.logger.info(
        `Finish getting approve for token [${token.symbol}], opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${EtfOpportunityState.ApprovalNeeded}]`
      );
    } catch (error) {
      this.showErrorToaster('Error', 'Something went wrong!');
      this.changeEtfState(EtfOpportunityState.ApprovalNeeded);
      readyToApprove = false;

      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.ApprovalNeeded,
        'Approval transaction has error',
        error
      );
    } finally {
      await this.waitingDialogService.close();
    }
    return readyToApprove;
  }

  private async checkAllowance(): Promise<any> {
    try {
      if (
        this.isCoin(this.token) ||
        this.etfOpportunityState ==
          this.EtfOpportunityState.InsufficientTokenBalance
      ) {
        return;
      }
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        let contractAddress = this._opportunity.contractAddress;
        let provider = this.web3Service.web3Provider;
        if (this.currentChainId !== this.token.chainId) {
          provider = this.web3Service.getNetworkProvider(this.token.chainId);
        }
        if (!this.isCoin(this.token)) {
          let allowance: BigNumber = await this.web3Service
            .getAllowanceBySpender(
              contractAddress,
              this.token.address,
              userAddress,
              provider
            )
            .catch((e) => {
              console.log(e);
              this.showErrorToaster('Error', 'Something went wrong!');
              this.changeEtfState(EtfOpportunityState.ApprovalRejected);
              return;
            });
          let allowanceValue = Conversion.toSafeBigNumberByRemovingFraction(
            allowance.toString()
          );
          let amountInValue = Conversion.toSafeBigNumberByRemovingFraction(
            Conversion.convertStringToDecimal(
              this.tokenAmount,
              this.token.decimals
            ).toString()
          );
          if (allowanceValue.lt(amountInValue)) {
            console.log('allowance for ' + this.token.symbol);
            this.changeEtfState(EtfOpportunityState.ApprovalNeeded);
          }
        }
      }
    } catch (e) {
      console.log(e);
      this.changeEtfState(EtfOpportunityState.ApprovalRejected);
    } finally {
      this.ref.detectChanges();
    }
  }

  public async confirm() {
    try {
      // this.etfOpportunityTx = undefined;
      // this.changeEtfState(EtfOpportunityState.Init);
      // this.logger.info(
      //   `Start getting confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${EtfOpportunityState.Init}]`
      // );
      // const dataForEstimation = {
      //   hasWaitingText: true,
      //   text1: 'Waiting for estimation',
      //   hasTransaction: false
      // };
      // await this.showWaitingDialog(dataForEstimation);
      // this.token.price = await this.priceService.getPrice(
      //   this.token,
      //   this.web3Service.getNetworkProvider(this.token.chainId)
      // );

      // this.etfOpportunityTx = await this.getEtfInvest(
      //   this.selectedOpportunity,
      //   this.token,
      //   this.tokenAmount
      // );
      this.tagManager(
        'ETF_invest_start',
        this.tokenAmount,
        this.tokenAmountInUsd,
        this.displayAmounts.platformFeeToDisplay
      );
      if (!this.etfOpportunityTx) {
        this.showErrorToaster('Error', 'Something went wrong!');
        this.tagManager(
          'ETF_invest_error',
          this.tokenAmount,
          this.tokenAmountInUsd,
          this.displayAmounts.platformFeeToDisplay,
          'Something went wrong.'
        );
        await this.waitingDialogService.close();
        this.setConsoleLog(
          this.selectedOpportunity,
          EtfOpportunityState.Init,
          'Confirmed transaction is empty'
        );
        return;
      }
      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.Init,
        'Confirmed transaction is received from backend'
      );

      // this.investedTokens = this.getUserTokens(
      //   this.etfOpportunityTx,
      //   this.token,
      //   'invest'
      // );
      // const data = {
      //   unusualMode: false,
      //   withdrawTokensIcon: this.investedTokens,
      //   activeOpportunity: this.selectedOpportunity,
      //   className: 'confirm-etf-dialog'
      // };
      // if (this.closedWaitingDialog) {
      //   await this.waitingDialogService.close();
      //   return;
      // }
      // let confirmEtfOppDialogRef = await this.confirmEtfOppDialogService.open(
      //   ConfirmEtfOpportunityDialogComponent,
      //   data
      // );

      await this.confirmOpportunity(this.etfOpportunityTx.transaction);
      // const sub: Subscription = (<ConfirmEtfOpportunityDialogComponent>(
      //   (<any>confirmEtfOppDialogRef!.instance)
      // )).confirmed.subscribe(async (event) => {
      //   if (event) {
      //     await this.confirmEtfOppDialogService.close();
      //     await this.waitingDialogService.close();
      //     this.setConsoleLog(
      //       this.selectedOpportunity,
      //       EtfOpportunityState.Init,
      //       'Confirmed transaction has been sent to the provider'
      //     );
      //   } else {
      //     await this.waitingDialogService.close();
      //     await this.confirmEtfOppDialogService.close();
      //     this.setConsoleLog(
      //       this.selectedOpportunity,
      //       EtfOpportunityState.Init,
      //       'Confirmed transaction has not been sent to the provider'
      //     );
      //   }
      // });
      // this.ref.detectChanges();
      // confirmEtfOppDialogRef!.onDestroy(async () => {
      //   await this.waitingDialogService.close();
      //   sub.unsubscribe();
      // });
      this.logger.info(
        `Finish getting confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${EtfOpportunityState.Init}]`
      );
    } catch (error) {
      await this.waitingDialogService.close();
      this.showErrorToaster('Error', 'Something went wrong!');
      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.Init,
        'Confirming transaction is failed',
        error
      );
    }
  }

  private async getEtfEstimate(
    selectedOpportunity: any,
    token: CrowdToken,
    tokenAmount: string
  ) {
    this.loadingAmount = true;
    return await this.etfOpportunitiesService.invest(
      this.etfOpportunityState !==
        EtfOpportunityState.InsufficientTokenBalance &&
        this.isUserWalletConnected
        ? this.walletAddress
        : '',
      selectedOpportunity,
      token,
      Conversion.convertStringToDecimal(tokenAmount, token.decimals),
      this.slippage
    );
  }

  /**
   * On confirm button clicked.
   * The given transaction has been received from backend
   * @param opportunityTx
   */
  async confirmOpportunity(opportunityTx: any) {
    try {
      if (this.currentChainId != this.token.chainId) {
        await this.web3Service.changeNetwork(this.token.chainId);
      }
      this.logger.info(
        `Start getting final confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${EtfOpportunityState.Init}], transaction = [${opportunityTx}] `
      );
      const text = `Creating new the ${this.selectedOpportunity.planName} for ${this.tokenAmount} ${this.token.symbol}`;
      const data = {
        hasWaitingText: true,
        hasTransaction: true,
        text1: text
      };
      await this.showWaitingDialog(data);

      this.detailsMsg = ` selectedToken: ${this.token.symbol}(${this.token.chainId})(${this.token.address})}
      `;

      if (opportunityTx.value) {
        opportunityTx.value = BigNumber.from(opportunityTx.value);
      }

      // Send transaction to the MM
      const oppTx = {
        from: opportunityTx.from,
        to: opportunityTx.to,
        data: opportunityTx.data,
        value: opportunityTx.value,
        gas: BigNumber.from(opportunityTx.gasLimit).toString(),
        gasLimit: BigNumber.from(opportunityTx.gasLimit).toString()
      };

      this.tagManager(
        'ETF_invest_confirmation_view',
        this.tokenAmount,
        this.tokenAmountInUsd,
        ''
      );

      let currentTransaction: any;
      currentTransaction = await this.web3Service
        .sendTransaction(oppTx)
        .then(async (data) => {
          await this.waitingDialogService.close();
          this.changeEtfState(EtfOpportunityState.Confirmed);
          this.showSteps.emit(true);
          this.lpDetails = true;
          this.setConsoleLog(
            this.selectedOpportunity,
            EtfOpportunityState.Confirmed,
            'Opportunity submitted'
          );
          this.ref.detectChanges();
          return data;
        })
        .catch(async (error) => {
          await this.waitingDialogService.close();
          this.showSteps.emit(false);
          this.lpDetails = false;
          this.showErrorToaster('Error', 'Opportunity rejected!');
          this.changeEtfState(EtfOpportunityState.Rejected);
          this.tagManager(
            'ETF_invest_confirmation_reject',
            this.tokenAmount,
            this.tokenAmountInUsd,
            this.displayAmounts.platformFeeToDisplay
          );
          this.setConsoleLog(
            this.selectedOpportunity,
            EtfOpportunityState.Rejected,
            'Opportunity rejected!' + this.detailsMsg,
            error
          );
        });

      // If the transaction is confirmed by user
      if (currentTransaction) {
        this.tagManager(
          'ETF_invest_confirmation_confirm',
          this.tokenAmount,
          this.tokenAmountInUsd,
          this.displayAmounts.platformFeeToDisplay
        );
        // Wait for swap tx confirmation on blockchain
        await this.web3Service
          .waitForTransaction(currentTransaction, 1)
          .then(async (data) => {
            if (data?.status === 1) {
              this.tagManager(
                'ETF_invest_done',
                this.tokenAmount,
                this.tokenAmountInUsd,
                this.displayAmounts.platformFeeToDisplay,
                'Success.'
              );
              if (
                parseFloat(this.token.price) * parseFloat(this.tokenAmount) >=
                environment.airdrop.minimumEtfInvestAmount
              ) {
                this.runGleamScript('buyEtf');
              }

              this.setConsoleLog(
                this.selectedOpportunity,
                EtfOpportunityState.Successful,
                'Opportunity successful.' + this.detailsMsg
              );
              this.changeEtfState(EtfOpportunityState.Successful);
              this.ref.detectChanges();
            } else {
              this.changeEtfState(EtfOpportunityState.Failed);
              this.tagManager(
                'ETF_invest_error',
                this.tokenAmount,
                this.tokenAmountInUsd,
                this.displayAmounts.platformFeeToDisplay,
                'Something went wrong.'
              );
              this.ref.detectChanges();
              this.setConsoleLog(
                this.selectedOpportunity,
                EtfOpportunityState.Failed,
                'Opportunity failed.' + this.detailsMsg
              );
            }
            try {
              this.updateOppDetails();
              this.showingToastr(currentTransaction);
            } catch (error) {
              this.setConsoleLog(
                this.selectedOpportunity,
                this.etfOpportunityState,
                'Failed to show toaster or push tag' + this.detailsMsg,
                error
              );
            }
            return data;
          })
          .catch(async (error) => {
            this.tagManager(
              'ETF_invest_error',
              this.tokenAmount,
              this.tokenAmountInUsd,
              this.displayAmounts.platformFeeToDisplay,
              'Something went wrong.'
            );
            this.changeEtfState(EtfOpportunityState.Failed);
            this.setConsoleLog(
              this.selectedOpportunity,
              EtfOpportunityState.Failed,
              'Opportunity failed in internal catch.' + this.detailsMsg,
              error
            );
          });
      }
      this.logger.info(
        `Finish getting final confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${EtfOpportunityState.Init}]`
      );
    } catch (error) {
      this.tagManager(
        'ETF_invest_error',
        this.tokenAmount,
        this.tokenAmountInUsd,
        this.displayAmounts.platformFeeToDisplay,
        'Something went wrong.'
      );
      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.Failed,
        'Opportunity failed in external catch.' + this.detailsMsg,
        error
      );
    }
  }

  private async showingToastr(currentTransaction: any) {
    const scanTransactionUrl =
      this.web3Service.getScanTransactionUrl(currentTransaction);
    switch (this.etfOpportunityState) {
      case EtfOpportunityState.Successful: {
        this.toastr.success(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          `Deposit of ${this.tokenAmount} ${this.token.symbol} successfully executed!`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'successClass'
          }
        );
        this.web3Service.assetChangeSubject.next(true);
        break;
      }
      case EtfOpportunityState.Failed: {
        this.toastr.error(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          `Transaction(s) failed`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'failedClass'
          }
        );
        break;
      }
    }
  }

  private changeEtfState(opportunityState: EtfOpportunityState) {
    this.etfOpportunityState = opportunityState;
  }

  private async showWaitingDialog(data) {
    this.closedWaitingDialog = false;

    let waitingDialogRef = await this.waitingDialogService.open(
      WaitingDialogComponent,
      data
    );
    const sub: Subscription = (<ConfirmEtfOpportunityDialogComponent>(
      (<any>waitingDialogRef!.instance)
    )).closed.subscribe(async () => {
      this.closedWaitingDialog = true;
    });
    waitingDialogRef!.onDestroy(async () => {
      this.closedWaitingDialog = true;
      sub.unsubscribe();
    });
    this.ref.detectChanges();
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  notifyTokenInput(event) {
    this.disableConfirm = true;
    this.tokenInputNotifier.next(event);
  }

  async openSelectOptionDialog(allTokens) {
    if (this.dialogOpened) {
      return;
    }
    this.loadingAmount = true;
    this.dialogOpened = true;
    const data: DialogDataModel = {
      filteredTokenList: allTokens,
      toggle: true,
      chainId: -1,
      className: 'choose-asset-component',
      dontShowAllNetworks: true
    };
    let selectOptionDialogRef =
      await this.crossChainSelectOptionDialogComponentModalService.open(
        CrossChainSelectOptionDialogComponent,
        data
      );
    const sub: Subscription = (<CrossChainSelectOptionDialogComponent>(
      (<any>selectOptionDialogRef!.instance)
    )).confirmed.subscribe(async (token) => {
      if (token) {
        this.token = <any>token;
        this.token.price = await this.priceService.getPrice(
          this.token,
          this.web3Service.getNetworkProvider(this.token.chainId)
        );
        this.tokenAmount = '1';
        await this.changeAmount('1');
      } else {
        this.loadingAmount = false;
      }
      this.dialogOpened = false;
      await this.crossChainSelectOptionDialogComponentModalService.close();
    });
    const subscribeImportNewToken: Subscription = (<
      CrossChainSelectOptionDialogComponent
    >(<any>selectOptionDialogRef!.instance)).confirmedImportToken.subscribe(
      async (token: CrowdToken) => {
        this.currentTokenList.push(token);
      }
    );

    selectOptionDialogRef!.onDestroy(() => {
      this.dialogOpened = false;
      this.loadingAmount = false;
      sub.unsubscribe();
      subscribeImportNewToken.unsubscribe();
    });
  }

  async changeAmount(amount: string) {
    if (parseFloat(amount) === 0 || parseFloat(amount) <= 0.0000001) {
      this.tokenAmount = '0';
      this.loadingAmount = false;
      this.tokenAmountInUsd = '0';
      return;
    }
    this.etfOpportunityState = EtfOpportunityState.Init;
    this.etfOpportunityTx = undefined;
    this.tokenAmount = this.insufficientAmount(this.token, amount);
    await this.checkAllowance();

    this.etfOpportunityTx = await this.getEtfEstimate(
      this.selectedOpportunity,
      this.token,
      this.tokenAmount
    );

    if (!this.etfOpportunityTx) {
      this.setZero();
      this.loadingAmount = false;
      this.disableConfirm = false;
      return;
    }
    this.calculateEstimationExpireTime();

    this.investedTokens = this.getUserTokens(
      this.etfOpportunityTx,
      this.token,
      'invest'
    );
    this.token.price = await this.priceService.getPrice(
      this.token,
      this.web3Service.getNetworkProvider(this.token.chainId)
    );
    this.tokenAmountInUsd = formatNumber(
      parseFloat(this.token.price) * parseFloat(this.tokenAmount),
      NumberType.FiatTokenPrice
    );
    await this.calculateAmounts(this.etfOpportunityTx);
    if (
      !this.etfOpportunityTx.transaction &&
      this.etfOpportunityState === EtfOpportunityState.Init
    ) {
      this.etfOpportunityState = EtfOpportunityState.NoTransactionFound;
    }
    this.loadingAmount = false;
    this.disableConfirm = false;
  }

  private setZero() {
    this.displayAmounts = new EtfInvestDisplayAmount();
    this.tokenAmountInUsd = '--';
    this.etfOpportunityState = EtfOpportunityState.FailedEstimation;
  }

  private calculateEstimationExpireTime() {
    if (this.calculateExpireTimeSubscriber) {
      this.calculateExpireTimeSubscriber.unsubscribe();
    }
    const timer$: any = interval(1000);
    this.calculateExpireTimeSubscriber = timer$.subscribe(async (sec) => {
      if (sec > CrowdSwapService.INTERVAL_REFRESH_TIME) {
        if (
          this.etfOpportunityState !== EtfOpportunityState.Successful &&
          this.etfOpportunityState !== EtfOpportunityState.Failed
        ) {
          this.etfOpportunityState = EtfOpportunityState.FailedEstimation;
          this.calculateExpireTimeSubscriber.unsubscribe();
        }
      }
    });
  }

  private insufficientAmount(token: CrowdToken, tokenAmount: string) {
    let maxAmountInDecimal = Conversion.convertStringToDecimal(
      token.balance,
      token.decimals
    );
    if (TokensHolder.isBaseToken(token.chainId, token.address)) {
      maxAmountInDecimal = maxAmountInDecimal.sub(
        Conversion.convertStringToDecimal(
          Constants.REDUCE_MAX_AMOUNT_IN.toString(),
          token.decimals
        )
      );
    }
    const maxAmount = Conversion.convertStringFromDecimal(
      maxAmountInDecimal.toString(),
      token.decimals
    );
    if (parseFloat(maxAmount) < parseFloat(tokenAmount)) {
      this.etfOpportunityState = EtfOpportunityState.InsufficientTokenBalance;
    }
    return tokenAmount;
  }

  public async setMaxBalance(token: CrowdToken) {
    if (this.loadingAmount) {
      return;
    }
    this.loadingAmount = true;
    let maxAmountInDecimal = Conversion.convertStringToDecimal(
      token.balance,
      token.decimals
    );
    if (TokensHolder.isBaseToken(token.chainId, token.address)) {
      maxAmountInDecimal = maxAmountInDecimal.sub(
        Conversion.convertStringToDecimal(
          Constants.REDUCE_MAX_AMOUNT_IN.toString(),
          token.decimals
        )
      );
      await this.setMaxAmount(maxAmountInDecimal, token);
    } else {
      await this.setMaxAmount(maxAmountInDecimal, token);
    }
  }

  private async setMaxAmount(maxAmountInDecimal: BigNumber, token: CrowdToken) {
    maxAmountInDecimal =
      maxAmountInDecimal > BigNumber.from(0)
        ? maxAmountInDecimal
        : BigNumber.from(0);

    const maxAmountIn = Conversion.convertStringFromDecimal(
      maxAmountInDecimal.toString(),
      token.decimals
    );
    let amount = Conversion.adjustFraction(maxAmountIn, 7);

    this.tokenAmount = Conversion.adjustFraction(maxAmountIn, 7);

    this.loadingAmount = true;
    this.token = token;
    this.tokenAmount = maxAmountIn.toString();
    await this.changeAmount(amount.toString());
  }

  public toFixFraction(num: number | string | undefined, fraction: number = 7) {
    return num ? Conversion.adjustFraction(num, 7) : '0';
  }

  private async checkUrlParams() {
    const opportunity: string =
      UtilsService.getQueryVariable('opportunity') || '';
    const opportunities = OpportunitiesHolder.Opportunities;
    if (opportunities[opportunity]) {
      return opportunities[opportunity];
    }
    await this.router.navigate(['opportunity']);
  }

  hidingSteps() {
    this.lpDetails = false;
    this.changeEtfState(EtfOpportunityState.Init);
    this.showSteps.emit(false);
  }

  updateOppDetails() {
    this.updateDetails.emit(false);
  }

  public async addBalanceToTokens(tokens: any, withBalance: boolean = false) {
    const promises: any[] = [];
    let result: any[] = [];

    for (const chainId in MainNetworksById) {
      if (environment.ACTIVE_NETWORK.includes(chainId)) {
        let tempTokens = tokens.filter(
          (token: CrowdToken) => token.chainId.toString() === chainId
        );
        promises.push(this.web3Service.addBalanceToTokens(tempTokens));
      }
    }

    // result.push(...tempTokens);
    let promiseResult = await Promise.allSettled(promises);
    promiseResult.forEach((item, index, array) => {
      if (item && item.status === 'fulfilled' && item.value.length > 0) {
        result.push(...item.value);
      }
    });

    if (withBalance) {
      result = result.filter((token: CrowdToken) => {
        return token.balance && token.balance != '' && +token.balance > 0;
      });
      result = result.filter((token) =>
        result.some(({ symbol }) => token === symbol)
      );
    }
    return result;
  }

  async retry() {
    this.loadingAmount = true;
    this.etfOpportunityState = EtfOpportunityState.Init;
    await this.changeAmount(this.tokenAmount);
  }

  public async changeSlippage(slippage: string) {
    if (
      !parseFloat(slippage) ||
      parseFloat(slippage) > this.MAX_ETF_INVEST_SLIPPAGE ||
      parseFloat(slippage) < this.MIN_ETF_INVEST_SLIPPAGE
    ) {
      return false;
    }

    this.slippage = slippage;
    await this.changeAmount(this.tokenAmount);
  }

  private async calculateAmounts(etfOpportunityTx: any) {
    const plan = this.etfOpportunitiesService.findPlanByName(
      etfOpportunityTx.planName
    );

    const valueInUsd =
      parseFloat(this.token.price) * parseFloat(this.tokenAmount);
    this.displayAmounts.investAmountInUsd = '0';

    this.displayAmounts.minInvestAmountInUsd = '0';

    const investFee = valueInUsd * this.etfInvestFee;
    let aggregatorFee = 0;

    this.displayAmounts.totalFee = '0';

    this.displayAmounts.platformFee = 0;
    this.displayAmounts.platformFeeToDisplay = '0';

    this.displayAmounts.providersFee = 0;

    this.displayAmounts.networkFee = '-';

    let investAmountInUsd = 0;
    let minInvestAmountInUsd = 0;

    for (let item of etfOpportunityTx.swaps) {
      const toToken =
        TokensHolder.TokenListByAddress[NetworksById[plan.chainId]][
          item.toToken
        ];
      let convertedMinAmountOut = Conversion.convertStringFromDecimal(
        item.minAmountOut,
        toToken.decimals
      );
      investAmountInUsd += +(item.amountOutInUSDT ?? '0');
      minInvestAmountInUsd += +convertedMinAmountOut * item.toTokenPrice ?? 0;

      if (item.swapFeeInUSDT) {
        this.displayAmounts.providersFee += parseFloat(
          item.swapFeeInUSDT ?? '0'
        );

        aggregatorFee +=
          parseFloat(item.amountOutInUSDT ?? '0') * this.crowdInvestFee;
      }
    }

    if (etfOpportunityTx.transaction) {
      const gasPrice = (
        await this.web3Service.getGasPrice(
          this.web3Service.getNetworkProvider(plan.chainId)
        )
      ).toString();
      const gasLimitInDecimal = parseInt(
        etfOpportunityTx.transaction.gasLimit,
        16
      );
      const coinPrice = await this.priceService.getPrice(
        TokensHolder.ETH_ONLY[plan.chainId][0]
      );

      const maxNetworkFee = this.calculateGasCost(
        gasLimitInDecimal,
        gasPrice,
        coinPrice
      );
      this.displayAmounts.networkFee = `${formatNumber(
        maxNetworkFee * 0.7,
        NumberType.FiatTokenPrice
      )} ~ ${formatNumber(maxNetworkFee, NumberType.FiatTokenPrice)}`;
    }

    this.displayAmounts.investAmountInUsd = formatNumber(
      investAmountInUsd,
      NumberType.FiatTokenPrice
    );

    this.displayAmounts.minInvestAmountInUsd = formatNumber(
      minInvestAmountInUsd,
      NumberType.FiatTokenPrice
    );

    this.displayAmounts.withdrawFee = formatNumber(
      valueInUsd * this.etfWithdrawFee,
      NumberType.FiatTokenPrice
    );

    this.displayAmounts.providersFeeToDisplay = formatNumber(
      this.displayAmounts.providersFee,
      NumberType.FiatTokenPrice
    );

    this.displayAmounts.platformFee = aggregatorFee + investFee;
    this.displayAmounts.platformFeeToDisplay = formatNumber(
      this.displayAmounts.platformFee,
      NumberType.FiatTokenPrice
    );

    this.displayAmounts.finalPlatformFee = aggregatorFee;
    this.displayAmounts.finalPlatformFeeToDisplay = formatNumber(
      this.displayAmounts.finalPlatformFee,
      NumberType.FiatTokenPrice
    );

    this.displayAmounts.totalFee = formatNumber(
      this.displayAmounts.platformFee + this.displayAmounts.providersFee,
      NumberType.FiatTokenPrice
    );
  }

  calculateGasCost(gasUsed, gasPriceGwei, bnbPriceUSD): number {
    // Convert gas price from Gwei to BNB
    const gasPriceBNB = gasPriceGwei / 1e18; // 1 Gwei = 1e9 Wei

    // Calculate total gas cost in BNB
    const totalGasCostBNB = gasUsed * gasPriceBNB;

    // Convert BNB to USD
    const gasCostUSD = totalGasCostBNB * +bnbPriceUSD;

    return gasCostUSD;
  }

  public async tagManager(
    event: any,
    tokenAmount: string = '',
    amountInUsd: string = '',
    crowdswapFee: string = '',
    status: string = ''
  ) {
    await this.tagManagerService.sendEtfOpportunityTags(
      event,
      this.selectedOpportunity,
      'etf_invest',
      this.token,
      tokenAmount,
      amountInUsd,
      crowdswapFee,
      status
    );
  }

  public checkETFInvestSlippage(event: any) {
    const inputElement = event.target;
    const inputValue = inputElement.value.replace(/[^\d.]/g, ''); // Remove non-numeric characters
    if (
      +inputValue &&
      (+inputValue > this.MAX_ETF_INVEST_SLIPPAGE ||
        +inputValue < this.MIN_ETF_INVEST_SLIPPAGE)
    ) {
      inputElement.value = inputValue.slice(0, -1); // Trim last character if it exceeds max value
      this.slippage = inputElement.value; // Update ngModel value
    }
  }
}
