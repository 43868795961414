import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { NzNotificationDataOptions } from 'ng-zorro-antd/notification';

export interface INotificationData<T> {
  title: string;
  content: string;
  options?: T;
}

@Injectable()
export class NotificationService {
  constructor(protected toastrService: ToastrService) {}

  error<T>(data: INotificationData<T>) {
    this.toastrService.error(
      data.content,
      data.title,
      <Partial<IndividualConfig<any>> | undefined>data.options ?? {
        closeButton: true,
        tapToDismiss: false,
        progressBar: true,
        positionClass: 'custom-toast-top-right',
        enableHtml: true,
        timeOut: 10000,
        messageClass: 'errorClass'
      }
    );
  }

  success<T>(data: INotificationData<T>) {
    this.toastrService.success(
      data.content,
      data.title,
      <Partial<IndividualConfig<any>> | undefined>data.options ?? {
        closeButton: true,
        tapToDismiss: false,
        progressBar: true,
        positionClass: 'custom-toast-top-right',
        enableHtml: true,
        timeOut: 10000,
        messageClass: 'successClass'
      }
    );
  }
}
