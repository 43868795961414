import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Conversion } from '@crowdswap/constant';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../../../../../environments/environment';
import { BaseComponent } from '../../base.component';
import { formatNumber, NumberType } from '@uniswap/conedison/format';
import {
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../../services';
import { Constants } from '../../../../constants';
import { EstimateTrade } from '../../../../model/estimate-trade.model';

@Component({
  selector: 'app-estimation-item[estimation]',
  templateUrl: './estimation-item.component.html',
  styleUrls: ['./estimation-item.component.scss']
})
export class EstimationItemComponent extends BaseComponent implements OnInit {
  estimationExtraValue: {
    formattedAmountOut: string | undefined;
    totalPaid: string | undefined;
    totalPaidToDisplay: string | undefined;
    cost: string | undefined;
    youSavePercentage: string | undefined;
    youSave: string | undefined;
  } = {
    formattedAmountOut: undefined,
    totalPaid: undefined,
    totalPaidToDisplay: undefined,
    cost: undefined,
    youSavePercentage: undefined,
    youSave: undefined
  };
  public isSelected: boolean = false;

  _estimation: EstimateTrade | undefined = undefined;
  @Input()
  set estimation(estimation: EstimateTrade | undefined) {
    this._estimation = estimation;

    this.calculateExtraValue(this._estimation, this._compareWithEstimation);
  }

  get estimation() {
    return this._estimation;
  }

  _compareWithEstimation: EstimateTrade | undefined = undefined;
  @Input()
  set compareWithEstimation(estimation: EstimateTrade | undefined) {
    this._compareWithEstimation = estimation;
    this.calculateExtraValue(this._estimation, this._compareWithEstimation);
  }

  get compareWithEstimation() {
    return this._compareWithEstimation;
  }

  @Input()
  selectedEstimation: EstimateTrade | undefined = undefined;

  @Input()
  bestPrice: boolean = false;

  @Output()
  onEstimationSelected = new EventEmitter();

  constructor(
    private $gtmService: GoogleTagManagerService,
    protected web3Service: Web3Service,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  async onEstimationClick($event: MouseEvent) {
    await this.$gtmService.pushTag({
      event: 'estimation_is_clicked',
      category: 'search',
      dex: this._estimation?.dex.name,
      delegatedDex: this._estimation?.delegatedDex,
      sourceTokenSymbol: this._estimation?.fromToken.symbol,
      destinationTokenSymbol: this._estimation?.toToken.symbol,
      amountIn: this._estimation?.amountIn,
      amountOut: this._estimation?.amountOut,
      isConnected: this.web3Service.isConnected()
    });

    if (this.estimation?.loading) {
      return;
    }
    if (environment.qa) {
      this._estimation === this.selectedEstimation
        ? (this.isSelected = false)
        : (this.isSelected = true);
    }
    this.onEstimationSelected.emit(
      this._estimation === this.selectedEstimation
        ? undefined
        : this._estimation
    );
  }

  private calculateExtraValue(
    estimation: EstimateTrade | undefined,
    compareWithEstimation: EstimateTrade | undefined
  ) {
    if (!estimation) {
      return;
    }
    let youSave = 0,
      difference = 1;
    if (compareWithEstimation) {
      youSave =
        +estimation.totalIncomeInUSDT -
        +compareWithEstimation.totalIncomeInUSDT;
      difference =
        +compareWithEstimation.totalPaidInUSDT -
          (+estimation.amountOutInUSDT -
            +compareWithEstimation.amountOutInUSDT) || 1;
    }
    this.estimationExtraValue = {
      formattedAmountOut: EstimationItemComponent.getAmountOut(estimation),
      totalPaid: estimation.totalPaidInUSDT,
      totalPaidToDisplay: estimation.totalPaidInUSDTToDisplay,
      cost: estimation.cost ? Conversion.adjustFraction(estimation.cost) : '',
      youSavePercentage: Conversion.adjustFraction(
        Math.abs(youSave / difference) * 100
      ),
      youSave:
        youSave > 0
          ? formatNumber(youSave, NumberType.FiatTokenPrice)
          : undefined
    };
    estimation.route = '';
    if (estimation.routes.length > 0) {
      for (let [index, item] of estimation.routes.entries()) {
        estimation.route += index === 0 ? '' : Constants.ARROW_UNICODE;
        estimation.route += item ? estimation.routes[index].symbol : '';
      }
    }
  }

  private static getAmountOut(estimation: EstimateTrade): string | undefined {
    return formatNumber(
      parseFloat(
        Conversion.convertStringFromDecimal(
          estimation.amountOut.toString(),
          estimation.toToken.decimals
        )
      ),
      NumberType.SwapTradeAmount
    );
  }
}
