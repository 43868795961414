import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { Web3Service } from '../../../../services';
import { SharedModule } from 'src/app/share/shared.module';

@Component({
  selector: 'app-withdraw-extend-dialog',
  templateUrl: './withdraw-extend-dialog.component.html',
  styleUrls: ['./withdraw-extend-dialog.component.scss']
})
export class WithdrawExtendDialogComponent extends DialogsComponent {
  @Output()
  confirmed = new EventEmitter<boolean>();

  constructor(public web3Service: Web3Service) {
    super();
  }

  withdraw() {
    this.confirmed.emit(true);
  }

  close() {
    this.closed.emit(true);
  }
}
@NgModule({
  imports: [CommonModule, ModalModule, SharedModule.forRoot()],
  declarations: [WithdrawExtendDialogComponent]
})
export class WithdrawExtendModeDialogComponentModule {}
