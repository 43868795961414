import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalComponent } from './modal.component';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
  declarations: [ModalComponent, NotificationComponent],
  imports: [CommonModule],
  exports: [ModalComponent, NotificationComponent]
})
export class ModalModule {}
