<div class="w-full opportunity_page_items">
  <div
    class="w-full d-flex flex-column justify-center py-25px px-0 px-sm-25px border-radius-6px border-md-2px border-00000010 dark:border-1E1E1E dark:bg-121212"
  >
    <div class="x-1 z-0 col-12">
      <div class="swap-box z-0" id="assetBox" *ngIf="!lpDetails">
        <div class="swap-token-header">
          <span class="title font-12 dark:text-fff">INVEST AMOUNT </span>
        </div>

        <div
          class="swap-token-box border-2 border-0066FF dark:border-3385ff bg-white dark:bg-121212"
          id="choose-asset-token"
        >
          <div class="swap-token-select my-3">
            <div
              class="absolute right-5 margin-top-6 mr-2 w-1/2 md:w-1/3 lg:w-5/12"
            >
              <div
                *ngIf="isUserWalletConnected"
                class="d-flex align-items-center justify-content-end"
              >
                <div class="balance text-gray-400 dark:text-darkGrayText">
                  Balance: {{ toFixFraction(token?.balance) }}
                </div>

                <div
                  class="font-14 font-openSans-regular text-0066FF dark:text-3385FF"
                >
                  <button
                    (click)="setMaxBalance(token)"
                    [disabled]="
                      loadingAmount ||
                      etfOpportunityState === EtfOpportunityState.Confirmed
                    "
                  >
                    Max
                  </button>
                </div>
              </div>

              <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                <button
                  id="source-option"
                  (click)="openSelectOptionDialog(opportunityTokenList)"
                  [disabled]="
                    loadingAmount ||
                    etfOpportunityState === EtfOpportunityState.Confirmed
                  "
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="absolute right-0 token-style border-radius-4px d-flex align-items-center cursor-pointer bg-F7F7F7 dark:bg-1E1E1E"
                  type="button"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="token-symbol">
                      <img
                        class="rounded-full"
                        width="20"
                        [src]="
                          baseLogoIconsUrl +
                          token?.symbol?.toLowerCase() +
                          '.png'
                        "
                        (error)="updateUrl($event)"
                      />
                    </div>
                  </div>

                  <div class="token-selected">
                    <span class="symbol text-000000cc dark:text-fff">
                      {{ token?.symbol }}
                    </span>
                  </div>

                  <div class="token-arrow text-000000cc dark:text-fff">
                    <svg
                      class="-mr-1 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>

            <div class="dropdown w-1/2 md:w-2/3 lg:w-7/12">
              <div>
                <input
                  appFocusAndSelectValue
                  [el]="chooseAssetToken"
                  #chooseAssetToken
                  type="text"
                  inputmode="decimal"
                  [(ngModel)]="tokenAmount"
                  mask="separator"
                  (ngModelChange)="notifyTokenInput($event)"
                  [disabled]="
                    loadingAmount ||
                    etfOpportunityState === EtfOpportunityState.Confirmed
                  "
                  class="w-full border-0 p-0 outline-none font-32 shadow-none bg-transparent dark:text-fff"
                />
                <nz-skeleton-element
                  class="skeleton-h-12"
                  nzType="button"
                  [nzActive]="true"
                  [nzSize]="'small'"
                  *ngIf="loadingAmount"
                ></nz-skeleton-element>
                <div *ngIf="!loadingAmount">~&nbsp;{{ tokenAmountInUsd }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="swap-token-header mt-2">
          <span class="title font-12 dark:text-fff">Slippage </span>
        </div>

        <div
          class="w-full px-2 pb-2 pt-1 d-flex flex-column border-2px border-0066FF dark:border-3385ff border-radius-8px bg-white dark:bg-121212"
        >
          <div
            class="w-full border-radius-4px d-flex align-items-center amount-label position-relative"
            [ngClass]="{
              'justify-content-between': +slippage < DEFAULT_SLIPPAGE
            }"
          >
            <input
              type="text"
              nz-input
              class="font-18 font-xs-28 text-000 dark:text-fff m-0 border-0 bg-transparent outline-none shadow-none border-none"
              [(ngModel)]="slippage"
              (ngModelChange)="changeSlippage(slippage)"
              inputmode="decimal"
              mask="percent.2"
              suffix="%"
              (input)="checkETFInvestSlippage($event)"
            />

            <div
              *ngIf="+slippage < DEFAULT_SLIPPAGE"
              class="d-flex align-items-center text-cd6116 dark:text-fff me-3"
            >
              <svg
                nz-tooltip
                [nzTooltipTitle]="slippageTooltip"
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                class="ng-star-inserted"
                [nzTooltipOverlayClassName]="
                  isDarkMode
                    ? 'dark dark-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                    : 'white-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                "
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m10.24 3.957l-8.422 14.06A1.989 1.989 0 0 0 3.518 21h16.845a1.989 1.989 0 0 0 1.7-2.983L13.64 3.957a1.989 1.989 0 0 0-3.4 0zM12 9v4m0 4h.01"
                ></path>
              </svg>

              <ng-template #slippageTooltip>
                <div class="text-sm text-center text-white bg-black">
                  <span
                    >Lowering slippage below {{ DEFAULT_SLIPPAGE }}% can
                    increase failed transaction risk in volatile markets.</span
                  >
                </div>
              </ng-template>
            </div>
          </div>

          <div
            class="flex w-full justify-content-between font-14 font-openSans-semiBold text-0066FF dark:text-fff"
          >
            <button
              *ngFor="let item of slippages"
              (click)="changeSlippage(item)"
              class="py-1 px-3 rounded-md d-flex align-items-center justify-content-center"
              [ngClass]="{
                'border-0066FF dark:border-3385ff border-2': +slippage === +item
              }"
            >
              <span> {{ item }}% </span>
            </button>
          </div>
        </div>

        <div
          class="w-full p-2 d-flex flex-column mt-2 border-2px"
          [ngClass]="{
            'border-595962 border-radius-8px is-dark': isDarkMode,
            'border-9797971a border-radius-6px bg-FAFAFA': !isDarkMode
          }"
        >
          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-7D7D7D dark:text-fff"
          >
            <div class="d-flex align-items-center">
              <span> Expected ETF value: </span>

              <span
                class="text-323232 dark:text-FFFFFFBF ml-1 cursor-pointer"
                nz-tooltip
                [nzTooltipTitle]="InvestmentTooltipText"
                [nzTooltipOverlayClassName]="
                  isDarkMode
                    ? 'dark dark-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                    : 'white-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                "
                [nzTooltipTrigger]="setting.isMobile ? 'click' : 'hover'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6" clip-path="url(#clip0_5395_63724)">
                    <path
                      d="M6.41602 9.9165H7.58268V6.4165H6.41602V9.9165ZM6.99935 1.1665C3.77935 1.1665 1.16602 3.77984 1.16602 6.99984C1.16602 10.2198 3.77935 12.8332 6.99935 12.8332C10.2193 12.8332 12.8327 10.2198 12.8327 6.99984C12.8327 3.77984 10.2193 1.1665 6.99935 1.1665ZM6.99935 11.6665C4.42685 11.6665 2.33268 9.57234 2.33268 6.99984C2.33268 4.42734 4.42685 2.33317 6.99935 2.33317C9.57185 2.33317 11.666 4.42734 11.666 6.99984C11.666 9.57234 9.57185 11.6665 6.99935 11.6665ZM6.41602 5.24984H7.58268V4.08317H6.41602V5.24984Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <ng-template #InvestmentTooltipText>
                  <div class="d-flex flex-column font-12">
                    <span>
                      Your output may be slightly lower than the entered value
                      due to price impact from the swaps.
                    </span>
                  </div>
                </ng-template>
              </span>
            </div>

            <nz-skeleton-element
              class="skeleton-h-12"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="loadingAmount"
            ></nz-skeleton-element>

            <div
              class="d-flex align-items-center pl-3 justify-content-end flex-wrap"
              *ngIf="!loadingAmount"
            >
              <span>
                {{ displayAmounts.investAmountInUsd }}
              </span>
            </div>
          </div>

          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-7D7D7D dark:text-fff mt-1"
          >
            <div class="d-flex align-items-center">
              <span> Minimum ETF value: </span>

              <span
                class="text-323232 dark:text-FFFFFFBF ml-1 cursor-pointer"
                nz-tooltip
                [nzTooltipTitle]="MinimumETFValue"
                [nzTooltipOverlayClassName]="
                  isDarkMode
                    ? 'dark dark-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                    : 'white-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                "
                [nzTooltipTrigger]="setting.isMobile ? 'click' : 'hover'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6" clip-path="url(#clip0_5395_63724)">
                    <path
                      d="M6.41602 9.9165H7.58268V6.4165H6.41602V9.9165ZM6.99935 1.1665C3.77935 1.1665 1.16602 3.77984 1.16602 6.99984C1.16602 10.2198 3.77935 12.8332 6.99935 12.8332C10.2193 12.8332 12.8327 10.2198 12.8327 6.99984C12.8327 3.77984 10.2193 1.1665 6.99935 1.1665ZM6.99935 11.6665C4.42685 11.6665 2.33268 9.57234 2.33268 6.99984C2.33268 4.42734 4.42685 2.33317 6.99935 2.33317C9.57185 2.33317 11.666 4.42734 11.666 6.99984C11.666 9.57234 9.57185 11.6665 6.99935 11.6665ZM6.41602 5.24984H7.58268V4.08317H6.41602V5.24984Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <ng-template #MinimumETFValue>
                  <div class="d-flex flex-column font-12">
                    <span>
                      Due to slippage (fluctuations in swap rates), the final
                      investment amount may be reduced. Transactions will be
                      cancelled if they don't reach the minimum required.
                    </span>
                  </div>
                </ng-template>
              </span>
            </div>
            <nz-skeleton-element
              class="skeleton-h-12"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="loadingAmount"
            ></nz-skeleton-element>

            <span
              *ngIf="!loadingAmount"
              class="pl-3 text-right d-flex flex-wrap align-items-center"
            >
              {{ displayAmounts.minInvestAmountInUsd }}
            </span>
          </div>

          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-7D7D7D dark:text-fff mt-1"
          >
            <div class="d-flex align-items-center">
              <span> Platform Fee: </span>
            </div>

            <nz-skeleton-element
              class="skeleton-h-12"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="loadingAmount"
            ></nz-skeleton-element>

            <div class="d-flex align-items-center" *ngIf="!loadingAmount">
              <span class="text-right d-flex flex-wrap align-items-center">
                <!-- class="pr-3 text-right d-flex flex-wrap align-items-center text-decoration-line-through" -->
                {{ displayAmounts.platformFeeToDisplay }}
              </span>

              <!-- <span class="pl-3 text-right d-flex flex-wrap align-items-center">
                {{ displayAmounts.finalPlatformFeeToDisplay }}
              </span> -->
            </div>
          </div>

          <div
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-7D7D7D dark:text-fff mt-1"
          >
            <div class="d-flex align-items-center">
              <span> Liquidity Provider Fee: </span>
            </div>

            <nz-skeleton-element
              class="skeleton-h-12"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="loadingAmount"
            ></nz-skeleton-element>

            <span
              *ngIf="!loadingAmount"
              class="pl-3 text-right d-flex flex-wrap align-items-center"
            >
              {{ this.displayAmounts.providersFeeToDisplay }}
            </span>
          </div>

          <div
            *ngIf="displayAmounts.networkFee !== '-'"
            class="w-full d-flex flex-wrap align-items-center justify-content-between font-12 text-7D7D7D dark:text-fff mt-1"
          >
            <div class="d-flex align-items-center">
              <span> Network Fee: </span>

              <span
                class="text-323232 dark:text-FFFFFFBF ml-1 cursor-pointer"
                nz-tooltip
                [nzTooltipTitle]="NetworkFeeTooltipText"
                [nzTooltipOverlayClassName]="
                  isDarkMode
                    ? 'dark dark-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                    : 'white-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                "
                [nzTooltipTrigger]="setting.isMobile ? 'click' : 'hover'"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6" clip-path="url(#clip0_5395_63724)">
                    <path
                      d="M6.41602 9.9165H7.58268V6.4165H6.41602V9.9165ZM6.99935 1.1665C3.77935 1.1665 1.16602 3.77984 1.16602 6.99984C1.16602 10.2198 3.77935 12.8332 6.99935 12.8332C10.2193 12.8332 12.8327 10.2198 12.8327 6.99984C12.8327 3.77984 10.2193 1.1665 6.99935 1.1665ZM6.99935 11.6665C4.42685 11.6665 2.33268 9.57234 2.33268 6.99984C2.33268 4.42734 4.42685 2.33317 6.99935 2.33317C9.57185 2.33317 11.666 4.42734 11.666 6.99984C11.666 9.57234 9.57185 11.6665 6.99935 11.6665ZM6.41602 5.24984H7.58268V4.08317H6.41602V5.24984Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>

                <ng-template #NetworkFeeTooltipText>
                  <div class="d-flex flex-column font-12">
                    <span> Estimated based on the network situation </span>
                  </div>
                </ng-template>
              </span>
            </div>

            <nz-skeleton-element
              class="skeleton-h-12"
              nzType="button"
              [nzActive]="true"
              nzSize="small"
              *ngIf="loadingAmount"
            ></nz-skeleton-element>

            <span
              *ngIf="!loadingAmount"
              class="pl-3 text-right d-flex flex-wrap align-items-center"
            >
              {{ displayAmounts.networkFee }}
            </span>
          </div>
        </div>

        <div
          *ngIf="etfOpportunityState === EtfOpportunityState.ApprovalConfirmed"
          class="flex mt-2 ml-3 pl-2"
        >
          <div class="flex items-center">
            <svg
              class="h-6 w-6 text-green-300 opacity-50"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </div>

          <div class="text-xs text-gray-400 ml-2 flex items-center">
            Now you can invest
            {{ token.symbol }}
          </div>
        </div>

        <div
          *ngIf="
            this.etfOpportunityState === EtfOpportunityState.ApprovalNeeded
          "
          class="flex flex-col mt-2"
        >
          <button
            nz-button
            nzType="default"
            (click)="approve()"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          >
            <span class="px-1"> Allow to invest the {{ token.symbol }} </span>
          </button>
        </div>

        <div
          *ngIf="
            etfOpportunityState !==
              EtfOpportunityState.InsufficientTokenBalance &&
            isUserWalletConnected &&
            !isWrongNetwork
          "
          class="flex flex-col mt-2"
        >
          <button
            *ngIf="
              etfOpportunityState !== EtfOpportunityState.FailedEstimation &&
              etfOpportunityState !== EtfOpportunityState.NoTransactionFound
            "
            nz-button
            nzType="default"
            (click)="confirm()"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [ngClass]="{
              'opacity-4':
                disableConfirm ||
                loadingAmount ||
                +tokenAmount === 0 ||
                etfOpportunityState === EtfOpportunityState.Confirmed ||
                etfOpportunityState === EtfOpportunityState.ApprovalNeeded ||
                etfOpportunityState === EtfOpportunityState.ApprovalRejected
            }"
            [disabled]="
              disableConfirm ||
              loadingAmount ||
              +tokenAmount === 0 ||
              etfOpportunityState === EtfOpportunityState.Confirmed ||
              etfOpportunityState === EtfOpportunityState.ApprovalNeeded ||
              etfOpportunityState === EtfOpportunityState.ApprovalRejected
            "
          >
            <span class="px-1"> Invest </span>
          </button>

          <button
            *ngIf="etfOpportunityState === EtfOpportunityState.FailedEstimation"
            nz-button
            nzType="default"
            (click)="retry()"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          >
            <span class="px-1"> Restart estimation </span>
          </button>

          <button
            *ngIf="
              etfOpportunityState === EtfOpportunityState.NoTransactionFound
            "
            nz-button
            nzType="default"
            [disabled]="true"
            [ngClass]="{ 'opacity-4': true }"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          >
            <span class="px-1"> No profitable route found </span>
          </button>
        </div>

        <div
          *ngIf="
            isUserWalletConnected &&
            etfOpportunityState === EtfOpportunityState.InsufficientTokenBalance
          "
          class="flex flex-col mt-2"
        >
          <button
            nz-button
            nzType="default"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [ngClass]="{ 'opacity-4': true }"
            [disabled]="true"
          >
            <span class="px-1">
              Insufficient
              {{ token.symbol }}
              balance
            </span>
          </button>
        </div>

        <div
          *ngIf="
            isUserWalletConnected &&
            etfOpportunityState === EtfOpportunityState.WrongNetwork
          "
          class="flex flex-col mt-2"
        >
          <button
            nz-button
            nzType="default"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [ngClass]="{ 'opacity-4': true }"
            [disabled]="true"
          >
            <span class="px-1"> Wrong network </span>
          </button>
        </div>

        <div *ngIf="!isUserWalletConnected" class="flex flex-col mt-2">
          <button
            nz-button
            nzType="default"
            (click)="connectWallet()"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          >
            <span class="px-1"> Connect Wallet </span>
          </button>
        </div>
      </div>

      <div *ngIf="lpDetails" class="col-12 px-1">
        <app-farm-confirmation
          (hideSteps)="hidingSteps()"
          [opportunityState]="etfOpportunityState"
          [token]="token.symbol"
          [selectedOpportunity]="selectedOpportunity"
          [investedTokens]="investedTokens"
          [investmentAmount]="tokenAmount"
          [isWithdraw]="false"
        >
        </app-farm-confirmation>
      </div>
    </div>
  </div>
</div>
