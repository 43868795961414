import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoggingService } from './log/service/logging.service';
import {
  AvalancheService,
  BscService,
  EthereumService,
  INetworkService,
  Networks,
  PolygonService,
  ApexService,
  ArbitrumService,
  ZksyncService,
  OptimismService,
  LineaService,
  BaseService,
  DeFiService,
  RootstockService
} from '@crowdswap/constant';

const baseUrl = environment.apiBaseUrl || '';

@Injectable()
export class NetworksService {
  private ethereumNetworkProvider: INetworkService | undefined;
  private ropstenNetworkProvider: INetworkService | undefined;
  private rinkebyNetworkProvider: INetworkService | undefined;
  private goerliNetworkProvider: INetworkService | undefined;
  private kovanNetworkProvider: INetworkService | undefined;
  private BSCNetworkProvider: INetworkService | undefined;
  private BSCTestNetworkProvider: INetworkService | undefined;
  private polygonNetworkProvider: INetworkService | undefined;
  private mumbaiNetworkProvider: INetworkService | undefined;
  private avalancheNetworkProvider: INetworkService | undefined;
  private apexNetworkProvider: INetworkService | undefined;
  private fujiNetworkProvider: INetworkService | undefined;
  private arbitrumNetworkProvider: INetworkService | undefined;
  private zksyncNetworkProvider: INetworkService | undefined;
  private zksyncTestNetworkProvider: INetworkService | undefined;
  private optimismNetworkProvider: INetworkService | undefined;
  private lineaNetworkProvider: INetworkService | undefined;
  private baseNetworkProvider: INetworkService | undefined;
  private defichainNetworkProvider: INetworkService | undefined;
  private rootstockNetworkProvider: INetworkService | undefined;

  constructor(private http: HttpClient, private logger: LoggingService) {}
  public async getNetworkRPCUrlById(id: number): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/network/network/${id}`;
      return this.http.get(url).toPromise();
    } catch (err: any) {
      console.error(`Error message: ${err.message}`);
    }
    throw new Error('Unable to get Networks');
  }

  public getNetworkProvider(chainId) {
    switch (chainId.toString()) {
      case Networks.MAINNET.toString():
        return this.getEthereumNetworkProvider();
      case Networks.ROPSTEN.toString():
        return this.getRopstenNetworkProvider();
      case Networks.RINKEBY.toString():
        return this.getRinkebyNetworkProvider();
      case Networks.GOERLI.toString():
        return this.getGoerliNetworkProvider();
      case Networks.KOVAN.toString():
        return this.getKovanNetworkProvider();
      case Networks.BSCMAIN.toString():
        return this.getBSCNetworkProvider();
      case Networks.BSCTEST.toString():
        return this.getBSCTestNetworkProvider();
      case Networks.POLYGON_MAINNET.toString():
        return this.getPolygonNetworkProvider();
      case Networks.POLYGON_MUMBAI.toString():
        return this.getMumbaiNetworkProvider();
      case Networks.AVALANCHE.toString():
        return this.getAvalancheNetworkProvider();
      case Networks.APEX.toString():
        return this.getApexNetworkProvider();
      case Networks.AVALANCHE_FUJI.toString():
        return this.getFujiNetworkProvider();
      case Networks.ARBITRUM.toString():
        return this.getArbitrumNetworkProvider();
      case Networks.ZKSYNC.toString():
        return this.getZksyncNetworkProvider();
      case Networks.ZKSYNCTEST.toString():
        return this.getZksyncTestNetworkProvider();
      case Networks.OPTIMISM.toString():
        return this.getOptimismNetworkProvider();
      case Networks.LINEA.toString():
        return this.getLineaNetworkProvider();
      case Networks.BASE.toString():
        return this.getBaseNetworkProvider();
      case Networks.DEFI.toString():
        return this.getDefichainNetworkProvider();
      case Networks.ROOTSTOCK.toString():
        return this.getRootstockNetworkProvider();
      default:
        throw new Error(`The given chainId ${chainId} is not supported`);
    }
  }

  public getEthereumNetworkProvider() {
    if (this.ethereumNetworkProvider) {
      return this.ethereumNetworkProvider;
    }
    return (this.ethereumNetworkProvider = new EthereumService(
      Networks.MAINNET,
      [environment.ETH_APIKEYS_MAINNET],
      [],
      this.logger
    ));
  }

  public getRopstenNetworkProvider() {
    if (this.ropstenNetworkProvider) {
      return this.ropstenNetworkProvider;
    }
    return (this.ropstenNetworkProvider = new EthereumService(
      Networks.ROPSTEN,
      [environment.ETH_APIKEYS_ROPSTEN],
      [],
      this.logger
    ));
  }

  public getRinkebyNetworkProvider() {
    if (this.rinkebyNetworkProvider) {
      return this.rinkebyNetworkProvider;
    }
    return (this.rinkebyNetworkProvider = new EthereumService(
      Networks.RINKEBY,
      [environment.ETH_APIKEYS_RINKEBY],
      [],
      this.logger
    ));
  }

  public getGoerliNetworkProvider() {
    if (this.goerliNetworkProvider) {
      return this.goerliNetworkProvider;
    }
    return (this.goerliNetworkProvider = new EthereumService(
      Networks.GOERLI,
      [environment.ETH_APIKEYS_GOERLI],
      [],
      this.logger
    ));
  }

  public getKovanNetworkProvider() {
    if (this.kovanNetworkProvider) {
      return this.kovanNetworkProvider;
    }
    return (this.kovanNetworkProvider = new EthereumService(
      Networks.KOVAN,
      [environment.ETH_APIKEYS_KOVAN],
      [],
      this.logger
    ));
  }

  public getBSCNetworkProvider() {
    if (this.BSCNetworkProvider) {
      return this.BSCNetworkProvider;
    }
    return (this.BSCNetworkProvider = new BscService(
      Networks.BSCMAIN,
      [environment.BSCMAIN_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getBSCTestNetworkProvider() {
    if (this.BSCTestNetworkProvider) {
      return this.BSCTestNetworkProvider;
    }
    return (this.BSCTestNetworkProvider = new BscService(
      Networks.BSCTEST,
      [environment.BSCTEST_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getPolygonNetworkProvider() {
    if (this.polygonNetworkProvider) {
      return this.polygonNetworkProvider;
    }
    return (this.polygonNetworkProvider = new PolygonService(
      Networks.POLYGON_MAINNET,
      [environment.POLYGON_MAINNET_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getMumbaiNetworkProvider() {
    if (this.mumbaiNetworkProvider) {
      return this.mumbaiNetworkProvider;
    }
    return (this.mumbaiNetworkProvider = new PolygonService(
      Networks.POLYGON_MUMBAI,
      [environment.POLYGON_MUMBAI_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getAvalancheNetworkProvider() {
    if (this.avalancheNetworkProvider) {
      return this.avalancheNetworkProvider;
    }
    return (this.avalancheNetworkProvider = new AvalancheService(
      Networks.AVALANCHE,
      [environment.AVALANCHE_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getFujiNetworkProvider() {
    if (this.fujiNetworkProvider) {
      return this.fujiNetworkProvider;
    }
    return (this.fujiNetworkProvider = new AvalancheService(
      Networks.AVALANCHE_FUJI,
      [environment.AVALANCHE_FUJI_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getApexNetworkProvider() {
    if (this.apexNetworkProvider) {
      return this.apexNetworkProvider;
    }
    return (this.apexNetworkProvider = new ApexService(
      Networks.APEX,
      [environment.APEX_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }

  public getArbitrumNetworkProvider() {
    if (this.arbitrumNetworkProvider) {
      return this.arbitrumNetworkProvider;
    }
    return (this.arbitrumNetworkProvider = new ArbitrumService(
      Networks.ARBITRUM,
      [environment.ARBITRUM_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }
  public getZksyncNetworkProvider() {
    if (this.zksyncNetworkProvider) {
      return this.zksyncNetworkProvider;
    }
    return (this.zksyncNetworkProvider = new ZksyncService(
      Networks.ZKSYNC,
      [environment.ZKSYNC_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }
  public getZksyncTestNetworkProvider() {
    if (this.zksyncTestNetworkProvider) {
      return this.zksyncTestNetworkProvider;
    }
    return (this.zksyncTestNetworkProvider = new ZksyncService(
      Networks.ZKSYNCTEST,
      [environment.ZKSYNCTEST_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }
  public getOptimismNetworkProvider() {
    if (this.optimismNetworkProvider) {
      return this.optimismNetworkProvider;
    }
    return (this.optimismNetworkProvider = new OptimismService(
      Networks.OPTIMISM,
      [environment.OPTIMISM_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }
  public getLineaNetworkProvider() {
    if (this.lineaNetworkProvider) {
      return this.lineaNetworkProvider;
    }
    return (this.lineaNetworkProvider = new LineaService(
      Networks.LINEA,
      [environment.LINEA_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }
  public getBaseNetworkProvider() {
    if (this.baseNetworkProvider) {
      return this.baseNetworkProvider;
    }
    return (this.baseNetworkProvider = new BaseService(
      Networks.BASE,
      [environment.BASE_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }
  public getDefichainNetworkProvider() {
    if (this.defichainNetworkProvider) {
      return this.defichainNetworkProvider;
    }
    return (this.defichainNetworkProvider = new DeFiService(
      Networks.DEFI,
      [environment.DEFI_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }
  public getRootstockNetworkProvider() {
    if (this.rootstockNetworkProvider) {
      return this.rootstockNetworkProvider;
    }
    return (this.rootstockNetworkProvider = new RootstockService(
      Networks.ROOTSTOCK,
      [environment.ROOTSTOCK_PROVIDER_ADDRESS],
      [],
      this.logger
    ));
  }
}
