import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = environment.ExchangeInfoUrl || '';

@Injectable()
export class ExchangeInfoService {
  constructor(private http: HttpClient) {}

  public async getSwapCount(): Promise<any> {
    try {
      const url = `${baseUrl}/swap-count`;
      const count = <any>await this.http.get(url).toPromise();
      return count | 0;
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
  }

  public async getCrossChainCount(): Promise<any> {
    try {
      const url = `${baseUrl}/cross-chain-count`;
      const count = <any>await this.http.get(url).toPromise();
      return count | 0;
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
  }

  public async getOpportunitiesCount(): Promise<any> {
    try {
      const url = `${baseUrl}/opportunity-staking-count`;
      const count = <any>await this.http.get(url).toPromise();
      return count | 0;
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
  }
}
