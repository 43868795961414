import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import {
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../../../services';

@Component({
  selector: 'app-circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
  styleUrls: ['./circular-progress-bar.component.scss']
})
export class CircularProgressBarComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  //----- inputs
  @Input()
  title!: string;
  @Input()
  percentage!: number;
  @Input()
  radius!: number;
  @Input()
  thick!: number;
  @Input()
  backgroundColor!: string;
  @Input()
  foregroundColor!: string;
  @Input()
  isLoading!: boolean;
  cx!: number;

  cy!: number;
  perimeter!: number;
  offset!: number;
  //------
  constructor(
    protected themeService: ThemeService,
    protected web3Service: Web3Service,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges() {
    this.cx = this.cy = this.radius + this.thick / 2;
    this.perimeter = 2 * this.radius * Math.PI;
    this.percentage = this.percentage < 0 ? 0 : this.percentage;
    this.percentage = this.percentage > 100 ? 100 : this.percentage;
    this.offset = ((this.perimeter / 2) * (100 - this.percentage)) / 100;
  }
}
