<div
  class="transfer-component container bg-fff dark:bg-121212 dark:text-textWhite rounded-md py-3 md:p-3 position-relative z-0"
>
  <div class="right-align pe-md-3 inline-flex justify-end col-12">
    <div class="cursor-pointer mr-3 md:mr-1" (click)="close()">
      <svg
        *ngIf="!isDarkMode"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2689_123992)">
          <rect
            width="32"
            height="32"
            rx="4"
            fill="black"
            fill-opacity="0.05"
          />
          <path
            d="M25.3327 8.54602L23.4527 6.66602L15.9993 14.1193L8.54602 6.66602L6.66602 8.54602L14.1193 15.9993L6.66602 23.4527L8.54602 25.3327L15.9993 17.8793L23.4527 25.3327L25.3327 23.4527L17.8793 15.9993L25.3327 8.54602Z"
            fill="#323232"
          />
        </g>
        <defs>
          <clipPath id="clip0_2689_123992">
            <rect width="32" height="32" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <svg
        *ngIf="isDarkMode"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2689_123992)">
          <rect
            width="32"
            height="32"
            rx="4"
            fill="black"
            fill-opacity="0.05"
          />
          <path
            d="M25.3327 8.54602L23.4527 6.66602L15.9993 14.1193L8.54602 6.66602L6.66602 8.54602L14.1193 15.9993L6.66602 23.4527L8.54602 25.3327L15.9993 17.8793L23.4527 25.3327L25.3327 23.4527L17.8793 15.9993L25.3327 8.54602Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_2689_123992">
            <rect width="32" height="32" rx="4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>

  <div class="flex justify-center">
    <div
      class="swap-box border-radius-6px md:border-2 border-00000010 dark:border-ffffff10 w-full p-3 mb-20 z-0 bg-fff dark:bg-121212"
    >
      <label class="title dark:text-textWhite">Send Crypto</label>

      <div class="dark:divide-appLightBackground">
        <div>
          <div class="network-change-section flex flex-col mt-2">
            <div
              class="network-change-box w-full px-2 pb-2 pt-1 d-flex flex-column mb-0 border-1px"
              [ngClass]="{
                'border-1E1E1E border-radius-8px is-dark': isDarkMode,
                'border-0066FF dark:border-3385ff border-radius-6px': !isDarkMode
              }"
            >
              <div class="d-flex">
                <div
                  (click)="openSelectOptionDialog()"
                  class="p-md-3 p-2 border-2 border-9797971a border-radius-4px d-flex align-items-center cursor-pointer"
                >
                  <div
                    class="d-flex align-items-center justify-content-center ml-10px me-sm-3"
                  >
                    <div class="token-symbol mr-1">
                      <img
                        alt="fromToken"
                        class="rounded-full"
                        width="40"
                        [src]="
                          baseLogoIconsUrl +
                          token?.symbol?.toLowerCase() +
                          '.png'
                        "
                        (error)="updateUrl($event)"
                      />
                    </div>

                    <div class="d-flex flex-column">
                      <span
                        class="font-12 font-xs-16 font-openSans-bold text-000 dark:text-fff"
                      >
                        {{ token?.symbol }}
                      </span>

                      <div class="d-flex align-items-center">
                        <div
                          class="rounded-full flex-shrink-0 p-1px bg-fff overflow-hidden"
                        >
                          <img
                            alt="fromChain"
                            [src]="
                              web3Service.networkSpec[token?.chainId]?.coinIcon
                            "
                            (error)="updateUrl($event)"
                            width="14"
                          />
                        </div>

                        <span
                          class="font-10 font-sm-12 text-000 dark:text-fff ml-1 flex-shrink-0"
                        >
                          {{ web3Service.networkSpec[token?.chainId]?.title }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="arrow-down d-flex align-items-center justify-content-center ms-2 mr--4px"
                  >
                    <svg
                      class="text-323232 dark:text-DADADA"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>

                <label
                  class="p-md-3 p-2 border-2 border-9797971a border-radius-4px ml-6px"
                >
                  <div class="w-full d-flex flex-column flex-shrink-0">
                    <div
                      class="from-title-section w-full d-flex flex-shrink-0 align-items-center justify-content-end"
                    >
                      <div
                        class="d-flex flex-shrink-0 align-items-center w-md-auto w-full justify-content-between justify-content-end"
                        *ngIf="web3Service.isConnected()"
                      >
                        <span
                          class="font-10 font-sm-12 text-00000080 dark:text-FFFFFFB2 me-1"
                        >
                          Balance:
                        </span>

                        <nz-skeleton-element
                          class="balance-skeleton"
                          nzType="button"
                          [nzActive]="true"
                          nzSize="small"
                          *ngIf="!web3Service.isConnected() || !token?.balance"
                        ></nz-skeleton-element>

                        <div
                          class="d-flex flex-shrink-0 align-items-center"
                          *ngIf="web3Service.isConnected() && token?.balance"
                        >
                          <span
                            class="font-10 font-sm-12 text-00000080 dark:text-FFFFFFB2"
                          >
                            {{ token?.balanceToDisplay }}
                          </span>

                          <span
                            class="text-0066FF dark:text-3385FF ml-1 font-bold font-sm-14 cursor-pointer"
                            (click)="setMaxBalance(token)"
                          >
                            Max
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex items-center dark:text-fff">
                      <input
                        type="text"
                        inputmode="decimal"
                        (ngModelChange)="notifyAmount($event)"
                        [(ngModel)]="transferAmount"
                        mask="separator"
                        class="w-full font-18 font-xs-28 text-000 dark:text-fff p-1 border-0 shadow-none outline-none bg-transparent"
                      />
                    </div>
                    <span class="font-12 text-00000080 dark:text-FFFFFFB2">
                      <ng-container *ngIf="transferValueInUsd !== '0'">
                        {{
                          !transferValueInUsd.startsWith("N")
                            ? "~" + transferValueInUsd
                            : "Unknown"
                        }}
                      </ng-container>

                      <nz-skeleton-element
                        class="swap-search-value-skeleton"
                        nzType="button"
                        [nzActive]="true"
                        [nzSize]="'small'"
                        *ngIf="transferValueInUsd === '0'"
                      ></nz-skeleton-element>
                    </span>
                  </div>
                </label>
              </div>
            </div>

            <label
              class="flex mt-4 justify-start title dark:text-textWhite font-12 font-openSans-bold"
              [ngClass]="{
                'text-ef4444':
                  (receiverInfo.address !== '' &&
                    receiverInfo.address === web3Service.getWalletAddress()) ||
                  !status.isAddressValid
              }"
            >
              Wallet address
            </label>

            <div
              class="dark:bg-darkBlack rounded-md border-1px border-solid pt-1 px-2 pb-1 w-full mt-2px"
              [ngClass]="
                receiverInfo.address === web3Service.getWalletAddress() ||
                !status.isAddressValid
                  ? 'border-ef4444'
                  : 'border-0066FF dark:border-3385ff'
              "
            >
              <div class="px-1 flex align-items-center">
                <textarea
                  #walletInput
                  type="text"
                  (ngModelChange)="notifyAddress($event)"
                  [(ngModel)]="receiverInfo.address"
                  class="wallet-input w-full no-resize font-20 border-0 px-1 bg-transparent focus:border-none shadow-none focus:outline-none"
                  type="text"
                  [ngClass]="
                    (receiverInfo.address !== '' &&
                      receiverInfo.address ===
                        web3Service.getWalletAddress()) ||
                    !status.isAddressValid
                      ? 'text-ef4444'
                      : 'text-0066FF dark:text-3385FF'
                  "
                  (input)="auto_grow(walletInput)"
                ></textarea>
              </div>
            </div>

            <div
              *ngIf="!status.isAddressValid && receiverInfo.address"
              class="text-12 mt-2 text-ef4444"
            >
              The address is not valid!
            </div>

            <div
              *ngIf="receiverInfo.address === web3Service.getWalletAddress()"
              class="font-12 mt-2 text-ef4444"
            >
              Wallet address already connected to the app. Please enter a
              different address to send the token
            </div>
          </div>
        </div>

        <div
          *ngIf="
            receiverInfo.address &&
            status.isAddressValid &&
            receiverInfo.address !== web3Service.getWalletAddress()
          "
          class="mt-20px"
        >
          <label class="dark:text-fff font-12 font-openSans-bold"
            >Receiver address information:</label
          >

          <div
            class="flex flex-column bg-gray-100 border-1 dark:bg-dialogBoxBackground rounded px-2 w-full mt-2px"
          >
            <div class="d-flex justify-content-between w-full">
              <div
                *ngIf="token?.chainId !== 324"
                class="rounded-xl pt-1 px-1 md:px-4 pb-2 w-full font-bold text-gray-800 dark:text-textWhite"
              >
                <div class="text-gray-400 font-12">
                  First&nbsp;transaction date:
                </div>
                <div class="grid">
                  <div *ngIf="!loading" class="col-span-1 detail">
                    {{ receiverInfo.firstTransactionDate }}
                  </div>
                  <nz-skeleton-element
                    class="skeleton-h-22"
                    nzType="button"
                    [nzActive]="true"
                    nzSize="small"
                    *ngIf="loading"
                  ></nz-skeleton-element>
                </div>
              </div>

              <div
                class="rounded-xl pt-1 px-1 md:px-4 pb-2 w-full font-bold text-gray-800 dark:text-textWhite"
              >
                <div class="text-gray-400 font-12">Network:</div>
                <div class="grid">
                  <div *ngIf="!loading" class="col-span-1 detail">
                    {{ web3Service.networkSpec[token?.chainId].title }}
                  </div>
                  <nz-skeleton-element
                    class="skeleton-h-22"
                    nzType="button"
                    [nzActive]="true"
                    nzSize="small"
                    *ngIf="loading"
                  ></nz-skeleton-element>
                </div>
              </div>

              <div
                class="rounded-xl pt-1 px-1 md:px-4 pb-2 w-full font-bold text-gray-800 dark:text-textWhite"
              >
                <div class="text-gray-400 font-12">Balance:</div>
                <div class="grid">
                  <!--                  <div class="col-span-1 detail">-->
                  <!--                    {{ receiverInfo?.networkCoinBalance }}&nbsp;{{-->
                  <!--                      web3Service.networkSpec[token?.chainId].coin-->
                  <!--                    }}-->
                  <!--                  </div>-->
                  <div *ngIf="!loading" class="col-span-1 detail">
                    {{ receiverInfo?.tokenBalance }}&nbsp;{{ token.symbol }}
                  </div>
                  <nz-skeleton-element
                    class="skeleton-h-22"
                    nzType="button"
                    [nzActive]="true"
                    nzSize="small"
                    *ngIf="loading"
                  ></nz-skeleton-element>
                </div>
              </div>

              <ng-container *ngIf="!setting.isMobile">
                <ng-container
                  *ngTemplateOutlet="lastTransaction"
                ></ng-container>
              </ng-container>
            </div>

            <ng-container *ngIf="setting.isMobile">
              <ng-container *ngTemplateOutlet="lastTransaction"></ng-container>
            </ng-container>

            <ng-template #lastTransaction>
              <div
                *ngIf="token?.chainId !== 324"
                class="rounded-xl pt-1 px-1 md:px-4 pb-2 w-full font-bold text-gray-800 dark:text-textWhite"
              >
                <div class="text-gray-400 font-12">Last&nbsp;transaction:</div>
                <div *ngIf="!loading" class="grid">
                  <div class="col-span-1">
                    {{ receiverInfo.lastTransactionDate }}
                  </div>
                  <div class="col-span-1 detail">
                    {{ receiverInfo.lastTransactionType }}
                  </div>
                  <div class="flex">
                    <!--                  <div class="col-span-1 detail">-->
                    <!--                    {{ receiverInfo.lastTransactionValue }}&nbsp;-->
                    <!--                  </div>-->
                    <!--                  <div class="col-span-1 detail">-->
                    <!--                    {{ receiverInfo.lastTransactionToken }}-->
                    <!--                  </div>-->
                  </div>
                </div>
                <nz-skeleton-element
                  class="skeleton-h-22"
                  nzType="button"
                  [nzActive]="true"
                  nzSize="small"
                  *ngIf="loading"
                ></nz-skeleton-element>
              </div>
            </ng-template>
          </div>
        </div>

        <!--        Security alerts-->
        <div
          *ngIf="
            receiverInfo.address &&
            status.isAddressValid &&
            receiverInfo.address !== web3Service.getWalletAddress() &&
            !loading
          "
          class="mt-20px"
        >
          <label class="dark:text-fff font-12 font-openSans-bold"
            >Security alerts</label
          >

          <div
            *ngFor="let item of securityAlerts"
            class="flex flex-column rounded w-full mt-2px"
          >
            <div
              class="flex items-center"
              ngClass="{{ item.condition ? 'security-green' : 'security-red' }}"
            >
              <img
                [hidden]="!item.condition"
                src="assets/media/icons/done.png"
              />
              <img
                [hidden]="item.condition"
                src="assets/media/icons/close.png"
              />
              <div class="pl-2">{{ item.title }}</div>
            </div>
          </div>
        </div>

        <!-- Confirm transfer message -->
        <div
          class="mt-2 rounded-md w-full bg-FBD127 p-2 d-flex align-items-start"
          *ngIf="
            isUserWalletConnected &&
            receiverInfo.address &&
            status.isAddressValid &&
            receiverInfo.address !== web3Service.getWalletAddress() &&
            !loading &&
            (!this.receiverInfo.hasActivityInLastThreeMonths ||
              !this.receiverInfo.hasTransactionInLastThreeMonths ||
              !this.receiverInfo.isWalletOld ||
              !receiverInfo.isWalletAddress)
          "
        >
          <label class="unusual-confirmed-switch-section w-full d-flex">
            <nz-switch
              id="UnusualConfirmed"
              nzSize="small"
              [(ngModel)]="receiverInfo.confirmed"
              class="mt-1 mr-2"
            ></nz-switch>

            <div class="w-full d-flex flex-column text-000">
              <p class="text-14 font-openSans-regular mt-1">
                Despite the warning alerts, I want to sent entered amount to
                this address.
              </p>
            </div>
          </label>
        </div>

        <div class="my-2">
          <div *ngIf="web3Service.isConnected()" class="w-full">
            <button
              id="btnConfirmSwapDialog"
              (click)="confirmTransferAsset(token)"
              class="blue-button w-full py-2 px-4 rounded h-44 flex justify-center text-white text-sm items-center text-center hover:bg-blue-700"
              ngClass="{{
                token?.balance < transferAmount ||
                !receiverInfo.address ||
                !transferAmount ||
                !receiverInfo.confirmed ||
                receiverInfo.address === web3Service.getWalletAddress() ||
                !status.isAddressValid
                  ? 'insufficient-button'
                  : 'blue-button'
              }}"
            >
              <span>
                {{
                  token?.balance < transferAmount
                    ? "Insufficient balance"
                    : "Send"
                }}
              </span>
            </button>
          </div>

          <div *ngIf="!web3Service.isConnected()" class="w-full">
            <button
              id="btnConnectWalletDialog"
              (click)="connectWallet()"
              class="blue-button w-full py-2 px-4 rounded h-44 flex justify-center text-white text-sm items-center text-center hover:bg-blue-700"
            >
              <span>Connect wallet</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
