import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { ThemeService } from './services/theme.service';
import {
  ActiveMenu,
  DeviceType,
  NDDClientInfoServiceImpl,
  NavigatorService,
  OsType,
  StorageService
} from './services';
import { DialogDataModel } from './model';
import { ModalService } from './views/modal/modal.service';
import { InternalWalletBrowserDialogComponent } from './views/modal/dialogs/internal-wallet-browser/internal-wallet-browser.component';
import { Subscription } from 'rxjs';
import { createMessenger, v1 } from '@userlike/messenger';
import { environment } from '../environments/environment';
import { PushNotificationComponent } from './views/modal/dialogs/push-notification/push-notification.component';
import { InitializeService } from './services/initialize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isDarkMode;
  ActiveMenu = ActiveMenu;
  public internalWalletBrowserDialogOpened = false;
  appEl = document.getElementById('app');
  public enableHelpCircle: boolean = environment.enableHelpCircle;
  public enablePushNotification: boolean = environment.enablePushNotification;
  public showZeroFeeContent = false;

  windowWidth = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  constructor(
    private themeService: ThemeService,
    public navigatorService: NavigatorService,
    public storageService: StorageService,
    private clientInfoServiceImpl: NDDClientInfoServiceImpl,
    private internalWalletBrowserService: ModalService<InternalWalletBrowserDialogComponent>,
    private pushNotificationService: ModalService<PushNotificationComponent>,
    private initializeService:InitializeService
  ) {}

  ngOnInit(): void {
    this.themeService.darkModeChangeSubject.subscribe((isDark) => {
      // Prevent ExpressionChangedAfterItHasBeenCheckedError => Child component emits an event that updates parent component
      setTimeout(() => {
        this.isDarkMode = isDark;
      }, 0);
    });

    this.checkBrowser();

    this.notifyMe();
  }

  private async notifyMe() {
    if (!('Notification' in window)) {
      // Check if the browser supports notifications
      console.log('This browser does not support desktop notification');
    } else if (
      Notification.permission === 'default' &&
      this.enablePushNotification
    ) {
      const data = undefined;
      const pushNotificationDialogRef = await this.pushNotificationService.open(
        PushNotificationComponent,
        data
      );
      const sub: Subscription = (<PushNotificationComponent>(
        (<any>pushNotificationDialogRef!.instance)
      )).confirmed.subscribe(async (event) => {
        await this.pushNotificationService.close();
        if (event) {
          Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === 'granted') {
              // …
            }
          });
        }
      });
    }
  }

  public async checkBrowser() {
    if (
      [OsType.ANDROID, OsType.IOS].indexOf(
        this.clientInfoServiceImpl.getOsType()
      ) > -1 &&
      [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
        this.clientInfoServiceImpl.getDeviceType()
      ) > -1 &&
      !window.navigator.userAgent.toLowerCase().match('webview')
    ) {
      this.internalWalletBrowserDialogOpened =
        sessionStorage.getItem('internalWalletBrowserDialogOpened') === 'true';

      if (this.internalWalletBrowserDialogOpened) {
        return;
      }

      this.internalWalletBrowserDialogOpened = true;

      const data: DialogDataModel = {
        className: 'internal-wallet-browser-dialog',
        isDarkMode: this.isDarkMode
      };

      let internalWalletBrowserDialogRef =
        await this.internalWalletBrowserService.open(
          InternalWalletBrowserDialogComponent,
          data
        );

      const sub: Subscription = (<InternalWalletBrowserDialogComponent>(
        (<any>internalWalletBrowserDialogRef!.instance)
      )).confirmed.subscribe(async (data) => {
        await this.internalWalletBrowserService.close();
        this.internalWalletBrowserDialogOpened = false;

        sessionStorage.setItem('internalWalletBrowserDialogOpened', 'true');
      });

      internalWalletBrowserDialogRef!.onDestroy(() => {
        this.internalWalletBrowserDialogOpened = false;
        sub.unsubscribe();
      });
    }
  }

  changePage() {
    this.navigatorService.changePage('opportunity/BSC_ETF');
    this.showZeroFeeContent = !this.showZeroFeeContent;
  }
}
