import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { CaptureConsole, HttpClient } from "@sentry/integrations";

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.SENTRY_DSN,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    // new Sentry.BrowserTracing({
    //   routingInstrumentation: Sentry.routingInstrumentation,
    // }),
    // new CaptureConsole({
    //   levels: ['error']
    // }),
    // new HttpClient(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Sentry.Replay(),
  ],
  ignoreErrors: ['Network request failed', 'Failed to fetch', 'NetworkError', 'WebSocket connection failed', 'WebSocket connection closed', 'Socket stalled'],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  enableTracing: false,
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  //tracePropagationTargets: [ /^https:\/\/*.crowdswap\.org\/api/],

  // Capture Replay for 0% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  allowUrls: ["app.crowdswap.org"]
});


platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
