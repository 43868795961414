import { CrosschainFeeType, CrowdToken } from '@crowdswap/constant';
import { ExchangeType } from '../views/pages/cross-and-same-chain-swap/model/cross-chain-state.enum';
import { EstimateTrade } from './estimate-trade.model';

export enum CrossChainSwapState {
  Init = 0,
  NotFound = 1,
  Expired = 2,
  WalletNotConnected = 3,
  InsufficientSourceBalance = 4,
  InsufficientCoinBalance = 5,
  AmountInIsTooLow = 6,
  InsufficientTakerBalance = 7,
  ApprovalNeeded = 8,
  ApprovalConfirmed = 9,
  ApprovalRejected = 10,
  StartPreCCStep = 11,
  FailedPreCCStep = 12,
  PassedPreCCStep = 13,
  StartCCStep = 14,
  FailedCCStep = 15,
  PassedCCStep = 16,
  StartPostCCStep = 17,
  PassedPostCCStep = 18,
  FailedPostCCStep = 19,
  WarningPostCCStep = 20,
  ClaimedCancelPostCCStep = 21,
  END = 22 //Caution: do not put the state after this
}

export interface CrossAndSameChainEstimateTrade extends EstimateTrade {
  minimize: boolean;
  amountInDisplay?: string | undefined;
  amountOutDisplay?: string | undefined;
  scanTransactionUrl?: string | undefined;
  crossChainSwapState: CrossChainSwapState;
  routeList?: any[];
  costAnalyze?: ICostAnalyze;
  minAmountOutToDisplay?: string;
  minAmountOutInUSDTToDisplay?: string;
  confirmationInterval?: ReturnType<typeof setInterval> | undefined;

  displayFees?: IFee[];

  protocolFee?: IFee;
  totalProtocolFee?: string;
  operatingExpensesFee?: IFee;
  networkCost?: IFee;
  crowdswapFeeToDisplay?: IFee;
  totalPaid?: string;
  totalPaidWithFee?: string;
  earn?: IFee;
  includedInOutputFees?: IFee[];
  limitRengeInvalid?: boolean;
  AmountOutEstimationUnknown?: boolean;
  exchangeType?: ExchangeType;
  marketAmountOut?: string;
  marketAmountOutToDisplay?: string;
  patchState?: IPatchState;
}

export interface IFee {
  token: CrowdToken;
  amount: string;
  amountInUSDT?: number;
  amountInUSDTToDisplay?: string;
  type?: CrosschainFeeType;
}

export interface ICostAnalyze {
  confirmed?: boolean;
  amount?: string;
  percent?: number;
  isUnusualAmountOut?: boolean;
  isUnusualFee?: boolean;
}

export interface IEstimateAllParam {
  userAddress: string | undefined;
  amountIn: string;
  fromToken: CrowdToken;
  toToken: CrowdToken;
  slippage: string;

  amountOut?: string;
}

export interface IPatchState {
  newAmountOut: number;
  newAmountOutUsdt: any;
  patchTx: any;
  lastPatchDate: number;
  approvedPatchTx: any;
}
