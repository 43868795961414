<div class="row justify-center m-1 md:m-2">
  <div
    class="swap-box z-0 rounded-md px-1 md:px-3"
    [ngClass]="investmentType === InvestmentType.ByCrossChain ? '' : 'py-xl-5'"
  >
    <div class="swap-header">
      <!--      <div>-->
      <!--        <span (click)="changeState()">-->
      <!--          test {{ crossChainState }} {{ opportunityState }}-->
      <!--        </span>-->
      <!--      </div>-->
      <div
        class="font-openSans-regular justify-center"
        [ngClass]="
          investmentType === InvestmentType.ByCrossChain ? 'py-1' : 'py-10'
        "
        *ngIf="
          opportunityState !== OpportunityState.Successful &&
          opportunityState !== OpportunityState.Failed
        "
      >
        <div>
          <span class="text-xl">
            Please wait till your investment be done. it might take a few
            minutes.
          </span>
        </div>
        <div class="warning-color mt-3">
          <img class="init w-5 h-5" src="assets/media/icons/attention.png" />
          <span>
            &nbsp;Stay on this page and don't close the browser until you see
            the final result.</span
          >
        </div>
      </div>
      <div *ngIf="investedTokens" class="dark:bg-darkBlack rounded w-full">
        <div
          class="w-full d-flex justify-content-between border-1px border-00000010 dark:border-00000012 border-radius-4px bg-F7F7F7 dark:bg-121212 text-000 dark:text-fff font-12 p-2"
        >
          <div class="d-flex align-items-center flex-wrap w-full">
            <div
              class="d-flex align-items-center px-2"
              *ngFor="let item of investedTokens"
            >
              <div class="d-flex align-items-center scale-60 mx--14">
                <img
                  class="z-1 rounded-full mr-2"
                  [src]="baseLogoIconsUrl + item.symbol.toLowerCase() + '.png'"
                  (error)="updateUrl($event)"
                />
              </div>

              <span class="font-12 text-000 dark:text-fff mx-1">
                {{ item.symbol }}
              </span>

              <span class="font-12 font-openSans-bold text-000 dark:text-fff">
                {{ item.amountOut }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="investmentType === InvestmentType.ByCrossChain" class="row">
        <div class="flex font-openSans-regular items-center pt-4">
          <span
            *ngIf="
              crossChainState === CrossChainState.SrcFailed ||
              crossChainState === CrossChainState.DstFailed ||
              crossChainState === CrossChainState.DstWarning ||
              crossChainState === CrossChainState.DstWarningCatch
            "
            class="default-width"
            ><img class="init w-12 h-8" src="assets/media/icons/error-logo.png"
          /></span>
          <span
            *ngIf="crossChainState >= CrossChainState.DstSuccessful"
            class="default-width"
            ><img class="init w-12 h-11" src="assets/media/icons/tick-icon.png"
          /></span>
          <span
            *ngIf="
              crossChainState < CrossChainState.DstWarning &&
              crossChainState !== CrossChainState.SrcFailed
            "
            class="default-width w-12 h-8 bg-0B9ED9 rounded-full"
            ><span
              class="flex loader ease-linear loading rounded-full loader-margin border-2 border-t-2 h-4 w-4 p-2"
            >
            </span>
          </span>
          <span class="text-xl"> Swap/CrossChain </span>
        </div>
        <div
          class="flex font-openSans-regular items-center pt-3"
          [ngClass]="
            crossChainState === CrossChainState.SrcInit
              ? 'text-blue-300'
              : crossChainState === CrossChainState.SrcFailed
              ? 'text-red-300'
              : crossChainState >= CrossChainState.SrcSuccessful
              ? 'text-green-300'
              : 'text-gray-500'
          "
        >
          <span class="default-width"></span>
          <span class="">
            Swap on
            {{ web3Service.networkSpec[web3Service.getCurrentChainId()].title }}
          </span>
          <span class="absolute right-5">
            <span
              *ngIf="crossChainState === CrossChainState.SrcInit"
              class="inline-flex"
            >
              <span>In progress</span
              ><span
                class="flex loader ease-linear loading rounded-full border-2 border-t-2 h-4 w-4 ml-3 p-2"
              >
              </span>
            </span>
            <span *ngIf="crossChainState === CrossChainState.SrcFailed">
              <span>Failed</span>
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/error-logo.png"
              />
            </span>
            <span *ngIf="crossChainState >= CrossChainState.SrcSuccessful">
              Done
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/tick.png"
              />
            </span>
          </span>
        </div>
        <div
          class="flex font-openSans-regular items-center pt-3"
          [ngClass]="
            crossChainState === CrossChainState.DstInit
              ? 'text-blue-300'
              : crossChainState === CrossChainState.DstFailed ||
                crossChainState === CrossChainState.DstWarning ||
                crossChainState === CrossChainState.DstWarningCatch
              ? 'text-red-300'
              : crossChainState >= CrossChainState.DstSuccessful
              ? 'text-green-300'
              : 'text-gray-500'
          "
        >
          <span class="default-width"></span>
          <span class="">
            CrossChain to
            {{
              web3Service.networkSpec[selectedOpportunity.chainId].title
            }}</span
          >
          <span class="absolute right-5">
            <span *ngIf="crossChainState < CrossChainState.DstInit">
              <span>Waiting</span>
              <img
                class="init ml-3 w-5 h-5 opacity-50"
                src="assets/media/icons/refresh-outline-black.svg"
              />
            </span>
            <span
              *ngIf="crossChainState === CrossChainState.DstInit"
              class="inline-flex"
            >
              <span>In progress</span
              ><span
                class="flex loader ease-linear loading rounded-full border-2 border-t-2 h-4 w-4 ml-3 p-2"
              >
              </span>
            </span>
            <span
              *ngIf="
                crossChainState === CrossChainState.DstFailed ||
                crossChainState === CrossChainState.DstWarning ||
                crossChainState === CrossChainState.DstWarningCatch
              "
            >
              <span>Failed</span>
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/error-logo.png"
              />
            </span>
            <span *ngIf="crossChainState === CrossChainState.DstSuccessful">
              Done
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/tick.png"
              />
            </span>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="flex font-openSans-regular items-center pt-4">
          <span
            *ngIf="opportunityState < OpportunityState.Confirmed"
            class="default-width"
            ><img
              class="init w-8 h-8 opacity-50"
              src="assets/media/icons/refresh-outline-black.svg"
          /></span>
          <span
            *ngIf="opportunityState >= OpportunityState.Successful"
            class="default-width"
            ><img class="init w-12 h-11" src="assets/media/icons/tick-icon.png"
          /></span>
          <span
            *ngIf="
              opportunityState === OpportunityState.Rejected ||
              opportunityState === OpportunityState.Failed
            "
            class="default-width"
            ><img class="init w-12 h-8" src="assets/media/icons/error-logo.png"
          /></span>
          <span
            *ngIf="
              opportunityState < OpportunityState.Failed &&
              opportunityState !== OpportunityState.Rejected &&
              opportunityState >= OpportunityState.Confirmed
            "
            class="default-width w-12 h-8 bg-0B9ED9 rounded-full"
            ><span
              class="flex loader ease-linear loading rounded-full loader-margin border-2 border-t-2 h-4 w-4 p-2"
            >
            </span>
          </span>
          <span class="text-xl"
            >{{ isWithdraw ? "Withdrawing" : "Investing" }}
          </span>
        </div>
        <div
          class="flex font-openSans-regular items-center pt-3"
          [ngClass]="
            opportunityState === OpportunityState.Confirmed
              ? 'text-blue-300'
              : opportunityState === OpportunityState.Failed
              ? 'text-red-300'
              : opportunityState >= OpportunityState.Successful
              ? 'text-green-300'
              : 'text-gray-500'
          "
        >
          <span class="default-width"></span>
          <span class="">
            {{
              investmentType === InvestmentType.ByTokenAAndTokenB
                ? "Using ($" +
                  investmentAmount +
                  ") " +
                  (investedTokens?.length > 0
                    ? token
                    : selectedOpportunity.tokenB.symbol +
                      " and " +
                      selectedOpportunity.tokenA.symbol)
                : "Converting $" +
                  investmentAmount +
                  " from " +
                  token +
                  " to " +
                  (investedTokens?.length > 0
                    ? "ETF tokens."
                    : selectedOpportunity.tokenB.symbol +
                      " and " +
                      selectedOpportunity.tokenA.symbol)
            }}
          </span>
          <span class="absolute right-5">
            <br class="d-md-none" />
            <br class="d-md-none" />
            <span *ngIf="opportunityState < OpportunityState.Confirmed">
              <span>Waiting</span>
              <img
                class="init ml-3 w-5 h-5 opacity-50"
                src="assets/media/icons/refresh-outline-black.svg"
              />
            </span>
            <span
              *ngIf="opportunityState === OpportunityState.Confirmed"
              class="inline-flex"
            >
              <span>In progress</span
              ><span
                class="flex loader ease-linear loading rounded-full border-2 border-t-2 h-4 w-4 ml-3 p-2"
              >
              </span>
            </span>
            <span
              *ngIf="
                opportunityState === OpportunityState.Rejected ||
                opportunityState === OpportunityState.Failed
              "
            >
              <span>Failed</span>
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/error-logo.png"
              />
            </span>
            <span *ngIf="opportunityState === OpportunityState.Successful">
              Done
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/tick.png"
              />
            </span>
          </span>
        </div>
        <div
          class="flex font-openSans-regular items-center pt-3"
          [ngClass]="
            opportunityState === OpportunityState.Confirmed
              ? 'text-blue-300'
              : opportunityState === OpportunityState.Failed
              ? 'text-red-300'
              : opportunityState >= OpportunityState.Successful
              ? 'text-green-300'
              : 'text-gray-500'
          "
        >
          <span class="default-width"></span>
          <span class="">
            Creating
            {{
              investedTokens?.length > 0
                ? "ETF "
                : selectedOpportunity.asset + "LP"
            }}
            tokens
          </span>
          <span class="absolute right-5">
            <br class="d-md-none" />
            <br class="d-md-none" />
            <span *ngIf="opportunityState < OpportunityState.Confirmed">
              <span>Waiting</span>
              <img
                class="init ml-3 w-5 h-5 opacity-50"
                src="assets/media/icons/refresh-outline-black.svg"
              />
            </span>
            <span
              *ngIf="opportunityState === OpportunityState.Confirmed"
              class="inline-flex"
            >
              <span>In progress</span
              ><span
                class="flex loader ease-linear loading rounded-full border-2 border-t-2 h-4 w-4 ml-3 p-2"
              >
              </span>
            </span>
            <span
              *ngIf="
                opportunityState === OpportunityState.Rejected ||
                opportunityState === OpportunityState.Failed
              "
            >
              <span>Failed</span>
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/error-logo.png"
              />
            </span>
            <span *ngIf="opportunityState === OpportunityState.Successful">
              Done
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/tick.png"
              />
            </span>
          </span>
        </div>
        <div
          class="flex font-openSans-regular items-center pt-3"
          [ngClass]="
            opportunityState === OpportunityState.Confirmed
              ? 'text-blue-300'
              : opportunityState === OpportunityState.Failed
              ? 'text-red-300'
              : opportunityState >= OpportunityState.Successful
              ? 'text-green-300'
              : 'text-gray-500'
          "
        >
          <span class="default-width"></span>
          <span *ngIf="!investedTokens" class="">
            Staking LP
            {{ +amountLP > 0 ? amountLP : "< 1e-6" }}&nbsp;</span
          >
          <span *ngIf="investedTokens?.length > 0" class="">
            Transferring NFT Token</span
          >
          <span class="absolute right-5">
            <br class="d-md-none" />
            <span *ngIf="opportunityState < OpportunityState.Confirmed">
              <span>Waiting</span>
              <img
                class="init ml-3 w-5 h-5 opacity-50"
                src="assets/media/icons/refresh-outline-black.svg"
              />
            </span>
            <span
              *ngIf="opportunityState === OpportunityState.Confirmed"
              class="inline-flex"
            >
              <span>In progress</span
              ><span
                class="flex loader ease-linear loading rounded-full border-2 border-t-2 h-4 w-4 ml-3 p-2"
              >
              </span>
            </span>
            <span
              *ngIf="
                opportunityState === OpportunityState.Rejected ||
                opportunityState === OpportunityState.Failed
              "
            >
              <span>Failed</span>
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/error-logo.png"
              />
            </span>
            <span *ngIf="opportunityState === OpportunityState.Successful">
              Done
              <img
                class="init ml-3 w-5 h-5"
                src="assets/media/icons/tick.png"
              />
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="opportunityState === OpportunityState.Successful"
    class="flex justify-center text-sm"
  >
    <button
      class="detail-btn blue-button hover:bg-blue-700 font-openSans-semiBold"
      (click)="openDetail()"
    >
      <span>Details</span>
    </button>
  </div>
</div>
