<app-dialog>
  <div class="flex justify-start items-center mb-3">
    <div
      *ngIf="!data?.stakeState"
      class="font-openSans-bold font-16 dark:text-textWhite"
    >
      {{ data?.isWithdrawExtend ? "Withdraw" : "Extend" }} Stake
    </div>
    <div class="absolute right-5 mr-1 dark:text-textWhite">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="close()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
  </div>
  <div
    class="d-flex align-items-center justify-content-between dark:text-fff flex-basis-100% w-full flex-grow-1"
  >
    <div *ngIf="!data?.stakeState" class="d-flex flex-column p-1">
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center scale-60 mx--14">
          <img
            class="z-1 rounded-full"
            [ngClass]="{ 'mr-2': !data?.activeOpportunity?.tokenB }"
            [src]="
              baseLogoIconsUrl +
              data?.activeOpportunity?.tokenA.symbol?.toLowerCase() +
              '.png'
            "
            (error)="updateUrl($event)"
          />
          <img
            class="rounded-full ml--10"
            *ngIf="data?.activeOpportunity?.tokenB"
            [src]="
              baseLogoIconsUrl +
              data?.activeOpportunity?.tokenB.symbol?.toLowerCase() +
              '.png'
            "
            (error)="updateUrl($event)"
          />
        </div>

        <div class="d-flex flex-column ml-8px">
          <div class="d-flex align-items-center">
            <div class="font-16 font-w-800">
              {{ data?.activeOpportunity?.displayName }}
            </div>

            <div
              *ngIf="data?.activeOpportunity?.type"
              class="rounded-full font-openSans-semiBold font-w-500 px-2 py-1 font-11 ml-1"
              [ngClass]="
                data?.activeOpportunity?.type === 'NEW'
                  ? 'text-000 bg-DEF7EC'
                  : 'text-fff bg-005374'
              "
            >
              {{ data?.activeOpportunity?.type }}
            </div>
          </div>

          <div class="d-flex align-items-center py-1">
            <img
              width="16"
              class="rounded-full"
              [src]="
                web3Service.networkSpec[data?.activeOpportunity?.chainId]
                  ?.coinIcon
              "
            />

            <span class="font-thin ml-1">
              {{
                web3Service.networkSpec[data?.activeOpportunity?.chainId]?.title
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div>
      <div
        *ngIf="!data?.stakeState"
        class="flex justify-center items-center flex-col mt-1"
      >
        <div *ngIf="!data?.isWithdrawExtend" class="w-full mb-2">
          <div
            class="w-full align-items-center justify-content-between border-1px border-00000010 dark:border-00000012 border-radius-4px bg-F7F7F7 dark:bg-121212 text-000 dark:text-fff d-flex flex-sm-row flex-column"
          >
            <div
              class="d-flex align-items-center justify-content-between flex-basis-100% w-full"
            >
              <div class="d-flex flex-column p-1">
                <div class="text-6b7280 font-12">TVL</div>

                <nz-skeleton-element
                  nzType="button"
                  [nzActive]="true"
                  [nzSize]="'small'"
                  *ngIf="!data?.displayTvl"
                ></nz-skeleton-element>

                <div
                  class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                  *ngIf="data?.displayTvl"
                >
                  {{ data?.displayTvl }}
                </div>
              </div>

              <div
                class="d-flex flex-column p-1 justify-content-end text-right"
              >
                <div class="text-6b7280 font-12">APY</div>

                <nz-skeleton-element
                  nzType="button"
                  [nzActive]="true"
                  [nzSize]="'small'"
                  *ngIf="!data?.displayApy"
                ></nz-skeleton-element>

                <div
                  class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                  *ngIf="data?.displayApy"
                >
                  {{ data?.displayApy }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!data?.isWithdrawExtend" class="w-full mb-2">
          <div
            class="w-full align-items-center justify-content-between border-1px border-00000010 dark:border-00000012 border-radius-4px bg-F7F7F7 dark:bg-121212 text-000 dark:text-fff d-flex flex-sm-row flex-column"
          >
            <div
              class="d-flex align-items-center justify-content-between flex-basis-100% w-full"
            >
              <div class="d-flex flex-column p-1">
                <div class="text-6b7280 font-12">
                  <nz-skeleton-element
                    nzType="button"
                    [nzActive]="true"
                    [nzSize]="'small'"
                    *ngIf="!data?.activeOpportunity?.asset"
                  ></nz-skeleton-element>

                  <span *ngIf="data?.activeOpportunity?.asset">
                    {{ data?.activeOpportunity?.asset }}
                  </span>
                </div>

                <nz-skeleton-element
                  nzType="button"
                  [nzActive]="true"
                  [nzSize]="'small'"
                  *ngIf="!data?.amountIn"
                ></nz-skeleton-element>

                <div
                  class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                  *ngIf="data?.amountIn"
                >
                  {{ data?.amountIn }}
                </div>
              </div>

              <div
                class="d-flex flex-column p-1 justify-content-center text-center"
              >
                <div class="text-6b7280 font-12">Duration</div>

                <nz-skeleton-element
                  nzType="button"
                  [nzActive]="true"
                  [nzSize]="'small'"
                  *ngIf="!data?.duration"
                ></nz-skeleton-element>

                <div
                  class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                  *ngIf="data?.duration"
                >
                  {{ data?.duration }} Days
                </div>
              </div>

              <div
                class="d-flex flex-column p-1 justify-content-end text-right"
              >
                <div class="text-6b7280 font-12">End Date</div>

                <nz-skeleton-element
                  nzType="button"
                  [nzActive]="true"
                  [nzSize]="'small'"
                  *ngIf="!data?.endDate"
                ></nz-skeleton-element>

                <div
                  class="font-bold font-14 font-w-500 text-0066FF dark:text-1BD37F"
                  *ngIf="data?.endDate"
                >
                  {{ data?.endDate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="rounded-md px-2 justify-center items-center text-000 w-full py-1 bg-BBF2D9"
        >
          <div class="flex justify-center font-openSans-regular">Rewards</div>
          <div
            *ngIf="data?.nextReward"
            class="flex justify-center font-openSans-semiBold"
          >
            {{ data?.nextReward }} {{ data?.tokenSymbol }}
          </div>
        </div>

        <div
          *ngIf="data?.isWithdrawExtend"
          class="rounded-md px-2 justify-center items-center w-full mt-2 py-1 text-000 border border-grayText text-center bg-gray-300 dark:border dark:border-darkGrayText"
        >
          <div class="flex justify-center font-openSans-regular">Total Amount</div>
          <div
            *ngIf="data?.releasableCrowdBalance"
            class="flex justify-center font-openSans-semiBold"
          >
            {{ data?.releasableCrowdBalance }} {{ data?.tokenSymbol }}
          </div>
        </div>
      </div>

      <div *ngIf="data?.stakeState" class="flex flex-col my-2">
        <span class="dark:text-white font-16 font-openSans-regular">
          Your are going to lock
          <span class="font-16 font-openSans-bold"
            >{{ data?.amountIn }} {{ data?.activeOpportunity?.asset }}</span
          >
          for
          <span class="font-16 font-openSans-bold">{{ data?.duration }}</span>
          days and you won't be able to withdraw before
          <span class="font-16 font-openSans-bold">{{ data?.endDate }}.</span>
        </span>
      </div>

      <div class="flex flex-col mt-4 mb-2">
        <button
          (click)="withdraw()"
          class="py-2 px-4 rounded-md h-44 flex justify-center blue-button font-openSans-semiBold text-sm items-center border border-grayText text-center dark:border dark:border-darkGrayText hover:bg-blue-700"
        >
          <span class="dark:text-white">
            {{ data?.isWithdrawExtend ? "Withdraw" : "Confirm" }}
          </span>
        </button>
      </div>

      <div
        *ngIf="!data?.isWithdrawExtend && !data?.stakeState"
        class="flex flex-col my-2"
      >
        <span class="dark:text-white font-openSans-bold">
          Your fund will be locked for {{ data?.duration }} days and you won't
          be able to withdraw before that time.
        </span>
      </div>

      <div *ngIf="data?.isWithdrawExtend" class="flex flex-col my-2">
        <button
          (click)="close()"
          class="py-2 px-4 rounded-md h-44 flex justify-center text-sm font-openSans-semiBold border border-grayText items-center text-center dark:text-white dark:border dark:border-darkGrayText hover:bg-gray-200 dark:hover:bg-unStakeBackground"
        >
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
