import {
  Component,
  EventEmitter,
  NgModule,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../../share/shared.module';

@Component({
  selector: 'internal-wallet-browser-dialog',
  templateUrl: './internal-wallet-browser.component.html',
  styleUrls: ['./internal-wallet-browser.component.scss']
})
export class InternalWalletBrowserDialogComponent
  extends DialogsComponent
  implements OnInit
{
  @Output()
  public confirmed = new EventEmitter<object>();

  constructor() {
    super();
  }

  public toggleDropdown(isHelp?: boolean) {
    this.confirmed.emit(undefined);

    if (isHelp) {
      window.open(
        'https://crowdswap.org/blog/what-is-a-dapp-browser',
        '_blank'
      );
    }
  }

  public async ngOnInit() {}
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule, SharedModule.forRoot()],
  declarations: [InternalWalletBrowserDialogComponent],
  exports: [InternalWalletBrowserDialogComponent]
})
export class SelectOptionDialogComponentModule {}
