import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OpportunityComponent } from '../opportunity/opportunity.component';
import { TagManagerService, Web3Service } from '../../../services';
import { SelectedOpportunityTab } from '../opportunity/model/opportunity-state.enum';

@Component({
  selector: 'app-user-portfolio',
  templateUrl: './user-portfolio.component.html',
  styleUrls: ['./user-portfolio.component.scss']
})
export class UserPortfolioComponent implements OnInit {
  public address: string | undefined = '';
  public isDarkMode;
  public isWrongNetwork: boolean = false;

  constructor(
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    protected tagManagerService: TagManagerService
  ) {}

  async ngOnInit(): Promise<any> {
    await this.tagManagerService.sendStartViewTag('user_portfolio');

    this.web3Service.accountChangeSubject.subscribe(() => {
      this.address = this.web3Service.getWalletAddress();
      this.ref.detectChanges();
    });
  }
}
