import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { EventType, NavigationEnd, Router } from '@angular/router';
import {
  NetworksById,
  OpportunitiesHolder,
  PriceService
} from '@crowdswap/constant';
import * as _ from 'lodash';
import { NgxMaskService } from 'ngx-mask';
import { ToastrService } from 'ngx-toastr';
import { filter, throttleTime } from 'rxjs/operators';
import { TimeService } from 'src/app/services/time.service';
import SwiperCore, { Keyboard, Navigation, Pagination, Virtual } from 'swiper';
import { CurrentNetwork } from '../../../model/current-network';
import {
  DeviceType,
  NavigatorService,
  NDDClientInfoServiceImpl,
  OpportunityService,
  OpportunityState,
  PrivateSaleService,
  StakeService,
  TagManagerService,
  ThemeService,
  UtilsService,
  Web3Service
} from '../../../services';
import { OpportunitiesService } from '../../../services/opportunities.service';
import { BaseComponent } from '../base.component';
import {
  OpportunityType,
  SelectedOpportunityTab
} from './model/opportunity-state.enum';
import { asyncScheduler } from 'rxjs';
import { Location } from '@angular/common';
import { EtfOpportunitiesService } from '../../../services/etf-opportunities.service';
import { IETFOpportunityItem } from '../etf-opportunity/model/etf.model';

SwiperCore.use([Keyboard, Pagination, Navigation, Virtual]);

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
  providers: [NgxMaskService]
})
export class OpportunityComponent extends BaseComponent implements OnInit {
  private activeOpportunities: any[] = [];
  private activeInvestments: any[] = [];

  public isWalletNotConnected: boolean = true;
  public isUserEligible: boolean = true;
  public showInvestment: boolean = false;
  public searchOpp: string = '';
  public opportunities;
  public etfOpportunities: IETFOpportunityItem[] = [];
  public etfOpportunitiesInvested: IETFOpportunityItem[] = [];

  public activeOppTempList: any[] = [];
  public selectedOpportunity: any;
  private firstTime: boolean = true;

  public indexOfOpp = -1;
  public loading: boolean = false;
  public refreshing: boolean = false;
  public tooltip: boolean = false;
  public address: string | undefined = '';
  @ViewChild('container', { read: ViewContainerRef })
  container: ViewContainerRef | undefined;
  public currentTime;
  public startTime = 1669248000; //start 24th Nov at 00:00
  public finishTime = 1669680000; //finish 28th Nov at 00:00
  selectFlag: boolean = false;
  SortList = ['Network', 'TVL', 'APY', 'Daily Interest'];
  sort: string = this.SortList[0];
  selectedTab = SelectedOpportunityTab.AllOpportunities;
  selectedOpportunityTab = SelectedOpportunityTab;
  public allOpportunityCount: number = 0;
  public opportunityCount: number = 0;
  public investOpportunityCount = 0;
  public static defaultTab = SelectedOpportunityTab.AllOpportunities;
  @Input()
  public countLimit: number = 0;

  @Input()
  public isViewMore: boolean = false;
  @Input()
  public isPortfolio: boolean = false;
  public activePresales: any[] = [];
  result: any;
  public OpportunityType = OpportunityType;

  constructor(
    public stakeService: StakeService,
    public web3Service: Web3Service,
    private maskService: NgxMaskService,
    public ref: ChangeDetectorRef,
    protected themeService: ThemeService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private toastr: ToastrService,
    private router: Router,
    private utilsService: UtilsService,
    private priceService: PriceService,
    public navigatorService: NavigatorService,
    private opportunityService: OpportunityService,
    private opportunitiesService: OpportunitiesService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    private privatesaleService: PrivateSaleService,
    protected tagManagerService: TagManagerService,
    protected timeService: TimeService,
    private location: Location,
    private etfOpportunitiesService: EtfOpportunitiesService
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);

    this.subscriptionList.push(
      this.router.events.subscribe((event) => {
        if (
          ((event.type === EventType.RoutesRecognized &&
            window.location.pathname.includes('opportunity')) ||
            event.type === EventType.NavigationSkipped) &&
          event.url.includes('ETF') &&
          !this.showInvestment
        ) {
          const urlParam = event.url.split('/');
          const opportunityName = urlParam[urlParam.length - 1];

          this.checkOpenInvestComponent(
            'opportunity',
            opportunityName.toLowerCase()
          );
        }
      })
    );
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    this.showInvestment = false;
    await this.opportunitiesService.ensureOpportunitiesLoaded();
    this.opportunities = OpportunitiesHolder.Opportunities;

    this.etfOpportunities = this.opportunities?.etf?.data ?? [];
    this.etfOpportunitiesInvested =
      (await this.etfOpportunitiesService.getInvestments()) ?? [];

    this.tagManagerService.sendStartViewTag('opportunity');
    if (!this.currentTime) {
      this.currentTime = this.timeService.getCurrentTime();
    }
    this.isWalletNotConnected = !this.web3Service.isConnected();
    this.address = this.web3Service.getWalletAddress();

    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe((connection) => {
        this.toggleTabs(
          this.isPortfolio
            ? SelectedOpportunityTab.InvestedOpportunities
            : OpportunityComponent.defaultTab
        );
        this.isUserEligible = true;
        this.isWalletNotConnected = !connection;
        if (!connection) {
          this.close();
        }
        this.ref.detectChanges();
      })
    );
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(this.firstTime ? 1000 : 100, asyncScheduler, {
            leading: false,
            trailing: true
          })
        )
        .subscribe((currentNetwork: CurrentNetwork) => {
          this.isUserEligible = true;
          if (currentNetwork.chainId > 0) {
            this.checkOpenInvestComponent();
            if (this.firstTime && this.web3Service.isConnected()) {
              if (!this.loading || this.investOpportunityCount === 0) {
                this.loading = true;
                this.refreshInvestments();
              }
              this.firstTime = false;
            }
            this.toggleTabs(
              this.isPortfolio
                ? SelectedOpportunityTab.InvestedOpportunities
                : this.selectedTab
            );
            this.ref.detectChanges();
          }
        })
    );
    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        (isWrongNetwork: boolean) => {
          this.isUserEligible = true;
          this.isWrongNetwork = isWrongNetwork;
          this.ref.detectChanges();
        }
      )
    );
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(() => {
        this.isUserEligible = true;
        if (!this.loading || this.investOpportunityCount === 0) {
          this.refreshInvestments(true);
        }
      })
    );
    this.opportunityCount = 0;
    for (const [key] of Object.entries(this.opportunities)) {
      if (this.opportunities[key].observable) {
        this.opportunityCount++;
        this.activeOpportunities.push(this.opportunities[key]);
      }
    }

    this.setting.isMobile =
      [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
        this.clientInfoServiceImpl.getDeviceType()
      ) > -1;

    if (
      (this.isViewMore ||
        OpportunityComponent.defaultTab ===
          SelectedOpportunityTab.InvestedOpportunities) &&
      this.isUserWalletConnected &&
      this.investOpportunityCount > 0
    ) {
      OpportunityComponent.defaultTab =
        SelectedOpportunityTab.InvestedOpportunities;
    } else {
      OpportunityComponent.defaultTab = SelectedOpportunityTab.AllOpportunities;
    }
    this.allOpportunityCount = this.opportunityCount;
    this.activePresales = await this.getPresaleList();
  }

  checkOpenInvestComponent(category?: string, opportunityName?: string) {
    const categoryOfURL = UtilsService.getURLCategory() ?? category;
    if (categoryOfURL) {
      if (categoryOfURL === 'opportunity') {
        const opportunityUrl =
          UtilsService.getOpportunityURL() ?? opportunityName;

        for (const [key] of Object.entries(this.opportunities)) {
          if (this.opportunities[key].name.toLowerCase() === opportunityUrl) {
            this.invest(this.opportunities[key]);
          }
        }

        if (this.etfOpportunities.length > 0) {
          this.etfOpportunities.forEach((etfOpportunity) => {
            if (etfOpportunity.etfName.toLowerCase() === opportunityUrl) {
              this.invest(etfOpportunity);
            }
          });
        }
      }

      this.location.replaceState('/opportunity');
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public async refreshInvestments(reload: boolean = false) {
    if (
      this.web3Service.isConnected() &&
      this.web3Service.getCurrentChainId() > 0 &&
      !this.refreshing
    ) {
      this.etfOpportunitiesInvested =
        (await this.etfOpportunitiesService.getInvestments()) ?? [];
      this.refreshing = true;
      this.activeInvestments = [];
      this.opportunitiesService
        .getInvestmentList(this.activeOpportunities, reload)
        .then((result) => {
          this.activeInvestments.push(...result);
          this.investOpportunityCount += this.activeInvestments.length;
          if (reload) {
            this.activeOpportunities.forEach((opportunity) => {
              const opp = this.activeInvestments.filter(
                (item) => item.name === opportunity.name
              );
              if (opp.length === 0) {
                opportunity.yourShare = undefined;
              }
            });
          }
          this.toggleTabs(this.selectedTab);
          this.refreshing = false;
          this.loading = false;
        });
    }
  }

  private async getPresaleList() {
    return this.privatesaleService.getPresaleList();
  }

  toggleTabs(tabName) {
    OpportunityComponent.defaultTab = tabName;
    this.selectedTab = tabName;
    this.activeOppTempList = [];
    if (this.selectedTab === this.selectedOpportunityTab.AllOpportunities) {
      this.etfOpportunities?.map((o) => {
        o.opportunityType = OpportunityType.ETF;
        o.Network = NetworksById[o.chainId];
        o.TVL = o.totalTVL;
        o.displayName = o.planName;
      });

      this.activeOppTempList = [
        ...this.etfOpportunities,
        ...this.activeOppTempList
      ];
    } else if (
      this.selectedTab === this.selectedOpportunityTab.InvestedOpportunities
    ) {
      if (!this.web3Service.getWalletAddress()) {
        return;
      }

      this.activeOppTempList = [
        ...(this.etfOpportunitiesInvested ?? []),
        ...this.activeOppTempList
      ];
    }

    this.searchOpp = '';
    this.sortList(this.sort);
    this.ref.detectChanges();

    this.allOpportunityCount = this.activeOppTempList.length;
    // this.activeOppTempList.push(this.etfOpportunities[0]);
    // console.table(this.activeOppTempList);
  }

  public async invest(opportunity) {
    this.showInvestment = true;
    this.selectedOpportunity = opportunity;
    this.scrollTopContentSection(this.countLimit !== 0);
    this.opportunityService.setConsoleLog(
      opportunity.name,
      OpportunityState.Init,
      'pushed invest button'
    );
  }

  /**
   * Filter data source value
   */
  public async filterDataSourceValue(event) {
    this.sort = this.SortList[0];
    event = event.toLowerCase();
    this.activeOppTempList = [];
    const allOpp = [...this.etfOpportunities, ...this.activeOpportunities];
    const allInvested = [
      ...this.etfOpportunitiesInvested,
      ...this.activeInvestments
    ];
    this.activeOppTempList =
      this.selectedTab === this.selectedOpportunityTab.AllOpportunities
        ? allOpp.filter((item: any) => {
            return (
              item.displayName.toLowerCase().indexOf(event, 0) >= 0 &&
              item.opportunityType !== OpportunityType.TypePancake &&
              item.opportunityType !== OpportunityType.TypeBeefy &&
              (!item.apr || item.apr > 0)
            );
          })
        : allInvested.filter((item: any) => {
            return item.displayName.toLowerCase().indexOf(event, 0) >= 0;
          });
  }

  public async goToOpportunities(selectedTab) {
    OpportunityComponent.defaultTab = selectedTab;

    await this.navigatorService.changePage('opportunity');
    await this.router.navigate(['opportunity']);
  }

  public sortList(value: string, isEtfNeededSort: boolean = true) {
    this.sort = value;
    if (this.selectedTab === this.selectedOpportunityTab.AllOpportunities) {
      const allOpp = [...this.etfOpportunities, ...this.activeOpportunities];
      const filteredOpportunities = allOpp.filter((item: any) => {
        return (
          (item.opportunityType !== OpportunityType.TypePancake &&
            item.opportunityType !== OpportunityType.TypeBeefy &&
            (!item.apr || item.apr > 0)) ||
          item.opportunityType === OpportunityType.ETF
        );
      });

      this.activeOppTempList = [...filteredOpportunities];

      this.allOpportunityCount = this.activeOppTempList.length;
    } else {
      this.activeOppTempList = [
        ...this.etfOpportunitiesInvested,
        ...this.activeInvestments
      ];
      this.allOpportunityCount = this.investOpportunityCount;
    }
    this.activeOppTempList = _.orderBy(
      this.activeOppTempList,
      [(opportunity) => opportunity[value] || 0],
      [value === this.SortList[0] ? 'asc' : 'desc']
    );

    if (isEtfNeededSort) {
      const customComparator = (a, b) => {
        if (
          a.opportunityType === OpportunityType.ETF &&
          b.opportunityType !== OpportunityType.ETF
        ) {
          return -1; // Move a to a lower index
        }
        if (
          a.opportunityType !== OpportunityType.ETF &&
          b.opportunityType === OpportunityType.ETF
        ) {
          return 1; // Move a to a higher index
        }
        return 0; // Leave the order unchanged
      };

      // Sorting the array using the custom comparator function
      this.activeOppTempList.sort(customComparator);
    }

    if (this.isViewMore) {
      this.activeOppTempList = this.activeOppTempList.slice(0, this.countLimit);
    }
    this.ref.detectChanges();
  }

  onClose() {
    this.selectFlag = false;
  }

  close() {
    this.showInvestment = false;
  }
}
