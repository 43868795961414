<div
  class="d-flex fiat-padding align-items-center justify-content-center container px-3 px-lt-24px"
>
  <div
    class="d-flex flex-column align-items-center justify-content-center m-auto fiat-max-width dark:text-fff"
  >
    <span class="font-16 font-md-20 font-openSans-semiBold">
      Buy/Sell token by bank transfer or card
    </span>
    <div class="text-center mt-3 font-12 font-md-14">
      Invest in Bitcoin, Ether and the most popular assets on multiple chains in
      a few clicks, at the best prices on the market and no KYC required.
    </div>
    <div class="flex justify-content-center mt-3 font-16">
      <span
        class="text-fff button-0066FF rounded-md border p-2 cursor-pointer"
        (click)="mtPelerin()"
      >
        Buy/Sell Crypto
      </span>
    </div>
  </div>
</div>
