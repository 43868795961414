import { Injectable } from '@angular/core';
import {
  Conversion,
  CrowdToken,
  MainNetworksById,
  NetworksByName,
  TokensHolder
} from '@crowdswap/constant';
import { Web3Service } from './web3.service';
import { Builder } from 'builder-pattern';
import { BaseComponent } from '../views/pages/base.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TOKENS_DATA } from '../backup-data';

@Injectable()
export class TokensService {
  private NETWORK_SERVICE_BASEURL = '';
  private LimitOrderTokens: { [key: string]: string[] } = {};
  constructor(public web3Service: Web3Service, public http: HttpClient) {
    this.NETWORK_SERVICE_BASEURL = environment.NETWORK_SERVICE_BASEURL;
  }

  getLocalStoredTokens(chainId): any {
    const tokenList: any[] = [];
    if (localStorage.getItem('tokens')) {
      const localTokens = JSON.parse(localStorage.getItem('tokens')!);
      if (localTokens[chainId]) {
        Object.keys(localTokens[chainId]).forEach((key) => {
          const token = Builder<CrowdToken>()
            .chainId(localTokens[chainId][key].chainId)
            .address(localTokens[chainId][key].address)
            .decimals(localTokens[chainId][key].decimals)
            .symbol(localTokens[chainId][key].symbol)
            .name(localTokens[chainId][key].name + ' • Added by user')
            .build();
          tokenList.push(token);
        });
      }
    }
    return tokenList;
  }

  //Get all chains listed tokens
  getAllTokens() {
    const observableTokens: any[] = [];
    let localStoredTokens: any[] = [];
    for (const chainId in TokensHolder.ObservableTokenListBySymbol) {
      if (
        !MainNetworksById[NetworksByName[chainId]] ||
        !environment.ACTIVE_NETWORK.includes(NetworksByName[chainId].toString())
      ) {
        continue;
      }
      localStoredTokens = localStoredTokens.concat(
        this.getLocalStoredTokens(NetworksByName[chainId])
      );
      for (const tokenSymbol in TokensHolder.ObservableTokenListBySymbol[
        chainId
      ]) {
        const token =
          TokensHolder.ObservableTokenListBySymbol[chainId][tokenSymbol];
        observableTokens.push(token);
      }
    }
    return BaseComponent.removeDuplicateTokens(
      observableTokens,
      localStoredTokens
    );
  }

  //Get all chains listed tokens for limit
  async getLimitOrderTokens() {
    let data: { [key: string]: string[] } | undefined;
    try {
      data = await this.http
        .get<{ [key: string]: string[] }>(
          `${environment.SWAP_BASEURL}/api/v1/crosschainswap/limitOrderTokens`,
          {
            headers: { 'x-api-key': environment.API_KEY }
          }
        )
        .toPromise();

      this.LimitOrderTokens = data ?? {};
    } catch {
      //the api had error so we stick with our current tokens
      return;
    }
  }

  get LimitOrderTokensList() {
    return this.LimitOrderTokens;
  }

  async GetTokensForANetwork(pchainId: string = '') {
    let data: TokensEntity[] | undefined;
    try {
      data = await this.http
        .get<TokensEntity[]>(
          `${this.NETWORK_SERVICE_BASEURL}/api/v1/token/all?chainId=${pchainId}`
        )
        .toPromise();
    } catch {
      //the api had error so we stick with our current tokens
      return;
    }

    this.FillInTokensHolder(data);
  }

  FillTokensWithStaticData() {
    let data: TokensEntity[] = TOKENS_DATA;
    this.FillInTokensHolder(data);
  }

  private FillInTokensHolder(data: TokensEntity[] | undefined) {
    const mappedTokens = data!.map(
      (t) =>
        new TokenViewModel(
          t.chainId,
          t.address,
          t.decimals,
          t.symbol,
          t.name,
          t.observable,
          t.hasCustomPrice,
          t.trending,
          t.timestamp
        )
    );
    TokensHolder.FillAllTokens(mappedTokens);
  }

  public async getTokenBalance(token: CrowdToken) {
    const balance = await this.web3Service.getBalance(
      token,
      this.web3Service.getNetworkProvider(token.chainId)
    );
    return balance
      ? Conversion.convertStringFromDecimal(balance.toString(), token.decimals)
      : '0';
  }
}

export class TokenViewModel extends CrowdToken {
  observable?: boolean;
  trending?: boolean;
  timestamp?: string;
  constructor(
    chainId: number,
    address: string,
    decimals: number,
    symbol?: string,
    name?: string,
    observable?: boolean,
    hasCustomPrice?: boolean,
    trending?: boolean,
    timestamp?: string
  ) {
    super(chainId, address, decimals, symbol, name);
    this.observable = observable;
    this.hasCustomPrice = hasCustomPrice;
    this.trending = trending;
    this.timestamp = timestamp;
  }
}

interface TokensEntity {
  id: string;
  address: string;
  chainId: number;
  name: string;
  symbol: string;
  decimals: number;
  observable: boolean;
  hasCustomPrice: boolean;
  trending: boolean;
  timestamp: string;
}
