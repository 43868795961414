import { Component, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from '../base.component';
import {
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  UtilsService,
  Web3Service
} from '../../../services';
import { ModalService } from '../../modal/modal.service';
import { MtPelerinDialogComponent } from './MtPelerin/mt-pelerin-dialog.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fiat-on-ramp',
  templateUrl: './fiat-on-ramp.component.html',
  styleUrls: ['./fiat-on-ramp.component.scss']
})
export class FiatOnRampComponent extends BaseComponent {
  dialogOpened: boolean = false;

  constructor(
    public web3Service: Web3Service,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    private MtPelerinDialogComponentModalService: ModalService<MtPelerinDialogComponent>,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit() {
    super.ngOnInit();

    await this.tagManagerService.sendStartViewTag('fiat');

    this.web3Service.accountChangeSubject.subscribe(async (address) => {
      //if(this.dialogOpened){
      //  await this.MtPelerinDialogComponentModalService.close();
      //}
    });
  }

  async mtPelerin() {
    if (this.dialogOpened) {
      return;
    }
    await this.tagManagerService.sendFiatOnRampTag('fiat_buy_sell');

    const mtPelerinOptions = {
      lang: 'en',
      bsc: 'EUR',
      bdc: 'MATIC',
      ssc: 'MATIC',
      sdc: 'EUR',
      net: 'matic_mainnet',
      nets: 'matic_mainnet , mainnet , arbitrum_mainnet , avalanche_mainnet , bsc_mainnet',
      crys: 'ETH , MATIC , USDC , USDT , AVAX , BNB , DAI , FRAX , LUSD , MAI , WBTC , BUSD',
      rfr: 'crowdswap',
      _ctkn: '7fd71ffb-22da-410f-b1c0-c43a684568b7'
      /*
      code: string,
      hash: string,
      addr: string,
      chain:string,
       */
    };

    const mtPelerinOptionsUrlEncode = new URLSearchParams(
      mtPelerinOptions as any
    );

    const data = {
      toggle: true,
      scanUrl: `https://widget.mtpelerin.com/?${mtPelerinOptionsUrlEncode}`
    };
    this.dialogOpened = true;
    let mtPelerinDialogRef =
      await this.MtPelerinDialogComponentModalService.open(
        MtPelerinDialogComponent,
        data,
        true
      );
    const sub: Subscription = (<MtPelerinDialogComponent>(
      (<any>mtPelerinDialogRef!.instance)
    )).confirmed.subscribe(async () => {
      await this.tagManagerService.sendFiatOnRampTag('fiat_close');

      await this.MtPelerinDialogComponentModalService.close();
      this.dialogOpened = false;
    });

    mtPelerinDialogRef!.onDestroy(() => {
      this.dialogOpened = false;
      sub.unsubscribe();
    });
  }
}
