import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import {
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../../services';
import {
  SelectedInvestmentTab,
  SelectedOpportunityTab
} from '../model/opportunity-state.enum';
import { OpportunityType } from '../model/opportunity-state.enum';

@Component({
  selector: 'app-investment',
  templateUrl: './investment.component.html',
  styleUrls: ['./investment.component.scss']
})
export class InvestmentComponent extends BaseComponent implements OnInit {
  selectedActionTab = SelectedInvestmentTab;
  selectedTab = SelectedInvestmentTab.Invest;
  public confirmation: boolean = false;
  public plan: any;
  public OpportunityType = OpportunityType;
  _opportunity;

  @Output()
  updateDetails = new EventEmitter();

  @Output()
  close = new EventEmitter();

  @Input()
  opportunities;

  @Input()
  selectedOpportunityTab = SelectedOpportunityTab.AllOpportunities;
  public SelectedOpportunityTab = SelectedOpportunityTab;

  @Input()
  set opportunity(opportunity: any) {
    this._opportunity = opportunity;
  }

  get opportunity() {
    return this._opportunity;
  }

  constructor(
    public web3Service: Web3Service,
    private router: Router,
    private ref: ChangeDetectorRef,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit() {
    super.ngOnInit();

    this.web3Service.walletConnectionChangeSubject.subscribe(async () => {
      this.ref.detectChanges();
    });
  }

  async toggleTabs(tabName) {
    this.selectedTab = tabName;
  }

  showingSteps(showHide: boolean) {
    this.confirmation = showHide;
    this.ref.detectChanges();
  }

  updateOppDetails() {
    this.updateDetails.emit(false);
  }

  selectPlan(plan: any) {
    this.plan = plan;
    this.ref.detectChanges();
  }

  closeOpportunity() {
    this.close.emit();
  }

  planImgName(): string {
    return (
      'assets/media/etf/' +
      this.opportunity.etfName +
      '_' +
      this.opportunity.planName.replace(/\s/g, '') +
      (this.isDarkMode ? '_dark' : '') +
      '.png'
    );
  }
}
