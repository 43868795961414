import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  commonConfig,
  Conversion,
  CrowdToken,
  ERC20,
  FractalRegistry,
  Networks,
  NetworksById,
  TokensHolder,
  OpportunityInvestmentTypeName,
  OpportunitiesHolder,
  MainNetworksById,
  YOUR_INVEST,
  MulticallService
} from '@crowdswap/constant';
import { ethers } from 'ethers';
import qs from 'qs';
import { Observable, throwError, interval } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Constants } from '../constants';
import { NetworksService } from './networks.service';
import { UtilsService } from './utils.service';
import { Web3Service } from './web3.service';

const baseUrl = environment.Opportunity_BASEURL || '';

@Injectable()
export class PrivateSaleService {
  //private fractalRegistryAddress = '0xfBDb867e7eFf0e3dBe63eE52eDA24d83fBacFe25';

  private crowdSaleList;
  private opportunities;

  constructor(
    private http: HttpClient,
    private web3Service: Web3Service,
    private networkService: NetworksService,
    private multicallService: MulticallService
  ) {
    this.opportunities = OpportunitiesHolder.Opportunities;
    interval(1800 * 1000).subscribe(() => {
      this.cacheCrowdSaleList().then();
    });
  }

  private getContract(chainId, address: string, abi: any) {
    return new ethers.Contract(address, abi, this.getProvider(chainId));
  }

  private async isKYC(presale, address) {
    const presaleContract = this.getContract(
      presale.chainId,
      OpportunitiesHolder.OpportunitiesData.PRESALE.networks[presale.chainId]
        .contractAddress,
      OpportunitiesHolder.OpportunitiesData.PRESALE.contractAbi
    );
    return presaleContract.isKYC(presale.presaleName, address);
  }

  private async isKYCWhenEth(presale, address) {
    const fractalContract = this.getContract(
      Networks.BSCMAIN,
      OpportunitiesHolder.OpportunitiesData.PRESALE.networks[Networks.BSCMAIN]
        .fractalContract,
      FractalRegistry
    );

    const fractalId = await fractalContract.getFractalId(address);
    return fractalContract.isUserInList(
      fractalId,
      presale.fractalListId ?? ' '
    );
  }

  public async getPresaleTX(
    presale,
    opportunityName: string,
    userAddress: string | undefined,
    token: CrowdToken,
    amount: string,
    slippage: string,
    deadline: string,
    networkCoinPrice: string,
    investmentType: number
  ): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/presaleOpportunity/${opportunityName}/${OpportunityInvestmentTypeName[investmentType]}`;
      const data = {
        userAddress: userAddress,
        token: token,
        amount: amount,
        networkCoinPrice: networkCoinPrice,
        slippage: slippage,
        deadline: deadline,
        presaleName: presale.presaleName,
        presaleChainId: presale.chainId
      };
      let params = new HttpParams({ fromString: qs.stringify(data) });

      return await this.http.get(url, { params: params }).toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to get opportunity estimate');
  }

  public async createNewPresale(formData): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/presaleOpportunity/createPresale`;

      return this.http.post(url, formData).toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error(`Unable to create a presale for ${formData.name}`);
  }

  // Returns an observable
  uploadPresaleIcon(file, presaleName): Observable<any> {
    const url = `${baseUrl}/api/v1/presaleOpportunity/uploadPresaleIcon`;
    // Create form data
    const formData = new FormData();

    const suffix = file.name.split('.');
    // Store form name as "file" with file data
    formData.append(
      'file',
      file,
      presaleName + '.' + suffix[suffix.length - 1]
    );

    // Make http post request over api
    // with formData as req
    return this.http.post(url, formData);
  }

  public async didRegistry(presale, address) {
    return presale.chainId === Networks.MAINNET
      ? this.isKYCWhenEth(presale, address)
      : this.isKYC(presale, address);
  }

  public async didSign(presaleName): Promise<any> {
    try {
      const userAddress: any = this.web3Service.getWalletAddress();
      const url = `${baseUrl}/api/v1/presaleOpportunity/verify`;

      let params = new HttpParams({
        fromString: qs.stringify({
          presaleName: presaleName,
          userAddress: userAddress,
          signature: '1'
        })
      });

      const result: any = await this.http
        .get(url, { params: params })
        .toPromise();
      if (
        result &&
        result.user.address.toLowerCase() === userAddress.toLowerCase()
      ) {
        return true;
      }
      return false;
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to verify');
  }

  async getPoolDetails(presale) {
    const contract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );

    const investToken =
      TokensHolder.TokenListByAddress[NetworksById[presale.chainId]][
        presale.investToken
      ];

    const result = await contract.getPoolDetail();
    return {
      investedAmount: +ethers.utils.formatUnits(
        result[0].toString(),
        investToken.decimals
      ),
      totalInvestorShare: result[1],
      startTime: result[2][0],
      tgeDate: result[2][1],
      finishTime: result[2][2],
      hardCap: +ethers.utils.formatUnits(
        result[2][3].toString(),
        investToken.decimals
      ),
      softCap: +ethers.utils.formatUnits(
        result[2][4].toString(),
        investToken.decimals
      ),
      minInvest: +ethers.utils.formatUnits(
        result[2][5].toString(),
        investToken.decimals
      ),
      priceStages: result[3].map((result) => {
        const amount = +ethers.utils.formatUnits(
          result[0].toString(),
          investToken.decimals
        );
        const price = +ethers.utils.formatUnits(result[1].toString(), 6);
        return [amount, price];
      }),
      priceStagesConverted: result[3].map((result) => {
        const amount = +ethers.utils.formatUnits(
          result[0].toString(),
          investToken.decimals
        );
        return Conversion.numFormatter(amount);
      }),
      priceStage: result[4],
      firstHoursBestPrice: result[2][6],
      vestingType: result[2][8] ?? 0
    };
  }

  async getUserDetails(presale) {
    const contract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );

    const investToken =
      TokensHolder.TokenListByAddress[NetworksById[presale.chainId]][
        presale.investToken
      ];

    const result = await contract.investorIndex(
      this.web3Service.getWalletAddress()
    );
    if (result[5]) {
      return {
        investorShare: Conversion.adjustFraction(
          Conversion.convertStringFromDecimal(
            result[0],
            presale.presaleTokenDecimal
          )
        ),
        released: Conversion.adjustFraction(
          Conversion.convertStringFromDecimal(
            result[1],
            presale.presaleTokenDecimal
          )
        ),
        balance: Conversion.adjustFraction(
          Conversion.convertStringFromDecimal(result[2], investToken.decimals)
        ),
        state: result[4]
      };
    }
    return { investorShare: '0', released: '0', balance: '0', state: 0 };
  }

  async claim(presale, address) {
    const contract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );

    try {
      const result = await contract.callStatic.getReleasable(address);
      return (+Conversion.convertStringFromDecimal(
        result,
        presale.presaleTokenDecimal
      )).toFixed(7);
    } catch (e) {
      return '0';
    }
  }

  async signMessage(signMessage, presaleName) {
    const signature = await this.web3Service.signMessage(signMessage);

    try {
      const url = `${baseUrl}/api/v1/presaleOpportunity/sign`;

      return await this.http
        .post(url, {
          signature: signature,
          opportunityName: presaleName
        })
        .toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to sign message');
  }

  public async verifySigner(presale, signature) {
    const signerAddress = ethers.utils.verifyMessage(
      presale.signMessage,
      signature
    );
    const address = this.web3Service.getWalletAddress();
    return signerAddress === address;
  }

  public async getPresaleList() {
    if (this.crowdSaleList == undefined) {
      await this.cacheCrowdSaleList();
    }
    return this.crowdSaleList;
  }

  private getProvider(chainId) {
    return this.networkService.getNetworkProvider(chainId).getProvider();
  }

  public getRemainingTime(startTime, currentTime) {
    return UtilsService.getRemainingTime(startTime, currentTime);
  }

  async getTicket(presale) {
    const iconxContract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );

    const ticketToken =
      TokensHolder.TokenListByAddress[NetworksById[presale.chainId]][
        presale.ticketToken
      ];
    return +Conversion.convertStringFromDecimal(
      await iconxContract.getTicket(),
      ticketToken.decimals
    );
  }

  async getTicketList(presale) {
    const iconxContract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );
    return iconxContract.getTickets();
  }

  async getStage(presale) {
    const iconxContract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );
    return iconxContract.priceStage();
  }

  async release(presale): Promise<any> {
    const iconxContract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );
    return iconxContract.populateTransaction.release();
  }

  async buyTicket(presale): Promise<any> {
    const presaleContract = this.getContract(
      presale.chainId,
      OpportunitiesHolder.OpportunitiesData.PRESALE.networks[presale.chainId]
        .contractAddress,
      OpportunitiesHolder.OpportunitiesData.PRESALE.contractAbi
    );
    return presaleContract.populateTransaction.buyTicket(
      presale.presaleName,
      this.web3Service.getWalletAddress()
    );
  }

  async approveBuyingTicket(presale): Promise<any> {
    const ticketToken =
      TokensHolder.TokenListByAddress[NetworksById[presale.chainId]][
        presale.ticketToken
      ];
    const crowdContract = this.getContract(
      presale.chainId,
      ticketToken.address,
      ERC20
    );
    return crowdContract.populateTransaction.approve(
      OpportunitiesHolder.OpportunitiesData.PRESALE.networks[presale.chainId]
        .contractAddress,
      '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
    );
  }

  async allowanceBuyingTicket(presale): Promise<any> {
    const ticketToken =
      TokensHolder.TokenListByAddress[NetworksById[presale.chainId]][
        presale.ticketToken
      ];
    const crowdContract = this.getContract(
      presale.chainId,
      ticketToken.address,
      ERC20
    );
    const result = await crowdContract.allowance(
      this.web3Service.getWalletAddress(),
      OpportunitiesHolder.OpportunitiesData.PRESALE.networks[presale.chainId]
        .contractAddress
    );
    return result > Conversion.convertStringToDecimal('10000');
  }

  async getNextRelease(presale) {
    const iconxContract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );
    return iconxContract.nextUserRelease(this.web3Service.getWalletAddress());
  }

  withdrawWhenFailed(presale) {
    const iconxContract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );
    return iconxContract.populateTransaction.withdrawInvestedToken();
  }

  async getPoolState(presale) {
    const iconxContract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );
    return iconxContract.state();
  }

  public getInvestAmountByCrossChain(
    opportunity: any,
    userAddress: string | undefined,
    token: CrowdToken,
    wToken: CrowdToken,
    amount: string,
    slippage: string,
    networkCoinInUSDT: string,
    reqTimeout: number = 1350000
  ): Observable<string> | undefined {
    try {
      const params = {
        userAddress: userAddress,
        srcChainId: token.chainId,
        srcChainTokenInAddress: token.address,
        srcChainTokenInAmount: Conversion.convertStringToDecimal(
          amount,
          token.decimals
        ).toString(),
        slippage: commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE,
        dstChainId: wToken.chainId,
        dstChainTokenOutAddress: wToken.address
      };
      return this.getDebridgeEstimation(params, opportunity, reqTimeout).pipe(
        catchError((err) => {
          if (
            err.error &&
            (err.error.msg.includes(
              'Execution fee < expected amount of incoming token'
            ) ||
              err.error.msg.includes('Input amount must be greater than'))
          ) {
            return throwError(Constants.EXECUTION_FEE_ERROR);
          }
          return throwError(-1);
        }),
        map((result: any) => {
          const estimation = result.body.estimation;
          return Conversion.adjustFraction(
            Conversion.convertStringFromDecimal(
              estimation.dstChainTokenOut.amount,
              wToken.decimals
            ),
            6
          );
        })
      );
    } catch (err) {
      console.error(`Error message: ${err}`);
      return undefined;
    }
  }

  private getDebridgeEstimation(params, opportunity, reqTimeout) {
    const url = `${baseUrl}/api/v1/presaleOpportunity/${opportunity.name}/crossChainEstimation/`;

    return this.http.get(url, { params: params, observe: 'response' }).pipe(
      timeout(reqTimeout),
      map((response: any) => {
        return {
          body: response.body,
          xCorrelationId: response.headers.get('x-correlation-id')
        };
      })
    );
  }

  async withdrawBeneficiaryFunds(presale): Promise<any> {
    const iconxContract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );
    return iconxContract.populateTransaction.withdrawInvestmentsByBeneficiary();
  }

  async getOwner(presale): Promise<any> {
    const iconxContract = this.getContract(
      presale.chainId,
      presale.poolAddress,
      presale.abiVersion && presale.abiVersion > 1
        ? OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi2
        : OpportunitiesHolder.OpportunitiesData.PRESALE.poolAbi1
    );
    return iconxContract.owner();
  }

  async getPoolBalance(presale): Promise<any> {
    const investTokenContract = this.getContract(
      presale.chainId,
      presale.investToken,
      ERC20
    );
    return investTokenContract.balanceOf(presale.poolAddress);
  }

  private async cacheCrowdSaleList() {
    this.crowdSaleList = await this.getCrowdSaleList();
  }

  private async getCrowdSaleList(): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/presaleOpportunity/getPresaleList`;
      const result = await this.http.get(url).toPromise();
      return this.getVisibleSaleList(result);
    } catch (err) {
      console.error(`Error message: ${err}`);
      return [];
    }
  }

  private async getVisibleSaleList(preSales: any): Promise<any> {
    try {
      if (!this.web3Service.isConnected()) {
        return;
      }
      const data: any[] = [];
      let results;
      for (let i = 0; i < preSales.length; i++) {
        if (preSales[i]?.justVisibleForInvestors) {
          const lpStakeholders = {
            oppName: preSales[i].presaleName,
            chainId: preSales[i].chainId,
            target: preSales[i].poolAddress,
            params: [this.web3Service.getWalletAddress()],
            method: 'investorIndex'
          };
          data.push(lpStakeholders);
        }
      }
      if (data) {
        results = await this.multicallOnDifferentNetworks(data);
      }
      const preSaleList = this.filterInvestedOpportunities(
        preSales,
        results,
        data
      );
      if (preSaleList.length > 0) console.log('RRRRRRRR ', preSaleList);

      return preSaleList;
    } catch (err) {
      console.error(`Error message: ${err}`);
      return [];
    }
  }

  async multicallOnDifferentNetworks(data) {
    const promises: any[] = [];
    for (const network of Object.keys(MainNetworksById).filter((chainId) =>
      environment.ACTIVE_NETWORK.includes(chainId)
    )) {
      promises.push(
        this.multicallService.call(
          this.web3Service.getNetworkProvider(+network),
          YOUR_INVEST,
          data.filter((item) => {
            return item.chainId === +network;
          })
        )
      );
    }
    let results;
    await Promise.all(promises).then((items) => {
      results = items;
    });
    return results;
  }

  filterInvestedOpportunities(opportunities, results, data) {
    const filteredData = opportunities.filter((opportunity) => {
      if (!opportunity?.justVisibleForInvestors) {
        return opportunity;
      }
      const index = this.getMulticallResultIndex(
        data,
        opportunity.chainId,
        opportunity.presaleName,
        'investorIndex'
      );
      const chainId = this.getNetworkIndex(opportunity.chainId);
      if (index !== -1 && results[chainId][index]['exist']) {
        return opportunity;
      }
    });
    return filteredData;
  }

  getMulticallResultIndex(
    data: any[],
    chainId: number,
    opportunityName: string,
    methodName: string
  ) {
    const index = data
      .filter((item) => {
        return item.chainId === chainId;
      })
      .findIndex((item) => {
        return item.oppName === opportunityName && item.method === methodName;
      });
    return index;
  }

  getNetworkIndex(_chainId) {
    for (const [index, chainId] of Object.keys(MainNetworksById)
      .filter((chainId) => environment.ACTIVE_NETWORK.includes(chainId))
      .entries()) {
      if (_chainId === +chainId) {
        return index;
      }
    }
    return -1;
  }
}
