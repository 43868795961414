<div class="w-full opportunity_page_items">
  <div
    class="w-full d-flex flex-column justify-center py-25px px-sm-25px px-0 border-radius-6px border-md-2px border-00000010 dark:border-1E1E1E dark:bg-121212"
  >
    <div class="x-1 z-0 col-12">
      <div class="swap-box z-0" id="assetBox">
        <!--WITHDRAW LABEL-->
        <div class="swap-token-header">
          <span class="title font-12 dark:text-fff">WITHDRAW AMOUNT </span>
        </div>

        <!--WITHDRAW BOX-->
        <div
          id="choose-asset-opp"
          class="swap-token-box border-radius-8px mt-2 h-110 border-2 border-0066FF dark:border-3385ff bg-white dark:bg-121212"
          id="etf-token"
        >
          <div class="swap-token-select my-3">
            <div class="flex justify-content-end margin-top-6 mr-2">
              <button class="count flex items-center">
                <label class="switch mr-1">
                  {{ selectedWithdrawToken }}
                  <input
                    [(ngModel)]="selectedWithdrawToken"
                    type="checkbox"
                    checked
                  />
                  <span class="slider round"></span>
                </label>
                <span
                  [ngClass]="
                    selectedWithdrawToken
                      ? 'text-0066FF dark:text-3385FF'
                      : 'text-000 dark:text-fff'
                  "
                >
                  Swap to token
                </span>
              </button>
            </div>
            <div class="w-full flex items-center justify-content-between">
              <div class="flex-column">
                <div
                  class="d-flex align-items-center w-full"
                  [ngClass]="{ 'animate-pulse': loadingAmount }"
                >
                  <input
                    appFocusAndSelectValue
                    [el]="chooseAssetToken"
                    #chooseAssetToken
                    type="text"
                    inputmode="decimal"
                    mask="percent.2"
                    suffix="%"
                    [(ngModel)]="removePercentage"
                    (ngModelChange)="changeValue(removePercentage)"
                    [disabled]="
                      loadingAmount ||
                      etfOpportunityState === EtfOpportunityState.Confirmed
                    "
                    class="w-full border-0 p-0 outline-none font-32 shadow-none bg-transparent dark:text-fff"
                    [ngClass]="{
                    ' bg-gray-300 text-gray-300 rounded-sm dark:bg-gray-300 dark:text-gray-300':
                      loadingAmount,

                  }"
                  />
                </div>
                <div>
                  <nz-skeleton-element
                    class="skeleton-h-16"
                    nzType="button"
                    [nzActive]="true"
                    [nzSize]="'small'"
                    *ngIf="loadingAmount"
                  ></nz-skeleton-element>
                  <div *ngIf="!loadingAmount">
                    ~&nbsp;{{ investedAmountUSD }}
                  </div>
                </div>
              </div>

              <div
                class="flex-shrink-0"
                [hidden]="!selectedWithdrawToken"
                [ngClass]="{ 'animate-pulse': loadingAmount }"
              >
                <button
                  id="source-option"
                  (click)="openSelectOptionDialog(opportunityTokenList)"
                  [disabled]="
                    loadingAmount ||
                    etfOpportunityState === EtfOpportunityState.Confirmed
                  "
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="token-style border-radius-4px d-flex align-items-center cursor-pointer bg-F7F7F7 dark:bg-1E1E1E"
                  type="button"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="token-symbol">
                      <img
                        class="rounded-full"
                        width="20"
                        [src]="
                          baseLogoIconsUrl +
                          token?.symbol?.toLowerCase() +
                          '.png'
                        "
                        (error)="updateUrl($event)"
                      />
                    </div>
                  </div>
                  <div class="token-selected dark:text-white">
                    <span class="symbol text-000000cc dark:text-fff">
                      {{ token?.symbol }}
                    </span>
                  </div>
                  <div class="token-arrow text-000000cc dark:text-fff">
                    <svg
                      class="-mr-1 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--AMOUNT LABEL-->
        <div class="swap-token-header mt-3">
          <span class="title font-12 dark:text-fff">AMOUNT PERCENT</span>
        </div>

        <!--AMOUNT PERCENT-->
        <div
          class="flex justify-content-between font-14 font-openSans-semiBold text-0066FF dark:text-3385FF"
        >
          <div *ngFor="let item of percents">
            <div class="flex">
              <button
                (click)="changeValue(item)"
                class="py-1 px-3 rounded-md"
                [ngClass]="{
                  'border-0066FF dark:border-3385ff border-2':
                    +removePercentage === +item
                }"
              >
                {{ item }}%
              </button>
            </div>
          </div>
        </div>

        <!--DISTRIBUTION LABEL-->
        <div class="mt-3 bg-white dark:bg-121212">
          <div class="swap-token-header w-full">
            <div class="flex pb-1 items-center detail">
              <span class="title dark:text-fff font-12"> DISTRIBUTION </span>
            </div>
          </div>
        </div>

        <!--DISTRIBUTION TOKENS-->
        <div
          class="swap-token-box border-radius-8px mt-2 w-full flex flex-wrap bg-white dark:bg-121212 px-0 mx--4px"
        >
          <div
            *ngFor="let item of opportunity.tokenShares"
            class="flex-column items-center col-md-3 col-4 p-1 detail"
          >
            <div class="bg-F0F0F0 dark:bg-1E1E1E p-2 rounded-md items-center">
              <div class="flex mb-2">
                <img
                  class="rounded-full"
                  width="20"
                  [src]="
                    baseLogoIconsUrl +
                    item?.token?.symbol?.toLowerCase() +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                />

                <div class="title ml-1 text-000 dark:text-fff font-14">
                  {{ normalizeSymbol(item?.token?.symbol) }}
                </div>
              </div>

              <div class="text-000 dark:text-fff font-12">
                <span class="font-openSans-bold">
                  <nz-skeleton-element
                    class="skeleton-h-16"
                    nzType="button"
                    [nzActive]="true"
                    [nzSize]="'small'"
                    *ngIf="loadingAmount"
                  ></nz-skeleton-element>
                  <span *ngIf="!loadingAmount">
                    {{ item.amountPercentToDisplay }}
                  </span>
                </span>
                /{{ item.amountToDisplay }}
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="etfOpportunityState === EtfOpportunityState.ApprovalConfirmed"
          class="flex mt-2 ml-3 pl-2"
        >
          <div class="flex items-center">
            <svg
              class="h-6 w-6 text-green-300 opacity-50"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </div>

          <div class="text-xs text-gray-400 ml-2 flex items-center">
            Now you can withdraw
          </div>
        </div>

        <!--Approve button-->
        <div
          *ngIf="
            this.etfOpportunityState === EtfOpportunityState.ApprovalNeeded
          "
          class="flex flex-col mt-2"
        >
          <button
            nz-button
            nzType="default"
            (click)="approve()"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          >
            <span class="px-1">
              Allow to withdraw the {{ opportunity.planName }}
            </span>
          </button>
        </div>

        <!--Confirm button-->
        <div
          *ngIf="isUserWalletConnected && !isWrongNetwork"
          class="flex flex-col mt-2"
        >
          <button
            nz-button
            nzType="default"
            (click)="confirm()"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 mt-3"
            [ngClass]="{
              'opacity-4':
                disableConfirm ||
                loadingAmount ||
                etfOpportunityState === EtfOpportunityState.Confirmed ||
                etfOpportunityState === EtfOpportunityState.ApprovalNeeded ||
                etfOpportunityState === EtfOpportunityState.ApprovalRejected ||
                etfOpportunityState ===
                  EtfOpportunityState.InsufficientTokenBalance
            }"
            [disabled]="
              disableConfirm ||
              loadingAmount ||
              etfOpportunityState === EtfOpportunityState.Confirmed ||
              etfOpportunityState === EtfOpportunityState.ApprovalNeeded ||
              etfOpportunityState === EtfOpportunityState.ApprovalRejected ||
              etfOpportunityState ===
                EtfOpportunityState.InsufficientTokenBalance
            "
          >
            <span class="px-1">
              {{
                selectedWithdrawToken
                  ? "Withdraw"
                  : "Withdraw Distribution Tokens"
              }}
            </span>
          </button>
        </div>

        <div
          *ngIf="
            isUserWalletConnected &&
            etfOpportunityState === EtfOpportunityState.WrongNetwork
          "
          class="flex flex-col"
        >
          <button
            nz-button
            nzType="default"
            [nzSize]="'default'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [ngClass]="{
              'opacity-4': true
            }"
            [disabled]="true"
          >
            <span class="px-1"> Wrong network </span>
          </button>
        </div>

        <div *ngIf="!isUserWalletConnected" class="flex flex-col mt-2">
          <button
            (click)="connectWallet()"
            nz-button
            nzType="default"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          >
            <span class="px-1"> Connect Wallet </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
