<app-dialog [dialogWidth]="false" [disableMode]="true"  [isSelectOption]="true" *ngIf="this.toggle">
  <div>
    <div class="pl-6 flex justify-start bp-2">
      <div class="absolute right-5 mr-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          (click)="toggleDropdown()"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>
    </div>
    <div class="divide-y dark:divide-appLightBackground">
      <div>
        <div
          class="rounded-xl pt-1 px-1 md:px-1 pb-1 w-full"
          style="height: 710px"
        >
          <iframe
            width="100%"
            height="100%"
            id="iframeMtPelerin"
            [src]="mtPelerinURLEncoded"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</app-dialog>
