import { Component } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-button',
  templateUrl: './button-component.html'
})
export class ButtonComponent {
  disable = false;
  loading = false;
  radioValue = 'A';

  constructor(private messageService: NzMessageService) {}

  btnClick() {
    this.messageService.info('button clicked!');
  }
}
