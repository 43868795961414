import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Conversion,
  CrowdToken,
  MainNetworksById,
  Networks,
  NetworksById,
  TokensHolder
} from '@crowdswap/constant';
import { filter, throttleTime } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AccountDialogComponent } from '../../modal/dialogs/account-dialog/account-dialog.component';
import { ChangeNetworkDialogComponent } from '../../modal/dialogs/change-network-dialog/change-network-dialog.component';
import { ModalService } from '../../modal/modal.service';

import { asyncScheduler } from 'rxjs';
import { Constants } from 'src/app/constants';
import { CurrentNetwork } from '../../../model';
import {
  ActiveMenu,
  AffiliateService,
  ConnectWalletService,
  NDDClientInfoServiceImpl,
  NavigatorService,
  PortfolioService,
  TagManagerService,
  ThemeService,
  UtilsService,
  Web3Service
} from '../../../services';
import { BaseComponent } from '../../pages/base.component';
import { WelcomeDialogComponent } from '../../modal/dialogs/welcome-dialog/welcome-dialog.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { FeedbackService } from '../../../services/feedback.service';
import { DialogDataModel } from '../../../model';
import { UserFeedbackComponent } from '../../modal/dialogs/user-feedback/user-feedback.component';
import { CookieService } from 'ngx-cookie-service';

export enum WalletState {
  WalletConnected,
  WalletNotConnected
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  baseToken?: { address: string; name: string; balance: string };
  copyClipboard?: string;
  currentChainId: number = 1;
  correctChainId: number = 1; //it uses in case of wrong/unsupported network
  pendingCounter: number = 0;
  networks = Networks;
  selectFlag: boolean = false;
  selectMeatballsFlag: boolean = false;
  selectUserFlag: boolean = false;
  tooltip: boolean = false;
  crowdTooltip: boolean = false;
  chainIds: string[] = [];
  walletState: WalletState = WalletState.WalletNotConnected;
  enableNetworkSelection: boolean = true;
  ActiveMenu = ActiveMenu;
  isMismatchNetwork: boolean = false;
  isCrowdTokenAvailable: boolean = false;
  welcomeDialog!: import('ng-zorro-antd/modal').NzModalRef<
    WelcomeDialogComponent,
    any
  >;

  constructor(
    public web3Service: Web3Service,
    public themeService: ThemeService,
    public navigatorService: NavigatorService,
    protected tagManagerService: TagManagerService,
    private ref: ChangeDetectorRef,
    private accountDialogService: ModalService<AccountDialogComponent>,
    private changeNetworkDialogService: ModalService<ChangeNetworkDialogComponent>,
    private router: Router,
    private affiliateService: AffiliateService,
    private portfolioService: PortfolioService,
    private feedbackService: FeedbackService,
    protected feedbackModalService: ModalService<UserFeedbackComponent>,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    protected connectWalletService: ConnectWalletService,
    private nzModalService: NzModalService,
    private viewContainerRef: ViewContainerRef,
    public cookieService: CookieService
  ) {
    super(
      web3Service,
      themeService,
      tagManagerService,
      clientInfoServiceImpl,
      connectWalletService
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectFlag = false;
      }
    });
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    HeaderComponent.removeAffiliateId();
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();

    HeaderComponent.setAffiliateId();
    this.setGleamId();

    if (localStorage.getItem('darkMode') === null) {
      localStorage.setItem('darkMode', 'false');
    } else {
      switch (localStorage.getItem('darkMode')) {
        case 'true': {
          this.isDarkMode = true;
          break;
        }
        case 'false': {
          this.isDarkMode = false;
          break;
        }
      }
      this.themeService.darkModeChangeSubject.next(this.isDarkMode);
    }

    const currentPage: string = UtilsService.getCurrentPage();
    await this.navigatorService.changePage(currentPage);

    let index = 0;
    if (environment.production) {
      for (let i in MainNetworksById) {
        if (environment.ACTIVE_NETWORK.includes(i)) {
          this.chainIds[index++] = i;
        }
      }
    } else {
      for (let i in NetworksById) {
        if (environment.ACTIVE_NETWORK.includes(i)) {
          this.chainIds[index++] = i;
        }
      }
    }

    this.web3Service.currentNetworkChangeSubject
      .pipe(
        filter((currentNetwork: CurrentNetwork) => {
          return currentNetwork.chainId > 0;
        }),
        throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
      )
      .subscribe(async (currentNetwork) => {
        this.isCrowdTokenAvailable =
          !!TokensHolder.TokenListBySymbol[Networks[currentNetwork.chainId]][
            'CROWD'
          ] && window.ethereum;
        await this.refreshToolbar();
      });

    this.web3Service.walletNetworkChangeSubject
      .pipe(
        throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
      )
      .subscribe(async (walletChainId: number) => {
        let fromTokenInUrl: CrowdToken | undefined;
        let toTokenInUrl: CrowdToken | undefined;
        [fromTokenInUrl, toTokenInUrl] = this.getTokensFromURL();

        if (!this.web3Service.isConnected()) {
          if (fromTokenInUrl && toTokenInUrl) {
            this.currentChainId = fromTokenInUrl.chainId;
            this.web3Service.currentNetworkChangeSubject.next(
              new CurrentNetwork(
                this.currentChainId,
                fromTokenInUrl,
                toTokenInUrl
              )
            );
          } else {
            switch (this.navigatorService.getActiveMenu()) {
              case ActiveMenu.ClassicSwap:
              case ActiveMenu.Opportunities:
              case ActiveMenu.Portfolio:
                this.currentChainId = Constants.SWAP_INITIAL_CHAIN_ID;
                break;
              case ActiveMenu.Exchange:
                this.currentChainId = Constants.CROSSCHAIN_INITIAL_CHAIN_ID;
                break;
            }
            this.web3Service.currentNetworkChangeSubject.next(
              new CurrentNetwork(this.currentChainId)
            );
            this.isMismatchNetwork = false;
            this.web3Service.mismatchNetworkSubject.next(
              this.isMismatchNetwork
            );
            await this.refreshToolbar();
          }
          return;
        }

        //if connected
        if (
          this.navigatorService.getActiveMenu() == ActiveMenu.ClassicSwap ||
          this.navigatorService.getActiveMenu() == ActiveMenu.Exchange
        ) {
          if (fromTokenInUrl && toTokenInUrl) {
            this.currentChainId = fromTokenInUrl.chainId;
            this.web3Service.currentNetworkChangeSubject.next(
              new CurrentNetwork(
                this.currentChainId,
                fromTokenInUrl,
                toTokenInUrl
              )
            );

            if (walletChainId == this.currentChainId) {
              this.isMismatchNetwork = false;
              this.web3Service.mismatchNetworkSubject.next(
                this.isMismatchNetwork
              );
            } else {
              this.isMismatchNetwork = true;
              this.web3Service.mismatchNetworkSubject.next(
                this.isMismatchNetwork
              );
              await this.refreshToolbar();
              return;
            }
          }
        }

        this.isMismatchNetwork = false;
        UtilsService._clearQueryString();

        // await this.$gtmService.pushTag({
        //   event: 'network_change',
        //   category: 'setting',
        //   chainId: walletChainId,
        //   isConnected: this.web3Service.isConnected(),
        // });

        const isWrong_ =
          !environment.ACTIVE_NETWORK.includes(walletChainId.toString()) ||
          (environment.production && !(walletChainId in MainNetworksById)) ||
          (!environment.production && !(walletChainId in NetworksById)) ||
          !this.web3Service.getNetworkName(walletChainId);

        const isWalletChainUnSupported_ =
          this.isUnSupportedForThisPage(walletChainId);

        if (isWrong_ || isWalletChainUnSupported_) {
          this.correctChainId = this.currentChainId;
          this.incorrectNetworkMessage = 'Wrong network';
          if (isWalletChainUnSupported_) {
            const isCurrentChainUnSupported_ = this.isUnSupportedForThisPage(
              this.currentChainId
            );
            this.correctChainId = isCurrentChainUnSupported_
              ? Constants.CROSSCHAIN_INITIAL_CHAIN_ID
              : this.currentChainId;
            this.incorrectNetworkMessage = 'Switch network';
          }

          this.isWrongNetwork = (!environment.SmartWallet) ? isWrong_ : false;
          this.web3Service.wrongNetworkSubject.next(this.isWrongNetwork);
          return;
        } else {
          this.isWrongNetwork = false;
          this.web3Service.wrongNetworkSubject.next(this.isWrongNetwork);
        }

        this.currentChainId = walletChainId;
        if (
          !this.isWrongNetwork /*BLOC-750*/ &&
          this.web3Service.getCurrentChainId() != walletChainId
        ) {
          this.web3Service.currentNetworkChangeSubject.next(
            new CurrentNetwork(walletChainId)
          );
        }

        await this.refreshToolbar();
      });

    //The network of specified in the url (if exists) is different from the wallet network.
    this.web3Service.mismatchNetworkSubject.subscribe(
      (isMismatchNetwork: boolean) => {
        this.isMismatchNetwork = isMismatchNetwork;
        if (this.isMismatchNetwork) {
          this.incorrectNetworkMessage = 'Mismatch network';
        }
        this.ref.detectChanges();
      }
    );

    this.web3Service.pendingChangeSubject.subscribe(async (pendingCount) => {
      this.pendingCounter = <number>pendingCount;
      await this.refreshToolbar();
    });

    this.onWalletConnectionChange(this.web3Service.isConnected());
    this.web3Service.walletConnectionChangeSubject.subscribe((connection) => {
      this.onWalletConnectionChange(connection);
    });

    this.web3Service.accountChangeSubject.subscribe(async (address) => {
      await this.refreshToolbar();

      await this.updateAffiliate(address);

      this.showNotification(address);
    });

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.enableNetworkSelection = !event.url
          .toUpperCase()
          .includes('whitelisting'.toUpperCase());
      });

    this.feedbackService.feedbackRequestSubject.subscribe(async (param) => {
      await this.showFeedbackModalIfNeeded(param);
    });
  }

  public async refreshToolbar() {
    if (
      !this.web3Service.isConnected() ||
      this.web3Service.getCurrentChainId() < 0
    ) {
      this.baseToken = { address: '', balance: '', name: '' };
    } else {
      try {
        const balance =
          (await this.web3Service.getBalance(
            undefined,
            this.web3Service.getNetworkProvider(
              this.web3Service.getCurrentChainId()
            )
          )) ?? 0;

        this.baseToken = {
          address: this.web3Service.getWalletAddress()!,
          balance: Conversion.adjustFraction(
            parseInt(balance!.toString(), 10) / Math.pow(10, 18),
            7
          ),
          name: this.web3Service.networkSpec[
            this.web3Service.getCurrentChainId()
          ].coin
        };
      } catch (e) {
        // this.showErrorToaster(
        //   'Unable to change network',
        //   'Please check ' +
        //     this.web3Service.networkSpec[this.web3Service.getCurrentChainId()]
        //       .title +
        //     ' RPC.'
        // );
        console.log(e);
      }
    }
    this.ref.detectChanges();
  }

  public onClose(flag) {
    switch (flag) {
      case 'select': {
        this.selectFlag = false;
        break;
      }
      case 'meatballs': {
        this.selectMeatballsFlag = false;
        break;
      }
      case 'UserMenu': {
        this.selectUserFlag = false;
        break;
      }
    }
  }

  public async showChangeNetworkDialog(
    newChainId: number,
    opportunityFlag: number = 0
  ) {
    this.selectFlag = false;
    if (this.walletState === WalletState.WalletConnected) {
      if (newChainId == this.web3Service.getWalletChainId()) {
        if (this.isMismatchNetwork) {
          this.isMismatchNetwork = false;
          this.currentChainId = newChainId;
          this.web3Service.mismatchNetworkSubject.next(this.isMismatchNetwork);
          UtilsService._clearQueryString();
          this.web3Service.currentNetworkChangeSubject.next(
            new CurrentNetwork(this.currentChainId)
          );
        }
      } else {
        const data = {
          currentNetwork: this.web3Service.networkSpec[newChainId].title,
          walletNetwork: this.web3Service.networkSpec[
            this.web3Service.getWalletChainId()
          ]
            ? this.web3Service.networkSpec[this.web3Service.getWalletChainId()]
                .title
            : undefined,
          isOpportunity: opportunityFlag === 1,
          isDarkMode: this.isDarkMode
        };
        const changeNetworkDialogRef =
          await this.changeNetworkDialogService.open(
            ChangeNetworkDialogComponent,
            data
          );
        (<ChangeNetworkDialogComponent>(
          (<any>changeNetworkDialogRef!.instance)
        )).confirmed.subscribe(async (event) => {
          await this.changeNetworkDialogService.close();
          if (!event) {
            return;
          }
          try {
            await this.web3Service.changeNetwork(newChainId);
            UtilsService._clearQueryString();
          } catch (e) {
            console.log(e);
          }
        });
      }
    } else {
      if (newChainId != this.web3Service.getCurrentChainId()) {
        this.currentChainId = newChainId;
        this.web3Service.currentNetworkChangeSubject.next(
          new CurrentNetwork(newChainId)
        );
        UtilsService._clearQueryString();
        await this.refreshToolbar();
      }
    }
  }

  public async changeAccountDialog() {
    const data = { isDarkMode: this.isDarkMode };
    const accountDialogRef = await this.accountDialogService.open(
      AccountDialogComponent,
      data
    );

    (<AccountDialogComponent>(
      (<any>accountDialogRef!.instance)
    )).returnValue.subscribe(async (event) => {
      await this.accountDialogService.close();
      if (event === 3) {
        await this.connectWallet();
        this.ref.detectChanges();
      }
      if (event === 2) {
        this.walletAddress = '';
        await this.web3Service.clearCachedProvider();
        await this.refreshToolbar();
      }
    });
  }

  public async changePage(page: string) {
    await this.navigatorService.changePage(page);
  }

  public async addCrowdToken() {
    const currentNetwork = this.web3Service.getCurrentChainId();
    const crowdToken =
      TokensHolder.TokenListBySymbol[Networks[currentNetwork]]['CROWD'];
    await this.web3Service.addTokenToWallet(crowdToken);
  }

  public async networkMenuClickHandler(selectedChainId: number) {
    if (this.currentChainId != selectedChainId) {
      await this.showChangeNetworkDialog(selectedChainId);
    }
  }

  private getTokensFromURL(): (CrowdToken | undefined)[] {
    let fromToken: CrowdToken | undefined;
    let toToken: CrowdToken | undefined;
    [fromToken, toToken] = UtilsService.getSwapInfoFromUrl();
    if (fromToken && toToken) {
      switch (this.navigatorService.getActiveMenu()) {
        case ActiveMenu.ClassicSwap:
          if (
            fromToken.chainId !== toToken.chainId ||
            fromToken.equals(toToken)
          ) {
            fromToken = undefined;
            toToken = undefined;
          }
          break;
        case ActiveMenu.Exchange:
          if (fromToken.chainId === toToken.chainId) {
            fromToken = undefined;
            toToken = undefined;
          }
          break;
      }
    } else {
      fromToken = toToken = undefined;
    }
    return [fromToken, toToken];
  }

  /*
   * Getting affiliateId from url and set to local storage
   * */
  private static setAffiliateId() {
    const affiliateId: string | undefined =
      UtilsService.getQueryVariable('referralId') ||
      UtilsService.getQueryVariable('clickid');
    if (affiliateId) {
      localStorage.setItem('referralId', affiliateId);
    }
  }

  /*
   * Getting GleamId from url and set to cookie
   * */
  private setGleamId() {
    const gleamXRefFromUrl = UtilsService.getGleamXRefFromUrl();
    if (gleamXRefFromUrl) {
      this.cookieService.set('GleamId', gleamXRefFromUrl);
    }
  }

  public static getAffiliateId(): string | null {
    return localStorage.getItem('referralId');
  }

  private static removeAffiliateId() {
    localStorage.removeItem('referralId');
  }

  public async toggleMeatballsMenu() {
    this.selectMeatballsFlag = !this.selectMeatballsFlag;
  }

  private isUnSupportedForThisPage(walletChainId: number) {
    // Previously, some networks were not supported on the cross-chain page. Currently, we don't have this case, but we're keeping it for future usage
    return false;
  }

  private showNotification(walletAddress: string) {
    try {
      this.portfolioService
        .getUnreadNotifications(walletAddress)
        .then(async (notifications: [] | null) => {
          if (!notifications || notifications.length === 0) {
            console.log(
              `There is no notifications for wallet address ${walletAddress}`
            );
            return;
          }

          notifications.forEach(
            (notification: { _id: string; message: string }) => {
              this.welcomeDialog = this.nzModalService.create<
                WelcomeDialogComponent,
                any
              >({
                nzTitle: undefined,
                nzFooter: [],
                nzContent: WelcomeDialogComponent,
                nzViewContainerRef: this.viewContainerRef,
                nzData: {
                  modalBody: notification.message,
                  walletAddress: walletAddress
                },
                nzWrapClassName: `${
                  this.isDarkMode ? 'dark' : 'light'
                } welcome-dialog bg-blur-4px`,
                nzClassName:
                  'bg-gray-50 dark:bg-darkBlack p-0 border-radius-6px',
                nzClosable: false,
                nzCloseOnNavigation: false
              });

              this.welcomeDialog.afterClose.subscribe((result) => {
                this.portfolioService.seenNotification(notification._id);
              });
            }
          );
        })
        .catch((error) => {
          console.error(
            `Unable to show ${walletAddress}'s notification, ${error.message}`
          );
        });
    } catch (error: any) {
      console.error(
        `Unable to show ${walletAddress}'s notification, ${error.message}`
      );
    }
  }

  private onWalletConnectionChange<T>(connection: T) {
    try {
      this.walletState = this.web3Service.isConnected()
        ? WalletState.WalletConnected
        : WalletState.WalletNotConnected;
      if (this.walletState == WalletState.WalletNotConnected) {
        this.isMismatchNetwork = false;
        this.web3Service.mismatchNetworkSubject.next(this.isMismatchNetwork);
      }
      if (!connection) {
        // switch (this.navigatorService.getActiveMenu()) {
        //   case this.ActiveMenu.Opportunities:
        //     await this.router.navigate(['opportunity']);
        //     break;
        //   case this.ActiveMenu.Affiliate:
        //     await this.router.navigate(['affiliate/revenue']);
        //     break;
        //   case this.ActiveMenu.SignIn:
        //     await this.router.navigate(['SignIn']);
        //     break;
        // }
      }
      this.ref.detectChanges();
    } catch (e) {
      console.error(e);
    }
  }

  private async updateAffiliate(address: string) {
    const affiliateId: string | null = HeaderComponent.getAffiliateId(); //reading from local storage
    if (affiliateId) {
      try {
        await this.affiliateService.saveAffiliate(affiliateId, address);
      } catch (error: any) {
        console.error(
          `Unable to save address ${address} for affiliateId ${affiliateId}, ${error.message}`
        );
      }
    }
  }

  private async showFeedbackModalIfNeeded(param: string) {
    const hasNewFeedback = await this.feedbackService.hasNewFeedback(
      this.web3Service.getWalletAddress()
    );
    if (!hasNewFeedback) {
      let data: DialogDataModel = { title: param };
      await this.feedbackModalService.open(UserFeedbackComponent, data, false);
    }
  }
}
