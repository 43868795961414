import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseEtfComponent } from '../base-etf.component';
import {
  LoggingService,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../../services';
import {
  SelectedInvestmentTab,
  SelectedOpportunityTab
} from '../../opportunity/model/opportunity-state.enum';
import { NgxMaskService } from 'ngx-mask';
import { NDDClientInfoServiceImpl } from 'src/app/services/client-info';
import { IETFOpportunityItem } from '../model/etf.model';

@Component({
  selector: 'app-etf-item',
  templateUrl: './etf-item.component.html',
  styleUrls: ['./etf-item.component.scss'],
  providers: [NgxMaskService]
})
export class EtfItemComponent extends BaseEtfComponent {
  @Input() opportunity!: IETFOpportunityItem;
  @Input() selectedTab = SelectedOpportunityTab.AllOpportunities;
  @Input() selectedActionTab!: SelectedInvestmentTab | undefined;
  @Input() loading!: boolean;
  @Input() itemsPage;
  @Input() index: number | undefined;

  public SelectedOpportunityTab = SelectedOpportunityTab;
  public SelectedInvestmentTab = SelectedInvestmentTab;

  @Output()
  onInvestClicked = new EventEmitter();

  constructor(
    web3Service: Web3Service,
    themeService: ThemeService,
    tagManagerService: TagManagerService,
    public logger: LoggingService,
    private ngxMaskService: NgxMaskService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(
      web3Service,
      themeService,
      tagManagerService,
      logger,
      clientInfoServiceImpl
    );
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
  }

  async invest(item: any) {
    await this.tagManagerService.sendEtfOpportunityTags(
      this.selectedTab === SelectedOpportunityTab.AllOpportunities
        ? 'ETF_invest_select'
        : 'ETF_withdraw_select',
      item,
      'etf_item'
    );
    this.onInvestClicked.emit(item);
  }
}
