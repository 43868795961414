import { Component, Input } from '@angular/core';
import { BaseEtfComponent } from '../base-etf.component';
import {
  LoggingService,
  TagManagerService,
  ThemeService,
  Web3Service
} from 'src/app/services';
import { NDDClientInfoServiceImpl } from 'src/app/services/client-info';
import {
  SelectedInvestmentTab,
  SelectedOpportunityTab
} from '../../opportunity/model/opportunity-state.enum';

@Component({
  selector: 'app-details-card',
  templateUrl: './details-card.component.html',
  styleUrls: ['./details-card.component.scss']
})
export class DetailsCardComponent extends BaseEtfComponent {
  @Input()
  public opportunity;
  @Input()
  loading!: boolean;

  @Input()
  selectedOpportunityTab = SelectedOpportunityTab.AllOpportunities;
  @Input() selectedActionTab!: SelectedInvestmentTab | undefined;

  constructor(
    web3Service: Web3Service,
    themeService: ThemeService,
    tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    public logger: LoggingService
  ) {
    super(
      web3Service,
      themeService,
      tagManagerService,
      logger,
      clientInfoServiceImpl
    );
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
  }
}
