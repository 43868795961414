import { Component, EventEmitter, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { FormsModule } from '@angular/forms';
import { ThemeService, Web3Service } from '../../../../services';
import { FeedbackService } from '../../../../services/feedback.service';

export enum Feedback {
  NotSelected,
  Awful,
  Bad,
  Meh,
  Good,
  Great
}

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss']
})
export class UserFeedbackComponent extends DialogsComponent {
  public isWrongEmail: boolean = false;
  public Feedback = Feedback;
  public isDarkMode: boolean = false;
  public feedbackData: FeedbackData = {
    address: '',
    feelLevel: Feedback.NotSelected,
    description: '',
    email: ''
  };
  public feedbackFormFlag = true;
  public submitUserFeedback = new EventEmitter<FeedbackData>();
  public isSuccess: boolean = false;

  constructor(
    public web3Service: Web3Service,
    private feedbackService: FeedbackService,
    protected themeService: ThemeService
  ) {
    super();
  }

  async close() {
    this.closed.emit(true);
  }

  public ngOnInit(): void {
    this.themeService.darkModeChangeSubject.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });
  }

  selectFeelLevel(number: number) {
    if (this.feedbackData.feelLevel === number) {
      this.feedbackData.feelLevel = Feedback.NotSelected;
    } else {
      this.feedbackData.feelLevel = number;
    }
  }

  async submitFeedback() {
    try {
      this.feedbackData.address = <string>this.web3Service.getWalletAddress();
      await this.feedbackService.insertUserFeedback(this.feedbackData);
      if (this.feedbackData.description) {
        this.isSuccess = true;
      } else {
        await this.close();
        return;
      }
    } catch (e) {
      this.isSuccess = false;
    } finally {
      this.feedbackFormFlag = false;
    }
  }

  tryAgain() {
    this.feedbackFormFlag = true;
  }

  emailValidation() {
    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    this.isWrongEmail = !regexp.test(this.feedbackData.email!);
    return this.isWrongEmail;
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule],
  declarations: [UserFeedbackComponent],
  exports: [UserFeedbackComponent]
})
export class UserFeedbackComponentModule {}

interface FeedbackData {
  address: string;
  feelLevel: Feedback;
  description: string;
  email: string;
}
