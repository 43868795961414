<app-dialog>
  <div class="flex justify-start"></div>
  <div class="divide-y">
    <div>
      <div class="rounded-md pt-1 pr-4 pl-4 pb-2 w-full">
        <div class="font-openSans-bold text-center dark:text-white">
          Change wallet network?
        </div>
        <div class="grid grid-cols-3 gap-4">
          <div
            class="col-span-3 text-center font-openSans-regular text-xs-2 dark:text-darkGrayText"
          >
            {{
              data?.isOpportunity
                ? "Switch to " +
                  data?.currentNetwork +
                  " network in order to invest in current opportunities."
                : "The selected network (" +
                  data?.currentNetwork +
                  ") does not match the network that is selected in the wallet"
            }}<span *ngIf="data?.walletNetwork"
              >&nbsp;({{ data?.walletNetwork }})</span
            >.
          </div>
        </div>
      </div>
      <div class="flex flex-col mt-3 mb-3">
        <button
          (click)="confirm(true)"
          id="change-network"
          class="blue-button py-2 px-4 rounded h-52 font-openSans-bold flex justify-center text-white text-sm items-center text-center hover:bg-blue-700"
        >
          <span>Change network</span>
        </button>
      </div>
      <div class="flex flex-col">
        <button
          (click)="confirm(false)"
          class="px-4 rounded dark:border dark:border-darkGrayText h-10 flex justify-center font-openSans-bold text-sm items-center text-center dark:text-white"
        >
          <span>Don't change</span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
