<div class="col-md-12 mt-3 mt-md-0 px-1 px-md-0 relative">
  <div
    class="d-md-flex justify-center text-sm py-2 text-white iconx-bg rounded-lg"
    style="
      background-image: url(assets/media/icons/iconx-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    "
  >
    <div
      class="row justify-end justify-content-md-start col-12 col-md-8 px-md-2"
    >
      <div class="row mb-2">
        <div class="flex items-center px-0">
          <img
            class="rounded-full"
            style="width: 32px"
            src="{{ baseUrl }}/api/v1/image/{{
              presale.presaleName.toLowerCase()
            }}.png"
          />
          &nbsp;{{ presale.presaleDisplayName }}
        </div>
        <div class="flex items-center pt-3 pt-md-0 px-0">
          {{ presale.bannerDescription }}
        </div>
      </div>
      <div class="flex col-11 mb-2 px-1">
        <div
          *ngFor="let item of poolDetail?.priceStages; let i = index"
          class="flex col-{{ (12 / poolDetail.priceStages.length).toFixed(0) }}"
        >
          <div class="col-12">
            <div class="pl-1">
              <div class="text-white p-1">
                ROUND {{ i + 1 }}&nbsp;
                <span class="text-xs-2"
                  >%{{ stage[i] ? stage[i].toFixed(0) : 0 }}</span
                >
              </div>
              <div class="bg-white bg-opacity-25">
                <div
                  id="stage"
                  class="h-3.5"
                  [ngClass]="{
                    'bg-white': 0 < +stage[i] && +stage[i] <= 20,
                    'bg-red-200': 20 < +stage[i] && +stage[i] <= 40,
                    'bg-red-400': 40 < +stage[i] && +stage[i] <= 60,
                    'bg-red-600': 60 < +stage[i] && +stage[i] <= 80,
                    'bg-red-800': 80 < +stage[i] && +stage[i] <= 100
                  }"
                  [style.width.%]="stage[i]"
                ></div>
              </div>
              <div
                [ngClass]="
                  i === 0 ? 'justify-content-between' : 'justify-content-end'
                "
                class="flex"
              >
                <div *ngIf="i === 0">0</div>
                <div>
                  ${{ poolDetail ? poolDetail.priceStagesConverted[i] : "" }}
                </div>
              </div>
              <div
                [ngClass]="
                  (
                    !isMinimum
                      ? i === 0
                        ? poolDetail.investedAmount <
                            poolDetail.priceStages[i][0] &&
                          0 <= poolDetail.investedAmount
                        : poolDetail.investedAmount <
                            poolDetail.priceStages[i][0] &&
                          poolDetail.priceStages[i - 1][0] <
                            poolDetail.investedAmount
                      : i === 0
                  )
                    ? 'text-green-500 font-openSans-semiBold'
                    : 'text-gray-400'
                "
              >
                <span class="text-xs">Token price:</span>
                ${{ poolDetail ? poolDetail.priceStages[i][1] : "" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-md-flex col-11 col-md-12 justify-content-between mb-2 px-1">
        <div class="flex justify-center items-center px-md-0">
          To participate, you need a valid KYC.&nbsp;
          <a href="{{ presale.fractalUrl }}" target="_blank" class="underline">
            Get it here
          </a>
        </div>
        <div class="flex justify-center items-center px-md-0">
          Total investment:&nbsp;$<span class="font-openSans-semiBold">{{
            poolDetail?.investedAmount.toFixed(2) | mask : "separator.3" : {thousandSeparator: ","}
          }}</span>
        </div>
      </div>
    </div>
    <div class="row justify-end col-12 col-md-3 px-md-2">
      <div class="col-12 row px-1 mb-2">
        <div>
          <span>
            {{
              startTime > currentTime
                ? "It starts in:"
                : finishTime > currentTime
                ? "It ends in:"
                : "It is finished"
            }}</span
          >
        </div>
      </div>
      <div class="col-12 font-openSans-semiBold row px-1 mb-2">
        <div class="col-3 col-md-6 flex p-1 items-center justify-center">
          <div class="w-full rounded-md text-white icon-x-btn">
            <div class="flex items-center justify-center">
              {{
                startTime > currentTime
                  ? startTimeRemain.daysLeft
                  : finishTimeRemain.daysLeft
              }}
            </div>
            <div
              class="flex items-center justify-center font-openSans-regular text-xs"
            >
              DAYS
            </div>
          </div>
        </div>
        <div class="col-3 col-md-6 flex p-1 items-center justify-center">
          <div class="w-full rounded-md text-white icon-x-btn">
            <div class="flex items-center justify-center">
              {{
                startTime > currentTime
                  ? startTimeRemain.hoursLeft
                  : finishTimeRemain.hoursLeft
              }}
            </div>
            <div
              class="flex items-center justify-center font-openSans-regular text-xs"
            >
              HOURS
            </div>
          </div>
        </div>
        <div class="col-3 col-md-6 flex p-1 items-center justify-center">
          <div class="w-full rounded-md text-white icon-x-btn">
            <div class="flex items-center justify-center">
              {{
                startTime > currentTime
                  ? startTimeRemain.minutesLeft
                  : finishTimeRemain.minutesLeft
              }}
            </div>
            <div
              class="flex items-center justify-center font-openSans-regular text-xs"
            >
              MINUTES
            </div>
          </div>
        </div>
        <div class="col-3 col-md-6 flex p-1 items-center justify-center">
          <div class="w-full rounded-md text-white icon-x-btn">
            <div class="flex items-center justify-center">
              {{
                startTime > currentTime
                  ? startTimeRemain.secondsLeft
                  : finishTimeRemain.secondsLeft
              }}
            </div>
            <div
              class="flex items-center justify-center font-openSans-regular text-xs"
            >
              SECONDS
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 font-openSans-semiBold row px-1 mb-2">
        <div class="col-6 flex p-1 items-center justify-center">
          <button
            [ngClass]="{
              'pointer-events-none finished-button': finishTime < currentTime
            }"
            class="w-full h-10 rounded-md text-white border border-red-500 bg-red-500"
            (click)="invest(presale)"
          >
            <span class="font-openSans-semiBold"> INVEST</span>
          </button>
        </div>
        <div class="col-6 flex p-1 items-center justify-center">
          <button
            class="w-full h-10 rounded-md text-white border"
            (click)="openDetail(presale)"
          >
            <span class="font-openSans-semiBold">Details</span>
          </button>
        </div>
      </div>
      <div class="mb-2">
        <div *ngIf="startTime > currentTime" class="pl-3">
          <span
            >It ends in: {{ finishTimeRemain.daysLeft }}D
            {{ finishTimeRemain.hoursLeft }}H
            {{ finishTimeRemain.minutesLeft }}M
            {{ finishTimeRemain.secondsLeft }}S</span
          >
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="absolute -top-4 -right-4">-->
  <!--    <img src="assets/media/icons/Star.png" />-->
  <!--  </div>-->
</div>
