<app-dialog class="w-20 dark:bg-darkBlack">
  <div class="flex justify-start mb-2">
    <div>
      <label class="text-base text-xs-2 pb-2 topTitle dark:text-white">
        Account
      </label>
    </div>
    <div class="absolute right-5 mr-1 dark:text-textWhite">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="close()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
  </div>
  <div class="flex justify-start mb-2">
    <div class="mt-2 mb-3">
      <label class="text-base dark:text-textWhite">
        Connected with {{ getProviderName() }}
      </label>
    </div>
    <div class="absolute right-5 mr-1">
      <button
        (click)="changeWallet()"
        class="blue-button py-2 px-4 rounded h-37 flex justify-center font-openSans-bold text-sm items-center lightblue dark:bg-darkButton hover:bg-blue-700"
      >
        <span>Change</span>
      </button>
    </div>
  </div>
  <div
    class="flex justify-start bg-gray-100 dark:bg-hoverBackground rounded pr-4 pl-4 pb-2 w-full"
  >
    <div class="flex items-center justify-center mt-2">
      <img src="{{ getIconURL() }}" height="15" width="15" />
    </div>
    <div class="flex items-center justify-center">
      <label class="text-gray-900 dark:text-textWhite pt-1 pl-1 mt-2">
        {{ getAddress() }}
      </label>
    </div>
  </div>
  <div
    *ngIf="isDistributionContractValid && isUserBeneficiary"
    class="flex justify-start mt-3 mb-3 mb-3 text-sm"
  >
    <div class="row">
      <div class="col-13 col-sm-8 col-md-8 col-xl-8 col-xxl-8">
        <div>
          <div class="flex w-56">
            <div class="flex items-center justify-center">
              <img
                src="assets/media/logo/Logos_CrowdSwap_CROWD%20Token.svg"
                height="40"
                width="40"
              />
            </div>
            <div
              class="flex grid grid-rows-2 text-gray-400 items-center justify-center pl-3"
            >
              <span>CROWD Inventory</span>
              <span
                *ngIf="
                  distributionCrowdBalance >= 1 || distributionCrowdBalance == 0
                "
                class="font-openSans-semiBold text-gray-900 dark:text-textWhite"
              >
                {{ distributionCrowdBalance }}</span
              >
              <span
                *ngIf="
                  distributionCrowdBalance < 1 && distributionCrowdBalance != 0
                "
                class="font-openSans-semiBold text-gray-900 dark:text-textWhite"
              >
                Less than 1</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-13 col-sm-4 col-md-4 col-xl-4 col-xxl-4">
        <div
          class="cursor-pointer font-openSans-semiBold rounded border border-gray-300 dark:bg-dialogBackground dark:border-darkGrayText shadow-sm py-2 bg-white absolute right-5 mr-1 px-3"
        >
          <div class="flex text-gray-800" (click)="openWithdrawDialog()">
            <div class="flex items-center justify-center">
              <img
                *ngIf="!data?.isDarkMode"
                src="assets/media/icons/withdraw-black.svg"
                height="12"
                width="14"
              />
              <img
                *ngIf="data?.isDarkMode"
                src="assets/media/icons/withdraw-white.svg"
                height="12"
                width="14"
              />
            </div>
            <div
              class="flex items-center justify-center pl-1 dark:text-textWhite"
            >
              Withdraw
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr *ngIf="isDistributionContractValid && isUserBeneficiary" />
  <div class="flex justify-start mt-3 text-sm">
    <div class="row">
      <div class="col-12 col-sm-4 col-md-4 col-xl-4 col-xxl-4">
        <div
          class="text-blue-600 cursor-pointer hover:text-blue-800"
          target="_blank"
          (click)="copyAddressToClipboard()"
        >
          <div class="flex w-32">
            <div class="flex items-center justify-center">
              <img src="{{ getCopyAddress() }}" height="10" width="10" />
            </div>
            <div class="flex items-center justify-center pl-1">
              <span>{{ copyAddress }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-xl-4 col-xxl-4">
        <a
          class="text-blue-600 hover:text-blue-800"
          target="_blank"
          href="{{ scanUrl }}"
        >
          <div class="flex w-24">
            <div class="flex items-center justify-center">
              <img
                src="assets/media/icons/etherscan-logo.svg"
                height="10"
                width="10"
              />
            </div>
            <div class="flex items-center justify-center pl-1">
              {{ scanName }}
            </div>
          </div>
        </a>
      </div>
      <div class="col-12 col-sm-4 col-md-4 col-xl-4 col-xxl-4">
        <div class="text-blue-600 hover:text-blue-800 cursor-pointer">
          <div class="flex w-24" (click)="exitWallet()">
            <div class="flex items-center justify-center">
              <img
                src="assets/media/icons/disconnect-logo.svg"
                height="9"
                width="10"
              />
            </div>
            <div class="flex items-center justify-center pl-1">Disconnect</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-dialog>
