import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Web3Service } from './web3.service';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import {
  PriceService,
  OpportunitiesHolder,
  CrowdToken,
  Conversion,
  ETFReceipt,
  NetworksById
} from '@crowdswap/constant';
import { OpportunityType } from '../views/pages/opportunity/model/opportunity-state.enum';
import { UtilsService } from '../services';
import { NgxMaskService } from 'ngx-mask';
import { BehaviorSubject } from 'rxjs';
import { ethers } from 'ethers';
import { TimeService } from './time.service';
import { Web3Provider } from '@ethersproject/providers';
import {
  IETFInvest,
  IETFOpportunityItem
} from '../views/pages/etf-opportunity/model/etf.model';

const baseUrl = environment.Opportunity_BASEURL || '';

@Injectable()
export class EtfOpportunitiesService {
  public currentTime;
  public _investmentInfoSubject: BehaviorSubject<{
    loading: boolean;
    totalInvestment: number;
    opportunities: any[];
  }>;
  constructor(
    private http: HttpClient,
    private priceService: PriceService,
    private utilsService: UtilsService,
    private maskService: NgxMaskService,
    private web3Service: Web3Service,
    protected timeService: TimeService
  ) {
    this._investmentInfoSubject = new BehaviorSubject<{
      loading: boolean;
      totalInvestment: number;
      opportunities: any[];
    }>({
      loading: true,
      totalInvestment: NaN,
      opportunities: []
    });
  }

  public async getInvestments() {
    if (!this.web3Service.isConnected()) {
      return;
    }

    const bscEtf = OpportunitiesHolder.OpportunitiesData.ETF.etfList.BSC_ETF;
    const nftContract = this.getContract(
      this.web3Service.getNetworkProvider(bscEtf.chainId),
      bscEtf.nftAddress
    );

    const list: IETFInvest[] = await nftContract.getTokensByOwner(
      this.web3Service.getWalletAddress()
    );

    const formatedList: IETFOpportunityItem[] = [];
    for (const etfInvest of list) {
      const plan = this.findPlanByID(+etfInvest.planId);

      await this.getPlanTokenPrices(plan);
      this.getPlanInvestedAmounts(plan, etfInvest);

      let totalProfit = 0;

      const formatedData: IETFOpportunityItem = {
        tokenId: etfInvest.id,
        planId: etfInvest.planId,
        planName: plan.planName,
        displayName: plan.planName,
        etfName: plan.etfName,
        chainId: plan.chainId,
        Network: NetworksById[plan.chainId],
        active: plan.active,
        contractAddress: plan.contractAddress,
        nftAddress: plan.nftAddress,
        opportunityType: OpportunityType.ETF,
        createdDate: new Date(etfInvest.createTime * 1000),
        createdDateToDisplay: new Date(
          etfInvest.createTime * 1000
        ).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }),
        totalTVL: plan.totalTVL,
        TVL: plan.totalTVL,
        totalTVLToDisplay: formatNumber(
          plan.totalTVL,
          NumberType.FiatTokenPrice
        ),
        totalInvestedAmountUSD: plan.totalInvestedAmountUSD,
        totalInvestedAmountUSDToDisplay: formatNumber(
          plan.totalInvestedAmountUSD,
          NumberType.FiatTokenPrice
        ),
        totalProfit: 0,
        totalProfitToDisplay: '',
        tokenShares: etfInvest.tokenDetails.map((td, index) => {
          const planTokenShareItem = plan.tokenShares[index];
          const token = planTokenShareItem.token;

          const tdPrice = parseFloat(
            Conversion.convertStringFromDecimal(td.price.toString(), 6)
          );

          const tdProfit = ((token.price - tdPrice) / tdPrice) * 100;

          const tokenPriceChangePercentage = (token.price - tdPrice) / tdPrice;

          totalProfit += tokenPriceChangePercentage * planTokenShareItem.share;

          const tdAmount = parseFloat(
            Conversion.convertStringFromDecimal(
              td.amount.toString(),
              planTokenShareItem.token.decimals
            )
          );

          return {
            share: planTokenShareItem.share,
            token: planTokenShareItem.token,
            tvl: planTokenShareItem.tvl,
            investedAmountUSD: planTokenShareItem.investedAmountUSD,
            investedAmountUSDToDisplay: formatNumber(
              planTokenShareItem.investedAmountUSD,
              NumberType.FiatTokenPrice
            ),
            amount: tdAmount,
            amountToDisplay: formatNumber(tdAmount, NumberType.TokenTx),
            profit: tdProfit,
            profitToDisplay: formatNumber(
              Math.abs(tdProfit),
              NumberType.TokenNonTx
            ),
            investPrice: tdPrice
          };
        })
      };

      formatedData.totalProfit = totalProfit;
      formatedData.totalProfitToDisplay = formatNumber(
        Math.abs(formatedData.totalProfit),
        NumberType.TokenNonTx
      );

      formatedList.push(formatedData);
    }

    return formatedList;
  }

  private getContract(web3Provider: Web3Provider, contractAddress: string) {
    return new ethers.Contract(contractAddress, ETFReceipt, web3Provider);
  }

  private findPlanByID(planId: number) {
    return OpportunitiesHolder.Opportunities['etf']?.data.find(
      (item) => item.planId === planId
    );
  }

  public findPlanByName(planName: string) {
    return OpportunitiesHolder.Opportunities['etf']?.data.find(
      (item) => item.planName === planName
    );
  }

  private async getPlanTokenPrices(plan) {
    const promises = plan.tokenShares.map(
      async (item, index) =>
        (plan.tokenShares[index].token.price = parseFloat(
          await this.priceService.getPrice(item.token)
        ))
    );

    await Promise.allSettled(promises);
  }

  private getPlanInvestedAmounts(plan, invest) {
    let totalInvestedAmountUSD = 0;

    for (let index = 0; index < plan.tokenShares.length; index++) {
      const tokenShare = plan.tokenShares[index];

      const investedAmountUSD = (tokenShare['investedAmountUSD'] =
        tokenShare.token.price *
        parseFloat(
          Conversion.convertStringFromDecimal(
            invest.tokenDetails[index].amount,
            tokenShare.token.decimals
          )
        ));

      tokenShare['investedAmountUSD'] = investedAmountUSD;
      totalInvestedAmountUSD += investedAmountUSD;
    }

    plan['totalInvestedAmountUSD'] = totalInvestedAmountUSD;
  }

  public async invest(
    userAddress: string,
    opportunity,
    token: CrowdToken,
    amount: any,
    slippage: string
  ) {
    let url = `${baseUrl}/api/v1/etf/invest?planName=${opportunity.planName}&token[decimals]=${token.decimals}&token[symbol]=${token.symbol}&token[name]=${token.name}&token[chainId]=${token.chainId}&token[address]=${token.address}&amount=${amount}&slippage=${slippage}`;

    if (userAddress) {
      url += `&userAddress=${userAddress}`;
    }

    try {
      const data = <any[]>await this.http.get(url).toPromise();
      return data;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  public async withdrawWithoutSwap(
    userAddress: string,
    opportunity,
    tokenId: string,
    percent: string
  ) {
    const url = `${baseUrl}/api/v1/etf/withdraw?etfName=${opportunity.etfName}&tokenId=${tokenId}&userAddress=${userAddress}&percent=${percent}`;
    const data = <any[]>await this.http.get(url).toPromise();
    return data;
  }

  public async withdrawWithSwap(
    userAddress: string,
    opportunity,
    tokenId: string,
    percent: string,
    token: CrowdToken,
    slippage: string
  ) {
    const url = `${baseUrl}/api/v1/etf/withdraw-swap?etfName=${opportunity.etfName}&tokenId=${tokenId}&userAddress=${userAddress}&percent=${percent}&targetToken[decimals]=${token.decimals}&targetToken[symbol]=${token.symbol}&targetToken[name]=${token.name}&targetToken[chainId]=${token.chainId}&targetToken[address]=${token.address}&slippage=${slippage}`;
    const data = <any[]>await this.http.get(url).toPromise();
    return data;
  }
}
