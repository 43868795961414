import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseComponent } from '../../pages/base.component';
import {
  Web3Service,
  ThemeService,
  NavigatorService,
  TagManagerService,
  NDDClientInfoServiceImpl
} from 'src/app/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent {
  showFooter = false;

  constructor(
    private router: Router,
    public web3Service: Web3Service,
    protected themeService: ThemeService,
    public navigatorService: NavigatorService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkLoadFooter(event.url);
      }
    });
  }

  checkLoadFooter(url: string) {
    if (
      url.match(/\/opportunity$/) ||
      url.match(/\/portfolio$/) ||
      url.match(/\/classicSwap$/) ||
      url.match(/\/exchange$/) ||
      url.match(/\/liquidity$/) ||
      url.match(/\/fiat$/) ||
      url.match(/\/revenue$/) ||
      url.match(/\/$/)
    ) {
      this.showFooter = true;
    } else {
      this.showFooter = false;
    }
  }
}
