import { ethers } from 'ethers';
import { UNISWAP_FACTORY_V2 } from '@crowdswap/constant';
import { Injectable } from '@angular/core';
import { Web3Service } from './web3.service';
import { Web3Provider } from '@ethersproject/providers';

@Injectable()
export class UniswapV2FactoryService {
  constructor(private provider: Web3Service) {}

  public async getPair(
    factoryAddress: string,
    token0Address: string,
    token1Address: string,
    provider: Web3Provider | undefined
  ): Promise<any> {
    const contract = new ethers.Contract(
      factoryAddress,
      UNISWAP_FACTORY_V2,
      provider || this.provider.web3Provider
    );
    return contract.getPair(token0Address, token1Address);
  }

  public async getReserves(
    pairAddress: string,
    provider: Web3Provider | undefined
  ): Promise<any> {
    const contract = new ethers.Contract(
      pairAddress,
      [
        'function getReserves() public view returns (uint112 _reserve0, uint112 _reserve1, uint32 _blockTimestampLast)'
      ],
      provider || this.provider.web3Provider
    );
    return contract.getReserves();
  }
}
