import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Conversion,
  CrowdToken,
  Dexchanges,
  TokensHolder,
  NetworksById,
  commonConfig
} from '@crowdswap/constant';
import { Web3Provider } from '@ethersproject/providers';
import { Builder } from 'builder-pattern';
import qs from 'qs';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Constants } from '../constants';
import { SearchParamModel } from '../model/search-param.model';
import { LoggingService } from './log/service/logging.service';
import { NetworksService } from './networks.service';
import { Web3Service } from './web3.service';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import {
  CrossAndSameChainEstimateTrade,
  CrossChainSwapState,
  IEstimateAllParam,
  IFee,
  IPatchState
} from '../model';
import { NotFoundException } from '../exception/not-found.exception';
import { ServerException } from '../exception/server.exception';
import { CrowdSwapService } from './crowdswap.service';
import { BaseSwapComponent } from '../views/pages/cross-and-same-chain-swap/base-swap.component';
import { BigNumber } from 'ethers';
import { ExchangeType } from '../views/pages/cross-and-same-chain-swap/model/cross-chain-state.enum';
import { EstimateTrade } from '../model/estimate-trade.model';

const baseUrl = environment.SWAP_BASEURL || '';
const API_KEY = environment.API_KEY;
const EstimationAllURl = `${baseUrl}/api/v1/crosschainswap/estimate-all/`;

@Injectable()
export class CrossChainSwapService {
  private defaultGas = {
    MAINNET: {
      protocolFee: 1000000000000000,
      price: 101000000000,
      coefficient: 1100,
      cost: 710000
    },
    BSCMAIN: {
      protocolFee: 5000000000000000,
      price: 5000000000,
      coefficient: 1000,
      cost: 1600000
    },
    POLYGON_MAINNET: {
      protocolFee: 500000000000000000,
      price: 65000000000,
      coefficient: 3000,
      cost: 2400000
    },
    AVALANCHE: {
      protocolFee: 10000000000000000,
      price: 100000000000,
      coefficient: 1400,
      cost: 2600000
    },
    ARBITRUM: {
      protocolFee: 1000000000000000,
      price: 700000000,
      coefficient: 800,
      cost: 12000000
    }
  };
  getEstimationSubscription: Subscription | undefined = undefined;
  public errorMessage: string | undefined = undefined;
  public isNoPairFound: boolean = false;
  estimations: EstimateTrade[] = [];
  searchParam$ = new BehaviorSubject<SearchParamModel | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private web3Service: Web3Service,
    private networkService: NetworksService,
    private logger: LoggingService,
    private crowdSwapService: CrowdSwapService
  ) {}

  public getCrossChainSwapEstimation(
    searchParam: SearchParamModel,
    networkCoinInUSDT: string,
    reqTimeout: number = 1350000
  ): Observable<Promise<CrossAndSameChainEstimateTrade>> | undefined {
    const amountIn: string = searchParam.swapValue;
    const amountInInDecimal: string = Conversion.convertStringToDecimal(
      searchParam.swapValue,
      searchParam.fromToken.decimals
    ).toString();

    this.logger.info(
      `Start getting cross chain swap estimation for sourceChainId = ${searchParam.fromToken.chainId}, fromToken = ${searchParam.fromToken.symbol}, dstChainId = ${searchParam.toToken.chainId}, dstToken = ${searchParam.toToken.symbol}, amount = ${amountIn}`
    );

    try {
      const userAddress = this.web3Service.getWalletAddress();
      const params: IEstimateAllParam = {
        userAddress: userAddress,
        amountIn: amountInInDecimal,
        fromToken: searchParam.fromToken,
        toToken: searchParam.toToken,
        slippage: searchParam.slippage
      };

      if (
        searchParam.exchangeType === ExchangeType.Limit &&
        searchParam.swapAmountOut
      ) {
        const amountOutInDecimal: string = Conversion.convertStringToDecimal(
          searchParam.swapAmountOut.replace(/\s/g, ''),
          searchParam.toToken.decimals
        ).toString();
        params['amountOut'] = amountOutInDecimal;
      }

      return this.crossChainEstimateAll(params, reqTimeout).pipe(
        map(async (result): Promise<CrossAndSameChainEstimateTrade> => {
          let estimation = result.body;
          if (!estimation) {
            throw new Error();
          }

          let estimateTrade: CrossAndSameChainEstimateTrade;
          switch (estimation.status) {
            case commonConfig.ERROR_CODES.NOT_FOUND:
              estimateTrade =
                BaseSwapComponent.GetNotFoundEstimate(searchParam);
              break;
            case commonConfig.ERROR_CODES.EXECUTION_FEE_ERROR:
            case commonConfig.ERROR_CODES.OUTPUT_AMOUNT_OUT_OF_SCOPE:
              estimateTrade =
                BaseSwapComponent.GetFeeErrorEstimation(estimation);
              break;
            default:
              estimateTrade = await this.handleSuccessfulCrossChainEstimation(
                estimation,
                amountInInDecimal,
                searchParam,
                userAddress,
                result,
                amountIn
              );
          }

          return estimateTrade;
        })
      );
    } catch (err) {
      console.error(`Error message: ${err}`);
      return undefined;
    }
  }

  public crossChainMarketEstimateAll() {}

  public async estimateGas(data: any, chainId: number): Promise<any> {
    const provider = await this.web3Service.getNetworkProvider(chainId);
    return await provider.estimateGas({
      from: this.web3Service.getWalletAddress(),
      to: data.to,
      data: data.data,
      value: data.value
    });
  }

  public async getCrossChainSwapTransaction(
    estimation: CrossAndSameChainEstimateTrade,
    slippage: string,
    userAddress: string,
    searchParam?: SearchParamModel
  ): Promise<any> {
    const amountIn: string =
      estimation.crossChainName == 'Debridge_ONEINCHE'
        ? estimation.cost.toString()
        : estimation.amountIn.toString();

    this.logger.info(
      `Start getting cross chain swap transaction for userAddress = ${userAddress}, sourceChainId = ${estimation.fromToken.chainId}, fromToken = ${estimation.fromToken.symbol}, dstChainId = ${estimation.toToken.chainId}, dstToken = ${estimation.toToken.symbol}, amount = ${amountIn}`
    );
    if (
      estimation.fromToken.chainId == undefined ||
      estimation.toToken.chainId == undefined
    ) {
      return;
    }
    try {
      const url = `${baseUrl}/api/v1/crosschainswap/${estimation.crossChainName}/`;
      const params = {
        fromToken: estimation.fromToken,
        toToken: estimation.toToken,
        amountIn: amountIn,
        slippage: slippage,
        userAddress: userAddress
      };

      if (searchParam?.exchangeType === ExchangeType.Limit) {
        params['amountOut'] = estimation.amountOut;
      }

      this.logger.info(
        `Finish getting cross chain swap transaction for userAddress = ${userAddress}, sourceChainId = ${estimation.fromToken.chainId}, fromToken = ${estimation.fromToken.symbol}, dstChainId = ${estimation.toToken.chainId}, dstToken = ${estimation.toToken.symbol}, amount = ${amountIn}`
      );

      return await this.http
        .get(url, {
          headers: { 'x-api-key': API_KEY },
          params: new HttpParams({ fromString: qs.stringify(params) })
        })
        .toPromise();
    } catch (e) {
      this.logger.error(
        `Error in CrossChainService, getting swap transaction for userAddress = ${userAddress}, srcChainId = ${
          estimation.fromToken.chainId
        }, fromToken = ${estimation.fromToken.symbol}, dstChainId = ${
          estimation.toToken.chainId
        }, dstToken = ${
          estimation.fromToken.symbol
        }, amount = ${amountIn}, error = ${JSON.stringify(e)}`
      );
    }
  }

  public async getAllowance(
    estimate: EstimateTrade,
    userAddress: string,
    provider: Web3Provider | undefined = undefined
  ) {
    if (!estimate.approveAddress) {
      console.log('Approval address is empty');
      return 0;
    }
    return this.web3Service.getAllowanceBySpender(
      estimate.approveAddress,
      estimate.fromToken.address,
      userAddress,
      provider
    );
  }

  public async getApprovalTransaction(estimate: EstimateTrade, amount: string) {
    if (!estimate.approveAddress) {
      return;
    }
    return this.web3Service.getApprovalTransactionBySpender(
      estimate.approveAddress,
      estimate.fromToken.address,
      amount,
      this.networkService
        .getNetworkProvider(estimate.fromToken.chainId)
        .getProvider()
    );
  }

  public async getAllFeesForMaxAmountIn(chainId: number) {
    let gasPrice =
      (await this.web3Service.getCCGasPrice()) ||
      this.defaultGas[NetworksById[chainId]].price;

    return gasPrice
      .mul(this.defaultGas[NetworksById[chainId]].coefficient)
      .mul(this.defaultGas[NetworksById[chainId]].cost + 21000)
      .div(1000)
      .add(this.defaultGas[NetworksById[chainId]].protocolFee.toString());
  }

  public getMultichainTxInfo(sourceTxHash): Promise<any> {
    try {
      const url = `https://scanapi.multichain.org/v3/tx/${sourceTxHash}`;
      return this.http
        .get(url, { observe: 'response' })
        .toPromise()
        .then((response) => response!.body);
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getMultichainTxInfo');
  }

  public getStatus(
    crossChainName: string,
    sourceTxHash: string,
    requestId: string,
    reqTimeout: number
  ): Promise<any> {
    try {
      const crossChain = Constants.CrosschainsMap[crossChainName];
      const url = `${baseUrl}/api/v1/crosschainswap/status/${crossChain}`;
      const params = { transactionHash: sourceTxHash, requestId: requestId };

      return this.http
        .get(url, {
          headers: { 'x-api-key': API_KEY },
          params: new HttpParams({ fromString: qs.stringify(params) }),
          observe: 'response'
        })
        .pipe(timeout(reqTimeout))
        .toPromise()
        .then((response) => response!.body);
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getStatus');
  }
  public getPatchTransaction(
    crossChainName: string,
    transactionHash: string,
    reqTimeout: number = 1350000
  ): Promise<any> {
    try {
      const crossChain = Constants.CrosschainsMap[crossChainName];
      const url = `${baseUrl}/api/v1/crosschainswap/patch/${crossChain}`;
      const params = { transactionHash };

      return this.http
        .get(url, {
          headers: { 'x-api-key': API_KEY },
          params: new HttpParams({ fromString: qs.stringify(params) })
        })
        .pipe(timeout(reqTimeout))
        .toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getPatchTransaction');
  }

  public getCancelTransaction(
    crossChainName: string,
    transactionHash: string,
    reqTimeout: number = 1350000
  ): Promise<any> {
    try {
      const crossChain = Constants.CrosschainsMap[crossChainName];
      const url = `${baseUrl}/api/v1/crosschainswap/cancel/${crossChain}`;
      const params = { transactionHash };
      return this.http
        .get(url, {
          headers: { 'x-api-key': API_KEY },
          params: new HttpParams({ fromString: qs.stringify(params) })
        })
        .pipe(timeout(reqTimeout))
        .toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
      throw err;
    }
  }

  private crossChainEstimateAll(params: IEstimateAllParam, reqTimeout) {
    return this.http
      .get(EstimationAllURl, {
        headers: { 'x-api-key': API_KEY },
        params: new HttpParams({ fromString: qs.stringify(params) }),
        observe: 'response'
      })
      .pipe(
        timeout(reqTimeout),
        map((response: any) => {
          return {
            body: response.body,
            xCorrelationId: response.headers.get('x-correlation-id')
          };
        })
      );
  }

  private async handleSuccessfulCrossChainEstimation(
    estimation: any,
    amountInInDecimal: string,
    searchParam: SearchParamModel,
    userAddress: string | undefined,
    result: { body: any; xCorrelationId: any },
    amountIn: string
  ) {
    estimation.crossChainName = Constants.CrosschainsMap[estimation.crossChain];
    //Using nested ifs in order to avoid useless calls
    if (estimation.crossChainName === 'Rango') {
      if (!!estimation.swapTx) {
        estimation.swapTx.gasLimit = undefined;
        if (
          await this.hasSufficientAmount(
            amountInInDecimal,
            searchParam.fromToken
          )
        ) {
          let isBaseToken: boolean = TokensHolder.isBaseToken(
            searchParam.fromToken.chainId,
            searchParam.fromToken.address
          );
          let hasAllowance;
          if (!isBaseToken) {
            hasAllowance = await this.hasAllowance(
              userAddress,
              estimation.approveAddress,
              searchParam.fromToken,
              amountInInDecimal
            );
          }

          if (
            (isBaseToken || hasAllowance) &&
            (await this.willBeFailed(
              estimation.swapTx,
              searchParam.fromToken.chainId
            ))
          ) {
            estimation = result.body.successfulEstimationsList[1];
            if (!estimation) {
              throw new Error();
            }
          }
        }
      }
    }
    this.logger.info(
      `Finish getting cross chain swap estimation for sourceChainId = ${searchParam.fromToken.chainId}, fromToken = ${searchParam.fromToken.symbol}, dstChainId = ${searchParam.toToken.chainId}, dstToken = ${searchParam.toToken.symbol}, amount = ${amountIn}, estimation = ${estimation}`
    );

    const amountOut = Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(
        estimation.amountOut ?? '0',
        searchParam.toToken.decimals
      ),
      6
    );

    let amountInInUSDT, amountInInUSDTToDisplay;
    if (searchParam.fromToken.price) {
      amountInInUSDT = +amountIn * +searchParam.fromToken.price;
      amountInInUSDTToDisplay = formatNumber(
        amountInInUSDT,
        NumberType.FiatTokenPrice
      );
    } else {
      amountInInUSDT = 'NaN';
      amountInInUSDTToDisplay = 'NaN';
    }

    let amountOutInUSDT, amountOutInUSDTToDisplay;
    if (searchParam.toToken.price) {
      amountOutInUSDT = +amountOut * +searchParam.toToken.price;
      amountOutInUSDTToDisplay = formatNumber(
        amountOutInUSDT,
        NumberType.FiatTokenPrice
      );
    } else {
      amountOutInUSDT = 'NaN';
      amountOutInUSDTToDisplay = 'NaN';
    }

    const displayFees: IFee[] = [];

    if (estimation?.fees) {
      estimation.fees.forEach((item) => {
        displayFees.push({
          token: item.token,
          amount:
            item.amount > '0'
              ? Conversion.adjustFraction(
                  Conversion.convertStringFromDecimal(
                    item.amount.toString(),
                    item.token.decimals
                  ),
                  6
                )
              : '0',
          type: item.type
        });
      });
    }

    const includedInOutputFees: IFee[] = [];
    if (estimation?.includedInOutputFees) {
      estimation?.includedInOutputFees.forEach((item) => {
        includedInOutputFees.push({
          token: item.token,
          amount: Conversion.adjustFraction(
            Conversion.convertStringFromDecimal(
              item.amount.toString(),
              item.token.decimals
            ),
            6
          ),
          type: item.type
        });
      });
    }

    let marketAmountOutToDisplay;
    if (estimation?.marketAmountOut) {
      marketAmountOutToDisplay = Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(
          estimation.marketAmountOut,
          searchParam.toToken.decimals
        ),
        6
      );
    }
    const patchState = this.getDefaultPatchState();
    const estimateTrade: CrossAndSameChainEstimateTrade =
      Builder<CrossAndSameChainEstimateTrade>()
        .correlationId(result.xCorrelationId)
        .dex(Dexchanges.CrossChain)
        .amountIn(amountInInDecimal)
        .amountInDisplay(Conversion.adjustFraction(amountIn, 6))
        .amountOut(estimation.amountOut)
        .marketAmountOut(estimation.marketAmountOut)
        .marketAmountOutToDisplay(marketAmountOutToDisplay)
        .amountOutDisplay(amountOut)
        .minAmountOut(estimation.minAmountOut)
        .fees(estimation.fees)
        .displayFees(displayFees)
        .includedInOutputFees(includedInOutputFees)
        .networkFee('0') //TODO: Check if it's possible to get the network fee from debrdige transaction
        .crowdswapFee('0')
        .pricePerToken(0)
        .cost(
          estimation.crossChainName == 'CROWDSWAP_BRIDGE'
            ? BigNumber.from(estimation.amountIn).add(estimation.fees[0].amount).toString()
            : estimation.amountIn
        ) //todo BLOC-1715: temporarily using cost as the new amountIn by deBridge
        .crowdswapFeePercentage('0')
        .currentDexFeePercentage('0')
        .fromToken(searchParam.fromToken)
        .toToken(searchParam.toToken)
        .delegatedDex('')
        .routes(estimation.routes)
        .swapFeeInUSDT('0')
        .networkFeeInUSDT('0') //TODO the network fee must be shown somehow
        .crowdswapFeeInUSDT('0')
        .totalFeeInUSDT('0')
        .totalPaidInUSDT('0')
        .totalIncomeInUSDT('0')
        .amountInInUSDT(amountInInUSDT.toString())
        .amountInInUSDTToDisplay(amountInInUSDTToDisplay)
        .amountOutInUSDT(amountOutInUSDT.toString())
        .amountOutInUSDTToDisplay(amountOutInUSDTToDisplay)
        .amountOutPerAmountInRatio(
          Conversion.adjustFraction((+amountIn / +amountOut).toString(), 6)
        )
        .amountInPerAmountOutRatio(
          Conversion.adjustFraction((+amountOut / +amountIn).toString(), 6)
        )
        .priceImpact('')
        .crossChainName(estimation.crossChainName)
        .requestId(estimation.requestId)
        .approveAddress(estimation.approveAddress)
        .swapTx(estimation.swapTx)
        .status(estimation.status)
        .msg(estimation.msg)
        .loading(false)
        .expirationDate(
          new Date(Date.now() + Constants.CROSSCHAIN_TX_LIFE_TIME)
        )
        .patchState(patchState)
        .build();
    return estimateTrade;
  }

  private async hasSufficientAmount(
    amountInDecimal: string,
    token: CrowdToken
  ): Promise<boolean> {
    const balance = await this.web3Service.getBalance(
      token,
      this.web3Service.getNetworkProvider(token.chainId)
    );
    if (!balance) return false;
    return balance.gte(amountInDecimal);
  }

  private async hasAllowance(
    userAddress: string | undefined,
    spenderAddress: string | undefined,
    token: CrowdToken,
    amountInDecimal: string
  ): Promise<boolean> {
    if (!userAddress) {
      return false;
    }
    if (!spenderAddress) {
      return false;
    }
    const provider = await this.web3Service.getNetworkProvider(token.chainId);
    const allowance = await this.web3Service.getAllowanceBySpender(
      spenderAddress,
      token.address,
      userAddress,
      provider
    );
    if (!allowance) return false;
    return allowance.gte(amountInDecimal);
  }

  private async willBeFailed(
    tx: {
      from: string;
      to: string;
      data: string;
      value: string;
      gasLimit: string;
    },
    chainId: number
  ): Promise<boolean> {
    const provider = await this.web3Service.getNetworkProvider(chainId);
    try {
      await provider.estimateGas(tx);
      return false;
    } catch (error) {
      return true;
    }
  }

  /**
   * ***Important**
   * Don't ues await in the body of search method. The function body must be run atomic.
   * @param searchParam
   * @private
   */
  public async search(searchParam: SearchParamModel) {
    try {
      // Cancel previous requests
      this.getEstimationSubscription &&
        this.getEstimationSubscription.unsubscribe();
      this.getEstimationSubscription = undefined;

      // invalidate the previous best estimation
      // this.bestEstimationChanged.emit(undefined);
      // this.compareWithEstimation = undefined;

      // this.searchState = SearchState.StartSearching;

      this.errorMessage = undefined;
      this.isNoPairFound = false;

      this.estimations = [
        BaseSwapComponent.getDummyEstimationTrade(),
        BaseSwapComponent.getDummyEstimationTrade()
      ];

      // this.ref.detectChanges();

      // await this.$gtmService.pushTag({
      //   event: 'start_search',
      //   category: 'search',
      //   sourceTokenSymbol: searchParam.fromToken.symbol,
      //   destinationTokenSymbol: searchParam.toToken.symbol,
      //   amount: searchParam.swapValue,
      //   isConnected: this.web3Service.isConnected(),
      // });

      //Fetches available dexes from server
      this.getEstimationSubscription = this.crowdSwapService
        .getAllSwapEstimation(searchParam)
        .subscribe(
          (estimateTradeList: EstimateTrade[]) => {
            this.estimations = estimateTradeList;

            this.handleCrowdSwapAggregatorEstimation();
            this.handleCrowdSwapDexEstimation();

            // Choose the best and compareWith estimations
            // this.bestEstimationChanged.emit(this.estimations[0]);
            // this.estimations[1] &&
            //   (this.compareWithEstimation = this.estimations[1]);

            this.errorMessage = undefined;

            // this.searchState = SearchState.FinishSearching;
            // this.ref.detectChanges();
          },
          (error) => {
            this.isNoPairFound = true;
            if (error instanceof NotFoundException) {
              this.errorMessage = 'No estimation found for the given pair';
              this.isNoPairFound = false;
            } else if (error instanceof ServerException) {
              this.errorMessage =
                'Sorry for the inconvenience, server is under maintenance. Please try again in a few minutes.';
            } else {
              this.errorMessage = 'Something went wrong';
            }
            this.setConsoleLog(this.errorMessage);
            // this.searchState = SearchState.FinishSearching;
            // this.ref.detectChanges();
          }
        );
    } catch (e) {
      console.log(e);
      // this.searchState = SearchState.FinishSearching;
    }
  }

  private handleCrowdSwapAggregatorEstimation() {
    let crowdswapEstimation: EstimateTrade | undefined = this.estimations.find(
      (estimationTrade: EstimateTrade) => {
        return estimationTrade.dex.name === Dexchanges.CrowdswapAggregatorV3.name;
      }
    );

    // In production mode, only a valid crowdswap estimation is allowed to be added to the result
    if (crowdswapEstimation) {
      const addingCrowdswapEstimationIsAllowed =
        !environment.production || // in production mode
        ((this.estimations[0] === crowdswapEstimation ||
          this.estimations[0].pricePerToken ===
            crowdswapEstimation.pricePerToken) && // crowdswap estimation is the best
          !this.estimations.filter((estimation) => {
            return (
              estimation.routes === crowdswapEstimation?.routes &&
              estimation.dex.name === crowdswapEstimation?.delegatedDex
            ); // no similar route exists
          }));

      if (!addingCrowdswapEstimationIsAllowed) {
        this.estimations.splice(
          this.estimations.indexOf(crowdswapEstimation),
          1
        );
      }
    }
  }

  private handleCrowdSwapDexEstimation() {
    let crowdswapEstimation: EstimateTrade | undefined = this.estimations.find(
      (estimationTrade: EstimateTrade) => {
        return estimationTrade.dex.name === Dexchanges.Crowdswap.name;
      }
    );

    if (crowdswapEstimation) {
      const addingCrowdswapEstimationIsAllowed =
        !environment.production || this.estimations[0] === crowdswapEstimation;

      if (!addingCrowdswapEstimationIsAllowed) {
        this.estimations.splice(
          this.estimations.indexOf(crowdswapEstimation),
          1
        );
      }
    }
  }

  private setConsoleLog(msg: string = '') {
    const searchParam: SearchParamModel | undefined =
      this.searchParam$.getValue();
    console.log(
      'Estimate swap transaction ChainId=' +
        searchParam?.fromToken.chainId +
        ' SrcTokenSymbol=' +
        searchParam?.fromToken.symbol +
        ' DstTokenSymbol=' +
        searchParam?.toToken.symbol +
        ' Slippage=' +
        searchParam?.slippage +
        ' AmountIn=' +
        searchParam?.swapValue +
        ' SwapState= getting estimation ' +
        msg
    );
  }

  public calculatePriceImpact(estimation) {
    if (
      estimation.loading ||
      estimation.crossChainSwapState === CrossChainSwapState.NotFound
    ) {
      return;
    }

    const amountInInUSDT = estimation?.amountInInUSDT?.replace(/,/g, '');
    const priceImpact = parseFloat(
      (
        ((parseFloat(estimation.amountOutInUSDT) - parseFloat(amountInInUSDT)) /
          parseFloat(amountInInUSDT)) *
        100
      ).toFixed(2)
    );
    if (priceImpact === 0) {
      return 0;
    }
    return priceImpact;
  }

  public async getApproximateOperatingExpenses(params) {
    const url = `${baseUrl}/api/v1/crosschainswap/operating-expenses/`;
    return this.http
      .get(url, {
        headers: { 'x-api-key': API_KEY },
        params: new HttpParams({ fromString: qs.stringify(params) }),
        observe: 'response'
      })
      .toPromise();
  }
  private getDefaultPatchState(): IPatchState {
    return {
      newAmountOut: 0,
      newAmountOutUsdt: 0,
      patchTx: undefined,
      lastPatchDate: 0,
      approvedPatchTx: false
    };
  }
}
