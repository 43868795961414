<div class="row justify-center w-full mt-5 ml-2 md:ml-0">
  <div class="z-0">
    <div class="box bg-gray-50 dark:bg-darkBlack">
      <div class="w-full col-12 pb-4">
        <div class="relative text-xl ml-2 mb-3 dark:text-darkGrayText">
          <span class="font-openSans-semiBold">Terms of service</span>
          <div class="absolute">
            <div
              class="top-0 bottom-space text-sm text-justify items-center border-none bg-gray-50 dark:bg-darkBlack w-full px-0 px-md-3 py-3"
            >
              <p>
                PLEASE READ THESE TERMS OF AFFILIATE PROGRAM CAREFULLY. BY
                PARTICIPATING IN THE AFFILIATE PROGRAM OR YOUR APPROVAL OF THE
                RELEVANT BLOCKCHAIN TRANSACTION (ALSO KNOWN AS TERMS-APPROVING
                TRANSACTION), YOU AGREE TO BE LEGALLY BOUND BY THESE TERMS OF
                AFFILIATE PROGRAM AND ALL TERMS, POLICIES AND AGREEMENTS
                INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE WITH THESE TERMS,
                PLEASE DO NOT PARTICIPATE IN THE AFFILIATE PROGRAM, ABSTAIN FROM
                APPROVING THE TERMS-APPROVING TRANSACTION AND LEAVE THE WEBSITE
                IMMEDIATELY. WE RESERVE THE RIGHT AT OUR SOLE DISCRETION, TO
                CHANGE, MODIFY, ADD OR REMOVE PORTIONS OF THESE TERMS OF
                AFFILIATE PROGRAM, AT ANY TIME.
              </p>
              <p>
                All the definitions used in these Terms of Affiliate Program
                shall have the same meaning as in our general Terms of Service
                unless these Terms of Affiliate Program set a different meaning.
              </p>
              <br />
              <ol type="1">
                <li>
                  <span class="font-bold">Definitions</span>
                  <ul type="a">
                    <li>
                      Affiliate Fee shall mean the percentage of our fees and
                      commissions received from the relevant Introduced Users
                      and to be payable to the Affiliate Partner according to
                      these Terms.
                    </li>
                    <li>
                      Creative Materials shall mean promotional materials
                      created by us that may be used for promotion of the
                      Platform and CrowdSwap, including, but not limited to,
                      texts, graphics, images, drawings, videos, banners,
                      buttons, memes, sticker packs, press releases, articles
                      for media.
                    </li>
                    <li>
                      Introduced User shall mean the user who connected its
                      Connected Wallet to the Platform, that was referred by the
                      Affiliate Partner through the eligible Involved Media
                      under the relevant Affiliate Link.
                    </li>
                    <li>
                      Involved Media shall mean Affiliate Partner’s own
                      websites, blogs, communities and networks, chats and
                      forums where the Affiliate Partner is a member.
                    </li>
                    <li>
                      Platform shall mean the website accessible via the link:
                      app.crowdswap.org, that provides the graphic user
                      interface to interact with decentralised swap,
                      aggregating, routing Cross-Chain liquidity and/or
                      crowd-sale protocols, related pools and smart contracts.
                    </li>
                    <li>
                      Trademarks shall mean our names, brand names, trade names,
                      logos, trademarks, service marks.
                    </li>
                    <li>
                      Website shall mean the website of the Affiliate Program
                      accessible via the relevant page of the website
                      www.crowdswap.org.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">Acceptance. Eligibility</span>
                  <ul>
                    <li>
                      These Terms of Affiliate Program (“Terms”) are a legal
                      agreement between you and Smart DeFi Limited, a private
                      company incorporated in the British Virgin Islands.
                      References in these Terms to “CrowdSwap”, “we”, “our” or
                      “us”, are to Smart DeFi Limited and references to “you”,
                      “your”, “affiliate partner” or “participant” are to the
                      person with whom CrowdSwap enters into these Terms.
                    </li>
                    <li>
                      These Terms apply to and govern your participation in the
                      Affiliate Program. Upon your approval of the relevant
                      terms-approval blockchain transaction, by requesting
                      authentication for or admission to the Affiliate Program,
                      requesting an affiliate link, participating in the
                      Affiliate Program, claiming of being Affiliate Partner or
                      ambassador of CrowdSwap or anyhow performing any actions
                      related to the Affiliate Program, you agree to be legally
                      bound by and to comply with these Terms.
                    </li>
                    <li>
                      You agree to provide accurate and complete information
                      during the registration process and you also agree not to
                      impersonate another person, and not to hide your identity
                      from us for any reason whatsoever. As the part of your
                      admission to the Affiliate Program you shall disclose your
                      then-current participation in any affiliate or ambassador
                      programs or campaigns as well as your intention to
                      participate in any similar programs or campaigns later. We
                      may terminate your participation in the Affiliate Program
                      without the obligation of any compensation to you and
                      without prior notice to you if any of your participation
                      in affiliate or ambassador programs or campaigns is not
                      suitable to us for any reason.
                    </li>
                    <li>
                      Participation in the Affiliate Program is available only
                      for participants who are 18 years old or older. You are
                      not allowed to participate in the Affiliate Program if
                      your previous participation in the Affiliate Program was
                      terminated by us for any reason, or within the further six
                      (6) months form the termination date if your participation
                      in the Affiliate Program was terminated due to inactivity.
                    </li>
                    <li>
                      You are not allowed to participate in the Affiliate
                      Program if you have been blocked, blacklisted or banned
                      from any of our social media or communication channels,
                      chat or groups, or if you ever made or make any
                      disparaging, false, accusative, deliberately negative
                      statements, publications, posts, public comments, notices,
                      public or private speeches, in any form or media, in
                      relation to the Platform, CrowdSwap or CrowdSwap’s
                      affiliates, directors, employees, representatives or
                      advisors.
                    </li>
                    <li>
                      You are not allowed to participate in the Affiliate
                      Program and will not be entitled to any Affiliate Fee,
                      notwithstanding whether you have met any requirements,
                      criteria or anyhow other participated in the Affiliate
                      Program, if you are a citizen, a permanent resident or
                      located in Afghanistan, Democratic People’s Republic of
                      Korea, Islamic Republic of Iran, Syrian Arab Republic or
                      of any state, country, territory or other jurisdiction
                      that is embargoed by the United Nations or the British
                      Virgin Islands.
                    </li>
                    <li>
                      You hereby acknowledge and agree that in case of (i) your
                      breach of your representations and warranties set out in
                      these Terms, (ii) your violation of the rules for
                      participation in the Affiliate Program, (iii) your
                      violation of geographical restriction for participation in
                      the Affiliate Program (as set in the Section 2(e) above),
                      (iv) your violation of these Terms or your abuse or
                      fraudulent activity with relation to the Affiliate
                      Program, we shall have the right to refuse to pay any
                      Affiliate Fee to you and terminate your participation in
                      the Affiliate Program without the obligation of any
                      compensation to you and without prior notice to you. We
                      shall have no liability to you for any damage caused by
                      such cessation of payment of Affiliate Fee to you.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">Participation</span>
                  <ul>
                    <li>
                      Upon successful authentication, we will assign you the
                      unique Affiliate ID that would serve as your identifier
                      for participating in the Affiliate Program. By using your
                      Affiliate ID, you would be able to generate the unique URL
                      affiliate link (“Affiliate Link”) that shall be used by
                      you in referring of potential Introduced Users to the
                      Platform.
                    </li>
                    <li>
                      You hereby agree to perform allowed promotional/marketing
                      activities in order to market and promote the Platform
                      through your Involved Media as well as at any networking
                      events where you take part. You hereby acknowledge and
                      agree that it is your responsibility to comply with rules
                      of social media, communication platforms and applications
                      as well as rules of particular communities, chats, forums
                      and networks where you promote the Platform.
                    </li>
                    <li>
                      You shall display on your Involved Media your Affiliate
                      Link in order to refer potential Introduced Users to the
                      Platform. For avoidance of doubt, displaying Affiliate
                      Link means all relevant and allowed activities, including
                      displaying our Creative Materials with Affiliate Link
                      being integrated in it
                    </li>
                    <li>
                      While performing promotional/marketing activities you must
                      only use the Creative Materials that were made available
                      to you. When you intend to create your own promotional
                      materials, you must never use such newly created materials
                      without our written advance approval. You must not alter,
                      modify, change and/or amend the Creative Materials in any
                      way, which includes, but is not limited to, changing of
                      the graphics, texts and/or images contained in such
                      Creative Materials.
                    </li>
                    <li>
                      You may also distribute to potential Introduced Users
                      information with respect to the Platform and services of
                      the Platform through your social media or communication
                      channels and/or through the organisation of presentations,
                      educational seminars, campaigns and other events, provided
                      that such information shall be either prepared by us or
                      shall be approved by us in advance and the venues or types
                      of any such offline events shall be approved by us in
                      advance.
                    </li>
                    <li>
                      You shall bear all costs and expenses of any nature
                      whatsoever incurred in connection with your participation
                      in the Affiliate Program. Under no circumstances we will
                      be liable hereunder for any amounts other than the
                      Affiliate Fee, unless otherwise expressly provided for in
                      these Terms.
                    </li>
                    <li>
                      We shall be entitled to determine upon our sole and
                      absolute discretion when your activity or results are
                      deemed to be fraudulent.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">Promotional Activities </span>
                  <ul>
                    <li>
                      You represent and warrant that you will all the time
                      comply with our marketing and promotional guidelines and
                      restrictions.
                    </li>
                    <li>
                      We may direct you and provide you with guidance how to
                      promote the Platform. We may request you to incorporate
                      hashtags, titles, or other relevant information to the
                      content being uploaded or published by you in relation to
                      the Platform. You shall be the continuous user of the
                      Platform.
                    </li>
                    <li>
                      We may at our sole discretion provide you with access to
                      software products, features and functionality of such
                      software products that are not released to the general
                      public for the purposes of beta testing, provision of
                      feedback (including public feedback), getting known of
                      features and functionality before their release.
                    </li>
                    <li>
                      You are expected to highlight the benefits of the Platform
                      and your personal experience interacting with them. You
                      are strictly prohibited from making any claim not approved
                      by us. You shall never recommend, communicate or advise
                      using the Platform for use other than those advised by us.
                    </li>
                    <li>
                      You shall all the time maintain the highest ethical
                      standards and shall always act in a manner that does not
                      dilute or harm our name and reputation. You shall maintain
                      the positive image of the Platform and must not make any
                      statements, publications, posts, public comments, notices,
                      public or private speeches in any form or media where you
                      disparage the Platform, our projects, us or our
                      affiliates, directors, employees, representatives or
                      advisors.
                    </li>
                    <li>
                      You must not make any promises, representations,
                      warranties or guarantees on behalf of or in the name of us
                      or in the relation to the Platform. You must not sell any
                      assets, including crypto assets, on behalf or in the name
                      of us, shall not provide any paid access to the Platform.
                    </li>
                    <li>
                      Within the term of these Terms and three (3) months
                      thereafter you shall not promote, market, advertise or
                      represent any projects, products or services that are
                      similar to and/or directly compete with the Platform or
                      our other known projects, products or services. Within the
                      term of these Terms, you shall not conclude any agreements
                      for marketing or promotion, accept any payments or rewards
                      from any our direct competitor or competitive projects,
                      products, companies or entities.
                    </li>
                    <li>
                      You acknowledge and agree that it is strictly prohibited
                      to use any websites, platforms or applications related to
                      the adult, gambling, dating, erotic or pornographic
                      content, or that are promoting violence, discrimination,
                      bigotry, hatred or physical harm of any kind against any
                      group or individual, or any websites, platforms or
                      applications which content or services are illegal, for
                      any marketing or promotion of the Platform.
                    </li>
                    <li>
                      You must not use any information received from us,
                      including, but not limited to, any confidential
                      information, for any kind of insider trading, including
                      trading in CROWD tokens. You must never use CROWD tokens
                      as speculative investments or for the purpose of
                      speculating on the crypto assets markets. You represent
                      and warrant that you will not manipulate the price of
                      CROWD tokens in any way and will not use CROWD tokens for
                      any other kind of market manipulation.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">Affiliate Fee</span>
                  <ul>
                    <li>
                      Your Affiliate Fee shall be calculated basing on the
                      volume of transactions performed via the Platform by
                      Introduced Users and the amount of fees and commissions
                      actually paid to us by Introduced Users. We will calculate
                      the percentage of fees and commissions for then-current
                      month basing on the volume of transactions performed of
                      your Introduced Users in the preceding month.
                    </li>
                    <li>
                      We will apply the following percentage to for calculation
                      the Affiliate Fee:
                    </li>
                    <br />
                    <table>
                      <tr>
                        <th>Transaction Volume</th>
                        <th>Applicable percent</th>
                      </tr>
                      <tr>
                        <td>0</td>
                        <td>20</td>
                      </tr>
                      <tr>
                        <td>100.000</td>
                        <td>25</td>
                      </tr>
                      <tr>
                        <td>250.000</td>
                        <td>30</td>
                      </tr>
                      <tr>
                        <td>500.000</td>
                        <td>35</td>
                      </tr>
                      <tr>
                        <td>1.500.000</td>
                        <td>40</td>
                      </tr>
                      <tr>
                        <td>5.000.000</td>
                        <td>45</td>
                      </tr>
                      <tr>
                        <td>15.000.000</td>
                        <td>50</td>
                      </tr>
                    </table>
                    <br />
                    <p>* Transaction Volume for the preceding month, in USD</p>
                    <p>
                      * Applicable percent of fees and commissions for
                      then-current month, %
                    </p>
                    <br />
                    <p>
                      In case of a conflict between the percentages set out in
                      these Section and the percentages placed on the Website,
                      the latter shall prevail.
                    </p>

                    <li>
                      The amount of the Affiliate Fee shall be based and
                      calculated solely and exclusively according to our data.
                      The Affiliate Fee will be calculated and nominated in US
                      Dollars and will be payable in crypto assets as determined
                      and supported by us at our sole and absolute discretion.
                      You would be able to choose the crypto assets for payment
                      of the Affiliate Fee from the options offered by us. The
                      exact amount of the Affiliate Fee payable to you will be
                      determined by us according to the exchange rate of USD to
                      the chosen crypto assets according to mid-market rates,
                      available at http://coinmarketcap.com/ as of the date
                      preceding the date of payment.
                    </li>
                    <li>
                      We will calculate the Affiliate Fee and add it to the
                      internal balance of your account once a month at the dates
                      determined by us at our sole discretion. You would be able
                      to see the amount of the Affiliate Fee accrued on the
                      dashboard of the Website. The Affiliate Fee accrued will
                      be accumulated at the internal balance of your account
                      until you withdraw (request payment) the accrued Affiliate
                      Fee.
                    </li>
                    <li>
                      You must reach a minimum of 50 US Dollars (in equivalent)
                      balance in order to request a payment (payout). Payment of
                      the Affiliate Fee will be made to your blockchain wallet,
                      provided that we would choose supported blockchain
                      networks at our sole and absolute discretion. Your
                      blockchain wallet must be all the time under your direct
                      or indirect control and shall not be under the direct or
                      indirect control of any third party. We shall have the
                      right to change the blockchain network for the transfer of
                      the Affiliate Fee at our sole and absolute discretion. In
                      case we decide to change the blockchain network, we will
                      inform you and request you to provide your blockchain
                      wallet address at that specified blockchain network.
                    </li>
                    <li>
                      You acknowledge and agree that we may have the right to
                      disable payments of the Affiliate Fee until the
                      fully-functional Website is developed, including the
                      development and deployment of a fully-functional
                      dashboard, as well as for periods of maintenance of the
                      Website.
                    </li>
                    <li>
                      You are solely responsible for, and will file, on a timely
                      basis, all tax returns and payments required to be filed
                      with, or made to, any national or local tax authority with
                      respect to participation in the Affiliate Program and
                      receipt of the Affiliate Fee.
                    </li>
                    <li>
                      We may charge our commission and fees for paying the
                      Affiliate Fee to you if you requested the payment in
                      crypto assets other than CROWD token. Also, we will
                      withhold from the amount of the Affiliate Fee payable to
                      you all transaction fees or commissions of blockchain
                      networks, ledgers or networks incurred by us in relation
                      to paying the Affiliate Fee to you.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">Prohibited Conduct</span>
                  <ul>
                    <li>
                      In relation to these Terms, your participation in the
                      Affiliate Program and your promotion of us or anyhow your
                      advertising of us, you must not:
                      <p>
                        i. manipulate any performance statistics relating to
                        your participation in the Affiliate Program,
                        artificially and/or fraudulently inflate the number of
                        actions or any other participation results or metrics;
                      </p>
                      <p>
                        ii. display, stream or promote any pornographic,
                        defamatory, libellous, threatening, harassing, hateful,
                        abusive, or inflammatory content;
                      </p>
                      <p>
                        iii. display or promote physical harm of any kind
                        against any group or individual, any instructional
                        information about illegal activities such as making or
                        buying illegal weapons, violating someone's privacy, or
                        creating computer viruses;
                      </p>
                      <p>
                        iv. display or promote any information that you know is
                        defamatory, false, misleading or promotes illegal
                        activities or conduct that is abusive, threatening,
                        obscene, defamatory or libellous;
                      </p>
                      <p>
                        v. market to any persons who are under the age of 18
                        years old, or under the age of majority in the territory
                        which is targeted by you;
                      </p>
                      <p>
                        vi. send junk mail or spam, including without limitation
                        unsolicited advertising, promotional materials, or other
                        solicitation material;
                      </p>
                      <p>
                        vii. harvest or collect email addresses or other contact
                        information of our other users;
                      </p>
                      <p>
                        viii. incentivise, pay, reward or promise any reward,
                        commission or incentive to any third party or anyhow
                        influence on any third party for its performance of any
                        actions related to the Affiliate Program or the
                        Platform;
                      </p>
                      <p>
                        ix. defame, harass, abuse, threaten, or defraud our
                        users, or collect or attempt to collect, personal
                        information about users or third parties without their
                        consent;
                      </p>
                      <p>
                        x. use the Website for any illegal purpose, or in
                        violation of any local, state, national, or
                        international law or regulation, including without
                        limitation laws governing intellectual property and
                        other proprietary rights, data protection, and privacy;
                      </p>
                      <p>xi. violate any law, regulation, or court order;</p>
                      <p>
                        xii. participate in any fraudulent or illegal activity,
                        including phishing, money laundering, or fraud;
                      </p>
                      <p>
                        xiii. access or use the Website or the Platform for the
                        purpose of creating a product or service that is
                        competitive with any of our products or services;
                      </p>
                      <p>
                        xiv. advocate, encourage or assist any third party in
                        doing any of the foregoing.
                      </p>
                    </li>
                    <li>
                      You must not use SMS traffic and email traffic for the
                      purposes of these Terms.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">Intellectual Property Rights</span>
                  <ul>
                    <li>
                      We hereby grant you a limited, revocable, non-assignable,
                      non-sublicensable, license to use, display, perform the
                      Creative Materials and the Trademarks for the limited
                      purpose of advertising and promotion of the Platform and
                      us. You must not (nor attempt to) copy, decompile, reverse
                      engineer, disassemble, derive the source code of, modify,
                      or create derivative works of the Creative Materials.
                    </li>
                    <li>
                      All Trademarks, whether registered or not, and all other
                      proprietary rights in the Platform are and will remain our
                      property. You must never take any action inconsistent with
                      our ownership of the Trademarks, and, specifically, will
                      not (i) contest or aid others in contesting the validity
                      of the Trademarks, or (ii) apply for, or aid or cause
                      others to apply for, any registration of the Trademarks or
                      any names, logos, marks, or related characteristics that
                      resemble the Trademarks.
                    </li>
                    <li>
                      You must not purchase or register keywords, search terms
                      or other identifiers for use in any search engine, portal,
                      sponsored advertising service or other search or referral
                      service and which are identical or similar to any of our
                      Trademarks, our domain names or otherwise include the word
                      “CrowdSwap”, “Crowd”, “Smart Chain”, “Smart DeFi”, other
                      words relevant to our business or variations thereof, or
                      include metatag keywords on any of your social media,
                      blog, channel or website which are identical or similar to
                      any of Trademarks or any of our products, promotions or
                      hashtag names (including Affiliate Program).
                    </li>
                    <li>
                      You must not register (or apply to register) any domain
                      name like any domain name used by or registered in the
                      name of us or any of our affiliates, any domain name
                      referring or similar to our Trademarks, or any other name
                      that could be understood to designate the Platform, us,
                      our products or our affiliate.
                    </li>
                    <li>
                      By submitting any ideas, thoughts, criticisms, suggested
                      improvements, or other feedback related to the Platform
                      and related products (“Feedback”), you agree that we may
                      use the Feedback in any way it deems appropriate to: (i)
                      improve the Platform and related products and (ii) promote
                      the Platform and related products, and that you will not
                      be due any compensation for your Feedback that is used in
                      these ways.
                    </li>
                    <li>
                      If you decide to submit any video, audio, image or
                      information to support or to prove your participation in
                      the Affiliate Program, you irrevocably grant a
                      transferable, sublicensable, worldwide, perpetual license
                      to us to display, exhibit, edit, modify, broadcast,
                      distribute, exploit and otherwise use (and authorise third
                      parties to use) such video, audio, image or information in
                      all media now known or hereafter devised.
                    </li>
                    <li>
                      If you create any promotional materials that were approved
                      by us, you irrevocably assign to us all of your rights,
                      title and interest in and to any such promotional
                      materials. This includes potential copyrights, trademarks,
                      and patents. You assign to us in perpetuity all
                      proprietary rights that you may have in such promotional
                      materials.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">Warranties</span>
                  <ul>
                    <li>
                      EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN THESE
                      TERMS, WE HEREBY DISCLAIM ALL EXPRESS OR IMPLIED
                      WARRANTIES WITH REGARD TO THE AFFILIATE PROGRAM AND THE
                      WEBSITE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED
                      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                      PURPOSE, TITLE, NON-INFRINGEMENT AND QUALITY. WE MAKE NO
                      REPRESENTATIONS OR WARRANTIES REGARDING THE RELIABILITY,
                      AVAILABILITY, TIMELINESS, SUITABILITY, ACCURACY OR
                      COMPLETENESS OF THE WEBSITE AND ANY SOFTWARE TRACKING YOUR
                      PARTICIPATION AND PERFORMANCE OF RELATED ACTIVITIES.
                    </li>
                    <li>
                      YOU ACKNOWLEDGE AND AGREE THAT WE DO NOT CONTROL THE
                      TRANSFER OF DATA OVER COMMUNICATIONS FACILITIES, INCLUDING
                      THE INTERNET AND BLOCKCHAINS, AND THAT THE WEBSITE MAY BE
                      SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS
                      INHERENT IN THE USE OF SUCH COMMUNICATIONS FACILITIES. WE
                      ARE NOT AND SHALL NOT BE RESPONSIBLE FOR ANY DELAYS,
                      DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH
                      PROBLEMS. WITHOUT LIMITING THE FOREGOING, WE DO NOT
                      WARRANT OR GUARANTEE THAT ANY OR ALL SECURITY ATTACKS WILL
                      BE DISCOVERED, REPORTED OR REMEDIED, OR THAT THERE WILL
                      NOT BE ANY SECURITY BREACHES BY THIRD PARTIES. EXCEPT
                      WHERE EXPRESSLY PROVIDED OTHERWISE BY US, THE WEBSITE AND
                      ANY SOFTWARE ARE PROVIDED TO YOU ON AN “AS IS”, “AS
                      DEVELOPED” AND “AS AVAILABLE” BASIS.
                    </li>
                    <li>
                      YOU ACCEPT RESPONSIBILITY FOR ALL ACTIVITIES AND CONTENT
                      GENERATED BY YOU THROUGH YOUR PARTICIPATION IN THE
                      AFFILIATE PROGRAM.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">Indemnification</span>
                  <ul>
                    <li>
                      You agree to defend, indemnify and hold harmless
                      CrowdSwap, its affiliates and their respective
                      shareholders, members, directors, officers, employees,
                      agents and representatives (collectively,
                      “Representatives”) from and against any and all claims,
                      damages, obligations, losses, liabilities, costs or debt,
                      and expenses (including but not limited to attorney’s
                      fees) arising out of or related to: (i) your violation of
                      any term of these Terms, including without limitation your
                      breach of any of the representations and warranties above;
                      (ii) your violation of any third-party right, including
                      without limitation any right of privacy, publicity rights
                      or intellectual property rights; (iii) your violation of
                      any applicable law, rule or regulation; (iv) any actual or
                      alleged breach of your representations, warranties, or
                      obligations set forth in these Terms; (v) negligent or
                      wilful misconduct, artificial and/or fraudulent inflation
                      of participation results or metrics; (vi) any of your
                      communications (including, but not limited to email
                      communications) is found to be misleading or unlawful in
                      any manner.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">Termination</span>
                  <ul>
                    <li>
                      You may cancel your participation in the Affiliate Program
                      and application of these Terms to you at any time by
                      contacting us.
                    </li>
                    <li>
                      You hereby acknowledge and agree that we may terminate
                      suspend, refuse or limit the Affiliate Program and/or your
                      participation in the Affiliate Program at our sole and
                      absolute discretion, without explaining reasons for such
                      termination, refusal or limitation and without prior
                      notice to you. We do not commit for your continuous
                      participation in the Affiliate Program even if you met all
                      the necessary requirements and criteria for such
                      participation and/or performed any required actions
                      related to such participation.
                    </li>
                    <li>
                      We shall have the right to terminate the application of
                      these Terms in relation to you and cease payment of
                      Affiliate Fee to you with immediate effect without the
                      obligation of any compensation to you and without prior
                      notice to you if you perform any activity prohibited by
                      these Terms, violate any term of these Terms, including
                      without limitation your breach of any of the
                      representations and warranties above, or violate any
                      applicable laws.
                    </li>
                    <li>
                      You hereby acknowledge and agree that upon the termination
                      of these Terms, you would not be able to request any
                      payment (payout) of the Affiliate Fee and no further
                      payments (payouts) will be made to you.
                    </li>
                  </ul>
                </li>
                <li>
                  <span class="font-bold">General Terms</span>
                  <ul>
                    <li>
                      We may modify these Terms, rules or conditions of the
                      participation in the Affiliate Program by providing notice
                      of such changes, such as by sending you an email,
                      providing notice through the Website, or updating the
                      “Last Updated” date at the bottom of these Terms. By
                      continuing your participation in the Affiliate Program,
                      you confirm your agreement to the modified Terms. If you
                      do not agree to any modification to these Terms, you must
                      stop your participation in the Affiliate Program. Any such
                      modification should not affect your acquired percentage of
                      the Affiliate Fee for a then-current month, however, this
                      should not apply if any such modifications set more strict
                      terms regulating fraudulent actions.
                    </li>
                    <li>
                      These Terms of Affiliate Program, Privacy Policy, and all
                      other documents incorporated by reference herein and
                      therein, comprise the entire agreement between you and us.
                    </li>
                    <li>
                      Section headings in these Terms are for convenience only,
                      and shall not govern the meaning or interpretation of any
                      provision of these Terms.
                    </li>
                    <li>
                      These Terms are personal to you, you cannot transfer or
                      assign your rights, licenses, interests and/or obligations
                      to anyone else.
                    </li>
                    <li>
                      If any provision of these Terms is determined to be
                      invalid or unenforceable under any applicable law, this
                      will not affect the validity of any other provision.
                    </li>
                    <li>
                      We may not always strictly enforce our rights under these
                      Terms. If we do this, it will be just a temporary measure
                      and we may enforce our rights strictly again at any time.
                    </li>
                    <li>
                      These Terms and any information or notifications that you
                      or we are to provide should be in English. Any translation
                      of these Terms or other documents is provided for your
                      convenience only. In the event of any inconsistency, the
                      English language version of these Terms or other documents
                      shall prevail.
                    </li>
                    <li>
                      Upon termination of these Terms, all sections of these
                      Terms which by their nature should survive termination or
                      expiration will survive.
                    </li>
                    <li>
                      These Terms shall be governed by and construed in
                      accordance with the laws of the British Virgin Islands.
                      Any dispute controversy, or claim arising out of, or
                      relating to, or in connection with these Terms, including
                      with respect to the formation, applicability, breach,
                      termination, invalidity, enforceability or any dispute
                      regarding non-contractual obligations arising out of or
                      relating to such obligations, shall be referred to the BVI
                      International Arbitration Centre for resolution pursuant
                      to the BVI IAC Arbitration Rules (in force at the time of
                      submission of a Notice of Arbitration and as may be
                      amended from time to time). The number of arbitrators
                      shall be one. The language to be used in the arbitral
                      proceedings shall be English.
                    </li>
                  </ul>
                </li>
              </ol>
              <br />
              <p class="font-bold">Last Updated on 21 November 2022</p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex col-12 py-2"></div>
    </div>
  </div>
</div>
<div
  class="fixed bottom-12 pb-3 bg-white dark:bg-darkBlack pb-md-0 left-0 md:bottom-0 w-full px-5"
>
  <div class="md:flex items-center justify-content-between">
    <div class="md:flex items-center my-3 md:order-last">
      <input
        *ngIf="isUserWalletConnected"
        [(ngModel)]="isSignAccepted"
        class="rounded-sm cursor-pointer"
        type="checkbox"
      />
      <div
        (click)="isSignAccepted = !isSignAccepted"
        *ngIf="isUserWalletConnected"
        class="flex cursor-pointer dark:text-white pr-2 items-center"
      >
        &nbsp;Mark&nbsp;as&nbsp;read&nbsp;and&nbsp;accept
      </div>
      <button
        (click)="onSignClicked()"
        [ngClass]="isSignAccepted || !isUserWalletConnected ? '' : 'disable'"
        class="blue-button font-openSans-semiBold py-2 px-4 w-full rounded-md text-white text-sm items-center text-center hover:bg-blue-700"
      >
        <span *ngIf="!isUserWalletConnected">Connect wallet</span>
        <span *ngIf="isUserWalletConnected">Sign and continue</span>
      </button>
    </div>
    <div class="flex blue-color space-x-2 items-center">
      <a target="_blank" href="https://crowdswap.org/ambassador-program/"
        >What is affiliate marketing
      </a>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.66927 5.83203H5.5026C4.58213 5.83203 3.83594 6.57822 3.83594 7.4987V14.9987C3.83594 15.9192 4.58213 16.6654 5.5026 16.6654H13.0026C13.9231 16.6654 14.6693 15.9192 14.6693 14.9987V10.832"
          stroke="#0066FF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.83594 11.6654L17.1693 3.33203"
          stroke="#0066FF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13 3.33203H17.1667V7.4987"
          stroke="#0066FF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</div>
