<div class="box h-100 dark:bg-darkBlack">
  <div class="flex col-12 gap-y-4">
    <div>
      <img
        class="d-sm-block mr-2 rounded-lg"
        alt="Bar Chart"
        src="{{ iconPath }}"
      />
    </div>
    <div class="col-span-3">
      <span class="blue-color text-sm">{{ title }}</span>
    </div>
  </div>
  <div class="flex justify-center">
    <div
      class="text-xl font-bold mt-2 blue-color"
      [ngClass]="{
        'bg-gray-300 w-1/2 text-gray-300 rounded-sm dark:bg-gray-300 dark:text-gray-300 animate-pulse':
          isLoading
      }"
    >
      {{ value }}
    </div>
  </div>
</div>
