<app-dialog [isSelectOption]="true">
  <div class="flex justify-center pb-3">
    <label class="title font-openSans-semiBold dark:text-textWhite">
      Attention!
    </label>
    <div class="absolute right-5 mr-1 dark:text-textWhite">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="close()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
  </div>
  <hr />
  <div>
    <div>
      <div class="flex flex-col items-center pb-3 mt-1">
        <div class="pb-1">
          <div class="align-items-center gap-4">
            <div class="flex justify-center align-items-center">
              <svg
                width="50"
                height="50"
                viewBox="0 0 75 75"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.5 42.9688C36.2188 42.9688 35.1562 41.9062 35.1562 40.625V24.2188C35.1562 22.9375 36.2188 21.875 37.5 21.875C38.7812 21.875 39.8438 22.9375 39.8438 24.2188V40.625C39.8438 41.9062 38.7812 42.9688 37.5 42.9688Z"
                  fill="#DAA43D"
                />
                <path
                  d="M37.5 53.9062C36.6562 53.9062 35.8749 53.5937 35.2812 53C34.9999 52.6875 34.7813 52.3437 34.5938 51.9687C34.4375 51.5937 34.375 51.1874 34.375 50.7812C34.375 49.9687 34.7187 49.1561 35.2812 48.5623C36.4374 47.4061 38.5626 47.4061 39.7188 48.5623C40.2813 49.1561 40.625 49.9687 40.625 50.7812C40.625 51.1874 40.5312 51.5937 40.3749 51.9687C40.2187 52.3437 40.0001 52.6875 39.7188 53C39.1251 53.5937 38.3438 53.9062 37.5 53.9062Z"
                  fill="#DAA43D"
                />
                <path
                  d="M37.5006 71.0937C35.4068 71.0937 33.2818 70.5624 31.4068 69.4686L12.8443 58.7499C9.09428 56.5624 6.75049 52.5311 6.75049 48.1874V26.8125C6.75049 22.4687 9.09428 18.4375 12.8443 16.25L31.4068 5.53123C35.1568 3.34373 39.8131 3.34373 43.5944 5.53123L62.1569 16.25C65.9069 18.4375 68.2507 22.4687 68.2507 26.8125V48.1874C68.2507 52.5311 65.9069 56.5624 62.1569 58.7499L43.5944 69.4686C41.7194 70.5624 39.5943 71.0937 37.5006 71.0937ZM37.5006 8.59367C36.2193 8.59367 34.9068 8.93745 33.7505 9.5937L15.188 20.3124C12.8755 21.6562 11.438 24.125 11.438 26.8125V48.1874C11.438 50.8436 12.8755 53.3437 15.188 54.6874L33.7505 65.4062C36.063 66.7499 38.9381 66.7499 41.2193 65.4062L59.7818 54.6874C62.0943 53.3437 63.5319 50.8749 63.5319 48.1874V26.8125C63.5319 24.1562 62.0943 21.6562 59.7818 20.3124L41.2193 9.5937C40.0943 8.93745 38.7818 8.59367 37.5006 8.59367Z"
                  fill="#DAA43D"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          *ngIf="data?.allTokens && data?.allTokens?.length === 1"
          class="items-center text-sm font-openSans-regular dialog-text px-3 pb-3 pt-1"
        >
          This token doesn't appear on active token list(s). Make sure this is
          the token that you want to trade. By trading the custom tokens, you
          may be charged “tax” or ”extra fees” which CrowdSwap is unable to
          account for. CrowdSwap does not take responsibility for any extra
          costs other than the displayed fees.
        </div>
        <div
          *ngIf="data?.allTokens && data?.allTokens?.length === 2"
          class="items-center text-sm font-openSans-regular dialog-text px-3 pb-3 pt-1"
        >
          These tokens don't appear on active token list(s). Make sure these are
          tokens that you want to trade. By trading the custom tokens, you may
          be charged “tax” or ”extra fees” which CrowdSwap is unable to account
          for. CrowdSwap does not take responsibility for any extra costs other
          than the displayed fees.
        </div>
        <div
          *ngFor="let item of data?.allTokens"
          class="token-box shadow-gray-50 bg-gray-100 dark:bg-dialogBoxBackground rounded-xl my-1 pl-3 pr-2 py-1"
        >
          <div class="align-items-center">
            <div class="flex justify-start align-items-center">
              <div>
                <img
                  class="rounded-full"
                  [src]="
                    baseLogoIconsUrl + item?.symbol?.toLowerCase() + '.png'
                  "
                  (error)="updateUrl($event)"
                />
              </div>
              <div>
                <div class="ml-3 mb-1 tokenSymbol">
                  {{ item?.name }}
                </div>
                <div class="flex ml-3 mt-1">
                  <span class="d-flex align-items-center mt-2px">
                    <div
                      class="token-network-icon bg-fff dark:bg-29355f d-flex align-items-center justify-content-center rounded-full"
                    >
                      <img
                        class="w-5 h-5"
                        [src]="
                          baseLogoIconsUrl +
                          Networks[item.chainId]?.toLowerCase() +
                          '.png'
                        "
                        (error)="updateUrl($event)"
                      />
                    </div>
                    <span class="font-12 text-000 dark:text-fff ms-1">
                      {{ web3Service.networkSpec[item.chainId].title }}
                    </span>
                  </span>
                  <span class="unknownSources rounded-full px-1 mx-1"
                    >unknown sources</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="flex justify-start">
        <div
          (click)="userApproved = !userApproved"
          class="text-sm cursor-pointer font-openSans-regular dialog-text mr-1 ml-5"
        >
          <input
            type="checkbox"
            id="chkUserApprove"
            name="chkUserApprove"
            [(ngModel)]="userApproved"
          />
          I agree
        </div>
      </div>
      <div class="flex flex-col my-2">
        <button
          (click)="confirm()"
          [disabled]="!userApproved"
          class="enabled-button blue-button py-2 px-4 mx-4 rounded-md flex justify-center text-white items-center text-center font-openSans-regular hover:bg-blue-700"
          [ngClass]="{ disable: !userApproved }"
        >
          <span>Import</span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
