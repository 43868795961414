import { ethers } from 'ethers';
import {
  Conversion,
  STAKINGREWARDS,
  LOCKED_STAKING_REWARDS,
  TokensHolder
} from '@crowdswap/constant';
import { Injectable } from '@angular/core';
import { Web3Service } from './web3.service';
import { NetworksService } from './networks.service';
import { Web3Provider } from '@ethersproject/providers';

@Injectable()
export class StakeService {
  constructor(
    private web3Service: Web3Service,
    private networksService: NetworksService
  ) {}

  async getStakedBalance(
    opportunity: any,
    userAddress: string | undefined,
    provider
  ): Promise<any> {
    if (!provider) {
      return undefined;
    }
    const stakeContract = this.getContract(opportunity, provider);
    return stakeContract.getBalance(userAddress);
  }

  async getLockedStakingBalance(
    opportunity: any,
    userAddress: string | undefined,
    provider
  ): Promise<any> {
    if (!provider) {
      return undefined;
    }
    const stakeContract = this.getContract(opportunity, provider);
    return stakeContract.getUserTotalStakedAmount(userAddress);
  }

  async getStakeReward(
    opportunity: any,
    userAddress: string | undefined,
    provider
  ): Promise<any> {
    if (!provider) {
      return undefined;
    }
    const stakeContract = this.getContract(opportunity, provider);
    return stakeContract.getReward(userAddress);
  }

  async getStakeTime(
    opportunity: any,
    userAddress: string | undefined
  ): Promise<number> {
    if (!this.web3Service.web3Provider) {
      return 0;
    }
    const stakeContract = this.getContract(
      opportunity,
      this.web3Service.web3Provider
    );
    return stakeContract.getStakeTime(userAddress);
  }

  async getWithdrawTransaction(opportunity: any, amount: string): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    const stakeContract = this.getContract(
      opportunity,
      this.web3Service.web3Provider
    );
    return stakeContract.populateTransaction.withdraw(amount);
  }

  async getExtendLockedStakingTransaction(
    opportunity: any,
    stakeId: string
  ): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    const stakeContract = this.getContract(
      opportunity,
      this.web3Service.web3Provider
    );
    return stakeContract.populateTransaction.extend(stakeId);
  }

  async getWithdrawLockedStakingTransaction(
    opportunity: any,
    stakeId: string,
    amount: string
  ): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    const stakeContract = this.getContract(
      opportunity,
      this.web3Service.web3Provider
    );
    return stakeContract.populateTransaction.withdraw(stakeId, amount, true);
  }

  async getStakeTransaction(opportunity: any, amount: string): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    const stakeContract = this.getContract(
      opportunity,
      this.web3Service.web3Provider
    );
    return stakeContract.populateTransaction.stake(amount);
  }

  async getLockedStakeTransaction(
    opportunity: any,
    planId: string,
    amount: string
  ): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    const stakeContract = this.getContract(
      opportunity,
      this.web3Service.web3Provider
    );
    return stakeContract.populateTransaction.stake(planId, amount);
  }

  async getNextHourReward(opportunity: any, provider) {
    if (!provider) {
      return 0;
    }
    const stakeContract = this.getContract(opportunity, provider);
    return stakeContract.rewardPerHour();
  }

  async getStakeHolder(
    opportunity: any,
    userAddress: string | undefined,
    provider
  ) {
    if (!provider) {
      return 0;
    }
    const stakeContract = this.getContract(opportunity, provider);
    return stakeContract.stakeholders(userAddress);
  }

  async getApy(opportunity: any) {
    const token = opportunity.tokenA;

    let inUseProvider: Web3Provider | undefined;

    if (
      this.web3Service.web3Provider &&
      this.web3Service.web3Provider.network.chainId == opportunity.chainId
    ) {
      inUseProvider = this.web3Service.web3Provider;
    } else {
      // In case of differences between wallet chain id and token chain id
      inUseProvider = await this.networksService
        .getNetworkProvider(opportunity.chainId)
        .getProvider();
    }
    if (!inUseProvider) {
      throw Error('No provider exception');
    }

    const stakeContract = this.getContract(opportunity, inUseProvider);

    const rewardPerYear = await stakeContract.rewardPerYear();
    return (
      +Conversion.convertStringFromDecimal(
        rewardPerYear.toString(),
        token.decimals
      ) * 100
    ).toString();
  }

  async getUnstakeFee(opportunity: any) {
    let inUseProvider: Web3Provider | undefined;

    if (
      this.web3Service.web3Provider &&
      this.web3Service.web3Provider.network.chainId == opportunity.chainId
    ) {
      inUseProvider = this.web3Service.web3Provider;
    } else {
      // In case of differences between wallet chain id and token chain id
      inUseProvider = await this.networksService
        .getNetworkProvider(opportunity.chainId)
        .getProvider();
    }
    if (!inUseProvider) {
      throw Error('No provider exception');
    }
    const stakeContract = this.getContract(opportunity, inUseProvider);
    const result = await stakeContract.unstakeFee();
    return Conversion.convertStringFromDecimal(result, 18).toString();
  }

  private getContract(opportunity: any, web3Provider: Web3Provider) {
    return new ethers.Contract(
      opportunity.contractAddress,
      opportunity.lockedStaking ? LOCKED_STAKING_REWARDS : STAKINGREWARDS,
      web3Provider
    );
  }

  async getEstimateGas(
    opportunity: any,
    amount: string,
    from: string,
    functionality: string
  ): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    const stakeContract = this.getContract(
      opportunity,
      this.web3Service.web3Provider
    );

    const param = {
      from: from,
      value: TokensHolder.isBaseToken(
        opportunity.chainId,
        opportunity.tokenA.address
      )
        ? this.web3Service.web3Provider?.getBalance(from)
        : 0
    };
    if (functionality === 'stake') {
      return stakeContract.estimateGas.stake(amount, param);
    } else {
      return stakeContract.estimateGas.withdraw(amount, param);
    }
  }

  async getLockedStakingEstimateGas(
    opportunity: any,
    amount: string,
    from: string,
    functionality: string,
    id: string
  ): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    const stakeContract = this.getContract(
      opportunity,
      this.web3Service.web3Provider
    );
    const param = {
      from: from,
      value: TokensHolder.isBaseToken(
        opportunity.chainId,
        opportunity.tokenA.address
      )
        ? this.web3Service.web3Provider?.getBalance(from)
        : 0
    };
    if (functionality === 'stake') {
      return stakeContract.estimateGas.stake(id, amount, param);
    } else if (functionality === 'withdraw') {
      return stakeContract.estimateGas.withdraw(id, amount, true, param);
    } else {
      return stakeContract.estimateGas.extend(id, param);
    }
  }
}
