<app-dialog>
  <div class="flex justify-start mb-2">
    <label class="title dark:text-textWhite"> Confirm Swap </label>
    <div class="absolute right-5 mr-1 dark:text-textWhite">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="close()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
  </div>
  <div class="dark:divide-appLightBackground">
    <div
      *ngIf="data?.receiverWalletAddress !== ''"
      class="rounded attention mt-1"
    >
      <div class="p-2 flex flex-col">
        <div class="flex">
          <div class="pr-1">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.975 2.75H14.025C14.3 2.75 14.4833 2.84167 14.6667 3.025L18.975 7.33333C19.1583 7.51667 19.25 7.7 19.25 7.975V14.025C19.25 14.3 19.1583 14.4833 18.975 14.6667L14.6667 18.975C14.4833 19.1583 14.3 19.25 14.025 19.25H7.975C7.7 19.25 7.51667 19.1583 7.33333 18.975L3.025 14.6667C2.84167 14.4833 2.75 14.3 2.75 14.025V7.975C2.75 7.7 2.84167 7.51667 3.025 7.33333L7.33333 3.025C7.51667 2.84167 7.7 2.75 7.975 2.75Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0013 7.33203V10.9987"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.0006 14.6654H11.0098"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="font-bold">Attention</div>
        </div>
        <div class="mt-1 px-2 dark:text-white">
          <span class="from-title break-normal dark:text-darkGrayText">
            Please ensure that you've entered the correct address and ensure
            that the network is the same as the token's network (<span
              class="font-bold"
              >{{ data?.sourceNetworkName }}</span
            >). Double-check the address and network to avoid any potential loss
            of funds.
          </span>
        </div>
      </div>
    </div>
    <div>
      <div class="flex flex-col items-center mt-2">
        <div
          class="bg-gray-50 dark:bg-dialogBoxBackground rounded pt-1 px-2 pb-1 w-full"
        >
          <div class="grid grid-cols-2 px-1 align-items-center gap-4">
            <div class="flex justify-start align-items-center">
              <div>
                <img
                  class="rounded-full"
                  [src]="
                    baseLogoIconsUrl +
                    data?.sourceTokenSymbol?.toLowerCase() +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                />
              </div>
              <div class="flex flex-col ml-3 from-token dark:text-white">
                {{ data?.sourceTokenSymbol }}
                <span
                  *ngIf="data?.targetNetworkName"
                  class="from-token detail dark:text-white"
                >
                  on {{ data?.sourceNetworkName }}
                </span>
              </div>
            </div>
            <div
              id="confirmDialogAmountOut"
              class="flex flex-col blue-text items-end amount dark:text-white"
            >
              {{ data?.amountIn }}
              <span class="text-gray-400 float-right text-xs-2"
                >~${{ (+data?.sourceValue).toFixed(2) }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="z-10 flex arrow-change-token dark:border-darkBorder bg-white dark:bg-dialogBoxBackground"
        >
          <img
            *ngIf="!data?.isDarkMode"
            alt="switch token"
            src="assets/media/icons/to.png"
          />
          <img
            *ngIf="data?.isDarkMode"
            alt="switch token"
            src="assets/media/icons/to.png"
          />
        </div>
        <div
          class="bg-gray-50 dark:bg-dialogBoxBackground rounded pt-1 px-2 pb-1 w-full"
        >
          <div class="grid grid-cols-2 px-1 align-items-center gap-4">
            <div class="flex justify-start align-items-center">
              <div>
                <img
                  class="rounded-full"
                  [src]="
                    baseLogoIconsUrl +
                    data?.destTokenSymbol?.toLowerCase() +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                />
              </div>
              <div class="flex flex-col ml-3 to-token dark:text-white">
                {{ data?.destTokenSymbol }}
                <span
                  *ngIf="data?.targetNetworkName"
                  class="from-token detail dark:text-white"
                >
                  on {{ data?.targetNetworkName }}
                </span>
              </div>
            </div>
            <div
              class="flex flex-col blue-text items-end amount dark:text-white"
            >
              {{ data?.amountOut }}
              <span class="text-gray-400 float-right text-xs-2"
                >~${{ (+data?.destValue).toFixed(2) }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="data?.receiverWalletAddress !== ''"
      class="bg-gray-50 dark:bg-dialogBoxBackground rounded-md py-1 px-2 mt-2 w-full"
    >
      <div class="rounded-md pt-1 px-1 md:px-4 my-1 w-full bg-white">
        <div class="detail-header">
          <span class="blue-text dark:text-textWhite"
            >{{ data?.amountOut }} {{ data?.destTokenSymbol }}</span
          >
          &nbsp;will be sent to address:
        </div>
        <div class="blue-text text-xs-2 dark:text-textWhite">
          {{ data?.receiverWalletAddress }}
        </div>
      </div>
    </div>
    <div
      class="bg-gray-50 dark:bg-dialogBoxBackground rounded pt-1 px-2 mt-2 w-full"
    >
      <div
        class="rounded-xl pt-1 px-1 md:px-4 pb-2 w-full text-gray-400 dark:text-textWhite"
      >
        <div class="detail-header text-gray-400 dark:text-textWhite">
          Details
        </div>
        <div
          class="grid"
          [ngClass]="data?.targetNetworkName ? 'grid-cols-3' : 'grid-cols-2'"
        >
          <div class="col-span-1 detail">
            {{
              data?.targetNetworkName
                ? "Protocol fee"
                : "Liquidity provider fee"
            }}
          </div>
          <div
            class="flex flex-col items-end detail"
            [ngClass]="data?.targetNetworkName ? 'col-span-2' : ''"
          >
            {{ data?.swapFee }}
            {{ data?.targetNetworkName ? "" : data?.sourceTokenSymbol }}
          </div>
        </div>
        <div *ngIf="data?.priceImpact" class="grid grid-cols-2">
          <div class="col-span-1 detail">Price impact</div>
          <div class="flex flex-col items-end detail">
            {{ data?.priceImpact }}%
          </div>
        </div>
        <div class="grid grid-cols-2" *ngIf="data?.slippage">
          <div class="col-span-1 detail">Allowed slippage</div>
          <div
            class="flex flex-col items-end detail"
            [ngClass]="
              data?.slippage ? (+data?.slippage! > 10 ? 'warning-msg' : '') : ''
            "
          >
            {{ data?.slippage }}%
            {{
              data?.slippage
                ? +data?.slippage! > 10
                  ? "⚠"
                  : undefined
                : undefined
            }}
          </div>
        </div>
        <div class="grid grid-cols-2" *ngIf="data?.minAmountOut">
          <div class="col-span-1 detail">Minimum received</div>
          <div class="flex flex-col items-end detail">
            {{ data?.minAmountOut }} {{ data?.destTokenSymbol }}
          </div>
        </div>
      </div>
      <div *ngIf="data?.warning">
        <label class="text-yellow-400 text-xs-2">
          {{ data?.warning }}
        </label>
      </div>
      <div class="warning-msg text-xs-2 text-center font-openSans-regular">
        <div *ngIf="isSourcePriceHigh()">
          It may take a few hours for the transfer to complete.
        </div>
      </div>
      <div
        *ngIf="data?.unusualMode"
        class="flex flex-col text-center font-openSans-semiBold mt-3 mb-3"
      >
        <div class="block">
          <div class="mt-1">
            <input
              type="checkbox"
              id="isUnusual"
              name="isUnusual"
              [(ngModel)]="isUnusualConfirmed"
            />
            <label>
              <span class="text-xs-2 font-openSans-regular text-red-500">
                I understand the risk, but would like to complete the swap at
                the displayed rate.
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="flex my-2 space-x-2">
      <div
        *ngIf="data?.receiverWalletAddress !== ''"
        class="flex flex-col w-1/4"
      >
        <button
          id="btnEdit"
          (click)="close()"
          class="button-d-0066FF border-2 border-solid py-2 px-4 rounded h-44 flex justify-center text-0066FF dark:text-3385FF text-sm items-center text-center hover:bg-blue-700"
        >
          <span>Edit</span>
        </button>
      </div>
      <div
        class="flex flex-col w-full"
        [ngClass]="{ 'w-3/4': data?.receiverWalletAddress !== '' }"
      >
        <button
          id="btnConfirmSwapDialog"
          (click)="confirm()"
          [ngClass]="{
            disable: data?.unusualMode && !isUnusualConfirmed
          }"
          class="blue-button py-2 px-4 rounded h-44 flex justify-center text-white text-sm items-center text-center hover:bg-blue-700"
        >
          <span>Confirm</span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
