<app-dialog [closable]="false">
  <div class="flex justify-end mb-4">
    <div class="absolute right-5 mr-1 dark:text-textWhite">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 cursor-pointer" fill="none"
           stroke="currentColor" viewBox="0 0 24 24" (click)="closeDialog()">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
        </path>
      </svg>
    </div>
  </div>
  <div class="flex justify-start">
  </div>
  <div class="divide-y">
    <div>
      <div class="rounded-xl pt-1 pr-4 pl-4 pb-2 w-full">
        <div class="text-base font-openSans-semiBold text-center dark:text-white">
          Turn on expert mode?
        </div>
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-3 text-center font-openSans-regular text-sm text-gray-400 mt-2">
            Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result in bad rates and lost funds.
          </div>
          <div class="col-span-3 text-center font-openSans-regular text-xs-2 dark:text-white">
            Use this mode only if you are sure of what you are doing.
          </div>
        </div>
      </div>
      <div class="flex flex-col mt-3 mb-3">
        <button (click)="confirm()"
                class="bg-red-expert-mode py-2 px-4 rounded h-52 font-openSans-bold flex justify-center text-white text-sm items-center text-center hover:bg-blue-700">
          <span>Turn on expert mode</span>
        </button>
      </div>
      <div class="flex flex-col mt-3 mb-3">
        <button (click)="closeDialog()"
                class=" py-2 px-4 rounded-md h-52 flex justify-center font-openSans-bold text-sm items-center text-center dark:text-white">
          <span>Cancel</span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
