import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getETFImage'
})
export class GetETFImagePipe implements PipeTransform {
  transform(etfName: string, planName: string, isDark: boolean): string {
    return (
      'assets/media/etf/' +
      etfName +
      '_' +
      planName?.replace(/\s/, '') +
      (isDark ? '_dark' : '') +
      '.png'
    );
  }
}
