import { Component, EventEmitter, Output } from '@angular/core';
import { DialogsComponent } from '../dialogs.component';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent {
  @Output()
  closed = new EventEmitter<boolean>();

  @Output()
  confirmed = new EventEmitter<boolean>();

  constructor() {}

  confirm() {
    this.confirmed.emit(true);
  }

  close() {
    this.closed.emit(true);
  }
}
