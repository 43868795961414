import { EventEmitter, Injectable, Input, Output } from '@angular/core';
import { DialogDataModel } from '../../../model/dialog-data.model';
import { environment } from '../../../../environments/environment';

@Injectable()
export abstract class DialogsComponent {
  public baseLogoIconsUrl = environment.BaseLogoIconsUrl;
  @Input('data')
  public data: DialogDataModel | undefined;
  @Input('closable')
  public closable: boolean = true;
  @Input('isSelectOption')
  public isSelectOption: boolean = false;
  @Input('dialogWidth')
  public dialogWidth: boolean = false;
  @Output()
  closed = new EventEmitter<boolean>();

  updateUrl(el: any) {
    el.target.src =
      'https://asset.crowdswap.org/00-none.png';
  }
}
