import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type
} from '@angular/core';
import { DialogsComponent } from './dialogs/dialogs.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService<T> {
  private componentRefList: Array<ComponentRef<T>> = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  async open(
    component: Type<T>,
    data: any,
    closable: boolean = true
  ): Promise<ComponentRef<T> | undefined> {
    try {
      const componentRef = this.componentFactoryResolver
        .resolveComponentFactory<T>(component)
        .create(this.injector);
      if (!componentRef) {
        throw Error('The component resolver returns undefined');
      }
      this.componentRefList.push(componentRef);
      this.appRef.attachView(componentRef.hostView);
      document.body.appendChild(
        (componentRef.hostView as EmbeddedViewRef<any>)
          .rootNodes[0] as HTMLElement
      );

      (<DialogsComponent>(<any>componentRef.instance)).data = data;
      (<DialogsComponent>(<any>componentRef.instance)).closable = closable;
      (<DialogsComponent>(<any>componentRef.instance)).closed.subscribe(
        (event) => {
          event && this.close();
        }
      );

      return componentRef;
    } catch (e) {
      console.log(`Could not resolve the given component: ${component}. ${e}`);
    }
  }

  async close(): Promise<void> {
    const componentRef = this.componentRefList.pop();
    if (!componentRef) {
      return;
    }
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  async closeByRef(
    ref: ComponentRef<T> | undefined = undefined
  ): Promise<void> {
    let list = this.componentRefList;
    if (ref) {
      list = list.filter((x) => x.componentType == ref.componentType);
    }
    const componentRef = list.pop();
    if (!componentRef) {
      return;
    }
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }
}
