<div class="d-flex justify-content-evenly text-323232 button-height">
  <div class="d-flex align-items-center justify-content-evenly w-full">
    <!-- Exchange -->
    <button
      [ngClass]="{
        'text-0066FF dark:text-3385FF':
          navigatorService.getActiveMenu() === ActiveMenu.Exchange,
        'dark:text-fff':
          navigatorService.getActiveMenu() !== ActiveMenu.Exchange,
        'ms-2': !isMobile,
        'col': isMobile
      }"
      id="menu-crossChain"
      (click)="changePage('exchange')"
      routerLink="/exchange"
      class="d-flex flex-column flex-md-row align-items-center justify-content-md-center font-openSans-bold font-16 p-2"
    >
      <div class="justify-center pr-1 d-flex d-lt-none d-lg-flex">
        <svg
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="4.58333"
            cy="17.0013"
            rx="1.83333"
            ry="1.83333"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <circle
            cx="17.4193"
            cy="6.0013"
            r="1.83333"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.4193 7.83203C18.4193 7.27975 17.9716 6.83203 17.4193 6.83203C16.867 6.83203 16.4193 7.27975 16.4193 7.83203H18.4193ZM10.0859 16.9987L9.37883 16.2916C9.09283 16.5776 9.00728 17.0077 9.16206 17.3814C9.31684 17.7551 9.68148 17.9987 10.0859 17.9987V16.9987ZM13.543 14.9558C13.9336 14.5653 13.9336 13.9321 13.543 13.5416C13.1525 13.1511 12.5194 13.1511 12.1288 13.5416L13.543 14.9558ZM12.1288 20.4558C12.5194 20.8463 13.1525 20.8463 13.543 20.4558C13.9336 20.0653 13.9336 19.4321 13.543 19.0416L12.1288 20.4558ZM10.793 16.2916C10.4025 15.9011 9.76936 15.9011 9.37883 16.2916C8.98831 16.6821 8.98831 17.3153 9.37883 17.7058L10.793 16.2916ZM16.4193 7.83203V12.4154H18.4193V7.83203H16.4193ZM16.4193 12.4154C16.4193 14.3944 14.815 15.9987 12.8359 15.9987V17.9987C15.9195 17.9987 18.4193 15.499 18.4193 12.4154H16.4193ZM12.8359 15.9987H10.0859V17.9987H12.8359V15.9987ZM10.793 17.7058L13.543 14.9558L12.1288 13.5416L9.37883 16.2916L10.793 17.7058ZM13.543 19.0416L10.793 16.2916L9.37883 17.7058L12.1288 20.4558L13.543 19.0416Z"
            fill="currentColor"
          />
          <path
            d="M3.58594 15.1667C3.58594 15.719 4.03365 16.1667 4.58594 16.1667C5.13822 16.1667 5.58594 15.719 5.58594 15.1667H3.58594ZM11.9193 6V7C12.3237 7 12.6884 6.75636 12.8432 6.38268C12.9979 6.00901 12.9124 5.57889 12.6264 5.29289L11.9193 6ZM9.87638 2.54289C9.48585 2.15237 8.85269 2.15237 8.46216 2.54289C8.07164 2.93342 8.07164 3.56658 8.46216 3.95711L9.87638 2.54289ZM8.46216 8.04289C8.07164 8.43342 8.07164 9.06658 8.46216 9.45711C8.85269 9.84763 9.48585 9.84763 9.87638 9.45711L8.46216 8.04289ZM12.6264 6.70711C13.0169 6.31658 13.0169 5.68342 12.6264 5.29289C12.2359 4.90237 11.6027 4.90237 11.2122 5.29289L12.6264 6.70711ZM5.58594 15.1667V10.5833H3.58594V15.1667H5.58594ZM5.58594 10.5833C5.58594 8.60431 7.19025 7 9.16927 7V5C6.08568 5 3.58594 7.49974 3.58594 10.5833H5.58594ZM9.16927 7H11.9193V5H9.16927V7ZM12.6264 5.29289L9.87638 2.54289L8.46216 3.95711L11.2122 6.70711L12.6264 5.29289ZM9.87638 9.45711L12.6264 6.70711L11.2122 5.29289L8.46216 8.04289L9.87638 9.45711Z"
            fill="currentColor"
          />
        </svg>
      </div>

      <span
        [ngClass]="{ 'font-8 font-md-12': isMobile }"
        *ngIf="
          !isMobile || navigatorService.getActiveMenu() === ActiveMenu.Exchange
        "
      >
        Exchange
      </span>
    </button>

    <!-- opportunity -->
    <button
      [ngClass]="{
        'text-0066FF dark:text-3385FF':
          navigatorService.getActiveMenu() === ActiveMenu.Opportunities,
        'dark:text-fff':
          navigatorService.getActiveMenu() !== ActiveMenu.Opportunities,
        'ms-2': !isMobile,
        col: isMobile
      }"
      id="menu-opportunity"
      (click)="changePage('opportunity')"
      routerLink="/opportunity"
      class="d-flex flex-column flex-md-row align-items-center justify-content-md-center font-openSans-bold font-16 p-2"
    >
      <div class="flex justify-center pr-1 d-flex d-lt-none d-lg-flex">
        <svg
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="9.16667"
            cy="9.66667"
            r="6.41667"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.25 19.75L13.75 14.25"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.0026 6.91797H8.71094C7.95155 6.91797 7.33594 7.53358 7.33594 8.29297C7.33594 9.05236 7.95155 9.66797 8.71094 9.66797H9.6276C10.387 9.66797 11.0026 10.2836 11.0026 11.043C11.0026 11.8024 10.387 12.418 9.6276 12.418H7.33594"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.1641 12.4167C10.1641 11.8644 9.71635 11.4167 9.16406 11.4167C8.61178 11.4167 8.16406 11.8644 8.16406 12.4167H10.1641ZM8.16406 13.3333C8.16406 13.8856 8.61178 14.3333 9.16406 14.3333C9.71635 14.3333 10.1641 13.8856 10.1641 13.3333H8.16406ZM10.1641 6C10.1641 5.44772 9.71635 5 9.16406 5C8.61178 5 8.16406 5.44772 8.16406 6H10.1641ZM8.16406 6.91667C8.16406 7.46895 8.61178 7.91667 9.16406 7.91667C9.71635 7.91667 10.1641 7.46895 10.1641 6.91667H8.16406ZM8.16406 12.4167V13.3333H10.1641V12.4167H8.16406ZM8.16406 6V6.91667H10.1641V6H8.16406Z"
            fill="currentColor"
          />
        </svg>
      </div>

      <span
        [ngClass]="{ 'font-8 font-md-12': isMobile }"
        *ngIf="
          !isMobile ||
          navigatorService.getActiveMenu() === ActiveMenu.Opportunities
        "
      >
        Opportunity
      </span>
    </button>

    <!-- Portfolio -->
    <button
      [ngClass]="{
        'text-0066FF dark:text-3385FF':
          navigatorService.getActiveMenu() === ActiveMenu.Portfolio,
        'dark:text-fff':
          navigatorService.getActiveMenu() !== ActiveMenu.Portfolio,
        'ms-2': !isMobile,
        col: isMobile
      }"
      id="menu-portfolio"
      (click)="changePage('portfolio')"
      routerLink="/portfolio"
      class="d-flex flex-column flex-md-row align-items-center justify-content-md-center font-openSans-bold font-16 p-2"
    >
      <div class="flex justify-center pr-1 d-flex d-lt-none d-lg-flex">
        <svg
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.66406 17.9154H18.3307"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.66406 14.25L7.33073 8.75L10.9974 10.5833L14.6641 6L18.3307 9.66667"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <span
        [ngClass]="{ 'font-8 font-md-12': isMobile }"
        *ngIf="
          !isMobile || navigatorService.getActiveMenu() === ActiveMenu.Portfolio
        "
      >
        Portfolio
      </span>
    </button>

    <!-- buy/sell -->
    <button
      [ngClass]="{
        'text-0066FF dark:text-3385FF':
          navigatorService.getActiveMenu() === ActiveMenu.FiatOnRamp,
        'dark:text-fff':
          navigatorService.getActiveMenu() !== ActiveMenu.FiatOnRamp,
        'ms-2': !isMobile,
        col: isMobile
      }"
      id="menu-fiat"
      (click)="changePage('fiat')"
      routerLink="/fiat"
      class="d-flex flex-column flex-md-row align-items-center justify-content-md-center font-openSans-bold font-16 p-2"
    >
      <div class="flex justify-center pr-1 d-flex d-lt-none d-lg-flex">
        <svg
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.75 19.7513H19.25"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2.75 9.66536H19.25"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M4.58203 6L10.9987 3.25L17.4154 6"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.66536 9.66797V19.7513"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.3333 9.66797V19.7513"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.33333 13.332V16.082"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.0013 13.332V16.082"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.6654 13.332V16.082"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <span
        [ngClass]="{ 'font-8 font-md-12': isMobile }"
        *ngIf="
          !isMobile ||
          navigatorService.getActiveMenu() === ActiveMenu.FiatOnRamp
        "
      >
        Buy/Sell
      </span>
    </button>

    <!-- swap -->
    <button
      [ngClass]="{
        'text-0066FF dark:text-3385FF':
          navigatorService.getActiveMenu() === ActiveMenu.ClassicSwap,
        'dark:text-fff':
          navigatorService.getActiveMenu() !== ActiveMenu.ClassicSwap,
        'ms-2': !isMobile,
        col: isMobile
      }"
      id="menu-swap"
      (click)="changePage('classicSwap')"
      routerLink="/classicSwap"
      class="d-flex flex-column flex-md-row align-items-center justify-content-md-center font-openSans-bold font-16 p-2"
    >
      <div class="flex justify-center pr-1 d-flex d-lt-none d-lg-flex">
        <svg
          width="22"
          height="23"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.5833 3.25V19.75"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.16406 17L6.41406 19.75L3.66406 17"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.41927 19.75V3.25"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.3359 6L15.5859 3.25L12.8359 6"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      <span
        [ngClass]="{ 'font-8 font-md-12': isMobile }"
        *ngIf="
          !isMobile ||
          navigatorService.getActiveMenu() === ActiveMenu.ClassicSwap
        "
      >
        Classic Swap
      </span>
    </button>
  </div>
</div>
