import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DexInfoService } from './dex-info.service';
import { formatNumber } from '@uniswap/conedison/format';
import { NetworksById } from '@crowdswap/constant';
import { Web3Service } from 'src/app/services';

@Component({
  selector: 'app-dex-info',
  templateUrl: './dex-info.component.html',
  styleUrls: ['./dex-info.component.scss']
})
export class DexInfoComponent implements OnInit {
  loading: boolean = false;
  dex = {
    total24hoursVolumeInUsd: 1111,
    totalLiquidityInUsd: 1111,
    pairs: []
  };

  constructor(
    public ref: ChangeDetectorRef,
    private web3Service: Web3Service,
    public dexInfoService: DexInfoService
  ) {}
  async ngOnInit(): Promise<void> {
    this.setRefreshDataIntervals();
    await this.initData();
  }

  private async initData() {
    this.loading = true;
    const dexes = await this.getData();
    this.loading = false;
    const [, dex] = this.aggregateDexes(dexes);
    this.updatePageContent(dex);
  }

  public setRefreshDataIntervals() {
    setInterval(async () => {
      const dexes = await this.getData();
      const dex = this.aggregateDexes(dexes);
      this.updatePageContent(dex);
    }, 60000);
  }

  private async getData() {
    const dexes = await this.dexInfoService.getAllDexesInfo();
    return dexes;
  }

  public updatePageContent(dex) {
    dex.total24hoursVolumeInUsd = dex.total24hoursVolumeInUsd.toFixed(2);
    dex.totalLiquidityInUsd = dex.totalLiquidityInUsd.toFixed(2);

    dex.total24hoursVolumeInUsd = formatNumber(dex.total24hoursVolumeInUsd);
    dex.totalLiquidityInUsd = formatNumber(dex.totalLiquidityInUsd);

    dex.pairs = dex.pairs.map((pair) => {
      pair['24hoursVolumeInUsd'] = pair['24hoursVolumeInUsd'].toFixed(2);
      pair['liquidityInUsd'] = pair['liquidityInUsd'].toFixed(2);

      pair['24hoursVolumeInUsd'] = formatNumber(pair['24hoursVolumeInUsd']);
      pair['liquidityInUsd'] = formatNumber(pair['liquidityInUsd']);

      pair['networkName'] = this.web3Service.networkSpec[pair['chainId']].title;
      return pair;
    });
    this.dex = dex;
    this.ref.detectChanges();
  }

  private aggregateDexes(dexes: Object) {
    const dex: {} = {
      total24hoursVolumeInUsd: 0,
      totalLiquidityInUsd: 0,
      pairs: []
    };
    for (const dexName in dexes) {
      dex['total24hoursVolumeInUsd'] +=
        dexes[dexName]['total24hoursVolumeInUsd'];
      dex['totalLiquidityInUsd'] += dexes[dexName]['totalLiquidityInUsd'];
      dex['pairs'].push(...dexes[dexName]['pairs']);
    }
    const dexName = Object.keys(dexes)[0];
    return [dexName, dex];
  }

  private getDefaultDex(dexes: Object) {
    const dexName = Object.keys(dexes)[0];
    const dex = dexes[dexName];
    return [dexName, dex];
  }
}
