import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { interval, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UtilsService } from '../../../../services/utils.service';

@Component({
  selector: 'app-confirm-swap-dialog',
  templateUrl: './unstake-dialog.component.html',
  styleUrls: ['./unstake-dialog.component.scss']
})
export class UnstakeDialogComponent extends DialogsComponent {
  @Output()
  confirmed = new EventEmitter<boolean>();
  public timeLeft$: Observable<{
    minutesToNextReward: number;
    secondsToNextReward: number;
  }>;

  constructor() {
    super();
    this.timeLeft$ = interval(1000).pipe(
      map((x) => UtilsService.nextRewardTime(this.data?.stakingTime)),
      shareReplay(1)
    );
  }

  unStake() {
    this.confirmed.emit(true);
  }

  close() {
    this.closed.emit(true);
  }
}

@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [UnstakeDialogComponent]
})
export class UnstakeDialogComponentModule {}
