<div class="w-full opportunity_page_items marginInput2">
  <div
    class="w-full d-flex flex-column justify-center p-1 md:p-3 border-radius-6px border-2 border-00000010 dark:border-1E1E1E"
  >
    <div class="x-1 mt-4 z-0 col-12">
      <div class="row">
        <div class="mt-4 m-2 flex items-center relative">
          <span
            *ngIf="!setting.isMobile"
            class="font-openSans-semiBold font-16 sm:ml-3 sm:mr-3 dark:text-white"
          >
            Stake
          </span>
        </div>
      </div>
      <div class="swap-box md:m-2 z-0">
        <div
          class="swap-token-box border-2 mb-3 bg-white dark:bg-darkBackground sm:ml-3 sm:mr-3 border-0066FF dark:border-3385ff"
        >
          <div class="swap-token-select">
            <div
              class="absolute right-5 margin-top-6 mr-2 w-1/2 md:w-1/3 lg:w-5/12"
            >
              <div class="count cursor-pointer">
                <button (click)="setMaxBalance(stakeMode)">Max</button>
              </div>

              <div class="balance text-gray-400 dark:text-darkGrayText">
                Balance: {{ toFixFraction(token?.balance) }}
              </div>

              <div [ngClass]="{ 'animate-pulse': loading }">
                <button
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="absolute right-0 token-style justify-center rounded-lg text-right outline-none border-0 bg-white text-sm font-medium text-gray-700 dark:bg-darkBackground"
                  type="button"
                >
                  <span class="token-symbol">
                    <img
                      class="rounded-full"
                      width="15"
                      [src]="
                        baseLogoIconsUrl + token?.symbol?.toLowerCase() + '.png'
                      "
                      (error)="updateUrl($event)"
                    />
                  </span>

                  <div class="token-selected dark:text-white">
                    <div class="symbol dark:text-000000cc">
                      {{ token?.symbol }}
                    </div>
                  </div>
                </button>
              </div>
            </div>

            <div class="dropdown w-1/2 md:w-2/3 lg:w-7/12">
              <div [ngClass]="{ 'animate-pulse': loading }">
                <input
                  appFocusAndSelectValue
                  [el]="tokenBAmountInput"
                  #tokenBAmountInput
                  type="text"
                  inputmode="decimal"
                  (blur)="isSelected = false"
                  (click)="isSelected = true"
                  [disabled]="loading"
                  (ngModelChange)="notifyInput($event)"
                  [(ngModel)]="stakeAmount"
                  mask="separator"
                  class="w-full border-0 p-0 outline-none font-24 shadow-none bg-transparent dark:text-fff"
                  [ngClass]="{
                    'w-1/2 bg-gray-300 text-gray-300 rounded-sm dark:bg-gray-300 dark:text-gray-300':
                      loading
                  }"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <ul class="w-full rounded-lg flex dark:text-white">
            <li *ngFor="let plan of activePlans" class="w-full">
              <div class="flex sm:ml-3 sm:mr-3">
                <span
                  class="w-full py-2 font-openSans-bold btn-swap-allow items-center justify-center text-center cursor-pointer hover:bg-gray-300 hover:text-white"
                  (click)="selectPlan(plan.id)"
                  [ngClass]="
                    plan.enable
                      ? 'border-1px border-0066FF dark:bg-3385ff26 dark:border-3385ff border-radius-4px'
                      : 'border-1px border-00000010 border-radius-4px'
                  "
                  >{{ plan.duration }} Days
                </span>
              </div>
            </li>
          </ul>
        </div>

        <div *ngIf="!loading" class="mt-4 sm:ml-3 sm:mr-3">
          <button
            *ngIf="
              stakeMode &&
              stakeButtonState === StakingButtonState.ApprovalNeeded &&
              stakeAmount <= +token.balance &&
              stakeAmount > 0
            "
            (click)="approve()"
            class="btn-swap-allow text-white h-12 w-full blue-button hover:bg-blue-700"
          >
            <svg
              class="bi bi-exclamation-circle text-white"
              fill="currentColor"
              height="20"
              viewBox="0 0 16 16"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path
                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
              />
            </svg>

            <span class="font-openSans-semiBold"
              >Allow to use your {{ token?.symbol }}</span
            >
          </button>
        </div>

        <div class="mt-4 sm:ml-3 sm:mr-3">
          <button
            nz-button
            nzType="default"
            (click)="stake()"
            *ngIf="
              stakeMode &&
              stakeButtonState !== StakingButtonState.WalletNotConnected
            "
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [ngClass]="{
              'opacity-4':
                +token.balance < stakeAmount ||
                stakeButtonState === StakingButtonState.ApprovalNeeded ||
                stakeButtonState === StakingButtonState.Init ||
                stakeAmount <= 0 ||
                loading
            }"
            [disabled]="
              +token.balance < stakeAmount ||
              stakeButtonState === StakingButtonState.ApprovalNeeded ||
              stakeButtonState === StakingButtonState.Init ||
              stakeAmount <= 0 ||
              loading
            "
          >
            <span class="px-1">
              <ng-container *ngIf="stakeAmount > 0">
                {{
                  stakeAmount <= +token.balance
                    ? "Stake"
                    : "Insufficient Balance"
                }}
              </ng-container>

              <ng-container *ngIf="stakeAmount <= 0"
                >Enter an amount</ng-container
              >
            </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="connectWallet()"
            *ngIf="stakeButtonState === StakingButtonState.WalletNotConnected"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          >
            <span class="px-1"> Connect Wallet </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
