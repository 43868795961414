import { Contract, ethers } from 'ethers';
import { CrowdToken, ERC20, ETFReceipt } from '@crowdswap/constant';
import { BaseProvider, Network } from '@ethersproject/providers';
import { Builder } from 'builder-pattern';

export class ERC20Service {
  constructor(private provider: BaseProvider) {}

  async getBalance(userAddress: string, tokenAddress: string): Promise<any> {
    const contract = new ethers.Contract(tokenAddress, ERC20, this.provider);
    return contract.balanceOf(userAddress);
  }

  async getSymbol(tokenAddress: string): Promise<string> {
    const contract = new ethers.Contract(tokenAddress, ERC20, this.provider);
    return contract.symbol();
  }

  async getToken(tokenAddress: string): Promise<CrowdToken> {
    const contract: Contract = new ethers.Contract(
      tokenAddress,
      ERC20,
      this.provider
    );
    const network: Network = await this.provider.getNetwork();
    const chainId: number = network.chainId;
    const decimals: number = await contract.decimals();
    const name: string = await contract.name();
    const symbol: string = await contract.symbol();

    return Builder<CrowdToken>()
      .address(tokenAddress)
      .chainId(chainId)
      .decimals(decimals)
      .name(name)
      .symbol(symbol)
      .build();
  }

  async getApproveTransaction(
    tokenAddress: string,
    spenderAddress: string,
    amount: string
  ): Promise<any> {
    const contract = new ethers.Contract(tokenAddress, ERC20, this.provider);
    return contract.populateTransaction.approve(spenderAddress, amount);
  }

  async getNFTApproveTransaction(
    tokenAddress: string,
    spenderAddress: string
  ): Promise<any> {
    const contract = new ethers.Contract(
      tokenAddress,
      ETFReceipt,
      this.provider
    );
    return contract.populateTransaction.setApprovalForAll(spenderAddress, true);
  }

  async getAllowance(
    tokenAddress: string,
    spenderAddress: string,
    userAddress: string
  ): Promise<any> {
    const contract = new ethers.Contract(tokenAddress, ERC20, this.provider);
    return contract.allowance(userAddress, spenderAddress);
  }

  async getNFTAllowance(
    tokenAddress: string,
    spenderAddress: string,
    userAddress: string
  ): Promise<any> {
    const contract = new ethers.Contract(
      tokenAddress,
      ETFReceipt,
      this.provider
    );
    return contract.isApprovedForAll(userAddress, spenderAddress);
  }
}
