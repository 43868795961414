<div class="w-full opportunity_page_items">
  <div
    class="w-full d-flex flex-column justify-center p-1 md:p-3 border-radius-6px border-sm-2px border-00000010 dark:border-1E1E1E dark:bg-121212"
  >
    <div *ngIf="!lpDetails" class="x-1 mt-4 z-0 col-12">
      <div class="swap-box md:m-2 z-0" id="assetBox">
        <div
          class="swap-token-box border-2 mb-3 bg-white dark:bg-1E1E1E sm:ml-3 sm:mr-3"
          [ngClass]="
            investmentType === InvestmentType.ByTokenAAndTokenB
              ? 'border-0066FF dark:border-3385ff'
              : 'border-00000010 dark:border-2D2D2D'
          "
          id="choose-asset-opp"
          (click)="chooseAsset(InvestmentType.ByTokenAAndTokenB)"
        >
          <div
            class="swap-token-header"
            [ngClass]="{
              'blur-asset': investmentType !== InvestmentType.ByTokenAAndTokenB
            }"
          >
            <span class="title text-sm text-gray-400 dark:text-darkGrayText">
              Use existing {{ selectedOpportunity?.asset }}s
            </span>
          </div>

          <div class="swap-token-select">
            <div
              class="absolute right-5 margin-top-6 mr-2 w-1/2 md:w-1/3 lg:w-5/12"
              [ngClass]="{
                'blur-asset':
                  investmentType !== InvestmentType.ByTokenAAndTokenB
              }"
            >
              <div class="count">
                <button
                  (click)="setMaxBalance(tokenB)"
                  [disabled]="
                    investmentType !== InvestmentType.ByTokenAAndTokenB ||
                    loadingAmount ||
                    opportunityState === OpportunityState.Confirmed
                  "
                >
                  Max
                </button>
              </div>

              <div class="balance text-gray-400 dark:text-darkGrayText">
                Balance: {{ toFixFraction(tokenB?.balance) }}
              </div>

              <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                <button
                  id="source-LP-option"
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="absolute right-0 token-style justify-center rounded-lg text-right outline-none border-0 bg-white text-sm font-medium text-gray-700 dark:bg-darkBlack"
                  type="button"
                >
                  <span class="token-symbol">
                    <img
                      class="rounded-full"
                      width="15"
                      [src]="
                        baseLogoIconsUrl +
                        tokenB?.symbol?.toLowerCase() +
                        '.png'
                      "
                      (error)="updateUrl($event)"
                    />
                  </span>

                  <div class="token-selected dark:text-white">
                    <div class="symbol dark:text-000000cc">
                      {{ tokenB?.symbol }}
                    </div>
                  </div>
                </button>
              </div>
            </div>

            <div
              class="dropdown w-1/2 md:w-2/3 lg:w-7/12"
              [ngClass]="{
                'blur-asset':
                  investmentType !== InvestmentType.ByTokenAAndTokenB
              }"
            >
              <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                <input
                  appFocusAndSelectValue
                  [el]="tokenBAmountInput"
                  #tokenBAmountInput
                  type="text"
                  inputmode="decimal"
                  (blur)="isSelected = false"
                  (click)="isSelected = true"
                  [disabled]="
                    investmentType !== InvestmentType.ByTokenAAndTokenB ||
                    loadingAmount ||
                    opportunityState === OpportunityState.Confirmed
                  "
                  (ngModelChange)="notifySrcInput($event)"
                  [(ngModel)]="tokenBAmount"
                  mask="separator"
                  class="w-full border-0 p-0 outline-none font-24 shadow-none bg-transparent dark:text-fff"
                  [ngClass]="{
                    'w-1/2 bg-gray-300 text-gray-300 rounded-sm dark:bg-gray-300 dark:text-gray-300':
                      loadingAmount,
                    'text-gray-400 dark:text-gray-400':
                      investmentType !== InvestmentType.ByTokenAAndTokenB
                  }"
                />
              </div>
            </div>
          </div>

          <div class="swap-token-select">
            <div
              class="absolute right-5 margin-top-6 mr-2 w-1/2 md:w-1/3 lg:w-5/12"
              [ngClass]="{
                'blur-asset':
                  investmentType !== InvestmentType.ByTokenAAndTokenB
              }"
            >
              <div class="count">
                <button
                  (click)="setMaxBalance(tokenA)"
                  [disabled]="
                    investmentType !== InvestmentType.ByTokenAAndTokenB ||
                    loadingAmount ||
                    opportunityState === OpportunityState.Confirmed
                  "
                >
                  Max
                </button>
              </div>

              <div class="balance text-gray-400 dark:text-darkGrayText">
                Balance: {{ toFixFraction(tokenA?.balance) }}
              </div>

              <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                <button
                  id="source-LP-option"
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="absolute right-0 token-style justify-center rounded-lg text-right outline-none border-0 bg-white text-sm font-medium text-gray-700 dark:bg-darkBlack"
                  type="button"
                >
                  <span class="token-symbol">
                    <img
                      class="rounded-full"
                      width="15"
                      [src]="
                        baseLogoIconsUrl +
                        tokenA?.symbol?.toLowerCase() +
                        '.png'
                      "
                      (error)="updateUrl($event)"
                    />
                  </span>

                  <div class="token-selected dark:text-white">
                    <div class="symbol dark:text-000000cc">
                      {{ tokenA?.symbol }}
                    </div>
                  </div>
                </button>
              </div>
            </div>

            <div
              class="dropdown w-1/2 md:w-2/3 lg:w-7/12"
              [ngClass]="{
                'blur-asset':
                  investmentType !== InvestmentType.ByTokenAAndTokenB
              }"
            >
              <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                <input
                  appFocusAndSelectValue
                  [el]="tokenAAmountInput"
                  #tokenAAmountInput
                  type="text"
                  inputmode="decimal"
                  (blur)="isSelected = false"
                  (click)="isSelected = true"
                  [disabled]="
                    investmentType !== InvestmentType.ByTokenAAndTokenB ||
                    loadingAmount ||
                    opportunityState === OpportunityState.Confirmed
                  "
                  (ngModelChange)="notifyDestInput($event)"
                  [(ngModel)]="tokenAAmount"
                  mask="separator"
                  class="w-full border-0 p-0 outline-none font-24 shadow-none bg-transparent dark:text-fff"
                  [ngClass]="{
                    'w-1/2 bg-gray-300 text-gray-300 rounded-sm dark:bg-gray-300 dark:text-gray-300':
                      loadingAmount,
                    'text-gray-400 dark:text-gray-400':
                      investmentType !== InvestmentType.ByTokenAAndTokenB
                  }"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="swap-token-box border-2 bg-white dark:bg-121212 cursor-pointer sm:ml-3 sm:mr-3"
          [ngClass]="
            investmentType !== InvestmentType.ByTokenAAndTokenB
              ? 'border-0066FF dark:border-3385ff'
              : 'border-00000010 dark:border-2D2D2D'
          "
          id="choose-asset-token"
          (click)="chooseAsset(InvestmentType.ByToken)"
        >
          <div
            class="swap-token-header"
            [ngClass]="{
              'blur-asset': investmentType === InvestmentType.ByTokenAAndTokenB
            }"
          >
            <span class="title text-sm text-gray-400 dark:text-darkGrayText"
              >Convert another token to create {{ selectedOpportunity?.asset }}s
            </span>
          </div>

          <div class="swap-token-select my-4">
            <div
              class="absolute right-5 margin-top-6 mr-2 w-1/2 md:w-1/3 lg:w-5/12"
              [ngClass]="{
                'blur-asset':
                  investmentType === InvestmentType.ByTokenAAndTokenB
              }"
            >
              <div class="count">
                <button
                  (click)="setMaxBalance(token)"
                  [disabled]="
                    investmentType === InvestmentType.ByTokenAAndTokenB ||
                    loadingAmount ||
                    opportunityState === OpportunityState.Confirmed
                  "
                >
                  Max
                </button>
              </div>

              <div class="balance text-gray-400 dark:text-darkGrayText">
                Balance: {{ toFixFraction(token?.balance) }}
              </div>

              <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                <button
                  id="source-option"
                  (click)="openSelectOptionDialog(currentTokenList)"
                  [disabled]="
                    investmentType === InvestmentType.ByTokenAAndTokenB ||
                    loadingAmount ||
                    opportunityState === OpportunityState.Confirmed
                  "
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="absolute right-0 token-style border-radius-4px d-flex align-items-center cursor-pointer"
                  type="button"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="token-symbol">
                      <img
                        class="rounded-full"
                        width="20"
                        [src]="
                          baseLogoIconsUrl +
                          token?.symbol?.toLowerCase() +
                          '.png'
                        "
                        (error)="updateUrl($event)"
                      />
                    </div>
                  </div>
                  <div class="token-selected dark:text-white">
                    <span class="symbol dark:text-000000cc">
                      {{ token?.symbol }}
                    </span>
                    <div class="d-flex align-items-center">
                      <div
                        class="rounded-full flex-shrink-0 p-1px bg-fff overflow-hidden"
                      >
                        <img
                          alt="fromChain"
                          [src]="
                            baseLogoIconsUrl +
                            Networks[token.chainId]?.toLowerCase() +
                            '.png'
                          "
                          (error)="updateUrl($event)"
                          width="14"
                        />
                      </div>
                      <span
                        class="font-10 font-sm-12 text-000 ml-1 flex-shrink-0"
                      >
                        {{ web3Service.networkSpec[token.chainId]?.title }}
                      </span>
                    </div>
                  </div>
                  <div class="token-arrow dark:text-000000cc">
                    <svg
                      class="-mr-1 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>

            <div
              class="dropdown w-1/2 md:w-2/3 lg:w-7/12"
              [ngClass]="{
                'blur-asset':
                  investmentType === InvestmentType.ByTokenAAndTokenB
              }"
            >
              <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                <input
                  appFocusAndSelectValue
                  [el]="chooseAssetToken"
                  #chooseAssetToken
                  type="text"
                  inputmode="decimal"
                  (blur)="isSelected = false"
                  (click)="isSelected = true"
                  [(ngModel)]="tokenAmount"
                  mask="separator"
                  (ngModelChange)="notifyTokenInput($event)"
                  [disabled]="
                    investmentType === InvestmentType.ByTokenAAndTokenB ||
                    loadingAmount ||
                    opportunityState === OpportunityState.Confirmed
                  "
                  class="w-full border-0 p-0 outline-none font-24 shadow-none bg-transparent dark:text-fff"
                  [ngClass]="{
                    'w-1/2 bg-gray-300 text-gray-300 rounded-sm dark:bg-gray-300 dark:text-gray-300':
                      loadingAmount,
                    'text-gray-400 dark:text-gray-400':
                      investmentType === InvestmentType.ByTokenAAndTokenB
                  }"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="swap-token-box border-2 bg-white dark:bg-121212 dark:border-0 cursor-pointer mt-2 sm:ml-3 sm:mr-3 border-00000010 dark:border-ffffff40"
        >
          <div class="swap-token-header w-full">
            <div
              *ngIf="investmentType !== InvestmentType.ByCrossChain"
              class="flex justify-content-between pb-1 items-center detail"
            >
              <div>
                <span
                  class="title text-gray-400 dark:text-darkGrayText text-sm"
                >
                  Investment Amount:
                </span>
              </div>

              <div class="mr-2" [ngClass]="{ 'animate-pulse': loadingAmount }">
                <span
                  *ngIf="loadingAmount"
                  class="text-gray-400 dark:text-darkGrayText flex items-center w-20 h-5"
                  [ngClass]="{
                    'bg-gray-300 text-gray-300 rounded-sm w-20 dark:bg-gray-300 dark:text-gray-300':
                      loadingAmount
                  }"
                >
                </span>

                <span *ngIf="!loadingAmount">
                  $&nbsp;{{ investmentAmount }}
                </span>
              </div>
            </div>

            <div
              *ngIf="investmentType === InvestmentType.ByCrossChain"
              class="flex justify-content-between pb-1 items-center detail"
            >
              <div class="text-gray-400 dark:text-darkGrayText">
                <div class="title text-sm">Investment Amount:</div>

                <div class="title text-xs d-none">
                  (Based on 3% slippage, unused funds will be visible as stable
                  coin in Portfolio)
                </div>
              </div>

              <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                <span
                  *ngIf="loadingAmount"
                  class="text-gray-400 dark:text-darkGrayText flex items-center w-20 h-5"
                  [ngClass]="{
                    'bg-gray-300 text-gray-300 rounded-sm w-20 dark:bg-gray-300 dark:text-gray-300':
                      loadingAmount
                  }"
                >
                </span>

                <span *ngIf="!loadingAmount">
                  $&nbsp;{{
                    selectedOpportunity.version === "V2"
                      ? investmentAmount
                      : minimumInvestmentAmount
                  }}
                </span>
              </div>
            </div>

            <div
              *ngIf="investmentType === InvestmentType.ByCrossChain"
              class="flex justify-content-between pb-1 items-center detail my-1 d-none"
            >
              <div class="text-gray-400 dark:text-darkGrayText">
                <span
                  class="title text-sm text-gray-400 dark:text-darkGrayText"
                >
                  Fee:
                </span>
              </div>

              <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                <span
                  *ngIf="loadingAmount"
                  class="text-gray-400 dark:text-darkGrayText flex items-center w-20 h-5"
                  [ngClass]="{
                    'bg-gray-300 text-gray-300 rounded-sm w-20 dark:bg-gray-300 dark:text-gray-300':
                      loadingAmount
                  }"
                >
                </span>

                <span *ngIf="!loadingAmount"> $&nbsp;{{ platformFee }} </span>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="opportunityState === OpportunityState.ApprovalConfirmed"
          class="flex mt-2 ml-3 pl-2"
        >
          <div class="flex items-center">
            <svg
              class="h-6 w-6 text-green-300 opacity-50"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </svg>
          </div>

          <div class="text-xs text-gray-400 ml-2 flex items-center">
            Now you can invest
            {{
              investmentType === InvestmentType.ByTokenAAndTokenB
                ? tokenB.symbol + " and " + tokenA.symbol
                : token.symbol
            }}
          </div>
        </div>

        <div
          *ngIf="
            this.opportunityState === OpportunityState.ApprovalNeeded &&
            (!(investmentType === InvestmentType.ByCrossChain) ||
              (selectedOpportunity.adapterContractAddress &&
                selectedOpportunity.adapterContractAddress !== ''))
          "
          class="flex flex-col mt-2 sm:ml-3 sm:mr-3"
        >
          <button
            nz-button
            nzType="default"
            (click)="approve()"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          >
            <span
              class="px-1"
              *ngIf="
                investmentType === InvestmentType.ByTokenAAndTokenB;
                else otherInvestmentType
              "
            >
              Allow to use your
              {{ tokenB.symbol }}
              or
              {{ tokenA.symbol }}
            </span>

            <ng-template #otherInvestmentType>
              <span class="px-1">Allow to use your {{ token.symbol }}</span>
            </ng-template>
          </button>
        </div>

        <div
          *ngIf="
            opportunityState !== OpportunityState.InsufficientTokenBBalance &&
            opportunityState !== OpportunityState.InsufficientTokenABalance &&
            opportunityState !== OpportunityState.AmountInIsTooLow &&
            isUserWalletConnected &&
            !isWrongNetwork &&
            (!(investmentType === InvestmentType.ByCrossChain) ||
              (selectedOpportunity.adapterContractAddress &&
                selectedOpportunity.adapterContractAddress !== ''))
          "
          class="flex flex-col mt-2 sm:ml-3 sm:mr-3"
        >
          <button
            nz-button
            nzType="default"
            (click)="confirm()"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [disabled]="
              disableConfirm ||
              loadingAmount ||
              +selectTokenAmount === 0 ||
              opportunityState === OpportunityState.Confirmed ||
              opportunityState === OpportunityState.ApprovalNeeded ||
              opportunityState === OpportunityState.ApprovalRejected
            "
            [ngClass]="{
              'opacity-4':
                disableConfirm ||
                loadingAmount ||
                +selectTokenAmount === 0 ||
                opportunityState === OpportunityState.Confirmed ||
                opportunityState === OpportunityState.ApprovalNeeded ||
                opportunityState === OpportunityState.ApprovalRejected ||
                (selectedOpportunity.apy === '0' &&
                  selectedOpportunity.opportunityType ===
                    opportunityType.TypePancake)
            }"
          >
            <span class="px-1">
              {{
                investmentType === InvestmentType.ByCrossChain
                  ? "Cross chain invest"
                  : "Invest"
              }}
            </span>
          </button>
        </div>

        <div
          *ngIf="
            isUserWalletConnected &&
            !isWrongNetwork &&
            investmentType === InvestmentType.ByCrossChain &&
            (!selectedOpportunity.adapterContractAddress ||
              selectedOpportunity.adapterContractAddress === '')
          "
          class="flex flex-col mt-2 sm:ml-3 sm:mr-3"
        >
          <button
            nz-button
            nzType="default"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [disabled]="true"
            [ngClass]="{
              'opacity-4': true
            }"
          >
            <span class="px-1"> Cross chain invest is not supported! </span>
          </button>
        </div>

        <div
          *ngIf="
            isUserWalletConnected &&
            !isWrongNetwork &&
            !(
              opportunityState === OpportunityState.InsufficientTokenBBalance ||
              opportunityState === OpportunityState.InsufficientTokenABalance
            ) &&
            investmentType === InvestmentType.ByToken &&
            selectedOpportunity.name === 'ARB_ETH_LP_STAKE'
          "
          class="flex flex-col mt-2 sm:ml-3 sm:mr-3 d-none"
        >
          <button
            nz-button
            nzType="default"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [disabled]="true"
            [ngClass]="{
              'opacity-4': true
            }"
          >
            <span class="px-1">
              Invest by token is not supported temporarily!
            </span>
          </button>
        </div>

        <div
          *ngIf="
            opportunityState === OpportunityState.AmountInIsTooLow &&
            !isWrongNetwork &&
            (!(investmentType === InvestmentType.ByCrossChain) ||
              (selectedOpportunity.adapterContractAddress &&
                selectedOpportunity.adapterContractAddress !== ''))
          "
          class="flex flex-col mt-2 sm:ml-3 sm:mr-3"
        >
          <button
            nz-button
            nzType="default"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [disabled]="true"
            [ngClass]="{
              'opacity-4': true
            }"
          >
            <span class="px-1"> Input amount is too low </span>
          </button>
        </div>

        <div
          *ngIf="
            isUserWalletConnected &&
            (opportunityState === OpportunityState.InsufficientTokenBBalance ||
              opportunityState ===
                OpportunityState.InsufficientTokenABalance) &&
            (!(investmentType === InvestmentType.ByCrossChain) ||
              (selectedOpportunity.adapterContractAddress &&
                selectedOpportunity.adapterContractAddress !== ''))
          "
          class="flex flex-col mt-2 sm:ml-3 sm:mr-3"
        >
          <button
            nz-button
            nzType="default"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [disabled]="true"
            [ngClass]="{
              'opacity-4': true
            }"
          >
            <span class="px-1">
              Insufficient
              {{ insufficientSymbol }}
              balance
            </span>
          </button>
        </div>

        <div
          *ngIf="
            isUserWalletConnected &&
            opportunityState === OpportunityState.WrongNetwork
          "
          class="flex flex-col mt-2 sm:ml-3 sm:mr-3"
        >
          <button
            nz-button
            nzType="default"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
            [disabled]="true"
            [ngClass]="{
              'opacity-4': true
            }"
          >
            <span class="px-1"> Wrong network </span>
          </button>
        </div>

        <div
          *ngIf="!isUserWalletConnected"
          class="flex flex-col mt-2 sm:ml-3 sm:mr-3"
        >
          <button
            nz-button
            (click)="connectWallet()"
            nzType="default"
            [nzSize]="'large'"
            class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          >
            <span class="px-1"> Connect Wallet </span>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="lpDetails" class="col-12 px-1">
      <app-farm-confirmation
        (hideSteps)="hidingSteps()"
        [investmentAmount]="investmentAmount"
        [amountLP]="amountLP"
        [opportunityState]="opportunityState"
        [crossChainState]="crossChainState"
        [investmentType]="investmentType"
        [token]="token.symbol"
        [fallbackToken]="fallbackToken"
        [selectedOpportunity]="selectedOpportunity"
        [originTxUrl]="originTxUrl"
        [claimTxUrl]="claimTxUrl"
      >
      </app-farm-confirmation>
    </div>
  </div>
</div>
