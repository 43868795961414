import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Conversion,
  CrowdToken,
  Dexchanges,
  Networks,
  NetworksById,
  PriceService,
  UNISWAP_FACTORY_V2,
  UNISWAP_V2_PAIR,
  TokensHolder
} from '@crowdswap/constant';
import { TokenAmount } from '@crowdswap/sdk';
import { ethers } from 'ethers';
import { Web3Service } from './web3.service';
import { LoggingService } from './log/service/logging.service';
import qs from 'qs';
import { environment } from '../../environments/environment';
import { UtilsService } from './utils.service';
import { Builder } from 'builder-pattern';
import { isZeroAddress } from 'ethereumjs-util';

const baseUrl = environment.SWAP_BASEURL || '';
const API_KEY = environment.API_KEY;

@Injectable()
export class LiquidityService {
  constructor(
    private http: HttpClient,
    private priceService: PriceService,
    private web3Service: Web3Service,
    private logger: LoggingService,
    private utilsService: UtilsService
  ) {}

  private async getAllPairs(chainId) {
    let pools: any[] = [];
    const pairsLength = await this.allPairsLength(chainId);
    for (let i = 0; i < pairsLength; i++) {
      pools.push(await this.getContractFactory(chainId).allPairs(i));
    }
    return pools;
  }

  private getContractFactory(chainId: number) {
    return this.getContract(
      Dexchanges['Crowdswap'].networks[this.web3Service.getCurrentChainId()][0]
        .factoryAddress,
      UNISWAP_FACTORY_V2,
      this.web3Service.getNetworkProvider(chainId)
    );
  }

  public async checkPoolExistence(
    tokenA: CrowdToken,
    tokenB: CrowdToken,
    chainId
  ) {
    const pair = await this.getContractFactory(chainId).getPair(
      TokensHolder.getWrappedToken(tokenA).address,
      TokensHolder.getWrappedToken(tokenB).address
    );
    return !isZeroAddress(pair);
  }

  private getContract(contractAddress: string, abi, web3Provider) {
    return new ethers.Contract(contractAddress, abi, web3Provider);
  }

  public async preparePoolInfo(
    poolAddress: string,
    chainId: number,
    token_A?: CrowdToken,
    token_B?: CrowdToken
  ) {
    const poolContract = this.getContract(
      poolAddress,
      UNISWAP_V2_PAIR,
      this.web3Service.getNetworkProvider(chainId)
    );
    const [reserves0, reserves1] = await poolContract.getReserves();
    const tokenATemp =
      TokensHolder.TokenListByAddress[NetworksById[chainId]][
        await poolContract.token0()
      ];
    const tokenA = new CrowdToken(
      tokenATemp.chainId,
      tokenATemp.address,
      tokenATemp.decimals,
      tokenATemp.symbol,
      tokenATemp.name,
      tokenATemp.price
    );
    tokenA.balance = reserves0;
    const tokenBTemp =
      TokensHolder.TokenListByAddress[NetworksById[chainId]][
        await poolContract.token1()
      ];
    const tokenB = new CrowdToken(
      tokenBTemp.chainId,
      tokenBTemp.address,
      tokenBTemp.decimals,
      tokenBTemp.symbol,
      tokenBTemp.name,
      tokenBTemp.price
    );
    tokenB.balance = reserves1;
    const userTokenBalance = await this.getTokenBalance(poolAddress, chainId);
    const userPoolShare = await this.getUserSharePercentage(
      poolAddress,
      userTokenBalance,
      chainId
    );
    const [tokenAAmount, tokenBAmount] = await this.convertPairTokenToAndB(
      tokenA,
      tokenB,
      poolAddress,
      userTokenBalance,
      chainId
    );
    const userLiquidityInUsd = parseFloat(
      await this.priceService.getLPTokenPrice(
        tokenA.chainId,
        poolAddress
        // tokenB,
        // userTokenBalance,
        // Dexchanges.Crowdswap, // TODO: we must check this dexchange if it's not only our DEX
        // this.web3Service.getNetworkProvider(tokenA.chainId)
      )
    );
    const liquidity = await this.calculateTvl(tokenA, tokenB);
    const volume24H = await this.getVolume24H(poolAddress);
    const feeAprYear = '';
    const feeAprWeek = '';
    const feeEarned = '0';
    const lpToken = new CrowdToken(
      chainId,
      poolAddress,
      await poolContract.decimals(),
      await poolContract.symbol()
    );
    return {
      tokenA: token_A ? token_A : TokensHolder.getUnwrappedToken(tokenA),
      tokenB: token_B ? token_B : TokensHolder.getUnwrappedToken(tokenB),
      poolShare: Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(userPoolShare, 18),
        7
      ),
      tokenAAmount: tokenAAmount,
      tokenBAmount: tokenBAmount,
      userLiquidityInUsd: userLiquidityInUsd,
      userLpToken: Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(userTokenBalance, 18),
        7
      ),
      feeEarned: feeEarned,
      liquidity: liquidity,
      volume: volume24H,
      feeAprYear: feeAprYear,
      feeAprWeek: feeAprWeek,
      poolAddress: poolAddress,
      lpToken: lpToken,
      isUserInvested: +userTokenBalance !== 0 ? true : false
    };
  }

  private async convertPairTokenToAndB(
    tokenA,
    tokenB,
    poolAddress,
    userTokenBalance,
    chainId
  ) {
    const pair = await this.utilsService.getPair(
      tokenA,
      tokenB,
      Dexchanges['Crowdswap'],
      this.web3Service.getNetworkProvider(chainId)
    );
    const totalSupply = await this.getTotalSupply(poolAddress, chainId);
    const liquidityToken = new TokenAmount(pair.liquidityToken, totalSupply);
    const userLiquidity = new TokenAmount(
      pair.liquidityToken,
      userTokenBalance
    );

    const receivedTokenA = pair.getLiquidityValue(
      tokenA,
      liquidityToken,
      userLiquidity
    );
    const receivedTokenB = pair.getLiquidityValue(
      tokenB,
      liquidityToken,
      userLiquidity
    );
    return [
      (+ethers.utils.formatUnits(
        receivedTokenA.raw.toString(),
        tokenA.decimals
      )).toFixed(2),
      (+ethers.utils.formatUnits(
        receivedTokenB.raw.toString(),
        tokenB.decimals
      )).toFixed(2)
    ] as const;
  }

  private async getTotalSupply(poolAddress: string, chainId: number) {
    let poolContract = this.getContract(
      poolAddress,
      UNISWAP_V2_PAIR,
      this.web3Service.getNetworkProvider(chainId)
    );
    return poolContract.totalSupply();
  }

  private async getUserSharePercentage(
    poolAddress: string,
    userBalance,
    chainId: number
  ) {
    const totalSupply = await this.getTotalSupply(poolAddress, chainId);
    const result = (
      (+userBalance / +Conversion.convertStringFromDecimal(totalSupply)) *
      100
    )
      .toFixed(0)
      .toString();
    return result;
  }

  public async getTokenBalance(token, chainId: number) {
    const balance = await this.web3Service.getBalance(
      token,
      this.web3Service.getNetworkProvider(chainId)
    );
    return balance ? balance.toString() : '0';
  }

  private async getVolume24H(poolAddress: string) {
    //TODO BLOC-1407: We must get Volume 24 hours per DEX and ChainId from Back-end side
    return 0;
  }

  private async calculateTvl(tokenA: CrowdToken, tokenB: CrowdToken) {
    if (!tokenA || !tokenB) {
      this.logger.error('calculateTVL tokenA or tokenB is undefined');
      throw new Error('calculateTVL tokenA or tokenB is undefined');
    }

    return (
      +Conversion.convertStringFromDecimal(
        tokenA.balance.toString(),
        tokenA.decimals
      ) *
        +tokenA.price +
      +Conversion.convertStringFromDecimal(
        tokenB.balance.toString(),
        tokenB.decimals
      ) *
        +tokenB.price
    ).toFixed(2);
  }

  public async fillPools(chainId) {
    const liquidityPools: any[] = await this.getAllPairs(chainId);
    for (let i = 0; i < liquidityPools.length; i++) {
      liquidityPools[i] = await this.preparePoolInfo(
        liquidityPools[i],
        chainId
      );
    }
    return liquidityPools;
  }

  public async allPairsLength(chainId: number) {
    try {
      return this.getContractFactory(chainId).allPairsLength();
    } catch (error) {
      return 0;
    }
  }

  public async addLiquidityTransaction(
    poolAddress: string,
    dexName: string,
    tokenA: CrowdToken,
    tokenB: CrowdToken,
    userAddress: string | undefined,
    chainId
  ): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/liquidity/add/${dexName}`;
      const data = {
        network: chainId.toString(),
        poolAddress: poolAddress,
        fromToken: tokenA,
        tokenB: tokenB,
        userAddress: userAddress,
        networkCoinPrice: await this.getNetworkCoinInUSDT(chainId)
      };
      let params = new HttpParams({ fromString: qs.stringify(data) });

      return await this.http
        .get(url, {
          params: params
        })
        .toPromise();
    } catch (err: any) {
      console.log(
        `Error in LiquidityService, getting add liquidity transaction for userAddress = ${userAddress}, ChainId = ${chainId}, tokenA = ${tokenA.symbol},tokenB = ${tokenB.symbol}, error = ${err.message}`
      );
    }
  }

  public async addLiquidityForFirstTimeTransaction(
    dexName: string,
    tokenA: CrowdToken,
    tokenB: CrowdToken,
    userAddress: string | undefined,
    chainId
  ): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/liquidity/addFirstTime/${dexName}`;
      const data = {
        network: chainId.toString(),
        fromToken: tokenA,
        tokenB: tokenB,
        userAddress: userAddress,
        networkCoinPrice: await this.getNetworkCoinInUSDT(chainId)
      };
      let params = new HttpParams({ fromString: qs.stringify(data) });

      return await this.http
        .get(url, {
          params: params
        })
        .toPromise();
    } catch (err: any) {
      console.log(
        `Error in LiquidityService, getting add liquidity for first Time transaction for userAddress = ${userAddress}, ChainId = ${chainId}, tokenA = ${tokenA},tokenB = ${tokenB}, error = ${err.message}`
      );
    }
  }

  public async removeLiquidityTransaction(
    poolAddress: string,
    chainId: number,
    dexName: string,
    token: CrowdToken,
    percentForRemove: number,
    userAddress: string | undefined
  ): Promise<any> {
    try {
      const userTokenBalance = await this.getTokenBalance(poolAddress, chainId);
      const url = `${baseUrl}/api/v1/liquidity/remove/${dexName}`;
      const data = {
        network: chainId.toString(),
        poolAddress: poolAddress,
        fromToken: token,
        amountIn: ((percentForRemove * +userTokenBalance) / 100).toFixed(0),
        userAddress: userAddress,
        networkCoinPrice: await this.getNetworkCoinInUSDT(chainId)
      };
      let params = new HttpParams({ fromString: qs.stringify(data) });

      return await this.http
        .get(url, {
          params: params
        })
        .toPromise();
    } catch (err: any) {
      console.log(
        `Error in LiquidityService, getting remove liquidity transaction for userAddress = ${userAddress}, ChainId = ${chainId}, token = ${token}, liquidity = ${percentForRemove}, error = ${err.message}`
      );
    }
  }

  private async getNetworkCoinInUSDT(chainId: number) {
    const networkCoin =
      TokensHolder.TokenListBySymbol[NetworksById[chainId]][
        this.web3Service.networkSpec[chainId].coin
      ];

    return this.priceService.getPrice(
      networkCoin,
      this.web3Service.getNetworkProvider(networkCoin.chainId)
    );
  }
}
