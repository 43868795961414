import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { TimeService } from '../../../services/time.service';
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  SwiperOptions
} from 'swiper';

// Install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);

export interface BannerOption {
  device: string;
  banner: {
    [key: string]: { path: string; link: string }[];
  };
}
@Component({
  selector: 'app-header-banner',
  templateUrl: './header-banner.component.html',
  styleUrls: ['./header-banner.component.scss']
})
export class HeaderBannerComponent implements OnInit {
  public defaultOptions: BannerOption = {
    device: '',
    banner: {
      MOB: [
        {
          path: '../../../../assets/media/header-banners/header-banner-MOB.webp',
          link: 'https://crowdswap.org/airdrop'
        }
        // {
        //   path: '../../../../assets/media/header-banners/header-banner-MOB2.webp',
        //   link: 'https://crowdswap.org/crowd-listing'
        // }
      ],
      TAB: [
        {
          path: '../../../../assets/media/header-banners/header-banner-TAB.webp',
          link: 'https://crowdswap.org/airdrop'
        }
        // {
        //   path: '../../../../assets/media/header-banners/header-banner-TAB2.webp',
        //   link: 'https://crowdswap.org/crowd-listing'
        // }
      ],
      LAP: [
        {
          path: '../../../../assets/media/header-banners/header-banner-LAP.webp',
          link: 'https://crowdswap.org/airdrop'
        }
        // {
        //   path: '../../../../assets/media/header-banners/header-banner-LAP2.webp',
        //   link: 'https://crowdswap.org/crowd-listing'
        // }
      ],
      WEB: [
        {
          path: '../../../../assets/media/header-banners/header-banner-WEB.webp',
          link: 'https://crowdswap.org/airdrop'
        }
        // {
        //   path: '../../../../assets/media/header-banners/header-banner-WEB2.webp',
        //   link: 'https://crowdswap.org/crowd-listing'
        // }
      ]
    }
  };

  public currentTime!: number;
  public startTime = 1716508800; //start Fri May 24 2024 00:00:00
  public finishTime = 1721865599; //finish Wednesday, July 24, 2024 11:59:59 PM
  public isClosed: boolean = false;
  public bannerConfig: SwiperOptions = {
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 0,
    direction: 'horizontal',
    navigation: false,
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    }
  };

  @ViewChild('headerBanner', { static: false }) headerBanner!: ElementRef;

  constructor(protected timeService: TimeService) {}

  ngOnInit(): void {
    this.currentTime = this.timeService.getCurrentTime();
    this.checkWidth(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWidth(event.target.innerWidth);
  }

  checkWidth(width: number) {
    if (width <= 430 && this.defaultOptions.device !== 'MOB') {
      this.defaultOptions.device = 'MOB';
    } else if (
      width > 430 &&
      width <= 1024 &&
      this.defaultOptions.device !== 'TAB'
    ) {
      this.defaultOptions.device = 'TAB';
    } else if (
      width > 1024 &&
      width <= 1600 &&
      this.defaultOptions.device !== 'LAP'
    ) {
      this.defaultOptions.device = 'LAP';
    } else if (width > 1600 && this.defaultOptions.device !== 'WEB') {
      this.defaultOptions.device = 'WEB';
    }
  }

  close() {
    this.isClosed = true;
  }
}
