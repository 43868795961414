import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { asyncScheduler, Subject, Subscription, throwError } from 'rxjs';
import {
  commonConfig,
  Conversion,
  CrowdToken,
  Networks,
  NetworksById,
  TokensHolder,
  OpportunityInvestmentType,
  OpportunityInvestmentTypeName,
  PriceService,
  OpportunitiesHolder,
  UNUSUAL_MODE_PERCENTAGE,
  MainNetworksById
} from '@crowdswap/constant';
import { ModalService } from '../../../modal/modal.service';
import { Constants } from '../../../../constants';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  throttleTime
} from 'rxjs/operators';
import { ConfirmOpportunityDialogComponent } from '../../../modal/dialogs/confirm-opportunity-dialog/confirm-opportunity-dialog.component';

import { CookieService } from 'ngx-cookie-service';
import { WaitingDialogComponent } from '../../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { BigNumber } from 'ethers';
import { PendingTransactionModel } from '../../../../model';
import { Router } from '@angular/router';
import {
  ConnectWalletService,
  CrossChainState,
  CrossChainSwapService,
  CrowdSwapService,
  DebridgeService,
  LoggingService,
  NDDClientInfoServiceImpl,
  OpportunityService,
  OpportunityState,
  TagManagerService,
  ThemeService,
  TokensService,
  UtilsService,
  Web3Service
} from '../../../../services';
import { CurrentNetwork } from '../../../../model';
import { BaseComponent } from '../../base.component';
import { OpportunityComponent } from '../opportunity.component';
import { ApproveState, LPState } from '../model/opportunity-state.enum';
import { OpportunityType } from '../model/opportunity-state.enum';
import { OpportunitiesService } from '../../../../services/opportunities.service';
import { CrossChainSelectOptionDialogComponent } from '../../../modal/dialogs/cross-chain-select-option-dialog/cross-chain-select-option-dialog.component';
import { DialogDataModel } from '../../../../model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-choose-asset',
  templateUrl: './choose-asset.component.html',
  styleUrls: ['./choose-asset.component.scss']
})
export class ChooseAssetComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public opportunityType = OpportunityType;
  public OpportunityState = OpportunityState;
  public InvestmentType = OpportunityInvestmentType;
  public lpDetails: boolean = false;
  public isSelected: boolean;
  public dialogOpened: boolean = false;
  public closedWaitingDialog: boolean = false;
  public subscriptionList: Subscription[] = [];
  public currentTokenList: CrowdToken[] = [];
  public opportunityTokenList: CrowdToken[] = [];
  public token!: CrowdToken;
  public selectToken!: CrowdToken;
  public tokenA!: CrowdToken;
  public tokenB!: CrowdToken;
  public fallbackToken!: CrowdToken;
  public tokenAAmount = '0';
  public tokenBAmount = '0';
  public tokenAmount = '0';
  public selectTokenAmount = '0';
  public investmentAmount = '111111111111';
  public minimumInvestmentAmount: string | undefined = '111111111111';
  public platformFee: string | undefined = '111111111111'; //the amount of usdc on the destination that is not invested and sends back to user
  public opportunityTx: any;
  public srcInputNotifier = new Subject<number>();
  public destInputNotifier = new Subject<number>();
  public tokenInputNotifier = new Subject<number>();
  private readonly hasSevenDecimalLength = /^\d+(\.\d{7,})$/;
  public slippage: string = CrowdSwapService.OPPORTUNITY_SLIPPAGE;
  public deadline: string = CrowdSwapService.DEADLINE;
  public loadingAmount: boolean = true;
  public disableConfirm: boolean = true;
  public currentChainId: number = Networks.POLYGON_MAINNET;
  public investmentType: OpportunityInvestmentType =
    OpportunityInvestmentType.ByToken;
  public opportunityState: OpportunityState = OpportunityState.Init;
  public crossChainState: CrossChainState = CrossChainState.SrcInit;
  public approveState: ApproveState = ApproveState.Init;
  public pendingTxList: PendingTransactionModel[] = [];
  public amountLP: any;
  public selectedOpportunity;
  public insufficientSymbol: any;
  public detailsMsg: string = '';
  private confirmationInterval: ReturnType<typeof setInterval> | undefined =
    undefined;
  public originTxUrl: any = undefined;
  public claimTxUrl: any = undefined;
  public tooltip: boolean = false;
  public Networks = Networks;
  private defaultFixFee = {
    MAINNET: 0.001,
    BSCMAIN: 0.005,
    POLYGON_MAINNET: 0.5,
    ARBITRUM: 0.001,
    OPTIMISM: 0.001,
    ZKSYNC: 0.001,
    AVALANCHE: 0.01
  };

  _opportunity;
  @Input()
  set opportunity(opportunity: any) {
    this._opportunity = opportunity;
  }
  @Output()
  showSteps = new EventEmitter();

  @Output()
  updateDetails = new EventEmitter();

  get opportunity() {
    return this._opportunity;
  }

  constructor(
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    protected themeService: ThemeService,
    private router: Router,
    private toastr: ToastrService,
    private priceService: PriceService,
    private cookieService: CookieService,
    private opportunityService: OpportunityService,
    private OpportunitiesService: OpportunitiesService,
    private crossChainSwapService: CrossChainSwapService,
    private debridgeService: DebridgeService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private confirmOppDialogService: ModalService<ConfirmOpportunityDialogComponent>,
    private crossChainSelectOptionDialogComponentModalService: ModalService<CrossChainSelectOptionDialogComponent>,
    private utilsService: UtilsService,
    private logger: LoggingService,
    private tokensService: TokensService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    protected connectWalletService: ConnectWalletService
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
    this.isSelected = false;
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    this.tagManagerService.sendStartViewTag('opportunity_invest');
    this.selectedOpportunity =
      this._opportunity || (await this.checkUrlParams());
    this.opportunityState = this.web3Service.isConnected()
      ? OpportunityState.Init
      : OpportunityState.WalletNotConnected;

    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe(
        async (connection) => {
          this.opportunityState = OpportunityState.Init;
          if (!connection) {
            this.hidingSteps();
            await this.router.navigate(['opportunity']);
            this.opportunityState = OpportunityState.WalletNotConnected;
          }
          this.ref.detectChanges();
        }
      )
    );
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          this.hidingSteps();
          this.currentChainId = currentNetwork.chainId;
          this.isWrongNetwork = false;

          if (this.selectedOpportunity.name === 'CROWD_MATIC_LP_STAKE') {
            this.currentTokenList = this.currentTokenList.filter(
              (token) =>
                token.symbol !== 'EDAT' &&
                token.symbol !== 'DEUSDC' &&
                token.symbol !== 'WMATIC'
            );
          }
          this.ref.detectChanges();
        })
    );
    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        async (isWrongNetwork: boolean) => {
          if (isWrongNetwork) {
            this.hidingSteps();
            this.opportunityState = OpportunityState.WrongNetwork;
            this.isWrongNetwork = true;
            this.ref.detectChanges();
          }
        }
      )
    );
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        await this.initialTokens();
        if (
          this.opportunityState !==
            OpportunityState.InsufficientTokenBBalance &&
          this.opportunityState !== OpportunityState.InsufficientTokenABalance
        ) {
          this.hidingSteps();
        }
      })
    );
    this.getCookies();

    this.srcInputNotifier
      .pipe(
        debounceTime(OpportunityService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async (value) => {
        this.loadingAmount = true;
        this.selectToken = this.tokenB;
        this.tokenBAmount = this.getCorrectAmount(value);
        this.selectTokenAmount = this.tokenBAmount;
        await this.changeLPAmount(this.tokenBAmount, LPState.tokenB);
      });
    this.destInputNotifier
      .pipe(
        debounceTime(OpportunityService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async (value) => {
        this.loadingAmount = true;
        this.selectToken = this.tokenA;
        this.tokenAAmount = this.getCorrectAmount(value);
        this.selectTokenAmount = this.tokenAAmount;
        await this.changeLPAmount(this.tokenAAmount, LPState.tokenA);
      });
    this.tokenInputNotifier
      .pipe(
        debounceTime(OpportunityService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async (value) => {
        this.loadingAmount = true;
        this.selectToken = this.token;
        this.tokenAmount = this.getCorrectAmount(value);
        this.selectTokenAmount = this.tokenAmount;
        await this.changeLPAmount(this.tokenAmount, LPState.token);
      });
    this.currentTokenList = this.tokensService.getAllTokens();
    this.opportunityTokenList = this.currentTokenList.filter(
      (item) => item.chainId === this.selectedOpportunity.chainId
    );
    this.token =
      TokensHolder.ObservableTokenListBySymbol[
        Networks[this.selectedOpportunity.chainId]
      ][Constants.DEFAULT_PAIRS[this.selectedOpportunity.chainId].fromToken];
    await this.initialTokens();
    this.ref.detectChanges();
  }

  getCorrectAmount(amount: number) {
    let result = amount;
    if (!result) {
      result = 0;
    }
    if (this.hasSevenDecimalLength.test(amount.toString())) {
      result = parseFloat(Conversion.adjustFraction(amount, 7));
    }
    return result.toString();
  }

  getCookies() {
    let cookie = this.cookieService?.get('slippage');
    // if (cookie != '' && cookie != null && cookie !== this.slippage) {
    //   this.slippage = cookie;
    // }
    cookie = this.cookieService?.get('deadline');
    if (cookie != '' && cookie != null && cookie !== this.deadline) {
      this.deadline = cookie;
    }
  }

  async chooseAsset(investType: number) {
    if (
      this.investmentType === this.InvestmentType.ByCrossChain &&
      investType === this.InvestmentType.ByToken
    ) {
      investType = this.InvestmentType.ByCrossChain;
    }
    if (+this.investmentType !== investType && !this.loadingAmount) {
      await this.updateBalances(true, investType);
    }
  }

  async updateBalances(
    withRefresh: boolean = false,
    investType: number = OpportunityInvestmentType.ByTokenAAndTokenB
  ) {
    this.loadingAmount = true;
    this.currentTokenList = await this.addBalanceToTokens(
      this.currentTokenList
    );
    this.opportunityTokenList = this.currentTokenList.filter(
      (item) => item.chainId === this.selectedOpportunity.chainId
    );
    this.tokenB.balance = this.opportunityTokenList.filter(
      (token) =>
        token.chainId === this.tokenB.chainId &&
        token.address.toLowerCase() === this.tokenB.address.toLowerCase()
    )[0].balance;
    this.tokenA.balance = this.opportunityTokenList.filter(
      (token) =>
        token.chainId === this.tokenA.chainId &&
        token.address.toLowerCase() === this.tokenA.address.toLowerCase()
    )[0].balance;
    this.token.balance = this.currentTokenList.filter(
      (token) =>
        token.chainId === this.token.chainId &&
        token.address.toLowerCase() === this.token.address.toLowerCase()
    )[0].balance;
    if (withRefresh) {
      this.investmentType = investType;
      if (this.investmentType == OpportunityInvestmentType.ByTokenAAndTokenB) {
        this.selectToken = this.tokenB;
        this.selectTokenAmount = this.tokenBAmount;
        await this.changeLPAmount(this.tokenBAmount, LPState.tokenB);
      } else {
        if (this.token.chainId !== this.selectedOpportunity.chainId) {
          this.investmentType = this.InvestmentType.ByCrossChain;
        }
        this.tokenAmount = '1';
        this.selectTokenAmount = '1';
        this.selectToken = this.token;
        await this.changeLPAmount(this.tokenAmount, LPState.token);
      }
    }
    this.disableConfirm = false;
    this.ref.detectChanges();
  }

  async initialTokens() {
    this.tokenB =
      TokensHolder.TokenListBySymbol[
        Networks[this.selectedOpportunity.chainId]
      ][this.selectedOpportunity.tokenB.symbol];
    this.tokenA =
      TokensHolder.TokenListBySymbol[
        Networks[this.selectedOpportunity.chainId]
      ][this.selectedOpportunity.tokenA.symbol];
    if (!this.tokenB || !this.tokenA) {
      return;
    }
    await this.updateBalances();
    this.tokenA.price = '0';
    this.tokenB.price = '0';
    this.tokenA.price = await this.priceService.getPrice(
      this.tokenA,
      this.web3Service.getNetworkProvider(this.tokenA.chainId)
    );
    this.tokenB.price = await this.priceService.getPrice(
      this.tokenB,
      this.web3Service.getNetworkProvider(this.tokenB.chainId)
    );
    let tokenBBalance = this.tokenB.balance;
    let tokenABalance = this.tokenA.balance;
    if (TokensHolder.isBaseToken(this.tokenA.chainId, this.tokenA.address)) {
      tokenABalance = (
        parseFloat(this.tokenA.balance) -
        parseFloat(Constants.REDUCE_MAX_AMOUNT_IN.toString())
      ).toString();
    } else if (
      TokensHolder.isBaseToken(this.tokenB.chainId, this.tokenB.address)
    ) {
      tokenBBalance = (
        parseFloat(this.tokenB.balance) -
        parseFloat(Constants.REDUCE_MAX_AMOUNT_IN.toString())
      ).toString();
    }
    if (
      parseFloat(Conversion.adjustFraction(tokenBBalance, 7)) > 0 &&
      parseFloat(Conversion.adjustFraction(tokenABalance, 7)) > 0
    ) {
      this.investmentType = OpportunityInvestmentType.ByTokenAAndTokenB;
      this.tokenAmount = Conversion.adjustFraction('0', 7);
      tokenBBalance = (
        parseFloat(tokenBBalance) -
        this.utilsService.getTotalFees(this.selectedOpportunity, tokenBBalance)
      ).toString();
      let tokenBInUSD = this.utilsService.multiplyInBigNumber(
        await this.priceService.getPrice(
          this.tokenB,
          this.web3Service.getNetworkProvider(this.tokenB.chainId)
        ),
        tokenBBalance
      )!;
      const tokenAInUSD = this.utilsService.multiplyInBigNumber(
        await this.priceService.getPrice(
          this.tokenA,
          this.web3Service.getNetworkProvider(this.tokenA.chainId)
        ),
        tokenABalance
      )!;
      if (parseFloat(tokenBInUSD) > parseFloat(tokenAInUSD)) {
        this.selectToken = this.tokenA;
        this.selectTokenAmount = tokenABalance;
        this.tokenAAmount = Conversion.adjustFraction(
          this.selectTokenAmount,
          7
        );
        await this.changeLPAmount(this.selectTokenAmount, LPState.tokenA);
      } else {
        this.selectToken = this.tokenB;
        this.selectTokenAmount = tokenBBalance;
        this.tokenBAmount = Conversion.adjustFraction(
          this.selectTokenAmount,
          7
        );
        await this.changeLPAmount(this.selectTokenAmount, LPState.tokenB);
      }
    } else {
      this.selectToken = this.token;
      this.selectTokenAmount = '1';
      if (this.investmentType !== OpportunityInvestmentType.ByCrossChain) {
        this.investmentType = OpportunityInvestmentType.ByToken;
      }
      this.tokenAmount = '1';
      this.tokenBAmount = Conversion.adjustFraction('0', 7);
      this.tokenAAmount = Conversion.adjustFraction('0', 7);
      await this.changeLPAmount(this.tokenAmount, LPState.token);
    }
  }

  async approve(): Promise<any> {
    try {
      if (this.currentChainId != this.selectToken.chainId) {
        await this.web3Service.changeNetwork(this.selectToken.chainId);
      }
      if (this.investmentType == OpportunityInvestmentType.ByTokenAAndTokenB) {
        if (this.approveState === ApproveState.srcToken) {
          await this.getApprove(this.tokenB);
        }
        if (this.approveState === ApproveState.destToken) {
          await this.getApprove(this.tokenA);
        }
        if (this.approveState === ApproveState.srcDestToken) {
          this.approveState = ApproveState.srcToken;
          await this.getApprove(this.tokenB);
          this.approveState = ApproveState.destToken;
          await this.getApprove(this.tokenA);
        }
      } else {
        await this.getApprove(this.token);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getApprove(token: CrowdToken): Promise<any> {
    let readyToApprove = true;
    try {
      this.logger.info(
        `Start getting approve for token [${token.symbol}], opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.ApprovalNeeded}]`
      );
      const userAddress = this.web3Service.getWalletAddress();
      if (!userAddress) {
        return;
      }
      const data = {
        sourceTokenSymbol: token.symbol
      };
      await this.waitingDialogService.open(WaitingDialogComponent, data);

      // Get approval tx
      let contractAddress = Constants.DEBRIDGE_ADDRESS;
      if (this.investmentType !== this.InvestmentType.ByCrossChain) {
        contractAddress = this._opportunity.contractAddress;
      }
      let approvalTx = await this.web3Service.getApprovalTransactionBySpender(
        contractAddress,
        token.address,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      );

      try {
        // Send approval tx
        approvalTx = await this.web3Service.sendTransaction(approvalTx);
      } catch (error) {
        this.changeOpportunityState(OpportunityState.ApprovalNeeded);
        this.showErrorToaster('Error', 'Approval rejected!');
        readyToApprove = false;
        this.opportunityService.setConsoleLog(
          this.selectedOpportunity,
          OpportunityState.ApprovalNeeded,
          'Approval transaction is not submitted',
          error
        );

        return false;
      }

      if (!approvalTx) {
        this.changeOpportunityState(OpportunityState.ApprovalNeeded);
        this.showErrorToaster('Error', 'Something went wrong!');
        this.opportunityService.setConsoleLog(
          this.selectedOpportunity,
          OpportunityState.ApprovalNeeded,
          'Approval transaction is empty'
        );
        return false;
      }
      this.opportunityService.setConsoleLog(
        this.selectedOpportunity,
        OpportunityState.ApprovalNeeded,
        'Approval transaction is submitted'
      );
      // Wait for approval tx confirmation
      await this.web3Service
        .waitForTransaction(approvalTx, 1)
        .then(async (data) => {
          if (data?.status === 1) {
            this.changeOpportunityState(OpportunityState.ApprovalConfirmed);
            this.opportunityService.setConsoleLog(
              this.selectedOpportunity,
              OpportunityState.ApprovalConfirmed,
              'Approval transaction is confirmed'
            );
            await this.checkAllowance();
          } else {
            this.showErrorToaster('Error', 'Something went wrong!');
            this.changeOpportunityState(OpportunityState.ApprovalNeeded);
            this.opportunityService.setConsoleLog(
              this.selectedOpportunity,
              OpportunityState.ApprovalNeeded,
              'Approval transaction is not confirmed'
            );
          }
        })
        .catch((error) => {
          this.showErrorToaster('Error', 'Something went wrong!');
          this.changeOpportunityState(OpportunityState.ApprovalNeeded);
          readyToApprove = false;
          this.opportunityService.setConsoleLog(
            this.selectedOpportunity,
            OpportunityState.ApprovalNeeded,
            'Approval transaction is not confirmed',
            error
          );
        });
      this.logger.info(
        `Finish getting approve for token [${token.symbol}], opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.ApprovalNeeded}]`
      );
    } catch (error) {
      this.showErrorToaster('Error', 'Something went wrong!');
      this.changeOpportunityState(OpportunityState.ApprovalNeeded);
      readyToApprove = false;

      this.opportunityService.setConsoleLog(
        this.selectedOpportunity,
        OpportunityState.ApprovalNeeded,
        'Approval transaction has error',
        error
      );
    } finally {
      await this.waitingDialogService.close();
    }
    return readyToApprove;
  }

  private async tagManager(event: any, status: string = '') {
    let unSelectedToken = this.tokenB;
    let unSelectedAmount = this.tokenBAmount;
    if (
      this.selectToken.address.toLowerCase() ===
      this.tokenB.address.toLowerCase()
    ) {
      unSelectedToken = this.tokenA;
      unSelectedAmount = this.tokenAAmount;
    }
    let actualInvestAmount = this.investmentAmount;
    if (
      this.investmentType === this.InvestmentType.ByCrossChain &&
      !this.selectedOpportunity.version
    ) {
      actualInvestAmount = this.minimumInvestmentAmount ?? '0';
    }

    await this.tagManagerService.sendOpportunityTags(
      event,
      this.selectedOpportunity,
      OpportunityComponent.defaultTab,
      this.investmentType,
      this.selectToken,
      this.selectTokenAmount,
      unSelectedToken,
      unSelectedAmount,
      actualInvestAmount,
      status
    );
  }

  private async checkAllowance(): Promise<any> {
    try {
      if (
        (this.investmentType !== OpportunityInvestmentType.ByTokenAAndTokenB &&
          this.isCoin(this.token)) ||
        this.opportunityState ==
          this.OpportunityState.InsufficientTokenABalance ||
        this.opportunityState == this.OpportunityState.InsufficientTokenBBalance
      ) {
        return;
      }
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        let contractAddress = Constants.DEBRIDGE_ADDRESS;
        let provider = this.web3Service.web3Provider;
        if (this.selectToken.chainId === this.selectedOpportunity.chainId) {
          contractAddress = this._opportunity.contractAddress;
        }
        if (this.currentChainId !== this.selectToken.chainId) {
          provider = this.web3Service.getNetworkProvider(
            this.selectToken.chainId
          );
        }
        this.approveState = ApproveState.token;
        if (!this.isCoin(this.selectToken)) {
          let allowance: BigNumber = await this.web3Service
            .getAllowanceBySpender(
              contractAddress,
              this.selectToken.address,
              userAddress,
              provider
            )
            .catch((e) => {
              console.log(e);
              this.showErrorToaster('Error', 'Something went wrong!');
              this.changeOpportunityState(OpportunityState.ApprovalRejected);
              return;
            });
          let allowanceValue = Conversion.toSafeBigNumberByRemovingFraction(
            allowance.toString()
          );
          let amountInValue = Conversion.toSafeBigNumberByRemovingFraction(
            Conversion.convertStringToDecimal(
              this.selectTokenAmount,
              this.selectToken.decimals
            ).toString()
          );
          if (allowanceValue.lt(amountInValue)) {
            this.approveState = ApproveState.token;
            if (
              this.investmentType == OpportunityInvestmentType.ByTokenAAndTokenB
            ) {
              this.approveState =
                this.selectToken.address.toLowerCase() ===
                this.tokenB.address.toLowerCase()
                  ? ApproveState.srcToken
                  : ApproveState.destToken;
            }
            console.log('allowance for 1 ' + this.selectToken.symbol);
            this.changeOpportunityState(OpportunityState.ApprovalNeeded);
          }
        }
        if (
          this.investmentType == OpportunityInvestmentType.ByTokenAAndTokenB
        ) {
          let unSelectedToken = this.tokenB;
          let unSelectedAmount = this.tokenBAmount;
          if (
            this.selectToken.address.toLowerCase() ===
            this.tokenB.address.toLowerCase()
          ) {
            unSelectedToken = this.tokenA;
            unSelectedAmount = this.tokenAAmount;
          }
          if (!this.isCoin(unSelectedToken)) {
            let allowance = await this.web3Service
              .getAllowanceBySpender(
                this._opportunity.contractAddress,
                unSelectedToken.address,
                userAddress,
                provider
              )
              .catch((e) => {
                console.log(e);
                this.showErrorToaster('Error', 'Something went wrong!');
                this.changeOpportunityState(OpportunityState.ApprovalRejected);
                return;
              });
            let allowanceValue = Conversion.toSafeBigNumberByRemovingFraction(
              allowance.toString()
            );
            let amountInValue = Conversion.toSafeBigNumberByRemovingFraction(
              Conversion.convertStringToDecimal(
                unSelectedAmount.toString(),
                unSelectedToken.decimals
              ).toString()
            );
            if (allowanceValue.lt(amountInValue)) {
              console.log('allowance for 2 ' + unSelectedToken.symbol);
              this.changeOpportunityState(OpportunityState.ApprovalNeeded);
              this.approveState =
                this.approveState > ApproveState.token
                  ? ApproveState.srcDestToken
                  : unSelectedToken.address.toLowerCase() ===
                    this.tokenB.address.toLowerCase()
                  ? ApproveState.srcToken
                  : ApproveState.destToken;
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
      this.changeOpportunityState(OpportunityState.ApprovalRejected);
    } finally {
      this.ref.detectChanges();
    }
  }

  public async confirm() {
    try {
      this.opportunityTx = undefined;
      this.changeOpportunityState(OpportunityState.Init);
      this.crossChainState = CrossChainState.SrcInit;
      this.logger.info(
        `Start getting confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.Init}]`
      );
      await this.showWaitingDialog(true);
      let totalPending = 0;
      const pendingList = this.pendingTxList.filter(
        (pending) => pending.sourceTokenSymbol === this.selectToken.symbol
      );
      pendingList.forEach((pending) => (totalPending += +pending.amountIn));
      this.selectToken.price = await this.priceService.getPrice(
        this.selectToken,
        this.web3Service.getNetworkProvider(this.selectToken.chainId)
      );
      if (this.investmentType === OpportunityInvestmentType.ByCrossChain) {
        this.fallbackToken = this.selectedOpportunity?.wToken;
        this.fallbackToken.balance = await this.tokensService.getTokenBalance(
          this.fallbackToken
        );
        this.fallbackToken.price = await this.priceService.getPrice(
          this.fallbackToken,
          this.web3Service.getNetworkProvider(this.fallbackToken.chainId)
        );
      }
      if (this.investmentType === this.InvestmentType.ByToken) {
        if (
          this.token.address.toLowerCase() ===
            this.selectedOpportunity.tokenA.address.toLowerCase() ||
          this.token.address.toLowerCase() ===
            this.selectedOpportunity.tokenB.address.toLowerCase()
        ) {
          this.investmentType = OpportunityInvestmentType.ByTokenAOrTokenB;
        } else if (
          this.token.address.toLowerCase() ===
          this._opportunity.LPToken.address.toLowerCase()
        ) {
          this.investmentType = OpportunityInvestmentType.ByLP;
        }
      }
      this.tagManager('invest_start_farm');
      this.opportunityTx = await this.opportunityService.getOpportunityTX(
        this.selectedOpportunity,
        this.web3Service.getWalletAddress(),
        this.selectToken,
        Conversion.convertStringToDecimal(
          this.selectTokenAmount,
          this.selectToken.decimals
        ).toString(),
        this.slippage,
        this.deadline,
        this.investmentType === OpportunityInvestmentType.ByCrossChain
          ? this.fallbackToken.price
          : this.selectToken.price,
        this.investmentType === OpportunityInvestmentType.ByCrossChain
          ? OpportunityInvestmentType.ByToken
          : this.investmentType
      );
      this.tagManager('invest_confirm_waiting_farm');
      if (!this.opportunityTx) {
        this.showErrorToaster('Error', 'Something went wrong!');
        await this.waitingDialogService.close();
        this.opportunityService.setConsoleLog(
          this.selectedOpportunity,
          OpportunityState.Init,
          'Confirmed transaction is empty'
        );
        return;
      }
      this.opportunityService.setConsoleLog(
        this.selectedOpportunity,
        OpportunityState.Init,
        'Confirmed transaction is received from backend'
      );

      this.amountLP = this.getAmount(this.opportunityTx.amountLP, 18, 6);
      const stakeAmount = Conversion.adjustFraction(
        2 *
          parseFloat(
            this.getAmount(
              this.opportunityTx.amountDesiredTokenB,
              this.tokenB.decimals
            )
          ) *
          parseFloat(this.tokenB.price),
        2
      );
      let unUsualMode =
        parseFloat(stakeAmount) <
        parseFloat(this.investmentAmount.replace(',', '')) *
          (1 - UNUSUAL_MODE_PERCENTAGE / 100);
      // if (
      //   !unUsualMode &&
      //   this.investmentType !== OpportunityInvestmentType.ByTokenAAndTokenB &&
      //   parseFloat(this.token.price) > 0
      // ) {
      //   unUsualMode =
      //     parseFloat(this.investmentAmount) <
      //     parseFloat(this.tokenAmount) *
      //       parseFloat(this.token.price) *
      //       (1 - UNUSUAL_MODE_PERCENTAGE / 100);
      // }
      const data = {
        amountOut: this.getAmount(this.opportunityTx.amountLP, 18, 6),
        sourcePrice: this.getAmount(
          this.opportunityTx.amountDesiredTokenB,
          this.tokenB.decimals
        ),
        destinationPrice: this.getAmount(
          this.opportunityTx.amountDesiredTokenA,
          this.tokenA.decimals
        ),
        stakeAmount: stakeAmount,
        activeOpportunity: this.selectedOpportunity,
        unusualMode: unUsualMode,
        className: 'confirm-opportunity-dialog'
      };
      if (this.closedWaitingDialog) {
        await this.waitingDialogService.close();
        return;
      }
      let confirmOppDialogRef = await this.confirmOppDialogService.open(
        ConfirmOpportunityDialogComponent,
        data
      );
      this.tagManager('invest_view_farm');

      const sub: Subscription = (<ConfirmOpportunityDialogComponent>(
        (<any>confirmOppDialogRef!.instance)
      )).confirmed.subscribe(async (event) => {
        if (event) {
          await this.confirmOppDialogService.close();
          await this.waitingDialogService.close();
          await this.confirmOpportunity(this.opportunityTx);
          this.opportunityService.setConsoleLog(
            this.selectedOpportunity,
            OpportunityState.Init,
            'Confirmed transaction has been sent to the provider'
          );
        } else {
          await this.waitingDialogService.close();
          await this.confirmOppDialogService.close();
          this.opportunityService.setConsoleLog(
            this.selectedOpportunity,
            OpportunityState.Init,
            'Confirmed transaction has not been sent to the provider'
          );
        }
      });
      this.ref.detectChanges();
      confirmOppDialogRef!.onDestroy(async () => {
        await this.waitingDialogService.close();
        sub.unsubscribe();
      });
      this.logger.info(
        `Finish getting confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.Init}]`
      );
    } catch (error) {
      await this.waitingDialogService.close();
      this.showErrorToaster('Error', 'Something went wrong!');
      this.opportunityService.setConsoleLog(
        this.selectedOpportunity,
        OpportunityState.Init,
        'Confirming transaction is failed',
        error
      );
    }
  }

  /**
   * On confirm button clicked.
   * The given transaction has been received from backend
   * @param opportunityTx
   */
  async confirmOpportunity(opportunityTx: any) {
    try {
      if (this.currentChainId != this.selectToken.chainId) {
        await this.web3Service.changeNetwork(this.selectToken.chainId);
      }
      this.logger.info(
        `Start getting final confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.Init}], transaction = [${opportunityTx}] `
      );
      this.tagManager('invest_confirm_farm');
      await this.showWaitingDialog();
      this.tagManager('invest_confirmation_view_farm');

      this.detailsMsg = ` selectedToken: ${this.selectToken.symbol}(${
        this.selectToken.chainId
      })(${this.selectToken.address}), amount: ${
        this.investmentAmount
      }, type: ${OpportunityInvestmentTypeName[this.investmentType]}`;

      if (opportunityTx.value) {
        opportunityTx.value = BigNumber.from(opportunityTx.value);
      }

      // Send transaction to the MM
      const oppTx = {
        from: opportunityTx.from,
        to: opportunityTx.to,
        data: opportunityTx.data,
        value: opportunityTx.value
          ? opportunityTx.value
          : TokensHolder.isBaseToken(
              this.tokenA.chainId,
              this.tokenA.address
            ) ||
            TokensHolder.isBaseToken(this.tokenB.chainId, this.tokenB.address)
          ? this.investmentType === this.InvestmentType.ByTokenAAndTokenB
            ? Conversion.convertStringToDecimal(
                TokensHolder.isBaseToken(
                  this.tokenA.chainId,
                  this.tokenA.address
                )
                  ? this.tokenAAmount
                  : this.tokenBAmount,
                TokensHolder.isBaseToken(
                  this.tokenA.chainId,
                  this.tokenA.address
                )
                  ? this.tokenA.decimals
                  : this.tokenB.decimals
              )
            : this.investmentType === this.InvestmentType.ByTokenAOrTokenB
            ? Conversion.convertStringToDecimal(
                TokensHolder.isBaseToken(
                  this.selectToken.chainId,
                  this.selectToken.address
                )
                  ? this.selectTokenAmount
                  : '0',
                TokensHolder.isBaseToken(
                  this.selectToken.chainId,
                  this.selectToken.address
                )
                  ? this.selectToken.decimals
                  : 0
              )
            : opportunityTx.value
          : opportunityTx.value,
        gas: opportunityTx.gasLimit,
        gasLimit: opportunityTx.gasLimit
      };

      let currentTransactionHash: any;
      currentTransactionHash = await this.web3Service
        .sendTransaction(oppTx)
        .then(async (data) => {
          await this.waitingDialogService.close();
          this.changeOpportunityState(OpportunityState.Confirmed);
          this.showSteps.emit(true);
          this.lpDetails = true;
          this.tagManager('invest_confirmation_view_confirm_farm');
          this.opportunityService.setConsoleLog(
            this.selectedOpportunity,
            OpportunityState.Confirmed,
            'Opportunity submitted'
          );
          const pendingTx: PendingTransactionModel = {
            sourceTokenSymbol: this.tokenB.symbol!,
            destTokenSymbol: this.tokenA.symbol!,
            amountIn: this.tokenBAmount,
            amountOut: this.tokenAAmount,
            chainId: this.currentChainId,
            hash: data
          };
          this.addToPendingTxList(pendingTx);
          this.ref.detectChanges();
          return data;
        })
        .catch(async (error) => {
          await this.waitingDialogService.close();
          this.showSteps.emit(false);
          this.lpDetails = false;
          this.tagManager('invest_confirmation_view_reject_farm');
          this.showErrorToaster('Error', 'Opportunity rejected!');
          this.changeOpportunityState(OpportunityState.Rejected);
          this.crossChainState = CrossChainState.SrcFailed;
          this.opportunityService.setConsoleLog(
            this.selectedOpportunity,
            OpportunityState.Rejected,
            'Opportunity rejected!' + this.detailsMsg,
            error
          );
        });

      // If the transaction is confirmed by user
      if (currentTransactionHash) {
        // Wait for swap tx confirmation on blockchain
        await this.web3Service
          .waitForTransaction(currentTransactionHash, 1)
          .then(async (data) => {
            if (data?.status === 1) {
              if (
                this.investmentType === OpportunityInvestmentType.ByCrossChain
              ) {
                this.crossChainState = CrossChainState.SrcSuccessful;
                await this.observeCrossChainSwapStatus(currentTransactionHash);
              } else {
                this.opportunityService.setConsoleLog(
                  this.selectedOpportunity,
                  OpportunityState.Successful,
                  'Opportunity successful.' + this.detailsMsg
                );
                this.changeOpportunityState(OpportunityState.Successful);

                if (
                  parseFloat(this.investmentAmount.replace(',', '')) >=
                    environment.airdrop.minimumAddLiquidityAmount &&
                  (this.selectedOpportunity.tokenA.symbol === 'CROWD' ||
                    this.selectedOpportunity.tokenB.symbol === 'CROWD')
                ) {
                  this.runGleamScript('addLiquidity');
                }
              }
            } else {
              this.changeOpportunityState(OpportunityState.Failed);
              this.crossChainState = CrossChainState.SrcFailed;
              this.opportunityService.setConsoleLog(
                this.selectedOpportunity,
                OpportunityState.Failed,
                'Opportunity failed.' + this.detailsMsg
              );
            }
            try {
              let pendingTx = this.findFromPendingList(currentTransactionHash);
              if (!pendingTx) {
                return;
              }
              if (
                this.investmentType === OpportunityInvestmentType.ByCrossChain
              ) {
                const interval = setInterval(async () => {
                  if (this.confirmationInterval === undefined) {
                    this.removeFromPendingTxList(currentTransactionHash);
                    clearInterval(interval);
                    this.updateOppDetails();
                  }
                }, 1000);
              } else {
                this.removeFromPendingTxList(currentTransactionHash);
                this.updateOppDetails();
              }
              this.showingToastr(currentTransactionHash);
            } catch (error) {
              this.opportunityService.setConsoleLog(
                this.selectedOpportunity,
                this.opportunityState,
                'Failed to show toaster or push tag' + this.detailsMsg,
                error
              );
            }
            return data;
          })
          .catch(async (error) => {
            this.changeOpportunityState(OpportunityState.Failed);
            this.removeFromPendingTxList(currentTransactionHash);
            this.tagManager('invest_error_farm', 'something went wrong');
            this.opportunityService.setConsoleLog(
              this.selectedOpportunity,
              OpportunityState.Failed,
              'Opportunity failed in internal catch.' + this.detailsMsg,
              error
            );
          });
      }
      this.logger.info(
        `Finish getting final confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.Init}]`
      );
    } catch (error) {
      this.tagManager('invest_error_farm', 'something went wrong');
      this.opportunityService.setConsoleLog(
        this.selectedOpportunity,
        OpportunityState.Failed,
        'Opportunity failed in external catch.' + this.detailsMsg,
        error
      );
    }
  }

  /**
   * Check deBrdige transaction status
   * @param transactionHash
   * @private
   */
  private async observeCrossChainSwapStatus(transactionHash: string) {
    let retryCount = 0;
    const fallbackTokenBalance = await this.web3Service.getBalance(
      this.fallbackToken,
      this.web3Service.getNetworkProvider(this.selectedOpportunity.chainId)
    );
    const fallbackTokenInUSDT = fallbackTokenBalance!
      .mul((parseFloat(this.fallbackToken.price) * 10000).toFixed())
      .div(10000);
    this.confirmationInterval = setInterval(async () => {
      retryCount++;
      this.originTxUrl = this.claimTxUrl = undefined;

      if (retryCount >= commonConfig.CROSS_CHAIN.CCLP_TIMEOUT) {
        this.clearConfirmationInterval();
        this.changeOpportunityState(OpportunityState.Failed);
        this.crossChainState = CrossChainState.DstFailed;
        this.opportunityService.setConsoleLog(
          this.selectedOpportunity,
          OpportunityState.Failed,
          ' Timeout after ' + retryCount + ' retry count' + this.detailsMsg,
          undefined,
          CrossChainState.DstFailed
        );
        return;
      }
      // Get destination token balance while CCLP is in progress
      if (this.crossChainState < CrossChainState.DstInit) {
        // Get DLN order status
        const dlnOrderStatus = await this.debridgeService.getDLNOrderStatus(
          this.web3Service.getWalletAddress(),
          transactionHash
        );
        // The dln order is fulfilled
        if (
          dlnOrderStatus &&
          dlnOrderStatus.orders &&
          dlnOrderStatus.orders[0] &&
          [
            'FULFILLED',
            'SENTUNLOCK',
            'CLAIMEDUNLOCK',
            'CLAIMEDORDERCANCEL'
          ].includes(dlnOrderStatus.orders[0].state.toString().toUpperCase())
        ) {
          if (
            ['FULFILLED', 'SENTUNLOCK', 'CLAIMEDUNLOCK'].includes(
              dlnOrderStatus.orders[0].state.toString().toUpperCase()
            )
          ) {
            this.crossChainState = CrossChainState.DstSuccessful;
            // this.tagManagerService.sendCrossChainTags(
            //   'cross_done',
            //   pendingTrade,
            //   this.walletAddress,
            //   this.setting.expertMode,
            //   CrossChainSwapState[pendingTrade.crossChainSwapState]
            // );
            this.opportunityService.setConsoleLog(
              this.selectedOpportunity,
              OpportunityState.Init,
              ' DLN, Successful destination transaction. ',
              undefined,
              CrossChainState.DstSuccessful
            );
            this.clearConfirmationInterval();
            let lastBalanceInUSDT;
            try {
              const wTokenBalance = await this.web3Service.getBalance(
                this.fallbackToken,
                this.web3Service.getNetworkProvider(
                  this.selectedOpportunity.chainId
                )
              );
              lastBalanceInUSDT = wTokenBalance!
                .mul((parseFloat(this.fallbackToken.price) * 10000).toFixed())
                .div(10000);
            } catch (error) {
              this.changeOpportunityState(OpportunityState.Failed);
              this.crossChainState = CrossChainState.DstWarningCatch;
              this.opportunityService.setConsoleLog(
                this.selectedOpportunity,
                OpportunityState.Failed,
                ' Failed last balance.' + this.detailsMsg,
                error,
                CrossChainState.DstFailed
              );
              await this.showingToastr(transactionHash);
              return;
            }
            if (lastBalanceInUSDT) {
              if (
                lastBalanceInUSDT.gt(
                  fallbackTokenInUSDT.add(
                    Conversion.convertStringToDecimal(
                      (parseFloat(this.investmentAmount) / 2).toString(),
                      this.fallbackToken.decimals
                    )
                  )
                )
              ) {
                this.changeOpportunityState(OpportunityState.Failed);
                this.crossChainState = CrossChainState.DstWarning;
                this.opportunityService.setConsoleLog(
                  this.selectedOpportunity,
                  OpportunityState.Failed,
                  ' wToken received by fallback address.',
                  undefined,
                  CrossChainState.DstFailed
                );
              } else {
                this.changeOpportunityState(OpportunityState.Successful);
                this.opportunityService.setConsoleLog(
                  this.selectedOpportunity,
                  OpportunityState.Successful,
                  ' Success last transaction.',
                  undefined,
                  CrossChainState.DstSuccessful
                );
              }
            } else {
              this.changeOpportunityState(OpportunityState.Failed);
              this.crossChainState = CrossChainState.DstWarning;
              this.opportunityService.setConsoleLog(
                this.selectedOpportunity,
                OpportunityState.Failed,
                ' Failed last balance.' + this.detailsMsg,
                CrossChainState.DstFailed
              );
            }
            await this.showingToastr(transactionHash);
          } else if (
            ['CLAIMEDORDERCANCEL'].includes(
              dlnOrderStatus.orders[0].state.toString().toUpperCase()
            )
          ) {
            this.crossChainState = CrossChainState.DstFailed;
            // this.changeCrossChainSwapState(
            //   pendingTrade,
            //   CrossChainSwapState.ClaimedCancelPostCCStep
            // );

            // await this.tagManagerService.sendCrossChainTags(
            //   'cross_canceled_by_user',
            //   pendingTrade,
            //   this.walletAddress,
            //   this.setting.expertMode,
            //   CrossChainSwapState[pendingTrade.crossChainSwapState]
            // );
            this.opportunityService.setConsoleLog(
              this.selectedOpportunity,
              OpportunityState.Failed,
              ' DLN, Canceled by user.',
              undefined,
              CrossChainState.DstFailed
            );
          }
          this.web3Service.assetChangeSubject.next(true);
        }
      }
    }, 10 * 1000);
  }

  private clearConfirmationInterval() {
    if (this.confirmationInterval) {
      clearInterval(this.confirmationInterval);
      this.confirmationInterval = undefined;
    }
  }

  private async showingToastr(transactionHash: string) {
    const scanTransactionUrl =
      this.web3Service.getScanTransactionUrl(transactionHash);
    switch (this.opportunityState) {
      case OpportunityState.Successful: {
        this.tagManager('invest_done_farm', 'Success');

        this.toastr.success(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          `Deposit of ${this.tokenB.symbol} and ${this.tokenA.symbol} ($${this.investmentAmount}) successfully executed!`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'successClass'
          }
        );
        this.web3Service.assetChangeSubject.next(true);
        break;
      }
      case OpportunityState.Failed: {
        this.tagManager('invest_error_farm', 'something went wrong');
        this.toastr.error(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          `Transaction(s) failed`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'failedClass'
          }
        );
        break;
      }
    }
  }

  private changeOpportunityState(opportunityState: OpportunityState) {
    this.opportunityState = opportunityState;
  }

  private async getSrcDestData(estimation: boolean = false) {
    let data = {
      sourcePrice: this.opportunityTx
        ? this.getAmount(
            this.opportunityTx.amountDesiredTokenB,
            this.tokenB.decimals
          )
        : this.tokenBAmount,
      destinationPrice: this.opportunityTx
        ? this.getAmount(
            this.opportunityTx.amountDesiredTokenA,
            this.tokenA.decimals
          )
        : this.tokenAAmount,
      isEstimation: estimation,
      isDarkMode: this.isDarkMode,
      opportunityAsset: this.selectedOpportunity.asset
    };
    return data;
  }

  private async showWaitingDialog(estimation: boolean = false) {
    this.closedWaitingDialog = false;
    const data = await this.getSrcDestData(estimation);
    let waitingDialogRef = await this.waitingDialogService.open(
      WaitingDialogComponent,
      data
    );
    const sub: Subscription = (<ConfirmOpportunityDialogComponent>(
      (<any>waitingDialogRef!.instance)
    )).closed.subscribe(async () => {
      this.closedWaitingDialog = true;
    });
    waitingDialogRef!.onDestroy(async () => {
      this.closedWaitingDialog = true;
      sub.unsubscribe();
    });
    this.ref.detectChanges();
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  notifySrcInput(event) {
    this.disableConfirm = true;
    this.srcInputNotifier.next(event);
  }

  notifyDestInput(event) {
    this.disableConfirm = true;
    this.destInputNotifier.next(event);
  }

  notifyTokenInput(event) {
    this.disableConfirm = true;
    this.tokenInputNotifier.next(event);
  }

  async openSelectOptionDialog(allTokens) {
    if (this.dialogOpened) {
      return;
    }
    this.loadingAmount = true;
    this.dialogOpened = true;
    const data: DialogDataModel = {
      filteredTokenList: allTokens,
      toggle: true,
      chainId: this.selectedOpportunity.chainId,
      className: 'choose-asset-component'
    };
    let selectOptionDialogRef =
      await this.crossChainSelectOptionDialogComponentModalService.open(
        CrossChainSelectOptionDialogComponent,
        data
      );
    const sub: Subscription = (<CrossChainSelectOptionDialogComponent>(
      (<any>selectOptionDialogRef!.instance)
    )).confirmed.subscribe(async (token) => {
      if (token) {
        this.token = <any>token;
        this.investmentType = this.InvestmentType.ByToken;
        if (this.token.chainId !== this.selectedOpportunity.chainId) {
          this.investmentType = this.InvestmentType.ByCrossChain;
        }
        this.token.price = await this.priceService.getPrice(
          this.token,
          this.web3Service.getNetworkProvider(this.token.chainId)
        );
        this.selectToken = this.token;
        this.tokenAmount = '1';
        this.selectTokenAmount = '1';
        await this.changeLPAmount('1', LPState.token);
      } else {
        this.loadingAmount = false;
      }
      this.dialogOpened = false;
      await this.crossChainSelectOptionDialogComponentModalService.close();
    });
    const subscribeImportNewToken: Subscription = (<
      CrossChainSelectOptionDialogComponent
    >(<any>selectOptionDialogRef!.instance)).confirmedImportToken.subscribe(
      async (token: CrowdToken) => {
        this.currentTokenList.push(token);
      }
    );

    selectOptionDialogRef!.onDestroy(() => {
      this.dialogOpened = false;
      this.loadingAmount = false;
      sub.unsubscribe();
      subscribeImportNewToken.unsubscribe();
    });
  }

  async changeLPAmount(amount: string, lpState: number) {
    if (parseFloat(amount) === 0) {
      this.loadingAmount = false;
      this.investmentAmount = '0';
      this.minimumInvestmentAmount = '0';
      this.platformFee = '0';
      return;
    }
    let tokenBAmount = this.tokenBAmount;
    let investAmount = 0;
    this.insufficientSymbol = this.selectToken.symbol;
    this.opportunityState = OpportunityState.Init;
    this.opportunityTx = undefined;
    if (this.investmentType == OpportunityInvestmentType.ByCrossChain) {
      await this.opportunityService
        .getInvestAmountByCrossChain(
          this.selectedOpportunity,
          this.web3Service.getWalletAddress(),
          this.token,
          this.selectedOpportunity.middleToken
            ? this.selectedOpportunity.middleToken
            : this.selectedOpportunity.wToken,
          this.tokenAmount,
          this.slippage
        )
        ?.pipe(
          catchError((err) => {
            if (err === Constants.EXECUTION_FEE_ERROR) {
              this.opportunityState = OpportunityState.AmountInIsTooLow;
            }
            this.disableConfirm = true;
            this.loadingAmount = false;
            this.investmentAmount = '0';
            this.minimumInvestmentAmount = '0';
            this.platformFee = '0';
            return throwError(-1);
          }),
          map(
            async (result: {
              crossChainAmountOut: string;
              crossChainMinAmountOut: string;
              ourPlatformFee: string;
            }) => {
              investAmount =
                parseFloat(
                  await this.priceService.getPrice(
                    this.selectToken,
                    this.web3Service.getNetworkProvider(
                      this.selectToken.chainId
                    )
                  )
                ) * parseFloat(amount);
              const crossChainPlatformFee =
                investAmount - +result.crossChainAmountOut;

              this.setPlatformFee(
                parseFloat(result.ourPlatformFee) + crossChainPlatformFee
              );
              this.setMinimumInvestmentAmount(result.crossChainMinAmountOut);
              await this.setInvestAmount(
                parseFloat(result.crossChainAmountOut)
              );
              return;
            }
          )
        )
        ?.subscribe();
      this.tokenAmount = this.insufficientAmount(this.token, this.tokenAmount);
    } else if (
      this.investmentType == OpportunityInvestmentType.ByTokenAAndTokenB
    ) {
      this.minimumInvestmentAmount = undefined;
      this.platformFee = undefined;

      const tokenBPrice = await this.priceService.getPrice(
        this.tokenB,
        this.web3Service.getNetworkProvider(this.tokenB.chainId)
      );

      if (lpState === LPState.tokenB) {
        tokenBAmount = (
          parseFloat(this.tokenBAmount) -
          this.utilsService.getTotalFees(
            this.selectedOpportunity,
            this.tokenBAmount
          )
        ).toString();
        this.tokenAAmount = await this.utilsService.getQuote(
          this.selectedOpportunity.dexchange,
          this.tokenB,
          this.tokenA,
          tokenBAmount,
          this.web3Service.getNetworkProvider(this.selectedOpportunity.chainId)
        );
      } else {
        this.tokenBAmount = await this.utilsService.getQuote(
          this.selectedOpportunity.dexchange,
          this.tokenA,
          this.tokenB,
          this.tokenAAmount,
          this.web3Service.getNetworkProvider(this.selectedOpportunity.chainId)
        );
        this.tokenBAmount = (
          parseFloat(this.tokenBAmount) +
          this.utilsService.getTotalFees(
            this.selectedOpportunity,
            this.tokenBAmount
          )
        ).toFixed(7);
      }
      investAmount =
        parseFloat(tokenBPrice) * parseFloat(this.tokenBAmount) * 2;
      this.tokenAAmount = this.insufficientAmount(
        this.tokenA,
        this.tokenAAmount
      );
      this.tokenBAmount = this.insufficientAmount(
        this.tokenB,
        this.tokenBAmount
      );
      await this.setInvestAmount(investAmount);
    } else {
      investAmount =
        parseFloat(
          await this.priceService.getPrice(
            this.token,
            this.web3Service.getNetworkProvider(this.token.chainId)
          )
        ) * parseFloat(amount);
      this.tokenAmount = this.insufficientAmount(this.token, amount);
      await this.setInvestAmount(investAmount);
    }
  }

  private insufficientAmount(token: CrowdToken, tokenAmount: string) {
    if (parseFloat(token.balance) < parseFloat(tokenAmount)) {
      this.opportunityState =
        token.address.toLowerCase() === this.tokenA.address.toLowerCase()
          ? OpportunityState.InsufficientTokenABalance
          : OpportunityState.InsufficientTokenBBalance;
      this.insufficientSymbol = token.symbol;
    }
    return Conversion.adjustFraction(tokenAmount, 7);
  }

  private async setInvestAmount(investAmount: number | undefined) {
    if (investAmount) {
      await this.checkAllowance();

      this.investmentAmount = Conversion.replaceSeparator(
        Conversion.adjustFraction(investAmount.toFixed(7), 2)
      ).toString();
      this.loadingAmount = false;
      this.disableConfirm = false;
      this.ref.detectChanges();
    }
  }

  private setMinimumInvestmentAmount(amount: string) {
    this.minimumInvestmentAmount = Conversion.replaceSeparator(
      Conversion.adjustFraction(amount, 2)
    ).toString();
  }

  private setPlatformFee(amount: number) {
    this.platformFee = Conversion.replaceSeparator(
      Conversion.adjustFraction(amount, 2)
    ).toString();
  }

  public async setMaxBalance(token: CrowdToken) {
    if (this.loadingAmount) {
      return;
    }
    this.loadingAmount = true;
    let maxAmountInDecimal = Conversion.convertStringToDecimal(
      token.balance,
      token.decimals
    );
    if (TokensHolder.isBaseToken(token.chainId, token.address)) {
      if (this.investmentType === this.InvestmentType.ByCrossChain) {
        maxAmountInDecimal = maxAmountInDecimal.sub(
          Conversion.convertStringToDecimal(
            this.defaultFixFee[NetworksById[token.chainId]],
            18
          )
        );
        await this.setMaxAmount(maxAmountInDecimal, token);
      } else {
        maxAmountInDecimal = maxAmountInDecimal.sub(
          Conversion.convertStringToDecimal(
            Constants.REDUCE_MAX_AMOUNT_IN.toString(),
            token.decimals
          )
        );
        await this.setMaxAmount(maxAmountInDecimal, token);
      }
    } else {
      await this.setMaxAmount(maxAmountInDecimal, token);
    }
  }

  private async setMaxAmount(maxAmountInDecimal: BigNumber, token: CrowdToken) {
    let state;
    maxAmountInDecimal =
      maxAmountInDecimal > BigNumber.from(0)
        ? maxAmountInDecimal
        : BigNumber.from(0);

    const maxAmountIn = Conversion.convertStringFromDecimal(
      maxAmountInDecimal.toString(),
      token.decimals
    );
    let amount = Conversion.adjustFraction(maxAmountIn, 7);
    switch (token) {
      case this.tokenB:
        state = LPState.tokenB;
        this.tokenBAmount = amount;
        break;
      case this.tokenA:
        state = LPState.tokenA;
        this.tokenAAmount = Conversion.adjustFraction(maxAmountIn, 7);
        break;
      case this.token:
        state = LPState.token;
        this.tokenAmount = Conversion.adjustFraction(maxAmountIn, 7);
        break;
    }
    this.loadingAmount = true;
    this.selectToken = token;
    this.selectTokenAmount = maxAmountIn.toString();
    await this.changeLPAmount(amount.toString(), state);
  }

  public toFixFraction(num: number | string | undefined, fraction: number = 7) {
    return num ? Conversion.adjustFraction(num, 7) : '0';
  }

  private getAmount(amount: string, decimal: number, fraction: number = 6) {
    return Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(amount, decimal),
      fraction
    );
  }

  private addToPendingTxList(pendingTx: PendingTransactionModel) {
    this.pendingTxList.push(pendingTx);
    this.web3Service.pendingChangeSubject.next(this.pendingTxList.length);
  }

  private removeFromPendingTxList(hash: string) {
    let pendingTx = this.findFromPendingList(hash);
    if (pendingTx) {
      this.pendingTxList.splice(this.pendingTxList.indexOf(pendingTx), 1);
      this.web3Service.pendingChangeSubject.next(this.pendingTxList.length);
    }
  }

  private findFromPendingList(
    hash: string
  ): PendingTransactionModel | undefined {
    return this.pendingTxList.find((pending) => pending.hash === hash);
  }

  private async checkUrlParams() {
    const opportunity: string =
      UtilsService.getQueryVariable('opportunity') || '';
    const opportunities = OpportunitiesHolder.Opportunities;
    if (opportunities[opportunity]) {
      return opportunities[opportunity];
    }
    await this.router.navigate(['opportunity']);
  }

  hidingSteps() {
    this.lpDetails = false;
    this.showSteps.emit(false);
  }

  updateOppDetails() {
    this.updateDetails.emit(false);
  }

  public async addBalanceToTokens(tokens: any, withBalance: boolean = false) {
    const promises: any[] = [];
    let result: any[] = [];

    for (const chainId in MainNetworksById) {
      if (environment.ACTIVE_NETWORK.includes(chainId)) {
        let tempTokens = tokens.filter(
          (token: CrowdToken) => token.chainId.toString() === chainId
        );
        promises.push(this.web3Service.addBalanceToTokens(tempTokens));
      }
    }

    // result.push(...tempTokens);
    let promiseResult = await Promise.allSettled(promises);
    promiseResult.forEach((item, index, array) => {
      if (item && item.status === 'fulfilled' && item.value.length > 0) {
        result.push(...item.value);
      }
    });

    if (withBalance) {
      result = result.filter((token: CrowdToken) => {
        return token.balance && token.balance != '' && +token.balance > 0;
      });
      result = result.filter((token) =>
        result.some(({ symbol }) => token === symbol)
      );
    }
    return result;
  }
}
