<div class="flex text-sm font-openSans-semiBold col-12 my-4 px-3 justify-center">
  <div class="flex col-6 cursor-pointer rounded-md justify-center py-2 m-1 font-medium text-center w-full md:w-48"
    [ngClass]="
      activeTab === AffiliateTabs.REVENUE
        ? 'text-white bg-blue-500'
        : 'text-black bg-blue-100 dark:bg-black dark:text-white'
    " (click)="setActiveTab(AffiliateTabs.REVENUE)">
    Dashboard
  </div>
  <div class="flex col-6 cursor-pointer rounded-md justify-center py-2 m-1 font-medium text-center w-full md:w-48"
    [ngClass]="
      activeTab === AffiliateTabs.PROFILE
        ? 'text-white bg-blue-500'
        : 'text-black bg-blue-100 dark:bg-black dark:text-white'
    " (click)="setActiveTab(AffiliateTabs.PROFILE)">
    Profile
  </div>
</div>

<div class="row justify-center mt-2"
  [ngClass]="{ 'blur-sm': !isUserWalletConnected || (sessionExpired && sessionExpired.isSessionExpired) }">
  <app-affiliate-profile [userInfo]="userInfo" (userInfoChanged)="onUserInfoChanged($event)" [isLoading]="isLoading"
    [walletAddress]="currentWalletAddress" [hidden]="activeTab !== AffiliateTabs.PROFILE"></app-affiliate-profile>
  <app-affiliate-revenue [hidden]="activeTab !== AffiliateTabs.REVENUE" [revenue]="revenue"
    [isLoading]="isLoading"></app-affiliate-revenue>
</div>

<app-notification *ngIf="!isUserWalletConnected" [notificationData]="wallConnectNotificationData"
  (onClicked)="connectWallet()"></app-notification>
<app-notification *ngIf="isUserWalletConnected && sessionExpired.isSessionExpired"
  [notificationData]="sessionExpiredNotificationData" (onClicked)="onSignInClicked()"></app-notification>
<app-notification
  *ngIf="isUserWalletConnected && !sessionExpired.isSessionExpired && (userInfo && userInfo.displayEmailVerificationNotify)"
  [notificationData]="notVerifiedEmailNotificationData" (onClicked)="onSendVerificationEmailClicked()"
  (onClosed)="notVerifiedEmailNotificationData.onClose()"></app-notification>