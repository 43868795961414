import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { UnstakeDialogComponent } from '../../../modal/dialogs/unstake-dialog/unstake-dialog.component';
import { ModalService } from '../../../modal/modal.service';
import {
  asyncScheduler,
  interval,
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import { SuccessfulUnStakeDialogComponent } from '../../../modal/dialogs/successful-unstake/successful-unstake-dialog.component';
import {
  Conversion,
  commonConfig,
  STAKINGREWARDS,
  PriceService,
  CrowdToken
} from '@crowdswap/constant';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  throttleTime
} from 'rxjs/operators';

import { WaitingDialogComponent } from '../../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CurrentNetwork } from '../../../../model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Web3Provider } from '@ethersproject/providers';
import { ethers } from 'ethers';
import { BaseComponent } from '../../base.component';
import {
  NDDClientInfoServiceImpl,
  OpportunityService,
  OpportunityState,
  TagManagerService,
  ThemeService,
  TokensService,
  UtilsService,
  Web3Service
} from '../../../../services';
import {
  OpportunityType,
  StakingButtonState
} from '../model/opportunity-state.enum';
import { OpportunitiesService } from '../../../../services/opportunities.service';

@Component({
  selector: 'app-opportunity-stake',
  templateUrl: './opportunity-stake.component.html',
  styleUrls: ['./opportunity-stake.component.scss']
})
export class OpportunityStakeComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  StakingButtonState = StakingButtonState;
  public static LIMITED_INTERVAL_TIME =
    commonConfig.STAKE_UNSTAKE.LIMITED_INTERVAL_TIME;
  public LPMode = false;
  public isSelected = false;
  public tokenBalance = 0;
  public stakedBalance = '0';
  public LPTokenInUSDT = 0;
  public amount = '1';
  public nextReward = 0;
  public stakeTime = 0;
  public allReward = '0';
  public selectedOpp;
  public LPState = 'staked';
  public amountNotifier = new Subject();
  subscriptionList: Subscription[] = [];
  isLPStakingContractValid = false;
  selectFlag = false;
  public tokens;
  public tokenIndex = 0;
  public isMaxLPAmount: boolean = false;

  public timeLeft$:
    | Observable<{
        minutesToNextReward: number;
        secondsToNextReward: number;
      }>
    | undefined = undefined;
  private unstakeInUSDT = 0;
  private LPToken;
  private stakingLPContractAddress;
  public stakeButtonState = StakingButtonState.WalletNotConnected;
  static LPToken: CrowdToken;
  public userPercentage = '0';
  public tokenATokenBAmount;
  public opportunityProvider;
  public minimumStakedBalance = '1';

  @Output()
  updateDetails = new EventEmitter();

  @Input()
  opportunities;

  _opportunity;
  @Input()
  set opportunity(opportunity: any) {
    this._opportunity = opportunity;
  }

  get opportunity() {
    return this._opportunity;
  }

  constructor(
    public opportunityService: OpportunityService,
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    protected themeService: ThemeService,
    private router: Router,
    private toastr: ToastrService,
    private $gtmService: GoogleTagManagerService,
    private unStakeDialogService: ModalService<UnstakeDialogComponent>,
    private successfulUnStakeDialogService: ModalService<SuccessfulUnStakeDialogComponent>,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private priceService: PriceService,
    private utilsService: UtilsService,
    private OpportunitiesService: OpportunitiesService,
    protected tagManagerService: TagManagerService,
    private tokensService: TokensService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    await this.tagManagerService.sendStartViewTag('opportunity_details');

    this.selectedOpp = this._opportunity;
    this.tokens = this.selectedOpp.withdrawTokens;

    this.stakeButtonState = this.web3Service.isConnected()
      ? StakingButtonState.Disabled
      : StakingButtonState.WalletNotConnected;

    this.isLPStakingContractValid =
      (this.opportunities[this.selectedOpp.name].stakingLPContractAddress ||
        this.opportunities[this.selectedOpp.name].adapterContractAddress ||
        undefined) !== undefined;
    // On wallet connect/disconnect
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe((connection) => {
        this.stakeButtonState = connection
          ? StakingButtonState.Disabled
          : StakingButtonState.WalletNotConnected;
        this.tokenBalance = 0;
        this.stakedBalance = '0';
        this.setZero();
        this.getAllowance();
        this.ref.detectChanges();
      })
    );
    // On network change
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          const chainId: number = currentNetwork.chainId;
          this.opportunityProvider =
            this._opportunity.chainId === chainId
              ? this.web3Service.web3Provider
              : this.web3Service.getNetworkProvider(this._opportunity.chainId);

          this.isLPStakingContractValid =
            (this.opportunities[this.selectedOpp.name]
              .stakingLPContractAddress ||
              this.opportunities[this.selectedOpp.name]
                .adapterContractAddress ||
              undefined) !== undefined;
          this.stakingLPContractAddress =
            this.opportunities[this.selectedOpp.name].stakingLPContractAddress;
          if (this.isLPStakingContractValid) {
            await this.getLPAddress();
            await this.refreshStakeData();
            await this.getAllowance();
          } else {
            this.tokenBalance = 0;
            this.stakedBalance = '0';
            this.setZero();
          }
          this.ref.detectChanges();
        })
    );
    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        await this.getLPAddress();
        this.tokenBalance = 0;
        this.stakedBalance = '0';
        this.setZero();
        await this.refreshStakeData();
        await this.getAllowance();
      })
    );

    this.timeLeft$ = interval(1000).pipe(
      map(() => {
        const { secondsToNextReward, minutesToNextReward } =
          UtilsService.nextRewardTime(this.stakeTime);
        if (secondsToNextReward === 0 && minutesToNextReward === 0) {
          this.refreshStakeData();
          this.ref.detectChanges();
        }

        return { secondsToNextReward, minutesToNextReward };
      }),
      shareReplay(1)
    );
    this.amountNotifier
      .pipe(
        debounceTime(OpportunityService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async () => {
        await this.checkBalance();
      });
  }

  public async changeSelectedToken(token: any) {
    this.selectFlag = false;
    this.tokenIndex = this.tokens.indexOf(token);
  }

  setZero() {
    this.allReward = '0';
    this.stakeTime = 0;
    this.nextReward = 0;
    this.userPercentage = '0';
    this.tokenATokenBAmount = { receivedTokenA: '0', receivedTokenB: '0' };
    this.LPTokenInUSDT = 0;
  }

  private async refreshStakeData() {
    try {
      this.stakedBalance = await this.getLPStakedTokenBalance();
      if (this.selectedOpp.rewardToken) {
        await this.getLPStakeReward();
      }
      this.tokenBalance = parseFloat(
        await this.tokensService.getTokenBalance(this.LPToken)
      );
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        if (this.selectedOpp.opportunityType !== OpportunityType.TypeStaking) {
          await this.getTokenATokenBAmount();
          this.LPTokenInUSDT =
            +(await this.priceService.getPrice(
              this._opportunity.tokenB,
              this.web3Service.getNetworkProvider(
                this._opportunity.tokenB.chainId
              )
            )) *
            +this.tokenATokenBAmount.receivedTokenB *
            2;
          this.userPercentage = await this.getUserPercentage();
        } else {
          const { exist: existHolder } =
            await this.opportunityService.getStakeHolder(
              this.selectedOpp,
              this.web3Service.getWalletAddress(),
              this.opportunityProvider
            );
          if (existHolder) {
            if (this.stakedBalance >= this.minimumStakedBalance) {
              await this.getLPStakeReward();
              await this.getLastCrowdStakeTime();
              await this.getNextReward();
            } else {
              this.setZero();
            }
          } else {
            this.stakedBalance = '0';
            this.setZero();
          }
          this.tokenBalance = parseFloat(
            await this.tokensService.getTokenBalance(this.LPToken)
          );
          await this.ref.detectChanges();
        }
      }
      this.ref.detectChanges();
    } catch (e) {
      this.stakedBalance = '0';
      this.setZero();
      this.ref.detectChanges();
      console.log(e);
    }
  }

  public async getLPAddress() {
    try {
      this.LPToken = this.opportunities[this.selectedOpp.name].LPToken;
    } catch (e) {
      console.log('Wrong Network');
      console.error(
        e +
          `StakeComponent-getLPAddress: now tokenBalance is = ${this.tokenBalance} and stakedBalance is  = ${this.stakedBalance}`
      );
    }
  }

  public async getLPStakeReward() {
    try {
      let stakeReward = (
        await this.opportunityService.getLPStakeReward(
          this._opportunity,
          this.web3Service.getWalletAddress()
        )
      )?.balance;
      if (stakeReward) {
        this.allReward = Conversion.convertStringFromDecimal(
          stakeReward.toString(),
          18
        );
      }
      return this.allReward;
    } catch (e) {
      console.log(e);
    }
  }

  async changeAmount(amount: string) {
    this.amount = Conversion.adjustFraction(amount, 18);
    await this.checkBalance();
  }

  public async setMaxBalance(index) {
    this.opportunityService.setConsoleLog(
      this.selectedOpp,
      OpportunityState.Init,
      'pushed max unstake button'
    );
    switch (index) {
      case 0: {
        await this.changeAmount(await this.getLPStakedTokenBalance());
        break;
      }
      case 1: {
        await this.changeAmount(this.allReward);
        break;
      }
    }
  }

  public async withdraw(index) {
    try {
      if (this.web3Service.getCurrentChainId() != this.selectedOpp.chainId) {
        await this.web3Service.changeNetwork(this.selectedOpp.chainId);
      }
      let comparisonValue: string;
      if (this.tokenIndex === 0) {
        // LP
        comparisonValue = await this.getLPStakedTokenBalance();
      } else {
        // Reward
        comparisonValue = this.allReward;
      }
      const isComparisonNegative = this.utilsService.isComparisonResultNegative(
        comparisonValue,
        this.amount
      );

      if (isComparisonNegative) {
        this.opportunityService.setConsoleLog(
          this.selectedOpp,
          OpportunityState.Init,
          'withdraw button,it happend out of our expectation',
          'withdraw button must be disabled , but acted wrongly'
        );
        return;
      }
    } catch (error) {
      // Handle any parsing errors here (e.g., invalid numeric strings)
      console.error('withdraw button,Error parsing numeric values:', error);
      return;
    }

    this.opportunityService.setConsoleLog(
      this.selectedOpp,
      OpportunityState.Init,
      'pushed unstake button'
    );
    try {
      let data;
      if (this.selectedOpp.opportunityType !== OpportunityType.TypeStaking) {
        if (index === 0) {
          this.isMaxLPAmount =
            this.amount === (await this.getLPStakedTokenBalance());
          const LPInUSDT = parseFloat(
            await this.priceService.getLPTokenPrice(
              this._opportunity.tokenA.chainId,
              this.LPToken.address
            )
          );
          this.unstakeInUSDT = parseFloat(
            Conversion.adjustFraction(LPInUSDT * parseFloat(this.amount))
          );
          await this.tagManager('withdraw_start_farm');
          data = {
            amountOut: this.amount,
            sourceBaseTokenSymbol: this.tokens[index],
            minAmountOut: this.unstakeInUSDT,
            nextReward: this.isMaxLPAmount ? this.allReward : '',
            tokenSymbol: this.isMaxLPAmount
              ? this.selectedOpp.rewardToken?.symbol
              : '',
            stakeState: true,
            isDarkMode: this.isDarkMode,
            activeOpportunity: this.selectedOpp
          };
        } else {
          data = {
            nextReward: this.amount,
            tokenSymbol: this.selectedOpp.rewardToken?.symbol,
            stakeState: true,
            isDarkMode: this.isDarkMode
          };
        }
      } else {
        data = {
          nextReward: this.nextReward,
          stakingTime: this.stakeTime,
          stakeAmount: this.amount,
          isDarkMode: this.isDarkMode,
          tokenSymbol: this.selectedOpp.tokenA.symbol
        };
      }
      const unStakeDialogRef = await this.unStakeDialogService.open(
        UnstakeDialogComponent,
        data
      );
      await this.tagManager('withdraw_view_farm');
      const sub: Subscription = (<UnstakeDialogComponent>(
        (<any>unStakeDialogRef!.instance)
      )).confirmed.subscribe(async (event) => {
        await this.unStakeDialogService.close();
        if (event) {
          await this.confirmWithdraw(index);
        } else if (!event) {
          this.ref.detectChanges();
        }
      });
      unStakeDialogRef!.onDestroy(() => {
        sub.unsubscribe();
      });
    } catch (e) {
      console.error(
        e +
          `StakeComponent-unstak: unstaked unsuccessfully.now tokenBalance is = ${this.tokenBalance} and stakedBalance is  = ${this.stakedBalance}`
      );
      console.log(e);
      await this.unStakeDialogService.close();
    }
  }

  private async getLPStakedTokenBalance() {
    try {
      let stakedBalance = (
        await this.opportunityService.getLPStakedBalance(
          this._opportunity,
          this.web3Service.getWalletAddress()
        )
      )?.balance;
      if (stakedBalance) {
        return Conversion.adjustFraction(
          Conversion.convertStringFromDecimal(stakedBalance.toString(), 18),
          18
        );
      }
    } catch (e) {
      console.error(
        e +
          `StakeComponent-getStakedTokenBalance: stakedBalance = ${this.stakedBalance} and now tokenBalance is = ${this.tokenBalance} and stakedBalance is  = ${this.stakedBalance}`
      );
      console.log(e);
    }
    return '0';
  }

  //index, 0: LP token, 1: Reward token
  private async confirmWithdraw(index): Promise<any> {
    this.LPState = 'withdrew';
    await this.tagManager('withdraw_confirm_farm');
    const detailsMsg =
      this.tokenIndex == 0
        ? 'unstake LPToken '
        : 'withdraw reward ' + this.amount + this.isMaxLPAmount
        ? ' and withdraw reward ' + this.allReward
        : '';
    try {
      await this.showWaitingDialog(this.tokenIndex, 'Withdraw');
      await this.tagManager('withdraw_confirmation_view_farm');

      const userAddress = this.web3Service.getWalletAddress();
      if (!userAddress) {
        return;
      }
      let unstakeTx = undefined;
      const amount = Conversion.convertStringToDecimal(
        this.amount.toString(),
        18
      ).toString();

      // Get Withdraw tx
      let currentTransaction: any;
      if (index == 0) {
        unstakeTx = await this.opportunityService.withdraw(
          this._opportunity,
          amount,
          userAddress
        );
      } else if (index == 1) {
        unstakeTx = await this.opportunityService.withdrawRewards(
          this._opportunity,
          amount,
          userAddress
        );
      }
      currentTransaction = await this.web3Service
        .sendTransaction(unstakeTx)
        .then(async (data) => {
          return data;
        })
        .catch(async (e) => {
          await this.waitingDialogService.close();
          console.error(
            e +
              `StakeComponent-confirmunStake-first: unstaked unsuccessfully and now tokenBalance is = ${this.tokenBalance} and stakedBalance is  = ${this.stakedBalance}`
          );
          console.log(e);
        });
      if (currentTransaction) {
        await this.tagManager('withdraw_confirmation_view_confirm_farm');
        await this.web3Service
          .waitForTransaction(currentTransaction, 1)
          .then(async (data) => {
            if (data?.status === 1) {
              this.opportunityService.setConsoleLog(
                this.selectedOpp,
                OpportunityState.Successful,
                'Successful ' + detailsMsg
              );
              this.updateOppDetails();
              this.tagManager('withdraw_done_farm', 'success');
              if (index !== 0) {
                await this.waitingDialogService.close();
                await this.showSuccessfulDialog();
                await this.refreshStakeData();
              }
              this.web3Service.assetChangeSubject.next(true);
            }
            if (data?.status === 0) {
              this.opportunityService.setConsoleLog(
                this.selectedOpp,
                OpportunityState.Failed,
                'Failed by status ' + detailsMsg
              );
              await this.tagManager(
                'withdraw_error_farm',
                'Something went wrong'
              );
              await this.waitingDialogService.close();
              this.showErrorToaster('Error', 'Withdrawing failed!');
            }
          });
      } else {
        await this.waitingDialogService.close();
        this.opportunityService.setConsoleLog(
          this.selectedOpp,
          OpportunityState.Failed,
          'Failed transaction ' + detailsMsg
        );
        this.showErrorToaster('Error', 'Withdrawing rejected!');
        await this.tagManager('withdraw_confirmation_view_reject_farm');
        return;
      }
      if (index === 0) {
        this.limitedInterval(
          `StakeComponent-unstake: before unstaked successfully with value = ${amount} and now tokenBalance is = ${this.tokenBalance} and stakedBalance is  = ${this.stakedBalance}`
        );
      }
    } catch (e) {
      this.opportunityService.setConsoleLog(
        this.selectedOpp,
        OpportunityState.Failed,
        'Failed in catch' + detailsMsg
      );
      console.log(e);
      console.error(
        e +
          `StakeComponent-confirmunstake-second: unstaked unsuccessfully.now tokenBalance is = ${this.tokenBalance} and stakedBalance is  = ${this.stakedBalance}`
      );
      await this.waitingDialogService.close();
    }
  }

  private limitedInterval(msg: string = '') {
    let timesRun = 0;
    let limitedInterval = setInterval(async () => {
      timesRun += 1;
      if (timesRun <= OpportunityStakeComponent.LIMITED_INTERVAL_TIME) {
        if (this.stakedBalance !== (await this.getLPStakedTokenBalance())) {
          clearInterval(limitedInterval);
          await this.refreshStakeData();
          await this.waitingDialogService.close();
          await this.showSuccessfulDialog();
          console.info(msg);
        }
      } else {
        clearInterval(limitedInterval);
        await this.waitingDialogService.close();
        this.showErrorToaster(
          'Error',
          'Please follow the result at https://polygonscan.com '
        );
      }
    }, 1000);
  }

  private async showSuccessfulDialog() {
    const data = {
      stakeAmount: this.amount,
      stakeState: this.LPState,
      isDarkMode: this.isDarkMode,
      tokenSymbol: this.tokens[this.tokenIndex],
      withdrawTokensIcon:
        this.tokenIndex === 0
          ? [this.selectedOpp.tokenA.symbol, this.selectedOpp.tokenB?.symbol]
          : [this.selectedOpp.rewardToken.symbol]
    };
    this.successfulUnStakeDialogService
      .open(SuccessfulUnStakeDialogComponent, data)
      .then(async () => {
        this.ref.detectChanges();
      });
  }

  private async getAllowance(): Promise<any> {
    if (
      !this.opportunities[this._opportunity.name].isApproveNeededForWithdraw
    ) {
      await this.changeLPStakingState(StakingButtonState.ApprovalConfirmed);
      return;
    }
    try {
      const userAddress = await this.web3Service.getWalletAddress();

      if (userAddress) {
        const allowance = await this.web3Service
          .getAllowanceBySpender(
            this.selectedOpp.opportunityType === OpportunityType.TypeCrowd
              ? this.stakingLPContractAddress
              : this.selectedOpp.contractAddress,
            this.selectedOpp.opportunityType === OpportunityType.TypeCrowd
              ? this.LPToken.address
              : this.selectedOpp.stakingLPContractAddress,
            userAddress,
            this.opportunityProvider
          )
          .catch((e) => {
            console.log(e);
            this.changeLPStakingState(StakingButtonState.ApprovalNeeded);
          });

        const amountInValue = Conversion.convertStringToDecimal(
          this.amount,
          18
        );

        if (allowance.lt(amountInValue)) {
          await this.changeLPStakingState(StakingButtonState.ApprovalNeeded);
        } else {
          await this.changeLPStakingState(StakingButtonState.ApprovalConfirmed);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.ref.detectChanges();
    }
  }

  private isStakingState(stakingState: StakingButtonState) {
    return this.stakeButtonState == stakingState;
  }

  private async changeLPStakingState(stakingState: StakingButtonState) {
    if (this.isStakingState(StakingButtonState.WalletNotConnected)) {
      await this.refreshStakeData();
      return;
    }

    this.stakeButtonState = stakingState;
  }

  async approve(): Promise<any> {
    try {
      if (this.web3Service.getCurrentChainId() != this.selectedOpp.chainId) {
        await this.web3Service.changeNetwork(this.selectedOpp.chainId);
      }
      let readyToApprove = true;
      try {
        const userAddress = this.web3Service.getWalletAddress();
        if (userAddress) {
          const data = {
            sourceTokenSymbol:
              this.selectedOpp.opportunityType === OpportunityType.TypeBeefy
                ? this.selectedOpp.proofOfInvestTokenName
                : `LP ${this.selectedOpp.asset}`
          };
          await this.waitingDialogService.open(WaitingDialogComponent, data);

          // Get approval tx
          let spenderAddress: string = '';
          let tokenAddress: string = '';
          const amount: string =
            '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

          switch (this.selectedOpp.opportunityType) {
            case 'crowd':
              spenderAddress = this.stakingLPContractAddress;
              tokenAddress = this.selectedOpp.LPToken.address;
              break;
            case 'beefy':
              spenderAddress = this.selectedOpp.contractAddress;
              tokenAddress = this.selectedOpp.stakingLPContractAddress;
              break;
            case 'pancake':
              spenderAddress = this.selectedOpp.contractAddress;
              tokenAddress = this.selectedOpp.LPToken.address;
              break;
          }
          // TODO handel this part
          if (!spenderAddress || !tokenAddress) {
            readyToApprove = false;
            return;
            //throw exception
          }
          let approvalTx =
            await this.web3Service.getApprovalTransactionBySpender(
              spenderAddress,
              tokenAddress,
              amount,
              this.opportunityProvider
            );
          // Send approval tx
          approvalTx = await this.web3Service
            .sendTransaction(approvalTx)
            .catch((e) => {
              console.log(e);
              this.changeLPStakingState(StakingButtonState.ApprovalNeeded);
              readyToApprove = false;
            });
          if (!approvalTx) {
            await this.changeLPStakingState(StakingButtonState.ApprovalNeeded);
            return false;
          }
          // Wait for approval tx confirmation
          await this.web3Service
            .waitForTransaction(approvalTx, 1)
            .then((data) => {
              if (data?.status === 1) {
                this.changeLPStakingState(StakingButtonState.ApprovalConfirmed);
              } else {
                this.changeLPStakingState(StakingButtonState.ApprovalNeeded);
              }
            })
            .catch((e) => {
              console.log(e);
              this.changeLPStakingState(StakingButtonState.ApprovalNeeded);
              readyToApprove = false;
            });
        }
      } catch (e) {
        console.log(e);
        await this.changeLPStakingState(StakingButtonState.ApprovalNeeded);
        readyToApprove = false;
      } finally {
        await this.waitingDialogService.close();
      }
      return readyToApprove;
    } catch (error) {
      console.log(error);
    }
  }

  private async showWaitingDialog(index, dialogType) {
    const data = {
      stakeState: dialogType,
      isDarkMode: this.isDarkMode,
      tokenSymbol: this.tokens[index],
      stakeAmount: this.amount
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  private async getUserPercentage() {
    const totalSupply = await this.opportunityService.getStakingLPTotalSupply(
      this._opportunity
    );

    return (
      (+this.stakedBalance /
        +Conversion.convertStringFromDecimal(totalSupply)) *
      100
    ).toString();
  }

  private async getTokenATokenBAmount() {
    const pair = await this.utilsService.getPair(
      this.opportunities[this._opportunity.name].tokenA,
      this.opportunities[this._opportunity.name].tokenB,
      this.opportunities[this._opportunity.name].dexchange,
      this.opportunityProvider
    );

    const userBalance = this.stakedBalance;
    this.tokenATokenBAmount =
      await this.opportunityService.convertLPToTokenAAndTokenB(
        pair,
        Conversion.convertStringToDecimal(userBalance),
        this._opportunity
      );
  }

  notifyAmount(event) {
    this.amountNotifier.next(event);
  }

  public async getNextReward() {
    try {
      let nextReward = await this.getNextHourReward(this.opportunityProvider);
      let stakedBalance = await this.getStakedBalance(
        this.web3Service.getWalletAddress(),
        this.opportunityProvider
      );

      if (stakedBalance) {
        nextReward = +Conversion.convertStringFromDecimal(
          nextReward.toString()
        );
        let convertedStakedBalance = +Conversion.convertStringFromDecimal(
          stakedBalance.toString()
        );
        if (convertedStakedBalance < +this.minimumStakedBalance) {
          this.nextReward = 0;
        } else {
          this.nextReward = +Conversion.adjustFraction(
            nextReward * convertedStakedBalance,
            6
          );
        }
      }
      return this.nextReward;
    } catch (e) {
      console.log(e);
    }
  }

  async getStakedBalance(
    userAddress: string | undefined,
    provider
  ): Promise<any> {
    if (!provider) {
      return undefined;
    }

    const stakeContract = this.getContract(provider);
    return stakeContract.getBalance(userAddress);
  }

  private getContract(web3Provider: Web3Provider) {
    return new ethers.Contract(
      this.selectedOpp.contractAddress,
      STAKINGREWARDS,
      web3Provider
    );
  }

  async getNextHourReward(provider) {
    if (!provider) {
      return 0;
    }
    const stakeContract = this.getContract(provider);
    return stakeContract.rewardPerHour();
  }

  public async getLastCrowdStakeTime() {
    try {
      let stakeTime = await this.opportunityService.getLastCrowdStakeTime(
        this.selectedOpp,
        this.web3Service.getWalletAddress()
      );
      this.stakeTime = +stakeTime.toString();
      return this.stakeTime;
    } catch (e) {
      console.log(e);
    }
  }

  private async tagManager(event: any, status: string = '') {
    const reward =
      this.tokenIndex === 0
        ? this.isMaxLPAmount
          ? this.allReward
          : '0'
        : this.amount;
    const rewardInUsd =
      parseFloat(reward) *
      +(await this.priceService.getPrice(
        this.selectedOpp.rewardToken,
        this.web3Service.getNetworkProvider(
          this.selectedOpp.rewardToken.chainId
        )
      ));
    const variant =
      this.tokenIndex === 0
        ? this.isMaxLPAmount
          ? 'LP and Reward'
          : 'LP'
        : 'Reward';
    await this.tagManagerService.sendWithdrawTags(
      event,
      this.selectedOpp,
      variant,
      this.amount,
      this.unstakeInUSDT.toString(),
      reward,
      rewardInUsd.toString(),
      status
    );
  }
  public changeDropDown() {
    this.amount = '0';
    this.selectFlag = !this.selectFlag;
  }

  private async checkBalance() {
    try {
      let comparisonValue: string;

      if (this.tokenIndex === 0) {
        // LP
        comparisonValue = await this.getLPStakedTokenBalance();
      } else {
        // Reward
        comparisonValue = this.allReward;
      }
      const isComparisonNegative = this.utilsService.isComparisonResultNegative(
        comparisonValue,
        this.amount
      );

      if (isComparisonNegative) {
        this.stakeButtonState = StakingButtonState.Disabled;
      } else {
        await this.getAllowance();
      }
    } catch (error) {
      // Handle any parsing errors here (e.g., invalid numeric strings)
      console.error('Error parsing numeric values:', error);
    }
  }

  updateOppDetails() {
    this.updateDetails.emit(false);
  }
}
