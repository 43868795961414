import { Conversion, NetworksById, TokensHolder } from '@crowdswap/constant';
import {
  CrossChainState,
  CrossChainStateName,
  DeviceType,
  LoggingService,
  OpportunityStateName,
  TagManagerService,
  ThemeService,
  Web3Service
} from 'src/app/services';
import { BaseComponent } from '../base.component';
import { NDDClientInfoServiceImpl } from 'src/app/services/client-info';
import { Component, OnInit } from '@angular/core';
import { EtfOpportunityState } from './model/etf-opportunity-state.enum';

@Component({
  template: ''
})
export abstract class BaseEtfComponent extends BaseComponent implements OnInit {
  public symbolsToInclude = ['BNB', 'USDC', 'USDT', 'BUSD'];

  constructor(
    web3Service: Web3Service,
    themeService: ThemeService,
    tagManagerService: TagManagerService,
    public logger: LoggingService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  public ngOnInit(
    onCurrentNetworkChange: ((currentNetwork) => void) | undefined = undefined,
    onWalletConnectionChange: ((connection) => void) | undefined = undefined,
    onAccountChange: ((address) => Promise<void>) | undefined = undefined
  ): void {
    super.ngOnInit(
      onCurrentNetworkChange,
      onWalletConnectionChange,
      onAccountChange
    );

    // this.setting.isMobile =
    //   [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
    //     this.clientInfoServiceImpl.getDeviceType()
    //   ) > -1;
  }

  public setConsoleLog(
    opp: any,
    state: EtfOpportunityState,
    msg: string = '',
    error: any = undefined,
    CCState: CrossChainState = CrossChainState.SrcInit
  ) {
    if (error) {
      this.logger.error(
        `Active opportunity= ${opp?.name} OpportunityState= ${
          OpportunityStateName[state]
        } CrossChainState= ${CrossChainStateName[CCState]} ${msg} ${
          'error is ' + JSON.stringify(error)
        }`
      );
    } else {
      this.logger.debug(
        `Active opportunity= ${opp?.name} OpportunityState= ${OpportunityStateName[state]} CrossChainState= ${CrossChainStateName[CCState]} ${msg}`
      );
    }
  }

  public getUserTokens(invest: any, selectedToken: any, target: string) {
    let investedTokens: any[] = [];
    switch (target) {
      case 'invest': {
        for (let item of invest.swaps) {
          let toToken = { symbol: '', amountOut: '' };
          if (item.fromToken) {
            let tempToken =
              TokensHolder.TokenListByAddress[
                NetworksById[selectedToken.chainId]
              ][item.toToken];
            toToken.symbol = tempToken.symbol!;
            toToken.amountOut = this.getAmount(
              item.amountOut,
              tempToken.decimals
            );
          } else {
            toToken.symbol = selectedToken.symbol || '';
            toToken.amountOut = this.getAmount(
              item.amountOut,
              selectedToken.decimals
            );
          }
          investedTokens.push(toToken);
        }
        break;
      }
      case 'withdraw': {
        for (let item of invest) {
          let token = { symbol: '', amountOut: '' };
          token.symbol = item.token.symbol;
          token.amountOut = item.amountPercentToDisplay;
          investedTokens.push(token);
        }

        break;
      }
    }
    return investedTokens;
  }

  public getAmount(amount: string, decimal: number, fraction: number = 6) {
    return Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(amount, decimal),
      fraction
    );
  }

  public normalizeSymbol(symbol: string | undefined): string {
    if (!symbol) {
      return '';
    }

    return symbol === 'BTCB' ? 'BTC' : symbol;
  }
}
