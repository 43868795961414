<div
  (click)="onEstimationClick($event)"
  [ngClass]="{
    'animate-pulse': estimation?.loading,
    selected: !bestPrice && estimation === selectedEstimation
  }"
>
  <div
    [ngClass]="{
      'selected-bestPrice': !estimation?.loading && bestPrice,
      'qa-mode': isSelected && estimation === selectedEstimation
    }"
    class="estimation-item-best border border-2 border-gray-200 dark:border-0 bg-white dark:bg-1E1E1E"
    id="estimationItem"
  >
    <div
      id="savePercentage"
      *ngIf="
        !estimation?.loading &&
        bestPrice &&
        estimationExtraValue.youSavePercentage !== '0.00' &&
        !estimationExtraValue.youSavePercentage?.startsWith('N')
      "
      class="tag"
    >
      Save %{{ estimationExtraValue.youSavePercentage }}
    </div>
    <div class="row">
      <div
        [ngClass]="{ 'd-sm-flex items-center': !bestPrice }"
        class="col-5 col-sm-6 col-md-6 col-xl-3 col-xxl-3"
      >
        <div
          id="divDexDisplayNameContainer"
          class="font-openSans-medium dark:text-white"
        >
          <label
            id="lblDexDisplayName"
            [ngClass]="{
              'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
            }"
          >
            {{ bestPrice ? "CrowdSwap" : estimation?.dex?.displayName }}
          </label>
        </div>
        <div>
          <svg
            *ngIf="bestPrice && !estimation?.loading"
            class="ok"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              fill-rule="evenodd"
            />
          </svg>
          <span *ngIf="bestPrice" class="best-price" id="best-price">
            <label
              id="lblBestPrice"
              [ngClass]="{
                'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
              }"
            >
              BEST PRICE
            </label>
          </span>
        </div>
      </div>
      <div
        id="divYouGetContainer"
        class="col-7 col-sm-6 col-md-6 col-xl-4 col-xxl-4"
      >
        <section>
          <div id="you-get" class="title">
            <label
              [ngClass]="{
                'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
              }"
            >
              You get
            </label>
          </div>
          <span
            [ngClass]="bestPrice ? 'dark:text-white' : 'dark:text-darkGrayText'"
            class="number"
          >
            <label
              id="lblYouGetAmount"
              [ngClass]="{
                'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
              }"
            >
              {{ estimationExtraValue.formattedAmountOut }}
            </label>
          </span>
          <span
            [ngClass]="bestPrice ? 'dark:text-white' : 'dark:text-darkGrayText'"
            class="eth"
          >
            <label
              id="lblYouGetTokenSymbol"
              [ngClass]="{
                'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
              }"
            >
              {{ estimation?.toToken?.symbol }}
            </label>
          </span>
          <span class="icon scaled">
            <img
              *ngIf="!estimation?.loading"
              class="rounded-full"
              [src]="
                baseLogoIconsUrl +
                estimation?.toToken?.symbol?.toLowerCase() +
                '.png'
              "
              (error)="updateUrl($event)"
            />
          </span>
        </section>
      </div>
      <div class="col-5 col-sm-6 col-md-6 col-xl-2 col-xxl-2">
        <section>
          <div class="title">
            <label
              [ngClass]="{
                'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
              }"
            >
              Total paid
            </label>
          </div>
          <span
            [ngClass]="bestPrice ? 'dark:text-white' : 'dark:text-darkGrayText'"
            class="number"
          >
            <label
              [ngClass]="{
                'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
              }"
            >
              {{
                !estimationExtraValue.totalPaidToDisplay?.startsWith("N")
                  ? estimationExtraValue.totalPaidToDisplay
                  : "Unknown"
              }}
            </label>
          </span>
        </section>
      </div>
      <div class="col-5 col-sm-5 col-md-4 col-xl-2 col-xxl-2">
        <section>
          <div class="title">
            <label
              [ngClass]="{
                'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
              }"
            >
              Cost
            </label>
          </div>
          <span
            [ngClass]="bestPrice ? 'dark:text-white' : 'dark:text-darkGrayText'"
            class="number"
          >
            <label
              [ngClass]="{
                'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
              }"
            >
              {{
                estimationExtraValue.cost !== ""
                  ? "%" + estimationExtraValue.cost
                  : "Unknown"
              }}
            </label>
          </span>
        </section>
      </div>
      <div
        *ngIf="!bestPrice"
        class="col-2 col-sm-1 col-md-2 col-xl-1 col-xxl-1 flex items-center"
      >
        <svg
          *ngIf="!(estimation === selectedEstimation)"
          class="h-5 w-5 text-gray-300"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 9l-7 7-7-7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
        <svg
          *ngIf="estimation === selectedEstimation"
          class="h-5 w-5 text-gray-300"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 15l7-7 7 7"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      </div>
    </div>
  </div>
  <div *ngIf="bestPrice || estimation === selectedEstimation">
    <div class="view-detail ps-md-4 pe-md-3">
      <div class="row">
        <div class="col-4 col-sm-6 col-md-2 col-xl-2 col-xxl-2">
          <section>
            <div class="title">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                DEX fee
              </label>
            </div>
            <span class="number dark:text-textWhite">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                {{ estimation?.swapFeeInUSDTToDisplay }}
              </label>
            </span>
          </section>
        </div>
        <div class="col-4 col-sm-6 col-md-2 col-xl-2 col-xxl-2">
          <section>
            <div class="title">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                Network cost
              </label>
            </div>
            <span class="number dark:text-textWhite">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                {{ estimation?.networkFeeInUSDTToDisplay }}
              </label>
            </span>
          </section>
        </div>
        <div class="col-4 col-sm-6 col-md-2 col-xl-2 col-xxl-2">
          <section>
            <div class="title">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                Crowd fee
              </label>
            </div>
            <span class="number dark:text-textWhite">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                {{ estimation?.crowdswapFeeInUSDTToDisplay }}
              </label>
            </span>
          </section>
        </div>
        <div class="col-4 col-sm-6 col-md-2 col-xl-2 col-xxl-2">
          <section>
            <div class="title">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                Total fee
              </label>
            </div>
            <span class="number dark:text-textWhite">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                {{ estimation?.totalFeeInUSDTToDisplay }}
              </label>
            </span>
          </section>
        </div>
        <div
          id="youSave"
          *ngIf="bestPrice && estimationExtraValue.youSave"
          class="col-4 col-sm-6 col-md-2 col-xl-2 col-xxl-2"
        >
          <section>
            <div class="title">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                You save
              </label>
            </div>
            <span class="number dark:text-textWhite">
              <label
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
                }"
              >
                {{ estimationExtraValue.youSave }}
              </label>
            </span>
          </section>
        </div>
      </div>
      <div class="inline-block w-full mt-3">
        <span *ngIf="estimation?.loading" class="float-left">
          <label
            [ngClass]="{
              'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
            }"
          >
            Crowd swap is loading dex ...
          </label>
        </span>
        <span *ngIf="!estimation?.loading" class="float-left">
          <img
            *ngIf="!isDarkMode"
            alt="Route token"
            src="assets/media/icons/rout-black.png"
          />
          <img
            *ngIf="isDarkMode"
            alt="Route token"
            src="assets/media/icons/rout-white.png"
          />
        </span>
        <span *ngIf="!estimation?.loading" class="rout"> Route </span>
        <ng-container *ngIf="!estimation?.loading">
          <label
            [ngClass]="{
              'bg-gray-300 text-gray-300 rounded-sm': estimation?.loading
            }"
          >
            <span class="name dark:text-darkGrayText">
              {{ estimation?.route }}
            </span>
          </label>
        </ng-container>
      </div>
    </div>
  </div>
</div>
