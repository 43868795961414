import { Component, OnInit } from '@angular/core';
import { EstimateTrade, SearchParamModel } from '../../../model';
import { BaseSwapComponent } from '../cross-and-same-chain-swap/base-swap.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  searchParams: SearchParamModel | undefined = undefined;
  bestEstimation: EstimateTrade = BaseSwapComponent.getDummyEstimationTrade();

  constructor() {}

  ngOnInit(): void {}

  onSearchParamsChanged(searchParam: SearchParamModel) {
    this.searchParams = searchParam;
  }

  public onBestEstimationChanged(bestEstimation: EstimateTrade) {
    this.bestEstimation = bestEstimation;
  }
}
