import { CrowdToken } from '@crowdswap/constant';
import { BigNumber } from 'ethers';

export interface ETFPlanViewModel {
  id: number;
  name: string;
  chainId: number;
  active: boolean;
  exists: boolean;
  tokenShares: ETFTokenShareViewModel[];
}

export interface ETFTokenShareViewModel {
  token: CrowdToken;
  share: number;
}

export interface IETFInvest {
  id: number;
  planId: number;
  createTime: number;
  tokenDetails: ITokenDetail[];
}

export interface ITokenDetail {
  token: string;
  amount: BigNumber;
  price: BigNumber;
}

export interface IETFOpportunityItem {
  tokenId?: number;
  planId: number;
  planName: string;
  etfName: string;
  chainId: number;
  active: boolean;
  contractAddress: string;
  nftAddress: string;
  opportunityType: string;
  createdDate: Date;
  createdDateToDisplay: string;
  tokenShares: {
    share: number;
    token: CrowdToken;
    tvl: number;
    investedAmountUSD: number;
    investedAmountUSDToDisplay: string;
    amount: number;
    amountToDisplay: string;
    profit: number;
    profitToDisplay: string;
    investPrice: number;
  }[];
  totalTVL: number;
  totalTVLToDisplay: string;
  totalInvestedAmountUSD?: number;
  totalInvestedAmountUSDToDisplay?: string;
  totalProfit?: number;
  totalProfitToDisplay?: string;
  type?: string;
  Network?: string;
  TVL?: number;
  displayName?: string;
}

export interface IEtfInvestDisplayAmount {
  investAmountInUsd: string;
  minInvestAmountInUsd: string;
  providersFee: number;
  providersFeeToDisplay: string;
  platformFee: number;
  platformFeeToDisplay: string;
  withdrawFee: string;
  networkFee: string;
  networkFeeToDisplay: string;
  totalFee: string;
}

export class EtfInvestDisplayAmount implements IEtfInvestDisplayAmount {
  investAmountInUsd = '--';
  minInvestAmountInUsd = '--';
  networkFee = '--';
  networkFeeToDisplay = '--';
  platformFee = 0;
  platformFeeToDisplay = '--';
  finalPlatformFee = 0;
  finalPlatformFeeToDisplay = '--';
  providersFee = 0;
  providersFeeToDisplay = '--';
  totalFee = '--';
  withdrawFee = '--';
}
