<app-dialog>
  <div class="flex justify-start"></div>
  <div class="divide-y">
    <div>
      <div class="flex justify-center mt-20-px">
        <svg
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="90" height="90" rx="45" fill="#1BD37F" />
          <g
            clip-path="url(#clip0_6642_101274)"
            filter="url(#filter0_d_6642_101274)"
          >
            <path
              d="M57.5013 34.5834L54.5638 31.6459L41.3555 44.8542L44.293 47.7917L57.5013 34.5834ZM66.3346 31.6459L44.293 53.6875L35.5846 45L32.6471 47.9375L44.293 59.5834L69.293 34.5834L66.3346 31.6459ZM20.8555 47.9375L32.5013 59.5834L35.4388 56.6459L23.8138 45L20.8555 47.9375Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_6642_101274"
              x="20"
              y="20"
              width="51.1401"
              height="51.1401"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="0.570033" dy="0.570033" />
              <feGaussianBlur stdDeviation="0.285016" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_6642_101274"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_6642_101274"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_6642_101274">
              <rect
                width="50"
                height="50"
                fill="white"
                transform="translate(20 20)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="flex justify-center mt-3 mb-4 tx-submitted dark:text-white">
        <p>Your Funds Sent Successfully!</p>
      </div>
      <div class="flex-column justify-center mt-3">
        <div class="flex justify-center dark:text-white">
          You sent {{ data?.destValue }} {{ data?.tokenSymbol }} to this
          address:
        </div>
        <div
          class="p-1 mt-2 rounded-sm font-12 bg-blue-100 text-0066FF dark:text-3385FF flex justify-center"
        >
          {{ data?.receiverWalletAddress }}
        </div>
      </div>
      <a
        href="{{ data?.scanUrl }}"
        target="_blank"
        class="flex justify-center mt-3"
      >
        <div class="flex text-0066FF dark:text-3385FF font-14 font-bold pr-3 justify-center">
          View your transaction
        </div>
        <img src="assets/media/icons/check-address.svg" alt="scan" />
      </a>
    </div>
  </div>
</app-dialog>
