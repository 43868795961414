<div
  class="swap-box z-0 bg-white dark:bg-121212 xl:ml-12 2xl:ml-12 dark:border-1E1E1E dark:border-2"
  id="swapBox"
  [style]="swapStyle"
>
  <div class="swap-header text-gray-400 dark:text-white">
    <span class="title"> Swap </span>

    <span class="setting" (click)="settingDialog()">
      <img
        *ngIf="!isDarkMode"
        alt="setting token"
        src="assets/media/icons/setting-black.svg"
      />
      <img
        *ngIf="isDarkMode"
        alt="setting token"
        src="assets/media/icons/setting-white.svg"
      />
    </span>

    <span class="refresh">
      <img
        *ngIf="!isDarkMode"
        (click)="refreshSearch()"
        nz-tooltip
        [nzTooltipTitle]="refreshTooltip"
        alt="refresh token"
        src="assets/media/icons/refresh-black.svg"
      />

      <img
        *ngIf="isDarkMode"
        (click)="refreshSearch()"
        nz-tooltip
        [nzTooltipTitle]="refreshTooltip"
        alt="refresh token"
        src="assets/media/icons/refresh-white.svg"
      />

      <ng-template #refreshTooltip>
        <div class="text-sm text-center text-white bg-black">
          <span>Reset search</span>
        </div>
      </ng-template>
    </span>

    <span class="refresh">
      <circle-progress
        *ngIf="hasAutoSearchSubscriber"
        (click)="switchRefreshSearch($event)"
        [percent]="countDownTimer"
        nz-tooltip
        [nzTooltipTitle]="circleRefresh"
        [radius]="7"
        [outerStrokeWidth]="2"
        [outerStrokeColor]="'#0B9ED9'"
        [animation]="true"
        [animationDuration]="1"
      ></circle-progress>

      <img
        *ngIf="!hasAutoSearchSubscriber && !isDarkMode"
        (click)="switchRefreshSearch($event)"
        alt="refresh token off"
        src="assets/media/icons/refresh-outline-black.svg"
        nz-tooltip
        [nzTooltipTitle]="circleRefresh"
      />

      <img
        *ngIf="!hasAutoSearchSubscriber && isDarkMode"
        (click)="switchRefreshSearch($event)"
        alt="refresh token off"
        src="assets/media/icons/refresh-outline-white.svg"
        nz-tooltip
        [nzTooltipTitle]="circleRefresh"
      />

      <ng-template #circleRefresh>
        <div class="text-sm text-center text-white">
          <span>{{
            hasAutoSearchSubscriber ? "Auto refresh countdown" : "Auto refresh"
          }}</span>
        </div>
      </ng-template>
    </span>
  </div>
  <app-token-selection
    (onPairSelected)="onPairSelected($event)"
    [estimation]="estimation"
  >
  </app-token-selection>
  <div class="flex mt-2 dark:text-white text-xs-2 items-center">
    <input
      id="isReceiverWalletSelected"
      [(ngModel)]="isReceiverWalletSelected"
      class="mr-1 cursor-pointer"
      type="checkbox"
      (change)="anotherReceiverChecked()"
    />
    <label class="m-0 p-0 cursor-pointer" for="isReceiverWalletSelected"
      >Send the swapped token to another address</label
    >
  </div>
  <div *ngIf="isReceiverWalletSelected">
    <div class="rounded-md warning-color mt-1 py-2">
      <div class="p-2 flex text-xs-2">
        <div class="pr-1">
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.975 2.75H14.025C14.3 2.75 14.4833 2.84167 14.6667 3.025L18.975 7.33333C19.1583 7.51667 19.25 7.7 19.25 7.975V14.025C19.25 14.3 19.1583 14.4833 18.975 14.6667L14.6667 18.975C14.4833 19.1583 14.3 19.25 14.025 19.25H7.975C7.7 19.25 7.51667 19.1583 7.33333 18.975L3.025 14.6667C2.84167 14.4833 2.75 14.3 2.75 14.025V7.975C2.75 7.7 2.84167 7.51667 3.025 7.33333L7.33333 3.025C7.51667 2.84167 7.7 2.75 7.975 2.75Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.0013 7.33203V10.9987"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.0006 14.6654H11.0098"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="text-000">
          Please ensure that the address you've entered is on the same network
          as selected on the app header (<span class="font-bold">{{
            web3Service.networkSpec[web3Service.getCurrentChainId()].title
          }}</span
          >). Any mismatch between the address and network will result in a loss
          of funds.

          <!-- Please ensure that you've entered the correct address and ensure that
          the network is the same as the token's network (<span
            class="font-bold"
            >{{
              web3Service.networkSpec[web3Service.getCurrentChainId()].title
            }}</span
          >). Double-check the address and network to avoid any potential loss
          of funds. -->
        </div>
      </div>
    </div>
    <div
      class="mt-2 text-xs-2"
      [ngClass]="
        isReceiverWalletSelected
          ? receiverAddress !== '' &&
            (receiverAddress === web3Service.getWalletAddress() ||
              !isAddressValid)
            ? 'text-ef4444'
            : 'text-3b82f6'
          : 'text-d1d5db'
      "
    >
      <label for="walletAddress">Wallet address</label>
      <input
        id="walletAddress"
        [(ngModel)]="receiverAddress"
        (ngModelChange)="isAddress()"
        [readOnly]="!isReceiverWalletSelected"
        [ngClass]="
          !isReceiverWalletSelected
            ? 'border-d1d5db'
            : receiverAddress !== '' &&
              (receiverAddress === web3Service.getWalletAddress() ||
                !isAddressValid)
            ? 'border-ef4444 text-ef4444'
            : 'border-3b82f6'
        "
        #receiverWalletAddress
        class="font-bold w-full text-xs-2 p-2 border-2px rounded-sm focus:outline-none dark:bg-darkBlack"
      />
    </div>
    <div
      *ngIf="
        !isAddressValid && isReceiverWalletSelected && receiverAddress !== ''
      "
      class="text-xs-2 mt-2 text-red-500"
    >
      The address is not valid!
    </div>
    <div
      *ngIf="
        isReceiverWalletSelected &&
        receiverAddress === web3Service.getWalletAddress()
      "
      class="font-12 mt-2 text-ef4444"
    >
      Wallet address already connected to the app. Please enter a different
      address to send the swapped token
    </div>
    <a
      *ngIf="receiverAddress && isAddressValid"
      target="_blank"
      href="{{ web3Service.getScanAddressUrl(receiverAddress) }}"
      class="text-xs-2 text-blue-500 flex justify-end items-center mt-2 space-x-2"
    >
      <div class="blue-color">Check address</div>
      <div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16536 5.83203H4.9987C4.07822 5.83203 3.33203 6.57822 3.33203 7.4987V14.9987C3.33203 15.9192 4.07822 16.6654 4.9987 16.6654H12.4987C13.4192 16.6654 14.1654 15.9192 14.1654 14.9987V10.832"
            stroke="#0066FF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.33203 11.6654L16.6654 3.33203"
            stroke="#0066FF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.5 3.33203H16.6667V7.4987"
            stroke="#0066FF"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </a>
  </div>
  <div
    *ngIf="
      this.swapState === SwapState.ApprovalNeeded &&
      !isWrongNetwork &&
      !isMismatchNetwork
    "
    class="flex flex-col mt-9"
  >
    <!--mt-4-->
    <button
      id="btnSwapAllow"
      (click)="approve()"
      class="btn-swap-allow blue-button hover:bg-blue-700"
    >
      <svg
        class="bi bi-exclamation-circle text-white"
        fill="currentColor"
        height="20"
        viewBox="0 0 16 16"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
        />
        <path
          d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
        />
      </svg>
      <span>Allow to use your {{ estimation.fromToken.symbol }}</span>
    </button>
  </div>

  <div
    ngClass="{{
      swapState === SwapState.ApprovalConfirmed
        ? 'p-1 mt-2 rounded-md cursor-pointer approve-confirmed-bg text-gray-100 dark:bg-gray-100 dark:text-gray-800 '
        : ''
    }}"
  >
    <div
      *ngIf="
        swapState !== SwapState.WalletNotConnected &&
        swapState !== SwapState.Expired &&
        swapState !== SwapState.InsufficientSourceBalance &&
        !isWrongNetwork &&
        !isMismatchNetwork
      "
      class="flex flex-col mt-20px"
      [ngClass]="{
        'bg-323232 p-4px border-radius-8px':
          swapState === SwapState.ApprovalConfirmed
      }"
    >
      <!--Now you can trade-->
      <div
        *ngIf="swapState === SwapState.ApprovalConfirmed"
        class="w-full mb-1 d-flex align-items-center justify-content-center"
      >
        <div class="font-14 text-fff me-2 flex items-center">
          Now you can swap
          {{ estimation.fromToken.symbol ?? "" }}
          for
          {{ estimation.toToken.symbol ?? "" }}
        </div>

        <svg
          width="18"
          height="10"
          viewBox="0 0 18 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.3337 10L14.242 8.09167L10.1753 4.025L6.84199 7.35833L0.666992 1.175L1.84199 0L6.84199 5L10.1753 1.66667L15.4253 6.90833L17.3337 5V10H12.3337Z"
            fill="white"
          />
        </svg>
      </div>

      <button
        (click)="swap()"
        id="btnSwapToken"
        class="btn-swap-token blue-button hover:bg-blue-700 dark:bg-darkBlueButton border-radius-8px"
        [ngClass]="{
          disable:
            swapState === SwapState.ApprovalNeeded ||
            !estimation.fromToken.symbol ||
            (isReceiverWalletSelected &&
              (!isAddressValid ||
                receiverAddress === '' ||
                receiverAddress === web3Service.getWalletAddress()))
        }"
      >
        <span
          *ngIf="
            isReceiverWalletSelected && receiverAddress === '';
            else elseBlock
          "
          >Wallet address is empty!</span
        >
        <ng-template #elseBlock>Swap token</ng-template>
      </button>
    </div>
  </div>

  <div
    *ngIf="
      swapState === SwapState.InsufficientSourceBalance &&
      !isWrongNetwork &&
      !isMismatchNetwork
    "
    class="flex flex-col mt-2"
  >
    <button id="btnSwapInsufficient" class="insufficient-button">
      Insufficient {{ searchParams?.fromToken?.symbol ?? "" }} balance
    </button>
  </div>
  <div
    *ngIf="swapState === SwapState.WalletNotConnected"
    class="flex flex-col mt-4 mb-3"
  >
    <button
      (click)="connectWallet()"
      class="blue-button font-openSans-medium py-2 px-4 rounded h-52 flex justify-center text-white text-sm items-center text-center hover:bg-blue-700"
    >
      <span>Connect wallet</span>
    </button>
  </div>
  <div *ngIf="swapState === SwapState.Expired" class="flex flex-col mt-4 mb-3">
    <button
      (click)="refreshSearch()"
      class="blue-button font-openSans-medium py-2 px-4 rounded h-52 flex justify-center text-white text-sm items-center text-center hover:bg-blue-700"
    >
      <span>Restart Estimation</span>
    </button>
  </div>
  <div
    *ngIf="isWrongNetwork || isMismatchNetwork"
    (click)="web3Service.changeToCorrectNetwork()"
    class="flex flex-col mt-4 mb-3"
  >
    <button
      class="btn-wrong-network font-openSans-medium py-2 px-4 rounded-md h-52 flex justify-center text-white text-sm items-center text-center"
    >
      <div
        nz-tooltip
        [nzTooltipTitle]="wrongNetworkTooltip"
        class="absolute right-8"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="20"
          height="20"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            fill="white"
            d="M12 1.999c5.524 0 10.002 4.478 10.002 10.002c0 5.523-4.478 10.001-10.002 10.001c-5.524 0-10-4.478-10-10.001C1.999 6.477 6.476 1.999 12 1.999Zm-.004 8.25a1 1 0 0 0-.992.885l-.007.116l.004 5.502l.006.117a1 1 0 0 0 1.987-.002L13 16.75l-.004-5.501l-.007-.117a1 1 0 0 0-.994-.882Zm.005-3.749a1.251 1.251 0 1 0 0 2.503A1.251 1.251 0 0 0 12 6.5Z"
          />
        </svg>

        <ng-template #wrongNetworkTooltip>
          <div class="font-openSans-regular text-sm text-center text-white">
            <span>
              {{ incorrectNetworkTooltipText }}
            </span>
          </div>
        </ng-template>
      </div>

      <div class="font-openSans-semiBold">
        {{ incorrectNetworkMessage }} &nbsp;
      </div>
    </button>
  </div>
</div>
