export enum EtfOpportunityState {
  Init,
  WalletNotConnected,
  ApprovalConfirmed,
  InsufficientTokenBBalance,
  InsufficientTokenBalance,
  AmountInIsTooLow,
  WrongNetwork,
  Confirmed,
  Rejected,
  ApprovalNeeded,
  ApprovalRejected,
  Failed,
  Successful,
  FailedEstimation,
  NoTransactionFound
}
