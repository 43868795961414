import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AffiliateService } from '../affiliate.service';
import { Web3Service } from '../web3.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private web3Service: Web3Service
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const userToken = this.cookieService.get(AffiliateService.AUTH_KEY);
    let modifiedReq = request;
    if (userToken) {
      modifiedReq = modifiedReq.clone({
        headers: modifiedReq.headers.set('Authorization', `${userToken}`)
      });
    }

    if (this.web3Service.isConnected()) {
      let wallet = this.web3Service.getWalletAddress();
      if (wallet) {
        wallet =
          wallet.substring(0, wallet.length / 3) +
          '********' +
          wallet.substring(wallet.length / 3 + 8, wallet.length);

        modifiedReq = modifiedReq.clone({
          headers: modifiedReq.headers.set('wallet', `${wallet}`)
        });
      }
    }

    return next.handle(modifiedReq);
  }
}
