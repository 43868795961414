import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { FormsModule } from '@angular/forms';
import { LoggingService } from '../../../../services/log/service/logging.service';

@Component({
  selector: 'app-confirm-swap-dialog',
  templateUrl: './confirm-swap-dialog.component.html',
  styleUrls: ['./confirm-swap-dialog.component.scss']
})
export class ConfirmSwapDialogComponent extends DialogsComponent {
  @Output()
  confirmed = new EventEmitter<{ state: boolean; isUnusual: boolean }>();
  public isUnusualConfirmed: boolean = false;

  constructor(private loggingService: LoggingService) {
    super();
  }

  confirm() {
    if (this.isUnusualConfirmed) {
      this.loggingService.log(
        `source token is : ${this.data?.sourceTokenSymbol} & and amountIn is : ${this.data?.amountIn} for destination token is : ${this.data?.tokenSymbol} & and amountOut is : ${this.data?.amountOut}`
      );
    }
    this.confirmed.emit({ state: true, isUnusual: !this.isUnusualConfirmed });
  }

  close() {
    this.confirmed.emit({ state: false, isUnusual: !this.isUnusualConfirmed });
  }

  isSourcePriceHigh() {
    if (this.data?.sourcePrice) {
      return +this.data.sourcePrice >= 100000;
    }
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule],
  declarations: [ConfirmSwapDialogComponent],
  exports: [ConfirmSwapDialogComponent]
})
export class ConfirmSwapDialogComponentModule {}
