import { NgModule } from '@angular/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

@NgModule({
  imports: [],
  exports: [
    NzTableModule,
    NzSkeletonModule,
    NzButtonModule,
    NzSpaceModule,
    NzDividerModule,
    NzToolTipModule,
    NzProgressModule,
    NzCollapseModule,
    NzSwitchModule,
    NzPopconfirmModule,
    NzModalModule,
    NzRadioModule,
    NzInputModule,
    NzDropDownModule,
    NzTagModule,
    NzMessageModule,
    NzCheckboxModule,
    NzAffixModule,
    NzTabsModule,
    NzFormModule,
    NzStatisticModule,
    NzDrawerModule
  ],
  providers: []
})
export class AntDesignModule {}
