<div class="container my-4 py-2 px-3 px-lt-24px">
  <div class="row">
    <div
      class="col-12 col-sm-12 col-lg-4 col-xl-4 col-xxl-4"
      id="swapContainer"
    >
      <app-swap
        (searchParamsChanged)="onSearchParamsChanged($event)"
        [bestEstimation]="bestEstimation"
      >
      </app-swap>
    </div>
    <div
      id="divEstimationContainer"
      class="col-12 col-sm-12 col-lg-8 col-xl-7 col-xxl-7 estimation-overflow"
    >
      <app-estimation
        (bestEstimationChanged)="onBestEstimationChanged($event)"
        [searchParams]="searchParams"
      >
      </app-estimation>
    </div>
  </div>
</div>
