import { Injectable } from '@angular/core';
import { Web3Service } from './web3.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoggingService } from './log';
import { Conversion, CrowdToken, PriceService } from '@crowdswap/constant';
import { TokensService } from './tokens.service';
import { Builder } from 'builder-pattern';
import { CurrentNetwork } from '../model/current-network';
import { filter, throttleTime, timeout } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';
import { StakeService } from './stake.service';
import { MainNetworksById } from '@crowdswap/constant/dist/utils/networks/networks';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = environment.PORTFOLIO_BASEURL || '';

@Injectable()
export class PortfolioService {
  private _tokenInfoSubject: BehaviorSubject<{
    loading: boolean;
    totalValue: number;
    tokens: CrowdToken[];
  }>;

  private _investmentInfoSubject: BehaviorSubject<{
    loading: boolean;
    totalInvestment: number;
    opportunities: any[];
  }>;

  public tokenBalanceChangeSubject: Subject<CrowdToken[]>;
  private loading = false;

  constructor(
    private web3Service: Web3Service,
    private stakeService: StakeService,
    private priceService: PriceService,
    private tokensService: TokensService,
    private logger: LoggingService,
    private http: HttpClient
  ) {
    this._tokenInfoSubject = new BehaviorSubject<{
      loading: boolean;
      totalValue: number;
      tokens: CrowdToken[];
    }>({
      loading: true,
      totalValue: NaN,
      tokens: this.getDummyTokens()
    });

    this._investmentInfoSubject = new BehaviorSubject<{
      loading: boolean;
      totalInvestment: number;
      opportunities: any[];
    }>({
      loading: true,
      totalInvestment: NaN,
      opportunities: this.getDummyOpportunities()
    });

    this.tokenBalanceChangeSubject = new Subject();

    // Listen to account change
    web3Service.accountChangeSubject.subscribe(async () => {
      if (
        !this.web3Service.isConnected() ||
        this.web3Service.getCurrentChainId() < 1
      ) {
        return;
      }
    });

    // Listen to network change
    web3Service.currentNetworkChangeSubject
      .pipe(
        filter((currentNetwork: CurrentNetwork) => {
          return currentNetwork.chainId > 0;
        }),
        throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
      )
      .subscribe(async () => {
        if (
          !this.web3Service.isConnected() ||
          this.web3Service.getCurrentChainId() < 1
        ) {
          return;
        }
      });

    // Listen to asset change
    web3Service.assetChangeSubject.subscribe(async () => {
      if (
        this.web3Service.isConnected() &&
        this.web3Service.getCurrentChainId() > 0
      ) {
        this.emitTokenInfo();
      }
    });
  }

  private async addValueToTokens(tokens: CrowdToken[]): Promise<CrowdToken[]> {
    const promises: any[] = [];
    for (const token of tokens) {
      try {
        if (+token.balance === 0) {
          continue;
        }
        promises.push(
          this.priceService.getPrice(
            token,
            this.web3Service.getNetworkProvider(token.chainId)
          ),
          token
        );
      } catch (error: any) {
        this.logger.error(
          `PortfolioService: Error in getting token price, ${
            error.message || error
          }`
        );
      }
    }
    let promiseResult = await Promise.allSettled(promises);
    promiseResult.forEach((item, index, array) => {
      if (item && item.status === 'fulfilled' && item.value > 0) {
        const itemArray: any = array[index + 1];
        for (const token of tokens) {
          if (token.address === itemArray.value.address) {
            token.price = item.value || token.price;
            if (token.price) {
              token.value =
                this.priceService.getTokenValueByAmount(
                  token,
                  Conversion.convertStringToDecimal(
                    token.balance,
                    token.decimals
                  ).toString()
                ) || '0';
            }
          }
        }
      }
    });
    return tokens;
  }

  public async emitTokenInfo() {
    if (
      this.loading ||
      !this.web3Service.isConnected() ||
      this.web3Service.getCurrentChainId() < 1
    ) {
      return;
    }
    this.loading = true;
    this._tokenInfoSubject.next({
      loading: true,
      totalValue: NaN,
      tokens: this.getDummyTokens()
    });

    let allTokens = this.tokensService.getAllTokens();

    const result = await this.addBalanceToTokens(allTokens, true);
    const tokensWithBalance = result.result;
    const totalValue = result.totalValue;

    this._tokenInfoSubject.next({
      loading: false,
      totalValue: totalValue,
      tokens: tokensWithBalance
    });
    this.loading = false;
  }

  public async getNextReward(opportunity: any) {
    try {
      const provider =
        opportunity.chainId === this.web3Service.getCurrentChainId()
          ? this.web3Service.web3Provider
          : this.web3Service.getNetworkProvider(opportunity.chainId);
      let nextReward = await this.stakeService.getNextHourReward(
        opportunity,
        provider
      );
      let stakedBalance = await this.stakeService.getStakedBalance(
        opportunity,
        this.web3Service.getWalletAddress(),
        provider
      );

      if (stakedBalance) {
        nextReward = +Conversion.convertStringFromDecimal(
          nextReward.toString()
        );
        let convertedStakedBalance = +Conversion.convertStringFromDecimal(
          stakedBalance.toString()
        );
        if (convertedStakedBalance < 1) {
          nextReward = 0;
        } else {
          nextReward = +Conversion.adjustFraction(
            nextReward * convertedStakedBalance,
            6
          );
        }
      }
      return nextReward;
    } catch (e) {
      console.log(e);
      return 0;
    }
  }

  public getTokenInfoSubject(): BehaviorSubject<{
    loading: boolean;
    totalValue: number;
    tokens: CrowdToken[];
  }> {
    return this._tokenInfoSubject;
  }

  public getInvestmentInfoSubject(): BehaviorSubject<{
    loading: boolean;
    totalInvestment: number;
    opportunities: any[];
  }> {
    return this._investmentInfoSubject;
  }

  private getDummyTokens() {
    const dummyToken = Builder<CrowdToken>()
      .price('--------')
      .balance('--------')
      .value('--------')
      .build();
    return [dummyToken, dummyToken, dummyToken];
  }

  public getDummyOpportunities() {
    const dummyOpportunity = {
      displayName: '--------',
      chainId: '-----',
      network: '-----',
      tokenA: {
        symbol: '--------'
      },
      tokenB: {
        symbol: '--------'
      },
      asset: '--------',
      iconUrl: '--------',
      displayTvl: '--------',
      opportunityType: '--------',
      lpStaked: '--------',
      stakeReward: '--------'
    };
    return [dummyOpportunity, dummyOpportunity, dummyOpportunity];
  }

  public async addBalanceToTokens(
    tokens: any,
    withBalance: boolean = false
  ): Promise<{ result: CrowdToken[]; totalValue: number }> {
    const promises: any[] = [];
    let result: any[] = [];

    for (const chainId in MainNetworksById) {
      if (environment.ACTIVE_NETWORK.includes(chainId)) {
        let tempTokens = tokens.filter(
          (token: CrowdToken) => token.chainId.toString() === chainId
        );
        promises.push(this.web3Service.addBalanceToTokens(tempTokens));
      }
    }

    let promiseResult = await Promise.allSettled(promises);
    promiseResult.forEach((item, index, array) => {
      if (item && item.status === 'fulfilled' && item.value.length > 0) {
        const list = item.value.filter((x) => parseFloat(x.balance) !== 0);
        result.push(...list);
      }
    });

    if (withBalance) {
      result = result.filter((token: CrowdToken) => {
        return token.balance && token.balance != '' && +token.balance > 0;
      });
    }

    let totalValue = 0;
    await result.map(async (item) => {
      try {
        item.price = await this.priceService.getPrice(
          item,
          this.web3Service.getNetworkProvider(item.chainId)
        );
        item.value = parseFloat(item.balance) * parseFloat(item.price);
        totalValue += isNaN(item.value) ? 0 : item.value;
      } catch (e) {
        console.log(e);
      }
    });

    return { result, totalValue };
  }

  public async getUnreadNotifications(
    walletAddress: string
  ): Promise<[] | null> {
    try {
      const url = `${baseUrl}/api/v1/notifications/notif-unReadList?walletAddress=${walletAddress}`;

      return this.http
        .get<[]>(url, {
          observe: 'response'
        })
        .toPromise()
        .then((response) => response!.body);
    } catch (err) {
      console.error(
        `An error occurred during getting user's notifications, message: ${err}`
      );
    }
    throw new Error(`Unable to get user's notifications`);
  }

  public async seenNotification(notificationId: string) {
    try {
      const url = `${baseUrl}/api/v1/notifications/${notificationId}/status?status=read`;

      return this.http
        .patch(url, {
          observe: 'response'
        })
        .toPromise();
    } catch (err) {
      console.error(
        `An error occurred during set status of user's notification, message: ${err}`
      );
    }
    throw new Error(`Unable to set user's notification status`);
  }

  async getReceiverInfo(
    address: string,
    chainId: number,
    walletAddress: string
  ): Promise<any> {
    try {
      let history: any = [];
      const url = `${baseUrl}/api/v1/transfer/transfer-asset`;

      const data = {
        receiverAddress: address,
        chainId: chainId,
        userAddress: walletAddress
      };
      history = await this.http
        .get(url, {
          params: data
        })
        .toPromise();
      return history;
    } catch (err: any) {
      console.log(`Cannot fetch user transaction history`);
    }
  }
}
