<div
  class="swap-token-box border-2 border-gray-200 dark:bg-1E1E1E dark:border-0 md:ml-3 sm:ml-3 xl:ml-3 2xl:ml-3"
>
  <div class="swap-token-header">
    <span class="title text-gray-400 dark:text-darkGrayText"> From </span>

    <span *ngIf="isConnected()" class="count">
      <button *ngIf="fromBalance !== '0'" (click)="setMaxBalance()">Max</button>
    </span>

    <span
      *ngIf="isConnected()"
      class="balance text-gray-400 dark:text-darkGrayText"
    >
      Balance: {{ fromBalance }}
    </span>
  </div>

  <div class="flex justify-content-between mt-2">
    <div>
      <button
        id="source-option"
        (click)="openSelectOptionDialog(tokenList, fromTokenList, toToken)"
        aria-expanded="false"
        aria-haspopup="true"
        class="justify-center rounded-lg pl-1 pr-1 border-0 bg-white text-sm font-medium text-gray-700 dark:bg-1E1E1E"
        type="button"
      >
        <span class="token-symbol">
          <img
            class="rounded-full"
            width="20"
            [src]="baseLogoIconsUrl + fromToken?.symbol?.toLowerCase() + '.png'"
            (error)="updateUrl($event)"
          />
        </span>

        <div class="token-selected dark:text-white">
          <div id="divFromTokenSymbol" class="symbol">
            {{ fromToken?.symbol }}
          </div>
        </div>

        <div class="token-arrow dark:text-textWhite">
          <svg
            class="-mr-1 h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              fill-rule="evenodd"
            />
          </svg>
        </div>

        <br />

        <span class="text-gray-400 justify-left text-left text-xs-2">
          <div class="name float-left">
            {{ fromToken?.name }}
          </div>
        </span>
      </button>
    </div>

    <div class="absolute right-5 mr-4 w-1/2 md:w-1/3 lg:w-5/12">
      <div
        [ngClass]="dollarBase ? 'input-symbol-dollar dark:text-textWhite' : ''"
      >
        <input
          appFocusAndSelectValue
          [el]="txtSwapInputAmount"
          #txtSwapInputAmount
          type="text"
          inputmode="decimal"
          id="txtSwapInputAmount"
          (blur)="isSelected = false"
          (click)="isSelected = true"
          (ngModelChange)="notifySearch($event)"
          [(ngModel)]="swapAmount"
          [ngClass]="dollarBase ? 'pe-2' : ''"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
          class="font-bold w-full text-base text-right shadow-none outline-none border-0 p-0 font-16 bg-transparent dark:text-fff"
        />
      </div>

      <span
        *ngIf="!swapSearchValue.startsWith('N')"
        class="text-gray-400 float-right text-xs-2"
        [ngClass]="{ 'bg-gray-300 text-gray-300 rounded-sm': loadingAmountOut }"
      >
        ~{{ dollarBase ? swapSearchValue : swapSearchValueToDisplay }}
      </span>

      <span
        *ngIf="swapSearchValue.startsWith('N')"
        class="text-gray-400 float-right text-xs-2"
      >
        Unknown
      </span>
    </div>
  </div>
</div>

<div
  id="divSwitchTokens"
  (click)="switchTokens()"
  [ngClass]="{ selected: isSelected }"
  class="arrow-change-token border border-2 border-gray-200 hover:bg-gray-100 dark:hover:bg-hoverBackground bg-white dark:text-fff  dark:border-595961 dark:bg-121212 dark:border-1"
>
  <img
    *ngIf="!isDarkMode"
    alt="switch token"
    src="assets/media/icons/switch.png"
  />
  <img
    *ngIf="isDarkMode"
    alt="switch token"
    src="assets/media/icons/switchDark.png"
  />
</div>

<div
  [ngClass]="{ selected: isSelected }"
  class="swap-token-box border-2 border-gray-200 dark:border-0 dark:bg-1E1E1E dark:border-0"
>
  <div class="swap-token-header">
    <span class="title text-gray-400 dark:text-darkGrayText"> To </span>

    <span
      *ngIf="isConnected()"
      class="balance text-gray-400 dark:text-darkGrayText"
    >
      Balance: {{ toBalance }}
    </span>
  </div>

  <div class="flex justify-content-between mt-2">
    <div>
      <button
        id="destination-option"
        (click)="openSelectOptionDialog(tokenList, toTokenList, fromToken)"
        aria-expanded="false"
        aria-haspopup="true"
        class="justify-center rounded-lg pl-1 pr-1 border-0 bg-white text-sm font-medium text-gray-700 dark:bg-1E1E1E"
        type="button"
      >
        <span class="token-symbol">
          <img
            class="rounded-full"
            width="20"
            [src]="baseLogoIconsUrl + toToken?.symbol?.toLowerCase() + '.png'"
            (error)="updateUrl($event)"
          />
        </span>

        <div class="token-selected dark:text-white">
          <div id="dviToTokenSymbol" class="symbol">
            {{ toToken?.symbol }}
          </div>
        </div>

        <div class="token-arrow dark:text-textWhite">
          <svg
            class="-mr-1 h-5 w-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              fill-rule="evenodd"
            />
          </svg>
        </div>

        <br />

        <span class="text-gray-400 justify-left text-left text-xs-2">
          <div class="name float-left">
            {{ toToken?.name }}
          </div>
        </span>
      </button>
    </div>

    <div [ngClass]="{ 'animate-pulse': loadingAmountOut }">
      <div class="font-bold text-base text-right dark:text-white">
        <label
          id="lblSwapOutputAmount"
          [ngClass]="{
            'bg-gray-300 text-gray-300 rounded-sm': loadingAmountOut
          }"
        >
          {{ swapAmountOut }}
        </label>
      </div>

      <div
        *ngIf="totalDestinationPaid && !loadingAmountOut"
        class="text-gray-400 items-center flex float-right text-xs-2"
      >
        <svg
          *ngIf="
            lostPercentage &&
            lostPercentage <= warningPriceImpactPercent &&
            lostPercentage >= highPriceImpactPercent
          "
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="#fb923c"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m10.24 3.957l-8.422 14.06A1.989 1.989 0 0 0 3.518 21h16.845a1.989 1.989 0 0 0 1.7-2.983L13.64 3.957a1.989 1.989 0 0 0-3.4 0zM12 9v4m0 4h.01"
          />
        </svg>

        <svg
          *ngIf="lostPercentage && lostPercentage <= highPriceImpactPercent"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="red"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m10.24 3.957l-8.422 14.06A1.989 1.989 0 0 0 3.518 21h16.845a1.989 1.989 0 0 0 1.7-2.983L13.64 3.957a1.989 1.989 0 0 0-3.4 0zM12 9v4m0 4h.01"
          />
        </svg>

        <span
          [ngClass]="
            lostPercentage && lostPercentage <= highPriceImpactPercent
              ? 'text-red-500'
              : lostPercentage && lostPercentage <= warningPriceImpactPercent
              ? 'warning-color'
              : lostPercentage && lostPercentage <= 0
              ? ''
              : 'text-green-400'
          "
        >
          {{ "(" + lostPercentage + "%)" }}
        </span>

        <span>
          {{ "~" + estimation?.amountOutInUSDTToDisplay }}
        </span>
      </div>
    </div>
  </div>
</div>

<div
  class="swap-token-amount"
  [ngClass]="{ 'animate-pulse': loadingAmountOut }"
>
  <label
    *ngIf="loadingAmountOut"
    [ngClass]="{ 'bg-gray-300 text-gray-300 rounded-sm': loadingAmountOut }"
  >
    Amount out is loading to estimate ...
  </label>

  <div *ngIf="!loadingAmountOut && swapAmountOutPerAmountInRatio">
    1
    {{ toToken.symbol }} = {{ swapAmountOutPerAmountInRatio }}
    {{ fromToken.symbol }}

    <span *ngIf="estimation?.toTokenPriceToDisplay">
      (~{{ estimation?.toTokenPriceToDisplay }})
    </span>
  </div>
</div>
