import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Conversion,
  CrowdToken,
  Networks,
  PriceService,
  TokensHolder
} from '@crowdswap/constant';
import {
  CrowdSwapService,
  DeviceType,
  NavigatorService,
  ConnectWalletService,
  NDDClientInfoServiceImpl,
  PortfolioService,
  TagManagerService,
  ThemeService,
  TokensService,
  Web3Service
} from '../../../services';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import {
  CurrentNetwork,
  DialogDataModel,
  IExecuteReqMdl,
  IGetTransferReqMdl,
  SmartWalletConfirmation
} from '../../../model';
import { BaseComponent } from '../base.component';
import { NumberType, formatNumber } from '@uniswap/conedison/format';

import { CrossChainSelectOptionDialogComponent } from '../../modal/dialogs/cross-chain-select-option-dialog/cross-chain-select-option-dialog.component';
import { ModalService } from '../../modal/modal.service';
import { Constants } from '../../../constants';

import { BigNumber } from 'ethers';
import { WaitingDialogComponent } from '../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { TransferSuccessfulDialogComponent } from '../../modal/dialogs/transfer-successful-dialog/transfer-successful-dialog.component';
import { environment } from '../../../../environments/environment';
import { Token } from '@crowdswap/sdk';
import { IConnectWalletRespMdl } from '../../modal/dialogs/connect-wallet-dialog/connect-wallet-dialog.component';
import { SmartWalletConfirmationDialogComponent } from '../../modal/dialogs/smart-wallet-confirmation/smart-wallet-confirmation.component';

export interface receiverInfo {
  networkCoinBalance: any;
  address: string;
  firstTransactionDate: string | undefined;
  lastTransactionType: string | undefined;
  lastTransactionValue: string;
  lastTransactionDate: string | undefined;
  lastTransactionToken: string;
  confirmed: boolean;
  tokenBalance: any;
  hasActivityInLastThreeMonths: boolean;
  hasTransactionInLastThreeMonths: boolean;
  isWalletOld: boolean;
  isWalletAddress: boolean;
}

export interface SecurityAlert {
  title: string;
  condition: any;
}

@Component({
  selector: 'app-transfer-assets',
  templateUrl: './transfer-assets.component.html',
  styleUrls: ['./transfer-assets.component.scss']
})
export class TransferAssetsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  private allTokens: any;
  private amountNotifier = new Subject<number>();
  private addressNotifier = new Subject<string>();
  private isUserSelectedToken: boolean = false;

  public token;
  public transferAmount: number = 1;
  public transferValueInUsd: string = '';

  public receiverInfo: receiverInfo = {
    networkCoinBalance: '',
    address: '',
    firstTransactionDate: undefined,
    lastTransactionDate: undefined,
    lastTransactionType: undefined,
    lastTransactionValue: '',
    lastTransactionToken: '',
    confirmed: false,
    tokenBalance: '',
    hasActivityInLastThreeMonths: false,
    hasTransactionInLastThreeMonths: false,
    isWalletOld: false,
    isWalletAddress: false
  };
  public securityAlerts: SecurityAlert[] = [];
  public loading: boolean = false;

  public status: {
    chainId;
    supportedNetwork: boolean;
    walletAddress: string;
    isUserWalletConnected: boolean;
    isWrongNetwork: boolean;
    incorrectNetworkMessage: string;
    dialogOpened: boolean;
    isAddressValid: boolean;
  } = {
    chainId: -1,
    supportedNetwork: false,
    walletAddress: '',
    isUserWalletConnected: false,
    isWrongNetwork: false,
    incorrectNetworkMessage: '',
    dialogOpened: false,
    isAddressValid: true
  };

  public confirmModal!: import('ng-zorro-antd/modal').NzModalRef<
    SmartWalletConfirmationDialogComponent,
    any
  >;

  constructor(
    private crossChainSelectOptionDialogComponentModalService: ModalService<CrossChainSelectOptionDialogComponent>,
    private route: ActivatedRoute,
    public activatedRoute: ActivatedRoute,
    private tokensService: TokensService,
    public web3Service: Web3Service,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    protected connectWalletService: ConnectWalletService,
    public priceService: PriceService,
    protected waitingDialogService: ModalService<WaitingDialogComponent>,
    protected transferSuccessfulDialogService: ModalService<TransferSuccessfulDialogComponent>,
    private toastr: ToastrService,
    private portfolioService: PortfolioService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    private navigatorService: NavigatorService,
    public ref: ChangeDetectorRef
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
    this.setting.isMobile =
      [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
        this.clientInfoServiceImpl.getDeviceType()
      ) > -1;
  }

  async ngOnInit() {
    super.ngOnInit();

    this.allTokens = this.tokensService.getAllTokens();

    this.token =
      (await this.checkUrlParams()) || (await this.updateNetworkCoin(1));

    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          })
        )
        .subscribe(async (currentNetwork) => {
          if (!this.isUserSelectedToken) {
            this.token = await this.updateNetworkCoin(currentNetwork.chainId);
          }
          if (this.receiverInfo.address) {
            await this.getReceiverInfo(this.receiverInfo.address, this.token);
          }
          this.transferValueInUsd = await this.calculateValueInUsd(
            this.token,
            this.transferAmount
          );
        })
    );

    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async (address) => {
        const tokenBalance = await this.web3Service.getBalance(this.token);
        if (tokenBalance) {
          this.token.balance = parseFloat(
            Conversion.convertStringFromDecimal(
              tokenBalance.toString(),
              this.token.decimals
            )
          );
          this.token.balanceToDisplay = formatNumber(
            this.token.balance,
            NumberType.TokenNonTx
          );
        } else {
          this.token.balanceToDisplay = 0;
        }
        await this.getReceiverInfo(this.receiverInfo.address, this.token);
      })
    );

    this.amountNotifier
      .pipe(
        debounceTime(CrowdSwapService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async (value) => {
        await this.changeTransferAmount(value);
      });

    this.addressNotifier
      .pipe(
        debounceTime(CrowdSwapService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async (value) => {
        await this.getReceiverInfo(value, this.token);
      });
  }

  public ngOnDestroy(): void {
    this.confirmModal?.destroy();
    this.subscriptionList.forEach((element) => {
      element.unsubscribe();
    });
  }

  private async checkUrlParams() {
    let token;
    this.activatedRoute.queryParams.forEach((params) => {
      token = this.getTokenByAddress(params.tokenAddress, params.tokenChainId);
    });
    if (!token) {
      return undefined;
    }
    token.balanceToDisplay = formatNumber(token.balance, NumberType.TokenNonTx);
    if (token.balanceToDisplay == 0) {
      const tokenBalance = await this.web3Service.getBalance(token);
      if (tokenBalance) {
        const tokenBalanceNumber = tokenBalance?.toNumber();
        token.balanceToDisplay = formatNumber(
          tokenBalanceNumber,
          NumberType.TokenNonTx
        );
      } else {
        token.balanceToDisplay = 0;
      }
    }

    this.isUserSelectedToken = true;
    return token;
  }

  private getTokenByAddress(address?: string, chainId?: string) {
    if (!address || !chainId) {
      return null;
    }
    for (const token of this.allTokens) {
      if (
        token.address.toLowerCase() === address.toLowerCase() &&
        token.chainId.toString() === chainId
      ) {
        return token;
      }
    }
  }

  async openSelectOptionDialog() {
    const allTokens = this.allTokens;

    allTokens.filter((item) => {
      return parseFloat(item.balance) > 0;
    });

    const tokensWithBalance = allTokens.filter(
      (x) => parseFloat(x.balance) !== 0
    );

    const data: DialogDataModel = {
      filteredTokenList: tokensWithBalance,
      toggle: true,
      chainId: -1,
      className: 'cross-chain-token-selection-dialog',
      currentTokenSymbol: this.token?.symbol
    };

    let selectOptionDialogRef =
      await this.crossChainSelectOptionDialogComponentModalService.open(
        CrossChainSelectOptionDialogComponent,
        data
      );

    const sub: Subscription = (<CrossChainSelectOptionDialogComponent>(
      (<any>selectOptionDialogRef!.instance)
    )).confirmed.subscribe(async (token: CrowdToken) => {
      await this.crossChainSelectOptionDialogComponentModalService.close();
      if (token !== this.token) {
        await this.getReceiverInfo(this.receiverInfo.address, token);
      }
      this.token = token;
      this.isUserSelectedToken = true;
      await this.changeTransferAmount(1);
    });

    selectOptionDialogRef!.onDestroy(() => {
      sub.unsubscribe();
    });
  }

  async setMaxBalance(token: CrowdToken) {
    let tokenBalance = await this.web3Service.getBalance(
      token,
      this.web3Service.getNetworkProvider(token.chainId)
    );
    if (tokenBalance) {
      let maxAmountIn = +Conversion.convertStringFromDecimal(
        tokenBalance.toString(),
        token.decimals
      );

      this.setting.showMaxTooltip = maxAmountIn > 0;

      if (TokensHolder.isBaseToken(token.chainId, token.address)) {
        maxAmountIn -=
          maxAmountIn < Constants.REDUCE_MAX_AMOUNT_IN
            ? maxAmountIn
            : Constants.REDUCE_MAX_AMOUNT_IN;
      }
      await this.changeTransferAmount(maxAmountIn);
    }
  }

  public notifyAmount(event) {
    this.amountNotifier.next(event);
  }

  public notifyAddress(event) {
    this.isAddress(event);
    this.addressNotifier.next(event);
  }

  private async calculateValueInUsd(
    token: CrowdToken,
    amount: number
  ): Promise<string> {
    if (!token) {
      return 'NaN';
    }
    token.price = await this.priceService.getPrice(
      token,
      this.web3Service.getNetworkProvider(token.chainId)
    );
    if (token.price) {
      return formatNumber(
        parseFloat(Conversion.adjustFraction(token.price, 8)) * amount,
        NumberType.FiatTokenPrice
      );
    } else {
      return 'NaN';
    }
  }

  private async changeTransferAmount(amount: number) {
    if (!amount) {
      return;
    }

    this.transferAmount = parseFloat(Conversion.adjustFraction(amount, 7));
    this.transferValueInUsd = await this.calculateValueInUsd(
      this.token,
      amount
    );
  }

  async confirmTransferAsset(token: CrowdToken) {
    if (
      !this.web3Service.usingCrowdWallet &&
      token.chainId !== this.web3Service.getCurrentChainId()
    ) {
      await this.web3Service.changeNetwork(token.chainId);
    }

    const receiverAddress = this.receiverInfo.address;
    const amount = this.transferAmount.toString();

    let transferTx;
    const data = {
      stakeState: 'Transferr',
      stakeAmount: amount,
      tokenSymbol: token.symbol,
      isDarkMode: this.isDarkMode
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
    // Get transfer tx
    if (!this.web3Service.usingCrowdWallet) {
      transferTx = await this.web3Service.getTransferTransaction(
        token,
        receiverAddress,
        amount
      );
      // Send transfer tx
      transferTx = await this.web3Service
        .sendTransaction({
          ...transferTx,
          value: TokensHolder.isBaseToken(token.chainId, token.address)
            ? Conversion.convertStringToDecimal(
                amount,
                token.decimals
              ).toString()
            : 0
        })
        .catch(async (e) => {
          this.showErrorToastr('Transfer rejected!');

          await this.waitingDialogService.close();

          console.log(e);
        });

      await this.waitForTransaction(transferTx);
    } else {
      const transferData: IGetTransferReqMdl = {
        token: token.address,
        destination: receiverAddress,
        amount: Conversion.convertStringToDecimal(
          amount,
          token.decimals
        ).toString(),
        chainId: token.chainId,
        email: '',
        passwordHash: ''
      };

      const transferResult = await this.connectWalletService?.getTransfer(
        transferData
      );

      if (!transferResult) {
        return;
      }

      const executeTx: IExecuteReqMdl = {
        email: '',
        passwordHash: '',
        transactions: transferResult?.request?.transactions,
        chainId: token.chainId,
        gasLimit: transferResult.request.gasLimit,
        gasPrice: transferResult.request.gasPrice
      };

      this.confirmModal = this.connectWalletService.openConfirmModal(
        this.isDarkMode,
        {
          executeTx: executeTx,
          type: SmartWalletConfirmation.Transfer,
          fees: transferResult.fees,
          fromToken: token,
          amount: amount,
          destination: receiverAddress
        }
      );

      this.confirmModal.afterClose.subscribe(async (resp: boolean) => {
        if (resp) {
          transferTx = await this.connectWalletService?.execute(executeTx);
          await this.waitForTransaction(transferTx, token.chainId);
        }

        await this.waitingDialogService.close();
      });
    }
  }

  private async waitForTransaction(transferTx, chainId?: number) {
    if (transferTx) {
      const scanTransactionUrl = this.web3Service.getScanTransactionUrl(
        transferTx,
        chainId
      );

      // Wait for approval tx confirmation
      await this.web3Service
        .waitForTransaction(transferTx, 1)
        .then(async (data) => {
          if (data?.status === 1) {
            await this.waitingDialogService.close();

            const data: DialogDataModel = {
              tokenSymbol: this.token.symbol,
              destValue: this.transferAmount,
              receiverWalletAddress: this.receiverInfo.address,
              scanUrl: this.web3Service.getScanTransactionUrl(
                transferTx,
                this.token.chainId
              )
            };

            await this.transferSuccessfulDialogService.open(
              TransferSuccessfulDialogComponent,
              data
            );
            this.showSuccessToastr(scanTransactionUrl);
            const tokenBalance = await this.web3Service.getBalance(this.token);
            if (tokenBalance) {
              this.token.balance = Conversion.convertStringFromDecimal(
                tokenBalance.toString(),
                this.token.decimals
              );
            }
            this.token.balanceToDisplay = formatNumber(
              this.token.balance,
              NumberType.TokenNonTx
            );
            await this.getReceiverInfo(this.receiverInfo.address, this.token);
            this.ref.detectChanges;
          } else {
            this.showFailedToastr(scanTransactionUrl);
          }
        })
        .catch(async (e) => {
          await this.waitingDialogService.close();
          this.showErrorToastr('Transfer rejected!');
          await this.waitingDialogService.close();
          console.log(e);
        });
    }
  }

  private async getReceiverInfo(
    address: string,
    token: CrowdToken
  ): Promise<any> {
    if (!address) {
      this.status.isAddressValid = true;
      return;
    }

    if (
      !this.status.isAddressValid ||
      this.receiverInfo.address === this.web3Service.getWalletAddress()
    ) {
      return;
    }
    this.loading = true;
    this.receiverInfo.confirmed = false;
    this.securityAlerts = [
      { title: '', condition: false },
      { title: '', condition: false },
      { title: '', condition: false },
      { title: '', condition: false }
    ];

    const isWallet = await this.web3Service
      .getNetworkProvider(this.token.chainId)
      .getCode(this.receiverInfo.address);
    this.receiverInfo.isWalletAddress = isWallet === '0x';
    const balance = await this.getNetworkCoinBalanceByUserAddress(
      token.chainId,
      this.receiverInfo.address
    );
    if (balance) {
      this.receiverInfo.networkCoinBalance = formatNumber(
        parseFloat(
          Conversion.convertStringFromDecimal(balance.toString(), 18).toString()
        ),
        NumberType.TokenNonTx
      );
    }
    const tokenBalance = await this.web3Service.getBalanceByUserAddress(
      token,
      this.receiverInfo.address,
      this.web3Service.getNetworkProvider(token.chainId)
    );
    if (tokenBalance) {
      this.receiverInfo.tokenBalance = formatNumber(
        parseFloat(
          Conversion.convertStringFromDecimal(
            tokenBalance.toString(),
            token.decimals
          ).toString()
        ),
        NumberType.TokenNonTx
      );
    }

    try {
      const result: any = await this.portfolioService.getReceiverInfo(
        address,
        token.chainId,
        this.status.walletAddress
      );

      this.receiverInfo.hasActivityInLastThreeMonths =
        result.isActiveLastThreeMonths;
      this.receiverInfo.isWalletOld = result.isWalletOld;
      this.receiverInfo.hasTransactionInLastThreeMonths =
        result.hasTxWithUserInLastThreeMonths;

      this.securityAlerts = [
        {
          title: 'Active within the last 3 months',
          condition: this.receiverInfo.hasActivityInLastThreeMonths
        },
        {
          title: 'Has previously transacted with your wallet',
          condition: this.receiverInfo.hasTransactionInLastThreeMonths
        },
        {
          title: 'Wallet is older than a month',
          condition: this.receiverInfo.isWalletOld
        },
        {
          title: 'Not a smart contract address',
          condition: this.receiverInfo.isWalletAddress
        }
      ];
      this.receiverInfo.confirmed =
        this.receiverInfo.hasTransactionInLastThreeMonths &&
        this.receiverInfo.hasActivityInLastThreeMonths &&
        this.receiverInfo.isWalletOld &&
        this.receiverInfo.isWalletAddress;

      this.receiverInfo.firstTransactionDate = result.firstTxData
        ? new Date(result.firstTxData.timeStamp * 1000)
            .toISOString()
            .split('T')[0]
        : 'No transaction yet';
      this.receiverInfo.lastTransactionDate = result.lastTxData
        ? new Date(result.lastTxData.timeStamp * 1000)
            .toISOString()
            .split('T')[0]
        : 'No transaction yet';

      const fnName = result.lastTxData
        ? result.lastTxData.functionName
        : undefined;
      this.receiverInfo.lastTransactionType = fnName
        ? fnName.split('(')[0]
        : undefined;
    } catch (err) {
      console.error(`Unable to get receiver data with reason: ${err}`);
    }
    this.loading = false;
  }

  async getNetworkCoinBalanceByUserAddress(
    chainId: number,
    address: string
  ): Promise<BigNumber | undefined> {
    const inUseProvider = this.web3Service.getNetworkProvider(
      TokensHolder.ObservableTokenListBySymbol[Networks[chainId]][
        this.web3Service.networkSpec[chainId]?.coin
      ].chainId
    );
    if (!inUseProvider) {
      return undefined;
    }

    return inUseProvider?.getBalance(address) || 0;
  }

  private showSuccessToastr(txUrl: string) {
    this.toastr.success(
      `<a href='${txUrl}' target='_blank'>View in explorer</a>`,
      `Successful transfer for ${this.transferAmount} ${this.token!.symbol} `,
      {
        closeButton: true,
        tapToDismiss: false,
        progressBar: true,
        positionClass: 'custom-toast-top-right',
        enableHtml: true,
        timeOut: 10000,
        messageClass: 'successClass'
      }
    );
  }

  private showFailedToastr(txUrl: string) {
    this.toastr.error(
      `<a href='${txUrl}' target='_blank'>View in explorer</a>`,
      `Transfer failed`,
      {
        closeButton: true,
        tapToDismiss: false,
        progressBar: true,
        positionClass: 'custom-toast-top-right',
        enableHtml: true,
        timeOut: 10000,
        messageClass: 'failedClass'
      }
    );
  }

  private showErrorToastr(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  private async updateNetworkCoin(chainId: number) {
    const token =
      TokensHolder.ObservableTokenListBySymbol[Networks[chainId]][
        this.web3Service.networkSpec[chainId]?.coin
      ];
    const balance = await this.web3Service.getBalance(
      token,
      this.web3Service.getNetworkProvider(chainId)
    );
    if (balance) {
      token.balance = Conversion.convertStringFromDecimal(
        balance.toString(),
        token.decimals
      );
      token.balanceToDisplay = formatNumber(
        parseFloat(token.balance),
        NumberType.TokenNonTx
      );
    }
    return token;
  }

  async close() {
    await this.navigatorService.navigate('portfolio', {});
  }

  public isAddress(address) {
    this.status.isAddressValid = this.web3Service.isAddress(address);
  }

  public auto_grow(element: HTMLElement) {
    element.style.height = '5px';
    element.style.height = element.scrollHeight + 'px';
  }
}
