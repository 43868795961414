import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import {
  Dexchanges,
  NetworksById,
  PriceService,
  TokensHolder
} from '@crowdswap/constant';
import {
  CrowdSwapService,
  TagManagerService,
  Web3Service
} from '../../../services';
import { environment } from '../../../../environments/environment';
import { EstimateTrade, SearchParamModel } from '../../../model';
import { NotFoundException } from '../../../exception/not-found.exception';
import { ServerException } from '../../../exception/server.exception';
import { BaseSwapComponent } from '../cross-and-same-chain-swap/base-swap.component';

export enum SearchState {
  Init = 0,
  StartSearching = 2,
  FinishSearching = 3
}

@Component({
  selector: 'app-estimation[searchParams]',
  templateUrl: './estimation.component.html',
  styleUrls: ['./estimation.component.scss']
})
export class EstimationComponent implements OnInit, OnDestroy {
  isViewMore: boolean = false;
  searchState: SearchState = SearchState.Init;
  SearchState = SearchState;

  dexes = Object.values(Dexchanges).filter((dex) => dex.observable);
  estimations: EstimateTrade[] = [];
  // after getting all estimations, the compareWithEstimation has value, but the selectedEstimation is undefined
  // estimation which is used to compare with the best estimation
  compareWithEstimation: EstimateTrade | undefined = undefined;
  // estimation which is shown
  selectedEstimation: EstimateTrade | undefined = undefined;

  getEstimationSubscription: Subscription | undefined = undefined;
  searchParam$ = new BehaviorSubject<SearchParamModel | undefined>(undefined);
  @Output()
  bestEstimationChanged = new EventEmitter<EstimateTrade>();
  public errorMessage: string | undefined = undefined;
  public isNoPairFound: boolean = false;

  @Input()
  set searchParams(searchParam: SearchParamModel | undefined) {
    this.searchParam$.next(searchParam);
  }

  constructor(
    private ref: ChangeDetectorRef,
    private tagManagerService: TagManagerService,
    private cookieService: CookieService,
    private web3Service: Web3Service,
    private priceService: PriceService,
    private crowdSwapService: CrowdSwapService
  ) {}

  ngOnInit(): void {
    this.searchParam$.subscribe(
      async (searchParam: SearchParamModel | undefined) => {
        if (!searchParam) {
          return;
        }
        this.searchState = SearchState.Init;

        const { durationBeforeExpiration, isAutoRefreshEnabled } =
          this.readCookies();

        const gasPrice = await this.web3Service.getGasPrice(
          this.web3Service.getNetworkProvider(searchParam.fromToken.chainId)
        );

        searchParam.fromToken.price = await this.priceService.getPrice(
          searchParam.fromToken,
          this.web3Service.getNetworkProvider(searchParam.fromToken.chainId)
        );

        searchParam.toToken.price = await this.priceService.getPrice(
          searchParam.toToken,
          this.web3Service.getNetworkProvider(searchParam.toToken.chainId)
        );

        const chainId = searchParam.fromToken.chainId;

        const networkCoinInUSDT = await this.priceService.getPrice(
          TokensHolder.TokenListBySymbol[NetworksById[chainId]][
            this.web3Service.networkSpec[chainId].coin
          ],
          this.web3Service.getNetworkProvider(chainId)
        );

        searchParam.gasPrice = gasPrice?.toString();
        searchParam.durationBeforeExpiration = durationBeforeExpiration || 120;
        searchParam.networkCoinInUSDT = networkCoinInUSDT;
        searchParam.isAutoRefreshEnabled =
          isAutoRefreshEnabled !== undefined ? isAutoRefreshEnabled : false;
        searchParam.userAddress =
          this.web3Service.getWalletAddress() || undefined;
        await this.search(searchParam);

        this.ref.detectChanges();
      }
    );
  }

  ngOnDestroy(): void {
    this.searchParam$ && this.searchParam$.unsubscribe();
  }

  private readCookies() {
    return {
      swapValue: +this.cookieService.get('swapValue'),
      durationBeforeExpiration: this.cookieService.get(
        'durationBeforeExpiration'
      )
        ? +this.cookieService.get('durationBeforeExpiration')
        : undefined,
      isAutoRefreshEnabled: this.cookieService.get('isAutoRefreshEnabled')
        ? this.cookieService.get('isAutoRefreshEnabled') === '1'
        : undefined
    };
  }

  /**
   * ***Important**
   * Don't ues await in the body of search method. The function body must be run atomic.
   * @param searchParam
   * @private
   */
  private async search(searchParam: SearchParamModel) {
    try {
      // Cancel previous requests
      this.getEstimationSubscription &&
        this.getEstimationSubscription.unsubscribe();
      this.getEstimationSubscription = undefined;

      // invalidate the previous best estimation
      this.bestEstimationChanged.emit(undefined);
      this.compareWithEstimation = undefined;

      this.searchState = SearchState.StartSearching;

      this.errorMessage = undefined;
      this.isNoPairFound = false;

      this.estimations = [
        BaseSwapComponent.getDummyEstimationTrade(),
        BaseSwapComponent.getDummyEstimationTrade()
      ];

      this.ref.detectChanges();

      // await this.$gtmService.pushTag({
      //   event: 'start_search',
      //   category: 'search',
      //   sourceTokenSymbol: searchParam.fromToken.symbol,
      //   destinationTokenSymbol: searchParam.toToken.symbol,
      //   amount: searchParam.swapValue,
      //   isConnected: this.web3Service.isConnected(),
      // });

      //Fetches available dexes from server

      this.getEstimationSubscription = this.crowdSwapService
        .getAllSwapEstimation(searchParam)
        .subscribe(
          async (estimateTradeList: EstimateTrade[]) => {
            this.estimations = estimateTradeList;

            this.handleCrowdSwapAggregatorEstimation();
            this.handleCrowdSwapDexEstimation();

            await this.tagManagerService.sendSwapSearchStartTags(
              searchParam.fromToken.symbol,
              searchParam.toToken.symbol,
              this.estimations[0].dex.displayName
            );

            // Choose the best and compareWith estimations
            this.bestEstimationChanged.emit(this.estimations[0]);
            this.estimations[1] &&
              (this.compareWithEstimation = this.estimations[1]);

            this.errorMessage = undefined;

            this.searchState = SearchState.FinishSearching;
            this.ref.detectChanges();
          },
          (error) => {
            this.isNoPairFound = true;
            if (error instanceof NotFoundException) {
              this.errorMessage = 'No estimation found for the given pair';
              this.isNoPairFound = false;
            } else if (error instanceof ServerException) {
              this.errorMessage =
                'Sorry for the inconvenience, server is under maintenance. Please try again in a few minutes.';
            } else {
              this.errorMessage = 'Something went wrong';
            }
            this.setConsoleLog(this.errorMessage);
            this.searchState = SearchState.FinishSearching;
            this.ref.detectChanges();
          }
        );
    } catch (e) {
      console.log(e);
      this.searchState = SearchState.FinishSearching;
    }
  }

  private handleCrowdSwapAggregatorEstimation() {
    let crowdswapEstimation: EstimateTrade | undefined = this.estimations.find(
      (estimationTrade: EstimateTrade) => {
        return (
          estimationTrade.dex.name === Dexchanges.CrowdswapAggregatorV3.name
        );
      }
    );

    // In production mode, only a valid crowdswap estimation is allowed to be added to the result
    if (crowdswapEstimation) {
      const addingCrowdswapEstimationIsAllowed =
        !environment.production || // in production mode
        ((this.estimations[0] === crowdswapEstimation ||
          this.estimations[0].pricePerToken ===
            crowdswapEstimation.pricePerToken) && // crowdswap estimation is the best
          !this.estimations.filter((estimation) => {
            return (
              estimation.routes === crowdswapEstimation?.routes &&
              estimation.dex.name === crowdswapEstimation?.delegatedDex
            ); // no similar route exists
          }));

      if (!addingCrowdswapEstimationIsAllowed) {
        this.estimations.splice(
          this.estimations.indexOf(crowdswapEstimation),
          1
        );
      }
    }
  }

  private handleCrowdSwapDexEstimation() {
    let crowdswapEstimation: EstimateTrade | undefined = this.estimations.find(
      (estimationTrade: EstimateTrade) => {
        return estimationTrade.dex.name === Dexchanges.Crowdswap.name;
      }
    );
    if (crowdswapEstimation) {
      const addingCrowdswapEstimationIsAllowed =
        !environment.production || this.estimations[0] === crowdswapEstimation;
      if (!addingCrowdswapEstimationIsAllowed) {
        this.estimations.splice(
          this.estimations.indexOf(crowdswapEstimation),
          1
        );
      }
    }

    crowdswapEstimation = this.estimations.find(
      (estimationTrade: EstimateTrade) => {
        return estimationTrade.dex.name === Dexchanges.CrowdswapV2.name;
      }
    );
    if (crowdswapEstimation) {
      const addingCrowdswapEstimationIsAllowed =
        !environment.production || this.estimations[0] === crowdswapEstimation;
      if (!addingCrowdswapEstimationIsAllowed) {
        this.estimations.splice(
          this.estimations.indexOf(crowdswapEstimation),
          1
        );
      }
    }
  }

  public onEstimationSelected(estimation: EstimateTrade) {
    // choose the estimation to compare with the best estimation
    if (this.estimations.indexOf(estimation) !== 0) {
      // Only not null/undefined estimation is assigned to compareWithEstimation
      if (estimation) {
        this.compareWithEstimation = estimation;
      }
      this.selectedEstimation = estimation;
    }
    if (environment.qa) {
      this.bestEstimationChanged.emit(estimation || this.estimations[0]);
      this.selectedEstimation = estimation;
    }
  }

  /**
   * Try search again
   */
  public async tryAgain() {
    // const searchParam = this.searchParam$.getValue();
    // await this.$gtmService.pushTag({
    //   event: 'try_again',
    //   category: 'search',
    //   sourceTokenSymbol: searchParam?.fromToken.symbol,
    //   destinationTokenSymbol: searchParam?.toToken.symbol,
    //   amount: searchParam?.swapValue,
    //   isConnected: this.web3Service.isConnected(),
    // });
    this.searchParam$.next(this.searchParam$.getValue());
  }

  public toggleViewMore() {
    this.isViewMore = !this.isViewMore;
  }

  private setConsoleLog(msg: string = '') {
    const searchParam: SearchParamModel | undefined =
      this.searchParam$.getValue();
    console.log(
      'Estimate swap transaction ChainId=' +
        searchParam?.fromToken.chainId +
        ' SrcTokenSymbol=' +
        searchParam?.fromToken.symbol +
        ' DstTokenSymbol=' +
        searchParam?.toToken.symbol +
        ' Slippage=' +
        searchParam?.slippage +
        ' AmountIn=' +
        searchParam?.swapValue +
        ' SwapState= getting estimation ' +
        msg
    );
  }
}
