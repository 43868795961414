import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  commonConfig,
  Conversion,
  CrowdToken,
  TokensHolder,
  OpportunityInvestmentTypeName,
  PriceService,
  UNISWAP_V2_PAIR,
  OpportunitiesHolder
} from '@crowdswap/constant';
import { TokenAmount } from '@crowdswap/sdk';
import qs from 'qs';
import { BigNumber, ethers } from 'ethers';
import { Web3Service } from './web3.service';
import { CrowdSwapService } from './crowdswap.service';
import {
  CrossChainState,
  CrossChainStateName,
  OpportunityState,
  OpportunityStateName
} from './utils.service';
import { LoggingService } from './log/service/logging.service';
import { Contract } from '@ethersproject/contracts';
import { Observable, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { Constants } from '../constants';
import { StakeService } from './stake.service';
import { OpportunityType } from '../views/pages/opportunity/model/opportunity-state.enum';

interface OpportunityBalance {
  name: string;
  balance: BigNumber;
}

const baseUrl = environment.Opportunity_BASEURL || '';

@Injectable()
export class OpportunityService {
  public static SEARCH_NOTIFIER_TIME = 1500;
  public chainId;

  constructor(
    private http: HttpClient,
    public priceService: PriceService,
    private web3Service: Web3Service,
    private logger: LoggingService,
    private stakeService: StakeService
  ) {}

  public async getOpportunityTX(
    opportunity: any,
    userAddress: string | undefined,
    token: CrowdToken,
    amount: string,
    slippage: string,
    deadline: string,
    networkCoinPrice: string,
    investmentType: number
  ): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/opportunity/${opportunity.name}/${OpportunityInvestmentTypeName[investmentType]}`;
      const data = {
        userAddress: userAddress,
        token: token,
        amount: amount,
        networkCoinPrice: networkCoinPrice,
        slippage: slippage,
        deadline: deadline
      };
      let params = new HttpParams({ fromString: qs.stringify(data) });

      return await this.http.get(url, { params: params }).toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to get opportunity estimate');
  }

  public getInvestAmountByCrossChain(
    opportunity: any,
    userAddress: string | undefined,
    token: CrowdToken,
    wToken: CrowdToken,
    amount: string,
    slippage: string,
    reqTimeout: number = 1350000
  ):
    | Observable<{
        crossChainAmountOut: string;
        crossChainMinAmountOut: string;
        ourPlatformFee: string;
        fixFee: string;
      }>
    | undefined {
    this.logger.info(
      `Start getting opportunity cross chain swap estimation for sourceChainId = ${token.chainId}, fromToken = ${token.symbol}, dstToken = deUSDC, amount = ${amount}`
    );
    try {
      const params = {
        userAddress: userAddress,
        srcChainId: token.chainId,
        srcChainTokenInAddress: token.address,
        srcChainTokenInAmount: Conversion.convertStringToDecimal(
          amount,
          token.decimals
        ).toString(),
        slippage: commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE,
        dstChainId: wToken.chainId,
        dstChainTokenOutAddress: wToken.address
      };
      return this.getDebridgeEstimation(params, opportunity, reqTimeout).pipe(
        catchError((err) => {
          if (
            err.error &&
            (err.error.msg.includes(
              'Execution fee < expected amount of incoming token'
            ) ||
              err.error.msg.includes('input asset is too small') ||
              err.error.msg.includes('Input amount must be greater than'))
          ) {
            return throwError(Constants.EXECUTION_FEE_ERROR);
          }
          return throwError(-1);
        }),
        map((result) => {
          const estimation = result.body.estimation;

          this.logger.info(
            `Finish getting opportunity cross chain swap estimation for sourceChainId = ${token.chainId}, fromToken = ${token.symbol}, dstToken = deUSDC, amount = ${amount}, estimation = ${estimation}`
          );

          const crossChainAmountOut = Conversion.adjustFraction(
            Conversion.convertStringFromDecimal(
              estimation.dstChainTokenOut.amount,
              wToken.decimals
            ),
            6
          );

          const crossChainMinAmountOut = Conversion.adjustFraction(
            Conversion.convertStringFromDecimal(
              estimation.dstChainTokenOut.minAmount,
              wToken.decimals
            ),
            6
          );

          const ourPlatformFee = Conversion.adjustFraction(
            Conversion.convertStringFromDecimal(
              estimation.platformFee,
              wToken.decimals
            ),
            6
          );

          return {
            crossChainAmountOut: crossChainAmountOut,
            crossChainMinAmountOut: crossChainMinAmountOut,
            ourPlatformFee: ourPlatformFee,
            fixFee: result.body.fixFee
          };
        })
      );
    } catch (err) {
      console.error(`Error message: ${err}`);
      return undefined;
    }
  }

  public async getLeaveByLPTX(
    opportunity: any,
    userAddress: string | undefined,
    token: CrowdToken,
    amount: string,
    networkCoinPrice: string,
    slippage: string,
    deadline: number
  ): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/opportunity/${opportunity.name}/leaveByLP`;
      const data = {
        userAddress: userAddress,
        token: token,
        amount: amount,
        networkCoinPrice: networkCoinPrice,
        slippage: slippage,
        deadline: deadline
      };
      let params = new HttpParams({ fromString: qs.stringify(data) });

      return await this.http.get(url, { params: params }).toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to get opportunity estimate');
  }

  public async withdrawRewards(
    opportunity: any,
    amount: string,
    address: string
  ): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    switch (opportunity.opportunityType) {
      case OpportunityType.TypeCrowd:
        const gasPrice = await this.web3Service.getGasPrice();
        const estimatedGasLimit = await this.getEstimateGas(
          opportunity,
          amount,
          'withdrawRewards',
          address
        );
        return this.getStakingLPContract(
          opportunity
        ).populateTransaction.withdrawRewards(amount, address, {
          ...(estimatedGasLimit
            ? { gasLimit: estimatedGasLimit.toHexString() }
            : {}),
          gasPrice: gasPrice.toHexString()
        });
      case OpportunityType.TypePancake:
        const pId = 2;
        //For withdraw from pancake we must set amount = 0

        return this.getOpportunityContract(
          opportunity
        ).populateTransaction.withdrawRewards(amount);
    }
  }

  public async withdraw(
    opportunity: any,
    amount: string,
    address: string
  ): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    const gasPrice = await this.web3Service.getGasPrice();

    if (opportunity.opportunityType === OpportunityType.TypeStaking) {
      const estimatedGasLimit = await this.getEstimateGas(
        opportunity,
        amount,
        'stake',
        address
      );
      const stakeContract = this.getOpportunityContract(opportunity);
      return stakeContract.populateTransaction.withdraw(amount, {
        ...(estimatedGasLimit
          ? { gasLimit: estimatedGasLimit.toHexString() }
          : {}),
        gasPrice: gasPrice.toHexString()
      });
    } else {
      const result = await this.getLeaveByLPTX(
        opportunity,
        address,
        <CrowdToken>opportunity.LPToken,
        amount,
        '0.1',
        commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE,
        +CrowdSwapService.DEADLINE
      );

      return {
        from: result.from,
        to: result.to,
        data: result.data,
        value: result.value,
        gasLimit: result.gasLimit,
        gasPrice: gasPrice,
        gas: result.gasLimit
      };
    }
  }

  public async getLPStakeReward(
    opportunity: any,
    userAddress: string | undefined
  ): Promise<OpportunityBalance | undefined> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    try {
      switch (opportunity.opportunityType) {
        case OpportunityType.TypeStaking: {
          return {
            name: opportunity.name,
            balance: await this.getCrowStakingReward(opportunity, userAddress)
          };
        }
        case OpportunityType.TypeBeefy:
          return undefined;
        case OpportunityType.TypeCrowd:
          return {
            name: opportunity.name,
            balance: await this.getStakingLPContract(opportunity).earned(
              userAddress
            )
          };
        case OpportunityType.TypePancake:
          const userInfo = await this.getOpportunityContract(
            opportunity
          ).getUserInfo(userAddress);
          return {
            name: opportunity.name,
            balance: userInfo[1]
          };
      }
    } catch (e) {
      console.log(e);
    }
  }

  public async getLPStakedBalance(
    opportunity: any,
    userAddress: string | undefined
  ): Promise<OpportunityBalance | undefined> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    try {
      switch (opportunity.opportunityType) {
        case OpportunityType.TypeStaking: {
          return {
            name: opportunity.name,
            balance: await this.getCrowdStakingAmount(opportunity, userAddress)
          };
        }
        case OpportunityType.TypeBeefy: {
          return {
            name: opportunity.name,
            balance: await this.getBeefyLPAmount(opportunity, userAddress)
          };
        }
        case OpportunityType.TypeCrowd: {
          return {
            name: opportunity.name,
            balance: await this.getStakingLPContract(opportunity).balanceOf(
              userAddress
            )
          };
        }
        case OpportunityType.TypePancake: {
          const userInfo = await this.getOpportunityContract(
            opportunity
          ).getUserInfo(userAddress);
          return { name: opportunity.name, balance: userInfo[0] };
        }
      }
    } catch (e) {
      console.error('For the opportunity: ' + opportunity.name, e);
      return undefined;
    }
  }

  public setConsoleLog(
    opp: any,
    state: OpportunityState,
    msg: string = '',
    error: any = undefined,
    CCState: CrossChainState = CrossChainState.SrcInit
  ) {
    if (error) {
      this.logger.error(
        `Active opportunity= ${opp?.name} OpportunityState= ${
          OpportunityStateName[state]
        } CrossChainState= ${CrossChainStateName[CCState]} ${msg} ${
          'error is ' + JSON.stringify(error)
        }`
      );
    } else {
      this.logger.debug(
        `Active opportunity= ${opp?.name} OpportunityState= ${OpportunityStateName[state]} CrossChainState= ${CrossChainStateName[CCState]} ${msg}`
      );
    }
  }

  public async getStakingLPTotalSupply(opportunity: any): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }

    if (opportunity.name === 'CROWD_USDT_LP_STAKE') {
      return this.getStakingLPContract(opportunity).totalSupply();
    }
    return this.getBeefyPoolTotalSupply(opportunity);
  }

  public async convertLPToTokenAAndTokenB(pair, lpAmount, opportunity) {
    const pairPoolContract = new Contract(
      opportunity.poolAddress,
      UNISWAP_V2_PAIR,
      this.getProvider(opportunity)
    );
    const totalSupply = await pairPoolContract.totalSupply();
    const liquidityToken = new TokenAmount(pair.liquidityToken, totalSupply);
    const liquidity = new TokenAmount(pair.liquidityToken, lpAmount);
    const tokenA = TokensHolder.getWrappedToken(opportunity.tokenA);
    const tokenB = TokensHolder.getWrappedToken(opportunity.tokenB);

    const receivedTokenA = pair.getLiquidityValue(
      tokenA,
      liquidityToken,
      liquidity
    );
    const receivedTokenB = pair.getLiquidityValue(
      tokenB,
      liquidityToken,
      liquidity
    );
    return {
      receivedTokenA: (+ethers.utils.formatUnits(
        receivedTokenA.raw.toString(),
        tokenA.decimals
      )).toFixed(10),
      receivedTokenB: (+ethers.utils.formatUnits(
        receivedTokenB.raw.toString(),
        tokenB.decimals
      )).toFixed(10)
    };
  }

  public async getCrowStakingReward(opportunity, userAddress) {
    try {
      let stakedReward = await this.stakeService.getStakeReward(
        opportunity,
        userAddress,
        this.getProvider(opportunity)
      );
      if (stakedReward) {
        return stakedReward;
      }
    } catch (e) {
      console.log(e);
    }
    return 0;
  }

  public async getCrowdStakingAmount(opportunity, userAddress) {
    try {
      let stakedBalance = await this.stakeService.getStakedBalance(
        opportunity,
        userAddress,
        this.getProvider(opportunity)
      );
      if (stakedBalance) {
        return stakedBalance;
      }
    } catch (e) {
      console.log(e);
    }
    return 0;
  }

  public async getBeefyLPAmount(opportunity, userAddress) {
    const mooBalance = await this.getStakingLPContract(opportunity).balanceOf(
      userAddress
    );
    const totalSupply = await this.getStakingLPContract(
      opportunity
    ).totalSupply();
    const balanceOf = await this.getStakingLPContract(opportunity).balance();
    return this.calculateBeefyLPAmount(mooBalance, balanceOf, totalSupply);
  }

  public calculateBeefyLPAmount(mooBalance, balanceOf, totalSupply) {
    return mooBalance.mul(balanceOf).div(totalSupply);
  }

  private getStakingLPContract(opportunity: any) {
    return new ethers.Contract(
      opportunity.stakingLPContractAddress,
      opportunity.opportunityType === OpportunityType.TypePancake
        ? OpportunitiesHolder.OpportunitiesData.FarmOpportunities
            .pancakeContractAbi
        : opportunity.opportunityType == OpportunityType.TypeBeefy
        ? OpportunitiesHolder.OpportunitiesData.FarmOpportunities.beefyStakeAbi
        : OpportunitiesHolder.OpportunitiesData.FarmOpportunities.crowdStakeAbi,
      this.getProvider(opportunity)
    );
  }

  private getOpportunityContract(opportunity: any) {
    return new ethers.Contract(
      opportunity.contractAddress,
      opportunity.opportunityType === OpportunityType.TypePancake
        ? OpportunitiesHolder.OpportunitiesData.FarmOpportunities
            .pancakeContractAbi
        : opportunity.opportunityType == OpportunityType.TypeBeefy
        ? OpportunitiesHolder.OpportunitiesData.FarmOpportunities
            .beefyContractAbi
        : opportunity.version === 'V2'
        ? OpportunitiesHolder.OpportunitiesData.FarmOpportunities
            .crowdContractAbiV2
        : OpportunitiesHolder.OpportunitiesData.FarmOpportunities
            .crowdContractAbi,
      this.getProvider(opportunity)
    );
  }

  private getProvider(opportunity: any) {
    return this.web3Service.getNetworkProvider(opportunity.chainId);
  }

  private getDebridgeEstimation(params, opportunity, reqTimeout) {
    const url = `${baseUrl}/api/v1/opportunity/${opportunity.name}/crossChainEstimation/`;

    return this.http.get(url, { params: params, observe: 'response' }).pipe(
      timeout(reqTimeout),
      map((response: any) => {
        return {
          body: response.body,
          xCorrelationId: response.headers.get('x-correlation-id')
        };
      })
    );
  }

  public async getCurrentTime(): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/opportunity/getTime`;
      return await this.http.get(url).toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to get time');
  }

  private async getBeefyPoolTotalSupply(opportunity) {
    const beefyPoolAddress = opportunity.poolAddress;
    const contract = new ethers.Contract(
      beefyPoolAddress,
      UNISWAP_V2_PAIR,
      this.getProvider(opportunity)
    );
    return contract.totalSupply();
  }

  async getLastCrowdStakeTime(
    opportunity,
    userAddress: string | undefined
  ): Promise<number> {
    if (!this.web3Service.web3Provider) {
      return 0;
    }
    const stakeContract = this.getOpportunityContract(opportunity);
    return stakeContract.getStakeTime(userAddress);
  }

  async getStakeHolder(opportunity, userAddress: string | undefined, provider) {
    if (!provider) {
      return 0;
    }
    const stakeContract = this.getOpportunityContract(opportunity);
    return stakeContract.stakeholders(userAddress);
  }

  async getEstimateGas(
    opportunity: any,
    amount: string,
    functionality: string,
    address: string
  ): Promise<any> {
    if (!this.web3Service.web3Provider) {
      return undefined;
    }
    if (functionality === 'stake') {
      const stakeContract = this.getOpportunityContract(opportunity);
      return stakeContract.estimateGas.withdraw(amount, {
        from: address
      });
    }
    const stakeContract = this.getStakingLPContract(opportunity);
    if (functionality === 'withdraw') {
      return stakeContract.estimateGas.withdraw(amount, address, {
        from: address
      });
    } else if (functionality === 'withdrawRewards') {
      return stakeContract.estimateGas.withdrawRewards(amount, address, {
        from: address
      });
    }
  }
}
