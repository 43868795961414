import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  Conversion,
  CrowdToken,
  Networks,
  PriceService
} from '@crowdswap/constant';
import { Constants } from '../../../../constants';
import { BaseComponent } from '../../base.component';
import {
  DeviceType,
  NavigatorService,
  PortfolioService,
  TagManagerService,
  ThemeService,
  TokensService,
  NDDClientInfoServiceImpl,
  Web3Service,
  ConnectWalletService
} from '../../../../services';
import {
  NumberType,
  formatNumber,
  formatUSDPrice
} from '@uniswap/conedison/format';
import Swiper, { SwiperOptions } from 'swiper';
import { isAddress } from 'ethers/lib/utils';
import { SwiperComponent } from 'swiper/angular';
import { filter } from 'rxjs/operators';
import { CurrentNetwork } from '../../../../model';
import { NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';

export interface IUserAssetsTokenInfo extends CrowdToken {
  displayBalance: string;
  compareBalance: number;
  displayPrice: string;
  comparePrice: number;
  holdingOn: number;
  holdingOnToDisplay: string;
  holdingOnPercent: string;
}

export interface ColumnItem {
  title: string;
  compare: NzTableSortFn<any> | null;
  sortOrder: NzTableSortOrder | null;
  sortDirections: NzTableSortOrder[];
  showSort: boolean;
  nzLeft?: boolean;
  nzWidth: string;
  inMobile: boolean;
  nzAlign?: 'left' | 'right' | 'center';
}

@Component({
  selector: 'app-user-assets',
  templateUrl: './user-assets.component.html',
  styleUrls: ['./user-assets.component.scss']
})
export class UserAssetsComponent extends BaseComponent implements OnInit {
  totalBalance: string = '--';
  loading: boolean = true;
  displayTokens: IUserAssetsTokenInfo[] = [];

  getTokenInfoSubject: any;
  walletConnectionChangeSubject: any;
  public tableScrollY = '300px';
  public viewMoreCount = 5;

  public selectedNetwork: number = -1;
  private originalTokens: any[] = [];
  public allNetworks: number[] = [];
  public filterValue: string = '';
  public selectFlag: boolean = false;
  public listOfColumn: ColumnItem[] = [];
  public swiperConfig: {
    networkConfig: SwiperOptions;
    tokenConfig: SwiperOptions;
    defaultTokensOfTheNetworkSlide: {
      index: number;
      isEnd: boolean;
    };
    allNetworksSlide: {
      index: number;
      isEnd: boolean;
    };
  } = {
    networkConfig: {
      slidesPerView: 'auto',
      spaceBetween: 3,
      direction: 'horizontal',
      navigation: {
        nextEl: '.network-swiper-next',
        prevEl: '.network-swiper-prev'
      }
    },
    tokenConfig: {
      slidesPerView: 'auto',
      spaceBetween: 3,
      direction: 'horizontal',
      navigation: {
        nextEl: '.token-swiper-next',
        prevEl: '.token-swiper-prev'
      }
    },
    defaultTokensOfTheNetworkSlide: {
      index: 0,
      isEnd: false
    },
    allNetworksSlide: {
      index: 0,
      isEnd: false
    }
  };
  public Networks = Networks;

  @ViewChild('baseNetworkTokensSwiper')
  baseNetworkTokensSwiper!: SwiperComponent;
  isViewMore: boolean = false;

  constructor(
    public web3Service: Web3Service,
    public priceService: PriceService,
    protected themeService: ThemeService,
    private tokensService: TokensService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private portfolioService: PortfolioService,
    private navigatorService: NavigatorService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    protected connectWalletService: ConnectWalletService
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit() {
    super.ngOnInit();
    this.originalTokens = this.tokensService.getAllTokens();
    this.allNetworks = [
      -1,
      ...new Set(this.originalTokens.map((t) => t.chainId))
    ];

    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        if (
          !this.web3Service.isConnected() ||
          this.web3Service.getCurrentChainId() < 1
        ) {
          return;
        }
        await this.portfolioService.emitTokenInfo();
        this.setTokensDetail();
      })
    );

    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          })
        )
        .subscribe(async () => {
          await this.portfolioService.emitTokenInfo();
          this.setTokensDetail();
        })
    );

    this.subscriptionList.push(
      (this.walletConnectionChangeSubject =
        this.web3Service.walletConnectionChangeSubject.subscribe(
          async (connection) => {
            this.ref.detectChanges();
          }
        ))
    );

    this.setting.isMobile =
      [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
        this.clientInfoServiceImpl.getDeviceType()
      ) > -1;

    this.listOfColumn = [
      {
        title: 'Asset',
        compare: (a: IUserAssetsTokenInfo, b: IUserAssetsTokenInfo) =>
          a.symbol && b.symbol ? a.symbol.localeCompare(b.symbol) : 0,
        sortOrder: null,
        showSort: true,
        sortDirections: ['ascend', 'descend'],
        nzLeft: true,
        nzWidth: '150px',
        inMobile: true
      },
      {
        title: 'Amount',
        compare: (a: IUserAssetsTokenInfo, b: IUserAssetsTokenInfo) =>
          a.compareBalance - b.compareBalance,
        sortOrder: null,
        showSort: true,
        sortDirections: ['ascend', 'descend'],
        nzWidth: '150px',
        inMobile: false
      },
      {
        title: this.setting.isMobile ? 'Value' : 'Value in $',
        compare: (a: IUserAssetsTokenInfo, b: IUserAssetsTokenInfo) =>
          a.holdingOn - b.holdingOn,
        sortOrder: 'descend',
        showSort: true,
        sortDirections: ['ascend', 'descend'],
        nzWidth: '150px',
        inMobile: true,
        nzAlign: this.setting.isMobile ? 'right' : 'left'
      },
      {
        title: 'Price',
        compare: (a: IUserAssetsTokenInfo, b: IUserAssetsTokenInfo) =>
          a.comparePrice - b.comparePrice,
        sortOrder: null,
        showSort: true,
        sortDirections: ['ascend', 'descend'],
        nzWidth: '150px',
        inMobile: false
      },
      {
        title: '',
        compare: null,
        sortOrder: null,
        showSort: false,
        sortDirections: [null],
        nzWidth: this.setting.isMobile ? '24px' : '200px',
        inMobile: true
      }
    ];
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  private setTokensDetail() {
    if (
      !this.web3Service.isConnected() ||
      this.web3Service.getCurrentChainId() < 1
    ) {
      return;
    }

    this.loading = true;

    let totalValue = 0;

    this.portfolioService.getTokenInfoSubject().subscribe((tokenInfo) => {
      if (tokenInfo.loading) {
        return;
      }
      try {
        this.totalBalance = isNaN(tokenInfo.totalValue)
          ? '--'
          : Conversion.adjustFraction(tokenInfo.totalValue.toString(), 2);
        totalValue = tokenInfo.totalValue;

        this.displayTokens = this.filterTokensByChainIdAndFilterValue(
          tokenInfo.tokens,
          this.selectedNetwork,
          this.filterValue
        ).filter((x) => parseFloat(x.balance || 0) !== 0);

        this.originalTokens = this.displayTokens;
        const tokenINFO: IUserAssetsTokenInfo[] = this.displayTokens;

        tokenINFO.map((t) => {
          t.displayBalance = !isNaN(parseFloat(t.balance))
            ? formatNumber(parseFloat(t.balance), NumberType.TokenTx)
            : '';
          t.compareBalance = !isNaN(parseFloat(t.balance))
            ? parseFloat(t.balance)
            : 0;
          t.displayPrice = !isNaN(parseFloat(t.price))
            ? formatUSDPrice(parseFloat(t.price))
            : 'Unknown price';
          t.comparePrice = !isNaN(parseFloat(t.price))
            ? parseFloat(t.price)
            : 0;
          t.holdingOn = !isNaN(parseFloat(t.value)) ? parseFloat(t.value) : 0;
          t.holdingOnToDisplay = !isNaN(parseFloat(t.value))
            ? formatNumber(parseFloat(t.value), NumberType.FiatTokenStats)
            : '0';
          const _holdingOnPercent =
            !isNaN(parseFloat(t.value)) && !isNaN(totalValue)
              ? (parseFloat(t.value) / totalValue) * 100
              : null;
          t.holdingOnPercent = _holdingOnPercent
            ? formatNumber(_holdingOnPercent, NumberType.TokenNonTx) + '%'
            : '-';
        });
        this.displayTokens = tokenINFO;
      } catch (error) {
        console.log(error);
      }
    });
    this.loading = false;
  }

  async navigateToSwap(fromToken?: CrowdToken) {
    await this.tagManagerService.sendPortfolioTags(
      fromToken,
      'classicSwap',
      this.web3Service.getWalletAddress()
    );

    if (!fromToken || !fromToken?.chainId) {
      await this.navigatorService.navigate('exchange', {});
      return;
    }
    let url = `exchange/from_chain_id/${fromToken?.chainId}/from_token_symbol/${fromToken?.symbol}/to_chain_id/${fromToken?.chainId}/to_token_symbol/`;
    url +=
      fromToken?.symbol === Constants.DEFAULT_PAIRS[fromToken.chainId].toToken
        ? Constants.DEFAULT_PAIRS[fromToken.chainId].fromToken
        : Constants.DEFAULT_PAIRS[fromToken.chainId].toToken;
    await this.navigatorService.navigate(url, {});
  }

  public selectNetwork(chainId: number) {
    this.selectFlag = false;
    this.selectedNetwork = chainId;
    this.displayTokens = this.filterTokensByChainIdAndFilterValue(
      this.originalTokens,
      this.selectedNetwork,
      this.filterValue
    ).filter((x) => parseFloat(x.balance || 0) !== 0);
    let totalValue = 0;
    this.displayTokens = this.displayTokens.filter((item) => {
      if (chainId > 0 && item.balance && item.price) {
        totalValue += parseFloat(item.balance) * parseFloat(item.price);
      }
      return parseFloat(item.balance) !== 0;
    });
    if (chainId === -1) {
      this.portfolioService.getTokenInfoSubject().subscribe((tokenInfo) => {
        totalValue = tokenInfo.totalValue;
      });
    }
    this.totalBalance = isNaN(totalValue)
      ? '--'
      : formatUSDPrice(Conversion.adjustFraction(totalValue.toString(), 2));

    if (this.baseNetworkTokensSwiper) {
      this.swiperConfig.defaultTokensOfTheNetworkSlide = {
        index: 0,
        isEnd: false
      };
      this.baseNetworkTokensSwiper.swiperRef.slideTo(0);
      this.baseNetworkTokensSwiper.swiperRef.destroy();
      this.baseNetworkTokensSwiper.initSwiper();
    }
  }

  public filterTokensByChainIdAndFilterValue(
    tokens: any[],
    selectNetwork: number,
    filterValue: string
  ) {
    return tokens.filter((token: CrowdToken) => {
      const networkMatches =
        selectNetwork === -1 || token.chainId === selectNetwork;
      const symbolMatches =
        !filterValue ||
        isAddress(filterValue) ||
        !token.symbol ||
        token.symbol.toLowerCase().includes(filterValue.toLowerCase());
      return networkMatches && symbolMatches;
    });
  }

  public slideChange(data: Swiper[], field: { index: number; isEnd: boolean }) {
    field.index = data[0].activeIndex;
    field.isEnd = data[0].isEnd;
  }

  async navigateToTransfer(token: CrowdToken) {
    await this.navigatorService.navigate('transfer', {
      tokenAddress: token.address,
      tokenChainId: token.chainId,
      tokenSymbol: token.symbol
    });
  }

  public tableDropdownVisible(status: boolean, element: HTMLElement) {
    if (status) {
      element.className += ' bg-0066FF text-white';
      element.classList.remove('text-0066FF');
      element.classList.remove('dark:text-3385FF');
      return;
    }

    element.classList.add('text-0066FF');
    element.classList.add('dark:text-3385FF');
    element.classList.remove('bg-0066FF');
    element.classList.remove('text-white');
  }

  public selectedNetworkVisibleChanged(status: boolean) {
    this.selectFlag = status;
  }
}
