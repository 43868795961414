import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Conversion, PriceService } from '@crowdswap/constant';
import { BaseComponent } from '../../pages/base.component';
import {
  NDDClientInfoServiceImpl,
  PortfolioService,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../services';
import { VipService } from '../../../services/vip.service';
import { environment } from '../../../../environments/environment';
import { OpportunitiesService } from 'src/app/services/opportunities.service';

@Component({
  selector: 'app-caption',
  templateUrl: './caption.component.html',
  styleUrls: ['./caption.component.scss']
})
export class CaptionComponent extends BaseComponent implements OnInit {
  totalInvestment: string = '000000';
  totalBalance: string = '000000';
  showBalance: boolean = true;
  showInvestment: boolean = true;
  balanceLoading: boolean = false;
  investmentLoading: boolean = false;
  public userStatus: {
    score: number;
    stage: number;
    rules: {};
  } = {
    score: 0,
    stage: 0,
    rules: {}
  };
  public enableVip = environment.enableVip;

  constructor(
    public web3Service: Web3Service,
    public priceService: PriceService,
    private router: Router,
    private ref: ChangeDetectorRef,
    protected themeService: ThemeService,
    public portfolioService: PortfolioService,
    public opportunitiesService: OpportunitiesService,
    protected tagManagerService: TagManagerService,
    public vipService: VipService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit() {
    super.ngOnInit(undefined, undefined, async (address) => {
      this.userStatus = await this.vipService.getVipStatus(address);
    });

    this.portfolioService.getTokenInfoSubject().subscribe((tokenInfo) => {
      this.balanceLoading = tokenInfo.loading;
      this.totalBalance = isNaN(tokenInfo.totalValue)
        ? '-----'
        : Conversion.adjustFraction(tokenInfo.totalValue.toString(), 2);
    });

    this.opportunitiesService._investmentInfoSubject.subscribe(
      (investmentInfo) => {
        this.investmentLoading = investmentInfo.loading;
        this.totalInvestment = isNaN(investmentInfo.totalInvestment)
          ? '-----'
          : Conversion.adjustFraction(
              investmentInfo.totalInvestment.toString(),
              2
            );
      }
    );

    if (this.isUserWalletConnected) {
      this.userStatus = await this.vipService.getVipStatus(
        this.web3Service.getWalletAddress()
      );
    }
  }

  async navigateToVip() {
    await this.router.navigate(['vip']);
  }
}
