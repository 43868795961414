import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { Networks } from '@crowdswap/constant';
import { Web3Service } from '../../../../services';

@Component({
  selector: 'app-import-token-dialog',
  templateUrl: './import-token-dialog.component.html',
  styleUrls: ['./import-token-dialog.component.scss']
})
export class ImportTokenDialogComponent extends DialogsComponent {
  @Output()
  confirmed = new EventEmitter<boolean>();
  userApproved: boolean = false;
  public Networks = Networks;

  constructor(public web3Service: Web3Service) {
    super();
  }

  confirm() {
    this.confirmed.emit(true);
  }

  close() {
    this.confirmed.emit(false);
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule],
  declarations: [ImportTokenDialogComponent],
  exports: [ImportTokenDialogComponent]
})
export class ImportTokenDialogComponentModule {}
