<div class="w-full opportunity_page_items">
  <div
    class="w-full d-flex flex-column justify-center p-1 p-md-4 border-radius-6px md:border-2 border-00000010 dark:border-0 dark:bg-1E1E1E col-12"
  >
    <div class="col-12 my-2 mt-5">
      <div *ngIf="!LPMode" class="flex items-center w-full pb-2 text-base">
        <div class="col-6 md:col-6">
          <span
            class="text-gray-400 dark:text-darkGrayText font-openSans-regular pr-2"
            >Select token to withdraw
          </span>
        </div>
        <div
          class="text-right w-full relative text-xs-2 dark:text-darkGrayText dark:opacity-100"
        >
          <button
            (click)="changeDropDown()"
            id="token-dropdown"
            (clickOutside)="selectFlag = false"
            aria-expanded="false"
            aria-haspopup="true"
            class="inline-flex justify-center w-full rounded border border-gray-300 dark:border-0 shadow-sm px-sm-1 bg-white dark:bg-121212 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
            type="button"
          >
            <div class="flex md:px-1">
              <div class="flex items-center justify-center relative scale-50">
                <img
                  class="z-1 rounded-full"
                  [src]="
                    baseLogoIconsUrl +
                    (tokenIndex === 0
                      ? selectedOpp?.tokenA?.symbol?.toLowerCase()
                      : selectedOpp?.rewardToken?.symbol?.toLowerCase()) +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                />
                <div *ngIf="tokenIndex === 0" class="ml--10">
                  <img
                    class="rounded-full"
                    [src]="
                      baseLogoIconsUrl +
                      selectedOpp?.tokenB?.symbol?.toLowerCase() +
                      '.png'
                    "
                    (error)="updateUrl($event)"
                  />
                </div>
              </div>
              <div class="grid items-center grid-rows-1">
                <span
                  class="row-span-1 font-openSans-bold text-xs-2 md:text-md dark:text-textWhite text-left"
                  >&nbsp;
                  {{ tokens?.[tokenIndex] }}
                </span>
              </div>
              <div class="grid items-center">
                <svg
                  class="-mr-1 h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clip-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    fill-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </button>
          <div
            *ngIf="selectFlag"
            aria-labelledby="menu-button"
            aria-orientation="vertical"
            class="origin-top-left w-max absolute w-66 z-20 h-auto overflow-hidden rounded-md shadow-lg bg-white dark:bg-darkBlack ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            role="menu"
          >
            <div class="overflow-x-hidden max-h-90 m-sm-2 m-1">
              <div
                (click)="changeSelectedToken(item)"
                *ngFor="let item of tokens"
                class="cursor-pointer rounded-md p-1 hover:bg-gray-100 dark:hover:bg-hoverBackground"
              >
                <div class="flex justify-content-between">
                  <div
                    class="flex items-center justify-center relative scale-50"
                  >
                    <img
                      class="z-1 rounded-full"
                      [src]="
                        baseLogoIconsUrl +
                        (tokens.indexOf(item) === 0
                          ? selectedOpp?.tokenA?.symbol?.toLowerCase()
                          : selectedOpp?.rewardToken?.symbol?.toLowerCase()) +
                        '.png'
                      "
                      (error)="updateUrl($event)"
                    />
                    <img
                      *ngIf="tokens.indexOf(item) === 0"
                      class="rounded-full ml--10"
                      [src]="
                        baseLogoIconsUrl +
                        selectedOpp?.tokenB?.symbol?.toLowerCase() +
                        '.png'
                      "
                      (error)="updateUrl($event)"
                    />
                  </div>
                  <div class="grid items-center grid-rows-1 ml-3">
                    <span class="row-span-1 dark:text-textWhite">{{
                      item
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!LPMode" class="col-12">
      <div
        [ngClass]="{ 'input-selected': isSelected }"
        class="flex md:items-center w-full border-2 rounded text-base md:px-2 h-20 md:h-12"
      >
        <div class="w-7/10">
          <input
            appFocusAndSelectValue
            [el]="txtWithdrawAmount"
            #txtWithdrawAmount
            type="text"
            inputmode="decimal"
            id="txtWithdrawAmount"
            [(ngModel)]="amount"
            (ngModelChange)="notifyAmount($event)"
            (blur)="isSelected = false"
            (click)="isSelected = true"
            mask="separator"
            class="w-full border-0 p-0 outline-none font-24 shadow-none bg-transparent dark:text-fff"
          />
        </div>

        <div
          *ngIf="stakeButtonState !== StakingButtonState.WalletNotConnected"
          (click)="setMaxBalance(tokenIndex)"
          class="w-3/10 absolute right-5 mr-3 pr-2 text-xs-2 cursor-pointer dark:text-darkGrayText dark:opacity-100"
        >
          <br class="d-md-none" />
          <div class="max-color text-right">Max&nbsp;Balance</div>
          <div class="text-right">
            {{ tokenIndex === 0 ? stakedBalance : allReward }}
          </div>
        </div>
      </div>
      <span class="text-gray-400 text-xs-2"></span>
    </div>
    <div class="col-12 mt-4">
      <button
        nz-button
        (click)="approve()"
        *ngIf="
          selectedOpp &&
          selectedOpp.isApproveNeededForWithdraw &&
          stakeButtonState === StakingButtonState.ApprovalNeeded &&
          isLPStakingContractValid &&
          +amount > 0
        "
        nzType="default"
        [nzSize]="'large'"
        class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
      >
        <span class="px-1">
          Allow to use your {{ selectedOpp?.proofOfInvestTokenName }}
        </span>
      </button>
    </div>

    <div *ngIf="!LPMode" class="col-12 mt-2">
      <button
        *ngIf="
          stakeButtonState !== StakingButtonState.WalletNotConnected &&
          isLPStakingContractValid &&
          ((tokenIndex === 1 && +allReward < +amount) ||
            (tokenIndex === 0 && +stakedBalance < +amount))
        "
        nz-button
        nzType="default"
        [nzSize]="'large'"
        class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
        [ngClass]="{
          'opacity-4':
            (tokenIndex === 0 && +amount > +stakedBalance) ||
            (tokenIndex === 1 && +amount > +allReward) ||
            stakeButtonState === StakingButtonState.ApprovalNeeded ||
            +amount <= 0
        }"
        [disabled]="
          (tokenIndex === 0 && +amount > +stakedBalance) ||
          (tokenIndex === 1 && +amount > +allReward) ||
          stakeButtonState === StakingButtonState.ApprovalNeeded ||
          +amount <= 0
        "
      >
        <span class="px-1">
          <ng-container *ngIf="+amount > 0">
            {{
              (tokenIndex === 0 && +amount <= +stakedBalance) ||
              (tokenIndex === 1 && +amount <= +allReward)
                ? "Stake"
                : "Insufficient Balance"
            }}
          </ng-container>

          <ng-container *ngIf="+amount <= 0"> Enter an amount </ng-container>
        </span>
      </button>

      <button
        *ngIf="
          stakeButtonState !== StakingButtonState.WalletNotConnected &&
          !isLPStakingContractValid
        "
        nz-button
        nzType="default"
        [nzSize]="'large'"
        class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
        [ngClass]="{ 'opacity-4': true }"
        [disabled]="true"
      >
        <span class="px-1"> Unsupported network </span>
      </button>

      <button
        nz-button
        nzType="default"
        (click)="connectWallet()"
        *ngIf="stakeButtonState === StakingButtonState.WalletNotConnected"
        [nzSize]="'large'"
        class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
      >
        <span class="px-1"> Connect Wallet </span>
      </button>

      <button
        id="btnWithdrawToken"
        (click)="withdraw(tokenIndex)"
        *ngIf="
          tokenIndex === 0 &&
          stakeButtonState !== StakingButtonState.WalletNotConnected &&
          isLPStakingContractValid &&
          +stakedBalance >= +amount
        "
        nz-button
        nzType="default"
        [nzSize]="'large'"
        class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
        [ngClass]="{
          'opacity-4':
            stakeButtonState === StakingButtonState.ApprovalNeeded ||
            stakeButtonState === StakingButtonState.Disabled ||
            +stakedBalance < +amount ||
            +amount <= 0
        }"
        [disabled]="
          stakeButtonState === StakingButtonState.ApprovalNeeded ||
          stakeButtonState === StakingButtonState.Disabled ||
          +stakedBalance < +amount ||
          +amount <= 0
        "
      >
        <span class="px-1">
          <ng-container *ngIf="+amount > 0">Withdraw</ng-container>
          <ng-container *ngIf="+amount <= 0">Enter an amount</ng-container>
        </span>
      </button>

      <button
        id="btnWithdrawReward"
        nz-button
        nzType="default"
        (click)="withdraw(tokenIndex)"
        *ngIf="
          tokenIndex === 1 &&
          stakeButtonState !== StakingButtonState.WalletNotConnected &&
          isLPStakingContractValid &&
          +allReward >= +amount
        "
        [nzSize]="'large'"
        class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
        [ngClass]="{
          'opacity-4':
            stakeButtonState === StakingButtonState.ApprovalNeeded ||
            stakeButtonState === StakingButtonState.Disabled ||
            +allReward < +amount ||
            +amount <= 0
        }"
        [disabled]="
          stakeButtonState === StakingButtonState.ApprovalNeeded ||
          stakeButtonState === StakingButtonState.Disabled ||
          +allReward < +amount ||
          +amount <= 0
        "
      >
        <span class="px-1">
          <ng-container *ngIf="+amount > 0"> Withdraw</ng-container>
          <ng-container *ngIf="+amount <= 0">Enter an amount</ng-container>
        </span>
      </button>
    </div>
  </div>
</div>
