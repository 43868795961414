import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Web3Service } from './web3.service';
import {
  Conversion,
  DEBRIDGE_SWAP,
  Networks,
  NetworksById,
  PriceService,
  TokensHolder
} from '@crowdswap/constant';
import { BigNumber, Contract } from 'ethers';
import { encodeParameter } from 'web3-eth-abi';
import { NetworksService } from './networks.service';
import { LoggingService } from './log/service/logging.service';

@Injectable()
export class DebridgeService {
  private readonly debridgeURL = 'https://nodes.debridge.finance/v4.0';
  private readonly intermediaryHolder =
    '0x0aee3e5871254d40084a6758d85ac453c1f12fca';
  private readonly ethereumCoinAddress =
    '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
  private readonly polygonCoinAddress =
    '0x0000000000000000000000000000000000001010';
  private readonly chain = {
    forwarders: {
      //deBridege on source network
      '1': '0x663DC15D3C1aC63ff12E45Ab68FeA3F0a883C251',
      '56': '0x663DC15D3C1aC63ff12E45Ab68FeA3F0a883C251',
      '128': null,
      '137': '0x663DC15D3C1aC63ff12E45Ab68FeA3F0a883C251',
      '42161': '0x663DC15D3C1aC63ff12E45Ab68FeA3F0a883C251'
    },
    receivers: {
      //deBridege on destination network
      '1': '0x413dDDCE3d0eAd2489648E482d192A7758C2B1b4',
      '56': '0x413dDDCE3d0eAd2489648E482d192A7758C2B1b4',
      '128': null,
      '137': '0x413dDDCE3d0eAd2489648E482d192A7758C2B1b4',
      '42161': '0x413dDDCE3d0eAd2489648E482d192A7758C2B1b4'
    },
    globalNativeFees: {
      '1': '1000000000000000',
      '56': '5000000000000000',
      '128': '100000000000000000',
      '137': '500000000000000000',
      '42161': '1000000000000000'
    }
  };
  private readonly deUSDC = '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa';
  private readonly SLIPPAGE = 1;

  constructor(
    private http: HttpClient,
    private web3Service: Web3Service,
    private networkService: NetworksService,
    private priceService: PriceService,
    private logger: LoggingService
  ) {}

  public async getOneInchTransaction(
    fromAddress: string,
    destReceiver: string,
    chainId: number,
    fromTokenAddress: string,
    toTokenAddress: string,
    amount: string,
    usePatching: boolean,
    reqTimeout: number = 1350000
  ): Promise<any> {
    try {
      //TODO: give slippage
      const url = `${this.debridgeURL}/${chainId}/swap?fromTokenAddress=${fromTokenAddress}&toTokenAddress=${toTokenAddress}&amount=${amount}&fromAddress=${fromAddress}&destReceiver=${destReceiver}&slippage=1&disableEstimate=true&usePatching=${usePatching}`;
      return this.http
        .get(url, { observe: 'response' })
        .toPromise()
        .then((response) => response!.body);
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to get debridge transaction');
  }

  public async getDebridgeTransaction(
    userAddress,
    sourceChainId,
    fromTokenSymbol,
    dstChainId,
    dstTokenSymbol,
    amount,
    reqTimeout = 1350000
  ) {
    try {
      this.logger.info(
        `Start getting debridge transaction for userAddress = ${userAddress} sourceChainId = ${sourceChainId} fromToken = ${fromTokenSymbol} dstChainId = ${dstChainId} dstToken = ${dstTokenSymbol} amount = ${amount}`
      );
      // Populate swap transaction
      const isFromTokenACoin =
        TokensHolder.TokenListBySymbol[NetworksById[sourceChainId]][
          fromTokenSymbol
        ].address === this.ethereumCoinAddress ||
        TokensHolder.TokenListBySymbol[NetworksById[sourceChainId]][
          fromTokenSymbol
        ].address === this.polygonCoinAddress;
      const isDstTokenACoin =
        TokensHolder.TokenListBySymbol[NetworksById[dstChainId]][dstTokenSymbol]
          .address === this.ethereumCoinAddress ||
        TokensHolder.TokenListBySymbol[NetworksById[dstChainId]][dstTokenSymbol]
          .address === this.polygonCoinAddress;

      const { sourceSwap, receiver, dstSwap } = await this.getOneInchSwaps(
        sourceChainId,
        fromTokenSymbol,
        dstChainId,
        dstTokenSymbol,
        amount,
        reqTimeout,
        userAddress,
        isFromTokenACoin,
        isDstTokenACoin
      );

      // Preparing swap transaction on the destination network
      const dstDetail = await this.getDstDetails(
        dstChainId,
        dstSwap,
        receiver,
        isDstTokenACoin
      );

      const transaction = await new Contract(
        this.chain.forwarders[sourceChainId],
        DEBRIDGE_SWAP.crossChainForwarderABI
      ).populateTransaction.swapAndSend(
        isFromTokenACoin
          ? '0x0000000000000000000000000000000000000000'
          : TokensHolder.TokenListBySymbol[NetworksById[sourceChainId]][
              fromTokenSymbol
            ].address,
        sourceSwap.fromTokenAmount,
        receiver.permit || '0x',
        sourceSwap.tx.to,
        sourceSwap.tx.data,
        sourceSwap.toToken.address,
        dstDetail,
        {
          value: isFromTokenACoin
            ? BigNumber.from(this.chain.globalNativeFees[sourceChainId]).add(
                BigNumber.from(amount)
              )
            : BigNumber.from(this.chain.globalNativeFees[sourceChainId])
        }
      );
      this.logger.info(
        `Finish getting debridge transaction for [userAddress = ${userAddress}] [sourceChainId = ${sourceChainId}] [fromToken = ${fromTokenSymbol}] [dstChainId = ${dstChainId}] [dstToken = ${dstTokenSymbol}] [amount = ${amount}]`
      );
      this.logger.debug(
        `The transaction info is [from = ${transaction.from}] [to = ${transaction.to}] [data.length = ${transaction.data?.length}] [value = ${transaction.value}]`
      );
      this.logger.trace(`The transaction is [${JSON.stringify(transaction)}]`);
      return transaction;
    } catch (e: any) {
      this.logger.error(
        `Error in getting debridge transaction for [userAddress = ${userAddress}] [sourceChainId = ${sourceChainId}] [fromToken = ${fromTokenSymbol}] [dstChainId = ${dstChainId}] [dstToken = ${dstTokenSymbol}] [amount = ${amount}] [error = ${e.message}]`
      );
    }
  }

  private async getOneInchSwaps(
    sourceChainId,
    fromTokenSymbol,
    dstChainId,
    dstTokenSymbol,
    amount,
    reqTimeout: number,
    userAddress,
    isFromTokenACoin: boolean,
    isDstTokenACoin: boolean
  ) {
    // Get transaction of fromTokenSymbol/deUSDC on the source network
    const sourceSwap = await this.getOneInchTransaction(
      this.chain.forwarders[sourceChainId],
      this.chain.forwarders[sourceChainId],
      sourceChainId,
      isFromTokenACoin
        ? this.ethereumCoinAddress
        : TokensHolder.TokenListBySymbol[NetworksById[sourceChainId]][
            fromTokenSymbol
          ].address,
      sourceChainId === Networks.MAINNET
        ? TokensHolder.TokenListBySymbol[Networks.MAINNET_NAME]['USDC'].address
        : this.deUSDC,
      amount,
      false,
      reqTimeout
    );

    const receiver = {
      destinationAddress: userAddress,
      executionFee: await this.getExecutionFee(
        dstChainId,
        this.deUSDC,
        this.ethereumCoinAddress
      ),
      permit: null,
      referralCode: 0,
      useAssetFee: false
    };
    let minAmountOut = this.applySlippage(
      sourceSwap.toTokenAmount,
      this.SLIPPAGE
    );
    minAmountOut = DebridgeService.cutOffFee(10, minAmountOut);
    minAmountOut = minAmountOut.sub(
      Conversion.toSafeBigNumberByRemovingFraction(
        receiver.executionFee.toString()
      )
    );

    // Get the transaction of deUSDC/toToken on the destination network
    const dstSwap = await this.getOneInchTransaction(
      this.chain.receivers[dstChainId],
      userAddress,
      dstChainId,
      this.deUSDC,
      isDstTokenACoin
        ? this.ethereumCoinAddress
        : TokensHolder.TokenListBySymbol[NetworksById[dstChainId]][
            dstTokenSymbol
          ].address,
      minAmountOut.toString(),
      true,
      reqTimeout
    );
    return { sourceSwap, receiver, dstSwap };
  }

  public applySlippage(amountOut, slippage) {
    return BigNumber.from(amountOut).sub(
      BigNumber.from(amountOut)
        .mul(BigNumber.from(slippage))
        .div(BigNumber.from(100))
    );
  }

  /**
   * Preparing destination swap call data
   * @param dstChainId
   * @param dstSwap
   * @param receiver
   * @param isDstTokenACoin
   * @private
   */
  private async getDstDetails(
    dstChainId,
    dstSwap: any,
    receiver: {
      destinationAddress: any;
      useAssetFee: boolean;
      referralCode: number;
      permit: null;
      executionFee: number;
    },
    isDstTokenACoin: boolean
  ) {
    const dstReceiverCalldata = await this.getDstReceiverCalldata(
      dstChainId,
      dstSwap,
      receiver,
      isDstTokenACoin
    );
    return encodeParameter(
      {
        DstDetails: {
          chainId: 'uint256',
          receiver: 'address',
          receiverCalldata: 'bytes',
          fallbackAddress: 'address',
          useAssetFee: 'bool',
          referralCode: 'uint32',
          executionFee: 'uint256'
        }
      },
      {
        chainId: dstChainId,
        receiver: dstReceiverCalldata.receiver,
        receiverCalldata: dstReceiverCalldata.receiverCalldata,
        fallbackAddress: receiver.destinationAddress,
        useAssetFee: receiver.useAssetFee,
        referralCode: receiver.referralCode || 0,
        executionFee: Conversion.toSafeBigNumberByRemovingFraction(
          receiver.executionFee.toString() || '0'
        )
      }
    );
  }

  /**
   * Get swap on the destination network
   * @param dstChainId
   * @param dstSwap
   * @param receiver
   * @private
   */
  private async getDstReceiverCalldata(
    dstChainId,
    dstSwap: any,
    receiver: {
      destinationAddress: any;
      useAssetFee: boolean;
      referralCode: number;
      permit: null;
      executionFee: number;
    },
    isDstTokenACoin: boolean
  ) {
    const forwardMethodCall = await new Contract(
      this.chain.receivers[dstChainId], //Receiver
      DEBRIDGE_SWAP.receivingForwarderABI
    ).populateTransaction.forward(
      dstSwap.fromToken.address,
      dstSwap.tx.to,
      dstSwap.tx.data,
      isDstTokenACoin
        ? '0x0000000000000000000000000000000000000000'
        : dstSwap.toToken.address,
      receiver.destinationAddress
    );
    return {
      receiver: this.chain.receivers[dstChainId],
      receiverCalldata: forwardMethodCall.data
    };
  }

  private static cutOffFee(
    gateFeeBps,
    tokenToAmount,
    chainBPSDenominator = 10000
  ) {
    return tokenToAmount
      .mul(chainBPSDenominator - gateFeeBps)
      .div(chainBPSDenominator);
  }

  private static getClaimOverhead(chainId) {
    switch (chainId) {
      case Networks.BSCMAIN:
      case Networks.MAINNET:
      case Networks.POLYGON_MAINNET:
        return 25e4;
      default:
        return 27e5;
    }
  }

  private static getIntermediaryInfoMinAmount(chainId) {
    switch (chainId) {
      case Networks.MAINNET:
        return 3000000000000000;
      case Networks.BSCMAIN:
      case Networks.POLYGON_MAINNET:
        return 1000000;
      default:
        return 3000000000000000;
    }
  }

  /**
   * Returns a predication of network cost to run the swap transaction on the destination network
   * @param chainId
   * @param fromTokenAddress
   * @param toTokenAddress
   */
  public async getExecutionFee(
    chainId,
    fromTokenAddress: string,
    toTokenAddress: string
  ) {
    // get transaction of deUSDC/ethereumCoinAddress with minAmount on destination network
    const gasLimitTx = await this.getOneInchTransaction(
      this.intermediaryHolder,
      this.intermediaryHolder,
      chainId,
      this.deUSDC,
      this.ethereumCoinAddress,
      DebridgeService.getIntermediaryInfoMinAmount(chainId).toString(),
      true
    );

    // get gasEstimation of above transaction data on destination network
    let gasEstimation = await (
      await this.networkService.getNetworkProvider(chainId)
    )
      .getProvider()
      .estimateGas({
        from: gasLimitTx.tx.from,
        to: gasLimitTx.tx.to,
        data: gasLimitTx.tx.data,
        value: gasLimitTx.tx.value
      });
    gasEstimation =
      (+gasEstimation.toString() + DebridgeService.getClaimOverhead(chainId)) *
      1.25;

    // Get current gas price of destination network
    const gasPrice = await this.networkService
      .getNetworkProvider(chainId)
      .getGasPrice();

    // get transaction of coin/deusdc with gasLimit * gasPrice amount on destination network
    const executionFee = await this.getOneInchTransaction(
      this.intermediaryHolder,
      this.intermediaryHolder,
      chainId,
      this.ethereumCoinAddress,
      this.deUSDC,
      gasPrice
        .mul(Conversion.toSafeBigNumberByRemovingFraction(gasEstimation))
        .toString(),
      true
    );
    return +executionFee.toTokenAmount * 1.3;
  }

  public getForSubmission(submissionId) {
    try {
      const url = `https://api.debridge.finance/api/SubmissionConfirmations/getForSubmission?submissionId=${submissionId}`;
      return this.http
        .get<Array<Object>>(url, { observe: 'response' })
        .toPromise()
        .then((response) => response!.body?.length);
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getForSubmission of debridge');
  }

  public getFullSubmissionInfo(sourceTxHash) {
    try {
      const url = `https://api.debridge.finance/api/Transactions/GetFullSubmissionInfo?filter=${sourceTxHash}&filterType=1`;
      return this.http
        .get(url, { observe: 'response' })
        .toPromise()
        .then((response) => response!.body);
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getFullSubmissionInfo of debridge');
  }

  public getDebridgeEstimationByApi(
    srcChainId,
    srcChainTokenInAddress,
    srcChainTokenInAmount,
    slippage,
    dstChainId,
    dstChainTokenOutAddress
  ) {
    try {
      const url = `https://deswap.debridge.finance/v1.0/estimation?srcChainId=${srcChainId}&srcChainTokenIn=${srcChainTokenInAddress}&srcChainTokenInAmount=${srcChainTokenInAmount}&slippage=${slippage}&dstChainId=${dstChainId}&dstChainTokenOut=${dstChainTokenOutAddress}&executionFeeAmount=auto`;
      // const headers = new HttpHeaders({'access-control-allow-origin':'*'});
      return this.http
        .get(url, { observe: 'response' })
        .toPromise()
        .then((response) => response!.body)
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getDebridgeEstimation of debridge');
  }

  public getDebridgeTransactionByApi(
    srcChainId,
    srcChainTokenInAddress,
    srcChainTokenInAmount,
    slippage,
    dstChainId,
    dstChainTokenOutAddress,
    userAddress
  ) {
    try {
      const url = `https://deswap.debridge.finance/v1.0/transaction?srcChainId=${srcChainId}&srcChainTokenIn=${srcChainTokenInAddress}&srcChainTokenInAmount=${srcChainTokenInAmount}&slippage=${slippage}&dstChainId=${dstChainId}&dstChainTokenOut=${dstChainTokenOutAddress}&executionFeeAmount=auto&dstChainTokenOutRecipient=${userAddress}`;
      return this.http
        .get(url, { observe: 'response' })
        .toPromise()
        .then((response) => response!.body);
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getDebridgeEstimation of debridge');
  }

  public getDLNOrderStatus(sender, transactionHash): Promise<any> {
    try {
      const url = `https://stats-api.dln.trade/api/Orders/filteredList`;
      return this.http
        .post<Array<Object>>(
          url,
          { creator: sender, filter: transactionHash, take: 1 },
          { observe: 'response' }
        )
        .toPromise()
        .then((response) => response!.body);
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error('Unable to getDLNOrderStatus of deBridge');
  }
}
