import { Component, OnInit } from '@angular/core';
import {
  DeviceType,
  NavigatorService,
  ActiveMenu,
  NDDClientInfoServiceImpl
} from '../../../services';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
const adminBaseUrl = environment.ADMIN_BASEURL || '';

@Component({
  selector: 'app-help-circle',
  templateUrl: './help-circle.component.html',
  styleUrls: ['./help-circle.component.scss']
})
export class HelpCircleComponent implements OnInit {
  public isMobile: boolean = false;
  public isHelpClicked: boolean = false;
  public helpCenterData: any[] = [];
  public mobileTooltip: boolean = false;
  public popConfirmVisible: boolean = false;

  constructor(
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    public navigatorService: NavigatorService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.isMobile =
      [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
        this.clientInfoServiceImpl.getDeviceType()
      ) > -1;

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.onClose();
      });
  }

  public async openHelp(showContext: boolean = false) {
    if (this.isMobile && !showContext) {
      return;
    }
    this.mobileTooltip = false;
    this.popConfirmVisible = true;
    this.helpCenterData = [];
    const currentRoute = this.router.url.substring(1).replace('/', '-');
    let data;
    try {
      const url = `${adminBaseUrl}/api/helper/helperPage/${currentRoute}`;
      data = await this.http.get(url).toPromise();
    } catch (err) {
      console.error(`Unable to get helper data from DB with reason: ${err}`);
    }
    this.helpCenterData = data
      ? data.filter((item) => item.page === currentRoute)
      : [];
    this.isHelpClicked = !this.isHelpClicked;
  }

  onClose() {
    this.isHelpClicked = false;
  }

  click(event) {
    if (event.target.title === 'confirm-open') {
      return;
    }
    this.isHelpClicked = false;
    this.popConfirmVisible = false;
  }
}
