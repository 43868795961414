import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  commonConfig,
  Conversion,
  NetworksById,
  TokensHolder
} from '@crowdswap/constant';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UnstakeDialogComponent } from '../../../modal/dialogs/unstake-dialog/unstake-dialog.component';
import { SuccessfulUnStakeDialogComponent } from '../../../modal/dialogs/successful-unstake/successful-unstake-dialog.component';
import { WaitingDialogComponent } from '../../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ModalService } from '../../../modal/modal.service';
import { CurrentNetwork } from '../../../../model';
import { Router } from '@angular/router';
import {
  PrivateSaleService,
  StakeService,
  ThemeService,
  TokensService,
  UtilsService,
  Web3Service
} from '../../../../services';

export enum WalletStatus {
  Connected,
  WalletNotConnected
}

export enum OpportunityStatus {
  notStarted,
  running,
  finished
}

export enum PoolState {
  PREOPEN,
  THREEHOURS,
  FIFO,
  CLOSE,
  FAILURE
}

@Component({
  selector: 'app-private-sale-stake',
  templateUrl: './details-private-sale.component.html',
  styleUrls: ['./details-private-sale.component.scss']
})
export class DetailsPrivateSaleComponent implements OnInit, OnDestroy {
  PoolState = PoolState;
  StakingButtonState = WalletStatus;
  public static LIMITED_INTERVAL_TIME =
    commonConfig.STAKE_UNSTAKE.LIMITED_INTERVAL_TIME;
  public stakeAmount = 0;
  public apyString!: string;
  public minimumStakedBalance = 1;
  public stakeState = 'staked';
  public isDarkMode;
  subscriptionList: Subscription[] = [];
  public startTimeRemain: {
    minutesLeft: number;
    daysLeft: number;
    secondsLeft: number;
    hoursLeft: number;
  } = { daysLeft: 0, hoursLeft: 0, minutesLeft: 0, secondsLeft: 0 };
  public finishTimeRemain: {
    minutesLeft: number;
    daysLeft: number;
    secondsLeft: number;
    hoursLeft: number;
  } = { daysLeft: 0, hoursLeft: 0, minutesLeft: 0, secondsLeft: 0 };

  public isWalletConnected = WalletStatus.WalletNotConnected;
  public investState: boolean = false;
  public tooltip: boolean = false;
  public currentTime;
  public finishTime;
  public startTime;
  public activePresale: any;
  public investmentType;
  public isVip = false;
  public opportunityStatus = 0;
  public poolDetail: any;
  public investorDetail: any;
  public releasable: string = '0';
  public spot: any;
  public spotList: any;
  public canBuyTicket: boolean = false;
  public isTicketApproved: boolean = false;
  public poolState: any;
  public isMinimum;
  public isWrongNetwork;
  public nextReleaseTime;
  public nextReleaseAmount;
  static activePresale: any;
  public isOwner: boolean = false;

  constructor(
    public stakeService: StakeService,
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private unStakeDialogService: ModalService<UnstakeDialogComponent>,
    private successfulUnStakeDialogService: ModalService<SuccessfulUnStakeDialogComponent>,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private themeService: ThemeService,
    private router: Router,
    private privateSaleService: PrivateSaleService
  ) {
    setInterval(() => {
      this.currentTime = Math.floor(Date.now() / 1000);
      this.checkPreSaleConditions();
    }, 1000);
    this.activePresale = DetailsPrivateSaleComponent.activePresale;
  }

  async ngOnInit(): Promise<any> {
    this.currentTime = Math.floor(Date.now() / 1000);
    this.themeService.darkModeChangeSubject.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });

    if (!this.activePresale) {
      await this.router.navigate(['opportunity']);
    }

    this.isWalletConnected = this.web3Service.isConnected()
      ? WalletStatus.Connected
      : WalletStatus.WalletNotConnected;

    // On wallet connect/disconnect
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe((connection) => {
        connection
          ? this.isWalletConnected === WalletStatus.Connected
          : this.isWalletConnected === WalletStatus.WalletNotConnected;
        this.ref.detectChanges();
      })
    );
    // On network change
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          const chainId: number = currentNetwork.chainId;
          this.isWrongNetwork = this.activePresale.chainId !== chainId;
          this.ref.detectChanges();
        })
    );
    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        await this.refreshData();
        this.ref.detectChanges();
      })
    );
    if (this.isWalletConnected === WalletStatus.Connected) {
      let owner = await this.privateSaleService.getOwner(this.activePresale);
      const isOwner =
        owner === this.web3Service.getWalletAddress() && !this.isWrongNetwork;
      if (isOwner) {
        const poolBalance = await this.privateSaleService.getPoolBalance(
          this.activePresale
        );
        this.isOwner = poolBalance.gt(0);
      }
    }
    await this.refreshData();
    this.isMinimum = await this.isMinimumPrice();
    this.isWrongNetwork =
      this.activePresale.chainId !== this.web3Service.getCurrentChainId();
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public async getTicketTokenBalance() {
    let balance = await this.web3Service.getBalance(
      this.activePresale.ticketToken
    );
    const ticketToken =
      TokensHolder.TokenListByAddress[NetworksById[this.activePresale.chainId]][
        this.activePresale.ticketToken
      ];
    if (balance) {
      return +Conversion.convertStringFromDecimal(
        balance.toString(),
        ticketToken.decimals
      );
    }
    return 0;
  }

  private async showWaitingDialog(dialogType) {
    const ticketToken =
      TokensHolder.TokenListByAddress[NetworksById[this.activePresale.chainId]][
        this.activePresale.ticketToken
      ];
    const data = {
      stakeState: dialogType,
      isDarkMode: this.isDarkMode,
      tokenSymbol: this.isTicketApproved
        ? 'ticket by ' + ticketToken.symbol
        : ticketToken.symbol
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  private showSuccessToaster(title, message?) {
    this.toastr.success(title, message, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      enableHtml: true,
      timeOut: 10000,
      messageClass: 'successClass'
    });
  }

  public async switchToNetwork() {
    await this.web3Service.changeNetwork(this.activePresale.chainId);
  }

  async navigateToInvest() {
    await this.router.navigate(['opportunity/private-sale']);
  }

  private async checkPreSaleConditions() {
    if (this.poolDetail) {
      this.startTime = this.poolDetail.startTime;
      this.finishTime = this.poolDetail.finishTime;
      if (this.startTime > this.currentTime) {
        this.opportunityStatus = OpportunityStatus.notStarted;
        this.startTimeRemain = this.privateSaleService.getRemainingTime(
          this.startTime,
          this.currentTime
        );
        this.finishTimeRemain = this.privateSaleService.getRemainingTime(
          this.finishTime,
          this.currentTime
        );
      }
      if (
        this.startTime < this.currentTime &&
        this.finishTime > this.currentTime
      ) {
        this.opportunityStatus = OpportunityStatus.running;

        this.startTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0
        };
        this.finishTimeRemain = UtilsService.getRemainingTime(
          this.finishTime,
          this.currentTime
        );
      }
      if (this.finishTime < this.currentTime) {
        this.opportunityStatus = OpportunityStatus.finished;
        this.startTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0
        };
        this.finishTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0
        };
      }
    }
  }
  public async release() {
    try {
      await this.confirmRelease();
    } catch (e) {
      console.error(
        e +
          `details-private-sale: release unsuccessfully.now release is = ${this.releasable}`
      );
      console.log(e);
    }
  }

  private async confirmRelease(): Promise<any> {
    this.stakeState = 'release';
    try {
      const data = {
        stakeState: 'Releas',
        tokenSymbol: this.activePresale.presaleTokenName,
        isDarkMode: this.isDarkMode
      };
      await this.waitingDialogService.open(WaitingDialogComponent, data);

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        const releaseTx = await this.privateSaleService.release(
          this.activePresale
        );
        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction(releaseTx)
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(
              e +
                `details-private-sale: release unsuccessfully releaseBalance is  = ${this.releasable}`
            );
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                await this.waitingDialogService.close();
                this.showSuccessToaster('Success', 'released successfully!');
                await this.getUserDetail();
              }
              if (data?.status === 0) {
                await this.waitingDialogService.close();
                this.showErrorToaster('Error', 'release rejected!');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          this.showErrorToaster('Error', 'release rejected!');
          console.log('release rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      console.error(
        e +
          `details-private-sale: release unsuccessfully. releasedBalance is  = ${this.releasable}`
      );
      await this.waitingDialogService.close();
    }
  }

  public async buyTicket() {
    try {
      await this.confirmBuyTicket();
    } catch (e) {
      console.error(e + `details-private-sale: buyTicket unsuccessfully.now`);
      console.log(e);
    }
  }

  private async confirmBuyTicket(): Promise<any> {
    const ticketTokenBalance = await this.getTicketTokenBalance();
    if (ticketTokenBalance < this.spot) {
      this.toastr.error(
        this.activePresale.ticketToken.symbol +
          ' balance is lower than ticket price',
        'Buying ticket is rejected.'
      );
      return;
    }
    this.stakeState = 'buyTicket';
    try {
      this.isTicketApproved
        ? await this.showWaitingDialog('Buy')
        : await this.showWaitingDialog('Approv');

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        const releaseTx = this.isTicketApproved
          ? await this.privateSaleService.buyTicket(this.activePresale)
          : await this.privateSaleService.approveBuyingTicket(
              this.activePresale
            );
        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction(releaseTx)
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(
              e + `details-private-sale: buy Ticket unsuccessfully`
            );
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                await this.waitingDialogService.close();
                this.showSuccessToaster(
                  'Success',
                  this.isTicketApproved
                    ? 'Ticket bought!'
                    : 'Approved successfully!'
                );
                await this.refreshData();
              }
              if (data?.status === 0) {
                await this.waitingDialogService.close();
                this.showErrorToaster('Error', 'Buy ticket rejected!');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          this.showErrorToaster('Error', 'Buy ticket rejected!');
          console.log('Buy ticket rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      console.error(e + `details-private-sale: Buy ticket unsuccessfully.`);
      await this.waitingDialogService.close();
    }
  }

  public async checkTicket() {
    await this.getPoolDetail();
    await this.getUserDetail();
    await this.checkPreSaleConditions();
    if (
      this.opportunityStatus === OpportunityStatus.finished ||
      this.opportunityStatus === OpportunityStatus.notStarted
    ) {
      this.canBuyTicket = false;
      return;
    } else if (this.investorDetail && this.investorDetail.state === 1) {
      this.isVip = true;
      this.canBuyTicket = false;
      return;
    } else if (
      this.currentTime - this.startTime <
      +this.poolDetail.firstHoursBestPrice.toString() * 60 * 60
    ) {
      this.canBuyTicket = false;
      return;
    }

    this.spot = await this.privateSaleService.getTicket(this.activePresale);
    if (this.spot && this.spot > 0) {
      this.canBuyTicket = true;
    }
    this.spotList = await this.privateSaleService.getTicketList(
      this.activePresale
    );
    this.isTicketApproved = await this.privateSaleService.allowanceBuyingTicket(
      this.activePresale
    );
  }

  public async getPoolDetail() {
    this.poolDetail = await this.privateSaleService.getPoolDetails(
      this.activePresale
    );
    this.poolDetail.investedAmount = Conversion.adjustFraction(
      this.poolDetail.investedAmount
    );
  }

  public async getUserDetail() {
    this.investorDetail = await this.privateSaleService.getUserDetails(
      this.activePresale
    );
    this.releasable = await this.privateSaleService.claim(
      this.activePresale,
      this.web3Service.getWalletAddress()
    );
    if (
      this.poolState !== PoolState.FAILURE ||
      this.poolState !== PoolState.PREOPEN
    ) {
      const nextRelease = await this.privateSaleService.getNextRelease(
        this.activePresale
      );
      this.nextReleaseTime = +(nextRelease[1] * 1000);
      this.nextReleaseAmount = (+Conversion.convertStringFromDecimal(
        nextRelease[0]
      )).toFixed(2);
    }
  }

  openRestrictedDialog() {}

  private async refreshData() {
    this.poolState = await this.privateSaleService.getPoolState(
      this.activePresale
    );
    await this.getPoolDetail();
    await this.getUserDetail();
    await this.checkTicket();
  }

  async withdraw() {
    try {
      await this.confirmWithdraw();
    } catch (e) {
      console.error(e + `details-private-sale: withdraw unsuccessfully`);
      console.log(e);
    }
  }

  private async confirmWithdraw() {
    this.stakeState = 'withdraw';
    try {
      const data = {
        stakeState: 'Withdraw',
        tokenSymbol: this.activePresale.presaleTokenName,
        isDarkMode: this.isDarkMode
      };
      await this.waitingDialogService.open(WaitingDialogComponent, data);

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        const releaseTx = await this.privateSaleService.withdrawWhenFailed(
          this.activePresale
        );
        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction(releaseTx)
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(e + `details-private-sale: Withdraw unsuccessfully.`);
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                await this.waitingDialogService.close();
                this.showSuccessToaster('Success', 'Withdrew successfully!');
                await this.getUserDetail();
              }
              if (data?.status === 0) {
                await this.waitingDialogService.close();
                this.showErrorToaster('Error', 'Withdrawal rejected!');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          this.showErrorToaster('Error', 'Withdrawal rejected!');
          console.log('Withdrawal rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      console.error(e + `details-private-sale: Withdrew unsuccessfully.`);
      await this.waitingDialogService.close();
    }
  }

  async withdrawBeneficiaryFunds() {
    this.stakeState = 'withdraw';
    try {
      const data = {
        stakeState: 'Withdraw',
        tokenSymbol:
          TokensHolder.TokenListByAddress[
            NetworksById[this.activePresale.chainId]
          ][this.activePresale.investToken].symbol,
        isDarkMode: this.isDarkMode
      };
      await this.waitingDialogService.open(WaitingDialogComponent, data);

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        const withdrawTX =
          await this.privateSaleService.withdrawBeneficiaryFunds(
            this.activePresale
          );
        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction(withdrawTX)
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(e + `details-private-sale: Withdrew unsuccessfully.`);
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                await this.waitingDialogService.close();
                this.showSuccessToaster('Success', 'Withdrew successfully!');
                this.isOwner = false;
              }
              if (data?.status === 0) {
                await this.waitingDialogService.close();
                this.showErrorToaster('Error', 'Withdrawal rejected!');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          this.showErrorToaster('Error', 'Withdrawal rejected!');
          console.log('Withdraw rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      console.error(e + `details-private-sale: Withdrew unsuccessfully.`);
      await this.waitingDialogService.close();
    }
  }

  private async isMinimumPrice() {
    if (
      this.currentTime - +this.startTime <
      +this.poolDetail.firstHoursBestPrice.toString() * 60 * 60
    ) {
      return true;
    }
    const userDetail = await this.privateSaleService.getUserDetails(
      this.activePresale
    );
    return userDetail.state === 1;
  }
}
