<div
  class="w-full container shadow dark:bg-darkBlack rounded-md h-100 history-component d-flex user-assets-section flex-column px-3 px-lt-24px"
>
  <div
    class="base-network-tokens mt-4 mb-2 dark:text-fff w-full d-flex justify-content-between overflow-hidden align-items-center no-text-wrap flex-shrink-0 position-relative"
  >
    <div class="font-24 px-2 font-openSans-semiBold">
      {{ showSetting ? "History Filters" : "Transaction History" }}
    </div>

    <div
      (click)="clearFilter()"
      *ngIf="showSetting"
      class="font-16 cursor-pointer text-0066FF dark:text-fff px-2 flex font-openSans-semiBold"
    >
      <div class="text-0066FF dark:text-fff">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_7176_59660)">
            <path
              d="M4.58333 10.0833H17.4167V11.9167H4.58333V10.0833ZM2.75 13.75H15.5833V15.5833H2.75V13.75ZM6.41667 6.41667H19.25V8.25H6.41667V6.41667Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_7176_59660">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      Clear All
    </div>
  </div>

  <nz-affix
    class="mb-2 z-10"
    [ngClass]="isDarkMode ? 'bg-darkBlack' : 'bg-FAFBFC'"
    [nzOffsetTop]="0"
    [nzTarget]="contentSection"
  >
    <div
      *ngIf="!showSetting"
      class="base-network-tokens my-2 w-full d-flex z-1 align-items-center no-text-wrap flex-shrink-0 justify-content-between position-relative"
      [ngClass]="{ 'px-2': setting.isMobile }"
    >
      <!--    Input-->
      <div
        class="dark:bg-121212 rounded-md w-full p-2 flex align-items-center focus:border-0066FF dark:border-3385ff border-2 border-0066FF dark:border-3385ff text-0066FF dark:text-3385FF dark:bg-3385ff26"
        [ngClass]="setting.isMobile ? 'w-full me-2' : 'col-5 h-45px'"
      >
        <svg
          width="18px"
          height="18px"
          viewBox="0 0 18 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>BB6A6DA6-4619-4FF0-AB89-86075C001114</title>
          <g
            id="Select-Token"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Select-a-token"
              transform="translate(-474.000000, -179.000000)"
              fill="#0066FF"
            >
              <g id="Group-4" transform="translate(441.000000, 98.000000)">
                <g
                  id="Text-Field/_Resource/Box"
                  transform="translate(20.000000, 64.000000)"
                >
                  <g
                    id="Icon/Search/Oultine"
                    transform="translate(13.666667, 17.666667)"
                  >
                    <path
                      d="M7.08333333,0 C10.9953503,0 14.1666667,3.17131636 14.1666667,7.08333333 C14.1666667,8.74182968 13.5966768,10.2671978 12.6419041,11.4742305 L16.7079314,15.5344799 C16.9684971,15.7946131 16.9688478,16.2167229 16.7087146,16.4772887 L16.4732083,16.7131867 C16.2130751,16.9737524 15.7909653,16.9741031 15.5303996,16.7139699 L11.4616951,12.6517969 C10.2566449,13.6006127 8.73609359,14.1666667 7.08333333,14.1666667 C3.17131636,14.1666667 0,10.9953503 0,7.08333333 C0,3.17131636 3.17131636,0 7.08333333,0 Z M7.08333333,1.66666667 C4.09179094,1.66666667 1.66666667,4.09179094 1.66666667,7.08333333 C1.66666667,10.0748757 4.09179094,12.5 7.08333333,12.5 C10.0748757,12.5 12.5,10.0748757 12.5,7.08333333 C12.5,4.09179094 10.0748757,1.66666667 7.08333333,1.66666667 Z"
                      id="Combined-Shape"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>

        <input
          (ngModelChange)="filterTransaction($event)"
          [(ngModel)]="filterValue"
          (keydown.escape)="clearSearch()"
          class="placeholder-blue-500 dark:placeholder-white w-full bg-transparent focus:outline-none dark:text-fff pl-2"
          placeholder="Search transaction"
        />
      </div>

      <div
        *ngIf="!setting.isMobile"
        class="flex col-4 justify-content-end space-x-2"
      >
        <!--    Network select-->
        <div class="relative h-45px w-150-px rightAlign">
          <button
            (click)="selectFlag = !selectFlag"
            id="network-dropdown"
            (clickOutside)="selectFlag = false"
            aria-expanded="false"
            aria-haspopup="true"
            class="flex justify-content-between items-center w-full rounded-md border-2 border-0066FF dark:bg-3385ff26 dark:border-3385ff bg-white text-sm text-0066FF dark:text-fff"
            type="button"
          >
            <div class="flex-column">
              <div class="font-10 text-left ml-2">Network</div>
              <div class="flex md:px-1">
                <div
                  *ngIf="selectedNetwork !== -1"
                  class="flex items-center justify-center"
                >
                  <img
                    class="rounded-full"
                    [src]="web3Service.networkSpec[selectedNetwork]?.coinIcon"
                    height="20"
                    width="20"
                  />
                </div>
                <div class="grid grid-rows-1">
                  <span class="d-block row-span-1 text-left px-2">{{
                    web3Service.networkSpec[selectedNetwork]?.title ?? "All"
                  }}</span>
                </div>
              </div>
            </div>

            <div class="text-0066FF dark:text-fff">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_917_16869)">
                  <path
                    d="M5.83594 8.83203L10.0026 12.9987L14.1693 8.83203H5.83594Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_917_16869">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>

          <div
            *ngIf="selectFlag"
            aria-labelledby="menu-button"
            aria-orientation="vertical"
            class="w-max w-min-100 absolute z-40 h-auto overflow-hidden rounded-md shadow-lg bg-white dark:bg-121212 ring-1 ring-black dark:ring-gray-700 ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            role="menu"
          >
            <div class="overflow-x-hidden max-h-90 m-sm-2 m-1">
              <div
                (click)="networkMenuClickHandler(item.chainId)"
                *ngFor="let item of allNetworks"
                class="cursor-pointer rounded-md py-1 pl-1 pr-1 mr-1 hover:bg-gray-100 dark:hover:bg-hoverBackground"
              >
                <div class="flex">
                  <div class="flex items-center justify-center">
                    <i class="iconify" data-icon="mdi-account-off"></i>
                    <img
                      *ngIf="item.chainId !== -1"
                      class="rounded-full"
                      [src]="web3Service.networkSpec[item?.chainId]?.coinIcon"
                      height="30"
                      width="20"
                    />
                  </div>
                  <div class="grid grid-rows-1 ml-3">
                    <span
                      id="spnNetworkItem"
                      class="row-span-1 dark:text-textWhite"
                      >{{
                        item.chainId !== -1
                          ? this.web3Service.networkSpec[item?.chainId]?.title
                          : "All"
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--    Transaction select-->
        <div class="relative text-0066FF dark:text-fff h-45px w-150-px">
          <button
            (click)="transactionFlag = !transactionFlag"
            id="transaction-dropdown"
            (clickOutside)="transactionFlag = false"
            aria-expanded="false"
            aria-haspopup="true"
            class="flex justify-content-between items-center w-full rounded-md border-2 border-0066FF dark:bg-3385ff26 dark:border-3385ff bg-white text-sm"
            type="button"
          >
            <div class="flex-column">
              <div class="font-10 text-left ml-2">Transaction</div>
              <div class="flex md:px-1">
                <div class="grid grid-rows-1">
                  <span class="d-block row-span-1 text-left px-2"
                    >{{ txState }}
                  </span>
                </div>
              </div>
            </div>
            <div class="text-0066FF dark:text-fff">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_917_16869)">
                  <path
                    d="M5.83594 8.83203L10.0026 12.9987L14.1693 8.83203H5.83594Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_917_16869">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </button>

          <div
            *ngIf="transactionFlag"
            aria-labelledby="menu-button"
            aria-orientation="vertical"
            class="origin-top-left w-max w-min-100 absolute z-40 h-auto overflow-hidden rounded-md shadow-lg bg-white dark:bg-121212 ring-1 ring-black dark:ring-gray-700 ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
            role="menu"
          >
            <div class="overflow-x-hidden max-h-90 m-sm-2 m-1">
              <div
                (click)="switchHistory(item)"
                *ngFor="let item of allTxStates"
                class="cursor-pointer rounded-md py-1 pl-1 pr-1 mr-1 hover:bg-gray-100 dark:hover:bg-hoverBackground"
              >
                <div class="flex">
                  <div class="grid grid-rows-1 ml-3">
                    <span
                      id="spnTransactionItem"
                      class="row-span-1 dark:text-textWhite"
                      >{{ item }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--    Export-->
      <div
        *ngIf="!setting.isMobile"
        class="flex col-3 h-45px justify-content-end"
      >
        <button
          (click)="downloadCSV()"
          class="p-2 border-2 text-0066FF dark:text-fff border-0066FF dark:bg-3385ff26 dark:border-3385ff space-x-2 rounded-md flex"
        >
          <div class="text-0066FF dark:text-fff">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.66602 15.5833V17.4167C3.66602 18.4292 4.48683 19.25 5.49935 19.25H16.4994C17.5119 19.25 18.3327 18.4292 18.3327 17.4167V15.5833"
                stroke="CurrentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.41602 10.0833L10.9993 14.6667L15.5827 10.0833"
                stroke="CurrentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.9993 3.66667V14.6667"
                stroke="CurrentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span class="font-16"> Export </span>
        </button>
      </div>

      <div
        *ngIf="setting.isMobile"
        class="flex items-center h-45px justify-content-start"
      >
        <button
          (click)="openFilter()"
          class="p-2 border-2 text-0066FF dark:text-3385FF border-0066FF dark:bg-3385ff26 dark:border-3385ff rounded-md flex"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_7131_55420)">
              <path
                d="M6.41598 5.5H15.5826L10.9901 11.275L6.41598 5.5ZM3.89514 5.1425C5.74681 7.51666 9.16598 11.9167 9.16598 11.9167V17.4167C9.16598 17.9208 9.57848 18.3333 10.0826 18.3333H11.916C12.4201 18.3333 12.8326 17.9208 12.8326 17.4167V11.9167C12.8326 11.9167 16.2426 7.51666 18.0943 5.1425C18.5618 4.5375 18.131 3.66666 17.3701 3.66666H4.61931C3.85848 3.66666 3.42764 4.5375 3.89514 5.1425Z"
                fill="#0066FF"
              />
            </g>
            <defs>
              <clipPath id="clip0_7131_55420">
                <rect width="22" height="22" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  </nz-affix>

  <!--  Setting on mobile-->
  <div class="p-2" *ngIf="showSetting">
    <!--    Network part-->
    <div class="flex-column">
      <div class="dark:text-fff my-3 font-openSans-semiBold">Network</div>

      <div class="flex flex-wrap">
        <div
          (click)="filteredNetwork = item.chainId"
          *ngFor="let item of allNetworks"
          class="cursor-pointer rounded-md py-1 pl-1 pr-1 mr-1"
          ngClass="{{
            item.chainId === filteredNetwork
              ? 'bg-0066FF text-fff'
              : 'text-0066FF dark:text-3385FF'
          }}"
        >
          <div class="flex">
            <div class="flex items-center justify-center">
              <i class="iconify" data-icon="mdi-account-off"></i>
              <img
                *ngIf="item.chainId !== -1"
                class="rounded-full"
                [src]="web3Service.networkSpec[item?.chainId]?.coinIcon"
                height="30"
                width="20"
              />
            </div>
            <div class="grid grid-rows-1 mx-2">
              <span
                id="spnNetworkItemOnMobile"
                class="row-span-1 dark:text-textWhite"
                >{{
                  item.chainId !== -1
                    ? this.web3Service.networkSpec[item?.chainId]?.title
                    : "All"
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    Transaction part-->
    <div class="flex-column">
      <div class="dark:text-fff mt-5 mb-3 font-openSans-semiBold">Status</div>
      <div class="flex flex-wrap">
        <div
          (click)="txStateFiltered = item"
          *ngFor="let item of allTxStates"
          class="cursor-pointer rounded-md py-1 pl-1 pr-1 mr-1"
          ngClass="{{
            item === txStateFiltered
              ? 'bg-0066FF text-fff'
              : 'text-0066FF dark:text-3385FF'
          }}"
        >
          <div class="flex">
            <div class="grid grid-rows-1 mx-2">
              <span
                id="spnTransactionItemOnMobile"
                class="row-span-1 dark:text-textWhite"
                >{{ item }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 flex">
      <div class="w-1/2 p-2">
        <button
          nz-button
          nzType="default"
          (click)="showSetting = false"
          [nzSize]="'default'"
          class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 mt-3"
        >
          <span class="px-1"> Cancel </span>
        </button>
      </div>

      <div class="w-1/2 p-2">
        <button
          nz-button
          nzType="default"
          (click)="saveFilter()"
          [nzSize]="'default'"
          class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
        >
          <span class="px-1"> Save </span>
        </button>
      </div>
    </div>
  </div>

  <!-- Transaction table -->
  <div *ngIf="!showSetting">
    <div
      class="d-flex justify-content-center"
      *ngIf="web3Service.isConnected(); else WalletConnect"
    >
      <nz-table
        #transactionTable
        [nzData]="txHistoryToDisplay"
        [nzScroll]="table.scroll"
        [nzPageSize]="table.pageSize"
        [nzPageIndex]="table.pageIndex"
        [nzFrontPagination]="true"
        [nzLoading]="loading"
        [nzShowSizeChanger]="true"
        (nzPageIndexChange)="scrollToTop()"
        class="w-full bg-FAFBFC xs:bg-fff dark:bg-darkBlack no-text-wrap history-table"
        [ngClass]="{
          'no-data-mode ':
            !txHistoryToDisplay || txHistoryToDisplay.length === 0,
          'rounded-md overflow-hidden': loading
        }"
      >
        <thead>
          <tr>
            <ng-container *ngIf="!setting.isMobile; else mobileTable">
              <ng-container *ngFor="let column of listOfColumn">
                <ng-container
                  *ngIf="
                    !setting.isMobile || (setting.isMobile && column.inMobile)
                  "
                >
                  <th
                    class="border-BFD8FF border-bottom-1px border-solid dark:text-fff bg-FAFBFC xs:bg-fff dark:bg-darkBlack"
                    [nzLeft]="column.nzLeft || false"
                    [nzWidth]="column.nzWidth"
                    [ngClass]="{
                      'text-right': column.nzAlign === 'right',
                      'px-0': column.title === '' && setting.isMobile,
                      'pr-0': column.title === 'Value' && setting.isMobile
                    }"
                  >
                    {{ column.title }}
                  </th>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-template #mobileTable>
              <th colSpan="5"></th>
            </ng-template>
          </tr>
        </thead>

        <tbody>
          <!--Desktop mode-->
          <ng-container *ngIf="web3Service.isConnected() && !setting.isMobile">
            <ng-container
              *ngFor="
                let item of transactionTable.data;
                let last = last;
                let i = index;
                let odd = odd
              "
            >
              <tr>
                <td
                  class="ps-2 pe-0 py-30px border-0 position-relative border-left-radius-4px"
                  [ngClass]="
                    odd ? 'bg-F5F5F5 dark:bg-F5F5F50A' : 'dark:bg-darkBlack'
                  "
                >
                  <div
                    class="d-flex align-items-center font-16 text-0066FF dark:text-13B9AA font-openSans-semiBold"
                  >
                    <div class="text-0066FF dark:text-13B9AA mr-1">
                      <ng-container [ngSwitch]="item.txType">
                        <ng-container
                          *ngSwitchCase="
                            [
                              TxType.CrossChain,
                              TxType.CrossChainLimitOrder,
                              TxType.Swap
                            ].indexOf(item.txType) > -1
                              ? item.txType
                              : false
                          "
                        >
                          <ng-container
                            [ngTemplateOutlet]="exchangeIcon"
                          ></ng-container>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            [TxType.WithdrawLP, TxType.WithdrawEtf].indexOf(
                              item.txType
                            ) > -1
                              ? item.txType
                              : false
                          "
                        >
                          <ng-container
                            [ngTemplateOutlet]="withdrawIcon"
                          ></ng-container>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            [TxType.Farming, TxType.Stake].indexOf(
                              item.txType
                            ) > -1
                              ? item.txType
                              : false
                          "
                        >
                          <ng-container
                            [ngTemplateOutlet]="FarmingIcon"
                          ></ng-container>
                        </ng-container>

                        <ng-container
                          *ngSwitchCase="
                            [TxType.WithdrawReward].indexOf(item.txType) > -1
                              ? item.txType
                              : false
                          "
                        >
                          <ng-container
                            [ngTemplateOutlet]="RewardIcon"
                          ></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="TxType.UnStake">
                          <ng-container
                            [ngTemplateOutlet]="UnStakeIcon"
                          ></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="TxType.Send">
                          <ng-container
                            [ngTemplateOutlet]="SendIcon"
                          ></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="TxType.Receive">
                          <ng-container
                            [ngTemplateOutlet]="ReceiveIcon"
                          ></ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="TxType.InvestEtf">
                          <ng-container
                            [ngTemplateOutlet]="EtfIcon"
                          ></ng-container>
                        </ng-container>
                      </ng-container>
                    </div>

                    {{ formatTxType(item.txType) || "Unknown" }}

                    <nz-tag
                      *ngIf="item.txType === TxType.CrossChainLimitOrder"
                      class="ms-2 rounded-full font-12"
                      [nzColor]="'#000'"
                      >Limit Order</nz-tag
                    >
                  </div>

                  <a
                    href="{{
                      web3Service.getScanTransactionUrl(
                        item.hash,
                        parseInt(item.chainId)
                      )
                    }}"
                    target="_blank"
                    class="d-block text-0066FF dark:text-13B9AA underline overflow-hidden overflow-ellipsis hash-tag mt-1"
                    [nz-tooltip]="item.hash"
                    [nzTooltipPlacement]="'top'"
                  >
                    {{ item.hash }}
                  </a>

                  <a
                    *ngIf="item.destTxHash && item.destChainId"
                    href="{{
                      web3Service.getScanTransactionUrl(
                        item.destTxHash,
                        parseInt(item.destChainId)
                      )
                    }}"
                    target="_blank"
                    class="d-block text-0066FF dark:text-13B9AA overflow-hidden underline overflow-ellipsis hash-tag mt-1"
                    [nz-tooltip]="item.destTxHash"
                    [nzTooltipPlacement]="'bottom'"
                  >
                    {{ item.destTxHash }}
                  </a>

                  <div
                    class="p-10px bg-0066ff1a rounded-full text-0066FF dark:text-3385FF position-absolute top-0 left-0 mt-1 ms-1"
                  >
                    <span class="absolute-center font-openSans-regular font-12">
                      {{ (table.pageIndex - 1) * table.pageSize + i + 1 }}
                    </span>
                  </div>
                </td>

                <td
                  class="px-0 py-30px border-b-0"
                  [ngClass]="{
                    'bg-F5F5F5 dark:bg-F5F5F50A': odd,
                    'dark:bg-darkBlack': !odd
                  }"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      CheckFirstColTxType;
                      context: { item: item }
                    "
                  ></ng-container>
                </td>

                <!-- arrow -->
                <td
                  class="px-0 py-30px border-0"
                  [ngClass]="
                    odd ? 'bg-F5F5F5 dark:bg-F5F5F50A' : 'dark:bg-darkBlack'
                  "
                >
                  <div
                    class="align-items-center justify-content-end"
                    *ngIf="
                      [TxType.Send, TxType.Receive].indexOf(item.txType) === -1
                    "
                  >
                    <div>
                      <svg
                        width="40"
                        height="8"
                        viewBox="0 0 40 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35.8404 3.16406H0.832031V4.83073H35.8404V7.33073L39.1654 3.9974L35.8404 0.664062V3.16406Z"
                          fill="#0066FF"
                        />
                      </svg>
                    </div>
                  </div>
                </td>

                <td
                  class="px-0 py-30px border-0"
                  [ngClass]="{
                    'bg-F5F5F5 dark:bg-F5F5F50A': odd,
                    'dark:bg-darkBlack': !odd
                  }"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      CheckSecondColTxType;
                      context: { item: item }
                    "
                  ></ng-container>
                </td>

                <td
                  class="py-30px border-0"
                  [ngClass]="
                    odd ? 'bg-F5F5F5 dark:bg-F5F5F50A' : 'dark:bg-darkBlack'
                  "
                >
                  <div class="align-items-right justify-content-end">
                    <div class="dark:text-fff font-14">
                      {{ item.date }}
                    </div>

                    <div class="dark:text-fff dark:opacity-75 font-12">
                      {{ item.time }}
                    </div>
                  </div>
                </td>

                <td
                  class="py-30px border-0"
                  [ngClass]="
                    odd ? 'bg-F5F5F5 dark:bg-F5F5F50A' : 'dark:bg-darkBlack'
                  "
                >
                  <div class="align-items-right justify-content-end">
                    <div
                      *ngFor="let token of item.fees; index as index"
                      class="dark:text-white font-14"
                    >
                      {{ token?.amount }}&nbsp;{{ token?.token }}
                    </div>
                  </div>
                </td>

                <td
                  class="ps-0 pe-2 pt-12px py-30px border-0 border-right-radius-4px"
                  [ngClass]="
                    odd ? 'bg-F5F5F5 dark:bg-F5F5F50A' : 'dark:bg-darkBlack'
                  "
                >
                  <ng-container
                    *ngTemplateOutlet="StatusTemplate; context: { trx: item }"
                  ></ng-container>
                </td>
              </tr>

              <tr
                *ngIf="
                  item.txType === TxType.WithdrawEtf &&
                  item.tokensTransferred.length > 2
                "
                class="py-3"
                [nzExpand]="expandSet.has(item.hash)"
              >
                <ng-container
                  *ngTemplateOutlet="ExpandRow; context: { trx: item }"
                ></ng-container>
              </tr>
            </ng-container>
          </ng-container>

          <!--Mobile mode-->
          <ng-container *ngIf="web3Service.isConnected() && setting.isMobile">
            <ng-container
              *ngFor="let item of transactionTable.data; let last = last"
            >
              <tr>
                <td class="px-0 pt-12px" colspan="3">
                  <div
                    class="d-flex align-items-center font-16 text-0066FF dark:text-3385FF font-openSans-semiBold"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="4.16667"
                        cy="15.0026"
                        rx="1.66667"
                        ry="1.66667"
                        stroke="#0066FF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <ellipse
                        cx="15.8346"
                        cy="5.0026"
                        rx="1.66667"
                        ry="1.66667"
                        stroke="#0066FF"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.8346 6.66406C16.8346 6.11178 16.3869 5.66406 15.8346 5.66406C15.2824 5.66406 14.8346 6.11178 14.8346 6.66406H16.8346ZM9.16797 14.9974L8.46086 14.2903C8.17486 14.5763 8.08931 15.0064 8.24409 15.3801C8.39887 15.7538 8.76351 15.9974 9.16797 15.9974V14.9974ZM12.3751 13.2045C12.7656 12.814 12.7656 12.1808 12.3751 11.7903C11.9846 11.3998 11.3514 11.3998 10.9609 11.7903L12.3751 13.2045ZM10.9609 18.2045C11.3514 18.595 11.9846 18.595 12.3751 18.2045C12.7656 17.814 12.7656 17.1808 12.3751 16.7903L10.9609 18.2045ZM9.87508 14.2903C9.48455 13.8998 8.85139 13.8998 8.46086 14.2903C8.07034 14.6808 8.07034 15.314 8.46086 15.7045L9.87508 14.2903ZM14.8346 6.66406V10.8307H16.8346V6.66406H14.8346ZM14.8346 10.8307C14.8346 12.5796 13.4169 13.9974 11.668 13.9974V15.9974C14.5214 15.9974 16.8346 13.6842 16.8346 10.8307H14.8346ZM11.668 13.9974H9.16797V15.9974H11.668V13.9974ZM9.87508 15.7045L12.3751 13.2045L10.9609 11.7903L8.46086 14.2903L9.87508 15.7045ZM12.3751 16.7903L9.87508 14.2903L8.46086 15.7045L10.9609 18.2045L12.3751 16.7903Z"
                        fill="#0066FF"
                      />
                      <path
                        d="M3.16797 13.3333C3.16797 13.8856 3.61568 14.3333 4.16797 14.3333C4.72025 14.3333 5.16797 13.8856 5.16797 13.3333H3.16797ZM10.8346 5V6C11.2391 6 11.6037 5.75636 11.7585 5.38268C11.9133 5.00901 11.8277 4.57889 11.5417 4.29289L10.8346 5ZM9.04174 1.79289C8.65122 1.40237 8.01805 1.40237 7.62753 1.79289C7.237 2.18342 7.237 2.81658 7.62753 3.20711L9.04174 1.79289ZM7.62753 6.79289C7.237 7.18342 7.237 7.81658 7.62753 8.20711C8.01805 8.59763 8.65122 8.59763 9.04174 8.20711L7.62753 6.79289ZM11.5417 5.70711C11.9323 5.31658 11.9323 4.68342 11.5417 4.29289C11.1512 3.90237 10.5181 3.90237 10.1275 4.29289L11.5417 5.70711ZM5.16797 13.3333V9.16667H3.16797V13.3333H5.16797ZM5.16797 9.16667C5.16797 7.41776 6.58573 6 8.33464 6V4C5.48116 4 3.16797 6.3132 3.16797 9.16667H5.16797ZM8.33464 6H10.8346V4H8.33464V6ZM11.5417 4.29289L9.04174 1.79289L7.62753 3.20711L10.1275 5.70711L11.5417 4.29289ZM9.04174 8.20711L11.5417 5.70711L10.1275 4.29289L7.62753 6.79289L9.04174 8.20711Z"
                        fill="#0066FF"
                      />
                    </svg>

                    {{ formatTxType(item.txType) || "Unknown" }}

                    <nz-tag
                      *ngIf="item.txType === TxType.CrossChainLimitOrder"
                      class="ms-2 rounded-full font-12"
                      [nzColor]="'#000'"
                      >Limit Order</nz-tag
                    >
                  </div>

                  <a
                    href="{{
                      web3Service.getScanTransactionUrl(
                        item.hash,
                        parseInt(item.chainId)
                      )
                    }}"
                    target="_blank"
                    class="d-block text-0066FF dark:text-3385FF overflow-hidden underline overflow-ellipsis hash-tag mt-1"
                    [nz-tooltip]="item.hash"
                    [nzTooltipPlacement]="'top'"
                  >
                    {{ item.hash }}
                  </a>

                  <a
                    *ngIf="item.destTxHash && item.destChainId"
                    href="{{
                      web3Service.getScanTransactionUrl(
                        item.destTxHash,
                        parseInt(item.destChainId)
                      )
                    }}"
                    target="_blank"
                    class="d-block text-0066FF dark:text-3385FF overflow-hidden underline overflow-ellipsis hash-tag mt-1"
                    [nz-tooltip]="item.destTxHash"
                    [nzTooltipPlacement]="'bottom'"
                  >
                    {{ item.destTxHash }}
                  </a>
                </td>

                <td class="px-0 pt-12px" colspan="2">
                  <ng-container
                    *ngTemplateOutlet="StatusTemplate; context: { trx: item }"
                  ></ng-container>
                </td>
              </tr>

              <tr
                *ngIf="
                  item.txType === TxType.WithdrawEtf &&
                  item.tokensTransferred.length > 2
                "
                class="py-3"
                [nzExpand]="expandSet.has(item.hash)"
              >
                <ng-container
                  *ngTemplateOutlet="ExpandRow; context: { trx: item }"
                ></ng-container>
              </tr>

              <tr>
                <td class="px-0 pb-12px" colspan="2">
                  <ng-container
                    *ngTemplateOutlet="
                      CheckFirstColTxType;
                      context: { item: item }
                    "
                  ></ng-container>
                </td>

                <!-- arrow -->
                <td class="px-0 pb-12px" colspan="1">
                  <div
                    class="align-items-center justify-content-end"
                    *ngIf="
                      [TxType.Send, TxType.Receive].indexOf(item.txType) === -1
                    "
                  >
                    <div>
                      <svg
                        width="40"
                        height="8"
                        viewBox="0 0 40 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35.8404 3.16406H0.832031V4.83073H35.8404V7.33073L39.1654 3.9974L35.8404 0.664062V3.16406Z"
                          fill="#0066FF"
                        />
                      </svg>
                    </div>
                  </div>
                </td>

                <td class="px-0 pb-12px" colSpan="2">
                  <ng-container
                    *ngTemplateOutlet="
                      CheckSecondColTxType;
                      context: { item: item }
                    "
                  ></ng-container>
                </td>
              </tr>

              <tr>
                <td
                  class="border-BFD8FF border-solid border-bottom-1px pb-12px"
                  colspan="2"
                >
                  <div class="align-items-right justify-content-end">
                    <div class="dark:text-fff font-14">
                      {{ item.date }}
                    </div>

                    <div class="dark:text-fff dark:opacity-75 font-12">
                      {{ item.time }}
                    </div>
                  </div>
                </td>
                <td
                  class="border-BFD8FF border-solid border-bottom-1px pb-12px"
                  colspan="1"
                ></td>
                <td
                  class="border-BFD8FF border-solid border-bottom-1px pb-12px"
                  colspan="2"
                >
                  <div class="align-items-right justify-content-end">
                    <span class="dark:text-fff font-14"> Fee: </span>

                    <div
                      *ngFor="let token of item.fees; index as index"
                      class="dark:text-white font-openSans-semiBold font-14 font-openSans-semiBold"
                    >
                      {{ token?.amount }}&nbsp;{{ token?.token }}
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>

  <ng-template #CheckFirstColTxType let-item="item">
    <ng-container [ngSwitch]="item.txType">
      <ng-container *ngSwitchCase="TxType.Receive">
        <ng-container
          *ngTemplateOutlet="
            TransferFrom;
            context: { item: item, type: item.txType }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TxType.Send">
        <ng-container
          *ngTemplateOutlet="
            TransferTo;
            context: { item: item, type: item.txType }
          "
        ></ng-container>
      </ng-container>

      <ng-container
        *ngSwitchCase="
          [TxType.WithdrawReward, TxType.WithdrawLP, TxType.UnStake].indexOf(
            item.txType
          ) > -1
            ? item.txType
            : false
        "
      >
        <ng-container
          *ngTemplateOutlet="
            OpportunityName;
            context: { item: item, type: item.txType }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container
          *ngTemplateOutlet="
            FromTransferredTokens;
            context: { item: item, type: item.txType }
          "
        ></ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #CheckSecondColTxType let-item="item">
    <ng-container [ngSwitch]="item.txType">
      <ng-container *ngSwitchCase="TxType.Send">
        <ng-container
          *ngTemplateOutlet="
            FromTransferredTokens;
            context: { item: item, type: item.txType }
          "
        ></ng-container>
      </ng-container>

      <ng-container
        *ngSwitchCase="
          [TxType.Farming, TxType.Stake].indexOf(item.txType) > -1
            ? item.txType
            : false
        "
      >
        <ng-container
          *ngTemplateOutlet="
            OpportunityName;
            context: { item: item, type: item.txType }
          "
        ></ng-container>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container
          *ngTemplateOutlet="
            ToTransferredTokens;
            context: { item: item, type: item.txType }
          "
        ></ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #WalletConnect>
    <div
      class="d-flex flex-column align-items-center justify-content-center mt-3 mb-3 w-full"
    >
      <img src="assets/media/icons/wallet-not-connect.png" width="200" />

      <span
        class="font-openSans-semiBold d-flex mt-3 justify-center dark:text-textWhite"
      >
        Wallet is not connected
      </span>

      <button
        (click)="connectWallet()"
        nz-button
        nzType="default"
        [nzSize]="'default'"
        class="px-20px d-flex align-items-center justify-content-center font-openSans-semiBold font-16 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
      >
        Connect wallet
      </button>
    </div>
  </ng-template>

  <ng-template #ComingSoon>
    <div
      class="d-flex flex-column align-items-center dark:text-fff justify-content-center mt-3 mb-3 w-full"
    >
      <div class="flex-column justify-content-center align-items-center">
        <div
          class="justify-content-center text-center align-items-start d-md-flex font-16 font-openSans-semiBold"
        >
          <div class="flex justify-content-center">
            <img style="width: 24px" src="assets/media/icons/party.png" />
          </div>

          Coming Soon: Transaction History for
          {{ web3Service.networkSpec[selectedNetwork].title }}!
        </div>

        <div class="flex justify-content-center text-center align-items-center">
          We're excited to let you know that we're currently developing the
          Transaction History feature for
          {{ web3Service.networkSpec[selectedNetwork].title }}. It's not
          available just yet, but we're working hard to bring it to you soon.
          Stay tuned for updates, and thank you for using CrowdSwap!
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #FromTransferredTokens let-item="item" let-type="type">
  <div class="w-full d-flex flex-column">
    <span
      class="font-12 text-2D2D2D dark:text-fff"
      *ngIf="type === TxType.Send"
    >
      Token & amount:
    </span>

    <div class="w-full token-symbol-section dark:text-fff align-items-center">
      <ng-container *ngIf="type === TxType.WithdrawEtf; else other">
        <div
          class="w-full d-flex"
          [ngClass]="{
            'flex-column': setting.isMobile
          }"
        >
          <div class="d-flex items-center">
            <img
              width="32"
              [src]="
                item.etf.etfName | getETFImage : item.etf.planName : isDarkMode
              "
            />

            <div class="ms-2">
              <div class="font-14 font-openSans-semiBold">
                {{ item.etf.planName }}
              </div>

              <div class="font-10 text-000 dark:text-fff">
                {{ web3Service.networkSpec[item.chainId]?.title }} (10 Tokens)
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #other>
        <ng-container
          *ngFor="let token of item.tokensTransferred; index as index"
        >
          <div
            class="w-full d-flex"
            [ngClass]="{ 'flex-column': setting.isMobile }"
            *ngIf="token.transferType === 'from'"
          >
            <div class="d-flex items-center">
              <img
                class="rounded-full symbol-icon"
                [src]="
                  baseLogoIconsUrl + token.token?.symbol?.toLowerCase() + '.png'
                "
                (error)="updateUrl($event)"
              />

              <div class="flex-column ms-2 text-left">
                <div class="font-14 font-openSans-semiBold">
                  {{ token.token?.symbol }}
                </div>

                <div class="font-10 text-000 dark:text-fff">
                  {{ web3Service.networkSpec[item.chainId]?.title }}
                </div>
              </div>
            </div>

            <div
              class="mt-1 mb-2 ml-2 px-2 py-1 bg-ff103b1a dark:text-fff dark:opacity-75 rounded-md font-14 font-openSans-semiBold d-flex align-items-center"
            >
              <ng-container *ngTemplateOutlet="OutIcon"></ng-container>

              <span class="ms-1">
                -
                {{ token.amount }}
              </span>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #ToTransferredTokens let-item="item" let-type="type">
  <div class="w-full d-flex flex-column">
    <span
      class="font-12 text-2D2D2D dark:text-fff"
      *ngIf="type === TxType.Receive"
    >
      Token & amount:
    </span>

    <div
      class="w-full token-symbol-section dark:text-fff align-items-center"
      [ngClass]="{ 'justify-content-center': type === TxType.Swap }"
    >
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="TxType.InvestEtf">
          <div
            class="w-full d-flex"
            [ngClass]="{
              'flex-column': setting.isMobile
            }"
          >
            <div class="d-flex items-center">
              <img
                width="32"
                [src]="
                  isDarkMode
                    ? 'assets/media/etf/BSC_ETF_BullRunETF_dark.png'
                    : 'assets/media/etf/BSC_ETF_BullRunETF.png'
                "
              />
              <div class="ms-2">
                <div class="font-14 font-openSans-semiBold">BullRun ETF</div>

                <div class="font-10 text-000 dark:text-fff">
                  {{ web3Service.networkSpec[item.chainId]?.title }} (10 Tokens)
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngSwitchCase="
            type === TxType.WithdrawEtf && item.tokensTransferred.length > 2
              ? type
              : false
          "
        >
          <div
            class="w-full d-flex"
            [ngClass]="{
              'flex-column': setting.isMobile
            }"
          >
            <div class="d-flex items-center">
              <div class="d-flex align-items-center flex-shrink-0">
                <ng-container
                  *ngFor="let token of item.tokensTransferred; index as i"
                >
                  <div
                    class="d-flex flex-shrink-0"
                    [ngClass]="{ 'ml--10px': i > 0 }"
                    *ngIf="i <= 2"
                  >
                    <img
                      class="rounded-full symbol-icon"
                      [src]="
                        baseLogoIconsUrl +
                        token.token?.symbol?.toLowerCase() +
                        '.png'
                      "
                    />
                  </div>

                  <div
                    *ngIf="i === 3"
                    class="rounded-full symbol-icon position-relative bg-000 d-flex align-items-center justify-content-center ml--10px flex-shrink-0"
                  >
                    <span
                      class="position-absolute font-11 text-fff dark:text-000"
                    >
                      +7
                      <!-- {{ item.tokensTransferred.length - 3 }} -->
                    </span>
                  </div>
                </ng-container>
              </div>

              <div class="ms-2 w-full d-flex flex-column">
                <div class="w-full text-wrap font-14 font-openSans-semiBold">
                  <ng-container
                    *ngFor="let token of item.tokensTransferred; index as i"
                  >
                    <ng-container *ngIf="i <= 2">
                      {{ token.token.symbol }}
                    </ng-container>

                    <ng-container *ngIf="i < 2"> , </ng-container>
                  </ng-container>

                  <ng-container>
                    <span class="ms-1">
                      & 7
                      <!-- {{ item.tokensTransferred.length - 3 }}  -->
                      other tokens
                    </span>
                  </ng-container>
                </div>

                <div
                  class="font-12 text-000 dark:text-fff font-openSans-regular"
                >
                  {{ web3Service.networkSpec[item.chainId]?.title }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <ng-container
            *ngFor="let token of item.tokensTransferred; index as index"
          >
            <ng-container *ngIf="token.transferType === 'to'">
              <div
                class="w-full d-flex"
                [ngClass]="{
                  'flex-column': setting.isMobile,
                  'opacity-500': item.newAmountOutVisibility
                }"
              >
                <div class="d-flex items-center">
                  <img
                    class="rounded-full symbol-icon"
                    [src]="
                      baseLogoIconsUrl +
                      token.token?.symbol?.toLowerCase() +
                      '.png'
                    "
                    (error)="updateUrl($event)"
                  />

                  <div class="ms-2">
                    <div class="font-14 font-openSans-semiBold">
                      {{ token.token?.symbol }}
                    </div>

                    <div class="font-10 text-000 dark:text-fff">
                      {{ web3Service.networkSpec[token.token.chainId]?.title }}
                    </div>
                  </div>
                </div>

                <div
                  class="mt-1 mb-2 px-2 py-1 ml-2px d-flex align-items-center bg-1DB4741A dark:text-fff dark:opacity-75 rounded-md font-14 font-openSans-semiBold"
                >
                  <ng-container *ngTemplateOutlet="InIcon"></ng-container>

                  <span class="ms-1">
                    +
                    {{ token.amount }}
                  </span>
                </div>
              </div>

              <div
                class="w-max-content d-flex flex-column mt-2 border-radius-4px border-1px border-solid border-0B9ED9 p-1"
                *ngIf="item.newAmountOutVisibility"
              >
                <span class="font-10"> New Estimation: </span>

                <div
                  class="d-flex w-full mt-1"
                  [ngClass]="{ 'flex-column': setting.isMobile }"
                >
                  <div class="d-flex items-center">
                    <img
                      class="rounded-full symbol-icon"
                      [src]="
                        baseLogoIconsUrl +
                        token.token?.symbol?.toLowerCase() +
                        '.png'
                      "
                      (error)="updateUrl($event)"
                    />

                    <div class="ms-2">
                      <div class="font-14 font-openSans-semiBold">
                        {{ token.token?.symbol }}
                      </div>

                      <div class="font-10 text-000 dark:text-fff">
                        {{
                          web3Service.networkSpec[token.token.chainId]?.title
                        }}
                      </div>
                    </div>
                  </div>

                  <div
                    class="mt-1 mb-2 bg-1DB4741A px-2 py-1 ml-2px dark:text-fff rounded-md font-14 font-openSans-semiBold d-flex align-items-center"
                  >
                    <ng-container *ngTemplateOutlet="InIcon"></ng-container>

                    <span class="ms-1">
                      +
                      {{ item.newAmountOutToDisplay }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #StatusTemplate let-trx="trx">
  <div class="w-full d-flex flex-column align-items-end">
    <ng-container [ngSwitch]="trx.txStatus">
      <ng-container *ngSwitchCase="TxStatus.TXFailed">
        <ng-container *ngTemplateOutlet="Failed"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TxStatus.TransactionConfirmed">
        <ng-container [ngSwitch]="trx.txType">
          <ng-container
            *ngSwitchCase="
              [
                TxType.Swap,
                TxType.Stake,
                TxType.WithdrawReward,
                TxType.WithdrawLP,
                TxType.Farming,
                TxType.Send,
                TxType.Receive,
                TxType.UnStake,
                TxType.InvestEtf,
                TxType.WithdrawEtf
              ].indexOf(trx.txType) > -1
                ? trx.txType
                : false
            "
          >
            <ng-container
              *ngTemplateOutlet="Completed; context: { trx: trx }"
            ></ng-container>
          </ng-container>

          <ng-container
            *ngSwitchCase="
              [TxType.CrossChain, TxType.CrossChainLimitOrder].indexOf(
                trx.txType
              ) > -1
                ? trx.txType
                : false
            "
          >
            <ng-container
              *ngIf="
                trx.destTxStatus !== DestTxStatus.Completed;
                else Completed
              "
            >
              <ng-container [ngSwitch]="trx.destTxStatus">
                <ng-container *ngSwitchCase="DestTxStatus.Pending">
                  <ng-container
                    *ngTemplateOutlet="
                      InProgress;
                      context: { showCancel: true, trx: trx }
                    "
                  ></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="DestTxStatus.CancelPending">
                  <ng-container *ngTemplateOutlet="Cancelling"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="DestTxStatus.Failed">
                  <ng-container *ngTemplateOutlet="Failed"></ng-container>
                </ng-container>

                <ng-container
                  *ngSwitchCase="
                    [undefined, DestTxStatus.Unknown].indexOf(
                      trx.destTxStatus
                    ) > -1
                      ? trx.destTxStatus
                      : null
                  "
                >
                  <ng-container *ngTemplateOutlet="InProgress"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="DestTxStatus.Canceled">
                  <ng-container *ngTemplateOutlet="Canceled"></ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TxStatus.TXUnConfirmed">
        <ng-container *ngTemplateOutlet="InProgress"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="TxStatus.Cancelling">
        <ng-container *ngTemplateOutlet="Cancelling"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #Failed>
  <div
    class="rounded-lg p-1 mx-1 bg-ff103b1a font-12 font-openSans-semiBold text-FF0000 d-inline-block"
  >
    Failed
  </div>
</ng-template>

<ng-template #Completed let-trx="trx">
  <div class="d-flex flex-column align-items-end max-w-100">
    <div
      class="rounded-full p-1 ml-2 bg-1DB4741A text-1DB474 font-openSans-semiBold font-12"
    >
      Completed
    </div>

    <!-- <div
      *ngIf="
        trx.txType === TxType.WithdrawEtf && trx.tokensTransferred.length > 2
      "
      class="border-radius-3px p-1 ml-2 bg-blue-100 dark:bg-opacity-10 text-0066FF dark:text-3385FF d-flex mx-1 align-items-center justify-content-between font-openSans-semiBold font-12 transition-03-linear mt-1 cursor-pointer"
      (click)="onExpandChange(trx.hash)"
    >
      <span>
        {{ expandSet.has(trx.hash) ? "Hide" : "Detail" }}
      </span>

      <span class="ms-1" [ngClass]="{ 'rotate-180': !expandSet.has(trx.hash) }">
        <svg
          width="10"
          height="7"
          viewBox="0 0 10 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.5575 6.0575L5 2.6225L8.4425 6.0575L9.5 5L5 0.5L0.5 5L1.5575 6.0575Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </div> -->
  </div>
</ng-template>

<ng-template #InProgress let-showCancel="showCancel" let-trx="trx">
  <div
    class="w-max-content rounded-full p-1 d-flex flex-shrink-0 align-items-center bg-blue-100 dark:bg-opacity-10 text-0066FF dark:text-3385FF"
  >
    <div
      class="pl-1 d-flex w-full font-openSans-semiBold font-12 align-items-center justify-content-between"
      [ngClass]="{ 'pr-1': !showCancel }"
    >
      In Progress
    </div>

    <div
      *ngIf="showCancel && trx.isCancelable"
      class="p-1 ml-1 d-flex align-items-center bg-FF0000 font-12 rounded-full text-fff cursor-pointer"
      (click)="cancelTransaction(trx)"
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.0711 2.92893C20.9763 6.83418 20.9763 13.1658 17.0711 17.0711C13.1658 20.9763 6.83418 20.9763 2.92893 17.0711C-0.976311 13.1658 -0.976311 6.83418 2.92893 2.92893C6.83418 -0.976311 13.1658 -0.976311 17.0711 2.92893ZM14.8319 5.40381L14.5962 5.1681C14.3595 4.93142 13.9891 4.90991 13.7282 5.10355L13.6534 5.1681L10.0006 8.8209L6.34661 5.1681C6.10993 4.93142 5.73957 4.90991 5.47858 5.10355L5.40381 5.1681L5.1681 5.40381C4.93142 5.64049 4.90991 6.01086 5.10355 6.27184L5.1681 6.34661L8.82208 9.99941L5.1681 13.6534C4.93142 13.8901 4.90991 14.2604 5.10355 14.5214L5.1681 14.5962L5.40381 14.8319C5.64049 15.0686 6.01086 15.0901 6.27184 14.8964L6.34661 14.8319L10.0006 11.1779L13.6534 14.8319C13.8901 15.0686 14.2604 15.0901 14.5214 14.8964L14.5962 14.8319L14.8319 14.5962C15.0686 14.3595 15.0901 13.9891 14.8964 13.7282L14.8319 13.6534L11.1791 9.99941L14.8319 6.34661C15.0686 6.10993 15.0901 5.73957 14.8964 5.47858L14.8319 5.40381Z"
          fill="white"
        />
      </svg>

      <span class="ml-1"> Cancel </span>
    </div>
  </div>

  <ng-container *ngIf="trx && trx.newAmountOut && trx.newAmountOut > 0">
    <button
      *ngIf="!trx.newAmountOutVisibility"
      nz-button
      nzType="default"
      (click)="trx.newAmountOutVisibility = true"
      [nzSize]="'small'"
      class="w-max-content px-2 d-flex align-items-center justify-content-between font-openSans-semiBold font-14 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-2"
    >
      <span class="px-1"> Check New Quote </span>

      <span>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.04917 3.92731C9.34852 3.006 10.6519 3.006 10.9513 3.92731L12.0209 7.21911C12.1547 7.63113 12.5387 7.91009 12.9719 7.91009H16.4331C17.4018 7.91009 17.8046 9.14971 17.0209 9.71911L14.2207 11.7536C13.8702 12.0082 13.7236 12.4596 13.8575 12.8716L14.927 16.1634C15.2264 17.0847 14.1719 17.8508 13.3882 17.2814L10.588 15.247C10.2375 14.9923 9.76293 14.9923 9.41244 15.247L6.61227 17.2814C5.82856 17.8508 4.77408 17.0847 5.07343 16.1634L6.143 12.8716C6.27688 12.4596 6.13022 12.0082 5.77973 11.7536L2.97956 9.71911C2.19585 9.14971 2.59862 7.91009 3.56735 7.91009H7.02855C7.46177 7.91009 7.84573 7.63113 7.9796 7.21911L9.04917 3.92731Z"
            fill="#FBD127"
          />
        </svg>
      </span>
    </button>

    <button
      *ngIf="trx.newAmountOutVisibility"
      nz-button
      nzType="default"
      (click)="approvePatchNewAmount(trx)"
      [nzSize]="'small'"
      class="w-max-content px-2 d-flex align-items-center justify-content-center font-openSans-semiBold font-14 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-2"
    >
      <span class="px-1"> Approve New Quote </span>
    </button>
  </ng-container>
</ng-template>

<ng-template #Cancelling>
  <div
    class="rounded-lg p-1 w-full d-flex flex-shrink-0 align-items-center bg-blue-100 text-blue-500"
  >
    <div class="pl-1 d-flex w-full align-items-center justify-content-between">
      Cancelling...
    </div>
  </div>
</ng-template>

<ng-template #Canceled>
  <div class="rounded-lg p-1 mx-1 bg-ff103b1a dark:text-red-500 d-inline-block">
    Canceled
  </div>
</ng-template>

<ng-template #OpportunityName let-item="item">
  <div class="w-full d-flex flex-column">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <img
          class="z-1 rounded-full symbol-icon"
          [ngClass]="{ 'mr-2': !item?.opportunity?.tokenB }"
          [src]="
            baseLogoIconsUrl +
            item?.opportunity?.tokenA?.symbol?.toLowerCase() +
            '.png'
          "
          (error)="updateUrl($event)"
        />
        <img
          class="rounded-full ml--10px z-1 symbol-icon"
          *ngIf="item?.opportunity?.tokenB"
          [src]="
            baseLogoIconsUrl +
            item?.opportunity?.tokenB?.symbol?.toLowerCase() +
            '.png'
          "
          (error)="updateUrl($event)"
        />
      </div>

      <div
        class="d-flex flex-column pl-2 text-left"
        [style.width]="setting.isMobile ? '100px' : '160px'"
      >
        <div
          class="font-14 w-full overflow-ellipsis-100 dark:text-fff font-openSans-semiBold"
          [nz-tooltip]="item?.opportunity?.name"
        >
          {{ item?.opportunity?.name }}
        </div>

        <div class="font-10 text-000 dark:text-fff">
          {{ web3Service.networkSpec[item?.chainId]?.title }}
        </div>
      </div>
    </div>

    <ng-container *ngIf="item.txType === TxType.Farming">
      <ng-container *ngFor="let token of item.tokensTransferred; index as i">
        <div
          class="d-flex align-items-center w-full"
          *ngIf="token.transferType === 'to'"
        >
          <div
            class="mt-1 mb-2 px-2 py-1 ml-2px d-flex align-items-center bg-1DB4741A dark:text-fff dark:opacity-75 rounded-md font-14 font-openSans-semiBold"
          >
            <ng-container *ngTemplateOutlet="InIcon"></ng-container>

            <span class="ms-1">
              +
              {{ token.amount }}
            </span>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #TransferFrom let-item="item">
  <div class="w-full d-flex flex-column">
    <span class="font-12 text-2D2D2D dark:text-fff"> From: </span>

    <a
      href="{{
        web3Service.getScanAddressUrl(item.from, parseInt(item.chainId))
      }}"
      target="_blank"
      class="font-14 text-0066FF dark:text-3385FF underline overflow-hidden overflow-ellipsis hash-tag"
    >
      {{ item?.from.substring(0, 7) }}..{{ item?.from.substring(36, 42) }}
    </a>
  </div>
</ng-template>

<ng-template #TransferTo let-item="item">
  <div class="w-full d-flex flex-column">
    <span class="font-12 text-2D2D2D dark:text-fff"> To: </span>

    <a
      href="{{
        web3Service.getScanAddressUrl(item.from, parseInt(item.chainId))
      }}"
      target="_blank"
      class="font-14 text-0066FF dark:text-3385FF underline overflow-hidden overflow-ellipsis hash-tag"
    >
      {{ item?.to.substring(0, 7) }}..{{ item?.to.substring(36, 42) }}
    </a>
  </div>
</ng-template>

<ng-template #exchangeIcon>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="4.16667"
      cy="15.0026"
      rx="1.66667"
      ry="1.66667"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse
      cx="15.8346"
      cy="5.0026"
      rx="1.66667"
      ry="1.66667"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.8346 6.66406C16.8346 6.11178 16.3869 5.66406 15.8346 5.66406C15.2824 5.66406 14.8346 6.11178 14.8346 6.66406H16.8346ZM9.16797 14.9974L8.46086 14.2903C8.17486 14.5763 8.08931 15.0064 8.24409 15.3801C8.39887 15.7538 8.76351 15.9974 9.16797 15.9974V14.9974ZM12.3751 13.2045C12.7656 12.814 12.7656 12.1808 12.3751 11.7903C11.9846 11.3998 11.3514 11.3998 10.9609 11.7903L12.3751 13.2045ZM10.9609 18.2045C11.3514 18.595 11.9846 18.595 12.3751 18.2045C12.7656 17.814 12.7656 17.1808 12.3751 16.7903L10.9609 18.2045ZM9.87508 14.2903C9.48455 13.8998 8.85139 13.8998 8.46086 14.2903C8.07034 14.6808 8.07034 15.314 8.46086 15.7045L9.87508 14.2903ZM14.8346 6.66406V10.8307H16.8346V6.66406H14.8346ZM14.8346 10.8307C14.8346 12.5796 13.4169 13.9974 11.668 13.9974V15.9974C14.5214 15.9974 16.8346 13.6842 16.8346 10.8307H14.8346ZM11.668 13.9974H9.16797V15.9974H11.668V13.9974ZM9.87508 15.7045L12.3751 13.2045L10.9609 11.7903L8.46086 14.2903L9.87508 15.7045ZM12.3751 16.7903L9.87508 14.2903L8.46086 15.7045L10.9609 18.2045L12.3751 16.7903Z"
      fill="currentColor"
    />
    <path
      d="M3.16797 13.3333C3.16797 13.8856 3.61568 14.3333 4.16797 14.3333C4.72025 14.3333 5.16797 13.8856 5.16797 13.3333H3.16797ZM10.8346 5V6C11.2391 6 11.6037 5.75636 11.7585 5.38268C11.9133 5.00901 11.8277 4.57889 11.5417 4.29289L10.8346 5ZM9.04174 1.79289C8.65122 1.40237 8.01805 1.40237 7.62753 1.79289C7.237 2.18342 7.237 2.81658 7.62753 3.20711L9.04174 1.79289ZM7.62753 6.79289C7.237 7.18342 7.237 7.81658 7.62753 8.20711C8.01805 8.59763 8.65122 8.59763 9.04174 8.20711L7.62753 6.79289ZM11.5417 5.70711C11.9323 5.31658 11.9323 4.68342 11.5417 4.29289C11.1512 3.90237 10.5181 3.90237 10.1275 4.29289L11.5417 5.70711ZM5.16797 13.3333V9.16667H3.16797V13.3333H5.16797ZM5.16797 9.16667C5.16797 7.41776 6.58573 6 8.33464 6V4C5.48116 4 3.16797 6.3132 3.16797 9.16667H5.16797ZM8.33464 6H10.8346V4H8.33464V6ZM11.5417 4.29289L9.04174 1.79289L7.62753 3.20711L10.1275 5.70711L11.5417 4.29289ZM9.04174 8.20711L11.5417 5.70711L10.1275 4.29289L7.62753 6.79289L9.04174 8.20711Z"
      fill="currentColor"
    />
  </svg>
</ng-template>

<ng-template #withdrawIcon>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="7.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 7.5L7.5 12.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 12.5H7.5V7.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #FarmingIcon>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33398 10.8334C6.39248 11.1969 8.80384 13.6082 9.16732 16.6667C10.6649 15.8033 11.6103 14.2277 11.6673 12.5C14.5288 11.4934 16.5022 8.86225 16.6673 5.83337C16.6673 4.45266 15.548 3.33337 14.1673 3.33337C11.1384 3.49852 8.50728 5.4719 7.50065 8.33337C5.77299 8.39043 4.19734 9.33582 3.33398 10.8334"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.83307 11.6666C4.05885 12.6683 3.06985 14.6463 3.33307 16.6666C5.35343 16.9298 7.33143 15.9408 8.33307 14.1666"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <ellipse
      cx="12.4993"
      cy="7.49996"
      rx="0.833333"
      ry="0.833333"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #RewardIcon>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.5"
      y="6.66663"
      width="15"
      height="3.33333"
      rx="1"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0007 6.66663V17.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8327 10V15.8333C15.8327 16.7538 15.0865 17.5 14.166 17.5H5.83268C4.91221 17.5 4.16602 16.7538 4.16602 15.8333V10"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.24935 6.66667C5.09876 6.66667 4.16602 5.73393 4.16602 4.58333C4.16602 3.43274 5.09876 2.5 6.24935 2.5C7.89454 2.47134 9.38246 4.12458 9.99935 6.66667C10.6162 4.12458 12.1042 2.47134 13.7493 2.5C14.8999 2.5 15.8327 3.43274 15.8327 4.58333C15.8327 5.73393 14.8999 6.66667 13.7493 6.66667"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #UnStakeIcon>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="7.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 7.5L7.5 12.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 12.5H7.5V7.5"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #SendIcon>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0007 4.16663V15.8333"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3333 7.49996L10 4.16663"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66602 7.49996L9.99935 4.16663"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #ReceiveIcon>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0007 4.16663V15.8333"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3333 12.5L10 15.8333"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66602 12.5L9.99935 15.8333"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #EtfIcon>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83398 8.33325L10.0007 3.33325L14.1673 8.33325"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5 8.33325L15.8333 14.9999C15.6725 15.9856 14.9713 16.6869 14.1667 16.6666H5.83333C5.02873 16.6869 4.32746 15.9856 4.16667 14.9999L2.5 8.33325H17.5Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="10.0007"
      cy="12.4999"
      r="1.66667"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #ExpandRow let-trx="trx">
  <div class="w-full d-flex flex-column">
    <div class="w-full font-14 font-openSans-semiBold text-000 dark:text-fff">
      Withdraw ETF to {{ trx.tokensTransferred.length }} below tokens:
    </div>

    <div class="w-full d-flex align-items-center flex-wrap my-12px">
      <ng-container *ngFor="let token of trx.tokensTransferred; index as i">
        <div
          class="d-flex align-items-center font-14 font-openSans-semiBold text-000 dark:text-fff my-1 mr-20px"
        >
          <img
            class="rounded-full symbol-icon mr-2"
            [src]="
              baseLogoIconsUrl + token.token?.symbol?.toLowerCase() + '.png'
            "
          />

          <span class="me-2">
            {{ token.token?.symbol }}
          </span>

          <div
            class="border-radius-3px p-1 bg-blue-100 dark:bg-opacity-10 d-flex align-items-center justify-content-between font-openSans-semiBold"
          >
            {{ token.amount }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #InIcon>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00065 10.6667V9.33335H2.60732L10.334 1.60669L9.39398 0.666687L1.66732 8.39335V4.00002H0.333984V10.6667H7.00065Z"
      fill="currentColor"
    />
  </svg>
</ng-template>

<ng-template #OutIcon>
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.99935 0.333313V1.66665H8.39268L0.666016 9.39331L1.60602 10.3333L9.33268 2.60665V6.99998H10.666V0.333313H3.99935Z"
      fill="currentColor"
    />
  </svg>
</ng-template>
