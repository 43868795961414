import { Pipe, PipeTransform } from '@angular/core';
import { CrossAndSameChainEstimateTrade } from '../../model';
import { ExchangeType } from '../../views/pages/cross-and-same-chain-swap/model/cross-chain-state.enum';

@Pipe({
  name: 'checkPendongListCount'
})
export class CheckPendongListCountPipe implements PipeTransform {
  public readonly MAX_PARALLEL_TRANSACTION = 2;

  transform(value: CrossAndSameChainEstimateTrade[]): boolean {
    const crossAndSameChainCount = value?.filter(
      (data) => data.exchangeType !== ExchangeType.Limit
    );

    return crossAndSameChainCount?.length < this.MAX_PARALLEL_TRANSACTION;
  }
}
