<div
  *ngIf="startTime < currentTime && finishTime > currentTime && !isClosed"
  class="listing-banner d-flex position-fixed bottom-0 z-20 w-full align-items-center justify-content-center"
>
  <div class="position-fixed z-20 right-1 mr-1 dark:text-textWhite">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 flex items-center text-fff cursor-pointer"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      (click)="close()"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  </div>

  <a [href]="bannerLink" target="_blank" class="w-full">
    <ng-lottie
      class="w-full"
      containerClass="moving-box another-class"
      [options]="defaultOptions"
    ></ng-lottie>
  </a>
</div>
