import {
  Component,
  EventEmitter,
  NgModule,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';

@Component({
  selector: 'app-change-network-dialog',
  templateUrl: './change-network-dialog.component.html',
  styleUrls: ['./change-network-dialog.component.scss']
})
export class ChangeNetworkDialogComponent extends DialogsComponent {
  @Output()
  confirmed = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  confirm(confirm) {
    this.confirmed.emit(confirm);
  }
}
@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [ChangeNetworkDialogComponent]
})
export class ChangeNetworkDialogComponentModule {}
