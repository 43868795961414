import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base.component';
import {
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../../services';
import { VipService } from '../../../../services';

@Component({
  selector: 'app-vip-ranking',
  templateUrl: './vip-ranking.component.html',
  styleUrls: ['./vip-ranking.component.scss']
})
export class VipRankingComponent extends BaseComponent implements OnInit {
  public userStatus: {
    score: number;
    stage: number;
    rules: {};
  } = {
    score: 0,
    stage: 0,
    rules: {}
  };
  public showBanner: boolean = true;

  constructor(
    protected web3Service: Web3Service,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    public vipService: VipService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit() {
    super.ngOnInit(undefined, undefined, async (address) => {
      this.userStatus = await this.vipService.getVipStatus(address);
    });

    if (this.isUserWalletConnected) {
      this.userStatus = await this.vipService.getVipStatus(
        this.web3Service.getWalletAddress()
      );
    }
  }

  public disableBanner() {
    this.showBanner = false;
  }
}
