<div class="row justify-center h-screen w-full p-md-5">
  <div *ngIf="!lpDetails" class="col-12 col-sm-12 col-md-5 x-1 z-0">
    <div class="margin border rounded-lg dark:bg-appLightBackground">
      <div class="swap-header">
        <div class="title mt-3 pl-5 justify-center dark:text-darkGrayText">
          Select asset from your wallet to buy

          <span class="opportunity-name">
            {{ activePresale.presaleTokenName }}
          </span>

          {{
            investmentType === InvestmentType.ByCrossChain
              ? " on " + web3Service.networkSpec[activePresale.chainId].title
              : ""
          }}
        </div>
      </div>

      <div class="swap-box z-0" id="assetBox">
        <div class="rounded-md bg-blue-50 dark:bg-darkBlack p-2 m-2">
          <div
            class="swap-token-box p-3 border-2 bg-white dark:bg-darkBlack cursor-pointer border-0066FF dark:border-gray-600"
            id="choose-asset-token"
          >
            <div class="swap-token-select">
              <div class="dropdown w-1/2 md:w-2/3 lg:w-7/12">
                <button
                  id="source-option"
                  (click)="openSelectOptionDialog(currentTokenList)"
                  [disabled]="
                    loadingAmount ||
                    opportunityState === OpportunityState.Confirmed
                  "
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="justify-center rounded-lg pl-1 pr-1 border-0 bg-white text-sm font-medium text-gray-700 dark:bg-darkBlack"
                  type="button"
                >
                  <span class="token-symbol">
                    <img
                      class="rounded-full"
                      width="20"
                      [src]="
                        baseLogoIconsUrl + token?.symbol?.toLowerCase() + '.png'
                      "
                      (error)="updateUrl($event)"
                    />
                  </span>

                  <div class="token-selected pl-4 dark:text-white">
                    <div class="symbol">
                      {{ token?.symbol }}
                    </div>
                  </div>

                  <div class="token-arrow dark:text-textWhite">
                    <svg
                      class="-mr-1 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              </div>

              <div class="absolute right-5 mr-4 pr-2 w-1/2 md:w-1/3 lg:w-5/12">
                <div class="count">
                  <button
                    (click)="setMaxBalance(token)"
                    [disabled]="
                      loadingAmount ||
                      opportunityState === OpportunityState.Confirmed
                    "
                  >
                    Max
                  </button>
                </div>

                <div class="balance text-gray-400 dark:text-darkGrayText">
                  Balance:
                  {{ !dialogOpened ? toFixFraction(token?.balance) : "" }}
                </div>

                <div [ngClass]="{ 'animate-pulse': loadingAmount }">
                  <input
                    appFocusAndSelectValue
                    [el]="tokenAmountInput"
                    #tokenAmountInput
                    type="text"
                    inputmode="decimal"
                    (ngModelChange)="notifyTokenInput($event)"
                    [disabled]="
                      loadingAmount ||
                      opportunityState === OpportunityState.Confirmed
                    "
                    [(ngModel)]="tokenAmount"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    class="font-bold text-xl w-full text-base text-right shadow-none outline-none border-0 p-0 font-16 bg-transparent dark:text-fff"
                    [ngClass]="{
                      'absolute right-0 w-1/2 mt-3 bg-gray-300 text-gray-300 rounded-sm dark:bg-gray-300 dark:text-gray-300':
                        loadingAmount
                    }"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="investment-box m-1 pt-3">
            <div class="swap-token-header">
              <span class="flex justify-start align-items-center">
                <span class="title dark:text-darkGrayText">
                  Estimated investment amount:&nbsp;
                </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  (mouseleave)="tooltipInvestmentAmount = false"
                  (mouseover)="tooltipInvestmentAmount = true"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 dark:text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>

                <div
                  *ngIf="tooltipInvestmentAmount"
                  class="relative"
                  x-cloak
                  x-show.transition.origin.top="tooltip"
                >
                  <div
                    class="absolute top-7 mt-3 z-10 w-48 p-2 mt-1 tooltip-left3 text-sm text-center leading-tight text-white transform -translate-x-1/2 -translate-y-full bg-black rounded-md shadow-lg"
                  >
                    <span>
                      Investing value in $ after deduction of the platform fees.
                    </span>
                  </div>

                  <svg
                    class="absolute left-10 top-0 mt-3 z-10 w-6 h-6 text-black transform -translate-x-12 -translate-y-3 fill-current stroke-current tooltip-left4"
                    height="8"
                    width="8"
                  >
                    <rect
                      height="8"
                      transform="rotate(45)"
                      width="8"
                      x="12"
                      y="-10"
                    />
                  </svg>
                </div>

                <span
                  class="absolute right-5 balance text-gray-400 dark:text-darkGrayText"
                  [ngClass]="{
                    'bg-gray-300 text-gray-300 rounded-sm dark:bg-gray-300 dark:text-gray-300 animate-pulse':
                      loadingAmount
                  }"
                >
                  $
                  {{
                    (+investmentAmount).toFixed(2)
                      | mask : "separator.3" : { thousandSeparator: "," }
                  }}
                </span>
              </span>
            </div>

            <div class="swap-token-header">
              <span
                class="title dark:text-darkGrayText flex justify-start align-items-center"
              >
                Minimum value to invest:&nbsp;
                <span class="text-xs-2 text-red-400">
                  ${{ poolDetails?.minInvest }} + ${{
                    compensatoryMinInvest.toFixed(2)
                      | mask : "separator.3" : { thousandSeparator: "," }
                  }}
                  &nbsp;
                </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  (mouseleave)="tooltip = false"
                  (mouseover)="tooltip = true"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 dark:text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
                  />
                </svg>

                <div
                  *ngIf="tooltip"
                  class="relative"
                  x-cloak
                  x-show.transition.origin.top="tooltip"
                >
                  <div
                    class="absolute top-7 mt-3 z-10 w-48 p-2 mt-1 tooltip-left2 text-sm text-center leading-tight text-white transform -translate-x-1/2 -translate-y-full bg-black rounded-md shadow-lg"
                  >
                    <span>
                      Extra fund to guarantee investment. This is to make sure
                      that all the extra costs (swap fees, network costs,
                      Cross-Chain, slippage) are covered. These costs will be
                      deducted and the rest will be invested.
                    </span>
                  </div>

                  <svg
                    class="absolute left-10 top-0 mt-3 z-10 w-6 h-6 text-black transform -translate-x-12 -translate-y-3 fill-current stroke-current tooltip-left1"
                    height="8"
                    width="8"
                  >
                    <rect
                      height="8"
                      transform="rotate(45)"
                      width="8"
                      x="12"
                      y="-10"
                    />
                  </svg>
                </div>
              </span>
            </div>

            <div class="text-xs-2">
              <span class="dark:text-darkGrayText">
                Maximum value you can invest:&nbsp;
              </span>

              <span class="text-green-400 font-bold">
                ${{
                  maximumValueToInvest.toFixed(2)
                    | mask : "separator.3" : { thousandSeparator: "," }
                }}
              </span>
            </div>
          </div>
        </div>

        <div
          *ngIf="blockCrossChain && isConnected()"
          class="flex flex-col attention rounded-md bg-blue-50 p-2 m-2"
        >
          <div class="flex">
            <div>Attention</div>
          </div>
          <div class="mt-1 px-1">
            <span class="from-title break-normal">
              Due to the high transaction fees on the Ethereum network, it is
              not advisable to directly carry out this investment using
              non-Ethereum-based tokens. To minimize fees, we suggest converting
              your desired token to ETH using our

              <span
                class="text-blue-600 hover:text-blue-800 cursor-pointer"
                (click)="navigateToCrossChain()"
                >crosschain</span
              >
              service before proceeding with the investment.
            </span>
          </div>
        </div>

        <div
          *ngIf="isConnected()"
          class="swap-token-box cursor-default m-3 border-2 dark:bg-darkBlack border-0066FF dark:border-gray-600"
        >
          <div class="flex items-center my-2">
            <div class="col-8">
              <div class="dropdown dark:text-white">KYC/AML</div>

              <div class="dropdown text-xs dark:text-white">
                To participate in the CrowdSale of project
                {{ activePresale.presaleDisplayName }}
                , you must perform the KYC/AML process.
              </div>
            </div>

            <div class="col-4 flex justify-end items-center">
              <a
                *ngIf="!didRegistry"
                href="{{ activePresale.fractalUrl }}"
                target="_blank"
                class="rounded-md btn-bg p-2 text-sm"
              >
                KYC by Fractal
              </a>

              <button
                *ngIf="didRegistry"
                class="text-green-500 bg-green-200 cursor-default rounded-md p-2 text-sm"
              >
                Fractal KYC approved
              </button>
            </div>
          </div>

          <hr />

          <div class="flex items-center my-2">
            <div class="col-8">
              <div class="dropdown dark:text-white">Wallet sign</div>

              <div class="dropdown text-xs dark:text-white">
                <input
                  *ngIf="!didSign"
                  type="checkbox"
                  [(ngModel)]="didChecked"
                  [ngModelOptions]="{ standalone: true }"
                />

                I confirm that I’m not taking part in the public sale from one
                of the restricted regions.
              </div>
            </div>

            <div class="col-4 flex justify-end items-center">
              <button
                *ngIf="!didSign && didRegistry"
                [ngClass]="
                  didChecked && didRegistry
                    ? 'rounded-md p-2 btn-bg text-sm'
                    : 'bg-gray-200 cursor-default rounded-md p-2 text-sm pointer-events-none'
                "
                (click)="sign()"
                class="rounded-md p-2 px-4 text-sm"
              >
                Sign
              </button>

              <button
                *ngIf="!didSign && !didRegistry"
                [ngClass]="
                  didChecked && didRegistry
                    ? 'rounded-md p-2 btn-bg text-sm'
                    : 'bg-gray-200 cursor-default rounded-md p-2 text-sm pointer-events-none'
                "
                class="rounded-md p-2 text-sm"
              >
                Register Fractal first
              </button>

              <button
                *ngIf="didSign && didRegistry"
                class="text-green-500 bg-green-200 cursor-default rounded-md p-2 text-sm"
              >
                Sign approved
              </button>
            </div>
          </div>

          <hr
            *ngIf="
              +investmentAmount < netMinInvest &&
              this.opportunityState !== OpportunityState.ApprovalNeeded &&
              this.opportunityStatus === OpportunityStatus.running
            "
          />
        </div>

        <div
          *ngIf="
            this.opportunityStatus === OpportunityStatus.running &&
            isConnected() &&
            !blockCrossChain
          "
        >
          <div
            *ngIf="opportunityState === OpportunityState.ApprovalConfirmed"
            class="flex mt-2"
          >
            <div class="flex items-center">
              <svg
                class="h-6 w-6 text-green-300 opacity-50"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </div>

            <div class="text-xs text-gray-400 ml-2 flex items-center">
              Now you can use
              {{ token.symbol }}
            </div>
          </div>

          <div
            *ngIf="
              this.opportunityState === OpportunityState.ApprovalNeeded &&
              didRegistry &&
              didSign &&
              isConnected()
            "
            class="flex flex-col m-3 mt-2"
          >
            <button
              (click)="approve()"
              class="btn-swap-token blue-button border-2 dark:bg-darkBlueButton"
            >
              <span>Allow to use your {{ token.symbol }}</span>
            </button>
          </div>

          <div
            *ngIf="
              opportunityState !== OpportunityState.InsufficientTokenBBalance &&
              opportunityState !== OpportunityState.InsufficientTokenABalance &&
              opportunityState !== OpportunityState.AmountInIsTooLow &&
              didRegistry &&
              didSign &&
              isConnected() &&
              +investmentAmount >= netMinInvest &&
              +investmentAmount <= maximumValueToInvest
            "
            class="flex flex-col m-3 mt-2"
          >
            <button
              (click)="confirm()"
              class="btn-swap-token blue-button border-2 dark:bg-darkBlueButton"
              [disabled]="
                disableConfirm ||
                loadingAmount ||
                +tokenAmount === 0 ||
                opportunityState === OpportunityState.Confirmed ||
                opportunityState === OpportunityState.ApprovalNeeded ||
                opportunityState === OpportunityState.ApprovalRejected ||
                +investmentAmount > maximumValueToInvest
              "
              [ngClass]="{
                disable:
                  disableConfirm ||
                  loadingAmount ||
                  +tokenAmount === 0 ||
                  opportunityState === OpportunityState.Confirmed ||
                  opportunityState === OpportunityState.ApprovalNeeded ||
                  opportunityState === OpportunityState.ApprovalRejected ||
                  +investmentAmount > maximumValueToInvest
              }"
            >
              <span>
                {{
                  investmentType === InvestmentType.ByCrossChain
                    ? "Cross chain invest"
                    : "Invest"
                }}
              </span>
            </button>
          </div>

          <div
            *ngIf="+investmentAmount < netMinInvest && didRegistry && didSign"
            class="flex flex-col m-3 mt-2"
          >
            <button class="insufficient-button border-2">
              Estimated investment amount is lower than $ {{ netMinInvest }}
            </button>
          </div>

          <div
            *ngIf="
              (opportunityState ===
                OpportunityState.InsufficientTokenBBalance ||
                opportunityState ===
                  OpportunityState.InsufficientTokenABalance) &&
              didRegistry &&
              didSign &&
              +investmentAmount >= netMinInvest
            "
            class="flex flex-col m-3 mt-2"
          >
            <button class="insufficient-button border-2">
              Insufficient {{ token.symbol }} balance
            </button>
          </div>

          <div
            *ngIf="
              !(
                opportunityState ===
                  OpportunityState.InsufficientTokenBBalance ||
                opportunityState === OpportunityState.InsufficientTokenABalance
              ) &&
              +investmentAmount > maximumValueToInvest &&
              didRegistry &&
              didSign
            "
            class="flex flex-col m-3 mt-2"
          >
            <button class="insufficient-button border-2">
              Input amount is greater than $
              {{ maximumValueToInvest }}
            </button>
          </div>

          <div
            *ngIf="(!didRegistry || !didSign) && isConnected()"
            class="flex flex-col m-3 mt-2"
          >
            <button class="insufficient-button border-2">
              Please complete requirements
            </button>
          </div>
        </div>

        <div
          *ngIf="
            this.opportunityStatus !== OpportunityStatus.running &&
            isConnected() &&
            !blockCrossChain
          "
        >
          <div class="flex flex-col m-3 mt-2">
            <button class="insufficient-button border-2">
              {{
                this.opportunityStatus === OpportunityStatus.notStarted
                  ? "Presale is not started"
                  : "Presale is finished"
              }}
            </button>
          </div>
        </div>

        <div *ngIf="!isConnected()" class="flex flex-col m-3 mt-2">
          <button
            (click)="connectWallet()"
            class="blue-button p-1 py-2 rounded-sm"
          >
            Please connect your wallet
          </button>
        </div>
        <div
          *ngIf="isConnected() && blockCrossChain"
          class="flex flex-col m-3 mt-2"
        >
          <button class="insufficient-button border-2">
            <span>Wrong network</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="lpDetails && investmentType === InvestmentType.ByCrossChain"
    class="col-12 col-xl-7 col-md-7 col-sm-12 px-1"
  >
    <app-confirmation-private-sale
      [activePresale]="activePresale"
      [opportunityState]="opportunityState"
      [crossChainState]="crossChainState"
      [investmentType]="investmentType"
      [fallbackToken]="fallbackToken"
      [investmentToken]="token.symbol"
      [investmentAmount]="investmentAmount"
    >
    </app-confirmation-private-sale>
  </div>
</div>
