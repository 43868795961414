import { HttpClient } from '@angular/common/http';
import { LoggingService } from './log';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

const baseUrl = environment.Opportunity_BASEURL || '';

@Injectable()
export class TimeService {
  constructor(private http: HttpClient, private logger: LoggingService) {}
  public async getServerTime(): Promise<void> {
    try {
      const url = `${baseUrl}/api/v1/opportunity/getTime`;
      const serverTime = (await this.http.get(url).toPromise()) as number;
      const localTime = (new Date().getTime() / 1000).toFixed();

      localStorage.setItem(
        'timeDiffrence',
        (+serverTime! - +localTime).toString()
      );
    } catch (err) {
      console.error(`Error message: ${err}`);
      localStorage.setItem('serverTime', '-1');
    }
  }

  public getCurrentTime(): number {
    return (
      +(new Date().getTime() / 1000).toFixed() +
      +localStorage.getItem('timeDiffrence')!
    );
  }
}
