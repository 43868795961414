import { CrowdToken, Networks } from '@crowdswap/constant';
import { ExchangeType } from '../model/cross-chain-state.enum';
import { TradeType } from '@crowdswap/sdk';

export interface TokensHelperInput {
  allTokens: CrowdToken[];
  fromToken: CrowdToken;
  toToken: CrowdToken;
  type: 'from' | 'to';
  tradeType?: TradeType;
  mode?: ExchangeType;
  limitTokens: { [key: string]: string[] };
}

export class CrossChainTokensHelper {
  static FilterViewTokens(input: TokensHelperInput): CrowdToken[] {
    const tFilter = new TokensFilter(input);

    if (input.type == 'from' && input.tradeType !== TradeType.EXACT_OUTPUT) {
      tFilter.removeToken(input.toToken).removeWrappedToken(input.toToken);

      if (input.mode === ExchangeType.Limit) {
        tFilter.removeUnsupportedLimitOrderTokens(
          input.type,
          input.toToken.chainId,
          input.limitTokens
        );
      }

      tFilter.build();
    } else if (
      input.type == 'from' &&
      input.tradeType === TradeType.EXACT_OUTPUT
    ) {
      tFilter.swapTokens(input.toToken);
    } else {
      if (tFilter.fromChainId === Networks.ZKSYNC) {
        if (tFilter.isFromCrowd) {
          tFilter.removeAllTokenExcept(input.fromToken);
        } else {
          tFilter.removeAllChainIdsExcept([Networks.ZKSYNC]);
        }
      } else if (
        input.fromToken.chainId !== Networks.POLYGON_MAINNET ||
        (input.fromToken.chainId === Networks.POLYGON_MAINNET &&
          !tFilter.isFromCrowd)
      ) {
        tFilter.removeChainId(Networks.ZKSYNC);
      }

      if (tFilter.isFromTmng) {
        if (input.fromToken.chainId === Networks.MAINNET) {
          tFilter.removeAllTokenExcept(input.fromToken);
        } else {
          tFilter.removeAllExcept(input.fromToken.chainId, 'TMNG');
        }
      }

      if (tFilter.isFromCrowd) {
        tFilter.removeAllExcept(input.fromToken.chainId, 'CROWD');
      }

      tFilter.removeToken(input.fromToken).removeWrappedToken(input.fromToken);

      if (input.mode === ExchangeType.Limit) {
        tFilter.removeUnsupportedLimitOrderTokens(
          input.type,
          input.fromToken.chainId,
          input.limitTokens
        );
      }
    }

    return tFilter.build();
  }
}

export class TokensFilter {
  private allTokens: CrowdToken[];
  private filteredToken: CrowdToken[] = [];
  readonly fromChainId: number;
  readonly isFromTmng: boolean;
  readonly isFromCrowd: boolean;
  readonly isToCrowd: boolean;

  constructor(input: TokensHelperInput) {
    this.allTokens = input.allTokens;
    this.filteredToken = this.allTokens.slice(0);

    this.fromChainId = input.fromToken.chainId;
    this.isFromTmng = input.fromToken.symbol === 'TMNG';
    this.isFromCrowd = input.fromToken.symbol === 'CROWD';
    this.isToCrowd = input.toToken.symbol === 'CROWD';
  }

  build(): CrowdToken[] {
    return this.filteredToken;
  }

  removeToken(token: CrowdToken) {
    this.filteredToken = this.filteredToken.filter(
      (c) => !(c.chainId === token.chainId && c.symbol === token.symbol)
    );
    return this;
  }

  removeAllTokenExcept(token: CrowdToken) {
    this.filteredToken = this.filteredToken.filter(
      (c) => c.symbol === token.symbol
    );
    return this;
  }

  removeChainId(chainId: number) {
    this.filteredToken = this.filteredToken.filter(
      (c) => c.chainId !== chainId
    );
    return this;
  }

  removeAllChainIdsExcept(chainIds: number[]) {
    this.filteredToken = this.filteredToken.filter(
      (c) => chainIds.indexOf(c.chainId) > -1
    );
    return this;
  }

  removeAllExcept(chainId: number, symbol: string) {
    this.filteredToken = this.filteredToken.filter(
      (c) => c.chainId === chainId || c.symbol === symbol
    );
    return this;
  }

  removeWrappedToken(token: CrowdToken) {
    const wrappedToken = this.filteredToken.find(
      (c) => c.chainId === token.chainId && c.symbol == 'W' + token.symbol
    );
    if (wrappedToken) {
      this.removeToken(wrappedToken);
    }
    return this;
  }

  removeUnsupportedLimitOrderTokens(
    type: 'from' | 'to',
    chainId: number,
    limitTokens: { [key: string]: string[] }
  ) {
    const tokens = this.filteredToken.filter((c) => {
      let validToken = false;
      let validChainId = false;

      if (type === 'from' && c.symbol) {
        validToken = ['CROWD', 'TMNG'].indexOf(c.symbol) !== -1;
      }

      if (type === 'to' && c.symbol && c.symbol === 'TMNG') {
        validToken = true;
      }

      if (c.symbol) {
        for (const key in limitTokens) {
          if (
            Object.prototype.hasOwnProperty.call(limitTokens, key) &&
            !validToken
          ) {
            const element = limitTokens[key];

            validChainId =
              Object.keys(limitTokens).indexOf(c.chainId.toString()) === -1;

            validToken =
              parseInt(key) === c.chainId && element.indexOf(c.symbol) === -1;
          }
        }
      }

      return validChainId || validToken;
    });

    tokens.forEach((t) => this.removeToken(t));

    return this.removeChainId(chainId);
  }

  swapTokens(token: CrowdToken) {
    this.filteredToken = this.filteredToken.filter(
      (c) => c.chainId === token.chainId && c.symbol !== token.symbol
    );
    return this;
  }
}
