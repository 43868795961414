<div class="container d-flex flex-column">
  <div class="container d-flex flex-column mt-3">
    <span
      class="d-block w-100 font-28 font-openSans-bold text-000 dark:text-fff px-3"
    >
      Buttons
    </span>

    <div class="w-full d-flex flex-wrap p-0 justify-content-evenly mt-3">
      <!-- Big -->
      <div
        class="ant-col-md-6 ant-col-sm-8 ant-col-xs-24 d-flex flex-column px-3"
      >
        <div
          class="w-full d-flex align-items-center justify-content-between mt-3"
        >
          <span
            class="w-full text-center font-20 font-openSans-bold text-000 dark:text-fff"
          >
            Big
          </span>

          <div class="d-flex flex-column justify-content-end">
            <label nz-checkbox [(ngModel)]="disable" class="mb-0">
              <span class="text-000 dark:text-fff">disable</span>
            </label>

            <label nz-checkbox [(ngModel)]="loading" class="mt-2 mb-0 ms-0">
              <span class="text-000 dark:text-fff">loading</span>
            </label>
          </div>
        </div>

        <button
          nz-button
          nzType="default"
          (click)="btnClick()"
          [nzSize]="'large'"
          class="w-full px-20px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          [ngClass]="{'opacity-4': disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="largIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="largIcon"></ng-container>
        </button>

        <button
          nz-button
          nzType="default"
          (click)="btnClick()"
          [nzSize]="'large'"
          class="w-full px-20px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 mt-3"
          [ngClass]="{'opacity-4': disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="largIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="largIcon"></ng-container>
        </button>

        <button
          nz-button
          nzType="default"
          (click)="btnClick()"
          [nzSize]="'large'"
          class="w-full px-20px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px shadow-none"
          [ngClass]="{'opacity-4': disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="largIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="largIcon"></ng-container>
        </button>

        <div class="d-flex align-items-center justify-content-evenly mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3 p-3"
            [ngClass]="{'opacity-4': disable}"
            [disabled]="disable"
            [nzLoading]="loading"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 mt-3 p-3"
            [ngClass]="{'opacity-4': disable}"
            [disabled]="disable"
            [nzLoading]="loading"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="d-flex align-items-center justify-content-between font-openSans-bold font-20 hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px p-3 shadow-none"
            [ngClass]="{'opacity-4 bg-transparent': disable, 'dark:bg-3385ff26':!disable}"
            [disabled]="disable"
            [nzLoading]="loading"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>
          </button>
        </div>
      </div>

        <!-- Med -->
      <div
        class="ant-col-md-6 ant-col-sm-8 ant-col-xs-24 d-flex flex-column px-3"
      >
        <div
          class="w-full d-flex align-items-center justify-content-between mt-3"
        >
          <span
            class="w-full text-center font-20 font-openSans-bold text-000 dark:text-fff"
          >
            Med
          </span>

          <div class="d-flex flex-column justify-content-end">
            <label nz-checkbox [(ngModel)]="disable" class="mb-0">
              <span class="text-000 dark:text-fff">disable</span>
            </label>

            <label nz-checkbox [(ngModel)]="loading" class="mt-2 mb-0 ms-0">
              <span class="text-000 dark:text-fff">loading</span>
            </label>
          </div>
        </div>

        <button
          nz-button
          nzType="default"
          (click)="btnClick()"
          [nzSize]="'default'"
          class="w-full px-20px d-flex align-items-center justify-content-between font-openSans-bold font-16 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          [ngClass]="{'opacity-4': disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="medIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="medIcon"></ng-container>
        </button>

        <button
          nz-button
          nzType="default"
          (click)="btnClick()"
          [nzSize]="'default'"
          class="w-full px-20px d-flex align-items-center justify-content-between font-openSans-bold font-16 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 mt-3"
          [ngClass]="{'opacity-4': disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="medIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="medIcon"></ng-container>
        </button>

        <button
          nz-button
          nzType="default"
          (click)="btnClick()"
          [nzSize]="'default'"
          class="w-full px-20px d-flex align-items-center justify-content-between font-openSans-bold font-16 hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px shadow-none"
          [ngClass]="{'opacity-4 bg-transparent': disable, 'dark:bg-3385ff26':!disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="medIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="medIcon"></ng-container>
        </button>

        <button
          nz-button
          nzType="link"
          (click)="btnClick()"
          [nzSize]="'default'"
          class="w-max-content d-flex align-items-center justify-content-between font-openSans-bold font-16 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px p-2px h-unset shadow-none"
          [ngClass]="{'opacity-4': disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="medIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="medIcon"></ng-container>
        </button>

        <div class="d-flex align-items-center justify-content-evenly mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3 p-3"
            [ngClass]="{'opacity-4': disable}"
            [disabled]="disable"
            [nzLoading]="loading"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 mt-3 p-3"
            [ngClass]="{'opacity-4': disable}"
            [disabled]="disable"
            [nzLoading]="loading"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="d-flex align-items-center justify-content-between font-openSans-bold font-20 hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px p-3 shadow-none"
            [ngClass]="{'opacity-4 bg-transparent': disable, 'dark:bg-3385ff26':!disable}"
            [disabled]="disable"
            [nzLoading]="loading"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>
          </button>
        </div>
      </div>

        <!-- Sma -->
      <div
        class="ant-col-md-6 ant-col-sm-8 ant-col-xs-24 d-flex flex-column px-3"
      >
        <div
          class="w-full d-flex align-items-center justify-content-between mt-3"
        >
          <span
            class="w-full text-center font-20 font-openSans-bold text-000 dark:text-fff"
          >
            Sma
          </span>

          <div class="d-flex flex-column justify-content-end">
            <label nz-checkbox [(ngModel)]="disable" class="mb-0">
              <span class="text-000 dark:text-fff">disable</span>
            </label>

            <label nz-checkbox [(ngModel)]="loading" class="mt-2 mb-0 ms-0">
              <span class="text-000 dark:text-fff">loading</span>
            </label>
          </div>
        </div>

        <button
          nz-button
          nzType="default"
          (click)="btnClick()"
          [nzSize]="'small'"
          class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          [ngClass]="{'opacity-4': disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="smaIcon"></ng-container>
        </button>

        <button
          nz-button
          nzType="default"
          (click)="btnClick()"
          [nzSize]="'small'"
          class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 mt-3"
          [ngClass]="{'opacity-4': disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="smaIcon"></ng-container>
        </button>

        <button
          nz-button
          nzType="default"
          (click)="btnClick()"
          [nzSize]="'small'"
          class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px shadow-none"
          [ngClass]="{'opacity-4 bg-transparent': disable, 'dark:bg-3385ff26':!disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="smaIcon"></ng-container>
        </button>

        <button
          nz-button
          nzType="link"
          (click)="btnClick()"
          [nzSize]="'small'"
          class="w-max-content d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px px-0 py-1px h-unset shadow-none"
          [ngClass]="{'opacity-4': disable}"
          [disabled]="disable"
          [nzLoading]="loading"
        >
          <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

          <span class="px-1"> CTA sample </span>

          <ng-container *ngTemplateOutlet="smaIcon"></ng-container>
        </button>

        <div class="d-flex align-items-center justify-content-evenly mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3 p-6px"
            [ngClass]="{'opacity-4': disable}"
            [disabled]="disable"
            [nzLoading]="loading"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 mt-3 p-6px"
            [ngClass]="{'opacity-4': disable}"
            [disabled]="disable"
            [nzLoading]="loading"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="d-flex align-items-center justify-content-between font-openSans-bold font-20 hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px p-6px shadow-none"
            [ngClass]="{'opacity-4 bg-transparent': disable, 'dark:bg-3385ff26':!disable}"
            [disabled]="disable"
            [nzLoading]="loading"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>

  <nz-divider class="dark:border-fff"></nz-divider>

  <div class="container d-flex flex-column mt-3">
    <span
      class="d-block w-100 font-28 font-openSans-bold text-000 dark:text-fff px-3"
    >
      Button group
    </span>

    <div class="w-full d-flex flex-wrap p-0 justify-content-evenly mt-3">
      <div class="ant-col-sm-12 ant-col-xs-24 d-flex flex-column px-3">
        <div
          class="w-full d-flex align-items-center justify-content-between mt-3"
        >
          <span
            class="w-full text-center font-20 font-openSans-bold text-000 dark:text-fff"
          >
            Big
          </span>

          <div class="d-flex flex-column justify-content-end">
            <label nz-checkbox [(ngModel)]="disable" class="mb-0">
              <span class="text-000 dark:text-fff">disable</span>
            </label>

            <label nz-checkbox [(ngModel)]="loading" class="mt-2 mb-0 ms-0">
              <span class="text-000 dark:text-fff">loading</span>
            </label>
          </div>
        </div>

        <nz-button-group class="mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-left-radius-6px border-1px border-ffffff80 focus:border-0055d399 focus:outline-solid focus:outline-0055d399 focus:bg-0066ff26 focus:outline-3px"
            [ngClass]="{'opacity-4': disable, 'mr-1': radioValue === 'A'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'A'"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-1px border-ffffff80 focus:border-0055d399 focus:outline-solid focus:outline-0055d399 focus:outline-3px"
            [ngClass]="{'opacity-4': disable, 'mx-1': radioValue === 'B', 'ms-0': radioValue !== 'B'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'B'"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-right-radius-6px border-1px border-ffffff80 focus:border-0055d399 focus:outline-solid focus:outline-0055d399 focus:outline-3px"
            [ngClass]="{'opacity-4': disable, 'ms-1': radioValue === 'C', 'ms-0': radioValue !== 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'C'"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>
        </nz-button-group>

        <nz-button-group class="mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-left-radius-6px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
            [ngClass]="{'opacity-4': disable, 'mr-1': radioValue === 'A'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'A'"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
            [ngClass]="{'opacity-4': disable, 'mx-1': radioValue === 'B', 'ms-0': radioValue !== 'B', 'border-right-1px': radioValue === 'A', 'border-left-1px': radioValue === 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'B'"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-right-radius-6px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
            [ngClass]="{'opacity-4': disable, 'ms-1': radioValue === 'C', 'ms-0': radioValue !== 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'C'"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>
        </nz-button-group>

        <nz-button-group class="mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-0 border-left-radius-6px focus:outline-solid focus:outline-3px focus:outline-0066FF99 shadow-none"
            [ngClass]="{'opacity-4': disable, 'bg-0066ff1a mr-1': radioValue === 'A'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'A'"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-0 focus:outline-solid focus:outline-3px focus:outline-0066FF99 shadow-none"
            [ngClass]="{'opacity-4': disable, 'bg-0066ff1a mx-1': radioValue === 'B', 'ms-0': radioValue !== 'B', 'border-right-1px': radioValue === 'A', 'border-left-1px': radioValue === 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'B'"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'large'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-0 border-right-radius-6px focus:outline-solid focus:outline-3px focus:outline-0066FF99 shadow-none"
            [ngClass]="{'opacity-4': disable, 'bg-0066ff1a ms-1': radioValue === 'C', 'ms-0': radioValue !== 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'C'"
          >
            <ng-container *ngTemplateOutlet="largIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>
        </nz-button-group>
      </div>

      <div class="ant-col-sm-12 ant-col-xs-24 d-flex flex-column px-3">
        <div
          class="w-full d-flex align-items-center justify-content-between mt-3"
        >
          <span
            class="w-full text-center font-20 font-openSans-bold text-000 dark:text-fff"
          >
            Med
          </span>

          <div class="d-flex flex-column justify-content-end">
            <label nz-checkbox [(ngModel)]="disable" class="mb-0">
              <span class="text-000 dark:text-fff">disable</span>
            </label>

            <label nz-checkbox [(ngModel)]="loading" class="mt-2 mb-0 ms-0">
              <span class="text-000 dark:text-fff">loading</span>
            </label>
          </div>
        </div>

        <nz-button-group class="mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-left-radius-6px border-1px border-ffffff80 focus:border-0055d399 focus:outline-solid focus:outline-0055d399 focus:bg-0066ff26 focus:outline-3px"
            [ngClass]="{'opacity-4': disable, 'mr-1': radioValue === 'A'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'A'"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-1px border-ffffff80 focus:border-0055d399 focus:outline-solid focus:outline-0055d399 focus:outline-3px"
            [ngClass]="{'opacity-4': disable, 'mx-1': radioValue === 'B', 'ms-0': radioValue !== 'B'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'B'"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-right-radius-6px border-1px border-ffffff80 focus:border-0055d399 focus:outline-solid focus:outline-0055d399 focus:outline-3px"
            [ngClass]="{'opacity-4': disable, 'ms-1': radioValue === 'C', 'ms-0': radioValue !== 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'C'"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>
        </nz-button-group>

        <nz-button-group class="mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-left-radius-6px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
            [ngClass]="{'opacity-4': disable, 'mr-1': radioValue === 'A'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'A'"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
            [ngClass]="{'opacity-4': disable, 'mx-1': radioValue === 'B', 'ms-0': radioValue !== 'B', 'border-right-1px': radioValue === 'A', 'border-left-1px': radioValue === 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'B'"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-right-radius-6px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
            [ngClass]="{'opacity-4': disable, 'ms-1': radioValue === 'C', 'ms-0': radioValue !== 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'C'"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>
        </nz-button-group>

        <nz-button-group class="mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-0 border-left-radius-6px focus:outline-solid focus:outline-3px focus:outline-0066FF99 shadow-none"
            [ngClass]="{'opacity-4': disable, 'bg-0066ff1a mr-1': radioValue === 'A'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'A'"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-0 focus:outline-solid focus:outline-3px focus:outline-0066FF99 shadow-none"
            [ngClass]="{'opacity-4': disable, 'bg-0066ff1a mx-1': radioValue === 'B', 'ms-0': radioValue !== 'B', 'border-right-1px': radioValue === 'A', 'border-left-1px': radioValue === 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'B'"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'default'"
            class="w-full px-14px d-flex align-items-center justify-content-between font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-0 border-right-radius-6px focus:outline-solid focus:outline-3px focus:outline-0066FF99 shadow-none"
            [ngClass]="{'opacity-4': disable, 'bg-0066ff1a ms-1': radioValue === 'C', 'ms-0': radioValue !== 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'C'"
          >
            <ng-container *ngTemplateOutlet="medIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>
        </nz-button-group>
      </div>

      <div class="ant-col-sm-12 ant-col-xs-24 d-flex flex-column px-3">
        <div
          class="w-full d-flex align-items-center justify-content-between mt-3"
        >
          <span
            class="w-full text-center font-20 font-openSans-bold text-000 dark:text-fff"
          >
            Sma
          </span>

          <div class="d-flex flex-column justify-content-end">
            <label nz-checkbox [(ngModel)]="disable" class="mb-0">
              <span class="text-000 dark:text-fff">disable</span>
            </label>

            <label nz-checkbox [(ngModel)]="loading" class="mt-2 mb-0 ms-0">
              <span class="text-000 dark:text-fff">loading</span>
            </label>
          </div>
        </div>

        <nz-button-group class="mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-left-radius-6px border-1px border-ffffff80 focus:border-0055d399 focus:outline-solid focus:outline-0055d399 focus:bg-0066ff26 focus:outline-3px"
            [ngClass]="{'opacity-4': disable, 'mr-1': radioValue === 'A'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'A'"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-1px border-ffffff80 focus:border-0055d399 focus:outline-solid focus:outline-0055d399 focus:outline-3px"
            [ngClass]="{'opacity-4': disable, 'mx-1': radioValue === 'B', 'ms-0': radioValue !== 'B'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'B'"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-right-radius-6px border-1px border-ffffff80 focus:border-0055d399 focus:outline-solid focus:outline-0055d399 focus:outline-3px"
            [ngClass]="{'opacity-4': disable, 'ms-1': radioValue === 'C', 'ms-0': radioValue !== 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'C'"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>
        </nz-button-group>

        <nz-button-group class="mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-left-radius-6px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
            [ngClass]="{'opacity-4': disable, 'mr-1': radioValue === 'A'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'A'"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
            [ngClass]="{'opacity-4': disable, 'mx-1': radioValue === 'B', 'ms-0': radioValue !== 'B', 'border-right-1px': radioValue === 'A', 'border-left-1px': radioValue === 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'B'"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-right-radius-6px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
            [ngClass]="{'opacity-4': disable, 'ms-1': radioValue === 'C', 'ms-0': radioValue !== 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'C'"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>
        </nz-button-group>

        <nz-button-group class="mt-3">
          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-0 border-left-radius-6px focus:outline-solid focus:outline-3px focus:outline-0066FF99 shadow-none"
            [ngClass]="{'opacity-4': disable, 'bg-0066ff1a mr-1': radioValue === 'A'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'A'"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-0 focus:outline-solid focus:outline-3px focus:outline-0066FF99 shadow-none"
            [ngClass]="{'opacity-4': disable, 'bg-0066ff1a mx-1': radioValue === 'B', 'ms-0': radioValue !== 'B', 'border-right-1px': radioValue === 'A', 'border-left-1px': radioValue === 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'B'"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>

          <button
            nz-button
            nzType="default"
            (click)="btnClick()"
            [nzSize]="'small'"
            class="w-full px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-0 border-right-radius-6px focus:outline-solid focus:outline-3px focus:outline-0066FF99 shadow-none"
            [ngClass]="{'opacity-4': disable, 'bg-0066ff1a ms-1': radioValue === 'C', 'ms-0': radioValue !== 'C'}"
            [disabled]="disable"
            [nzLoading]="loading"
            (click)="radioValue = 'C'"
          >
            <ng-container *ngTemplateOutlet="smaIcon"></ng-container>

            <span class="px-1"> CTA sample </span>
          </button>
        </nz-button-group>
      </div>
    </div>
  </div>
</div>

<!-- You can use your own icon inside the <button> tag. I've used a template for all buttons here. -->
<ng-template #largIcon>
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="15"
      cy="15.5"
      r="11.25"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.2501 13H11.2626"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.7501 13H18.7626"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.875 19.25C12.6979 20.0899 13.8242 20.5631 15 20.5631C16.1758 20.5631 17.3021 20.0899 18.125 19.25"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #medIcon>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11"
      cy="11"
      r="8.25"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.25014 9.16634H8.2593"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.7501 9.16634H13.7593"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.7085 13.75C9.31195 14.3659 10.1379 14.713 11.0002 14.713C11.8624 14.713 12.6884 14.3659 13.2918 13.75"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #smaIcon>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9"
      cy="9.5"
      r="6.75"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.75016 8H6.75766"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.2502 8H11.2577"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.125 11.75C7.61873 12.2539 8.29452 12.5379 9 12.5379C9.70548 12.5379 10.3813 12.2539 10.875 11.75"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>
