import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'renameSymbol'
})
export class RenameSymbol implements PipeTransform {
  transform(symbol: string): string {
    switch (symbol) {
      case 'BTCB':
        symbol = 'BTC';
        break;

      default:
        break;
    }
    return symbol;
  }
}
