<div
  class="network-change-section w-full position-relative d-flex flex-wrap justify-content-center"
>
  <!-- for="swapAmountInput" -->
  <div
    class="network-change-box w-full px-2 pb-2 pt-1 d-flex flex-column mb-0 border-1px"
    [ngClass]="{
      'border-1E1E1E border-radius-8px is-dark': isDarkMode,
      'border-0066FF border-radius-6px': !isDarkMode
    }"
  >
    <span class="text-000 dark:text-fff font-12"> YOU PAY </span>

    <div class="d-flex">
      <div
        (click)="openSelectOptionDialog(fromToken, toToken, 'from')"
        class="p-md-3 p-2 border-2 border-9797971a border-radius-4px d-flex align-items-center cursor-pointer dark:bg-1E1E1E dark:border-1E1E1E dark:border-0"
      >
        <div
          class="d-flex align-items-center justify-content-center ml-10px me-sm-3"
        >
          <div class="token-symbol">
            <img
              alt="fromToken"
              class="rounded-full"
              width="20"
              [src]="
                baseLogoIconsUrl + fromToken.symbol?.toLowerCase() + '.png'
              "
              (error)="updateUrl($event)"
            />
          </div>

          <div class="d-flex flex-column">
            <span
              class="font-12 font-xs-16 font-openSans-bold text-000 dark:text-fff"
            >
              {{ fromToken.symbol }}
            </span>

            <div class="d-flex align-items-center">
              <div
                class="rounded-full flex-shrink-0 p-1px bg-fff overflow-hidden"
              >
                <img
                  alt="fromChain"
                  [src]="
                    baseLogoIconsUrl +
                    Networks[fromToken.chainId]?.toLowerCase() +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                  width="14"
                />
              </div>

              <span
                class="font-10 font-sm-12 text-000 dark:text-fff ml-1 flex-shrink-0"
              >
                {{
                  web3Service.networkSpec[network.from.selectedChainId].title
                }}
              </span>
            </div>
          </div>
        </div>

        <div
          class="arrow-down d-flex align-items-center justify-content-center ms-2 mr--4px"
        >
          <svg
            class="text-323232 dark:text-DADADA"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </div>

      <label
        class="p-md-3 p-2 border-2 border-9797971a border-radius-4px ml-6px dark:bg-1E1E1E dark:border-1E1E1E dark:border-0 w-full"
      >
        <div class="w-full d-flex flex-column flex-shrink-0">
          <div
            class="from-title-section w-full d-flex flex-shrink-0 align-items-center justify-content-between"
          >
            <span class="flex-shrink-0 d-none d-md-block"> </span>
            <div
              class="d-flex flex-shrink-0 align-items-center w-md-auto w-full justify-content-between justify-content-end"
              *ngIf="this.isUserWalletConnected"
            >
              <span
                class="font-10 font-sm-12 text-00000080 dark:text-FFFFFFB2 me-1"
              >
                Balance:
              </span>

              <nz-skeleton-element
                class="balance-skeleton"
                nzType="button"
                [nzActive]="true"
                nzSize="small"
                *ngIf="!this.isUserWalletConnected || !fromBalance"
              ></nz-skeleton-element>

              <div
                class="d-flex flex-shrink-0 align-items-center"
                *ngIf="this.isUserWalletConnected && fromBalance"
              >
                <span
                  class="font-10 font-sm-12 text-00000080 dark:text-FFFFFFB2"
                >
                  {{ fromBalance }}
                </span>

                <span
                  class="text-323232 dark:text-FFFFFFBF ml-1 cursor-pointer"
                  *ngIf="
                    !estimation?.loading &&
                    setting?.showMaxTooltip &&
                    estimation?.operatingExpensesFee &&
                    estimation?.operatingExpensesFee?.amount &&
                    estimation?.operatingExpensesFee?.amount! > '0'
                  "
                  nz-tooltip
                  [nzTooltipTitle]="MaxTooltipText"
                  [nzTooltipOverlayClassName]="
                    isDarkMode
                      ? 'dark dark-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                      : 'white-exchange-tooltip max-tooltip exchange-tooltip has-arrow'
                  "
                  [nzTooltipTrigger]="setting.isMobile ? 'click' : 'hover'"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.6" clip-path="url(#clip0_5395_63724)">
                      <path
                        d="M6.41602 9.9165H7.58268V6.4165H6.41602V9.9165ZM6.99935 1.1665C3.77935 1.1665 1.16602 3.77984 1.16602 6.99984C1.16602 10.2198 3.77935 12.8332 6.99935 12.8332C10.2193 12.8332 12.8327 10.2198 12.8327 6.99984C12.8327 3.77984 10.2193 1.1665 6.99935 1.1665ZM6.99935 11.6665C4.42685 11.6665 2.33268 9.57234 2.33268 6.99984C2.33268 4.42734 4.42685 2.33317 6.99935 2.33317C9.57185 2.33317 11.666 4.42734 11.666 6.99984C11.666 9.57234 9.57185 11.6665 6.99935 11.6665ZM6.41602 5.24984H7.58268V4.08317H6.41602V5.24984Z"
                        fill="currentColor"
                      />
                    </g>
                  </svg>

                  <ng-template #MaxTooltipText>
                    <div class="d-flex flex-column font-12">
                      <span>
                        Protocol fee covers transaction execution costs on the
                        destination network.
                      </span>

                      <span class="mt-10px"> You are paying: </span>

                      <span class="mt-10px font-openSans-bold">
                        {{ estimation?.totalPaid }}
                        {{ estimation?.fromToken?.symbol }}
                        =
                      </span>

                      <div class="d-flex align-items-center">
                        <span>
                          {{ swapAmount }}
                          {{ fromToken.symbol }}
                        </span>

                        <span class="mx-1"> + </span>

                        <span>
                          {{ estimation?.operatingExpensesFee?.amount }}
                          {{ fromToken.symbol }}
                        </span>
                      </div>
                    </div>
                  </ng-template>
                </span>

                <span
                  class="text-0066FF dark:text-3385FF ml-1 font-10 font-sm-12 font-w-500 cursor-pointer"
                  (click)="setMaxBalance()"
                >
                  Max
                </span>
              </div>
            </div>
          </div>

          <div class="w-full flex items-center dark:text-fff">
            <input
              type="text"
              *ngIf="!(estimation?.loading && searchParam?.isMax)"
              inputmode="decimal"
              (ngModelChange)="notifySearch($event)"
              [(ngModel)]="swapAmount"
              mask="separator"
              class="w-full font-18 font-xs-28 text-000 dark:text-fff p-1 border-0 shadow-none outline-none bg-transparent"
            />

            <nz-skeleton-element
              class="skeleton-h-42 mb-1"
              nzType="button"
              [nzActive]="true"
              *ngIf="estimation?.loading && searchParam?.isMax"
            ></nz-skeleton-element>
          </div>

          <span class="font-12 text-00000080 dark:text-FFFFFFB2">
            <ng-container *ngIf="swapSearchValue !== '0'">
              {{
                !swapSearchValue.startsWith("N")
                  ? "~" + swapSearchValue
                  : "Unknown"
              }}
            </ng-container>

            <nz-skeleton-element
              class="swap-search-value-skeleton"
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="swapSearchValue === '0'"
            ></nz-skeleton-element>
          </span>
        </div>
      </label>
    </div>
  </div>

  <div
    (click)="switchTokens()"
    [ngClass]="{
      selected: isSelected,
      'cursor-pointer': switchTokenVisibility(),
      'cursor-not-allowed': !switchTokenVisibility()
    }"
    class="arrow-change-token rounded-full border-1px border-0066FF dark:border-595961 position-relative z-1 bg-fff dark:bg-121212 dark:hover:bg-1E1E1E hover:bg-f3f4f6 cursor-pointer text-0066FF dark:text-fff"
    nz-tooltip
    [nzTooltipTitle]="LimitTooltipText"
    [nzTooltipTrigger]="switchTokenVisibility() ? null : 'hover'"
  >
    <svg
      class="absolute-center"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33268 12.175V6.33333H7.66602V12.175H5.16602L8.49935 15.5L11.8327 12.175H9.33268ZM3.49935 0.5L0.166016 3.825H2.66602V9.66667H4.33268V3.825H6.83268L3.49935 0.5Z"
      />
    </svg>

    <ng-template #LimitTooltipText>
      <span *ngIf="!switchTokenVisibility()">
        <ng-container *ngIf="toToken?.symbol === 'CROWD'; else TMNGError">
          Limit order is not supported when CROWD is selected as 'from' token.
        </ng-container>

        <ng-template #TMNGError>
          TMNG token is not supported in limit order
        </ng-template>
      </span>
    </ng-template>
  </div>

  <div
    class="network-change-box to-section w-full px-2 pb-2 pt-1 d-flex flex-column border-1px"
    [ngClass]="{
      'border-1E1E1E border-radius-8px is-dark': isDarkMode,
      'border-0066FF border-radius-6px': !isDarkMode
    }"
  >
    <label for="swapAmountOut" class="text-000 dark:text-fff font-12 m-0">
      YOU RECEIVE
    </label>

    <div class="d-flex">
      <div
        #tokenSelection
        (click)="openSelectOptionDialog(fromToken, toToken, 'to')"
        class="p-md-3 p-2 border-2 border-9797971a border-radius-4px d-flex align-items-center cursor-pointer dark:bg-1E1E1E dark:border-1E1E1E dark:border-0"
      >
        <div
          class="d-flex align-items-center justify-content-center ml-10px me-sm-3"
        >
          <div class="token-symbol">
            <img
              alt="toToken"
              class="rounded-full"
              width="20"
              [src]="baseLogoIconsUrl + toToken.symbol?.toLowerCase() + '.png'"
              (error)="updateUrl($event)"
            />
          </div>

          <div class="d-flex flex-column">
            <span
              class="font-12 font-xs-16 font-openSans-bold text-000 dark:text-fff"
            >
              {{ toToken.symbol }}
            </span>

            <div class="d-flex align-items-center w-md-auto w-full">
              <div
                class="rounded-full flex-shrink-0 p-1px bg-fff overflow-hidden"
              >
                <img
                  alt="toNetwork"
                  [src]="
                    baseLogoIconsUrl +
                    Networks[network.to.selectedChainId]?.toLowerCase() +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                  width="14"
                />
              </div>

              <span class="font-10 font-sm-12 text-000 dark:text-fff ml-1">
                {{ web3Service.networkSpec[network.to.selectedChainId].title }}
              </span>
            </div>
          </div>
        </div>

        <div
          class="arrow-down d-flex align-items-center justify-content-center ms-2 mr--4px"
        >
          <svg
            class="text-323232 dark:text-DADADA"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </div>

      <div
        [style.width.px]="450 - tokenSelection.offsetWidth"
        class="w-full p-md-3 p-2 border-2 border-9797971a border-radius-4px ml-6px dark:bg-1E1E1E dark:border-1E1E1E dark:border-0"
      >
        <div class="w-full d-flex flex-column">
          <div
            *ngIf="
              !loadingAmountOut &&
              estimation?.crossChainSwapState === CrossChainSwapState.Expired
            "
            class="w-full d-flex alig-items-center justify-content-end"
          >
            <img
              nz-tooltip
              nzTooltipTitle="Restart Estimation"
              src="../../../../../assets/media/icons/refresh-blue.svg"
              class="ms-1 cursor-pointer flex-shrink-0"
              alt="refresh"
              width="15"
              (click)="resetSearch()"
            />
          </div>

          <div class="d-flex align-items-center amount-label">
            <input
              id="swapAmountOut"
              *ngIf="
                !loadingAmountOut || realExchangeType === ExchangeType.Limit
              "
              class="w-full font-18 font-xs-28 text-000 dark:text-fff m-0 border-0 bg-transparent outline-none shadow-none"
              [ngClass]="{
                'has-refresh':
                  !loadingAmountOut &&
                  estimation?.crossChainSwapState ===
                    CrossChainSwapState.Expired
              }"
              [(ngModel)]="swapAmountOut"
              [readonly]="exchangeType === ExchangeType.Market"
              (ngModelChange)="swapAmountOutChangeNotifier.next(swapAmountOut)"
              mask="separator"
              nz-tooltip="Enter Your Target Amount"
              [nzTooltipTrigger]="
                exchangeType === ExchangeType.Limit ? 'hover' : null
              "
            />

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              *ngIf="
                loadingAmountOut && realExchangeType === ExchangeType.Market
              "
            ></nz-skeleton-element>
          </div>

          <span
            class="font-12 d-flex align-items-center text-00000080 dark:text-FFFFFFB2"
          >
            <ng-container *ngIf="totalDestinationPaid && !loadingAmountOut">
              {{
                !amountOutUSDT?.startsWith("N")
                  ? "~" + amountOutUSDT
                  : "Unknown"
              }}

              <!--              Todo BBBBBBBB-->
              <!--              <div-->
              <!--                class="d-flex align-items-center ms-2"-->
              <!--                *ngIf="lostPercentage"-->
              <!--              >-->
              <!--                <svg-->
              <!--                  *ngIf="-->
              <!--                    lostPercentage &&-->
              <!--                    lostPercentage <= warningPriceImpactPercent &&-->
              <!--                    lostPercentage >= highPriceImpactPercent-->
              <!--                  "-->
              <!--                  xmlns="http://www.w3.org/2000/svg"-->
              <!--                  width="1em"-->
              <!--                  height="1em"-->
              <!--                  viewBox="0 0 24 24"-->
              <!--                >-->
              <!--                  <path-->
              <!--                    fill="none"-->
              <!--                    stroke="#fb923c"-->
              <!--                    stroke-linecap="round"-->
              <!--                    stroke-linejoin="round"-->
              <!--                    stroke-width="2"-->
              <!--                    d="m10.24 3.957l-8.422 14.06A1.989 1.989 0 0 0 3.518 21h16.845a1.989 1.989 0 0 0 1.7-2.983L13.64 3.957a1.989 1.989 0 0 0-3.4 0zM12 9v4m0 4h.01"-->
              <!--                  />-->
              <!--                </svg>-->

              <!--                <svg-->
              <!--                  *ngIf="-->
              <!--                    lostPercentage && lostPercentage <= highPriceImpactPercent-->
              <!--                  "-->
              <!--                  xmlns="http://www.w3.org/2000/svg"-->
              <!--                  width="1em"-->
              <!--                  height="1em"-->
              <!--                  viewBox="0 0 24 24"-->
              <!--                >-->
              <!--                  <path-->
              <!--                    fill="none"-->
              <!--                    stroke="red"-->
              <!--                    stroke-linecap="round"-->
              <!--                    stroke-linejoin="round"-->
              <!--                    stroke-width="2"-->
              <!--                    d="m10.24 3.957l-8.422 14.06A1.989 1.989 0 0 0 3.518 21h16.845a1.989 1.989 0 0 0 1.7-2.983L13.64 3.957a1.989 1.989 0 0 0-3.4 0zM12 9v4m0 4h.01"-->
              <!--                  />-->
              <!--                </svg>-->

              <!--                <span-->
              <!--                  *ngIf="lostPercentage"-->
              <!--                  [ngClass]="-->
              <!--                    lostPercentage && lostPercentage <= highPriceImpactPercent-->
              <!--                      ? 'text-FF0000'-->
              <!--                      : lostPercentage &&-->
              <!--                        lostPercentage <= warningPriceImpactPercent-->
              <!--                      ? 'text-cd6116'-->
              <!--                      : lostPercentage && lostPercentage <= 0-->
              <!--                      ? ''-->
              <!--                      : 'text-green-400'-->
              <!--                  "-->
              <!--                >-->
              <!--                  {{ "(" + lostPercentage + "%)" }}-->
              <!--                </span>-->
              <!--              </div>-->
            </ng-container>

            <nz-skeleton-element
              class="skeleton-h-18"
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="!totalDestinationPaid || loadingAmountOut"
            ></nz-skeleton-element>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="exchangeType === ExchangeType.Limit"
    class="network-change-box price-section w-full px-2 pb-2 pt-1 d-flex flex-column mt-6px border-1px"
    [ngClass]="{
      'border-595962 border-radius-8px is-dark': isDarkMode,
      'border-0066FF border-radius-6px': !isDarkMode
    }"
  >
    <div class="d-flex w-full justify-content-between">
      <label for="limitInput" class="text-000 dark:text-fff font-12 m-0">
        Price
      </label>

      <div
        *ngIf="
          !loadingAmountOut &&
          (searchParam?.limitAmountDifference !== 0 ||
            estimation?.limitRengeInvalid ||
            estimation?.AmountOutEstimationUnknown)
        "
        class="d-flex align-items-center"
      >
        <!-- <div
          class="d-flex align-items-center"
          *ngIf="searchParam && searchParam.limitAmountDifference"
        >
          <span
            class="font-11"
            [ngClass]="{
              'text-ffc107':
                (limitFrom &&
                  searchParam.limitAmountDifference < 0 &&
                  searchParam.limitAmountDifference > -5) ||
                (!limitFrom &&
                  searchParam.limitAmountDifference > 0 &&
                  searchParam.limitAmountDifference < 5),
              'text-FF0000':
                (limitFrom && searchParam.limitAmountDifference <= -5) ||
                (!limitFrom && searchParam.limitAmountDifference >= 5),
              'text-green-400':
                (limitFrom && searchParam.limitAmountDifference > 0) ||
                (!limitFrom && searchParam.limitAmountDifference < 0)
            }"
          >
            {{ searchParam.limitAmountDifference }}%
            {{
              (limitFrom && searchParam.limitAmountDifference > 0) ||
              (!limitFrom && searchParam.limitAmountDifference < 0)
                ? "above"
                : "below"
            }}
          </span>

          <span
            (click)="setToMarket()"
            class="ms-1 cursor-pointer text-0066FF font-12"
          >
            Market
          </span>
        </div> -->
        <!-- *ngIf="!searchParam?.limitAmountDifference" -->

        <span
          (click)="setToMarket()"
          class="ms-1 cursor-pointer text-0066FF dark:text-3385FF font-12"
        >
          Set To Market
        </span>
      </div>
    </div>

    <div
      class="w-full border-2 border-9797971a border-radius-4px d-flex align-items-center amount-label position-relative"
    >
      <nz-input-group
        class="w-full border-0 bg-transparent"
        *ngIf="!loadingAmountOut || realExchangeType === ExchangeType.Limit"
        [nzAddOnBefore]="before"
        [nzAddOnAfter]="after"
      >
        <input
          type="text"
          id="limitInput"
          nz-input
          class="font-18 font-xs-28 text-000 dark:text-fff m-0 border-0 bg-transparent outline-none shadow-none"
          [(ngModel)]="limitAmount"
          (ngModelChange)="limitAmountChanged($event)"
          mask="separator"
          nz-tooltip="Or Set Your Target Price"
          [nzTooltipTrigger]="'hover'"
        />
      </nz-input-group>

      <ng-template #before>
        <div class="d-flex flex-column">
          <div
            class="text-00000099 dark:text-ffffff99 d-flex align-items-center"
          >
            <span> 1 </span>

            <div class="mx-1 d-flex align-items-center font-openSans-bold">
              <span class="font-11 letter-spacing0.5px">
                {{ limitFrom ? fromToken.symbol : toToken.symbol }}
              </span>

              <span
                class="font-8 ml-2px"
                *ngIf="fromToken.symbol === toToken.symbol"
              >
                ({{
                  web3Service.networkSpec[
                    limitFrom
                      ? network.from.selectedChainId
                      : network.to.selectedChainId
                  ].title
                }})
              </span>
            </div>

            <span> = </span>
          </div>
        </div>
      </ng-template>

      <ng-template #after>
        <div
          class="d-flex align-items-center text-00000080 dark:text-FFFFFFB2 cursor-pointer"
          (click)="changeLimitType(limitFrom)"
        >
          <div class="text-00000099 dark:text-ffffff99 font-openSans-bold">
            <span class="font-11 letter-spacing0.5px">
              {{ limitFrom ? toToken.symbol : fromToken.symbol }}
            </span>

            <span class="font-8" *ngIf="fromToken.symbol === toToken.symbol">
              ({{
                web3Service.networkSpec[
                  limitFrom
                    ? network.to.selectedChainId
                    : network.from.selectedChainId
                ].title
              }})
            </span>
          </div>

          <span class="text-323232 dark:text-FFFFFFBF ml-1">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4826_70838)">
                <path
                  d="M4.66 7.33594L2 10.0026L4.66 12.6693V10.6693H9.33333V9.33594H4.66V7.33594ZM14 6.0026L11.34 3.33594V5.33594H6.66667V6.66927H11.34V8.66927L14 6.0026Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_4826_70838">
                  <rect width="16" height="16" fill="transparent" />
                </clipPath>
              </defs>
            </svg>
          </span>
        </div>
      </ng-template>

      <nz-skeleton-element
        class="w-full limit-skeleton"
        nzType="button"
        [nzActive]="true"
        *ngIf="loadingAmountOut && realExchangeType === ExchangeType.Market"
      ></nz-skeleton-element>
    </div>
  </div>
</div>
