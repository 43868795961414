import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  ConnectWalletService,
  CrossChainSwapService,
  HistoryService,
  NDDClientInfoServiceImpl,
  NotificationService,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../../services';
import { BaseComponent } from '../../base.component';
import {
  Conversion,
  MainNetworksById,
  Networks,
  NetworksById,
  PriceService,
  TokensHolder
} from '@crowdswap/constant';
import Swiper, { SwiperOptions } from 'swiper';
import { formatNumber, NumberType } from '@uniswap/conedison/format';
import { BigNumber } from 'ethers';
import { ToastrService } from 'ngx-toastr';
import { HistoryTabs } from '../../cross-and-same-chain-swap/model/cross-chain-state.enum';
import {
  DestTxStatus,
  ITxHistoryToDisplay,
  TxHistoryOrg,
  TxStatus,
  TxType
} from 'src/app/model';
import { HttpErrorResponse } from '@angular/common/http';
import * as Papa from 'papaparse';
import { Constants } from 'src/app/constants';
import { ModalService } from 'src/app/views/modal/modal.service';
import { WaitingDialogComponent } from 'src/app/views/modal/dialogs/waiting-dialog/waiting-dialog.component';
import { environment } from '../../../../../environments/environment';

export interface ColumnItem {
  title: string;
  nzLeft?: boolean;
  nzWidth: string;
  inMobile: boolean;
  nzAlign?: 'left' | 'right' | 'center';
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent extends BaseComponent implements OnInit {
  public txHistoryTemplate = new TxHistoryOrg();
  public txHistoryToDisplay: ITxHistoryToDisplay[] = [];
  public txHistoryToFilter: ITxHistoryToDisplay[] = [];
  public filterValue: string = '';
  public showSetting: boolean = false;
  public historyPage = 1;
  public loading = false;
  public apiCallFlag: boolean = false;
  public historyState = HistoryTabs.All;
  public HistoryTabs = HistoryTabs;
  public selectedNetwork: number = -1;
  public filteredNetwork: number = -1;
  public Networks = Networks;
  public allNetworks: any[] = [];
  public allTxStates: any[] = ['All', 'In progress'];
  public txState: string = 'All';
  public txStateFiltered: string = 'All';
  public DestTxStatus = DestTxStatus;
  public TxStatus = TxStatus;
  public TxType = TxType;
  public parseInt = parseInt;
  public startDateDay: any;
  public endDateDay: any;
  public selectFlag: boolean = false;
  public transactionFlag: boolean = false;
  public contentSection;
  public swiperConfig: {
    networkConfig: SwiperOptions;
    allNetworksSlide: {
      index: number;
      isEnd: boolean;
    };
  } = {
    networkConfig: {
      slidesPerView: 'auto',
      spaceBetween: 3,
      direction: 'horizontal',
      navigation: {
        nextEl: '.network-swiper-next',
        prevEl: '.network-swiper-prev'
      }
    },
    allNetworksSlide: {
      index: 0,
      isEnd: false
    }
  };
  public table = {
    pageSize: 20,
    pageIndex: 1,
    scroll: {
      x: '350px'
    }
  };
  public expandSet = new Set<string>();

  public listOfColumn: ColumnItem[] = [];

  constructor(
    protected themeService: ThemeService,
    public web3Service: Web3Service,
    protected tagManagerService: TagManagerService,
    private crossChainSwapService: CrossChainSwapService,
    protected toastr: ToastrService,
    private historyService: HistoryService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    private priceService: PriceService,
    private notificationService: NotificationService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    protected connectWalletService: ConnectWalletService
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
    this.contentSection = document.getElementById('contentSection');
  }

  ngOnInit(): void {
    super.ngOnInit(
      undefined,
      async (connection) => {
        this.initTxHistory();
      },
      async (address) => {
        this.initTxHistory();
      }
    );

    this.initTxHistory();
    this.getAllNetworks();

    this.listOfColumn = [
      {
        title: 'Transaction',
        nzLeft: false,
        nzWidth: '250px',
        inMobile: true
      },
      {
        title: 'Token and amount',
        nzWidth: '200px',
        inMobile: true
      },
      {
        title: '',
        nzWidth: '50px',
        inMobile: true
      },
      {
        title: '',
        nzWidth: '200px',
        inMobile: true
      },
      {
        title: 'Date',
        nzWidth: '120px',
        inMobile: true
      },
      {
        title: 'Fee',
        nzWidth: '150px',
        inMobile: true
      },
      {
        title: 'Status',
        nzWidth: '180px',
        inMobile: true,
        nzAlign: 'right'
      }
    ];
  }

  private async getTransactionHistory(nextPage?: boolean) {
    try {
      this.loading = true;

      if (nextPage) {
        this.historyPage++;
      }

      const data: { [key: number]: ITxHistoryToDisplay[] } =
        await this.historyService.getTransactionHistory(
          this.web3Service.getWalletAddress() || '',
          20,
          this.historyPage
        );

      const flattedHistory: ITxHistoryToDisplay[] = [];
      for (const key in data) {
        if (Array.isArray(data[key])) {
          flattedHistory.push(...data[key]);
        }
      }

      flattedHistory.sort((a, b) => {
        return parseFloat(b.timeStamp) - parseFloat(a.timeStamp);
      });

      flattedHistory.map((d) => {
        if (d.txType === TxType.Invest) {
          d.txType = TxType.Farming;
        }

        d.tokensTransferred.map((t) => {
          if (t.amount) {
            t.amount = formatNumber(
              typeof t.amount == 'string' ? parseFloat(t.amount) : t.amount,
              NumberType.TokenTx
            );
          }
        });

        d.fees.map((f) => {
          if (f.amount) {
            f.amount = formatNumber(
              typeof f.amount == 'string' ? parseFloat(f.amount) : f.amount,
              NumberType.TokenTx
            );
          }
        });
      });

      this.txHistoryTemplate.all = nextPage
        ? [...this.txHistoryTemplate.all, ...flattedHistory]
        : flattedHistory;

      const inProgressData = this.txHistoryTemplate.all.filter((x) => {
        return (
          x.txStatus === TxStatus.TXUnConfirmed ||
          ([TxType.CrossChain, TxType.CrossChainLimitOrder].indexOf(x.txType) >
            -1 &&
            x.txStatus === TxStatus.TransactionConfirmed &&
            [undefined, DestTxStatus.Pending, DestTxStatus.Unknown].indexOf(
              x.destTxStatus
            ) > -1)
        );
      });

      this.txHistoryTemplate.inProgress = inProgressData;

      this.selectNetwork(this.selectedNetwork);
      this.checkPatchAvailable();
      this.scrollToTop();

      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.txHistoryTemplate = this.txHistoryTemplate ?? new TxHistoryOrg();
      this.scrollToTop();
    }
  }

  switchHistory(tab: string) {
    this.txState = tab;
    switch (tab) {
      case 'All': {
        this.historyState = HistoryTabs.All;
        break;
      }
      case 'In progress': {
        this.historyState = HistoryTabs.InProgress;
        break;
      }
    }
    this.txStateFiltered = this.txState;
    this.selectNetwork(this.selectedNetwork);
  }

  public selectNetwork(chainId: number) {
    this.selectedNetwork = chainId;

    switch (this.historyState) {
      case HistoryTabs.All:
        if (
          !this.txHistoryTemplate.all ||
          this.txHistoryTemplate.all.length === 0
        ) {
          this.txHistoryToDisplay = [];
          return;
        }

        this.txHistoryToDisplay =
          chainId === -1
            ? [...this.txHistoryTemplate.all]
            : [
                ...this.txHistoryTemplate.all.filter((history) => {
                  if (parseInt(history.chainId) === chainId) {
                    return history;
                  }
                })
              ];

        break;

      case HistoryTabs.InProgress:
        if (
          !this.txHistoryTemplate.inProgress ||
          this.txHistoryTemplate.inProgress.length === 0
        ) {
          this.txHistoryToDisplay = [];
          return;
        }

        this.txHistoryToDisplay =
          chainId === -1
            ? [...this.txHistoryTemplate.inProgress]
            : [
                ...this.txHistoryTemplate.inProgress.filter((history) => {
                  if (parseInt(history.chainId) === chainId) {
                    return history;
                  }
                })
              ];

        break;
    }
    this.txHistoryToFilter = this.txHistoryToDisplay;
    this.filterTransaction(this.filterValue);
    this.scrollToTop();
  }

  async showMore() {
    await this.getTransactionHistory(true);
  }

  async refresh() {
    if (!this.web3Service.isConnected()) {
      return;
    }

    await this.getTransactionHistory();
  }

  public async initTxHistory() {
    if (this.loading || !this.web3Service.isConnected()) {
      return;
    }

    await this.getTransactionHistory();
  }

  public getAllNetworks() {
    const originalNetworks = Object.keys(MainNetworksById).filter((chainId) =>
      environment.ACTIVE_NETWORK.includes(chainId)
    );
    const networks: { title: string; chainId: number }[] = [
      { title: 'All', chainId: -1 }
    ];

    originalNetworks.map((n: string) =>
      networks.push({
        title: this.web3Service.networkSpec[n]?.title,
        chainId: parseInt(n)
      })
    );

    this.allNetworks = [
      ...networks.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
    ];
  }

  public slideChange(data: Swiper[], field: { index: number; isEnd: boolean }) {
    field.index = data[0].activeIndex;
    field.isEnd = data[0].isEnd;
  }

  async cancelTransaction(item: ITxHistoryToDisplay) {
    try {
      const cancelTransaction =
        await this.crossChainSwapService.getCancelTransaction(
          'Debridge_CROWDSWAP',
          item.hash
        );

      if (!cancelTransaction) {
        // this.showErrorToaster('Error', 'Something went wrong!');
        console.log('Something went wrong!');
        return;
      }

      if (cancelTransaction.value) {
        cancelTransaction.value = BigNumber.from(cancelTransaction.value);
      }

      const destChainId = item.tokensTransferred.find(
        (item) => item.transferType === 'to'
      )?.token.chainId;

      if (!destChainId) {
        console.log('Destination chainId is missing!');
        this.toastr.error('Something went wrong, try again!', 'Error', {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          positionClass: 'custom-toast-top-right',
          enableHtml: true,
          timeOut: 10000,
          messageClass: 'errorClass'
        });
        return;
      }

      if (this.web3Service.getWalletChainId() !== destChainId) {
        await this.changeNetworkTo(destChainId);
      }

      for (const key in this.txHistoryTemplate) {
        if (Object.prototype.hasOwnProperty.call(this.txHistoryTemplate, key)) {
          const element: ITxHistoryToDisplay[] = this.txHistoryTemplate[key];
          const transaction = element.find((tx) => tx.hash === item.hash);
          if (transaction) {
            transaction.destTxStatus = DestTxStatus.CancelPending;
          }
        }
      }

      await this.web3Service
        .sendTransaction(cancelTransaction)
        .then(async () => {
          this.getTransactionHistory();

          console.log(item, ' DLN, Canceled by user. ');

          this.toastr.success(
            `<a href='${item.hash}' target='_blank'>View in explorer</a>`,
            `The Cross-chain swap has been canceled!`,
            {
              closeButton: true,
              tapToDismiss: false,
              progressBar: true,
              positionClass: 'custom-toast-top-right',
              enableHtml: true,
              timeOut: 10000,
              messageClass: 'successClass'
            }
          );
        });
    } catch (e) {
      this.getTransactionHistory();
      var httpError = e as HttpErrorResponse;
      if (httpError) {
        if (httpError.error.msg.includes('insufficient funds')) {
          this.toastr.error(
            `Something went wrong,<br/>please contact support.`,
            'Error',
            {
              closeButton: true,
              tapToDismiss: false,
              progressBar: true,
              positionClass: 'custom-toast-top-right',
              enableHtml: true,
              timeOut: 10000,
              messageClass: 'errorClass'
            }
          );
          return;
        }
      }

      console.log(e);
      this.toastr.error('Something went wrong, try again!', 'Error', {
        closeButton: true,
        tapToDismiss: false,
        progressBar: true,
        positionClass: 'custom-toast-top-right',
        enableHtml: true,
        timeOut: 10000,
        messageClass: 'errorClass'
      });
    }
  }

  formatTxType(txType: TxType): string {
    switch (txType) {
      case TxType.CrossChainLimitOrder:
        return TxType.CrossChain;

      case TxType.InvestEtf:
        return 'ETF';

      default:
        return txType;
    }
  }

  public async networkMenuClickHandler(selectedChainId: number) {
    if (selectedChainId === -1) {
      this.txHistoryToDisplay = this.txHistoryTemplate.all;
    } else {
      this.txHistoryToDisplay = this.txHistoryTemplate.all.filter((x) => {
        return +x.chainId === selectedChainId;
      });
    }
    this.txHistoryToFilter = this.txHistoryToDisplay;
    this.selectedNetwork = selectedChainId;
    this.selectFlag = false;
    this.switchHistory(this.txState);
  }

  public filterTransaction(value: string) {
    const filteredTransactions = this.txHistoryToFilter.filter((x) => {
      return (
        x.hash.toLowerCase().indexOf(value) >= 0 ||
        x.txType.toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
    });
    this.txHistoryToDisplay = filteredTransactions;
    this.scrollToTop();
  }

  public clearSearch() {
    this.filterValue = '';
    this.filterTransaction('');
    this.scrollToTop();
  }

  async saveFilter() {
    this.showSetting = false;
    this.txState = this.txStateFiltered;
    this.selectedNetwork = this.filteredNetwork;
    await this.networkMenuClickHandler(this.selectedNetwork);
    this.scrollToTop();
  }

  public clearFilter() {
    this.txStateFiltered = 'All';
    this.filteredNetwork = -1;
    this.scrollToTop();
  }

  public openFilter() {
    this.txStateFiltered = this.txState;
    this.filteredNetwork = this.selectedNetwork;
    this.showSetting = true;
  }

  downloadCSV() {
    const flatData = this.txHistoryToDisplay.map((item) => {
      let fromToken: { token: string; amount: string } = {
        token: '',
        amount: ''
      };
      let toToken: { token: string; amount: string } = {
        token: '',
        amount: ''
      };
      for (let token of item.tokensTransferred) {
        if (token.transferType === 'from') {
          fromToken.token = token.token.symbol;
          fromToken.amount = token.amount;
        } else {
          toToken.token = token.token.symbol;
          toToken.amount = token.amount;
        }
      }

      return {
        name: item.txType,
        hash: item.hash,
        network: this.web3Service.networkSpec[item.chainId]?.title,
        chainId: item.chainId,
        date: item.date,
        time: item.time,
        from_token: fromToken.token,
        from_amount: fromToken.amount,
        to_token: toToken.token,
        to_amount: toToken.amount,
        fee_token: item.fees[0].token,
        fee_token_amount: item.fees[0].amount,
        tx_status:
          item.destTxStatus === DestTxStatus.Unknown
            ? 'Completed'
            : item.destTxStatus
      };
    });
    const csvData = Papa.unparse(flatData, {
      header: true // Include header row
    });

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    // Create a link and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'crowdswap_history.csv';
    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  public checkPatchAvailable() {
    this.txHistoryTemplate.inProgress.map((txn) => {
      if (
        [TxType.CrossChain, TxType.CrossChainLimitOrder].indexOf(txn.txType) ===
          -1 ||
        !txn.isPatchable
      ) {
        return;
      }

      this.preparePatchNewAmount(txn);
    });
  }

  public async preparePatchNewAmount(transaction: ITxHistoryToDisplay) {
    try {
      const pathTransactionResult = await this.getPatchTransaction(transaction);

      transaction.tokensTransferred.map((t) => {
        if (t.transferType === 'from') {
          transaction.fromTokensTransferred = t;
        }

        if (t.transferType === 'to') {
          transaction.toTokensTransferred = t;
        }
      });

      if (
        !transaction.fromTokensTransferred ||
        !transaction.toTokensTransferred
      ) {
        return;
      }

      const newAmountOut = Conversion.convertStringFromDecimal(
        pathTransactionResult.newAmountOut?.toString(),
        transaction.toTokensTransferred.token.decimals
      );

      transaction.newAmountOut = parseFloat(newAmountOut);

      transaction.newAmountOutToDisplay = parseFloat(
        formatNumber(parseFloat(newAmountOut), NumberType.TokenTx)
      );

      transaction.newAmountOutVisibility = false;

      if (transaction.toTokensTransferred?.token.symbol) {
        const tokenPrice = await this.priceService.getPrice(
          TokensHolder.TokenListBySymbol[
            NetworksById[transaction.toTokensTransferred.token.chainId]
          ][transaction.toTokensTransferred.token.symbol],
          this.web3Service.getNetworkProvider(
            transaction.toTokensTransferred.token.chainId
          )
        );

        transaction.newAmountOutUsdt = formatNumber(
          Number(newAmountOut) * parseFloat(tokenPrice),
          NumberType.FiatTokenPrice
        );
      }

      transaction.patchTx = pathTransactionResult.tx;
      console.log('transaction: ', transaction);
    } catch (e) {
      console.log(e);
    }
  }

  public async getPatchTransaction(transaction: ITxHistoryToDisplay) {
    try {
      if (!transaction || !transaction?.hash) {
        console.log('transaction hash is not provided');
        return;
      }

      const patchTransaction =
        await this.crossChainSwapService.getPatchTransaction(
          Constants.CrosschainsMap[1],
          transaction.hash
        );

      if (!patchTransaction) {
        console.log('patchTransactionResult is empty');
      }

      return patchTransaction;
    } catch (e) {
      console.log(e);
    }
  }

  public async approvePatchNewAmount(transaction: ITxHistoryToDisplay) {
    try {
      await this.approvePatchTransaction(transaction, transaction.patchTx)
        .then(() => {
          console.log('set approved approvedPatchTx = true');
        })
        .catch((error) => {
          console.error('Error approving patch transaction:', error);
        });
    } catch (e) {
      console.log(e);
    }
  }

  public async approvePatchTransaction(
    transaction: ITxHistoryToDisplay,
    patchTx: any
  ) {
    try {
      // const crossAndSameChainEstimation = <CrossAndSameChainEstimateTrade>(
      //   estimation
      // );
      if (!transaction || !transaction?.hash) {
        console.log('transaction hash is not provided');
        return;
      }

      if (!patchTx) {
        this.notificationService.error({
          title: 'Error',
          content: 'Something went wrong, try again!',
          options: {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'failedClass'
          }
        });
        return;
      }

      if (patchTx.value) {
        patchTx.value = BigNumber.from(patchTx.value);
      }

      if (
        transaction.toTokensTransferred?.token.chainId &&
        this.web3Service.getWalletChainId() !==
          transaction.toTokensTransferred?.token.chainId
      ) {
        await this.changeNetworkTo(
          transaction.toTokensTransferred?.token.chainId
        );
      }

      const currentTransaction = await this.web3Service
        .sendTransaction(patchTx)
        .then(async (data) => {
          await this.waitingDialogService.close();

          // await this.tagManagerService.sendCrossChainTags(
          //   'cross_wallet_confirm',
          //   crossAndSameChainEstimation!,
          //   this.walletAddress,
          //   this.setting.expertMode
          // );

          console.log(' Swap confirmed. ');

          this.getTransactionHistory();
          return data;
        })
        .catch(async (e) => {
          // await this.tagManagerService.sendCrossChainTags(
          //   'cross_wallet_reject',
          //   crossAndSameChainEstimation!,
          //   this.web3Service.getWalletAddress(),
          //   this.setting.expertMode
          // );
          // this.setConsoleLog(crossAndSameChainEstimation, ' Swap rejected! ');
          console.log(e);

          this.notificationService.error({
            title: 'Error',
            content: 'Swap rejected!'
          });

          this.getTransactionHistory();
          throw e;
        });
    } catch (e) {
      console.log(e);
      this.getTransactionHistory();
      throw e;
    }
  }

  public scrollToTop(): void {
    document.getElementById('contentSection')?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  onExpandChange(hash: string): void {
    if (!this.expandSet.has(hash)) {
      this.expandSet.add(hash);
    } else {
      this.expandSet.delete(hash);
    }
  }
}
