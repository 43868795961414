import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ModalService } from '../../modal/modal.service';
import { WaitingDialogComponent } from '../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ConfirmSwapDialogComponent } from '../../modal/dialogs/confirm-swap-dialog/confirm-swap-dialog.component';
import { TransactionSubmittedDialogComponent } from '../../modal/dialogs/transaction-submitted-dialog/transaction-submitted-dialog.component';
import { SwapIsDisabledDialogComponent } from '../../modal/dialogs/swap-is-disabled/swap-is-disabled-dialog.component';
import { asyncScheduler, interval, Subscription } from 'rxjs';
import { BigNumber } from 'ethers';
import {
  Conversion,
  PriceService,
  UNUSUAL_MODE_PERCENTAGE,
  CrowdToken,
  commonConfig
} from '@crowdswap/constant';
import { SettingDialogComponent } from '../../modal/dialogs/setting-dialog/setting-dialog.component';
import { TokenSelectionComponent } from './token-selection/token-selection.component';
import { ToastrService } from 'ngx-toastr';
import { Builder } from 'builder-pattern';
import { filter, throttleTime } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { BaseSwapComponent } from '../cross-and-same-chain-swap/base-swap.component';
import { SettingModel } from '../../modal/dialogs/setting-dialog/setting-models';
import {
  ConnectWalletService,
  CrowdSwapService,
  LoggingService,
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  TokensService,
  Web3Service
} from '../../../services';
import {
  CurrentNetwork,
  DialogDataModel,
  EstimateTrade,
  PendingTransactionModel,
  SearchParamModel
} from '../../../model';
import { environment } from '../../../../environments/environment';

export enum SwapState {
  Init,
  WalletNotConnected,
  ApprovalNeeded,
  ApprovalConfirmed,
  ApprovalRejected,
  SwapConfirmed,
  SwapFailed,
  Successful,
  InsufficientSourceBalance,
  Expired
}

@Component({
  selector: 'app-swap[bestEstimation]',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.scss']
})
export class SwapComponent extends BaseComponent implements OnInit, OnDestroy {
  SwapState = SwapState;
  swapState: SwapState = SwapState.WalletNotConnected;
  dollarBase: boolean = false;
  estimation: EstimateTrade = <any>{};
  pendingTxList: PendingTransactionModel[] = [];
  subscriptionList: Subscription[] = [];
  searchParams?: SearchParamModel;
  autoSearchSubscriber: any = null;
  isMismatchNetwork: boolean = false;
  incorrectNetworkTooltipText: String = '';
  hasAutoSearchSubscriber: boolean = true;
  public countDownTimer: number = 100;
  public swapStyle: string = '';
  public receiverAddress: string = '';
  public isReceiverWalletSelected: boolean = false;
  public isAddressValid: boolean = false;

  private receiverWalletAddress: ElementRef | undefined;
  @ViewChild('receiverWalletAddress', { static: false })
  set content(content: ElementRef) {
    if (content) {
      this.receiverWalletAddress = content;
    }
  }

  @Input()
  set bestEstimation(bestEstimation: EstimateTrade) {
    if (bestEstimation) {
      if (
        !this.isSwapState(SwapState.WalletNotConnected) &&
        !this.isSwapState(SwapState.InsufficientSourceBalance)
      ) {
        this.checkAllowance(bestEstimation).then();
      }
      this.estimation = bestEstimation;
    } else {
      this.estimation = BaseSwapComponent.getDummyEstimationTrade();
    }
    this.ref.detectChanges();
  }

  @Output()
  searchParamsChanged = new EventEmitter();

  @ViewChild(TokenSelectionComponent) childTokenSelect:
    | TokenSelectionComponent
    | undefined;

  constructor(
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    protected tagManagerService: TagManagerService,
    protected themeService: ThemeService,
    private priceService: PriceService,
    private crowdSwapService: CrowdSwapService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private confirmDialogService: ModalService<ConfirmSwapDialogComponent>,
    private settingDialogService: ModalService<SettingDialogComponent>,
    private txSubmittedDialogService: ModalService<TransactionSubmittedDialogComponent>,
    private swapIsDisabledDialogService: ModalService<SwapIsDisabledDialogComponent>,
    private toastr: ToastrService,
    private loggingService: LoggingService,
    private tokensService: TokensService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    protected connectWalletService: ConnectWalletService
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit() {
    super.ngOnInit();
    await this.tagManagerService.sendStartViewTag('classicSwap');
    if (
      this.web3Service.isConnected() &&
      this.web3Service.getCurrentChainId() !==
        this.web3Service.getWalletChainId() &&
      this.web3Service.getCurrentChainId() > 0
    ) {
      this.isMismatchNetwork = true;
    } else {
      this.isMismatchNetwork = false;
    }
    this.swapState = this.getCurrentRightState();
    // On wallet connect/disconnect
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe((connection) => {
        this.swapState = connection
          ? SwapState.Init
          : SwapState.WalletNotConnected;
        this.ref.detectChanges();
      })
    );

    // On network change
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe((currentNetwork: CurrentNetwork) => {
          this.receiverAddress = '';
          this.isReceiverWalletSelected = false;
          this.isWrongNetwork = false;
          this.ref.detectChanges();
        })
    );

    //The network selected in the wallet is not supported by our app
    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        (isWrongNetwork: boolean) => {
          this.isWrongNetwork = isWrongNetwork;
          if (this.isWrongNetwork) {
            this.incorrectNetworkMessage = 'Wrong network';
            this.incorrectNetworkTooltipText =
              'Please connect to a supported network in the dropdown menu or in your wallet.';
          }
          this.ref.detectChanges();
        }
      )
    );

    //The network of specified in the url (if exists) is different from the wallet network.
    this.subscriptionList.push(
      this.web3Service.mismatchNetworkSubject.subscribe(
        (isMismatchNetwork: boolean) => {
          this.isMismatchNetwork = isMismatchNetwork;
          if (this.isMismatchNetwork) {
            this.incorrectNetworkMessage = 'Mismatch network';
            this.incorrectNetworkTooltipText = `The page network is not matched with the wallet network.`;
          }
          this.ref.detectChanges();
        }
      )
    );

    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async (address) => {
        if (this.searchParams) {
          this.changeSwapState(SwapState.Init);
          await this.checkBalance(
            this.searchParams.fromToken,
            this.searchParams.swapValue
          );
        }
        if (this.estimation) {
          const chainId = this.estimation.fromToken.chainId; //or const chainId=this.estimation.toToken.chainId
          if (
            !this.isSwapState(SwapState.WalletNotConnected) &&
            !this.isSwapState(SwapState.InsufficientSourceBalance) &&
            chainId === this.web3Service.getCurrentChainId()
          ) {
            await this.checkAllowance(this.estimation);
          }
        }
      })
    );
  }

  public async settingDialog() {
    const data = { isDarkMode: this.isDarkMode };
    const settingDialogRef = await this.settingDialogService.open(
      SettingDialogComponent,
      data,
      false
    );
    <SettingDialogComponent>(<any>settingDialogRef!.instance).closed.subscribe(
      async (isChanged) => {
        await this.settingDialogService.close();
        if (isChanged) {
          await this.refreshSearch();
        }
      }
    );
  }
  async onPairSelected(searchParam: SearchParamModel) {
    this.searchParams = searchParam;
    const setting = this.getSetting();
    searchParam.slippage = setting.slippage;
    searchParam.deadline = setting.deadline;

    this.estimation = BaseSwapComponent.getDummyEstimationTrade();

    var state: SwapState = this.getCurrentRightState();

    this.changeSwapState(state);
    await this.checkBalance(searchParam.fromToken, searchParam.swapValue);
    this.searchParamsChanged.emit(searchParam);

    await this.startAutoRefresh(!this.hasAutoSearchSubscriber);
  }

  async approve(): Promise<any> {
    this.unSubscribeAutoSearch();
    let readyToSwap = true;
    try {
      if (!this.isCoin(this.estimation.fromToken)) {
        await this.showApproveWaitingDialog(this.estimation);
        readyToSwap = await this.approveTransfer(
          this.estimation.fromToken,
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
          this.estimation
        );
      }
      if (readyToSwap) {
        this.changeSwapState(SwapState.ApprovalConfirmed);
      } else {
        this.changeSwapState(SwapState.ApprovalNeeded);
        this.showErrorToaster('Error', 'Approval rejected!');
      }
      await this.waitingDialogService.close();
    } catch (e) {
      console.log(e);
      this.changeSwapState(SwapState.ApprovalRejected);
    }
  }

  async swap(): Promise<any> {
    if (!this.estimation) {
      this.setConsoleLog(this.swapState, ' Start swap estimation failed. ');
      return;
    }
    const setting = this.getSetting();
    const chainId = this.estimation.fromToken.chainId; //or const chainId=this.estimation.toToken.chainId;
    await this.tagManagerService.sendSwapTags(
      'swap_start',
      this.estimation,
      this.web3Service.getNetworkName(this.web3Service.getCurrentChainId()),
      this.receiverAddress || this.web3Service.getWalletAddress()
    );
    let totalPending = 0;
    const pendingList = this.pendingTxList.filter(
      (pending) => pending.sourceTokenSymbol == this.estimation.fromToken.symbol
    );
    pendingList.forEach((pending) => (totalPending += +pending.amountIn));
    const sourceTokenBalance = parseFloat(
      await this.tokensService.getTokenBalance(this.estimation.fromToken)
    );
    const amountIn = +SwapComponent.getAmount(
      this.estimation.amountIn,
      this.estimation.fromToken.decimals
    );

    const [unUsualMode, sourceValue, destValue] = this.checkUnUsualSituation();
    const data: DialogDataModel = {
      sourceTokenSymbol: this.estimation.fromToken.symbol,
      destTokenSymbol: this.estimation.toToken.symbol,
      sourceValue: sourceValue,
      destValue: destValue,
      amountIn: SwapComponent.getAmount(
        this.estimation.amountIn,
        this.estimation.fromToken.decimals
      ),
      amountOut: SwapComponent.getAmount(
        this.estimation.amountOut,
        this.estimation.toToken.decimals
      ),
      minAmountOut: SwapComponent.getAmount(
        this.estimation.minAmountOut,
        this.estimation.toToken.decimals
      ),
      swapFee: SwapComponent.getAmount(
        this.estimation.swapFee,
        this.estimation.fromToken.decimals
      ),
      sourcePrice: this.estimation.fromToken.price,
      destinationPrice: Conversion.replaceSeparator(
        Conversion.adjustFraction(this.estimation.toToken.price, 4)
      ),
      amountOutPerAmountInRatio: this.estimation.amountOutPerAmountInRatio,
      amountInPerAmountOutRatio: this.estimation.amountInPerAmountOutRatio,
      priceImpact: this.estimation.priceImpact,
      slippage: setting.slippage,
      deadline: setting.deadline,
      sourceNetworkName: this.web3Service.networkSpec[chainId].title,
      targetNetworkName: '',
      receiverWalletAddress: this.isReceiverWalletSelected
        ? this.receiverAddress
        : '',
      isDarkMode: this.isDarkMode,
      unusualMode: unUsualMode
    };
    if (amountIn > sourceTokenBalance - totalPending) {
      if (pendingList.length > 1) {
        data.warning = `There are currently ${pendingList.length} pending transactions with total value of
          ${totalPending} ${this.estimation.fromToken.symbol}. This might cause this transaction to fail.`;
      } else {
        data.warning = `There is currently ${pendingList.length} pending transaction with total value of
          ${totalPending} ${this.estimation.fromToken.symbol}. This might cause this transaction to fail.`;
      }
    }
    if (setting.expertMode) {
      this.setConsoleLog(
        this.swapState,
        ' Start confirm swap in expert mode. '
      );
      await this.confirmSwap(false);
    } else {
      await this.showConfirmSwapDialog(data, setting);
    }
  }

  private checkUnUsualSituation() {
    let sourceValue: number;
    let destinationValue: number;

    let valueFrom = this.priceService.getTokenValueByAmount(
      this.estimation.fromToken,
      this.estimation.amountIn
    );
    sourceValue = valueFrom === undefined ? 0 : +valueFrom;

    let valueTo = this.priceService.getTokenValueByAmount(
      this.estimation.toToken,
      this.estimation.amountOut
    );
    destinationValue = valueTo === undefined ? 0 : +valueTo;

    return [
      destinationValue <
        sourceValue - (sourceValue * UNUSUAL_MODE_PERCENTAGE) / 100,
      sourceValue,
      destinationValue
    ];
  }

  async confirmSwap(checkValidation: boolean): Promise<any> {
    try {
      const setting = this.getSetting();
      await this.tagManagerService.sendSwapTags(
        'swap_confirm',
        this.estimation,
        this.web3Service.getNetworkName(this.web3Service.getCurrentChainId()),
        this.receiverAddress || this.web3Service.getWalletAddress()
      );

      await this.confirmDialogService.close();
      await this.showSwapWaitingDialog(this.estimation);

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        this.setConsoleLog(this.swapState, ' Start getting transaction. ');
        if (this.isAddressValid && this.isReceiverWalletSelected) {
          this.loggingService.log(
            `Sender address is : ${userAddress} & Receiver address is : ${this.receiverAddress} for ${this.estimation}`
          );
        }

        let swapTx: any;
        swapTx = await this.useSwapTxOrDoTransaction(
          setting,
          userAddress,
          checkValidation
        );

        if (!swapTx) {
          await this.tagManagerService.sendSwapTags(
            'swap_error',
            this.estimation,
            this.web3Service.getNetworkName(
              this.web3Service.getCurrentChainId()
            ),
            this.receiverAddress || this.web3Service.getWalletAddress(),
            'tx_not_found'
          );
          this.showErrorToaster('Error', 'Something went wrong!');
          this.swapState = SwapState.SwapFailed;
          this.setConsoleLog(this.swapState, ' Getting transaction failed. ');
          await this.waitingDialogService.close();
          return;
        }
        if (swapTx.state === '508') {
          await this.tagManagerService.sendSwapTags(
            'swap_error',
            this.estimation,
            this.web3Service.getNetworkName(
              this.web3Service.getCurrentChainId()
            ),
            this.receiverAddress || this.web3Service.getWalletAddress(),
            'error_508'
          );
          this.toastr.error(
            'This transaction carries the risk of assets lost and its\n' +
              '                execution is not supported by CrowdSwap.',
            'Error',
            {
              closeButton: true,
              tapToDismiss: false,
              progressBar: true,
              positionClass: 'custom-toast-top-right',
              timeOut: 15000,
              messageClass: 'errorClass-unusual'
            }
          );
          this.swapState = SwapState.SwapFailed;
          this.setConsoleLog(
            this.swapState,
            ' Getting transaction failed.This transaction carries the risk of assets lost and its\n' +
              '                execution is not supported by CrowdSwap. '
          );
          await this.waitingDialogService.close();
          return;
        }
        delete swapTx.state;

        this.setConsoleLog(this.swapState, ' Getting transaction success. ');
        console.info(
          `Swap delegatedDex: ${this.estimation.dexName},` +
            ` fromToken: ${this.estimation.fromToken.symbol}(${this.estimation.fromToken.chainId})(${this.estimation.fromToken.address}),` +
            ` toToken: ${this.estimation.toToken.symbol}(${this.estimation.toToken.chainId})(${this.estimation.toToken.address}),` +
            ` amountIn: ${this.estimation.amountIn}, amountInInUSDT: ${this.estimation.amountInInUSDT},` +
            ` amountOut: ${this.estimation.amountOut}, amountOutInUSDT: ${this.estimation.amountOutInUSDT}, minAmountOut: ${this.estimation.minAmountOut},` +
            ` route: ${this.estimation.routes}, priceImpact: ${this.estimation.priceImpact}`
        );
        if (swapTx.value) {
          swapTx.value = BigNumber.from(swapTx.value);
        }

        await this.tagManagerService.sendSwapTags(
          'swap_confirmation_view',
          this.estimation,
          this.web3Service.getNetworkName(this.web3Service.getCurrentChainId()),
          this.receiverAddress || this.web3Service.getWalletAddress()
        );

        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction({
            ...swapTx,
            ...(swapTx?.gasLimit
              ? {
                  gas: swapTx?.gasLimit?.toString(),
                  gasLimit: swapTx?.gasLimit?.toString()
                }
              : {})
          })
          .then(async (data) => {
            await this.tagManagerService.sendSwapTags(
              'swap_confirmation_confirm',
              this.estimation,
              this.web3Service.getNetworkName(
                this.estimation.fromToken.chainId
              ),
              this.receiverAddress || this.walletAddress
            );

            await this.waitingDialogService.close();
            this.changeSwapState(SwapState.SwapConfirmed);
            this.setConsoleLog(this.swapState, ' Swap confirmed. ');

            const pendingTx: PendingTransactionModel = {
              sourceTokenSymbol: this.estimation.fromToken.symbol!,
              destTokenSymbol: this.estimation.toToken.symbol!,
              amountIn: SwapComponent.getAmount(
                this.estimation.amountIn,
                this.estimation.fromToken.decimals
              ),
              amountOut: SwapComponent.getAmount(
                this.estimation.amountOut,
                this.estimation.toToken.decimals
              ),
              chainId: this.estimation.fromToken.chainId,
              hash: data
            };
            this.addToPendingTxList(pendingTx);
            return data;
          })
          .catch(async (e) => {
            await this.tagManagerService.sendSwapTags(
              'swap_confirmation_reject',
              this.estimation,
              this.web3Service.getNetworkName(
                this.estimation.fromToken.chainId
              ),
              this.receiverAddress || this.walletAddress
            );
            console.log(e.message);
            this.showErrorToaster('Error', 'Swap rejected!');
            this.changeSwapState(SwapState.SwapFailed);
            this.setConsoleLog(this.swapState, ' Swap rejected! ');
            await this.waitingDialogService.close();
          });

        if (currentTransaction) {
          await this.showSuccessfulDialog(this.estimation, currentTransaction);

          // Wait for swap tx confirmation
          await this.web3Service
            .waitForTransaction(
              currentTransaction,
              1,
              this.web3Service.getNetworkProvider(
                this.web3Service.getCurrentChainId()
              )
            )
            .then(async (data) => {
              if (data?.status === 1) {
                if (
                  parseFloat(this.estimation.amountInInUSDT) >=
                    environment.airdrop.minimumSwapAmount &&
                  this.estimation.toToken.symbol === 'CROWD'
                ) {
                  this.runGleamScript('classicVersion');
                }
                this.changeSwapState(SwapState.Successful);
                this.setConsoleLog(this.swapState, ' Swap Successful. ');
              } else {
                this.changeSwapState(SwapState.SwapFailed);
                this.setConsoleLog(this.swapState, ' Swap Failed. ');
              }

              let pendingTx = this.findFromPendingList(currentTransaction);
              if (!pendingTx) {
                return;
              }
              this.removeFromPendingTxList(currentTransaction);
              const scanTransactionUrl =
                this.web3Service.getScanTransactionUrl(currentTransaction);
              switch (this.swapState) {
                case SwapState.Successful: {
                  await this.tagManagerService.sendSwapTags(
                    'swap_done',
                    this.estimation,
                    this.web3Service.getNetworkName(
                      this.web3Service.getCurrentChainId()
                    ),
                    this.receiverAddress || this.web3Service.getWalletAddress(),
                    'success'
                  );

                  this.toastr.success(
                    `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
                    `Swap exactly ${pendingTx.amountIn} ${pendingTx.sourceTokenSymbol} to ${pendingTx.amountOut} ${pendingTx.destTokenSymbol}`,
                    {
                      closeButton: true,
                      tapToDismiss: false,
                      progressBar: true,
                      positionClass: 'custom-toast-top-right',
                      enableHtml: true,
                      timeOut: 10000,
                      messageClass: 'successClass'
                    }
                  );
                  break;
                }
                case SwapState.SwapFailed: {
                  await this.tagManagerService.sendSwapTags(
                    'swap_error',
                    this.estimation,
                    this.web3Service.getNetworkName(
                      this.web3Service.getCurrentChainId()
                    ),
                    this.receiverAddress || this.web3Service.getWalletAddress(),
                    'failed_swap'
                  );
                  this.toastr.error(
                    `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
                    `Swap failed`,
                    {
                      closeButton: true,
                      tapToDismiss: false,
                      progressBar: true,
                      positionClass: 'custom-toast-top-right',
                      enableHtml: true,
                      timeOut: 10000,
                      messageClass: 'failedClass'
                    }
                  );
                  return;
                }
              }
              await this.checkBalance(
                this.estimation.fromToken,
                SwapComponent.getAmount(
                  this.estimation.amountIn,
                  this.estimation.fromToken.decimals
                )
              );
              return data;
            })
            .catch((e) => {
              console.log(e);
              this.changeSwapState(SwapState.SwapFailed);
              this.setConsoleLog(
                this.swapState,
                ' Swap Failed in internal catch. '
              );
              this.removeFromPendingTxList(currentTransaction);
            });
        }
      }
    } catch (e) {
      console.log(e);
      this.changeSwapState(SwapState.SwapFailed);
      this.setConsoleLog(this.swapState, ' Swap Failed in external catch. ');
      await this.waitingDialogService.close();
    }
  }

  /**
   * Check scroll event for fix position of swap component
   * @param $event
   */
  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    if (this.estimation?.loading) {
      return;
    }

    let swapContainer = document.getElementById('swapContainer');
    let swap = document.getElementById('swapBox');

    if (window.pageYOffset > 30 && window.innerWidth > 767) {
      swap?.classList.add('fixed');
      let sub = 24;
      if (window.innerWidth > 1279) {
        sub = 72;
      }
      let width = Number(swapContainer?.offsetWidth) - sub;
      this.swapStyle = 'width:' + width.toString() + 'px';
    } else {
      swap?.classList.remove('fixed');
      this.swapStyle = '';
    }
  }

  /**
   * Check screen resize
   * @param $event
   */
  @HostListener('window:resize', ['$event']) onResize(event) {
    if (this.estimation?.loading) {
      return;
    }

    let swapContainer = document.getElementById('swapContainer');
    let swap = document.getElementById('swapBox');

    if (window.pageYOffset > 30 && window.innerWidth > 767) {
      swap?.classList.add('fixed');
      let sub = 24;
      if (window.innerWidth > 1279) {
        sub = 72;
      }
      let width = Number(swapContainer?.offsetWidth) - sub;
      this.swapStyle = 'width:' + width.toString() + 'px';
    } else {
      swap?.classList.remove('fixed');
      this.swapStyle = '';
    }
  }

  private async checkAllowance(estimation: EstimateTrade): Promise<any> {
    try {
      if (this.isCoin(estimation.fromToken)) {
        return;
      }

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        const allowance: BigNumber = await this.web3Service
          .getAllowanceBySpender(
            estimation.approveAddress,
            estimation.fromToken.address,
            userAddress,
            this.web3Service.getNetworkProvider(estimation.fromToken.chainId)
          )
          .catch((e) => {
            console.log(e);
            this.showErrorToaster('Error', 'Something went wrong!');
            this.changeSwapState(SwapState.ApprovalRejected);
          });
        const allowanceValue = Conversion.toSafeBigNumberByRemovingFraction(
          allowance.toString()
        );
        const amountInValue = Conversion.toSafeBigNumberByRemovingFraction(
          estimation.amountIn
        );
        if (allowanceValue.lt(amountInValue)) {
          this.changeSwapState(SwapState.ApprovalNeeded);
        }
      }
    } catch (e) {
      console.log(e);
      this.changeSwapState(SwapState.ApprovalRejected);
    } finally {
      this.ref.detectChanges();
    }
  }

  private async approveTransfer(
    token: CrowdToken,
    amount: string,
    estimation
  ): Promise<boolean> {
    let readyToSwap = true;
    try {
      // Get approval tx
      let approvalTx = await this.web3Service.getApprovalTransactionBySpender(
        estimation.approveAddress,
        token.address,
        amount,
        this.web3Service.getNetworkProvider(estimation.fromToken.chainId)
      );
      // Send approval tx
      approvalTx = await this.web3Service
        .sendTransaction(approvalTx)
        .catch((e) => {
          console.log(e);
          this.changeSwapState(SwapState.ApprovalRejected);
          readyToSwap = false;
        });
      if (!approvalTx) {
        return false;
      }
      // Wait for approval tx confirmation
      await this.web3Service
        .waitForTransaction(
          approvalTx,
          1,
          this.web3Service.getNetworkProvider(
            this.web3Service.getCurrentChainId()
          )
        )
        .then((data) => {
          if (data?.status === 1) {
            this.changeSwapState(SwapState.ApprovalConfirmed);
          } else {
            this.changeSwapState(SwapState.ApprovalNeeded);
          }
        })
        .catch((e) => {
          console.log(e);
          this.changeSwapState(SwapState.ApprovalRejected);
          readyToSwap = false;
        });
    } catch (e) {
      console.log(e);
      this.changeSwapState(SwapState.ApprovalRejected);
      readyToSwap = false;
    }
    return readyToSwap;
  }

  private async showApproveWaitingDialog(estimation: EstimateTrade) {
    const data = {
      sourceTokenSymbol: estimation.fromToken.symbol
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  private async showSwapWaitingDialog(estimation: EstimateTrade) {
    const data = {
      sourceTokenSymbol: estimation.fromToken.symbol,
      destTokenSymbol: estimation.toToken.symbol,
      amountIn: SwapComponent.getAmount(
        estimation.amountIn,
        estimation.fromToken.decimals
      ),
      amountOut: SwapComponent.getAmount(
        estimation.amountOut,
        estimation.toToken.decimals
      ),
      isDarkMode: this.isDarkMode
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  private async showConfirmSwapDialog(
    data: DialogDataModel,
    setting: SettingModel
  ) {
    let confirmSwapDialogRef = await this.confirmDialogService.open(
      ConfirmSwapDialogComponent,
      data
    );

    await this.tagManagerService.sendSwapTags(
      'swap_view',
      this.estimation,
      this.web3Service.getNetworkName(this.web3Service.getCurrentChainId()),
      this.web3Service.getWalletAddress() //todo in the develop this.receiverAddress,
    );
    const sub: Subscription = (<ConfirmSwapDialogComponent>(
      (<any>confirmSwapDialogRef!.instance)
    )).confirmed.subscribe(async (event) => {
      if (event.state) {
        this.setConsoleLog(
          this.swapState,
          ' Start confirm swap from confirm dialog. '
        );
        await this.confirmSwap(event.isUnusual);
      } else if (!event.state) {
        await this.confirmDialogService.close();
        this.ref.detectChanges();
      }
    });
    confirmSwapDialogRef!.onDestroy(() => {
      if (this.isReceiverWalletSelected) {
        this.receiverWalletAddress?.nativeElement.focus();
      }
      sub.unsubscribe();
    });
  }

  private async showSuccessfulDialog(
    estimation: EstimateTrade,
    transaction: any
  ) {
    const data = {
      scanUrl: this.web3Service.getScanTransactionUrl(transaction),
      destTokenSymbol: estimation.toToken.symbol,
      isDarkMode: this.isDarkMode
    };
    await this.txSubmittedDialogService.open(
      TransactionSubmittedDialogComponent,
      data
    );
  }

  private static getAmount(amount: string, decimal: number) {
    return Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(amount, decimal),
      6
    );
  }

  private changeSwapState(swapState: SwapState) {
    if (
      this.isSwapState(SwapState.WalletNotConnected) &&
      swapState !== SwapState.Expired
    ) {
      return;
    }
    if (
      this.isSwapState(SwapState.InsufficientSourceBalance) &&
      swapState != SwapState.Init
    ) {
      return;
    }
    this.swapState = swapState;
  }

  private isSwapState(swapState: SwapState) {
    return this.swapState == swapState;
  }

  private async checkBalance(token: CrowdToken, amountInValue: string) {
    const sourceBalance = await this.tokensService.getTokenBalance(token);
    if (+sourceBalance < +amountInValue) {
      this.changeSwapState(SwapState.InsufficientSourceBalance);
    }
    this.ref.detectChanges();
  }

  private addToPendingTxList(pendingTx: PendingTransactionModel) {
    this.pendingTxList.push(pendingTx);
    this.web3Service.pendingChangeSubject.next(this.pendingTxList.length);
  }

  private removeFromPendingTxList(hash: string) {
    let pendingTx = this.findFromPendingList(hash);
    if (pendingTx) {
      this.pendingTxList.splice(this.pendingTxList.indexOf(pendingTx), 1);
      this.web3Service.pendingChangeSubject.next(this.pendingTxList.length);
      this.web3Service.assetChangeSubject.next(true);
    }
  }

  private findFromPendingList(
    hash: string
  ): PendingTransactionModel | undefined {
    return this.pendingTxList.find((pending) => pending.hash === hash);
  }

  private async startAutoRefresh(ignoreAutoRefresh: boolean = false) {
    this.unSubscribeAutoSearch();

    if (!this.searchParams?.lastUserChangeTime && this.searchParams)
      this.searchParams.lastUserChangeTime = Date.now();

    const timer$: any = interval(100);
    this.autoSearchSubscriber = timer$.subscribe(async (sec) => {
      this.countDownTimer =
        100 - (sec * 10) / CrowdSwapService.INTERVAL_REFRESH_TIME;

      this.doResetEstimation();
      if (!ignoreAutoRefresh) this.doRefreshEstimation(sec);
    });
  }
  private doResetEstimation() {
    const timeElapsed =
      Date.now() - (this.searchParams?.lastUserChangeTime ?? 0);
    if (
      this.searchParams?.lastUserChangeTime &&
      timeElapsed >= CrowdSwapService.END_REFRESH_TIME &&
      this.swapState !== SwapState.Expired &&
      this.searchParams?.lastUserChangeTime !== 0
    ) {
      console.log('raise expire');
      this.unSubscribeAutoSearch();
      this.changeSwapState(SwapState.Expired);
      this.searchParams.lastUserChangeTime = 0;
    }
  }
  private async doRefreshEstimation(sec: any) {
    if (CrowdSwapService.INTERVAL_REFRESH_TIME * 10 === sec) {
      this.unSubscribeAutoSearch();
      await this.refreshSearch(false);
    }
  }

  private unSubscribeAutoSearch() {
    if (this.autoSearchSubscriber) {
      this.autoSearchSubscriber.unsubscribe();
      this.autoSearchSubscriber = null;
      this.countDownTimer = 100;
    }
    this.ref.detectChanges();
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  async switchRefreshSearch(event: any) {
    event.stopPropagation();
    if (this.hasAutoSearchSubscriber) {
      this.unSubscribeAutoSearch();
      this.startAutoRefresh(true);
      this.hasAutoSearchSubscriber = false;
    } else {
      this.startAutoRefresh();
      this.hasAutoSearchSubscriber = true;
    }
  }

  async refreshSearch(userRefresh: boolean = true) {
    if (!this.searchParams) {
      return;
    }

    const setting = this.getSetting();
    const cloneSearchParams = Builder<SearchParamModel>()
      .fromToken(this.searchParams.fromToken)
      .toToken(this.searchParams.toToken)
      .swapValue(this.searchParams.swapValue)
      .slippage(setting.slippage)
      .deadline(setting.deadline)
      .build();

    cloneSearchParams.lastUserChangeTime = this.searchParams.lastUserChangeTime;
    if (userRefresh || !cloneSearchParams.lastUserChangeTime) {
      cloneSearchParams.lastUserChangeTime = Date.now();
      this.searchParams.lastUserChangeTime = Date.now();
    }

    await this.onPairSelected(cloneSearchParams);
  }

  private getTxState(state: SwapState) {
    switch (state) {
      case SwapState.SwapConfirmed:
        return 'SwapConfirmed';
      case SwapState.Successful:
        return 'Successful';
      case SwapState.SwapFailed:
        return 'SwapFailed';
      default:
        return 'Init';
    }
  }

  private setConsoleLog(state: SwapState, msg: string = '') {
    console.log(
      'Swap transaction ChainId=' +
        this.searchParams?.fromToken.chainId +
        ' SrcTokenSymbol=' +
        this.searchParams?.fromToken.symbol +
        ' DstTokenSymbol=' +
        this.searchParams?.toToken.symbol +
        ' Slippage=' +
        this.searchParams?.slippage +
        ' AmountIn=' +
        this.searchParams?.swapValue +
        ' SwapState=' +
        this.getTxState(state) +
        msg
    );
  }

  isAddress() {
    this.isAddressValid = this.web3Service.isAddress(this.receiverAddress);
  }

  anotherReceiverChecked() {
    if (this.isReceiverWalletSelected) {
      setTimeout(() => {
        this.receiverWalletAddress?.nativeElement.focus();
      }, 1);
    }
  }
  private getCurrentRightState(): SwapState {
    return this.web3Service.isConnected()
      ? SwapState.Init
      : SwapState.WalletNotConnected;
  }
  private getSetting(): SettingModel {
    let settingJson = localStorage.getItem('user-setting');
    return settingJson ? JSON.parse(settingJson) : this.getDefaultSetting();
  }
  private async useSwapTxOrDoTransaction(
    setting: SettingModel,
    userAddress: string,
    checkValidation: boolean
  ) {
    if (!this.estimation.swapTx || this.isReceiverWalletSelected) {
      return !this.estimation.middleToken
        ? await this.crowdSwapService.getSwapTransaction(
            this.estimation.dex.name,
            this.estimation.fromToken,
            this.estimation.toToken,
            this.estimation.amountIn.toString(),
            userAddress,
            this.estimation.delegatedDex,
            setting.slippage,
            setting.deadline,
            this.isAddressValid && this.isReceiverWalletSelected
              ? this.receiverAddress
              : userAddress,
            checkValidation
          )
        : await this.crowdSwapService.getCrossDexSwapTransaction(
            this.estimation.firstDex!,
            this.estimation.secondDex!,
            this.estimation.fromToken,
            this.estimation.middleToken,
            this.estimation.toToken,
            this.estimation.amountIn.toString(),
            this.estimation.amountInSecondSwap!.toString(),
            userAddress,
            this.estimation.delegatedDex,
            setting.slippage,
            setting.deadline,
            this.isAddressValid && this.isReceiverWalletSelected
              ? this.receiverAddress
              : userAddress
          );
    } else {
      return this.estimation.swapTx;
    }
  }
  getDefaultSetting(): SettingModel {
    return {
      unitTokenDollar: true,
      expertMode: false,
      slippage: CrowdSwapService.SLIPPAGE,
      crossChainSlippage: commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE,
      deadline: CrowdSwapService.DEADLINE
    };
  }
}
