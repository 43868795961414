<div class="w-full detail_card_page h-100">
  <app-etf-item
    [opportunity]="opportunity"
    [selectedTab]="selectedOpportunityTab"
    [loading]="loading"
    [itemsPage]="true"
    [selectedActionTab]="selectedActionTab"
    class="w-full d-block h-100"
  >
  </app-etf-item>
</div>
