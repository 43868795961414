import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';

@Component({
  selector: 'app-expert-mode-dialog',
  templateUrl: './withdraw-dialog.component.html',
  styleUrls: ['./withdraw-dialog.component.scss']
})
export class WithdrawDialogComponent extends DialogsComponent {
  @Output()
  confirmed = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  confirm() {
    if (this.data?.releasableCrowdBalance == 0) {
      return;
    }
    this.confirmed.emit(true);
  }

  closeDialog() {
    this.confirmed.emit(false);
  }
}
@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [WithdrawDialogComponent]
})
export class ExpertModeDialogComponentModule {}
