import { Component, Input } from '@angular/core';
import {
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../../../services';
import { BaseComponent } from '../../../base.component';

@Component({
  selector: 'app-vip-card',
  templateUrl: './vip-card.component.html',
  styleUrls: ['./vip-card.component.scss']
})
export class VipCardComponent extends BaseComponent {
  private _vip: any;
  private _userStatus: any;

  constructor(
    public web3Service: Web3Service,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  @Input()
  set vip(ranking: any) {
    this._vip = ranking;
  }

  @Input()
  set userStatus(userStatus: any) {
    this._userStatus = userStatus;
  }

  get vip() {
    return this._vip;
  }

  get userStatus() {
    return this._userStatus;
  }
}
