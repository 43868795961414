import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConnectWalletDialogComponent } from '../views/modal/dialogs/connect-wallet-dialog/connect-wallet-dialog.component';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  IExecuteReqMdl,
  IGetAddressReqMdl,
  IGetSwapReqMdl,
  IGetTransferReqMdl,
  IGetTransferRespMdl,
  ISmartWalletConfirmationDialogData
} from '../model';
import { SmartWalletConfirmationDialogComponent } from '../views/modal/dialogs/smart-wallet-confirmation/smart-wallet-confirmation.component';
import { throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConnectWalletService {
  // connectWalletDialog!: import('ng-zorro-antd/modal').NzModalRef<
  //   ConnectWalletDialogComponent,
  //   any
  // >;
  public userData: IGetAddressReqMdl = { email: '', passwordHash: '' };

  constructor(
    private nzModalService: NzModalService,
    private http: HttpClient
  ) {}

  openConnectModal = (isDarkMode: boolean) => {
    return this.nzModalService.create<ConnectWalletDialogComponent, any>({
      nzTitle: undefined,
      nzFooter: undefined,
      nzContent: ConnectWalletDialogComponent,
      nzWrapClassName: `${
        isDarkMode ? 'dark' : 'light'
      } wallet-connect-dialog bg-blur-4px`,
      nzClassName: 'bg-gray-50 dark:bg-darkBlack p-0 border-radius-6px',
      nzClosable: false,
      nzCloseOnNavigation: false
    });
  };

  openConfirmModal = (
    isDarkMode: boolean,
    data: ISmartWalletConfirmationDialogData
  ) => {
    return this.nzModalService.create<
      SmartWalletConfirmationDialogComponent,
      any
    >({
      nzTitle: undefined,
      nzFooter: undefined,
      nzContent: SmartWalletConfirmationDialogComponent,
      nzWrapClassName: `${
        isDarkMode ? 'dark' : 'light'
      } smart-wallet-confirmation bg-blur-4px`,
      nzClassName: 'bg-gray-50 dark:bg-darkBlack p-0 border-radius-6px',
      nzClosable: false,
      nzCloseOnNavigation: false,
      nzData: data
    });
  };

  public async getAddress(data: IGetAddressReqMdl): Promise<any> {
    try {
      const url = `${environment.PORTFOLIO_BASEURL}/crowdwallet/get-address`;
      this.userData = data;

      return await this.http.post(url, data).toPromise();
    } catch (err: any) {
      console.error(`Cannot fetch user address`);
    }
  }

  public async getSwap(data: IGetSwapReqMdl): Promise<any> {
    try {
      const url = `${environment.PORTFOLIO_BASEURL}/crowdwallet/get-swap`;
      data.email = this.userData.email;
      data.passwordHash = this.userData.passwordHash;

      let history = await this.http.post(url, data).toPromise();
      return history;
    } catch (err: any) {
      console.error(`Cannot swap the transaction`);
    }
  }

  public async getTransfer(
    data: IGetTransferReqMdl
  ): Promise<IGetTransferRespMdl | undefined> {
    try {
      const url = `${environment.PORTFOLIO_BASEURL}/crowdwallet/get-transfer`;
      data.email = this.userData.email;
      data.passwordHash = this.userData.passwordHash;

      let transfer = await this.http
        .post<IGetTransferRespMdl>(url, data)
        .toPromise();

      if (!transfer?.request) {
        throwError(transfer);
      }

      return transfer;
    } catch (err: any) {
      console.error(`Cannot fetch user transfer transaction`);
    }
  }

  public async execute(data: IExecuteReqMdl): Promise<string | undefined> {
    try {
      const url = `${environment.PORTFOLIO_BASEURL}/crowdwallet/execute`;
      data.email = this.userData.email;
      data.passwordHash = this.userData.passwordHash;

      let execute = await this.http
        .post<string | undefined>(url, data)
        .toPromise();

      return execute;
    } catch (err: any) {
      console.error(`The transaction cannot be executed.`);
      if (err.error.text) {
        return err.error.text;
      }
    }
  }

  public async isUserExistOrSendCode(emailAddress: string): Promise<boolean> {
    try {
      const url = `${environment.PORTFOLIO_BASEURL}/crowdwallet/send-verification-code`;
      const data = { email: emailAddress };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result !== 'OK';
    } catch (err: any) {
      if (
        err.error.statusCode === 302 &&
        err.error.message === 'User Already Exists'
      ) {
        return true;
      }
      console.error(`Cannot send verification code to the server`);
      throw err;
    }
  }

  public async isVerificationCodeCorrect(
    emailAddress: string,
    verificationCode: string
  ) {
    try {
      const url = `${environment.PORTFOLIO_BASEURL}/crowdwallet/is-verification-code-correct`;
      const data = { email: emailAddress, verificationCode: verificationCode };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: boolean;
      };

      return userObj.result;
    } catch (err: any) {
      console.error(`Cannot send verification code to the server`);
      return false;
    }
  }

  public async registerUser(
    emailAddress: string,
    verificationCode: string,
    passwordHash: string
  ) {
    try {
      const url = `${environment.PORTFOLIO_BASEURL}/crowdwallet/register`;
      const data = {
        email: emailAddress,
        verificationCode: verificationCode,
        passwordHash: passwordHash
      };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result === 'OK';
    } catch (err: any) {
      console.log(`Cannot send verification code to the server`);
      return false;
    }
  }
}
