<div class="flex justify-center">
  <div
    *ngIf="isUserWalletConnected && enableVip"
    class="md:flex items-center my-4 container justify-content-between px-3 px-lt-24px"
  >
    <div
      class="flex text-sm justify-content-center cursor-pointer md:justify-content-between items-center"
      (click)="navigateToVip()"
    >
      <div class="mr-2">
        <div class="py-2">
          <img
            alt="userAvatar"
            class="mx-2"
            src="{{
              userStatus && vipService.rankings[userStatus.stage]?.avatar
            }}"
          />
        </div>
      </div>
      <div>
        <div class="relative">
          <div class="text-xl font-openSans-semiBold dark:text-textWhite">
            <span>
              {{
                userStatus && vipService.rankings[userStatus.stage]?.captionName
              }}
            </span>
          </div>
          <div class="absolute -top-5 right-0 rounded-sm stage-label">
            {{
              userStatus && userStatus?.stage === 0
                ? "Join VIP"
                : "Stage " + userStatus?.stage
            }}
          </div>
        </div>
        <div
          class="flex font-openSans-regular text-xs text-gray-400 dark:text-darkGrayText"
        >
          <span>
            {{
              web3Service.getWalletAddress()?.substring(0, 11) +
                "..." +
                web3Service.getWalletAddress()?.substring(33, 42)
            }}
          </span>
        </div>
      </div>
    </div>
    <br class="d-md-none" />
    <div class="flex space-x-2">
      <div class="relative bg-cyan-200 rounded ml-2 p-2 px-3 w-1/2 md:w-48">
        <div>
          <div class="text-xs text-gray-700">
            <span>Total investments</span>
          </div>
        </div>
        <div class="flex font-openSans-regular text-xl">
          <div class="flex">
            <div class="mt-1 mr-1" (click)="showInvestment = !showInvestment">
              <ng-container *ngIf="showInvestment; else eyeOff">
                <ng-container *ngTemplateOutlet="eye"></ng-container>
              </ng-container>
            </div>
            <div
              class="font-openSans-semiBold pl-2"
              [ngClass]="{ 'animate-pulse': investmentLoading }"
            >
              <span
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': investmentLoading
                }"
                >{{ showInvestment ? "~$" + totalInvestment : "******" }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="relative bg-green-200 rounded ml-2 p-2 px-3 w-1/2 md:w-48">
        <div>
          <div class="flex text-xs text-gray-700">
            <div><span>Total asset balance</span></div>
          </div>
        </div>
        <div class="flex font-openSans-regular text-xl">
          <div class="flex">
            <div class="mt-1 mr-1" (click)="showBalance = !showBalance">
              <ng-container *ngIf="showBalance; else eyeOff">
                <ng-container *ngTemplateOutlet="eye"></ng-container>
              </ng-container>
            </div>
            <div
              class="font-openSans-semiBold pl-2"
              [ngClass]="{ 'animate-pulse': balanceLoading }"
            >
              <span
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': balanceLoading
                }"
                >{{ showBalance ? "~$" + totalBalance : "*******" }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="isUserWalletConnected && !enableVip"
    class="md:flex items-center my-4 container justify-content-between"
  >
    <div
      class="flex text-sm justify-content-center md:justify-content-between items-center"
    >
      <div class="rounded-full border-2 mr-2">
        <div class="py-2">
          <img
            alt="userAvatar"
            class="w-10 h-10 mx-2"
            src="assets/media/icons/wallet.png"
          />
        </div>
      </div>
      <div>
        <div class="relative">
          <div class="text-xl font-openSans-semiBold dark:text-textWhite">
            <span>My wallet</span>
          </div>
        </div>
        <div
          class="flex font-openSans-regular text-xs text-gray-400 dark:text-darkGrayText"
        >
          <span>
            {{
              web3Service.getWalletAddress()?.substring(0, 11) +
                "..." +
                web3Service.getWalletAddress()?.substring(33, 42)
            }}
          </span>
        </div>
      </div>
    </div>
    <br class="d-md-none" />
    <div class="flex space-x-2">
      <div class="relative bg-cyan-200 rounded ml-2 p-2 px-3 w-1/2 md:w-48">
        <div>
          <div class="text-xs text-gray-700">
            <span>Total&nbsp;investments</span>
          </div>
        </div>
        <div class="flex font-openSans-regular text-xl">
          <div class="flex">
            <div class="mt-1 mr-1" (click)="showInvestment = !showInvestment">
              <img
                alt="showInvestment"
                [hidden]="!showInvestment"
                class="w-5"
                src="assets/media/icons/eyeOn.png"
              />
              <img
                alt="hideInvestment"
                [hidden]="showInvestment"
                class="w-5"
                src="assets/media/icons/eyeOff.png"
              />
            </div>
            <div
              class="font-openSans-semiBold pl-2"
              [ngClass]="{ 'animate-pulse': investmentLoading }"
            >
              <span
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': investmentLoading
                }"
                >{{ showInvestment ? "~$" + totalInvestment : "******" }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="relative bg-green-200 rounded ml-2 p-2 px-3 w-1/2 md:w-48">
        <div>
          <div class="flex text-xs text-gray-700">
            <div><span>Total&nbsp;asset&nbsp;balance</span></div>
          </div>
        </div>
        <div class="flex font-openSans-regular text-xl">
          <div class="flex">
            <div class="mt-1 mr-1" (click)="showBalance = !showBalance">
              <img
                alt="showBalance"
                [hidden]="!showBalance"
                class="w-5"
                src="assets/media/icons/eyeOn.png"
              />
              <img
                alt="hideBalance"
                [hidden]="showBalance"
                class="w-5"
                src="assets/media/icons/eyeOff.png"
              />
            </div>
            <div
              class="font-openSans-semiBold pl-2"
              [ngClass]="{ 'animate-pulse': balanceLoading }"
            >
              <span
                [ngClass]="{
                  'bg-gray-300 text-gray-300 rounded-sm': balanceLoading
                }"
                >{{ showBalance ? "~$" + totalBalance : "*******" }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="!isUserWalletConnected"
    class="md:flex items-center justify-center md:w-4/5 md:mx-6 md:px-6"
  >
    <div
      class="flex text-sm justify-content-center md:justify-content-between items-center"
    >
      <div>
        <div>
          <div
            class="text-xl font-bold dark:text-textWhite flex justify-center underline text-blue-700 cursor-pointer"
            (click)="connectWallet()"
          >
            <span> Connect wallet </span>
          </div>
        </div>
        <div
          class="flex font-openSans-regular text-xs text-gray-400 dark:text-darkGrayText"
        >
          <span> Connect your wallet to see your investments </span>
        </div>
      </div>
    </div>
    <br class="d-md-none" />
  </div>
</div>

<ng-template #eye>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="2"
      stroke="#323232"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22 12C19.333 16.667 16 19 12 19C8 19 4.667 16.667 2 12C4.667 7.333 8 5 12 5C16 5 19.333 7.333 22 12"
      stroke="#323232"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</ng-template>

<ng-template #eyeOff>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 3L21 21"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.584 10.5869C9.80248 11.3678 9.80203 12.6344 10.583 13.4159C11.3639 14.1974 12.6305 14.1978 13.412 13.4169"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.08797 4.40362C8.55698 4.55552 8.24967 5.10911 8.40157 5.64009C8.55346 6.17108 9.10705 6.47839 9.63803 6.32649L9.08797 4.40362ZM12 5.00006L11.9965 6.00006H12V5.00006ZM22 12.0001L22.8682 12.4963C23.0439 12.1889 23.0439 11.8114 22.8682 11.5039L22 12.0001ZM18.7626 14.8093C18.3878 15.2149 18.4127 15.8476 18.8182 16.2224C19.2238 16.5973 19.8565 16.5724 20.2314 16.1668L18.7626 14.8093ZM17.9162 18.1781C18.374 17.8693 18.4949 17.2478 18.1861 16.7899C17.8773 16.332 17.2557 16.2112 16.7979 16.52L17.9162 18.1781ZM2 12.0001L1.13182 11.5038C0.956079 11.8113 0.956057 12.1887 1.13177 12.4962L2 12.0001ZM7.19335 7.48664C7.65041 7.17661 7.7696 6.55477 7.45958 6.09771C7.14956 5.64065 6.52771 5.52146 6.07065 5.83148L7.19335 7.48664ZM9.63803 6.32649C10.4049 6.10713 11.1989 5.99723 11.9965 6.00005L12.0035 4.00007C11.0175 3.99657 10.036 4.13244 9.08797 4.40362L9.63803 6.32649ZM12 6.00006C15.52 6.00006 18.5732 8.01897 21.1318 12.4962L22.8682 11.5039C20.0928 6.64715 16.48 4.00006 12 4.00006V6.00006ZM21.1318 11.5038C20.3842 12.8117 19.5933 13.9106 18.7626 14.8093L20.2314 16.1668C21.1827 15.1375 22.0598 13.9105 22.8682 12.4963L21.1318 11.5038ZM16.7979 16.52C15.3292 17.5105 13.7394 18.0001 12 18.0001V20.0001C14.1446 20.0001 16.1228 19.3876 17.9162 18.1781L16.7979 16.52ZM12 18.0001C8.48001 18.0001 5.4268 15.9812 2.86823 11.5039L1.13177 12.4962C3.9072 17.353 7.51999 20.0001 12 20.0001V18.0001ZM2.86818 12.4963C4.18518 10.1923 5.63485 8.54377 7.19335 7.48664L6.07065 5.83148C4.19115 7.10635 2.55282 9.01784 1.13182 11.5038L2.86818 12.4963Z"
      fill="currentColor"
    />
  </svg>
</ng-template>
