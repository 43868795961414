import { Component, NgModule, OnInit, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConnectWalletService, Web3Service } from '../../../../services';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import {
  ISmartWalletConfirmationDialogData,
  ISmartWalletConfirmationRespMdl,
  SmartWalletConfirmation
} from 'src/app/model';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { SharedModule } from 'src/app/share/shared.module';
import { Conversion, Networks, PriceService } from '@crowdswap/constant';
import { NumberType, formatNumber } from '@uniswap/conedison/format';

@Component({
  selector: 'app-smart-wallet-confirmation',
  templateUrl: './smart-wallet-confirmation.component.html',
  styleUrls: ['./smart-wallet-confirmation.component.scss']
})
export class SmartWalletConfirmationDialogComponent implements OnInit {
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: ISmartWalletConfirmationDialogData =
    inject(NZ_MODAL_DATA);
  public SmartWalletConfirmation = SmartWalletConfirmation;
  public Networks = Networks;

  constructor(
    private connectWalletService: ConnectWalletService,
    private formBuilder: FormBuilder,
    public web3Service: Web3Service,
    public priceService: PriceService
  ) {}

  async ngOnInit() {
    this.nzModalData.fees.map(async (fee) => {
      if (!fee.token || !fee.amount) {
        return;
      }

      fee.amount = Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(fee.amount, fee.token.decimals),
        6
      );

      if (
        !fee.token.price ||
        (fee.token.price && !parseFloat(fee.token.price))
      ) {
        fee.token.price = await this.priceService.getPrice(fee.token);
      }

      fee.amountInUSDT = parseFloat(fee.amount) * parseFloat(fee.token.price);

      fee.amountInUSDTToDisplay = formatNumber(
        fee.amountInUSDT,
        NumberType.FiatTokenPrice
      );

      return fee;
    });
  }

  destroyModal(confirm?: boolean): void {
    this.#modal.destroy(confirm);
  }
}

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot()
  ],
  declarations: [SmartWalletConfirmationDialogComponent]
})
export class SmartWalletConfirmationDialogModule {}
