import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appFocusAndSelectValue]'
})
export class FocusAndSelectValueDirective {
  @Input() el!: HTMLInputElement;
  focus: any;

  constructor() {}

  @HostListener('blur') blur() {
    if (this.focus === this.el) {
      this.focus = undefined;
    }
  }

  @HostListener('click') click() {
    if (this.focus === this.el) {
      return;
    }
    this.focus = this.el;
    setTimeout(() => {
      this.el.select();
    }, 10);
  }
}
