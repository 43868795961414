import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  NgModule,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { Constants } from '../../../../constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { FormsModule } from '@angular/forms';
import {
  CrowdToken,
  Networks,
  TokensHolder,
  TokenType
} from '@crowdswap/constant';
import { isAddress } from 'ethers/lib/utils';
import { Builder } from 'builder-pattern';
import { ModalService } from '../../modal.service';
import { ImportTokenDialogComponent } from '../import-token-dialog/import-token-dialog.component';
import { Subscription } from 'rxjs';
import { ITokensTempDisplay } from 'src/app/model/dialog-data.model';
import {
  DeviceType,
  ERC20Service,
  NDDClientInfoServiceImpl,
  Web3Service
} from '../../../../services';
import { MainNetworksById } from '@crowdswap/constant/dist/utils/networks/networks';
import { SharedModule } from '../../../../share/shared.module';
import { SwiperComponent } from 'swiper/angular';
import Swiper, { SwiperOptions } from 'swiper';
import { ExchangeType } from 'src/app/views/pages/cross-and-same-chain-swap/model/cross-chain-state.enum';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'cross-chain-select-option-dialog',
  templateUrl: './cross-chain-select-option-dialog.component.html',
  styleUrls: ['./cross-chain-select-option-dialog.component.scss']
})
export class CrossChainSelectOptionDialogComponent
  extends DialogsComponent
  implements OnInit, AfterViewInit
{
  public selectedToken: CrowdToken | undefined;
  public selectedNetwork: number = -1;
  public defaultTokensOfTheNetwork: CrowdToken[] = [];
  public isNewToken: boolean = false;
  public newTokensLoading: boolean = false;
  public importTokens: CrowdToken[] | undefined;
  public filterValue = '';
  public toggle: boolean = false;
  public tokensTempDisplay: ITokensTempDisplay | undefined = {
    zeroList: [],
    hasBalanceList: []
  };
  public allNetworks: number[] = [];
  public showZero = false;
  public swiperConfig: {
    networkConfig: SwiperOptions;
    tokenConfig: SwiperOptions;
    defaultTokensOfTheNetworkSlide: {
      index: number;
      isEnd: boolean;
    };
    allNetworksSlide: {
      index: number;
      isEnd: boolean;
    };
  } = {
    networkConfig: {
      slidesPerView: 'auto',
      spaceBetween: 3,
      direction: 'horizontal',
      navigation: {
        nextEl: '.network-swiper-next',
        prevEl: '.network-swiper-prev'
      }
    },
    tokenConfig: {
      slidesPerView: 'auto',
      spaceBetween: 3,
      direction: 'horizontal',
      navigation: {
        nextEl: '.token-swiper-next',
        prevEl: '.token-swiper-prev'
      }
    },
    defaultTokensOfTheNetworkSlide: {
      index: 0,
      isEnd: false
    },
    allNetworksSlide: {
      index: 0,
      isEnd: false
    }
  };
  public TokenType = TokenType;
  public Networks = Networks;
  public JSON = JSON;
  public ExchangeType = ExchangeType;

  @Output()
  public confirmed = new EventEmitter<object>();
  public confirmedImportToken = new EventEmitter<object>();

  private originalTokens: CrowdToken[] = [];

  @ViewChild('baseNetworkTokensSwiper')
  baseNetworkTokensSwiper!: SwiperComponent;

  @ViewChild('txtSearchTokenInput')
  txtSearchTokenInput!: ElementRef;

  constructor(
    private $gtmService: GoogleTagManagerService,
    public web3Service: Web3Service,
    private importTokenDialogComponent: ModalService<ImportTokenDialogComponent>,
    private clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super();
  }

  public async ngOnInit() {
    if (!this.data || !this.data.filteredTokenList) {
      return;
    }

    this.allNetworks = [
      ...new Set(this.data.filteredTokenList.map((t) => t.chainId))
    ];
    this.originalTokens = this.data.filteredTokenList;

    this.addBalanceToTokens(this.originalTokens).then((tokensWithBalance) => {
      this.normalizeTokenList(
        // Filter and sort tokens based on the selected network and filterValue
        this.filterTokensByChainIdAndFilterValue(
          tokensWithBalance,
          this.selectedNetwork,
          this.filterValue
        )
      );
    });
    this.selectNetwork(
      this.data?.dontShowAllNetworks && this.allNetworks.length === 1
        ? this.allNetworks[0]
        : this.data.chainId
    );

    this.toggle = this.data?.toggle || false;
  }

  ngAfterViewInit(): void {
    if (
      [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
        this.clientInfoServiceImpl.getDeviceType()
      ) == -1
    ) {
      setTimeout(() => {
        this.txtSearchTokenInput.nativeElement.focus();
      }, 500);
    }
  }

  /**
   * Select items and set to button title
   */
  public async selectToken(item: any) {
    this.toggle = !this.data?.toggle || false;
    this.selectedToken = item;
    this.confirmed.emit(item);
  }

  /**
   * Filter data source value
   */
  public async filterDataSourceValue(filterValue) {
    this.filterValue = filterValue.trim().toLowerCase();
    this.importTokens = undefined;
    this.isNewToken = false;

    let tokensTemp: CrowdToken[] = [];

    if (isAddress(this.filterValue)) {
      this.normalizeTokenList(tokensTemp);

      const tokenByAddress = this.getTokenByAddress(this.filterValue);

      if (!tokenByAddress) {
        if (this.isTokenSelectedOtherSide(this.filterValue)) {
          this.normalizeTokenList(undefined);
          return;
        }
        this.isNewToken = true;
        this.newTokensLoading = true;
        this.importTokens = await this.fetchNewTokenDetailsByAddress(
          this.filterValue
        )
          .catch(async () => {
            await this.$gtmService.pushTag({
              event: 'token_not_found',
              category: 'tokenSelection',
              token: this.filterValue,
              isConnected: this.web3Service.isConnected()
            });
            return undefined;
          })
          .finally(() => {
            this.newTokensLoading = false;
          });

        if (!this.importTokens) {
          this.normalizeTokenList(undefined);
        }
      } else {
        tokensTemp = [tokenByAddress];
        this.normalizeTokenList(tokensTemp);
      }
    } else {
      tokensTemp = this.filterTokensByChainIdAndFilterValue(
        this.originalTokens,
        this.selectedNetwork,
        this.filterValue
      );

      if (tokensTemp.length === 0) {
        tokensTemp = [];
        await this.$gtmService.pushTag({
          event: 'token_not_found',
          category: 'tokenSelection',
          token: this.filterValue,
          isConnected: this.web3Service.isConnected()
        });
      }
      this.normalizeTokenList(tokensTemp);
    }
  }

  public async fetchNewTokenDetailsByAddress(address: string) {
    const tokens: any = [];
    if (this.selectedNetwork === -1) {
      for (const item of this.allNetworks) {
        try {
          const erc20 = new ERC20Service(
            this.web3Service.getNetworkProvider(item)
          );
          const token = await erc20.getToken(address);
          if (token) {
            tokens.push(token);
          }
        } catch (e) {}
      }
    } else {
      const erc20 = new ERC20Service(
        this.web3Service.getNetworkProvider(this.selectedNetwork)
      );
      tokens.push(await erc20.getToken(address));
    }
    return tokens;
  }

  public importNewToken(token: any) {
    const chainId = token.chainId;
    const address = token.address.toLowerCase();
    const tokenObj = localStorage.getItem('tokens')
      ? JSON.parse(localStorage.getItem('tokens')!)
      : {};
    tokenObj[chainId] = tokenObj[chainId] || { [address]: token };
    tokenObj[chainId][address] = tokenObj[chainId][address] || token;
    localStorage.setItem('tokens', JSON.stringify(tokenObj));
    this.selectToken(token);
    const newToken = Builder<CrowdToken>()
      .chainId(token.chainId)
      .address(token.address)
      .decimals(token.decimals)
      .symbol(token.symbol)
      .name(token.name + ' • Added by user')
      .build();

    this.confirmedImportToken.emit(newToken);
    this.toggle = false;
  }

  public onEnter() {
    if (
      !this.tokensTempDisplay ||
      (this.tokensTempDisplay.hasBalanceList[0] &&
        this.tokensTempDisplay.zeroList[0])
    ) {
      return;
    }
    this.selectToken(
      this.tokensTempDisplay.hasBalanceList[0] ||
        this.tokensTempDisplay.zeroList[0]
    );
  }

  public toggleDropdown() {
    this.confirmed.emit(undefined);
    this.toggle = !this.toggle;
  }

  public async showImportTokenConfirmDialog(newToken: CrowdToken) {
    const data = {
      allTokens: [newToken],
      isDarkMode: this.data?.isDarkMode
    };
    let importTokenDialogRef = await this.importTokenDialogComponent.open(
      ImportTokenDialogComponent,
      data
    );
    const sub: Subscription = (<ImportTokenDialogComponent>(
      (<any>importTokenDialogRef!.instance)
    )).confirmed.subscribe(async (isImportConfirmed: boolean) => {
      if (isImportConfirmed) {
        this.importNewToken(newToken);
      } else if (!isImportConfirmed) {
        await this.importTokenDialogComponent.close();
      }
    });
    importTokenDialogRef!.onDestroy(() => {
      sub.unsubscribe();
    });
  }

  public async addBalanceToTokens(tokens: any) {
    const promises: any[] = [];
    let result: any[] = [];

    for (const chainId in MainNetworksById) {
      if (environment.ACTIVE_NETWORK.includes(chainId)) {
        let tempTokens = tokens.filter(
          (token: CrowdToken) => token.chainId.toString() === chainId
        );
        promises.push(this.web3Service.addBalanceToTokens(tempTokens));
      }
    }

    // result.push(...tempTokens);
    let promiseResult = await Promise.allSettled(promises);
    promiseResult.forEach((item, index, array) => {
      if (item && item.status === 'fulfilled' && item.value.length > 0) {
        result.push(...item.value);
      }
    });
    return result;

    // if (this.data?.withBalance) {
    //   result = result.filter((token: CrowdToken) => {
    //     return token.balance && token.balance != '' && +token.balance > 0;
    //   });
    //   this.baseNetworkTokens = this.baseNetworkTokens.filter((token) =>
    //     result.some(({ symbol }) => token === symbol)
    //   );
    // }
    // return result;
  }

  public toFixed(value: string, numberOfDecimals: number): string {
    const valueInFloat: number = parseFloat(value);
    return isNaN(valueInFloat) ? '' : valueInFloat.toFixed(numberOfDecimals);
  }

  public async clearSearch() {
    this.filterValue = '';
    await this.filterDataSourceValue('');
  }

  /**
   * Selects a network based on the provided chain ID and performs necessary operations.
   * If the filter value is an address, it filters the data source accordingly.
   * Otherwise, it filters tokens by chain ID and filter value, updates the default tokens list,
   * normalizes and sorts tokens, and updates the Swiper if present.
   * @param chainId The chain ID of the selected network.
   */
  public async selectNetwork(chainId: number) {
    // Set the selected network
    this.selectedNetwork = chainId;

    // Filter data source if filter value is an address
    if (isAddress(this.filterValue)) {
      await this.filterDataSourceValue(this.filterValue);
      return;
    }

    // Filter tokens by chain ID and filter value
    const tempTokens = this.filterTokensByChainIdAndFilterValue(
      this.originalTokens,
      chainId,
      this.filterValue
    );
    this.defaultTokensOfTheNetwork = [];

    // Filter trending tokens and add them to the default tokens list
    let trendingTokens: any[] = [];
    if (this.data?.destValue) {
      trendingTokens = tempTokens.filter((filteredToken) =>
        chainId === -1
          ? filteredToken.type === TokenType.Trending
          : filteredToken.chainId === chainId &&
            filteredToken.type === TokenType.Trending &&
            filteredToken.symbol !== this.data?.destValue.symbol &&
            (this.data?.mode === ExchangeType.Market ||
              (this.data?.mode === ExchangeType.Limit &&
                ['CROWD', 'TMNG'].indexOf(filteredToken.symbol) === -1))
      );
      for (const item of trendingTokens) {
        this.defaultTokensOfTheNetwork.push(
          TokensHolder.convertSymbolToToken(item.chainId, item.symbol)
        );
      }
    }
    // Add default tokens to the network list if chain ID is positive
    if (chainId > 0) {
      this.addDefaultTokensToNetworkList(chainId, trendingTokens);
    }

    // Normalize and sort tokens
    this.normalizeAndSortTokens(tempTokens);

    // Update Swiper if present
    this.updateSwiperIfPresent();
  }

  /**
   * Adds default tokens to the network list based on chain ID and skips tokens based on specified conditions.
   * @param chainId The chain ID of the tokens.
   * @param trendingTokens The array of trending tokens used for filtering.
   */
  private addDefaultTokensToNetworkList(
    chainId: number,
    trendingTokens: any[]
  ) {
    Constants.DEFAULT_TOKENS[chainId].forEach((item) => {
      if (
        this.shouldSkipTokenAddition(item, chainId) ||
        this.originalTokens.findIndex(
          (ot) => ot.chainId === chainId && ot.symbol === item
        ) === -1
      ) {
        return;
      }

      if (!this.isTokenInList(item, chainId, trendingTokens)) {
        this.defaultTokensOfTheNetwork.push(
          TokensHolder.convertSymbolToToken(chainId, item)
        );
      }
    });
  }

  /**
   * Determines whether a token should be skipped based on specified conditions.
   * @param item The token symbol to check.
   * @param chainId The chain ID of the token.
   * @returns True if the token should be skipped, false otherwise.
   */
  private shouldSkipTokenAddition(item: string, chainId: number): boolean {
    // If data is not available, no token should be skipped
    if (!this.data || !this.data?.destValue) {
      return false;
    }

    // Extract destination symbol and chainId from data
    const destSymbol = this.data.destValue.symbol;
    const destChainId = this.data.destValue.chainId;
    const exchangeType = this.data.mode;

    // Check if the title is 'to'
    const isToTitle = this.data.title === 'to';

    if (destChainId === chainId && destSymbol === item) {
      return true;
    }

    if (isToTitle) {
      // If the destination symbol is 'CROWD' or 'TMNG' and the token does not match, skip it
      if (
        ['CROWD', 'TMNG'].indexOf(destSymbol) > -1 &&
        item !== destSymbol &&
        exchangeType === ExchangeType.Limit
      ) {
        return true;
      }
    }

    if (!isToTitle) {
      if (
        exchangeType === ExchangeType.Limit &&
        ['CROWD', 'TMNG'].indexOf(item) > -1
      ) {
        return true;
      }
    }

    // If no conditions are met, do not skip the token
    return false;
  }

  /**
   * Determines whether a token is in the provided list based on symbol and chain ID.
   * @param symbol The token symbol to check.
   * @param chainId The chain ID of the token.
   * @param tokens The array of tokens to search in.
   * @returns True if the token is in the list, false otherwise.
   */
  private isTokenInList(
    symbol: string,
    chainId: number,
    tokens: any[]
  ): boolean {
    return tokens.some(
      (token) => token.symbol === symbol && token.chainId === chainId
    );
  }

  /**
   * Normalizes and sorts the provided tokens list.
   * @param tokens The array of tokens to normalize and sort.
   */
  private normalizeAndSortTokens(tokens: any[]) {
    this.normalizeTokenList(tokens);
    this.sortTokensByBalanceDesc();
  }

  /**
   * Updates the Swiper configuration and initializes it if present.
   */
  private updateSwiperIfPresent() {
    if (this.baseNetworkTokensSwiper) {
      this.updateSwiperConfig();
    }
  }

  /**
   * Updates the Swiper configuration with default tokens of the network and initializes the Swiper.
   */
  private updateSwiperConfig() {
    this.swiperConfig.defaultTokensOfTheNetworkSlide = {
      index: 0,
      isEnd: false
    };
    this.baseNetworkTokensSwiper.swiperRef.slideTo(0);
    this.baseNetworkTokensSwiper.swiperRef.destroy();
    this.baseNetworkTokensSwiper.initSwiper();
  }

  /**
   * normalisation of the token list for UI
   */
  public normalizeTokenList(tokenList: any[] | undefined) {
    if (!tokenList || tokenList.length === 0) {
      this.tokensTempDisplay = undefined;
      return;
    }
    this.tokensTempDisplay = this.web3Service.isConnected()
      ? {
          zeroList: tokenList.filter((x) => parseFloat(x.balance || 0) === 0),
          hasBalanceList: tokenList.filter(
            (x) => parseFloat(x.balance || 0) !== 0
          )
        }
      : { zeroList: tokenList, hasBalanceList: [] };
  }

  public slideChange(data: Swiper[], field: { index: number; isEnd: boolean }) {
    field.index = data[0].activeIndex;
    field.isEnd = data[0].isEnd;
  }

  public filterTokensByChainIdAndFilterValue(
    tokens: any[],
    selectNetwork: number,
    filterValue: string
  ) {
    return tokens.filter((token: CrowdToken) => {
      const networkMatches = this.isNetworkMatch(token.chainId, selectNetwork);
      const symbolMatches =
        token.symbol && this.isSymbolMatch(token.symbol, filterValue);
      const validToken = this.isValidToken(selectNetwork, token);

      return networkMatches && symbolMatches && validToken;
    });
  }

  private isNetworkMatch(
    tokenChainId: number,
    selectedNetwork: number
  ): boolean {
    return selectedNetwork === -1 || tokenChainId === selectedNetwork;
  }

  private isSymbolMatch(tokenSymbol: string, filterValue: string): boolean {
    return (
      !filterValue ||
      isAddress(filterValue) ||
      !tokenSymbol ||
      tokenSymbol.toLowerCase().includes(filterValue.toLowerCase())
    );
  }

  private isValidToken(selectedNetwork: number, token: CrowdToken): boolean {
    if (selectedNetwork === -1 || !this.data?.destValue) {
      return true;
    }

    if (!this.data) {
      return false;
    }

    const destValue = this.data.destValue;
    const title = this.data.title;

    if (title === 'from') {
      return true;
    }

    if (title === 'to') {
      if (destValue.chainId === selectedNetwork) {
        return true;
      }

      const destSymbol = destValue.symbol;

      if (destSymbol && ['CROWD', 'TMNG'].indexOf(destSymbol) === -1) {
        return true;
      }

      if (destSymbol === 'TMNG' && token.symbol === 'TMNG') {
        return true;
      }

      if (destSymbol === 'CROWD' && token.symbol === 'CROWD') {
        return true;
      }
    }

    return false;
  }

  private sortTokensByBalanceDesc() {
    if (this.tokensTempDisplay)
      this.tokensTempDisplay.hasBalanceList =
        this.tokensTempDisplay.hasBalanceList.sort(function (a, b) {
          if (a.chainId === b.chainId) {
            return parseFloat(b.balance) - parseFloat(a.balance);
          }
          return a.chainId > b.chainId ? 1 : -1;
        });
  }
  private getTokenByAddress(address: any) {
    address = address.toLowerCase();
    if (!this.data || !this.data.filteredTokenList) {
      return undefined;
    }

    for (const token of this.data.filteredTokenList) {
      if (token.address.toLowerCase() === address) {
        return this.selectedNetwork === -1 ||
          this.selectedNetwork === token.chainId
          ? token
          : undefined;
      }
    }
    return undefined;
  }

  private isTokenSelectedOtherSide(tokenAddress: any) {
    if (this.data?.destValue.address.toLowerCase() === tokenAddress) {
      return true;
    }
    return false;
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule, SharedModule.forRoot()],
  declarations: [CrossChainSelectOptionDialogComponent],
  exports: [CrossChainSelectOptionDialogComponent]
})
export class SelectOptionDialogComponentModule {}
