import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base.component';
import { RevenueDto } from '@crowdswap/constant';
import { CookieService } from 'ngx-cookie-service';
import { AffiliateService } from 'src/app/services/affiliate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import {
  Web3Service,
  ThemeService,
  AuthenticationService,
  TagManagerService,
  NDDClientInfoServiceImpl
} from '../../../services';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from '../../modal/modal.service';
import { WaitingDialogComponent } from '../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { UserInfo } from 'src/app/model/user-info';

export enum AffiliateTabs {
  REVENUE = 'revenue',
  PROFILE = 'profile'
}

@Component({
  selector: 'app-affiliate',
  templateUrl: './affiliate.component.html',
  styleUrls: ['./affiliate.component.scss']
})
export class AffiliateComponent extends BaseComponent implements OnInit {
  private readonly createSessionMessage =
    '{' +
    '"message": "By signing this message you confirm, you\'re the owner of the #wallet_address wallet address",' +
    '"nonce": #nonce' +
    '}';

  public revenue: RevenueDto | undefined = undefined;
  public userInfo: UserInfo | undefined = undefined;

  public isLoading: boolean = false;
  public sessionExpired: {
    isSessionExpired: boolean;
    retryFunction: (() => void) | undefined;
  } = { isSessionExpired: false, retryFunction: undefined };

  public AffiliateTabs = AffiliateTabs;
  public activeTab = AffiliateTabs.REVENUE;
  public wallConnectNotificationData: {
    title: string;
    subTitle: string;
    buttonTitle: string;
    closable: boolean;
  } = {
    title: 'Connect wallet',
    subTitle:
      'Your wallet is not connected. Please connect your wallet to continue with affiliate',
    buttonTitle: 'Connect wallet',
    closable: false
  };
  public sessionExpiredNotificationData: {
    title: string;
    subTitle: string;
    buttonTitle: string;
    closable: boolean;
  } = {
    title: 'Session expired!',
    subTitle:
      `Your session has been expired, please click 'sign' button in your\n` +
      `      wallet popup to sign in.`,
    buttonTitle: 'Create session',
    closable: false
  };
  public notVerifiedEmailNotificationData: {
    title: string;
    subTitle: string;
    buttonTitle: string;
    closable: boolean;
    onClose: () => void;
  } = {
    title: 'Please verify your email!',
    subTitle:
      `We've sent a verification email to your email address.\n` +
      `      Open your email and click on the link to verify your email.`,
    buttonTitle: 'Send again',
    closable: true,
    onClose: () => {
      this.userInfo && (this.userInfo.displayEmailVerificationNotify = false);
    }
  };
  public currentWalletAddress: string | undefined = undefined;

  constructor(
    public web3Service: Web3Service,
    public themeService: ThemeService,
    private cookieService: CookieService,
    private affiliateService: AffiliateService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  public async ngOnInit() {
    super.ngOnInit();

    if (this.route.snapshot.routeConfig) {
      switch (this.route.snapshot.routeConfig.path) {
        case AffiliateTabs.REVENUE:
          this.activeTab = AffiliateTabs.REVENUE;
          break;
        default:
          this.activeTab = AffiliateTabs.PROFILE;
          break;
      }
    }

    this.currentWalletAddress = this.web3Service.getWalletAddress();
    if (this.currentWalletAddress) {
      await this.onAccountChanged(this.currentWalletAddress);
    }

    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(
        async (address: string) => {
          if (this.currentWalletAddress === address) {
            return;
          }
          this.currentWalletAddress = address;
          await this.onAccountChanged(this.currentWalletAddress);
        }
      )
    );
  }

  public setActiveTab(nextTab: AffiliateTabs, refreshData = true) {
    // Prevent user from navigating to the dashboard tab if the session is created but the profile is not completed
    if (
      this.activeTab === AffiliateTabs.PROFILE &&
      this.activeTab !== nextTab
    ) {
      const authSession = this.cookieService.get(AffiliateService.AUTH_KEY);
      if (authSession && !this.isUserInfoValid()) {
        this.showToast(
          'Complete the profile',
          'The profile is not completed',
          'error'
        );
        return;
      }
    }

    // Show email verification notification
    if (
      nextTab === AffiliateTabs.PROFILE &&
      this.userInfo &&
      !this.userInfo.isEmailVerified
    ) {
      this.userInfo.displayEmailVerificationNotify = true;
    }

    this.activeTab = nextTab;

    const dataIsNotLoaded =
      nextTab === AffiliateTabs.REVENUE
        ? !this.isRevenueValid()
        : !this.isUserInfoValid();
    refreshData &&
      dataIsNotLoaded &&
      this.currentWalletAddress &&
      this.onAccountChanged(this.currentWalletAddress);
  }

  public async onUserInfoChanged(userInfo) {
    this.userInfo = userInfo;
    if (this.userInfo && !this.userInfo.isEmailVerified) {
      await this.onSendVerificationEmailClicked(false);
    }
  }

  private isUserInfoValid() {
    return (
      this.userInfo &&
      this.currentWalletAddress &&
      this.userInfo.walletAddress === this.currentWalletAddress
    );
  }

  private isRevenueValid() {
    return (
      this.revenue &&
      this.currentWalletAddress &&
      this.revenue.walletAddress === this.currentWalletAddress
    );
  }

  private async onAccountChanged(address: string) {
    try {
      this.isLoading = true;

      // Since there is no authSession, it's unauthorized
      const authSession = this.cookieService.get(AffiliateService.AUTH_KEY);
      if (!authSession) {
        await this.handleUnAuthorizedSession(address, this.onAccountChanged);
        return;
      }

      switch (this.activeTab) {
        case AffiliateTabs.REVENUE:
          this.revenue = await this.affiliateService.getRevenueOfWalletAddress(
            address
          );
          break;
        case AffiliateTabs.PROFILE:
          this.userInfo = await this.affiliateService.getUserInfo(address);
          if (
            this.route.snapshot.queryParams &&
            this.route.snapshot.queryParams.token
          ) {
            await this.verifyEmail(this.route.snapshot.queryParams.token);
          }
          break;
      }
    } catch (error: any) {
      {
        if (
          error.status === HttpStatusCode.Unauthorized ||
          error.status === HttpStatusCode.Forbidden
        ) {
          await this.handleUnAuthorizedSession(address, this.onAccountChanged);
        }
        if (error.status === HttpStatusCode.NotFound) {
          this.setActiveTab(AffiliateTabs.PROFILE, false);
        }
      }
    } finally {
      this.isLoading = false;
    }
  }

  private async handleUnAuthorizedSession(address: string, retryFunction) {
    try {
      // Session creation is needed, probably the user does not create the affiliate profile, so signing terms-of-service is needed as well
      // To prevent duplicate signing processes let's check if the affiliate profile does not exist, ask user to sign terms-of-service directly
      const isAffiliate = await this.affiliateService.isAffiliate(address);
      isAffiliate
        ? this.showSignInNotification(address, retryFunction)
        : this.navigateToTermsOfService();
    } catch (error: any) {
      {
        if (error.status === HttpStatusCode.NotFound) {
          this.navigateToTermsOfService();
        }
      }
    }
  }

  private navigateToTermsOfService() {
    this.router.navigate(['affiliate/terms-of-service']);
  }

  private async showSignInNotification(walletAddress, retryFunction) {
    this.sessionExpired.isSessionExpired = true;
    this.sessionExpired.retryFunction = () => {
      retryFunction.apply(this, [walletAddress]);
    };
  }

  public async onSignInClicked() {
    try {
      await this.waitingDialogService.open(WaitingDialogComponent, {
        isSign: true
      });

      const nonce = (await this.web3Service.getNonce()) || '';
      const walletAddress = this.web3Service.getWalletAddress();
      if (!walletAddress) {
        throw new Error(
          'Affiliate.onSignInClicked: wallet address is not defined'
        );
      }
      const signature = await this.web3Service.signMessage(
        this.createSessionMessage
          .replace('#wallet_address', walletAddress.toString())
          .replace('#nonce', nonce.toString())
      );
      if (!signature) {
        throw new Error('Affiliate.onSignInClicked: signature is not valid');
      }
      const jwt = await this.authenticationService.login(
        this.web3Service.getCurrentChainId(),
        walletAddress,
        signature,
        false
      );
      this.cookieService.set(
        AffiliateService.AUTH_KEY,
        `Bearer ${jwt.access_token}`,
        undefined,
        '/'
      );
      this.showToast('Sign-in succeeded', '', 'success');

      // Retry previous step after successful sign in
      this.sessionExpired.retryFunction && this.sessionExpired.retryFunction();
      this.sessionExpired.isSessionExpired = false;
      this.sessionExpired.retryFunction = undefined;
      //Redirect to the previous page/main route
    } catch (e: any) {
      console.log(e);
      this.showToast(
        'Sign-in failed',
        (e.error && (e.error.error || e.error.message)) ||
          'Something went wrong',
        'error'
      );
    } finally {
      await this.waitingDialogService.close();
    }
  }

  public async onSendVerificationEmailClicked(showMessage: boolean = true) {
    try {
      if (!this.userInfo || !this.userInfo.walletAddress) {
        return;
      }
      await this.affiliateService.sendVerificationToken(
        this.userInfo.walletAddress
      );
      showMessage && this.showToast('Sending email succeeded', '', 'success');
    } catch (e: any) {
      console.log(e);
      showMessage &&
        this.showToast(
          'Sending email failed',
          (e.error && (e.error.error || e.error.message)) ||
            'Something went wrong',
          'error'
        );
    }
  }

  private async verifyEmail(token: any) {
    try {
      if (!this.userInfo) {
        return;
      }
      if (this.userInfo && this.userInfo.isEmailVerified) {
        return;
      }
      const verificationResult = await this.affiliateService.verifyEmail(
        this.userInfo.walletAddress,
        token
      );
      this.userInfo.isEmailVerified = verificationResult;
      this.userInfo.displayEmailVerificationNotify = !verificationResult;
      this.showToast('Verification email succeeded', '', 'success');
    } catch (e: any) {
      console.log(e);
      this.showToast(
        'Verification email failed',
        (e.error && (e.error.error || e.error.message)) ||
          'Something went wrong',
        'error'
      );
    }
  }

  private showToast(title: string, body: string, type: 'error' | 'success') {
    switch (type) {
      case 'error':
        this.toastr.error(body, title, {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          positionClass: 'custom-toast-top-right',
          enableHtml: true,
          timeOut: 5000,
          messageClass: 'failedClass'
        });
        break;
      case 'success':
        this.toastr.success(body, title, {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          positionClass: 'custom-toast-top-right',
          enableHtml: true,
          timeOut: 5000,
          messageClass: 'successClass'
        });
        break;
    }
  }
}
