import { Web3Service } from '../../../../services/web3.service';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  OpportunityState,
  CrossChainState
} from '../../../../services/utils.service';
import { OpportunityInvestmentType } from '@crowdswap/constant';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-farm-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class OpportunityConfirmationComponent implements OnInit, OnDestroy {
  public isDarkMode;
  OpportunityState = OpportunityState;
  CrossChainState = CrossChainState;
  InvestmentType = OpportunityInvestmentType;
  public baseLogoIconsUrl = environment.BaseLogoIconsUrl;

  @Input()
  investmentAmount;
  @Input()
  amountLP;
  @Input()
  opportunityState;
  @Input()
  crossChainState;
  @Input()
  investmentType;
  @Input()
  token;
  @Input()
  selectedOpportunity;
  @Input()
  fallbackToken;
  @Input()
  originTxUrl;
  @Input()
  claimTxUrl;
  @Input()
  investedTokens;
  @Input()
  isWithdraw;

  @Output()
  hideSteps = new EventEmitter();

  constructor(public web3Service: Web3Service, public ref: ChangeDetectorRef) {}

  async ngOnInit(): Promise<any> {}
  ngOnDestroy(): void {}

  public async confirm() {}

  async openDetail() {
    this.hideSteps.emit();
  }

  // Only for testing of crossChain and opportunity stats
  changeState() {
    if (this.crossChainState < 7) {
      this.crossChainState++;
    } else {
      this.opportunityState++;
    }
  }

  updateUrl(el: any) {
    el.target.src = environment.BaseLogoIconsUrl + '00-none.png';
  }
}
