<app-dialog>
  <!--  Close cross-->
  <div class="flex justify-end mb-4">
    <div class="absolute right-5 mr-1 dark:text-textWhite">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="close()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
  </div>

  <!--  Loading circle-->
  <div class="flex justify-center">
    <div class="mb-2 loader-container">
      <div
        class="loader ease-linear rounded-full border-4 border-t-4 h-32 w-32 border-gray-200"
      ></div>
      <div class="loading-text flex justify-center">
        <img src="assets/media/logo/title-logo.svg" />
      </div>
    </div>
  </div>

  <div *ngIf="!data?.hasWaitingText">
    <!--  Waiting text for estimation or signing-->
    <div
      *ngIf="data?.isEstimation || data?.isSign"
      class="mt-4 flex justify-center"
    >
      <h4 class="text-lg font-bold dark:text-white">
        Waiting for
        {{
          data?.isEstimation
            ? "estimation"
            : data?.isSign
            ? "signing"
            : "confirmation"
        }}
      </h4>
    </div>

    <!--  Other texts-->

    <div
      class="mt-2 flex justify-center text-lg font-bold text-center dark:text-white"
    >
      <!--    Allowing-->
      <div
        id="divWaitingForAllow"
        *ngIf="
          !data?.destTokenSymbol &&
          !data?.stakeState &&
          !data?.sourcePrice &&
          !data?.isSign
        "
      >
        <p>
          Authorize CrowdSwap to utilize your {{ data?.sourceTokenSymbol }}
          {{ data?.targetNetworkName ? "on " + data?.sourceNetworkName : "" }}
          in your wallet to proceed with the transaction.
        </p>
      </div>

      <!--    Pairing-->
      <div *ngIf="data?.sourcePrice && !data?.isEstimation">
        <p class="mx-3">
          Pairing {{ data?.sourcePrice }}
          {{ data?.opportunityAsset!.split("/")[0] }} with
          {{ data?.destinationPrice }}
          {{ data?.opportunityAsset!.split("/")[1] }}
        </p>
      </div>

      <!--    Swapping-->
      <div *ngIf="data?.destTokenSymbol && !data?.stakeState">
        <p>
          Swapping {{ data?.amountIn }} {{ data?.sourceTokenSymbol }}
          {{ data?.targetNetworkName ? "on " + data?.sourceNetworkName : "" }}
          for {{ data?.amountOut }} {{ data?.destTokenSymbol }}
          {{ data?.targetNetworkName ? "on " + data?.targetNetworkName : "" }}
        </p>
      </div>

      <!--    StakeState-->
      <div *ngIf="data?.stakeState">
        <p class="dark:text-white">
          {{ data?.stakeState }}ing {{ data?.stakeAmount }}
          {{ data?.tokenSymbol }} token
        </p>
      </div>
    </div>

    <!--  Confirm in wallet-->
    <div
      *ngIf="!data?.isSign && (!data?.opportunityAsset || !data?.isEstimation)"
    >
      <p
        class="text-base dark:text-fff text-center m-3"
        *ngIf="
          !data?.destTokenSymbol &&
            !data?.stakeState &&
            !data?.sourcePrice &&
            !data?.isSign;
          else other
        "
      >
        (Open Your Wallet)
      </p>

      <ng-template #other>
        <p class="text-base dark:text-fff text-center m-3">
          Please confirm the transaction in your wallet.
        </p>
      </ng-template>
    </div>
  </div>

  <div *ngIf="data?.hasWaitingText">
    <!--  Waiting text for estimation or signing-->
    <div
      class="mt-4 font-18 font-bold dark:text-white flex text-center justify-center"
    >
      <p [innerHTML]="data?.text1"></p>
    </div>

    <!--  Other texts-->
    <div
      class="mt-2 flex justify-center text-lg font-bold text-center dark:text-white"
    >
      <div id="text-2">
        <p>
          {{ data?.text2 }}
        </p>
      </div>
    </div>

    <!--  Confirm in wallet-->
    <div *ngIf="data?.hasTransaction">
      <p
        class="text-base dark:text-fff text-center m-3"
        *ngIf="!data?.text3; else text3"
      >
        Please confirm the transaction in your wallet.
      </p>

      <ng-template #text3>
        <p class="text-base dark:text-fff text-center m-3">
          {{ data?.text3 }}
        </p>
      </ng-template>
    </div>
  </div>

  <div class="text-center rounded-md pt-3 pb-2 bg-gray-100 dark:bg-1E1E1E">
    <p class="text-xs text-7D7D7D dark:text-ffffff99">
      Please ensure you are visiting:
    </p>
    <p class="text-base font-bold">
      <span class="text-1BD37F">https</span
      ><span class="dark:text-fff">://app.crowdswap.org</span>
    </p>
  </div>
</app-dialog>
