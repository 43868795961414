export enum SelectedOpportunityTab {
  AllOpportunities,
  InvestedOpportunities
}

export enum SelectedInvestmentTab {
  Invest,
  Withdraw
}

export enum StakingButtonState {
  ApprovalNeeded,
  ApprovalConfirmed,
  WalletNotConnected,
  Disabled
}

export enum LPState {
  tokenB,
  tokenA,
  token
}

export enum ApproveState {
  Init,
  token,
  srcToken,
  destToken,
  srcDestToken
}

export enum OpportunityType {
  TypeCrowd = 'crowd',
  TypePancake = 'pancake',
  TypeBeefy = 'beefy',
  TypeStaking = 'staking',
  TypePrivateSale = 'private-sale',
  ETF = 'etf'
}
