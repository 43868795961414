import { Component, NgModule, inject } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { Web3Service } from 'src/app/services';
import { environment } from '../../../../../environments/environment';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';

export interface IWelcomDialogData {
  modalBody: string;
  walletAddress: string;
}

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss']
})
export class WelcomeDialogComponent {
  public email: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ]);
  public submited = false;
  readonly #modal = inject(NzModalRef);
  readonly nzModalData: IWelcomDialogData = inject(NZ_MODAL_DATA);

  constructor(private web3Service: Web3Service) {}

  destroyModal(): void {
    this.#modal.destroy();
  }

  async confirm() {
    this.submited = true;
    this.email.updateValueAndValidity();
    this.email.markAsDirty();
    if (this.email.invalid) {
      return;
    }

    try {
      const logItem = {
        level: 'info',
        datetime: new Date(),
        value: `${this.nzModalData.walletAddress} email ${this.email.value}`
      };
      fetch(`${environment.AFFILIATE_BASEURL}/api/v1/log/_doc`, {
        method: 'POST',
        body: JSON.stringify({
          wallet: this.web3Service.getWalletAddress,
          agent: window.navigator.userAgent,
          items: [logItem]
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
    } catch (e) {
      console.error(e);
    }

    this.destroyModal();
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule, ReactiveFormsModule],
  declarations: [WelcomeDialogComponent]
})
export class WelcomeDialogComponentModule {}
