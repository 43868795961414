<div class="flex justify-center bottom-space">
  <div class="flex w-4/5 mt-4">
    <div class="col-2 space-y-3 blue-color fixed">
      <button
        (click)="scroll(information, Tabs.Information)"
        href="opportunity/form/#information"
        class="flex items-center space-x-1 rounded-md p-1 hover:bg-blue-200"
        [ngClass]="{
          'blue-color-bg text-white': tab === Tabs.Information
        }"
      >
        <div [hidden]="tab !== Tabs.Information">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
              fill="white"
            />
          </svg>
        </div>
        <div [hidden]="tab === Tabs.Information">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
              fill="#0066FF"
            />
          </svg>
        </div>
        <div>Information</div>
      </button>
      <button
        (click)="scroll(kyc, Tabs.Kyc)"
        class="flex items-center space-x-1 rounded-md p-1 hover:bg-blue-200"
        [ngClass]="{
          'blue-color-bg text-white': tab === Tabs.Kyc
        }"
      >
        <div [hidden]="tab === Tabs.Kyc">
          <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 19.93V11H2V5.3L9 2.19V10.99H16C15.47 15.11 12.72 18.78 9 19.93Z"
              fill="#0066FF"
            />
          </svg>
        </div>
        <div [hidden]="tab !== Tabs.Kyc">
          <svg
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 19.93V11H2V5.3L9 2.19V10.99H16C15.47 15.11 12.72 18.78 9 19.93Z"
              fill="white"
            />
          </svg>
        </div>
        <div>KYC level</div>
      </button>
      <button
        (click)="scroll(price, Tabs.Price)"
        class="flex items-center space-x-1 rounded-md p-1 hover:bg-blue-200"
        [ngClass]="{
          'blue-color-bg text-white': tab === Tabs.Price
        }"
      >
        <div [hidden]="tab !== Tabs.Price">
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 12H16C16.55 12 17 11.55 17 11V5C17 4.45 16.55 4 16 4H13C12.45 4 12 4.45 12 5V11C12 11.55 12.45 12 13 12ZM14 6H15V10H14V6ZM7 12H10C10.55 12 11 11.55 11 11V5C11 4.45 10.55 4 10 4H7C6.45 4 6 4.45 6 5V11C6 11.55 6.45 12 7 12ZM8 6H9V10H8V6ZM3 4H5V12H3V4ZM0 0V16H20V0H0ZM18 14H2V2H18V14Z"
              fill="white"
            />
          </svg>
        </div>
        <div [hidden]="tab === Tabs.Price">
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 12H16C16.55 12 17 11.55 17 11V5C17 4.45 16.55 4 16 4H13C12.45 4 12 4.45 12 5V11C12 11.55 12.45 12 13 12ZM14 6H15V10H14V6ZM7 12H10C10.55 12 11 11.55 11 11V5C11 4.45 10.55 4 10 4H7C6.45 4 6 4.45 6 5V11C6 11.55 6.45 12 7 12ZM8 6H9V10H8V6ZM3 4H5V12H3V4ZM0 0V16H20V0H0ZM18 14H2V2H18V14Z"
              fill="#0066FF"
            />
          </svg>
        </div>
        <div>Price & funding range</div>
      </button>
      <button
        (click)="scroll(vesting, Tabs.Vesting)"
        class="flex items-center space-x-1 rounded-md p-1 hover:bg-blue-200"
        [ngClass]="{
          'blue-color-bg text-white': tab === Tabs.Vesting
        }"
      >
        <div [hidden]="tab !== Tabs.Vesting">
          <svg
            width="20"
            height="24"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.6037 7.25177L10.0003 0.648438L3.39699 7.25177C-0.243008 10.8918 -0.243008 16.8068 3.39699 20.4468C5.21699 22.2668 7.60866 23.1768 10.0003 23.1768C12.392 23.1768 14.7837 22.2668 16.6037 20.4468C20.2437 16.8068 20.2437 10.8918 16.6037 7.25177ZM10.0003 20.8551C8.13366 20.8551 6.37199 20.1318 5.05366 18.8018C3.72366 17.4718 3.00033 15.7218 3.00033 13.8551C3.00033 11.9884 3.72366 10.2268 5.05366 8.90844L10.0003 3.9501V20.8551Z"
              fill="white"
            />
          </svg>
        </div>
        <div [hidden]="tab === Tabs.Vesting">
          <svg
            width="20"
            height="24"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.6037 7.25177L10.0003 0.648438L3.39699 7.25177C-0.243008 10.8918 -0.243008 16.8068 3.39699 20.4468C5.21699 22.2668 7.60866 23.1768 10.0003 23.1768C12.392 23.1768 14.7837 22.2668 16.6037 20.4468C20.2437 16.8068 20.2437 10.8918 16.6037 7.25177ZM10.0003 20.8551C8.13366 20.8551 6.37199 20.1318 5.05366 18.8018C3.72366 17.4718 3.00033 15.7218 3.00033 13.8551C3.00033 11.9884 3.72366 10.2268 5.05366 8.90844L10.0003 3.9501V20.8551Z"
              fill="#0066FF"
            />
          </svg>
        </div>
        <div>Vesting plan</div>
      </button>
      <button
        (click)="scroll(time, Tabs.Time)"
        class="flex items-center space-x-1 rounded-md p-1 hover:bg-blue-200"
        [ngClass]="{
          'blue-color-bg text-white': tab === Tabs.Time
        }"
      >
        <div [hidden]="tab !== Tabs.Time">
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 9H6V11H4V9ZM16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM16 6H2V4H16V6ZM12 9H14V11H12V9ZM8 9H10V11H8V9Z"
              fill="white"
            />
          </svg>
        </div>
        <div [hidden]="tab === Tabs.Time">
          <svg
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 9H6V11H4V9ZM16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM16 6H2V4H16V6ZM12 9H14V11H12V9ZM8 9H10V11H8V9Z"
              fill="#0066FF"
            />
          </svg>
        </div>
        <div>start & end dates</div>
      </button>
      <button
        (click)="scroll(ticket, Tabs.Ticket)"
        class="flex items-center space-x-1 rounded-md p-1 hover:bg-blue-200"
        [ngClass]="{
          'blue-color-bg text-white': tab === Tabs.Ticket
        }"
      >
        <div [hidden]="tab !== Tabs.Ticket">
          <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.9998 16.75L4.82784 19.995L6.00684 13.122L1.00684 8.25495L7.90684 7.25495L10.9928 1.00195L14.0788 7.25495L20.9788 8.25495L15.9788 13.122L17.1578 19.995L10.9998 16.75Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div [hidden]="tab === Tabs.Ticket">
          <svg
            width="22"
            height="21"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.9998 16.75L4.82784 19.995L6.00684 13.122L1.00684 8.25495L7.90684 7.25495L10.9928 1.00195L14.0788 7.25495L20.9788 8.25495L15.9788 13.122L17.1578 19.995L10.9998 16.75Z"
              stroke="#0066FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div>First hours best price</div>
      </button>
      <button
        (click)="scroll(links, Tabs.Links)"
        class="flex items-center space-x-1 rounded-md p-1 hover:bg-blue-200"
        [ngClass]="{
          'blue-color-bg text-white': tab === Tabs.Links
        }"
      >
        <div [hidden]="tab !== Tabs.Links">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 14.0004C10.6583 14.6723 11.5594 15.0509 12.5 15.0509C13.4406 15.0509 14.3417 14.6723 15 14.0004L19 10.0004C20.3807 8.61967 20.3807 6.38109 19 5.00038C17.6193 3.61967 15.3807 3.61967 14 5.00038L13.5 5.50038"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.0004 9.99973C13.3421 9.32784 12.441 8.94922 11.5004 8.94922C10.5597 8.94922 9.65869 9.32784 9.00038 9.99973L5.00038 13.9997C3.61967 15.3804 3.61967 17.619 5.00038 18.9997C6.38109 20.3804 8.61967 20.3804 10.0004 18.9997L10.5004 18.4997"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div [hidden]="tab === Tabs.Links">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 14.0004C10.6583 14.6723 11.5594 15.0509 12.5 15.0509C13.4406 15.0509 14.3417 14.6723 15 14.0004L19 10.0004C20.3807 8.61967 20.3807 6.38109 19 5.00038C17.6193 3.61967 15.3807 3.61967 14 5.00038L13.5 5.50038"
              stroke="#0066FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.0004 9.99973C13.3421 9.32784 12.441 8.94922 11.5004 8.94922C10.5597 8.94922 9.65869 9.32784 9.00038 9.99973L5.00038 13.9997C3.61967 15.3804 3.61967 17.619 5.00038 18.9997C6.38109 20.3804 8.61967 20.3804 10.0004 18.9997L10.5004 18.4997"
              stroke="#0066FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div>Links</div>
      </button>
      <button
        (click)="scroll(rules, Tabs.Rules)"
        class="flex items-center space-x-1 rounded-md p-1 hover:bg-blue-200"
        [ngClass]="{
          'blue-color-bg text-white': tab === Tabs.Rules
        }"
      >
        <div [hidden]="tab === Tabs.Rules">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.54 10.9997L13 7.45969L14.41 6.04969L16.53 8.16969L20.77 3.92969L22.18 5.33969L16.54 10.9997ZM11 6.99969H2V8.99969H11V6.99969ZM21 13.4097L19.59 11.9997L17 14.5897L14.41 11.9997L13 13.4097L15.59 15.9997L13 18.5897L14.41 19.9997L17 17.4097L19.59 19.9997L21 18.5897L18.41 15.9997L21 13.4097ZM11 14.9997H2V16.9997H11V14.9997Z"
              fill="#0066FF"
            />
          </svg>
        </div>
        <div [hidden]="tab !== Tabs.Rules">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.54 10.9997L13 7.45969L14.41 6.04969L16.53 8.16969L20.77 3.92969L22.18 5.33969L16.54 10.9997ZM11 6.99969H2V8.99969H11V6.99969ZM21 13.4097L19.59 11.9997L17 14.5897L14.41 11.9997L13 13.4097L15.59 15.9997L13 18.5897L14.41 19.9997L17 17.4097L19.59 19.9997L21 18.5897L18.41 15.9997L21 13.4097ZM11 14.9997H2V16.9997H11V14.9997Z"
              fill="white"
            />
          </svg>
        </div>
        <div>Rules</div>
      </button>
    </div>
    <div class="col-6 offset-4 space-y-3">
      <div
        #information
        id="information"
        accesskey="information"
        class="pb-md-5"
      >
        <div class="font-bold text-3xl mb-3 dark:text-white">
          Crowd sale setup
        </div>
        <div class="pb-2 dark:text-gray-200">Set-up your crowd sale</div>
        <div class="pb-3">
          <span class="bg-green-100 text-green-400 text-sm p-1 rounded-md"
            >KYB Approved</span
          >
        </div>
        <div class="rounded-md p-4 shadow-md space-y-3">
          <div class="dark:text-gray-400">
            Give some information about your pool like name, icon, description &
            risks
          </div>
          <div>
            <div class="blue-color text-xs-2">
              Project name<span *ngIf="!projectName" class="text-red-500"
                >&nbsp;*</span
              >
            </div>
            <input
              [(ngModel)]="projectName"
              id="projectName"
              class="border-2 blue-color-border focus:outline-none p-1 rounded-md dark:text-white dark:bg-darkBlack"
            />
          </div>
          <div>
            <div class="blue-color text-xs-2">Network</div>
            <div class="relative rightAlign">
              <div
                class="inline-flex justify-center rounded-md border-2 blue-color-border dark:bg-darkBlack button-height px-sm-1 p-2 bg-white items-center text-sm font-medium text-gray-700"
              >
                <div class="flex md:px-1">
                  <div class="flex items-center justify-center">
                    <img
                      class="rounded-full"
                      alt="coinIcon"
                      [src]="web3Service.networkSpec[currentChainId].coinIcon"
                      height="20"
                      width="20"
                    />
                  </div>
                  <div class="grid grid-rows-1">
                    <span
                      class="d-none d-md-block row-span-1 font-openSans-bold dark:text-textWhite text-left"
                      >&nbsp;&nbsp;{{
                        web3Service.networkSpec[currentChainId].title
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="blue-color text-xs-2">
              Token name<span *ngIf="!presaleTokenName" class="text-red-500"
                >&nbsp;*</span
              >
            </div>
            <input
              [(ngModel)]="presaleTokenName"
              id="tokenName"
              class="border-2 blue-color-border focus:outline-none p-1 dark:text-white rounded-md dark:bg-darkBlack"
            />
          </div>
          <div>
            <div class="blue-color text-xs-2">
              Token decimal<span
                *ngIf="!presaleTokenDecimal"
                class="text-red-500"
                >&nbsp;*</span
              >
            </div>
            <div class="relative rightAlign">
              <button
                (click)="decimalFlag = !decimalFlag"
                id="decimal-dropdown"
                (clickOutside)="onClose(decimalFlag)"
                aria-expanded="false"
                aria-haspopup="true"
                class="inline-flex justify-center rounded-md border-2 p-2 blue-color-border dark:bg-darkBlack button-height px-sm-1 bg-white items-center text-sm font-medium text-gray-700"
                type="button"
              >
                <div class="flex items-center md:px-1">
                  <div class="grid grid-rows-1">
                    <span
                      class="d-none d-md-block row-span-1 font-openSans-bold dark:text-textWhite text-left"
                      >&nbsp;{{ presaleTokenDecimal }}</span
                    >
                  </div>
                  <div>
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_917_16869)">
                        <path
                          d="M5.83594 8.83203L10.0026 12.9987L14.1693 8.83203H5.83594Z"
                          fill="#323232"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_917_16869">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </button>
              <div
                *ngIf="decimalFlag"
                aria-labelledby="menu-button"
                aria-orientation="vertical"
                class="origin-top-left w-max absolute w-66 z-20 h-auto overflow-hidden rounded-md shadow-lg p-sm-1 bg-white dark:bg-darkBlack ring-1 ring-black dark:ring-gray-700 ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu"
              >
                <div class="overflow-x-hidden max-h-90 m-sm-2 m-1">
                  <div
                    (click)="decimalSelect(item)"
                    *ngFor="let item of decimals"
                    class="cursor-pointer rounded-md p-1 mr-1 hover:bg-gray-100 dark:hover:bg-hoverBackground"
                  >
                    <div class="flex items-center">
                      <div class="grid grid-rows-1 ml-3">
                        <span
                          id="decimal"
                          class="row-span-1 dark:text-textWhite"
                          >{{ item }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="blue-color text-xs-2">Token address (optional)</div>
            <input
              [(ngModel)]="presaleTokenAddress"
              id="tokenAddress"
              class="border-2 blue-color-border focus:outline-none p-1 dark:text-white rounded-md dark:bg-darkBlack"
            />
          </div>
          <div>
            <div class="blue-color text-xs-2">Required token</div>
            <div class="relative rightAlign">
              <button
                (click)="tokenFlag = !tokenFlag"
                id="token-dropdown"
                (clickOutside)="onClose(tokenFlag)"
                aria-expanded="false"
                aria-haspopup="true"
                class="inline-flex justify-center rounded-md border-2 p-2 blue-color-border dark:bg-darkBlack button-height px-sm-1 bg-white items-center text-sm font-medium text-gray-700"
                type="button"
              >
                <div class="flex items-center md:px-1">
                  <div
                    class="flex items-center justify-center"
                    style="height: 20px"
                  >
                    <img
                      class="rounded-full"
                      width="20"
                      [src]="
                        baseLogoIconsUrl + investToken.toLowerCase() + '.png'
                      "
                      (error)="updateUrl($event)"
                    />
                  </div>
                  <div class="grid grid-rows-1">
                    <span
                      class="d-none d-md-block row-span-1 font-openSans-bold dark:text-textWhite text-left"
                      >&nbsp;{{ investToken }}</span
                    >
                  </div>
                  <div>
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_917_16869)">
                        <path
                          d="M5.83594 8.83203L10.0026 12.9987L14.1693 8.83203H5.83594Z"
                          fill="#323232"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_917_16869">
                          <rect
                            width="20"
                            height="20"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </button>
              <div
                *ngIf="tokenFlag"
                aria-labelledby="menu-button"
                aria-orientation="vertical"
                class="origin-top-left w-max absolute w-66 z-20 h-auto overflow-hidden rounded-md shadow-lg p-sm-1 bg-white dark:bg-darkBlack ring-1 ring-black dark:ring-gray-700 ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                role="menu"
              >
                <div class="overflow-x-hidden max-h-90 m-sm-2 m-1">
                  <div
                    (click)="tokenSelect(item)"
                    *ngFor="let item of tokenList"
                    class="cursor-pointer rounded-md p-1 mr-1 hover:bg-gray-100 dark:hover:bg-hoverBackground"
                  >
                    <div class="flex items-center">
                      <div
                        class="flex items-center justify-center"
                        style="height: 20px"
                      >
                        <img
                          class="rounded-full"
                          width="20"
                          [src]="baseLogoIconsUrl + item.toLowerCase() + '.png'"
                          (error)="updateUrl($event)"
                        />
                      </div>
                      <div class="grid grid-rows-1 ml-3">
                        <span
                          id="spnNetworkItem"
                          class="row-span-1 dark:text-textWhite"
                          >{{ item }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="dark:text-gray-400">
              Upload icon<span *ngIf="!isIconUploaded" class="text-red-500"
                >&nbsp;*</span
              >
            </div>
            <div class="text-xs-2 dark:text-gray-500">
              Square aspect ratio, png, up to 100KB, Project name must be filled
            </div>
            <div class="flex justify-content-between">
              <input
                class="border-2 rounded-md p-1 dark:text-white blue-color-border"
                type="file"
                id="myFile"
                size="10"
                name="filename"
                (change)="onChange($event)"
              />
              <button
                class="py-1 text-white rounded-md blue-color-bg px-2"
                [ngClass]="{
                  'pointer-events-none cursor-default bg-gray-400':
                    !file ||
                    this.projectName === undefined ||
                    this.projectName === ''
                }"
                (click)="onUpload()"
              >
                Upload
              </button>
            </div>
          </div>
          <div class="container" *ngIf="loading">
            <h3 class="dark:text-white">Loading ...</h3>
          </div>
          <div class="container" *ngIf="isIconUploaded">
            <h3 class="dark:text-white">Uploaded successfully</h3>
          </div>
          <div>
            <div class="blue-color text-xs-2">
              Description<span *ngIf="!description" class="text-red-500"
                >&nbsp;*</span
              >
            </div>
            <textarea
              [(ngModel)]="description"
              class="w-full h-20 rounded-md border-2 dark:text-white blue-color-border dark:bg-darkBlack"
              type="text"
              id="description"
              name="description"
              required
              minlength="0"
              cols="40"
              rows="15"
              size="20"
            ></textarea>
          </div>
          <div>
            <div class="blue-color text-xs-2">
              Banner Description<span
                *ngIf="!bannerDescription"
                class="text-red-500"
                >&nbsp;*</span
              >
            </div>
            <textarea
              [(ngModel)]="bannerDescription"
              class="w-full h-20 rounded-md border-2 dark:text-white blue-color-border dark:bg-darkBlack"
              type="text"
              id="bannerDescription"
              name="description"
              required
              minlength="0"
              maxlength="180"
              cols="40"
              rows="5"
              size="20"
            ></textarea>
          </div>
          <div>
            <div class="blue-color text-xs-2">
              Risk<span *ngIf="!risk" class="text-red-500">&nbsp;*</span>
            </div>
            <textarea
              [(ngModel)]="risk"
              class="w-full h-20 rounded-md border-2 dark:text-white blue-color-border dark:bg-darkBlack"
              type="text"
              id="risk"
              name="risk"
              required
              minlength="0"
              size="10"
              cols="40"
              rows="5"
            ></textarea>
          </div>
          <div>
            <div class="blue-color text-xs-2">
              Sign message<span *ngIf="!signMessage" class="text-red-500"
                >&nbsp;*</span
              >
            </div>
            <textarea
              [(ngModel)]="signMessage"
              class="w-full h-20 rounded-md border-2 dark:text-white blue-color-border dark:bg-darkBlack"
              type="text"
              id="signMessage"
              name="signMessage"
              required
              minlength="0"
              maxlength="180"
              size="10"
              cols="40"
              rows="5"
            ></textarea>
          </div>
        </div>
      </div>
      <div #kyc class="pb-md-5">
        <div class="flex items-center space-x-1 font-bold pb-3">
          <div [hidden]="isDarkMode">
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 19.93V11H2V5.3L9 2.19V10.99H16C15.47 15.11 12.72 18.78 9 19.93Z"
                fill="black"
              />
            </svg>
          </div>
          <div [hidden]="!isDarkMode">
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 19.93V11H2V5.3L9 2.19V10.99H16C15.47 15.11 12.72 18.78 9 19.93Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="dark:text-white">KYC level</div>
        </div>
        <fieldset class="dark:text-gray-200">
          <legend class="pb-5">
            Select the level of KYC for users:<span
              *ngIf="!kycLevel"
              class="text-red-500"
              >&nbsp;*</span
            >
          </legend>
          <div class="rounded-md shadow-md p-3 space-y-3">
            <div>
              <input
                type="radio"
                id="minimum"
                name="kycLevel"
                value="0"
                [(ngModel)]="kycLevel"
              />
              <label for="minimum">Minimum requirements</label>
              <div class="pt-3 pl-2">
                <li>Liveness check</li>
                <li>Proof of address</li>
                <li>Uniqueness</li>
                <li>Block restricted countries</li>
              </div>
            </div>
            <div>
              <input
                type="radio"
                id="medium"
                name="kycLevel"
                value="1"
                [(ngModel)]="kycLevel"
              />
              <label for="medium">Medium</label>
              <div class="pt-3 pl-2">
                <li>Liveness check</li>
                <li>Proof of address</li>
                <li>Uniqueness</li>
                <li>Block restricted countries</li>
              </div>
            </div>
            <div>
              <input
                type="radio"
                id="maximum"
                name="kycLevel"
                value="2"
                [(ngModel)]="kycLevel"
              />
              <label for="maximum">Maximum</label>
              <div class="pt-3 pl-2">
                <li>Liveness check</li>
                <li>Proof of address</li>
                <li>Uniqueness</li>
                <li>Block restricted countries</li>
              </div>
            </div>
            <div>
              <div class="blue-color text-xs-2">
                Fractal List Id<span *ngIf="!fractalListId" class="text-red-500"
                  >&nbsp;*</span
                >
              </div>
              <input
                [(ngModel)]="fractalListId"
                id="fractalListId"
                class="border-2 blue-color-border focus:outline-none col-10 text-xs-2 p-1 rounded-md dark:text-white dark:bg-darkBlack"
              />
            </div>
            <div>
              <div class="blue-color text-xs-2">
                Fractal URL<span *ngIf="!fractalUrl" class="text-red-500"
                  >&nbsp;*</span
                >
              </div>
              <input
                [(ngModel)]="fractalUrl"
                id="fractalUrl"
                class="border-2 blue-color-border focus:outline-none col-10 text-xs-2 p-1 rounded-md dark:text-white dark:bg-darkBlack"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div #price class="pb-md-5">
        <div class="flex items-center space-x-1 font-bold dark:text-white pb-3">
          <div>
            <div [hidden]="isDarkMode">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 12H16C16.55 12 17 11.55 17 11V5C17 4.45 16.55 4 16 4H13C12.45 4 12 4.45 12 5V11C12 11.55 12.45 12 13 12ZM14 6H15V10H14V6ZM7 12H10C10.55 12 11 11.55 11 11V5C11 4.45 10.55 4 10 4H7C6.45 4 6 4.45 6 5V11C6 11.55 6.45 12 7 12ZM8 6H9V10H8V6ZM3 4H5V12H3V4ZM0 0V16H20V0H0ZM18 14H2V2H18V14Z"
                  fill="black"
                />
              </svg>
            </div>
            <div [hidden]="!isDarkMode">
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 12H16C16.55 12 17 11.55 17 11V5C17 4.45 16.55 4 16 4H13C12.45 4 12 4.45 12 5V11C12 11.55 12.45 12 13 12ZM14 6H15V10H14V6ZM7 12H10C10.55 12 11 11.55 11 11V5C11 4.45 10.55 4 10 4H7C6.45 4 6 4.45 6 5V11C6 11.55 6.45 12 7 12ZM8 6H9V10H8V6ZM3 4H5V12H3V4ZM0 0V16H20V0H0ZM18 14H2V2H18V14Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div>Price and funding ranges</div>
        </div>
        <div class="dark:text-gray-200 mb-3 text-xs-2">
          Set the minimum of sale and maximum of sale in bellow. then fill the
          the minimum amount, maximum amount and price of each round in their
          sections. max 3 rounds are available
        </div>
        <div class="shadow-md rounded-md p-4 mb-4">
          <div class="mb-3 dark:text-white">Minimum and maximum of sale</div>
          <div class="flex mb-2 justify-content-between space-x-3">
            <div>
              <div class="blue-color text-xs-2">
                Minimum of sale<span *ngIf="!softCap" class="text-red-500"
                  >&nbsp;*</span
                >
              </div>
              <div
                class="flex items-center h-9 rounded-md border-2 blue-color-border"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 10H10V6"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 4L10 10"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18 14H14V18"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 14L20 20"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input
                  [(ngModel)]="softCap"
                  id="minimumOfSale"
                  class="dark:text-white rounded-md focus:outline-none p-1 dark:bg-darkBlack"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </div>
            </div>
            <div>
              <div class="blue-color text-xs-2">
                Maximum of sale<span *ngIf="!hardCap" class="text-red-500"
                  >&nbsp;*</span
                >
              </div>
              <div
                class="flex items-center h-9 rounded-md border-2 blue-color-border"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 20H20V16"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 14L20 20"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 4H4V8"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 4L10 10"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input
                  [(ngModel)]="hardCap"
                  id="maximumOfSale"
                  class="dark:text-white rounded-md focus:outline-none p-1 dark:bg-darkBlack"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </div>
            </div>
          </div>
          <div class="flex mb-5 justify-content-between space-x-3">
            <div>
              <div class="blue-color text-xs-2">
                Minimum of invest by user<span
                  *ngIf="!minInvest"
                  class="text-red-500"
                  >&nbsp;*</span
                >
              </div>
              <div
                class="flex items-center h-9 rounded-md border-2 blue-color-border"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 20H20V16"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 14L20 20"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 4H4V8"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4 4L10 10"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <input
                  [(ngModel)]="minInvest"
                  id="minInvest"
                  class="dark:text-white rounded-md focus:outline-none p-1 dark:bg-darkBlack"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </div>
            </div>
          </div>
          <span
            *ngIf="
              +hardCap < +softCap ||
              +maximumOfRound2 < +maximumOfRound1 ||
              +maximumOfRound3 < +maximumOfRound2
            "
            class="text-xs-2 text-red-500"
            >Maximum must be bigger than minimum
          </span>
          <hr />
          <div class="mt-3 space-y-4">
            <div class="flex justify-content-between space-x-3 blue-color">
              <div class="flex items-center space-x-1">
                <div>Round1</div>
                <div>
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.4 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04ZM0 14.25V18H3.75L14.81 6.94L11.06 3.19L0 14.25ZM2.92 16H2V15.08L11.06 6.02L11.98 6.94L2.92 16Z"
                      fill="#5B9DFF"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <div class="text-xs-2">Minimum</div>
                <div
                  class="flex items-center h-9 rounded-md border-2 blue-color-border"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10H10V6"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 4L10 10"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18 14H14V18"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14L20 20"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div
                    id="minimumOfRound1"
                    style="width: 120px"
                    class="focus:outline-none p-1 dark:bg-darkBlack"
                  >
                    0
                  </div>
                </div>
              </div>
              <div>
                <div class="text-xs-2">
                  Maximum<span *ngIf="!maximumOfRound1" class="text-red-500"
                    >&nbsp;*</span
                  >
                </div>
                <div
                  class="flex items-center h-9 rounded-md border-2 blue-color-border"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 20H20V16"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14L20 20"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 4H4V8"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 4L10 10"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <input
                    [(ngModel)]="maximumOfRound1"
                    id="maximumOfRound1"
                    style="width: 120px"
                    class="dark:text-white rounded-md focus:outline-none p-1 dark:bg-darkBlack"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  />
                </div>
              </div>
              <div>
                <div class="text-xs-2">
                  Price<span *ngIf="!priceOfRound1" class="text-red-500"
                    >&nbsp;*</span
                  >
                </div>
                <input
                  [(ngModel)]="priceOfRound1"
                  id="priceOfRound1"
                  style="width: 100px"
                  class="border-2 rounded-md focus:outline-none p-1 blue-color-border dark:text-white dark:bg-darkBlack"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </div>
            </div>
            <div class="flex justify-content-between space-x-3 blue-color">
              <div class="flex items-center space-x-1">
                <div>Round2</div>
                <div>
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.4 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04ZM0 14.25V18H3.75L14.81 6.94L11.06 3.19L0 14.25ZM2.92 16H2V15.08L11.06 6.02L11.98 6.94L2.92 16Z"
                      fill="#5B9DFF"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <div class="text-xs-2">Minimum</div>
                <div
                  class="flex items-center rounded-md h-9 border-2 blue-color-border"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10H10V6"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 4L10 10"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18 14H14V18"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14L20 20"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div
                    id="minimumOfRound2"
                    style="width: 120px"
                    class="focus:outline-none p-1 dark:bg-darkBlack"
                  >
                    {{ maximumOfRound1 === hardCap ? "" : maximumOfRound1 }}
                  </div>
                </div>
              </div>
              <div>
                <div class="text-xs-2">Maximum</div>
                <div
                  class="flex items-center h-9 rounded-md border-2 blue-color-border"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 20H20V16"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14L20 20"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 4H4V8"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 4L10 10"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <input
                    [(ngModel)]="maximumOfRound2"
                    id="maximumOfRound2"
                    style="width: 120px"
                    class="dark:text-white rounded-md focus:outline-none p-1 dark:bg-darkBlack"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  />
                </div>
              </div>
              <div>
                <div class="text-xs-2">Price</div>
                <input
                  [(ngModel)]="priceOfRound2"
                  id="priceOfRound2"
                  style="width: 100px"
                  class="border-2 rounded-md focus:outline-none p-1 blue-color-border dark:text-white dark:bg-darkBlack"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </div>
            </div>
            <div class="flex justify-content-between space-x-3 blue-color">
              <div class="flex items-center space-x-1">
                <div>Round3</div>
                <div>
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.4 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04ZM0 14.25V18H3.75L14.81 6.94L11.06 3.19L0 14.25ZM2.92 16H2V15.08L11.06 6.02L11.98 6.94L2.92 16Z"
                      fill="#5B9DFF"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <div class="text-xs-2">Minimum</div>
                <div
                  class="flex items-center rounded-md h-9 border-2 blue-color-border"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 10H10V6"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 4L10 10"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18 14H14V18"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14L20 20"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div
                    id="minimumOfRound3"
                    style="width: 120px"
                    class="focus:outline-none p-1 dark:bg-darkBlack"
                  >
                    {{ maximumOfRound2 === hardCap ? "" : maximumOfRound2 }}
                  </div>
                </div>
              </div>
              <div>
                <div class="text-xs-2">Maximum</div>
                <div
                  class="flex items-center rounded-md h-9 border-2 blue-color-border"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 20H20V16"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 14L20 20"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 4H4V8"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4 4L10 10"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <input
                    [(ngModel)]="maximumOfRound3"
                    id="maximumOfRound3"
                    style="width: 120px"
                    class="dark:text-white rounded-md focus:outline-none p-1 dark:bg-darkBlack"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  />
                </div>
              </div>
              <div>
                <div class="text-xs-2">Price</div>
                <input
                  [(ngModel)]="priceOfRound3"
                  id="priceOfRound3"
                  style="width: 100px"
                  class="border-2 rounded-md focus:outline-none p-1 blue-color-border dark:text-white dark:bg-darkBlack"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="border rounded-md p-2 attention rounded-md w-full">
          <div class="flex space-x-1">
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 21H23L12 2L1 21ZM4.47 19L12 5.99L19.53 19H4.47ZM11 16H13V18H11V16ZM11 10H13V14H11V10Z"
                  fill="#4E3200"
                />
              </svg>
            </div>
            <div>Attention</div>
          </div>
          <div class="text-xs-2">
            <li>Funding ranges must at least be $50K.</li>
            <li>Additional funding ranges can be defined.</li>
            <li>
              The price for the funding range must always be at least the price
              from the round before.
            </li>
          </div>
        </div>
      </div>
      <div #vesting class="pb-md-5 dark:text-white">
        <div class="flex space-x-1 font-bold">
          <div [hidden]="isDarkMode">
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6037 7.25177L10.0003 0.648438L3.39699 7.25177C-0.243008 10.8918 -0.243008 16.8068 3.39699 20.4468C5.21699 22.2668 7.60866 23.1768 10.0003 23.1768C12.392 23.1768 14.7837 22.2668 16.6037 20.4468C20.2437 16.8068 20.2437 10.8918 16.6037 7.25177V7.25177ZM10.0003 20.8551C8.13366 20.8551 6.37199 20.1318 5.05366 18.8018C3.72366 17.4718 3.00033 15.7218 3.00033 13.8551C3.00033 11.9884 3.72366 10.2268 5.05366 8.90844L10.0003 3.9501V20.8551Z"
                fill="#323232"
              />
            </svg>
          </div>
          <div [hidden]="!isDarkMode">
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6037 7.25177L10.0003 0.648438L3.39699 7.25177C-0.243008 10.8918 -0.243008 16.8068 3.39699 20.4468C5.21699 22.2668 7.60866 23.1768 10.0003 23.1768C12.392 23.1768 14.7837 22.2668 16.6037 20.4468C20.2437 16.8068 20.2437 10.8918 16.6037 7.25177V7.25177ZM10.0003 20.8551C8.13366 20.8551 6.37199 20.1318 5.05366 18.8018C3.72366 17.4718 3.00033 15.7218 3.00033 13.8551C3.00033 11.9884 3.72366 10.2268 5.05366 8.90844L10.0003 3.9501V20.8551Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="mb-2">Vesting plan</div>
        </div>
        <div class="text-xs-2 mb-5">
          Set the vesting plan to distribute token of users.
        </div>

        <div *ngIf="presale.vestingType" class="flex items-center space-x-1 mb-3">
          <div>
            <input
              [(ngModel)]="isVestingLinear"
              id="vestingLinear"
              name="vestingLinear"
              type="checkbox"
            />
          </div>
          <div class="text-sm blue-color">
            Use vesting linear instead of monthly plan
          </div>
        </div>

        <div class="shadow-md space-y-3 rounded-md p-4">
          <div
            *ngFor="let item of rulesetList; index as index"
            class="space-y-4"
          >
            <div class="flex justify-content-between space-x-3 blue-color">
              <div class="flex col-3 items-center space-x-1">
                <div>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 11V9C19 7.89543 18.1046 7 17 7H9C7.89543 7 7 7.89543 7 9V17C7 18.1046 7.89543 19 9 19H11"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13 13L22 16L18 18L16 22L13 13"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 3.00086V3.01086"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 3.00086V3.01086"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 3.00086V3.01086"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15 3.00086V3.01086"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 7.00086V7.01086"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 11.0009V11.0109"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 15.0009V15.0109"
                      stroke="#0066FF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div>Period&nbsp;{{ index + 1 }}</div>
                <div>
                  <button *ngIf="index !== 0" (click)="removeRuleset(index)">
                    <svg
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1978_14485)">
                        <path
                          d="M16 29C16 30.1 16.9 31 18 31H26C27.1 31 28 30.1 28 29V17H16V29ZM18 19H26V29H18V19ZM25.5 14L24.5 13H19.5L18.5 14H15V16H29V14H25.5Z"
                          fill="#0066FF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1978_14485">
                          <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="translate(10 10)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
              <div>
                <div class="text-xs-2">
                  Number of months
                  <span *ngIf="!rulesetList[index].month" class="text-red-500"
                    >&nbsp;*</span
                  >
                </div>
                <div
                  class="flex items-center h-9 rounded-md border-2 blue-color-border"
                >
                  <input
                    [(ngModel)]="rulesetList[index].month"
                    (ngModelChange)="calculatePercentageAndMonth()"
                    id="vestingMonth1"
                    style="width: 120px"
                    class="dark:text-white rounded-md focus:outline-none p-1 dark:bg-darkBlack"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  />
                </div>
              </div>
              <div>
                <div class="text-xs-2">
                  Percent of return<span
                    *ngIf="
                      !rulesetList[index].value ||
                      rulesetList[index].value === 0
                    "
                    class="text-red-500"
                    >&nbsp;*</span
                  >
                </div>
                <div
                  class="flex items-center justify-content-between h-9 rounded-md border-2 blue-color-border"
                >
                  <input
                    [(ngModel)]="rulesetList[index].value"
                    (ngModelChange)="calculatePercentageAndMonth()"
                    id="vestingPercent1"
                    style="width: 100px"
                    class="focus:outline-none rounded-md p-1 dark:text-white dark:bg-darkBlack"
                    appRegExpDirective
                    [appRegex]="twoDigitDecimalNumberRegex"
                  />
                  <div class="text-xs-2 pr-1">%</div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="text-xs-2 font-openSans-semiBold"
            [ngClass]="{
              'text-red-500':
                +rulesetPercentage > 100 ||
                +rulesetPercentage < 100 ||
                !isPercentageValid,
              'text-green-500': +rulesetPercentage === 100 && isPercentageValid
            }"
          >
            Total percents:&nbsp;%{{ rulesetPercentage }}
          </div>
          <div
            class="text-xs-2 font-openSans-semiBold"
            [ngClass]="{
              'text-red-500': !isMonthsValid,
              'text-green-500': isMonthsValid
            }"
          >
            Total Month:&nbsp;{{ rulesetMonth }} months
          </div>
          <div class="flex justify-center mt-3 items-center">
            <button
              (click)="addRuleset()"
              class="blue-color flex font-openSans-semiBold p-1"
              [disabled]="isVestingLinear"
              [ngClass]="{ disable: isVestingLinear }"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 5V19"
                  stroke="#0066FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 12H19"
                  stroke="#0066FF"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Add
            </button>
          </div>
        </div>
      </div>
      <div #time class="pb-md-5 dark:text-white">
        <div class="flex space-x-1 font-bold">
          <div [hidden]="isDarkMode">
            <svg
              width="22"
              height="24"
              viewBox="0 0 22 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.16667 10.834H7.5V13.1673H5.16667V10.834ZM19.1667 2.66732H18V0.333984H15.6667V2.66732H6.33333V0.333984H4V2.66732H2.83333C1.53833 2.66732 0.511667 3.71732 0.511667 5.00065L0.5 21.334C0.5 22.6173 1.53833 23.6673 2.83333 23.6673H19.1667C20.45 23.6673 21.5 22.6173 21.5 21.334V5.00065C21.5 3.71732 20.45 2.66732 19.1667 2.66732ZM19.1667 21.334H2.83333V9.66732H19.1667V21.334ZM19.1667 7.33398H2.83333V5.00065H19.1667V7.33398ZM14.5 10.834H16.8333V13.1673H14.5V10.834ZM9.83333 10.834H12.1667V13.1673H9.83333V10.834Z"
                fill="black"
              />
            </svg>
          </div>
          <div [hidden]="!isDarkMode">
            <svg
              width="22"
              height="24"
              viewBox="0 0 22 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.16667 10.834H7.5V13.1673H5.16667V10.834ZM19.1667 2.66732H18V0.333984H15.6667V2.66732H6.33333V0.333984H4V2.66732H2.83333C1.53833 2.66732 0.511667 3.71732 0.511667 5.00065L0.5 21.334C0.5 22.6173 1.53833 23.6673 2.83333 23.6673H19.1667C20.45 23.6673 21.5 22.6173 21.5 21.334V5.00065C21.5 3.71732 20.45 2.66732 19.1667 2.66732ZM19.1667 21.334H2.83333V9.66732H19.1667V21.334ZM19.1667 7.33398H2.83333V5.00065H19.1667V7.33398ZM14.5 10.834H16.8333V13.1673H14.5V10.834ZM9.83333 10.834H12.1667V13.1673H9.83333V10.834Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="mb-2">Start and end dates</div>
        </div>
        <div class="text-xs-2 mb-5">
          Set the start date and end date of crowd sale. After setting up the
          Crowd Sale the start and end dates can’t be changed anymore.
        </div>
        <div class="shadow-md space-y-3 rounded-md p-4">
          <div class="flex items-center space-x-2">
            <div class="col-4">
              <div>
                Start date and time<span
                  *ngIf="!startDateDay || !startDateHour"
                  class="text-red-500"
                  >&nbsp;*</span
                >
              </div>
            </div>
            <div class="col-4">
              <input
                class="dark:bg-darkBlack border-2 blue-color-border rounded-md blue-color"
                type="date"
                id="startDateDay"
                name="startDateDay"
                min="{{ minStartTime }}"
                max="{{ maxStartTime }}"
                [(ngModel)]="startDateDay"
                (change)="setEndTime()"
              />
            </div>
            <div class="col-4">
              <input
                class="dark:bg-darkBlack border-2 blue-color-border rounded-md blue-color"
                type="time"
                id="startDateHour"
                name="startDateHour"
                [(ngModel)]="startDateHour"
              />
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="col-4">
              <div>
                End date and time<span
                  *ngIf="!endDateDay || !endDateHour"
                  class="text-red-500"
                  >&nbsp;*</span
                >
              </div>
            </div>
            <div class="col-4">
              <input
                class="dark:bg-darkBlack border-2 blue-color-border rounded-md blue-color"
                type="date"
                id="endDateDay"
                name="endDateDay"
                min="{{ minEndTime }}"
                max="{{ maxEndTime }}"
                [(ngModel)]="endDateDay"
              />
            </div>
            <div class="col-4">
              <input
                class="dark:bg-darkBlack border-2 blue-color-border rounded-md blue-color"
                type="time"
                id="endDateHour"
                name="endDateHour"
                [(ngModel)]="endDateHour"
              />
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="col-4">
              <div>TGE date and time</div>
            </div>
            <div class="col-4">
              <input
                class="dark:bg-darkBlack border-2 blue-color-border rounded-md blue-color"
                type="date"
                id="tgeDateDay"
                name="tgeDateDay"
                [(ngModel)]="tgeDateDay"
              />
            </div>
            <div class="col-4">
              <input
                class="dark:bg-darkBlack border-2 blue-color-border rounded-md blue-color"
                type="time"
                id="tgeDateHour"
                name="tgeDateHour"
                [(ngModel)]="tgeDateHour"
              />
            </div>
          </div>
        </div>
      </div>
      <div #ticket class="pb-md-5 dark:text-white">
        <div class="flex space-x-1 font-bold">
          <div [hidden]="isDarkMode">
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.049 1.92664C12.3483 1.00537 13.6517 1.00538 13.951 1.92664L15.919 7.98246C16.0528 8.39445 16.4368 8.67339 16.87 8.6734L23.2375 8.67365C24.2062 8.67368 24.6089 9.91323 23.8253 10.4826L18.674 14.2256C18.3235 14.4802 18.1769 14.9316 18.3107 15.3436L20.2782 21.3995C20.5775 22.3208 19.5231 23.0869 18.7393 22.5175L13.5878 18.775C13.2373 18.5204 12.7627 18.5204 12.4122 18.775L7.26065 22.5175C6.47695 23.0869 5.42252 22.3208 5.72183 21.3995L7.68928 15.3436C7.82313 14.9316 7.67648 14.4802 7.32603 14.2256L2.17472 10.4826C1.39106 9.91323 1.79381 8.67368 2.7625 8.67365L9.13004 8.6734C9.56324 8.67339 9.94717 8.39445 10.081 7.98246L12.049 1.92664Z"
                stroke="#111827"
                stroke-width="2"
              />
            </svg>
          </div>
          <div [hidden]="!isDarkMode">
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.049 1.92664C12.3483 1.00537 13.6517 1.00538 13.951 1.92664L15.919 7.98246C16.0528 8.39445 16.4368 8.67339 16.87 8.6734L23.2375 8.67365C24.2062 8.67368 24.6089 9.91323 23.8253 10.4826L18.674 14.2256C18.3235 14.4802 18.1769 14.9316 18.3107 15.3436L20.2782 21.3995C20.5775 22.3208 19.5231 23.0869 18.7393 22.5175L13.5878 18.775C13.2373 18.5204 12.7627 18.5204 12.4122 18.775L7.26065 22.5175C6.47695 23.0869 5.42252 22.3208 5.72183 21.3995L7.68928 15.3436C7.82313 14.9316 7.67648 14.4802 7.32603 14.2256L2.17472 10.4826C1.39106 9.91323 1.79381 8.67368 2.7625 8.67365L9.13004 8.6734C9.56324 8.67339 9.94717 8.39445 10.081 7.98246L12.049 1.92664Z"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </div>
          <div class="mb-4">First hours best-price</div>
        </div>
        <div class="flex items-center space-x-1 mb-3">
          <div>
            <input
              [(ngModel)]="isFirstHourActive"
              id="keep"
              name="keep"
              type="checkbox"
            />
          </div>
          <div class="text-sm blue-color">
            Keep price fixed for users who invest in first hours.
          </div>
        </div>
        <div
          class="p-4 shadow-md rounded-md text-xs-2 flex justify-content-between"
        >
          <div class="justify-center">
            <div>Set duration of first hours best price</div>
            <div class="flex mt-2 space-x-2 items-center">
              <button
                (click)="changeFirstHours('decrease')"
                class="p-2 px-3 rounded-md text-white blue-color-bg"
                [ngClass]="{
                  'bg-gray-200 pointer-events-none cursor-default text-gray-300 ':
                    firstHours <= 0 || !isFirstHourActive
                }"
              >
                -
              </button>
              <div class="p-2 px-3 rounded-md dark:text-black bg-blue-100">
                {{ firstHours }}&nbsp;H
              </div>
              <button
                (click)="changeFirstHours('increase')"
                class="p-2 px-3 rounded-md text-white blue-color-bg"
                [ngClass]="{
                  'bg-gray-200 pointer-events-none cursor-default text-gray-300 ':
                    firstHours >= 24 || !isFirstHourActive
                }"
              >
                +
              </button>
            </div>
          </div>
          <div class="justify-center">
            <div>Define number of tickets</div>
            <div class="flex mt-2 space-x-2 items-center">
              <button
                (click)="changeTicketNumbers('decrease')"
                class="p-2 px-3 text-white rounded-md blue-color-bg"
                [ngClass]="{
                  'bg-gray-200 pointer-events-none cursor-default text-gray-300 ':
                    ticketNumbers <= 0 || !isFirstHourActive
                }"
              >
                -
              </button>
              <div class="p-2 px-3 rounded-md dark:text-black bg-blue-100">
                {{ ticketNumbers }}
              </div>
              <button
                (click)="changeTicketNumbers('increase')"
                class="p-2 px-3 text-white rounded-md blue-color-bg"
                [ngClass]="{
                  'bg-gray-200 pointer-events-none cursor-default text-gray-300 ':
                    ticketNumbers >= 10 || !isFirstHourActive
                }"
              >
                +
              </button>
              <button
                (click)="changeTicketNumbers('max')"
                class="p-2 px-3 rounded-md text-white blue-color-bg"
                [ngClass]="{
                  'bg-gray-200 pointer-events-none cursor-default text-gray-300 ':
                    ticketNumbers >= 10 || !isFirstHourActive
                }"
              >
                MAX
              </button>
            </div>
          </div>
        </div>
      </div>
      <div #links class="pb-md-5 dark:text-white">
        <div class="flex space-x-1 font-bold">
          <div [hidden]="isDarkMode">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.667 16.3331C12.435 17.117 13.4862 17.5587 14.5837 17.5587C15.6811 17.5587 16.7323 17.117 17.5003 16.3331L22.167 11.6665C23.7778 10.0556 23.7778 7.44395 22.167 5.83312C20.5562 4.22229 17.9445 4.22229 16.3337 5.83312L15.7503 6.41646"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.3331 11.667C15.5651 10.8831 14.5139 10.4414 13.4165 10.4414C12.319 10.4414 11.2678 10.8831 10.4998 11.667L5.83312 16.3337C4.22229 17.9445 4.22229 20.5562 5.83312 22.167C7.44395 23.7778 10.0556 23.7778 11.6665 22.167L12.2498 21.5837"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div [hidden]="!isDarkMode">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.667 16.3331C12.435 17.117 13.4862 17.5587 14.5837 17.5587C15.6811 17.5587 16.7323 17.117 17.5003 16.3331L22.167 11.6665C23.7778 10.0556 23.7778 7.44395 22.167 5.83312C20.5562 4.22229 17.9445 4.22229 16.3337 5.83312L15.7503 6.41646"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.3331 11.667C15.5651 10.8831 14.5139 10.4414 13.4165 10.4414C12.319 10.4414 11.2678 10.8831 10.4998 11.667L5.83312 16.3337C4.22229 17.9445 4.22229 20.5562 5.83312 22.167C7.44395 23.7778 10.0556 23.7778 11.6665 22.167L12.2498 21.5837"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="mb-4">Links</div>
        </div>
        <div class="mb-3">
          <div class="text-sm">Add your links to for your users.</div>
        </div>
        <div class="p-4 shadow-md space-y-2 rounded-md text-xs-2 blue-color">
          <div class="col-12 flex items-end space-x-3">
            <div class="col-3">
              <div>Link for</div>
              <div
                class="border-2 p-2 flex items-center space-x-1 rounded-md blue-color-border w-full"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="9"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <ellipse
                    cx="12"
                    cy="12"
                    rx="4"
                    ry="9"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <ellipse
                    cx="12"
                    cy="12"
                    rx="4"
                    ry="9"
                    transform="rotate(90 12 12)"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="font-openSans-semiBold">Website</div>
              </div>
            </div>
            <div class="col-7">
              <div>
                URL<span *ngIf="!websiteUrl" class="text-red-500">&nbsp;*</span>
              </div>
              <input
                [(ngModel)]="websiteUrl"
                class="border-2 p-2 focus:outline-none rounded-md dark:bg-darkBlack blue-color-border w-full"
              />
            </div>
          </div>
          <div class="col-12 flex items-end space-x-3">
            <div class="col-3">
              <div>Link for</div>
              <div
                class="border-2 p-2 flex items-center space-x-1 rounded-md blue-color-border w-full"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="4"
                    y="4"
                    width="16"
                    height="16"
                    rx="4"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="12"
                    cy="12"
                    r="3"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 7.4995V7.5005"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="font-openSans-semiBold">Instagram</div>
              </div>
            </div>
            <div class="col-7">
              <div>URL</div>
              <input
                [(ngModel)]="instagramUrl"
                class="border-2 p-2 focus:outline-none rounded-md dark:bg-darkBlack blue-color-border w-full"
              />
            </div>
          </div>
          <div class="col-12 flex items-end space-x-3">
            <div class="col-3">
              <div>Link for</div>
              <div
                class="border-2 p-2 flex items-center space-x-1 rounded-md blue-color-border w-full"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0002 8C14.6482 8 17.0282 8.826 18.6752 10.14C19.8394 9.73191 21.1273 10.2341 21.7079 11.3225C22.2886 12.411 21.9885 13.7603 21.0012 14.5C21.0012 18.09 16.9712 21 12.0012 21C7.12619 21 3.15619 18.2 3.00119 14.706L2.0012 14.5C1.0139 13.7603 0.71378 12.411 1.29446 11.3225C1.87513 10.2341 3.16298 9.73191 4.32719 10.14C5.97319 8.827 8.35319 8 11.0012 8H12.0002Z"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8L13 3L19 4"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="19"
                    cy="4"
                    r="1"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="9"
                    cy="13"
                    r="0.5"
                    fill="#0066FF"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle
                    cx="15"
                    cy="13"
                    r="0.5"
                    fill="#0066FF"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 17C10.667 17.333 11.333 17.5 12 17.5C12.667 17.5 13.333 17.333 14 17"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="font-openSans-semiBold">Reddit</div>
              </div>
            </div>
            <div class="col-7">
              <div>URL</div>
              <input
                [(ngModel)]="redditUrl"
                class="border-2 rounded-md focus:outline-none p-1 dark:bg-darkBlack blue-color-border w-full"
              />
            </div>
          </div>
          <div class="col-12 flex items-end space-x-3">
            <div class="col-3">
              <div>Link for</div>
              <div
                class="border-2 p-2 flex items-center space-x-1 rounded-md blue-color-border w-full"
              >
                <svg
                  width="22"
                  height="19"
                  viewBox="0 0 22 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21 2.01001C20 2.50001 19.02 2.69901 18 3.00001C16.879 1.73501 15.217 1.66501 13.62 2.26301C12.023 2.86101 10.977 4.32301 11 6.00001V7.00001C7.755 7.08301 4.865 5.60501 3 3.00001C3 3.00001 -1.182 10.433 7 14C5.128 15.247 3.261 16.088 1 16C4.308 17.803 7.913 18.423 11.034 17.517C14.614 16.477 17.556 13.794 18.685 9.77501C19.0218 8.55268 19.189 7.28987 19.182 6.02201C19.18 5.77301 20.692 3.25001 21 2.00901V2.01001Z"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="font-openSans-semiBold">Twitter</div>
              </div>
            </div>
            <div class="col-7">
              <div>URL</div>
              <input
                [(ngModel)]="twitterUrl"
                class="border-2 p-2 focus:outline-none rounded-md dark:bg-darkBlack blue-color-border w-full"
              />
            </div>
          </div>
          <div class="col-12 flex items-end space-x-3">
            <div class="col-3">
              <div>Link for</div>
              <div
                class="border-2 p-2 flex items-center space-x-1 rounded-md blue-color-border w-full"
              >
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H10L15 6V17C15 18.1046 14.1046 19 13 19Z"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="font-openSans-semiBold">Pitch deck</div>
              </div>
            </div>
            <div class="col-7">
              <div>URL</div>
              <input
                [(ngModel)]="pitchDeckUrl"
                class="border-2 rounded-md focus:outline-none p-1 dark:bg-darkBlack blue-color-border w-full"
              />
            </div>
          </div>
          <div class="col-12 flex items-end space-x-3">
            <div class="col-3">
              <div>Link for</div>
              <div
                class="border-2 p-2 flex items-center space-x-1 rounded-md blue-color-border w-full"
              >
                <svg
                  fill="#0066FF"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>telegram</title>
                  <path
                    d="M22.26465,2.42773a2.04837,2.04837,0,0,0-2.07813-.32421L2.26562,9.33887a2.043,2.043,0,0,0,.1045,3.81836l3.625,1.26074,2.0205,6.68164A.998.998,0,0,0,8.134,21.352c.00775.012.01868.02093.02692.03259a.98844.98844,0,0,0,.21143.21576c.02307.01758.04516.03406.06982.04968a.98592.98592,0,0,0,.31073.13611l.01184.001.00671.00287a1.02183,1.02183,0,0,0,.20215.02051c.00653,0,.01233-.00312.0188-.00324a.99255.99255,0,0,0,.30109-.05231c.02258-.00769.04193-.02056.06384-.02984a.9931.9931,0,0,0,.20429-.11456,250.75993,250.75993,0,0,1,.15222-.12818L12.416,18.499l4.03027,3.12207a2.02322,2.02322,0,0,0,1.24121.42676A2.05413,2.05413,0,0,0,19.69531,20.415L22.958,4.39844A2.02966,2.02966,0,0,0,22.26465,2.42773ZM9.37012,14.73633a.99357.99357,0,0,0-.27246.50586l-.30951,1.504-.78406-2.59307,4.06525-2.11695ZM17.67188,20.04l-4.7627-3.68945a1.00134,1.00134,0,0,0-1.35352.11914l-.86541.9552.30584-1.48645,7.083-7.083a.99975.99975,0,0,0-1.16894-1.59375L6.74487,12.55432,3.02051,11.19141,20.999,3.999Z"
                  />
                </svg>
                <div class="font-openSans-semiBold">Telegram</div>
              </div>
            </div>
            <div class="col-7">
              <div>URL</div>
              <input
                [(ngModel)]="telegramUrl"
                class="border-2 rounded-md focus:outline-none p-1 dark:bg-darkBlack blue-color-border w-full"
              />
            </div>
          </div>
          <div class="col-12 flex items-end space-x-3">
            <div class="col-3">
              <div>Link for</div>
              <div
                class="border-2 p-2 flex items-center space-x-1 rounded-md blue-color-border w-full"
              >
                <svg
                  fill="#0066FF"
                  height="24px"
                  width="24px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 49 49"
                  xml:space="preserve"
                >
                  <g>
                    <g>
                      <path
                        d="M39.256,6.5H9.744C4.371,6.5,0,10.885,0,16.274v16.451c0,5.39,4.371,9.774,9.744,9.774h29.512
			c5.373,0,9.744-4.385,9.744-9.774V16.274C49,10.885,44.629,6.5,39.256,6.5z M47,32.726c0,4.287-3.474,7.774-7.744,7.774H9.744
			C5.474,40.5,2,37.012,2,32.726V16.274C2,11.988,5.474,8.5,9.744,8.5h29.512c4.27,0,7.744,3.488,7.744,7.774V32.726z"
                      />
                      <path
                        d="M33.36,24.138l-13.855-8.115c-0.308-0.18-0.691-0.183-1.002-0.005S18,16.527,18,16.886v16.229
			c0,0.358,0.192,0.69,0.502,0.868c0.154,0.088,0.326,0.132,0.498,0.132c0.175,0,0.349-0.046,0.505-0.137l13.855-8.113
			c0.306-0.179,0.495-0.508,0.495-0.863S33.667,24.317,33.36,24.138z M20,31.37V18.63l10.876,6.371L20,31.37z"
                      />
                    </g>
                  </g>
                </svg>
                <div class="font-openSans-semiBold">Youtube</div>
              </div>
            </div>
            <div class="col-7">
              <div>URL</div>
              <input
                [(ngModel)]="youtubeUrl"
                class="border-2 rounded-md focus:outline-none p-1 dark:bg-darkBlack blue-color-border w-full"
              />
            </div>
          </div>
          <div class="col-12 flex items-end space-x-3">
            <div class="col-3">
              <div>Link for</div>
              <div
                class="border-2 p-2 flex items-center space-x-1 rounded-md blue-color-border w-full"
              >
                <svg
                  fill="#0066FF"
                  width="24px"
                  height="24px"
                  viewBox="-2 -2 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMinYMin"
                  class="jam jam-linkedin-square"
                >
                  <path
                    d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457-.592 0-.945.398-1.1.784-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066 1.41 0 2.468.922 2.468 2.902zM6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115-.014-.632-.465-1.114-1.199-1.114zm-1.086 9.556h2.144V8.38H5.127v6.447z"
                  />
                  <path
                    d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                  />
                </svg>
                <div class="font-openSans-semiBold">LinkedIn</div>
              </div>
            </div>
            <div class="col-7">
              <div>URL</div>
              <input
                [(ngModel)]="linkedInUrl"
                class="border-2 rounded-md focus:outline-none p-1 dark:bg-darkBlack blue-color-border w-full"
              />
            </div>
          </div>
          <div class="col-12 flex items-end space-x-3">
            <div class="col-3">
              <div>Link for</div>
              <div
                class="border-2 p-2 flex items-center space-x-1 rounded-md blue-color-border w-full"
              >
                <svg
                  fill="#0066FF"
                  width="24px"
                  height="24px"
                  viewBox="0 0 256 256"
                  id="Flat"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M70,56C33.60742,56,4,88.29885,4,128s29.60742,72,66,72,66-32.29883,66-72S106.39258,56,70,56Zm0,120c-23.15918,0-42-21.5332-42-48S46.84082,80,70,80s42,21.5332,42,48S93.15918,176,70,176ZM182,60c-23.36035,0-34,35.249-34,68s10.63965,68,34,68,34-35.249,34-68S205.36035,60,182,60Zm0,111.26367c-3.65674-4.06347-10-18.89746-10-43.26367s6.34326-39.20019,10-43.26367c3.65674,4.06348,10,18.89746,10,43.26367S185.65674,167.20022,182,171.26369ZM252,72V184a12,12,0,0,1-24,0V72a12,12,0,1,1,24,0Z"
                  />
                </svg>
                <div class="font-openSans-semiBold">Medium</div>
              </div>
            </div>
            <div class="col-7">
              <div>URL</div>
              <input
                [(ngModel)]="mediumUrl"
                class="border-2 rounded-md focus:outline-none p-1 dark:bg-darkBlack blue-color-border w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div #rules class="pb-md-5 dark:text-white">
        <div class="flex space-x-1 font-bold">
          <div [hidden]="isDarkMode">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.2963 12.8343L15.1663 8.70427L16.8113 7.05927L19.2847 9.5326L24.2313 4.58594L25.8763 6.23094L19.2963 12.8343ZM12.833 8.1676H2.33301V10.5009H12.833V8.1676ZM24.4997 15.6459L22.8547 14.0009L19.833 17.0226L16.8113 14.0009L15.1663 15.6459L18.188 18.6676L15.1663 21.6893L16.8113 23.3343L19.833 20.3126L22.8547 23.3343L24.4997 21.6893L21.478 18.6676L24.4997 15.6459ZM12.833 17.5009H2.33301V19.8343H12.833V17.5009Z"
                fill="#323232"
              />
            </svg>
          </div>
          <div [hidden]="!isDarkMode">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.2963 12.8343L15.1663 8.70427L16.8113 7.05927L19.2847 9.5326L24.2313 4.58594L25.8763 6.23094L19.2963 12.8343ZM12.833 8.1676H2.33301V10.5009H12.833V8.1676ZM24.4997 15.6459L22.8547 14.0009L19.833 17.0226L16.8113 14.0009L15.1663 15.6459L18.188 18.6676L15.1663 21.6893L16.8113 23.3343L19.833 20.3126L22.8547 23.3343L24.4997 21.6893L21.478 18.6676L24.4997 15.6459ZM12.833 17.5009H2.33301V19.8343H12.833V17.5009Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="mb-4">Rules</div>
        </div>
        <div class="mb-3">
          <div class="text-sm">Please pay attention to these information</div>
        </div>
        <div class="space-y-3">
          <div
            *ngIf="hardCap < 500000"
            class="rounded-md p-2 rules-1 rounded-md w-full"
          >
            <div class="flex space-x-1">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.7 3H15.3C15.6 3 15.8 3.1 16 3.3L20.7 8C20.9 8.2 21 8.4 21 8.7V15.3C21 15.6 20.9 15.8 20.7 16L16 20.7C15.8 20.9 15.6 21 15.3 21H8.7C8.4 21 8.2 20.9 8 20.7L3.3 16C3.1 15.8 3 15.6 3 15.3V8.7C3 8.4 3.1 8.2 3.3 8L8 3.3C8.2 3.1 8.4 3 8.7 3Z"
                    stroke="#FF103B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8V12"
                    stroke="#FF103B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.9999 16H12.0099"
                    stroke="#FF103B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="red-color">Attention</div>
            </div>
            <div class="red-color px-3">
              We will keep 10% of the funding in reserve to make sure that
              punishment can be executed on the project if there are latencies
              in sending the project tokens at the right time. We might have
              exceptions to this rule based on the volume of the sale above 500K
              funding result.
            </div>
          </div>
          <div class="rounded-md p-2 rules-2 rounded-md w-full">
            <div class="flex space-x-1">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="9"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.9999 8H12.0099"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 12H12V16H13"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="blue-color">Fees/commission</div>
            </div>
            <div class="blue-color px-3">
              The standard fee for the operator of the sale is 10% from the
              funding result. Feel free to contact with the sales team at
              sales@crowdswap.org to discuss different solutions.
            </div>
          </div>
          <div class="rounded-md p-2 rules-2 rounded-md w-full">
            <div class="flex space-x-1">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="9"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.9999 8H12.0099"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11 12H12V16H13"
                    stroke="#0066FF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="blue-color">Chain</div>
            </div>
            <div class="blue-color px-3">
              The Crowd Sale needs to be created on the chain that the sale will
              take place and the project token will launch.
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <div class="flex col-12 pb-2">
          <div class="font-openSans-semiBold text-xl ml-2 dark:text-darkGrayText">
            <span>CrowdSale Terms of service</span>
          </div>
        </div>
        <div class="flex col-12 py-2">
          <textarea
            class="flex items-center w-full border-2 border-gray-300 rounded-md text-base px-2 h-12 hover:border-gray-300 dark:border-darkBorder"
            readonly
            >{{ termsOfService }}</textarea
          >
        </div>
        <div class="flex flex-col mt-2">
          <div class="flex flex-col mt-4 mb-3">
            <button
              *ngIf="!didSign"
              (click)="sign()"
              class="blue-button font-openSans-medium py-2 px-4 rounded-md h-52 flex justify-center text-white text-sm items-center text-center hover:bg-blue-700"
            >
              <span>Sign</span>
            </button>
            <button
              *ngIf="didSign"
              class="text-green-500 bg-green-200 cursor-default rounded-md p-2 text-sm"
            >
              Sign approved
            </button>
          </div>
        </div>
      </div>

      <div class="flex items-center space-x-1 mb-3">
        <div><input [(ngModel)]="isReadAndAccept" type="checkbox" /></div>
        <div class="text-sm blue-color">
          I’ve read the rules and accept them
        </div>
      </div>
      <div class="flex justify-content-between">
        <button
          (click)="createSale()"
          class="py-1 text-white rounded-md px-2"
          [ngClass]="
            !isReadAndAccept
              ? 'pointer-events-none cursor-default bg-gray-400'
              : 'blue-color-bg'
          "
        >
          Create sale
        </button>
        <div>
          <button (click)="clearSale()" class="py-1 blue-color rounded-md px-2">
            Clear
          </button>
          <button (click)="saveAs()" class="py-1 blue-color rounded-md px-2">
            Save as
          </button>
        </div>
      </div>
      <div *ngIf="isRequired" class="flex justify-content-between bottom-space">
        <div class="text-red-600">Please complete all required fields</div>
      </div>
      <div *ngIf="isNotValid" class="flex justify-content-between bottom-space">
        <div class="text-red-600">
          Please check data again. Something went wrong.
        </div>
      </div>
    </div>
  </div>
</div>
