import {
  Component,
  Renderer2,
  Inject,
  OnInit,
  ElementRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ThemeService } from '../../../services/theme.service';

declare const particlesJS;

@Component({
  selector: '[app-christmas]',
  template: '<div class="flex" id="christmas" #christmas></div>',
  styleUrls: ['./christmas.component.scss']
})
export class ChristmasComponent {
  private currentTime;
  private startTimestamp = 1671753600; //start 23th Dec 2022 at 00:00
  private endTimestamp = 1672704000; //start 3th Jan 2023 at 00:00

  @ViewChild('christmas')
  private christmasElement;

  constructor(
    private renderer2: Renderer2,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngAfterViewInit() {
    this.currentTime = Math.floor(Date.now() / 1000);
    if (
      this.currentTime < this.startTimestamp ||
      this.currentTime > this.endTimestamp
    ) {
      return;
    }
    this.snowEffect();
    this.changeLogo();
    this.themeService.darkModeChangeSubject.next(true);
  }

  private snowEffect() {
    this.christmasElement.nativeElement.classList.add('christmas');
    this.christmasElement.nativeElement.classList.add('desktop-background');
    this.christmasElement.nativeElement.classList.add('mobile-background');
    const script = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js';
    script.text = ``;
    script.onload = this.onParticlesLoaded;
    this.renderer2.appendChild(this.christmasElement.nativeElement, script);
  }

  private changeLogo() {
    const logo: any = this.document.getElementById('logo-image');
    logo.src = '/assets/media/logo/logo-christmas.svg';
    logo.width = 150;

    const logoWithoutTitle: any = this.document.getElementById(
      'logo-without-title-image'
    );
    logoWithoutTitle.src =
      '/assets/media/logo/Logo-without-title-christmas.svg';
    logoWithoutTitle.width = 100;
  }

  private onParticlesLoaded = () => {
    (<any>particlesJS)('christmas', {
      particles: {
        number: {
          value: 100,
          density: {
            enable: true,
            value_area: 800
          }
        },
        color: {
          value: '#e3e5e8'
        },
        opacity: {
          value: 0.7,
          random: false,
          anim: {
            enable: false
          }
        },
        size: {
          value: 5,
          random: true,
          anim: {
            enable: false
          }
        },
        line_linked: {
          enable: false
        },
        move: {
          enable: true,
          speed: 2,
          direction: 'bottom',
          random: true,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: true,
            rotateX: 300,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        events: {
          onhover: {
            enable: false
          },
          onclick: {
            enable: false
          },
          resize: true
        }
      },
      retina_detect: true
    });
  };
}
