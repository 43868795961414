import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Networks, TokensHolder } from '@crowdswap/constant';
import { DialogsComponent } from '../dialogs.component';
import { Web3Service } from '../../../../services/web3.service';
import { ModalModule } from '../../modal.module';

@Component({
  selector: 'app-transfer-successful-dialog',
  templateUrl: './transfer-successful-dialog.component.html',
  styleUrls: ['./transfer-successful-dialog.component.scss']
})
export class TransferSuccessfulDialogComponent extends DialogsComponent {
  constructor() {
    super();
  }
}

@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [TransferSuccessfulDialogComponent]
})
export class TransferSuccessfulDialogComponentModule {}
