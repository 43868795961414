<app-dialog>
  <div class="absolute right-5 mr-1 dark:text-textWhite">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 h-5 cursor-pointer"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      (click)="close()"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  </div>
  <div>
    <div class="flex flex-col items-center mt-6">
      <div class="text-center my-4">
        <label class="title text-lg font-openSans-semiBold dark:text-white">
          {{
            !data?.stakeState
              ? "Want to Unstake?"
              : !data?.amountOut
              ? "Do you really want to withdraw your reward?"
              : "Do you really want to remove your liquidity from " +
                data?.activeOpportunity.displayName +
                "?"
          }}
        </label>
      </div>
      <div
        *ngIf="!data?.stakeState"
        class="flex flex-col items-center opacity-50"
      >
        <div class="z-10 flex items-center font-openSans-regular dark:text-white">
          You can wait for your next reward,
        </div>
        <div class="z-10 flex font-openSans-regular dark:text-white">
          or unstake now.
        </div>
      </div>
    </div>
    <div>
      <div class="flex justify-center items-center flex-col mt-2 py-3">
        <div
          class="rounded-md px-4 justify-center items-center w-full py-3 bg-black bg-opacity-5 dark:bg-unStakeBackground"
        >
          <div
            *ngIf="!data?.stakeState && (this.timeLeft$ | async) as t"
            class="opacity-50 flex justify-center font-openSans-regular dark:text-white"
          >
            {{
              t.minutesToNextReward +
                "min " +
                t.secondsToNextReward +
                "s to receive"
            }}
          </div>
          <div
            *ngIf="data?.stakeState"
            class="opacity-50 flex justify-center font-openSans-regular dark:text-white"
          >
            you withdraw
            <!--                you {{data?.nextReward && data?.amountOut ? 'unstake and withdraw' : data?.nextReward ? 'withdraw' : 'unstake'}}-->
          </div>
          <div
            *ngIf="data?.amountOut"
            class="flex justify-center font-openSans-semiBold dark:text-white"
          >
            {{ (+data?.amountOut!).toFixed(10) }}
            {{ data?.sourceBaseTokenSymbol }}
          </div>
          <!--          <div-->
          <!--            *ngIf="data?.amountOut"-->
          <!--            class="flex justify-center font-openSans-semiBold dark:text-white"-->
          <!--          >-->
          <!--            ${{ data?.minAmountOut }}-->
          <!--          </div>-->
          <div
            *ngIf="data?.nextReward"
            class="flex justify-center font-openSans-semiBold dark:text-white"
          >
            Rewards: {{ data?.nextReward }} {{ data?.tokenSymbol }}
          </div>
        </div>
      </div>
      <div class="flex flex-col mt-3 mb-3">
        <button
          (click)="close()"
          class="py-2 px-4 rounded-md h-52 flex justify-center blue-button text-white text-sm font-openSans-semiBold items-center text-center hover:bg-blue-700"
        >
          <span>{{
            !data?.stakeState ? "Wait for next reward" : "Cancel"
          }}</span>
        </button>
      </div>
      <div class="flex flex-col mt-3 mb-3">
        <button
          (click)="unStake()"
          class="py-2 px-4 rounded-md h-52 flex justify-center font-openSans-semiBold text-sm items-center border border-grayText text-center dark:border dark:border-darkGrayText hover:bg-gray-200 dark:hover:bg-unStakeBackground"
        >
          <span class="dark:text-white">
            Withdraw
            <!--              {{data?.nextReward && data?.amountOut ? 'Unstake and withdraw' : data?.nextReward ? 'Withdraw' : 'Unstake'}}-->
          </span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
