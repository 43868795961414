import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UnstakeDialogComponent } from '../../modal/dialogs/unstake-dialog/unstake-dialog.component';
import { ModalService } from '../../modal/modal.service';
import {
  asyncScheduler,
  interval,
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import { SuccessfulUnStakeDialogComponent } from '../../modal/dialogs/successful-unstake/successful-unstake-dialog.component';
import {
  commonConfig,
  Conversion,
  CrowdToken,
  OpportunitiesHolder,
  PriceService
} from '@crowdswap/constant';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  throttleTime
} from 'rxjs/operators';
import { WaitingDialogComponent } from '../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CurrentNetwork } from '../../../model';
import { BaseComponent } from '../base.component';
import { Router } from '@angular/router';
import {
  NDDClientInfoServiceImpl,
  OpportunityService,
  StakeService,
  TagManagerService,
  ThemeService,
  TokensService,
  UtilsService,
  Web3Service
} from '../../../services';

export enum StakingButtonState {
  Init,
  ApprovalNeeded,
  ApprovalConfirmed,
  WalletNotConnected
}

@Component({
  selector: 'app-stake',
  templateUrl: './stake.component.html',
  styleUrls: ['./stake.component.scss']
})
export class StakeComponent extends BaseComponent implements OnInit, OnDestroy {
  StakingButtonState = StakingButtonState;
  public static LIMITED_INTERVAL_TIME =
    commonConfig.STAKE_UNSTAKE.LIMITED_INTERVAL_TIME;
  public isSelected = false;
  public token!: CrowdToken;
  public stakedBalance = 0;
  public stakeAmount = 1000;
  public nextReward = 0;
  public stakeTime = 0;
  public stakeReward = 0;
  public apyString!: string;
  public minimumStakedBalance = 1;
  public stakeState = 'staked';
  public _stakeMode: boolean = true;
  private existHolder;

  subscriptionList: Subscription[] = [];

  _opportunity;
  @Input()
  set opportunity(opportunity: any) {
    this._opportunity = opportunity;
  }

  @Output()
  updateDetails = new EventEmitter();

  get opportunity() {
    return this._opportunity;
  }

  @ViewChild('stakeAmountInput') stakeAmountInput!: ElementRef;

  public timeLeft$:
    | Observable<{
        minutesToNextReward: number;
        secondsToNextReward: number;
      }>
    | undefined = undefined;
  public stakeButtonState = StakingButtonState.Init;
  public amountNotifier = new Subject();
  public loading: boolean = false;
  private inUseProvider: any;
  private opportunities;

  constructor(
    public stakeService: StakeService,
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    private router: Router,
    protected themeService: ThemeService,
    private toastr: ToastrService,
    private unStakeDialogService: ModalService<UnstakeDialogComponent>,
    private successfulUnStakeDialogService: ModalService<SuccessfulUnStakeDialogComponent>,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private priceService: PriceService,
    protected tagManagerService: TagManagerService,
    private tokensService: TokensService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    // this.tagManagerService.sendStartViewTag('stake');
    this.opportunities = OpportunitiesHolder.Opportunities;

    this.apyString = await this._getApy();

    this.token = this._opportunity.tokenA;
    this.stakeButtonState = this.web3Service.isConnected()
      ? StakingButtonState.Init
      : StakingButtonState.WalletNotConnected;

    // On wallet connect/disconnect
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe((connection) => {
        this.stakeButtonState = connection
          ? StakingButtonState.Init
          : StakingButtonState.WalletNotConnected;
        this.token.balance = '0';
        this.stakedBalance = 0;
        this.setZero();
        this.checkAllowance();
        this.ref.detectChanges();
      })
    );
    // Todo we can remove throttleTime here
    // On network change
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          const chainId: number = currentNetwork.chainId;
          this.setProvider(chainId);

          await this.refreshStakeData();
          await this.checkAllowance();
          this.ref.detectChanges();
        })
    );
    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        this.token.balance = '0';
        this.stakedBalance = 0;
        this.setZero();
        await this.refreshStakeData();
        await this.checkAllowance();
      })
    );

    this.setProvider(this.web3Service.getCurrentChainId());
    await this.refreshStakeData();
    this.timeLeft$ = interval(1000).pipe(
      map((x) => {
        const { secondsToNextReward, minutesToNextReward } =
          UtilsService.nextRewardTime(this.stakeTime);
        if (secondsToNextReward === 0 && minutesToNextReward === 0) {
          this.refreshStakeData();
          this.ref.detectChanges();
        }

        return { secondsToNextReward, minutesToNextReward };
      }),
      shareReplay(1)
    );

    this.amountNotifier
      .pipe(
        debounceTime(OpportunityService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async () => {
        this.loading = true;
        await this.checkAllowance();
        this.loading = false;
      });
  }

  private setProvider(chainId: number) {
    this.inUseProvider =
      this._opportunity.chainId === chainId
        ? this.web3Service.web3Provider
        : this.web3Service.getNetworkProvider(this._opportunity.chainId);
  }

  private async _getApy() {
    try {
      return await this.stakeService.getApy(this._opportunity);
    } catch (err) {
      console.error(`Error message: ${err}`);
      return '-';
    }
  }

  public async switchStakeUnStake(mode: string) {
    // await this.tagManager(mode + '_select');

    switch (mode) {
      case 'stake': {
        this._stakeMode = true;
        this.token.balance = await this.tokensService.getTokenBalance(
          this.token
        );
        break;
      }
      case 'unStake': {
        this._stakeMode = false;
        this.stakedBalance = await this.getStakedTokenBalance();
        break;
      }
    }
    this.ref.detectChanges();
  }

  setZero() {
    this.stakeReward = 0;
    this.stakeTime = 0;
    this.nextReward = 0;
  }

  private async refreshStakeData() {
    try {
      this.existHolder = await this.stakeService.getStakeHolder(
        this._opportunity,
        this.web3Service.getWalletAddress(),
        this.inUseProvider
      );
      if (this.existHolder) {
        this.stakedBalance = await this.getStakedTokenBalance();
        if (this.stakedBalance >= this.minimumStakedBalance) {
          await this.getStakeReward();
          await this.getStakeTime();
          await this.getNextReward();
        } else {
          this.setZero();
        }
      } else {
        this.stakedBalance = 0;
        this.setZero();
      }
      this.token.balance = await this.tokensService.getTokenBalance(this.token);
      this.ref.detectChanges();
    } catch (e) {
      console.log(e);
    }
  }

  public async getNextReward() {
    try {
      let nextReward = await this.stakeService.getNextHourReward(
        this._opportunity,
        this.inUseProvider
      );
      let stakedBalance = await this.stakeService.getStakedBalance(
        this._opportunity,
        this.web3Service.getWalletAddress(),
        this.inUseProvider
      );

      if (stakedBalance) {
        nextReward = +Conversion.convertStringFromDecimal(
          nextReward.toString()
        );
        let convertedStakedBalance = +Conversion.convertStringFromDecimal(
          stakedBalance.toString()
        );
        if (convertedStakedBalance < this.minimumStakedBalance) {
          this.nextReward = 0;
        } else {
          this.nextReward = +Conversion.adjustFraction(
            nextReward * convertedStakedBalance,
            6
          );
        }
      }

      return this.nextReward;
    } catch (e) {
      console.log(e);
    }
  }

  public async getStakeReward() {
    try {
      let stakeReward = await this.stakeService.getStakeReward(
        this._opportunity,
        this.web3Service.getWalletAddress(),
        this.inUseProvider
      );
      if (stakeReward) {
        this.stakeReward = +Conversion.adjustFraction(
          Conversion.convertStringFromDecimal(stakeReward.toString(), 18),
          6
        );
      }
      return this.stakeReward;
    } catch (e) {
      console.log(e);
    }
  }

  public async getStakeTime() {
    try {
      let stakeTime = await this.stakeService.getStakeTime(
        this._opportunity,
        this.web3Service.getWalletAddress()
      );
      this.stakeTime = +stakeTime.toString();
      return this.stakeTime;
    } catch (e) {
      console.log(e);
    }
  }

  async changeAmount(amount: number) {
    this.stakeAmount = +Conversion.adjustFraction(amount, 5);
  }

  public async setMaxBalance(mode) {
    switch (mode) {
      case true: {
        await this.changeAmount(+this.token.balance);
        break;
      }
      case false: {
        await this.changeAmount(this.stakedBalance);
        break;
      }
    }
  }

  public async stake() {
    try {
      this.stakeState = 'staked';
      if (this.web3Service.getCurrentChainId() != this._opportunity.chainId) {
        await this.web3Service.changeNetwork(this._opportunity.chainId);
      }
      this.tagManager('stake_start');
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        await this.showWaitingDialog('Stak'); //Stak is sending to dialog to show correct message
        await this.tagManager('stake_confirmation_view');

        // Get Withdraw tx
        let amount = Conversion.convertStringToDecimal(
          this.stakeAmount.toString(),
          18
        ).toString();
        const stakeTx = await this.stakeService.getStakeTransaction(
          this._opportunity,
          amount
        );
        const gasPrice = await this.web3Service.getGasPrice();
        const estimatedGasLimit = await this.stakeService.getEstimateGas(
          this._opportunity,
          amount,
          userAddress,
          'stake'
        );

        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction({
            ...stakeTx,
            ...(estimatedGasLimit
              ? {
                  gas: estimatedGasLimit.toHexString(),
                  gasLimit: estimatedGasLimit.toHexString()
                }
              : {})
          })
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(
              e +
                `StakeComponent-stake-first: staked unsuccessfully.now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
            );
            console.log(e);
          });
        if (currentTransaction) {
          this.tagManager('stake_confirmation_confirm');

          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                this.updateOppDetails();
                this.tagManager('stake_done', 'success');
              }
              if (data?.status === 0) {
                await this.waitingDialogService.close();
                await this.tagManager('stake_error', 'something went wrong');
                this.showErrorToaster('Error', 'Staking rejected!');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          await this.tagManager('stake_confirmation_reject');
          this.showErrorToaster('Error', 'Staking rejected!');
          console.log('Staking rejected!');
          return;
        }
        this.limitedInterval(
          `StakeComponent-stake: before staked successfully with value = ${amount} and now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
        );
      }
    } catch (e) {
      console.log(e);
      await this.tagManager('stake_error', 'something went wrong');
      console.error(
        e +
          `StakeComponent-stake-second: staked unsuccessfully.now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
      );
      await this.waitingDialogService.close();
    }
  }

  public async unStake() {
    try {
      if (this.web3Service.getCurrentChainId() != this._opportunity.chainId) {
        await this.web3Service.changeNetwork(this._opportunity.chainId);
      }
      this.tagManager('unstake_start');
      const data = {
        nextReward: this.nextReward,
        stakingTime: this.stakeTime,
        stakeAmount: this.stakeAmount,
        isDarkMode: this.isDarkMode,
        tokenSymbol: this._opportunity.tokenA.symbol
      };
      const unStakeDialogRef = await this.unStakeDialogService.open(
        UnstakeDialogComponent,
        data
      );
      // await this.tagManager('unstake_view_withdraw');
      const sub: Subscription = await (<UnstakeDialogComponent>(
        (<any>unStakeDialogRef!.instance)
      )).confirmed.subscribe(async (event) => {
        await this.unStakeDialogService.close();
        await this.tagManager('unstake_start_withdraw');
        if (event) {
          await this.confirmUnStake();
        } else if (!event) {
          this.ref.detectChanges();
        }
      });
      unStakeDialogRef!.onDestroy(() => {
        sub.unsubscribe();
      });
    } catch (e) {
      console.error(
        e +
          `StakeComponent-unstak: unstaked unsuccessfully.now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
      );
      console.log(e);
      await this.unStakeDialogService.close();
    }
  }

  private async getStakedTokenBalance() {
    try {
      let stakedBalance = await this.stakeService.getStakedBalance(
        this._opportunity,
        this.web3Service.getWalletAddress(),
        this.inUseProvider
      );
      if (stakedBalance) {
        return +Conversion.convertStringFromDecimal(
          stakedBalance.toString(),
          18
        );
      }
    } catch (e) {
      console.error(
        e +
          `StakeComponent-getStakedTokenBalance: stakedBalance = ${this.stakedBalance} and now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
      );
      console.log(e);
    }
    return 0;
  }

  private async confirmUnStake(): Promise<any> {
    this.stakeState = 'unstaked';
    try {
      await this.tagManager('unstake_confirmation_view');
      await this.showWaitingDialog('Unstak'); //Unstak is sending to dialog to show correct message

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        let amount = Conversion.convertStringToDecimal(
          this.stakeAmount.toString(),
          18
        ).toString();
        const unStakeTx = await this.stakeService.getWithdrawTransaction(
          this._opportunity,
          amount
        );

        const gasPrice = await this.web3Service.getGasPrice();
        const estimatedGasLimit = await this.stakeService.getEstimateGas(
          this._opportunity,
          amount,
          userAddress,
          'unstake'
        );

        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction({
            ...unStakeTx,
            ...(estimatedGasLimit
              ? {
                  gas: estimatedGasLimit.toHexString(),
                  gasLimit: estimatedGasLimit.toHexString()
                }
              : {})
          })
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(
              e +
                `StakeComponent-confirmunStake-first: unstaked unsuccessfully and now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
            );
            console.log(e);
          });
        if (currentTransaction) {
          this.tagManager('unstake_confirmation_confirm');
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                this.updateOppDetails();
                this.tagManager('unstake_done', 'success');
              }
              if (data?.status === 0) {
                await this.waitingDialogService.close();
                await this.tagManager('unstake_error', 'something went wrong');
                this.showErrorToaster('Error', 'Unstaking rejected!');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          await this.tagManager('unstake_confirmation_reject');
          this.showErrorToaster('Error', 'Unstaking rejected!');
          return;
        }
        this.limitedInterval(
          `StakeComponent-unstake: before unstaked successfully with value = ${amount} and now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
        );
      }
    } catch (e) {
      console.log(e);
      await this.tagManager('unstake_error', 'something went wrong');
      console.error(
        e +
          `StakeComponent-confirmunstake-second: unstaked unsuccessfully.now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
      );
      await this.waitingDialogService.close();
    }
  }

  private limitedInterval(msg: string = '') {
    let timesRun = 0;
    let limitedInterval = setInterval(async () => {
      timesRun += 1;
      if (timesRun <= StakeComponent.LIMITED_INTERVAL_TIME) {
        if (this.stakedBalance !== (await this.getStakedTokenBalance())) {
          clearInterval(limitedInterval);
          await this.refreshStakeData();
          await this.waitingDialogService.close();
          await this.showSuccessfulDialog();
          this.web3Service.assetChangeSubject.next(true);
          console.info(msg);
        }
      } else {
        clearInterval(limitedInterval);
        await this.waitingDialogService.close();
        this.showErrorToaster(
          'Error',
          'Please follow the result at https://polygonscan.com '
        );
      }
    }, 1000);
  }

  private async showSuccessfulDialog() {
    const data = {
      stakeAmount: this.stakeAmount,
      stakeState: this.stakeState,
      isDarkMode: this.isDarkMode,
      tokenSymbol: this._opportunity.tokenA.symbol,
      withdrawTokensIcon: [this._opportunity.rewardToken.symbol]
    };
    this.successfulUnStakeDialogService
      .open(SuccessfulUnStakeDialogComponent, data)
      .then(async () => {
        await this.ref.detectChanges();
      });
  }

  private async checkAllowance(): Promise<any> {
    try {
      const userAddress = await this.web3Service.getWalletAddress();

      if (userAddress) {
        const allowance = await this.web3Service
          .getAllowanceBySpender(
            this._opportunity.contractAddress,
            this.token.address,
            userAddress,
            this.inUseProvider
          )
          .catch((e) => {
            console.log(e);
            this.changeStakingState(StakingButtonState.ApprovalNeeded);
            return;
          });

        const amountInValue = Conversion.convertStringToDecimal(
          this.stakeAmount.toString(),
          18
        );

        if (allowance.lt(amountInValue)) {
          await this.changeStakingState(StakingButtonState.ApprovalNeeded);
        } else {
          await this.changeStakingState(StakingButtonState.ApprovalConfirmed);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.ref.detectChanges();
    }
  }

  private isStakingState(stakingState: StakingButtonState) {
    return this.stakeButtonState == stakingState;
  }

  private async changeStakingState(stakingState: StakingButtonState) {
    if (this.isStakingState(StakingButtonState.WalletNotConnected)) {
      await this.refreshStakeData();
      return;
    }

    this.stakeButtonState = stakingState;
  }

  async approve(): Promise<any> {
    let readyToApprove = true;
    try {
      if (this.web3Service.getCurrentChainId() != this._opportunity.chainId) {
        await this.web3Service.changeNetwork(this._opportunity.chainId);
      }
      // this.tagManager('stake_allow');
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        const data = {
          sourceTokenSymbol: this._opportunity.tokenA.symbol
        };
        await this.waitingDialogService.open(WaitingDialogComponent, data);

        // Get approval tx
        let approvalTx = await this.web3Service.getApprovalTransactionBySpender(
          this._opportunity.contractAddress,
          this.token.address,
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        );
        // Send approval tx
        approvalTx = await this.web3Service
          .sendTransaction(approvalTx)
          .catch((e) => {
            console.log(e);
            this.changeStakingState(StakingButtonState.ApprovalNeeded);
            readyToApprove = false;
          });
        if (!approvalTx) {
          await this.changeStakingState(StakingButtonState.ApprovalNeeded);
          return false;
        }
        // Wait for approval tx confirmation
        await this.web3Service
          .waitForTransaction(approvalTx, 1)
          .then((data) => {
            if (data?.status === 1) {
              this.changeStakingState(StakingButtonState.ApprovalConfirmed);
              this.checkAllowance();
            } else {
              this.changeStakingState(StakingButtonState.ApprovalNeeded);
            }
          })
          .catch((e) => {
            console.log(e);
            this.changeStakingState(StakingButtonState.ApprovalNeeded);
            readyToApprove = false;
          });
      }
    } catch (e) {
      console.log(e);
      this.changeStakingState(StakingButtonState.ApprovalNeeded);
      readyToApprove = false;
    } finally {
      await this.waitingDialogService.close();
    }
    return readyToApprove;
  }

  private async showWaitingDialog(dialogType) {
    const data = {
      stakeState: dialogType,
      isDarkMode: this.isDarkMode,
      tokenSymbol: this._opportunity.tokenA.symbol
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  private async tagManager(event: any, status: string = '') {
    const amount =
      parseFloat(
        this.token.price !== '0'
          ? this.token.price
          : await this.priceService.getPrice(
              this.token,
              this.web3Service.getNetworkProvider(this.token.chainId)
            )
      ) * this.stakeAmount;
    await this.tagManagerService.sendStakingTags(
      event,
      this._opportunity,
      this.stakeAmount.toString(),
      this.existHolder ? 'invested' : 'new',
      amount.toString(),
      status
    );
  }

  notifyInput(event) {
    this.amountNotifier.next(event);
  }

  keyUp(amount: number) {
    if (this.stakeAmount > 0 && amount != this.stakeAmount) {
      this.loading = true;
    }
  }

  updateOppDetails() {
    this.updateDetails.emit(false);
  }
}
