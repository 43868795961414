import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';

@Component({
  selector: 'app-expert-mode-dialog',
  templateUrl: './expert-mode-dialog.component.html',
  styleUrls: ['./expert-mode-dialog.component.scss']
})
export class ExpertModeDialogComponent extends DialogsComponent {
  @Output()
  confirmed = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  confirm() {
    this.confirmed.emit(true);
  }

  closeDialog() {
    this.confirmed.emit(false);
  }
}
@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [ExpertModeDialogComponent]
})
export class ExpertModeDialogComponentModule {}
