<div
  class="d-flex justify-content-center w-full"
  [ngClass]="{
    'flex-column': _opportunity.opportunityType === OpportunityType.ETF
  }"
>
  <div
    *ngIf="_opportunity.opportunityType === OpportunityType.ETF"
    [ngClass]="
      setting.isMobile
        ? 'w-full d-flex align-items-center justify-content-between flex-flex-basis-100% flex-grow-1'
        : ''
    "
  >
    <div
      class="d-flex p-2"
      [ngClass]="
        setting.isMobile
          ? 'justify-content-between w-full'
          : 'justify-content-end'
      "
    >
      <div class="w-full d-flex flex-column" *ngIf="setting.isMobile">
        <div
          class="title-section w-full d-flex align-items-center font-12 px-2"
        >
          <img
            class="z-1 rounded-full mr-2"
            [src]="planImgName()"
            (error)="updateUrl($event)"
          />

          <div class="d-flex flex-column w-full">
            <div
              class="w-full d-flex align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center">
                <div class="font-16 font-w-800">
                  {{ _opportunity.planName }}
                </div>

                <div
                  *ngIf="_opportunity.type"
                  class="rounded-full font-openSans-semiBold font-w-500 px-2 py-1 font-11 ml-1"
                  [ngClass]="
                    _opportunity.type === 'NEW'
                      ? 'text-000 bg-fef5e6'
                      : 'text-fff bg-005374'
                  "
                >
                  {{ _opportunity.type }}
                </div>
              </div>

              <div
                class="p-3 position-relative d-flex align-items-center justify-content-center bg-F7F7F7 rounded-full"
                [ngClass]="
                  selectedActionTab === undefined
                    ? 'dark:bg-121212'
                    : 'dark:bg-1E1E1E'
                "
                *ngIf="
                  selectedOpportunityTab ===
                  SelectedOpportunityTab.InvestedOpportunities
                "
              >
                <span
                  class="font-12 text-000 dark:text-fff font-bold position-absolute"
                >
                  {{ opportunity.tokenId }}
                </span>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <img
                class="rounded-full"
                [src]="web3Service.networkSpec[_opportunity.chainId].coinIcon"
                width="16"
              />

              <span class="font-12 text-000 dark:text-fff ml-1">
                {{ web3Service.networkSpec[_opportunity.chainId].title }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div (click)="close.emit()" class="cursor-pointer">
        <svg
          *ngIf="!isDarkMode"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2689_123992)">
            <rect
              width="32"
              height="32"
              rx="4"
              fill="black"
              fill-opacity="0.05"
            />
            <path
              d="M25.3327 8.54602L23.4527 6.66602L15.9993 14.1193L8.54602 6.66602L6.66602 8.54602L14.1193 15.9993L6.66602 23.4527L8.54602 25.3327L15.9993 17.8793L23.4527 25.3327L25.3327 23.4527L17.8793 15.9993L25.3327 8.54602Z"
              fill="#323232"
            />
          </g>
          <defs>
            <clipPath id="clip0_2689_123992">
              <rect width="32" height="32" rx="4" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <svg
          *ngIf="isDarkMode"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2689_123992)">
            <rect
              width="32"
              height="32"
              rx="4"
              fill="black"
              fill-opacity="0.05"
            />
            <path
              d="M25.3327 8.54602L23.4527 6.66602L15.9993 14.1193L8.54602 6.66602L6.66602 8.54602L14.1193 15.9993L6.66602 23.4527L8.54602 25.3327L15.9993 17.8793L23.4527 25.3327L25.3327 23.4527L17.8793 15.9993L25.3327 8.54602Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2689_123992">
              <rect width="32" height="32" rx="4" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>

  <div
    class="investment"
    [ngClass]="{
      'lg:flex container justify-content-between max-w-2xl lg:space-x-2':
        !confirmation
    }"
  >
    <div
      class="relative rounded"
      [ngClass]="confirmation ? 'pt-4' : 'lg:w-1/2'"
    >
      <ul
        *ngIf="
          !confirmation &&
          [OpportunityType.TypeStaking, OpportunityType.ETF].indexOf(
            opportunity.opportunityType
          ) === -1
        "
        id="myTab"
        data-tabs-toggle="#myTabContent"
        role="tablist"
        class="flex space-x-2 justify-content-between -mb-px text-sm font-medium tab-margin text-center text-gray-500 dark:text-gray-400"
      >
        <li class="mr-2 w-full" role="presentation">
          <a
            id="invest-tab"
            data-tabs-target="#invest"
            role="tab"
            aria-controls="invest"
            aria-selected="true"
            class="inline-flex px-2 py-1 p-md-2 w-full justify-center rounded-md cursor-pointer border-2 border-transparent font-bold hover:border-gray-300 group"
            (click)="toggleTabs(selectedActionTab.Invest)"
            [ngClass]="
              selectedTab === selectedActionTab.Invest
                ? 'text-fff button-0066FF'
                : 'text-000 bg-blue-100 dark:bg-2D2D2D dark:text-fff'
            "
          >
            Invest
          </a>
        </li>

        <li class="mr-2 w-full" role="presentation">
          <a
            id="withdraw-tab"
            data-tabs-target="#withdraw"
            role="tab"
            aria-controls="withdraw"
            aria-selected="true"
            class="inline-flex px-2 py-1 p-md-2 w-full justify-center rounded-md cursor-pointer border-2 border-transparent font-bold hover:border-gray-300 group"
            aria-current="page"
            (click)="toggleTabs(selectedActionTab.Withdraw)"
            [ngClass]="
              selectedTab === selectedActionTab.Withdraw
                ? 'text-fff button-0066FF'
                : 'text-000 bg-blue-100 dark:bg-2D2D2D dark:text-fff'
            "
          >
            Withdraw
          </a>
        </li>
      </ul>

      <div
        *ngIf="opportunity.opportunityType === OpportunityType.TypeStaking"
        class="investment-list w-full dark:bg-121212 rounded-md"
      >
        <app-stake
          *ngIf="!opportunity.lockedStaking; else LockedDtaking"
          (updateDetails)="updateOppDetails()"
          [opportunity]="opportunity"
        ></app-stake>

        <ng-template #LockedDtaking>
          <app-locked-staking
            (updateDetails)="updateOppDetails()"
            (onPlanClicked)="selectPlan($event)"
            [opportunity]="opportunity"
          ></app-locked-staking>
        </ng-template>
      </div>

      <ng-container
        *ngIf="opportunity.opportunityType !== OpportunityType.TypeStaking"
      >
        <ng-container
          *ngIf="
            opportunity.opportunityType !== OpportunityType.ETF;
            else ETFInvestment
          "
        >
          <div
            *ngIf="selectedTab === selectedActionTab.Invest"
            class="investment-list w-full dark:bg-darkBlack dark:border-1E1E1E rounded-md"
          >
            <app-choose-asset
              (updateDetails)="updateOppDetails()"
              (showSteps)="showingSteps($event)"
              [opportunity]="opportunity"
            ></app-choose-asset>
          </div>

          <div
            *ngIf="selectedTab === selectedActionTab.Withdraw"
            class="investment-list dark:bg-darkBlack rounded-md"
          >
            <app-opportunity-stake
              (updateDetails)="updateOppDetails()"
              [opportunity]="opportunity"
              [opportunities]="opportunities"
            ></app-opportunity-stake>
          </div>
        </ng-container>

        <ng-template #ETFInvestment>
          <div
            *ngIf="
              selectedOpportunityTab === SelectedOpportunityTab.AllOpportunities
            "
            class="dark:bg-darkBlack dark:border-1E1E1E rounded-md"
            [ngClass]="{ 'mx-auto': confirmation }"
          >
            <app-etf-invest
              [opportunity]="opportunity"
              (showSteps)="showingSteps($event)"
              (updateDetails)="updateOppDetails()"
            ></app-etf-invest>
          </div>

          <div
            *ngIf="
              selectedOpportunityTab ===
              SelectedOpportunityTab.InvestedOpportunities
            "
            class="dark:bg-darkBlack rounded-md"
          >
            <app-etf-withdraw
              [opportunity]="opportunity"
              (close)="closeOpportunity()"
              (updateDetails)="updateOppDetails()"
            ></app-etf-withdraw>
          </div>
        </ng-template>
      </ng-container>
    </div>

    <div
      *ngIf="!confirmation"
      class="relative rounded dark:text-fff dark:bg-121212"
      [ngClass]="{
        'lg:w-1/2': !confirmation,
        'mt-lg-0 mt-20px': opportunity.opportunityType === OpportunityType.ETF,
        'mt-18px': opportunity.opportunityType !== OpportunityType.ETF
      }"
    >
      <app-details-card
        *ngIf="
          opportunity.opportunityType === OpportunityType.ETF;
          else OpportunityItem
        "
        [selectedOpportunityTab]="selectedOpportunityTab"
        [opportunity]="_opportunity"
        [selectedActionTab]="
          selectedOpportunityTab === SelectedOpportunityTab.AllOpportunities
            ? selectedActionTab.Invest
            : selectedOpportunityTab ===
              SelectedOpportunityTab.InvestedOpportunities
            ? selectedActionTab.Withdraw
            : undefined
        "
        [loading]="confirmation"
      ></app-details-card>

      <ng-template #OpportunityItem>
        <app-opportunity-item
          (updateDetails)="updateOppDetails()"
          [opportunity]="_opportunity"
          [selectedActionTab]="
            selectedOpportunityTab === SelectedOpportunityTab.AllOpportunities
              ? selectedActionTab.Invest
              : selectedOpportunityTab ===
                SelectedOpportunityTab.InvestedOpportunities
              ? selectedActionTab.Withdraw
              : undefined
          "
          [plan]="plan"
          [isFundingAllowed]="false"
          [loading]="false"
          class="w-full"
        ></app-opportunity-item>
      </ng-template>
    </div>
  </div>
</div>
