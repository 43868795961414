import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';

@Component({
  selector: 'app-transaction-submitted-dialog',
  templateUrl: './successful-unstake-dialog.component.html',
  styleUrls: ['./successful-unstake-dialog.component.scss']
})
export class SuccessfulUnStakeDialogComponent extends DialogsComponent {
  constructor() {
    super();
  }

  close() {
    this.closed.emit(true);
  }
}

@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [SuccessfulUnStakeDialogComponent]
})
export class SuccessfulUnStakeDialogComponentModule {}
