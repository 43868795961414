import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CrowdToken, NetworksById, TokensHolder } from '@crowdswap/constant';
import {
  OpportunityState,
  CrossChainState
} from '../../../../services/utils.service';
import { DetailsPrivateSaleComponent } from '../details-private-sale/details-private-sale.component';
import { Web3Service } from '../../../../services/web3.service';
import { ThemeService } from '../../../../services/theme.service';

@Component({
  selector: 'app-confirmation-private-sale',
  templateUrl: './confirmation-private-sale.component.html',
  styleUrls: ['./confirmation-private-sale.component.scss']
})
export class ConfirmationPrivateSaleComponent implements OnInit {
  public isDarkMode;
  public OpportunityState = OpportunityState;
  public CrossChainState = CrossChainState;
  public investToken!: CrowdToken;
  @Input()
  public activePresale;
  @Input()
  public opportunityState;
  @Input()
  public crossChainState;
  @Input()
  public investmentType;
  @Input()
  public fallbackToken;
  @Input()
  public investmentToken;
  @Input()
  public investmentAmount;

  constructor(
    private router: Router,
    public themeService: ThemeService,
    public web3Service: Web3Service
  ) {}

  ngOnInit(): void {
    this.investToken =
      TokensHolder.TokenListByAddress[NetworksById[this.activePresale.chainId]][
        this.activePresale.investToken
      ];

    this.themeService.darkModeChangeSubject.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });
  }

  public async openDetail() {
    DetailsPrivateSaleComponent.activePresale = this.activePresale;
    await this.router.navigate(['opportunity/private-sale-details']);
  }
}
