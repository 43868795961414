import { Injectable } from '@angular/core';
import {
  Conversion,
  Dexchanges,
  OpportunityInvestmentTypeName,
  OpportunityInvestmentType
} from '@crowdswap/constant';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Web3Service } from './web3.service';
import { SelectedOpportunityTab } from '../views/pages/opportunity/model/opportunity-state.enum';
import { CrossAndSameChainEstimateTrade, CrossChainSwapState } from '../model';

const CROSS_CHIAN_FEE_NUMERATOR =
  +Conversion.convertStringFromDecimal(Dexchanges.CrossChain.feePercentage) *
  10;
const FEE_DENOMINATOR = 1000;

@Injectable()
export class TagManagerService {
  constructor(
    private $gtmService: GoogleTagManagerService,
    private web3Service: Web3Service
  ) {}

  public async sendSwapTags(
    event: any,
    estimation: any,
    network: string,
    item_id?: string,
    status?: string
  ) {
    try {
      const tag: SwapTag = {
        event: event,
        item_id: item_id,
        network: network,
        token_a: estimation.fromToken.symbol,
        token_b: estimation.toToken.symbol,
        swap_pair:
          estimation.fromToken.symbol + '-' + estimation.toToken.symbol,
        dex: estimation.dexName
      };

      if (event !== 'swap_approve') {
        tag.amount_a = Conversion.convertStringFromDecimal(
          estimation.amountIn,
          estimation.fromToken.decimals
        );
        tag.amount_a_usd = estimation.amountInInUSDT;
        tag.amount_b = Conversion.convertStringFromDecimal(
          estimation.amountOut,
          estimation.toToken.decimals
        );
        tag.amount_b_usd = estimation.amountOutInUSDT;
        tag.crowdswap_fee_usd = estimation.crowdswapFeeInUSDT;
        if (event === 'swap_done') {
          tag.value = parseFloat(estimation.amountInInUSDT);
          await this.sendPurchase(
            estimation.fromToken.symbol,
            tag.value,
            estimation.fromToken.chainId,
            estimation.fromToken.chainId,
            this.web3Service.getWalletAddress(),
            'swap'
          );
        }
        if (status) {
          tag.status = status;
        }
      }
      await this.pushTag(tag);
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public async sendLoginTag(event: string, address: string | undefined) {
    try {
      const tag: LoginTag = {
        type_wallet: this.web3Service.web3Provider?.connection?.url,
        event: event,
        item_id: address
      };
      await this.pushTag(tag);
    } catch (e) {
      console.error(e);
    }
  }

  public async sendPortfolioTags(token, type: string, address?: string) {
    try {
      const tag: PortfolioTag = {
        event: type,
        item_id: address,
        network: this.web3Service.networkSpec[token.chainId]?.title,
        item_name: token.symbol
      };
      await this.pushTag(tag);
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public async sendOpportunitiesTags(
    opportunity,
    userType: string,
    type: string
  ) {
    try {
      const tag: OpportunitiesTag = {
        event: type,
        item_id: this.web3Service.getWalletAddress(),
        network: this.web3Service.networkSpec[opportunity.chainId].title,
        item_name: opportunity.displayName,
        user_type: userType,
        invest_pair_token:
          opportunity.tokenA.symbol + '/' + opportunity.tokenB.symbol
      };
      await this.pushTag(tag);
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public async sendEtfOpportunityTags(
    event: any,
    opportunity: any,
    root: string,
    token?: any,
    amount?: string,
    amountInUsd?: string,
    crowdswapFee?: string,
    status?: string
  ) {
    try {
      const tag: EtfOpportunityTag = {
        event: event,
        item_id: this.web3Service.getWalletAddress(),
        network: this.web3Service.networkSpec[opportunity.chainId].title,
        item_name: opportunity.planName
      };

      if (root === 'etf_withdraw') {
        tag.received_token = token ? token.symbol : 'Distribution tokens';
      } else if (token && root === 'etf_invest') {
        tag.token_a = token.symbol;
      }

      if (amount && root === 'etf_invest') {
        tag.amount_a = amount;
        tag.amount_a_usd = amountInUsd;
      } else if (amount && root === 'etf_withdraw') {
        tag.withdraw_amount_percent = amount;
        tag.received_token_usd = amountInUsd;
      }
      // if (crowdswapFee) {
      //   tag.crowdswap_fee_usd = crowdswapFee;
      // }
      if (status) {
        tag.status = status;
      }
      if (opportunity.totalProfitToDisplay) {
        tag.profit =
          (opportunity.totalProfit > 0 ? '+' : '-') +
          opportunity.totalProfitToDisplay +
          '%';
      }
      {
        if (event === 'ETF_invest_done' || event === 'ETF_withdraw_done') {
          tag.value = parseFloat(
            !amountInUsd ? '0' : amountInUsd.replace('$', '')
          );
          await this.sendPurchase(
            opportunity.planName,
            tag.value,
            token.chainId,
            opportunity.chainId,
            this.web3Service.getWalletAddress(),
            event.substring(0, event.indexOf('_done'))
          );
        }
      }
      await this.pushTag(tag);
    } catch (e) {
      console.error(e);
    }
  }

  public async sendOpportunityTags(
    event: any,
    opportunity: any,
    userType: SelectedOpportunityTab,
    investType: number,
    token0: any,
    amount?: string,
    token1?: any,
    nextAmount?: string,
    investmentAmount?: string,
    status?: string
  ) {
    try {
      amount = !amount ? '0' : amount;
      nextAmount = !nextAmount ? '0' : nextAmount;
      investmentAmount = !investmentAmount ? '0' : investmentAmount;
      const InvestmentType = OpportunityInvestmentType;
      const swapFee = parseFloat(
        Conversion.convertStringFromDecimal(
          Dexchanges.CrowdswapAggregatorV3.feeConfiguration.feePercentage,
          20
        )
      );
      // const crossChainFee = parseFloat(
      //   Conversion.convertStringFromDecimal(
      //     Dexchanges.CrossChain.feePercentage,
      //     20
      //   )
      // );
      const investFee = parseFloat(
        Conversion.convertStringFromDecimal(
          opportunity.feeConfiguration.addLiquidityFee +
            opportunity.feeConfiguration.stakeFee,
          20
        )
      );

      const amountUsd = (
        parseFloat(token0.price) * parseFloat(amount)
      ).toString();
      const nextAmountUsd =
        investType === InvestmentType.ByTokenAAndTokenB
          ? (parseFloat(token1.price) * parseFloat(nextAmount)).toString()
          : '0';
      let crowdFee =
        ((investType === InvestmentType.ByTokenAOrTokenB
          ? swapFee / 2 // half of select token swap to other one
          : investType === InvestmentType.ByToken
          ? swapFee * 1.5 // token swap to tokeB and half of tokenB to tokenA
          : 0) +
          investFee) *
        parseFloat(investmentAmount);
      if (investType === InvestmentType.ByCrossChain) {
        crowdFee = investFee + swapFee * 1.5; // receive token from deBridge to tokenB and half of tokenB to tokenA
        crowdFee += opportunity.needsExtraSwap ? swapFee : 0;
        crowdFee *= parseFloat(investmentAmount);
      }
      const tag: OpportunityTag = {
        event: event,
        item_id: this.web3Service.getWalletAddress(),
        network: this.web3Service.getNetworkName(
          this.web3Service.getCurrentChainId()
        ),
        item_name: opportunity.displayName,
        user_type:
          userType === SelectedOpportunityTab.InvestedOpportunities
            ? 'invested'
            : 'new',
        invest_type: OpportunityInvestmentTypeName[investType],
        from: token0.symbol,
        invest_pair_token: token0.symbol + '/' + token1.symbol
      };
      if (event !== 'invest_token_approve') {
        tag.token_a = token0.symbol;
        tag.amount_a = amount;
        tag.amount_a_usd = amountUsd;
        tag.token_b =
          investType === InvestmentType.ByTokenAAndTokenB ? token1.symbol : '';
        tag.amount_b = nextAmount;
        tag.amount_b_usd = nextAmountUsd;
        tag.crowdswap_fee_usd = crowdFee; //BLOC-2245
        if (event === 'invest_done_farm') {
          tag.value =
            parseFloat(!amountUsd ? '0' : amountUsd) +
            parseFloat(!nextAmountUsd ? '0' : nextAmountUsd);
          await this.sendPurchase(
            opportunity.displayName,
            tag.value,
            token0.chainId,
            opportunity.chainId,
            this.web3Service.getWalletAddress(),
            'invest'
          );
        }
        if (status) {
          tag.status = status;
        }
      }
      await this.pushTag(tag);
    } catch (e) {
      console.error(e);
    }
  }

  public async sendWithdrawTags(
    event: string,
    opportunity: any,
    variant?: string,
    lpAmount?: string,
    lpAmountInUsd?: string,
    reward?: string,
    rewardInUsd?: string,
    status?: string
  ) {
    try {
      const tag: WithdrawTag = {
        event: event,
        item_id: this.web3Service.getWalletAddress(),
        network: this.web3Service.getNetworkName(
          this.web3Service.getCurrentChainId()
        ),
        item_name: opportunity.displayName
      };

      if (variant) {
        tag.item_variant = variant;

        switch (variant) {
          case 'LP': {
            tag.lp_amount = lpAmount;
            tag.lp_amount_usd = '$' + lpAmountInUsd;
            tag.reward = '0';
            tag.reward_usd = '$0';
            break;
          }
          case 'Reward': {
            tag.lp_amount = '0';
            tag.lp_amount_usd = '$0';
            tag.reward = reward;
            tag.reward_usd = '$' + rewardInUsd;
            break;
          }
          case 'LP and Reward': {
            tag.lp_amount = opportunity.stakedBalance;
            tag.lp_amount_usd = '$' + opportunity.stakedBalanceInUSDT;
            tag.reward = reward;
            tag.reward_usd = '$' + rewardInUsd;
            break;
          }
        }
      }

      if (status) {
        tag.status = status;
      }

      if (event === 'withdraw_done') {
        tag.value = parseFloat(opportunity.stakedBalanceInUSDT);
        await this.sendPurchase(
          opportunity.displayName,
          tag.value,
          opportunity.chainId,
          opportunity.chainId,
          this.web3Service.getWalletAddress(),
          'withdraw'
        );
      }
      await this.pushTag(tag);
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public async sendStakingTags(
    event: any,
    opportunity: any,
    amount?: string,
    userType?: any,
    amountUsd?: string,
    status?: string
  ) {
    try {
      const crowdFee =
        parseFloat(!amountUsd ? '0' : amountUsd) *
        parseFloat(
          Conversion.convertStringFromDecimal(
            opportunity.feeConfiguration.unstakeFee,
            20
          )
        );
      const tag: StakingTag = {
        event: event,
        item_id: this.web3Service.getWalletAddress(),
        network: this.web3Service.getNetworkName(
          this.web3Service.getCurrentChainId()
        ),
        item_name: opportunity.displayName,
        user_type: userType
      };
      if (amount) {
        tag.amount_a = amount;
      }
      if (event !== 'staking') {
        tag.crowdswap_fee_usd = crowdFee;
        tag.amount_a_usd = amountUsd;
        if (event === 'stake_done' || event === 'unstake_done') {
          tag.value = parseFloat(!amountUsd ? '0' : amountUsd);
          await this.sendPurchase(
            opportunity.displayName,
            tag.value,
            opportunity.chainId,
            opportunity.chainId,
            this.web3Service.getWalletAddress(),
            event.substring(0, event.indexOf('_done'))
          );
        }
        if (status) {
          tag.status = status;
        }
      }
      await this.pushTag(tag);
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public sendStartViewTag(pageName: string) {
    try {
      return this.pushTag({
        event: 'start_view',
        pName: pageName
      });
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public async sendCrossChainTags(
    event: string,
    estimation: CrossAndSameChainEstimateTrade,
    item_id?: string,
    status?: string
  ) {
    try {
      const crossChainTag: CrossChainTag = <CrossChainTag>{};

      crossChainTag.event = event;
      crossChainTag.item_id = item_id;
      crossChainTag.network = this.web3Service.getNetworkName(
        estimation.fromToken.chainId
      );
      crossChainTag.exchange_type =
        estimation.fromToken.chainId === estimation.toToken.chainId
          ? 'swap'
          : 'crossChain';
      crossChainTag.exchange_pair =
        estimation.fromToken.symbol + '-' + estimation.toToken.symbol;
      crossChainTag.token_a = estimation.fromToken.symbol
        ? estimation.fromToken.symbol
        : 'unknown';

      crossChainTag.amount_a =
        estimation.crossChainSwapState === CrossChainSwapState.NotFound
          ? estimation.amountIn
          : Conversion.convertStringFromDecimal(
              estimation.amountIn,
              estimation.fromToken.decimals
            );
      crossChainTag.amount_a_usd = estimation.amountInInUSDT;

      crossChainTag.network_destination = this.web3Service.getNetworkName(
        estimation.toToken.chainId
      );
      crossChainTag.token_b = estimation.toToken.symbol
        ? estimation.toToken.symbol
        : 'unknown';
      crossChainTag.amount_b =
        estimation.crossChainSwapState === CrossChainSwapState.NotFound
          ? estimation.amountOut
          : Conversion.convertStringFromDecimal(
              estimation.amountOut,
              estimation.toToken.decimals
            );
      crossChainTag.amount_b_usd = estimation.amountOutInUSDT;

      crossChainTag.crowdswap_fee_usd = (
        parseFloat(
          Conversion.convertStringFromDecimal(
            Conversion.toSafeBigNumberByRemovingFraction(estimation.amountIn)
              .mul(CROSS_CHIAN_FEE_NUMERATOR)
              .div(FEE_DENOMINATOR)
              .toString()
          )
        ) * parseFloat(estimation.fromToken.price)
      ).toString();

      crossChainTag.dex = estimation.dex.displayName;

      if (
        event === 'exchange_search' ||
        event === 'exchange_approve' ||
        event === 'exchange_start' ||
        event === 'exchange_confirmation_view_confirm' ||
        event === 'exchange_confirmation_view' ||
        event === 'exchange_confirmation_view_reject'
      ) {
        await this.pushTag(crossChainTag);
        return;
      }

      if (event === 'exchange_done') {
        crossChainTag.value = parseFloat(estimation.amountInInUSDT);
        await this.sendPurchase(
          estimation.fromToken.symbol,
          crossChainTag.value,
          estimation.fromToken.chainId,
          estimation.toToken.chainId,
          this.web3Service.getWalletAddress(),
          'crossChain'
        );
      }
      crossChainTag.status = status;

      if (
        event === 'exchange_error' ||
        event === 'exchange_done' ||
        event === 'exchange_canceled'
      ) {
        await this.pushTag(crossChainTag);
        return;
      }
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public sendWalletConnectButtonPushedTag() {
    try {
      return this.pushTag({ event: 'click_on_connect_wallet' });
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public sendSwapSearchStartTags(
    fromToken: string | undefined,
    toToken: string | undefined,
    dexDisplayName: string
  ) {
    try {
      const tag: SwapTag = {
        event: 'swap_search',
        item_id: this.web3Service.getWalletAddress(),
        network: this.web3Service.getNetworkName(
          this.web3Service.getCurrentChainId()
        ),
        token_a: fromToken,
        token_b: toToken,
        swap_pair: fromToken + '-' + toToken,
        dex: dexDisplayName
      };
      return this.pushTag(tag);
    } catch (e) {
      console.error(e);
    }
  }

  public sendFiatOnRampTag(event: string) {
    try {
      return this.pushTag({ event: event });
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public async pushTag(tag: BaseTag) {
    try {
      tag.date_time = new Date();
      await this.$gtmService.pushTag(tag);
    } catch (e) {
      console.log('An error occurred while pushing tag', e);
    }
  }

  public async sendPurchase(
    itemName: string | undefined,
    value: number,
    chainId1: number,
    chainId2: number,
    address: string | undefined,
    itemCategory: string
  ) {
    try {
      const tag: Purchase = {
        event: 'purchase',
        currency: 'USD',
        value: value,
        network: this.web3Service.networkSpec[chainId1]?.title,
        network2: this.web3Service.networkSpec[chainId2]?.title,
        items: [
          {
            item_name: itemName,
            item_id: address,
            item_category: itemCategory
          }
        ]
      };
      await this.pushTag(tag);
    } catch (e) {
      console.error(e);
    }
  }
}

interface CrossChainTag extends BaseTag {
  token_a: string;
  amount_a: string;
  amount_a_usd: string;
  network_destination: string;
  token_b: string;
  amount_b: string;
  amount_b_usd: string;
  crowdswap_fee_usd: string;
  dex: string;
  exchange_type: string;
  value?: number;
  status: string | undefined;
  exchange_pair: string;
}

interface StakingTag extends BaseTag {
  user_type?: string;
  amount_a_usd?: string;
  status?: string;
  item_name?: string;
  crowdswap_fee_usd?: number;
  value?: number;
  amount_a?: string;
}

interface OpportunitiesTag extends BaseTag {
  item_name: string;
  user_type: string;
  invest_pair_token: string;
}

interface SwapTag extends BaseTag {
  token_a?: string;
  token_b?: string;
  dex?: string;
  amount_a?: string;
  amount_a_usd?: string;
  amount_b?: string;
  amount_b_usd?: string;
  crowdswap_fee_usd?: string;
  value?: number;
  status?: string;
  swap_pair: string;
}

interface OpportunityTag extends BaseTag {
  user_type: string;
  invest_type: string;
  from: string;
  token_a?: string;
  amount_a?: string;
  token_b?: string;
  amount_b?: string;
  amount_b_usd?: string;
  status?: string;
  item_name: string;
  amount_a_usd?: string;
  crowdswap_fee_usd?: number;
  value?: number;
  invest_pair_token?: string;
}

interface EtfOpportunityTag extends BaseTag {
  user_type?: string;
  token_a?: string;
  amount_a?: string;
  status?: string;
  item_name: string;
  amount_a_usd?: string;
  crowdswap_fee_usd?: string;
  value?: number;
  received_token?: string;
  received_token_usd?: string;
  withdraw_amount_percent?: string;
  profit?: string;
}

interface LoginTag extends BaseTag {
  type_wallet?: string;
}

interface PortfolioTag extends BaseTag {
  item_name: string;
}

interface WithdrawTag extends BaseTag {
  item_name: string;
  reward?: string;
  reward_usd?: string;
  item_variant?: string;
  crowdswap_fee_usd?: string | number;
  value?: number;
  lp_amount?: string;
  lp_amount_usd?: string;
  status?: string;
}

interface Purchase extends BaseTag {
  event: string;
  currency: string;
  network2: string;
  items: [
    {
      item_name: string | undefined;
      item_id: string | undefined;
      item_category: string;
    }
  ];
  value?: number;
  status?: { status?: string };
}

interface BaseTag {
  event: string;
  date_time?: Date;
  item_id?: string;
  network?: string;
  pName?: string;
}
