import { ModuleWithProviders, NgModule } from '@angular/core';
import { AntDesignModule } from './components/ant-design/ant-design.module';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { AutofocusDirective } from '../directives/autofocus.directive';
import { SwiperModule } from 'swiper/angular';
import { WelcomeDialogComponentModule } from '../views/modal/dialogs/welcome-dialog/welcome-dialog.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CheckPendongListCountPipe } from './pipes/checkPendongListCount.pipe';
import { GetETFImagePipe } from './pipes/getETFImage.pipe';
import { LimitToPipe } from './pipes/limitTo.pipe';
import { RenameSymbol } from './pipes/renameSymbol.pipe';
import { CheckPatternPipe } from '../views/modal/dialogs/connect-wallet-dialog/checkPatternPipe.pipe';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

const IMPORTS = [
  AntDesignModule,
  SwiperModule,
  WelcomeDialogComponentModule,
  LottieModule.forRoot({ player: playerFactory })
];

const Declarations = [
  AutofocusDirective,
  CheckPendongListCountPipe,
  GetETFImagePipe,
  LimitToPipe,
  RenameSymbol,
  CheckPatternPipe
];

const EXPORT = [
  AntDesignModule,
  SwiperModule,
  AutofocusDirective,
  LottieModule,
  CheckPendongListCountPipe,
  GetETFImagePipe,
  LimitToPipe,
  RenameSymbol,
  CheckPatternPipe
];

const PROVIDERS = [{ provide: NZ_I18N, useValue: en_US }];

const EntryComponents = [];

@NgModule({
  imports: IMPORTS,
  declarations: [Declarations],
  exports: [EXPORT],
  providers: PROVIDERS
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule
    };
  }
}
