import { Injectable } from '@angular/core';
import {
  Conversion,
  CrowdToken,
  TokensHolder,
  MainNetworksById,
  NetworksById
} from '@crowdswap/constant';
import { Fetcher, Pair, TokenAmount } from '@crowdswap/sdk';
import { environment } from '../../environments/environment';
import { BigNumber } from 'ethers';
import { BigDecimal } from 'bigdecimal';
import { ERC20Service } from './erc20.service';
import { Web3Service } from './web3.service';
import { TokensService } from './tokens.service';

@Injectable()
export class UtilsService {
  constructor(
    public web3Service: Web3Service,
    private tokensService: TokensService
  ) {}

  public static nextRewardTime(stakingTime) {
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;
    let timeDifference =
      3600 - (Math.floor(Math.floor(Date.now() / 1000) - stakingTime) % 3600);

    const minutesToNextReward = Math.floor(
      (timeDifference / minutesInAnHour) % secondsInAMinute
    );
    const secondsToNextReward = Math.floor(timeDifference) % secondsInAMinute;

    return { secondsToNextReward, minutesToNextReward };
  }

  public static getRemainingTime(futureTime, currentTime) {
    const hoursInDay = 24;
    const minutesInHour = 60;
    const secondsInMinute = 60;
    let diff = futureTime - currentTime;

    const daysLeft = Math.floor(
      diff / secondsInMinute / minutesInHour / hoursInDay
    );
    diff -= daysLeft * secondsInMinute * minutesInHour * hoursInDay;

    const hoursLeft = Math.floor(diff / secondsInMinute / minutesInHour);
    diff -= hoursLeft * secondsInMinute * minutesInHour;

    const minutesLeft = Math.floor(diff / secondsInMinute);
    diff -= minutesLeft * secondsInMinute;

    const secondsLeft = Math.floor(diff);

    return { daysLeft, hoursLeft, minutesLeft, secondsLeft };
  }

  public static getURLCategory(): string | undefined {
    const pathname: string = window.location.pathname;
    const queries: string[] = pathname.split('/');
    if (queries.length > 2) {
      return queries[1].toLowerCase();
    }
    return undefined;
  }

  public static getOpportunityURL(): string | undefined {
    const pathname: string = window.location.pathname;
    const queries: string[] = pathname.split('/');
    if (queries.length > 2) {
      return queries[2].toLowerCase();
    }
    return undefined;
  }

  public static getQueryVariable(field: string): string | undefined {
    field = field.toLowerCase();
    const pathname: string = window.location.pathname;
    const queries: string[] = pathname.split('/');
    for (let i = 0; i < queries.length; i++) {
      if (queries[i].toLowerCase() == field) {
        return queries[i + 1];
      }
    }
    return undefined;
  }

  static getCurrentPage(): string {
    return window.location.pathname.substring(1);
  }

  static isValidChainId(chainId: number): boolean {
    return environment.production
      ? MainNetworksById.hasOwnProperty(chainId)
      : NetworksById.hasOwnProperty(chainId);
  }

  /*
   * Here swap refers to both normal swap and crossSwap.
   * This function returns tokens info from url if exists.
   * */
  static getSwapInfoFromUrl(): (CrowdToken | undefined)[] {
    let fromToken: CrowdToken | undefined = undefined;
    let toToken: CrowdToken | undefined = undefined;
    const fromChainIdInString: string | undefined =
      UtilsService.getQueryVariable('from_chain_id');
    const fromChainId: number = Number(fromChainIdInString);
    const fromTokenSymbol: string =
      UtilsService.getQueryVariable('from_token_symbol') ?? '-';
    if (
      !Number.isNaN(fromChainId) &&
      this.isValidChainId(fromChainId) &&
      NetworksById[fromChainId]
    ) {
      const token =
        TokensHolder.TokenListBySymbol[NetworksById[fromChainId]][
          fromTokenSymbol
        ];
      if (token) {
        fromToken = new CrowdToken(
          token.chainId,
          token.address,
          token.decimals,
          token.symbol,
          token.name
        );
      }
    }

    const toChainIdInString: string | undefined =
      UtilsService.getQueryVariable('to_chain_id');
    const toChainId: number = Number(toChainIdInString);
    const toTokenSymbol: string =
      UtilsService.getQueryVariable('to_token_symbol') ?? '-';

    if (
      !Number.isNaN(toChainId) &&
      this.isValidChainId(toChainId) &&
      NetworksById[toChainId]
    ) {
      const token =
        TokensHolder.TokenListBySymbol[NetworksById[toChainId]][toTokenSymbol];
      if (token) {
        toToken = new CrowdToken(
          token.chainId,
          token.address,
          token.decimals,
          token.symbol,
          token.name
        );
      }
    }
    return [fromToken, toToken];
  }

  static getGleamXRefFromUrl(): string | null {
    return new URLSearchParams(window.location.search).get('xref');
  }

  static _clearQueryString() {
    const yourCurrentUrl = window.location.href.split('?')[0];
    window.history.replaceState({}, '', yourCurrentUrl);
  }

  public async getPair(
    tokenA: CrowdToken,
    tokenB: CrowdToken,
    dex: any,
    provider
  ): Promise<Pair> {
    return Fetcher.fetchPairData(
      dex.name,
      dex.factoryAddress ?? dex.networks[tokenA.chainId][0].factoryAddress,
      dex.initCodeHash ?? dex.networks[tokenA.chainId][0].initCodeHash,
      TokensHolder.getWrappedToken(tokenA),
      TokensHolder.getWrappedToken(tokenB),
      provider
    );
  }

  public static deductLimitMargin(
    value: string,
    limitMarginPercent: number
  ): BigNumber {
    if (limitMarginPercent > 100) {
      limitMarginPercent = 100;
    }

    const marginAmount = BigNumber.from(value)
      .mul(BigNumber.from(limitMarginPercent * 100))
      .div(BigNumber.from(10000));

    return BigNumber.from(value).sub(marginAmount);
  }

  multiplyInBigNumber(
    num1: string | number | undefined,
    num2: string | number | undefined
  ): string | undefined {
    try {
      if (!num1 || !num2) {
        return '0';
      }
      return Conversion.convertStringFromDecimal(
        Conversion.toSafeBigNumberByRemovingFraction(
          Conversion.convertStringFromDecimal(
            Conversion.convertStringToDecimal(num1.toString())
              .mul(Conversion.convertStringToDecimal(num2.toString()))
              .toString()
          ).toString()
        ).toString()
      ).toString();
    } catch (e) {
      console.log('Getting value from numbers by error: ', e);
    }
  }

  public getTotalFees(
    opportunity,
    amount,
    stakeFeeEnabled = true,
    unstakeFeeEnabled = false,
    addLiquidityFeeEnabled = true,
    removeLiquidityFeeEnabled = false,
    crossChainFeeEnabled = false
  ) {
    if (!opportunity.version || opportunity.version !== 'V2') {
      return 0;
    }
    const feeResult = opportunity.feeConfiguration;

    const stakeFee = stakeFeeEnabled ? feeResult.stakeFee : 0;
    const unstakeFee = unstakeFeeEnabled ? feeResult.unstakeFee : 0;
    const addLiquidityFee = addLiquidityFeeEnabled
      ? feeResult.addLiquidityFee
      : 0;
    const removeLiquidityFee = removeLiquidityFeeEnabled
      ? feeResult.removeLiquidityFee
      : 0;
    const crossChainFee = crossChainFeeEnabled ? feeResult.crossChainFee : 0;
    const fees = Conversion.convertStringFromDecimal(
      BigNumber.from(stakeFee.toString())
        .add(unstakeFee.toString())
        .add(addLiquidityFee.toString())
        .add(removeLiquidityFee.toString())
        .add(crossChainFee.toString())
        .toString()
    );
    // '* 2' is for both token (amount is tokenBAmount) and 100 is fee percentage
    return (parseFloat(amount) * parseFloat(fees) * 2) / 100;
  }

  public async getQuote(
    dex: any,
    token0: CrowdToken,
    token1: CrowdToken,
    amount: string,
    provider: any
  ) {
    token0 = TokensHolder.getWrappedToken(token0);
    token1 = TokensHolder.getWrappedToken(token1);
    const pair = await this.getPair(token1, token0, dex, provider);
    const token0Price = pair.priceOf(token0);

    return Conversion.convertStringFromDecimal(
      token0Price
        .quote(
          new TokenAmount(
            token0,
            Conversion.convertStringToDecimal(
              amount,
              token0.decimals
            ).toString()
          )
        )
        .raw.toString(),
      token1.decimals
    );
  }

  public isComparisonResultNegative(aAmount: string, bAmount: string): boolean {
    const numericAAmount = new BigDecimal(aAmount.toString());
    const numericBAmount = new BigDecimal(bAmount.toString());
    const comparisonResult = numericAAmount.subtract(numericBAmount);

    return comparisonResult < 0;
  }

  public isSwapParamCrossChain(
    fromToken: CrowdToken | undefined,
    toToken: CrowdToken | undefined
  ) {
    if (!fromToken || !toToken) return false;

    return fromToken.chainId !== toToken.chainId;
  }

  public isBridge(
    fromToken: CrowdToken | undefined,
    toToken: CrowdToken | undefined
  ) {
    if (!fromToken || !toToken) return false;

    return (
      this.isCrowdBridge(fromToken, toToken) ||
      this.isTmngBridge(fromToken, toToken)
    );
  }

  public isCrowdBridge(fromToken: CrowdToken, toToken: CrowdToken) {
    return fromToken.symbol === toToken.symbol && fromToken.symbol === 'CROWD';
  }

  public isTmngBridge(fromToken: CrowdToken, toToken: CrowdToken) {
    return fromToken.symbol === toToken.symbol && fromToken.symbol === 'TMNG';
  }

  public isUnsupportedLimitOrderFromTokens(
    fromToken: CrowdToken,
    limitOrderTokens: string[] | undefined
  ) {
    // Check if fromToken's symbol is defined and is one of the unsupported symbols (e.g., 'CROWD', 'TMNG')
    // or if limitOrderTokens is not provided (undefined), indicating unsupported token.
    // If either condition is true, return true (indicating unsupported limit order for the fromToken).
    // Otherwise, check if the fromToken's symbol is not present in the provided limitOrderTokens array.
    return (
      fromToken.symbol &&
      (['CROWD', 'TMNG'].indexOf(fromToken.symbol) > -1 || !limitOrderTokens
        ? true
        : limitOrderTokens.indexOf(fromToken.symbol) === -1)
    );
  }

  public isUnsupportedLimitOrderToTokens(
    toToken: CrowdToken,
    limitOrderTokens: string[] | undefined
  ) {
    // Check if toToken's symbol is defined and is one of the unsupported symbols (e.g., 'TMNG')
    // or if limitOrderTokens is not provided (null or undefined), indicating unsupported token.
    // If either condition is true, return true (indicating unsupported limit order for the toToken).
    // Otherwise, check if the toToken's symbol is not present in the provided limitOrderTokens array.
    return (
      toToken.symbol &&
      (['TMNG'].indexOf(toToken.symbol) > -1 || !limitOrderTokens
        ? true
        : limitOrderTokens.indexOf(toToken.symbol) === -1)
    );
  }

  public isUnsupportedLimitOrderChainId(
    token: CrowdToken,
    limitOrderTokens:
      | {
          [key: string]: string[];
        }
      | undefined
  ) {
    if (limitOrderTokens) {
      const validChain = Object.keys(limitOrderTokens);

      return (
        token.symbol && validChain.indexOf(token.chainId.toString()) === -1
      );
    }

    return false;
  }

  static getPattern() {
    const urlSegments = window.location.pathname.split('/');
    let pattern;

    // Check for the presence of specific segments to determine the pattern
    if (
      urlSegments.includes('from_token_symbol') &&
      urlSegments.includes('to_token_symbol')
    ) {
      pattern = 'symbol';
    } else if (
      urlSegments.includes('from_token_address') &&
      urlSegments.includes('to_token_address')
    ) {
      pattern = 'address';
    } else {
      // Handle the case when the URL pattern is unknown or not supported
      console.error('Unknown URL pattern');
    }

    return pattern;
  }

  public async getTokenInfoFromNetworkByAddressAndChainId(
    address: string,
    chainId: number
  ) {
    try {
      const erc20 = new ERC20Service(
        this.web3Service.getNetworkProvider(chainId)
      );
      const token = await erc20.getToken(address);
      if (token.symbol) {
        return token;
      }
      return undefined;
    } catch (e) {}
  }

  public async getSwapInfoFromUrlByAddress(): Promise<searchTokensByAddressResult> {
    let result: searchTokensByAddressResult = {
      from: { token: undefined, imported: false },
      to: { token: undefined, imported: false }
    };
    const fromChainIdInString: string | undefined =
      UtilsService.getQueryVariable('from_chain_id');
    const fromChainId: number = Number(fromChainIdInString);
    const fromTokenAddress: string =
      UtilsService.getQueryVariable('from_token_address') ?? '-';
    const toChainIdInString: string | undefined =
      UtilsService.getQueryVariable('to_chain_id');
    const toChainId: number = Number(toChainIdInString);
    const toTokenAddress: string =
      UtilsService.getQueryVariable('to_token_address') ?? '-';
    const allTokens = this.tokensService.getAllTokens();
    if (
      fromChainId === toChainId &&
      fromTokenAddress.toLowerCase() === toTokenAddress.toLowerCase()
    ) {
      return result;
    }
    if (
      !Number.isNaN(fromChainId) &&
      UtilsService.isValidChainId(fromChainId) &&
      NetworksById[fromChainId]
    ) {
      const token = allTokens.find(
        (x) => x.address == fromTokenAddress && x.chainId == fromChainId
      );
      if (token) {
        result.from.token = new CrowdToken(
          token.chainId,
          token.address,
          token.decimals,
          token.symbol,
          token.name
        );
      } else {
        result.from.token =
          await this.getTokenInfoFromNetworkByAddressAndChainId(
            fromTokenAddress,
            fromChainId
          );
        result.from.imported = true;
      }
    }

    if (
      !Number.isNaN(toChainId) &&
      UtilsService.isValidChainId(toChainId) &&
      NetworksById[toChainId]
    ) {
      const token = allTokens.find(
        (x) => x.address == toTokenAddress && x.chainId == toChainId
      );
      if (token) {
        result.to.token = new CrowdToken(
          token.chainId,
          token.address,
          token.decimals,
          token.symbol,
          token.name
        );
      } else {
        result.to.token = await this.getTokenInfoFromNetworkByAddressAndChainId(
          toTokenAddress,
          toChainId
        );
        result.to.imported = true;
      }
    }
    return result;
  }
}

interface searchTokensByAddressResult {
  from: { token?: CrowdToken; imported: boolean };
  to: { token?: CrowdToken; imported: boolean };
}

export enum OpportunityState {
  Init,
  WalletNotConnected,
  ApprovalConfirmed,
  InsufficientTokenBBalance,
  InsufficientTokenABalance,
  AmountInIsTooLow,
  WrongNetwork,
  Confirmed,
  Rejected,
  ApprovalNeeded,
  ApprovalRejected,
  Failed,
  Successful
}

export const OpportunityStateName = {
  0: 'Init',
  1: 'WalletNotConnected',
  2: 'ApprovalConfirmed',
  3: 'InsufficientSrcBalance',
  4: 'InsufficientDestBalance',
  5: 'AmountInIsTooLow',
  6: 'WrongNetwork',
  7: 'Confirmed',
  8: 'Rejected',
  9: 'ApprovalNeeded',
  10: 'ApprovalRejected',
  11: 'Failed',
  12: 'Successful'
};

export enum CrossChainState {
  SrcInit,
  SrcFailed,
  SrcSuccessful,
  DstInit,
  DstWarning,
  DstWarningCatch,
  DstFailed,
  DstSuccessful
}

export const CrossChainStateName = {
  0: 'SrcInit',
  1: 'SrcFailed',
  2: 'SrcSuccessful',
  3: 'DstInit',
  4: 'DstWarning',
  5: 'DstFailed',
  6: 'DstSuccessful'
};
