import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { fromError } from 'stacktrace-js';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  getClientErrorStack(error: Error): Promise<string> {
    const callback = function (stackframes) {
      const stringifiedStack = stackframes
        .map(function (sf) {
          return sf.toString();
        })
        .join('\n');
      return stringifiedStack;
    };

    const errback = function (err) {
      console.log(err.message);
    };

    return fromError(error).then(callback).catch(errback);
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    return navigator.onLine ? error.message : 'No Internet Connection';
  }

  getClientErrorMessage(message: string, error: any) {
    return error.message
      ? error.message + '\n' + message
      : error.toString() + '\n' + message;
  }
}
