import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-iconic-value',
  templateUrl: './iconic-value.component.html',
  styleUrls: ['./iconic-value.component.scss']
})
export class IconicValueComponent implements OnInit {
  //----- inputs
  @Input()
  iconPath!: string;

  @Input()
  title!: string;

  @Input()
  value!: string;

  @Input()
  isLoading!: boolean;
  //------

  constructor() {}

  ngOnInit(): void {}
}
