import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Web3Service } from './web3.service';
import { Router } from '@angular/router';
import { match } from 'ts-pattern';

export enum ActiveMenu {
  ClassicSwap,
  Exchange,
  Opportunities,
  Affiliate,
  Portfolio,
  History,
  SignIn,
  Liquidity,
  FiatOnRamp
}

@Injectable()
export class NavigatorService {
  activeMenu?: ActiveMenu = ActiveMenu.SignIn;

  constructor(public web3Service: Web3Service, private router: Router) {}

  public changePage(currentPage: string) {
    //It just activates the 'behaviorSubject' with the last value.
    function wakeUp(behaviorSubject: BehaviorSubject<number>): void {
      const lastValue: number = behaviorSubject.getValue();
      behaviorSubject.next(lastValue);
    }

    match(currentPage)
      .when(
        (value: string) => {
          return value.includes('affiliate');
        },
        () => {
          this.activeMenu = ActiveMenu.Affiliate;
        }
      )
      .when(
        (value: string) => {
          return value.includes('exchange');
        },
        () => {
          this.activeMenu = ActiveMenu.Exchange;
          wakeUp(this.web3Service.walletNetworkChangeSubject);
        }
      )
      .when(
        (value: string) => {
          return value.includes('opportunity');
        },
        () => {
          this.activeMenu = ActiveMenu.Opportunities;
          wakeUp(this.web3Service.walletNetworkChangeSubject);
        }
      )
      .with('classicSwap', () => {
        this.activeMenu = ActiveMenu.ClassicSwap;
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      })
      .with('dashboard', () => {
        this.activeMenu = ActiveMenu.Affiliate;
        this.router.navigate(['affiliate/revenue']);
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      })
      .with('portfolio', () => {
        this.activeMenu = ActiveMenu.Portfolio;
        this.router.navigate(['portfolio']);
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      })
      .with('history', () => {
        this.activeMenu = ActiveMenu.History;
        this.router.navigate(['history']);
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      })
      .with('signIn', () => {
        this.activeMenu = ActiveMenu.SignIn;
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      })
      .with(
        'opportunity/private-sale-details',
        'opportunity/private-sale',
        'opportunity/form',
        async () => {
          this.activeMenu = ActiveMenu.Opportunities;
          wakeUp(this.web3Service.walletNetworkChangeSubject);
        }
      )
      .with('liquidity', () => {
        this.activeMenu = ActiveMenu.Liquidity;
        this.router.navigate(['liquidity']);
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      })
      .with('vip', () => {
        this.router.navigate(['vip']);
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      })
      .with('fiat', () => {
        this.activeMenu = ActiveMenu.FiatOnRamp;
        this.router.navigate(['fiat']);
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      })
      .with('transfer', () => {
        this.activeMenu = ActiveMenu.Portfolio;
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      })
      .otherwise(() => {
        this.activeMenu = ActiveMenu.Exchange;
        wakeUp(this.web3Service.walletNetworkChangeSubject);
      });

    return this.activeMenu;
  }

  public getActiveMenu(): ActiveMenu | undefined {
    return this.activeMenu;
  }

  public async navigate(route: string, param) {
    await this.router.navigate([route], {
      queryParams: param
    });
    this.changePage(route);
  }
}
