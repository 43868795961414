import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';

@Component({
  selector: 'app-waiting-dialog',
  templateUrl: './waiting-dialog.component.html',
  styleUrls: ['./waiting-dialog.component.scss']
})
export class WaitingDialogComponent extends DialogsComponent {
  constructor() {
    super();
  }

  async close() {
    this.closed.emit(true);
  }
}

@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [WaitingDialogComponent]
})
export class WaitingDialogComponentModule {}
