import { Component, Input } from '@angular/core';
import { RevenueDto } from '@crowdswap/constant';
import { AffiliateService, Web3Service } from '../../../../services';
import { WaitingDialogComponent } from 'src/app/views/modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ModalService } from 'src/app/views/modal/modal.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-affiliate-revenue',
  templateUrl: './affiliate-revenue.component.html',
  styleUrls: ['./affiliate-revenue.component.scss']
})
export class AffiliateRevenueComponent {
  public revenue: RevenueDto = new RevenueDto();
  public isLoading: boolean = false;
  public isReleasable: string = '0';

  @Input('revenue')
  set setRevenue(revenue: RevenueDto | undefined) {
    this.revenue = revenue || new RevenueDto();
    this.affiliateService.setDefaultValuesForRevenueDto(new RevenueDto());
  }

  @Input('isLoading')
  set setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  constructor(
    private affiliateService: AffiliateService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    public web3Service: Web3Service,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<any> {
    await this.getReleasable();
  }

  private async withdraw(): Promise<any> {
    try {
      const data = {
        stakeState: 'Withdraw'
      };
      await this.waitingDialogService.open(WaitingDialogComponent, data);

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        const releaseTx = await this.affiliateService.withdraw();
        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction(releaseTx)
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(e + `affiliate: withdrew unsuccessfully`);
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                await this.waitingDialogService.close();
                this.showSuccessToaster('Success', 'withdrew successfully!');
                await this.getReleasable();
              }
              if (data?.status === 0) {
                await this.waitingDialogService.close();
                this.showErrorToaster('Error', 'withdraw rejected!');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          this.showErrorToaster('Error', 'withdraw rejected!');
          console.log('withdraw rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      console.error(e + `details-private-sale: release unsuccessfully}`);
      await this.waitingDialogService.close();
    }
  }

  public async getReleasable() {
    this.isReleasable = await this.affiliateService.getReleasable();
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  private showSuccessToaster(title, message?) {
    this.toastr.success(title, message, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      enableHtml: true,
      timeOut: 10000,
      messageClass: 'successClass'
    });
  }
}
