import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ThemeService {
  private _darkModeChangeSubject = new BehaviorSubject(false);

  get darkModeChangeSubject() {
    return this._darkModeChangeSubject;
  }
}
