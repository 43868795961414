<div
  class="user-assets-section container flex font-12 justify-center px-3 px-lt-24px"
>
  <div
    class="w-full assets-list p-2 mt-4 rounded-md bg-white dark:text-white dark:bg-121212 dark:border-1E1E1E dark:border-2 relative"
  >
    <div class="mt--28px d-flex w-full px-2 left-0" role="presentation">
      <div
        id="wallet-assets"
        class="d-flex align-items-center px-2 items-center py-1 rounded-md border-2 text-white bg-0066FF border-transparent w-full"
        nz-dropdown
        [nzDropdownMenu]="menu"
        nzTrigger="click"
        [nzOverlayClassName]="'network-deopdown'"
        (nzVisibleChange)="selectedNetworkVisibleChanged($event)"
      >
        <span class="ml-1 font-openSans-regular font-18 font-openSans-bold">
          Wallet
        </span>

        <div
          *ngIf="web3Service.isConnected()"
          class="pl-2 font-openSans-regular font-18 font-openSans-bold d-flex align-items-center"
        >
          <nz-skeleton-element
            *ngIf="loading"
            nzType="button"
            [nzActive]="true"
            [nzSize]="'small'"
            class="w-full skeleton-h-28"
          ></nz-skeleton-element>

          <span *ngIf="!loading && totalBalance !== '--'">
            ~{{ totalBalance }}
          </span>
        </div>

        <span
          *ngIf="!loading"
          class="ml-3 font-16 font-openSans-bold d-none d-md-block"
        >
        </span>

        <div
          class="d-flex align-items-center ml-3 rounded-full text-000 d-lg-none py-1 px-1 flex-shrink-0"
          [ngClass]="{ 'bg-e5e7eb dark:bg-29355f': selectFlag }"
          (click)="selectFlag = !selectFlag"
        >
          <img
            class="h-5 w-5"
            [hidden]="selectFlag"
            src="assets/media/icons/3dot-white.png"
          />

          <img
            class="h-5 w-5"
            [hidden]="!selectFlag"
            src="assets/media/icons/3dot-black.png"
          />
        </div>

        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul
            nz-menu
            nzSelectable
            class="rounded-md d-lg-none shadow-lg p-10px ring-1 ring-black bg-blue-500 dark:ring-gray-700 ring-opacity-5 focus:outline-none"
          >
            <ng-container *ngFor="let item of allNetworks; let last = last">
              <li
                nz-menu-item
                (click)="selectNetwork(item)"
                [ngClass]="{
                  'bg-e5e7eb text-0066FF dark:text-3385FF':
                    selectedNetwork === item,
                  'text-fff': selectedNetwork !== item,
                  'mb-1': !last
                }"
                class="cursor-pointer rounded-md p-1 hover:bg-hoverBackground border-0 font-openSans-bold"
              >
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <span *ngIf="item < 0; else networkIcon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1654 6.66659C13.1654 7.21887 13.6131 7.66659 14.1654 7.66659C14.7176 7.66659 15.1654 7.21887 15.1654 6.66659H13.1654ZM14.832 9.99992C14.832 10.5522 15.2797 10.9999 15.832 10.9999C16.3843 10.9999 16.832 10.5522 16.832 9.99992H14.832ZM16.832 13.3333C16.832 12.781 16.3843 12.3333 15.832 12.3333C15.2797 12.3333 14.832 12.781 14.832 13.3333H16.832ZM4.33203 4.99992C4.33203 4.44763 3.88432 3.99992 3.33203 3.99992C2.77975 3.99992 2.33203 4.44763 2.33203 4.99992H4.33203ZM15.1654 6.66659V4.16659H13.1654V6.66659H15.1654ZM15.1654 4.16659C15.1654 3.15406 14.3446 2.33325 13.332 2.33325V4.33325C13.24 4.33325 13.1654 4.25863 13.1654 4.16659H15.1654ZM13.332 2.33325H4.9987V4.33325H13.332V2.33325ZM4.9987 2.33325C3.52594 2.33325 2.33203 3.52716 2.33203 4.99992H4.33203C4.33203 4.63173 4.63051 4.33325 4.9987 4.33325V2.33325ZM2.33203 4.99992C2.33203 6.47268 3.52594 7.66659 4.9987 7.66659V5.66659C4.63051 5.66659 4.33203 5.36811 4.33203 4.99992H2.33203ZM4.9987 7.66659H14.9987V5.66659H4.9987V7.66659ZM14.9987 7.66659C14.9067 7.66659 14.832 7.59197 14.832 7.49992H16.832C16.832 6.4874 16.0112 5.66659 14.9987 5.66659V7.66659ZM14.832 7.49992V9.99992H16.832V7.49992H14.832ZM14.832 13.3333V15.8333H16.832V13.3333H14.832ZM14.832 15.8333C14.832 15.7412 14.9067 15.6666 14.9987 15.6666V17.6666C16.0112 17.6666 16.832 16.8458 16.832 15.8333H14.832ZM14.9987 15.6666H4.9987V17.6666H14.9987V15.6666ZM4.9987 15.6666C4.63051 15.6666 4.33203 15.3681 4.33203 14.9999H2.33203C2.33203 16.4727 3.52594 17.6666 4.9987 17.6666V15.6666ZM4.33203 14.9999V4.99992H2.33203V14.9999H4.33203Z"
                          fill="currentColor"
                        />
                        <path
                          d="M16.668 10V13.3333H13.3346C12.4142 13.3333 11.668 12.5871 11.668 11.6667C11.668 10.7462 12.4142 10 13.3346 10H16.668"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>

                    <ng-template #networkIcon>
                      <img
                        *ngIf="item > 0"
                        class="rounded-full h-5 w-5"
                        [src]="web3Service.networkSpec[item]?.coinIcon"
                      />
                    </ng-template>
                  </div>

                  <div class="ml-3">
                    {{
                      item === -1
                        ? "All"
                        : this.web3Service.networkSpec[item]?.title
                    }}
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>
        </nz-dropdown-menu>

        <div
          class="base-network-tokens w-full d-none d-lg-flex overflow-hidden align-items-center no-text-wrap position-relative"
        >
          <div
            class="network-swiper-next gradient-right cursor-pointer swiper-button-next z-30"
          ></div>

          <div
            class="network-swiper-prev gradient-left cursor-pointer swiper-button-prev z-30"
          ></div>

          <swiper
            [config]="swiperConfig.networkConfig"
            class="mx-auto h-100"
            (realIndexChange)="
              slideChange($event, swiperConfig.allNetworksSlide)
            "
          >
            <ng-template
              swiperSlide
              class="pr-1 d-none d-md-block"
              *ngFor="let item of allNetworks"
            >
              <button
                (click)="selectNetwork(item)"
                class="d-flex align-items-center border-0 border-radius-4px p-1 pe-2 hover:bg-hoverBackground flex-shrink-0"
                [ngClass]="
                  selectedNetwork === item
                    ? 'bg-e5e7eb text-0e77a8cc'
                    : 'text-fff dark:text-0e77a8cc'
                "
              >
                <div
                  [ngClass]="{
                    'p-1px bg-fff rounded-full': selectedNetwork === item
                  }"
                  *ngIf="item > 0"
                >
                  <img
                    [src]="
                      baseLogoIconsUrl + Networks[item]?.toLowerCase() + '.png'
                    "
                    (error)="updateUrl($event)"
                    class="rounded-full"
                    width="20"
                  />
                </div>

                <span
                  *ngIf="item < 0"
                  class="ml-1 font-openSans-regular font-14 font-openSans-bold"
                >
                  All
                </span>

                <span
                  *ngIf="item > 0"
                  class="ml-1 font-openSans-regular font-12 font-openSans-bold"
                >
                  {{ this.web3Service.networkSpec[item]?.title }}
                </span>
              </button>
            </ng-template>
          </swiper>
        </div>
      </div>
    </div>

    <div class="flex pt-3 justify-center">
      <nz-table
        #sortTable
        [nzData]="displayTokens"
        [nzShowPagination]="false"
        [nzTotal]="displayTokens.length"
        [nzScroll]="{
          y: !isViewMore ? tableScrollY : null
        }"
        [nzPageSize]="isViewMore ? displayTokens.length : viewMoreCount"
        nzTableLayout="fixed"
        class="no-text-wrap bg-white dark:bg-darkBlack"
        [nzLoading]="loading && web3Service.isConnected()"
      >
        <thead>
          <tr>
            <ng-container *ngFor="let column of listOfColumn">
              <ng-container
                *ngIf="
                  !setting.isMobile || (setting.isMobile && column.inMobile)
                "
              >
                <th
                  class="border-none bg-fff hover:bg-0066ff1a dark:text-fff dark:bg-darkBlack"
                  [nzSortFn]="column.compare"
                  [nzShowSort]="column.showSort"
                  [nzSortOrder]="column.sortOrder"
                  [nzSortDirections]="column.sortDirections"
                  [nzLeft]="column.nzLeft || false"
                  [nzWidth]="column.nzWidth"
                  [ngClass]="{
                    'text-right': column.nzAlign === 'right',
                    'px-0': column.title === '' && setting.isMobile,
                    'pr-0': column.title === 'Value' && setting.isMobile
                  }"
                >
                  {{ column.title }}
                </th>
              </ng-container>
            </ng-container>
          </tr>
        </thead>

        <tbody *ngIf="web3Service.isConnected()">
          <ng-container *ngFor="let item of sortTable.data">
            <tr *ngIf="+item.balance > 0 && !loading">
              <ng-container *ngFor="let column of listOfColumn">
                <td
                  [nzLeft]="column.nzLeft || false"
                  *ngIf="
                    !setting.isMobile || (setting.isMobile && column.inMobile)
                  "
                  [ngClass]="{
                    'px-0': column.title === '' && setting.isMobile,
                    'pr-0': column.title === 'Value' && setting.isMobile
                  }"
                >
                  <ng-container [ngSwitch]="column.title">
                    <ng-container *ngSwitchCase="'Asset'">
                      <div
                        class="token-symbol-section d-flex pl-3 text-374151 align-items-center"
                      >
                        <div class="symbol mx--8px">
                          <img
                            class="rounded-full"
                            width="20"
                            [src]="
                              baseLogoIconsUrl +
                              item.symbol?.toLowerCase() +
                              '.png'
                            "
                            (error)="updateUrl($event)"
                          />
                        </div>

                        <div class="d-flex flex-column ms-2">
                          <span
                            class="ms-1 text-000 dark:text-fff"
                            [ngClass]="setting.isMobile ? 'font-14' : 'font-16'"
                          >
                            {{ item.symbol }}
                          </span>

                          <span class="d-flex align-items-center">
                            <span
                              class="font-10 text-000000cc dark:text-ffffffcc ms-1"
                            >
                              {{ web3Service.networkSpec[item.chainId].title }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'Amount'">
                      <div
                        class="d-flex align-items-center text-000 dark:text-fff"
                        [ngClass]="setting.isMobile ? 'font-14' : 'font-16'"
                      >
                        {{ item.displayBalance }}
                      </div>
                    </ng-container>

                    <ng-container
                      *ngSwitchCase="
                        ['Value in $', 'Value'].indexOf(column.title) > -1
                          ? column.title
                          : null
                      "
                    >
                      <div
                        class="d-flex align-items-center"
                        *ngIf="!setting.isMobile; else value"
                      >
                        <div class="dark:text-white font-16 p-1">
                          {{ item.holdingOnToDisplay }}
                        </div>

                        <div
                          class="font-12 text-0066FF dark:text-3385FF rounded-lg bg-0066ff1a ml-1 p-1 h-20px line-height-initial"
                        >
                          {{ item.holdingOnPercent }}
                        </div>
                      </div>

                      <ng-template #value>
                        <div
                          class="d-flex justify-content-end align-items-center"
                        >
                          <div
                            class="p-1 d-flex flex-column align-items-end text-right"
                          >
                            <span class="dark:text-fff text-000 font-14">
                              {{ item.displayBalance }}
                            </span>

                            <span
                              class="dark:text-ffffffcc text-000000cc font-10"
                            >
                              {{ item.holdingOnToDisplay }}
                            </span>
                          </div>

                          <div
                            class="font-10 text-0066FF dark:text-3385FF rounded-md bg-0066ff1a ml-1 p-1 h-20px d-flex"
                          >
                            {{ item.holdingOnPercent }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>

                    <ng-container *ngSwitchCase="'Price'">
                      <span
                        class="text-000 dark:text-fff"
                        [ngClass]="setting.isMobile ? 'font-14' : 'font-16'"
                      >
                        {{ item.displayPrice }}
                      </span>
                    </ng-container>

                    <ng-container *ngSwitchCase="''">
                      <div
                        class="w-100 d-flex align-items-center justify-content-end"
                        *ngIf="!setting.isMobile"
                      >
                        <button
                          nz-button
                          nzType="default"
                          (click)="navigateToSwap(item)"
                          [nzSize]="'small'"
                          class="px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
                        >
                          Exchange
                        </button>

                        <button
                          (click)="navigateToTransfer(item)"
                          nz-button
                          nzType="default"
                          [nzSize]="'small'"
                          class="px-2 d-flex align-items-center justify-content-between font-openSans-bold font-14 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 ms-2"
                          [ngClass]="{
                            'opacity-4 bg-transparent': item.chainId === 324,
                            'dark:bg-3385ff26': item.chainId !== 324
                          }"
                          [disabled]="item.chainId === 324"
                        >
                          Send
                        </button>
                      </div>

                      <a
                        #tableDropdownBtn
                        nz-dropdown
                        nzTrigger="click"
                        *ngIf="setting.isMobile"
                        [nzDropdownMenu]="menu"
                        (nzVisibleChange)="
                          tableDropdownVisible($event, tableDropdownBtn)
                        "
                        class="text-0066FF dark:text-3385FF rounded-full table-dropdown-btn d-flex align-items-center justify-content-center text-center"
                      >
                        <svg
                          width="4"
                          height="17"
                          viewBox="0 0 4 17"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2 4.5C3.1 4.5 4 3.6 4 2.5C4 1.4 3.1 0.5 2 0.5C0.9 0.5 0 1.4 0 2.5C0 3.6 0.9 4.5 2 4.5ZM2 6.5C0.9 6.5 0 7.4 0 8.5C0 9.6 0.9 10.5 2 10.5C3.1 10.5 4 9.6 4 8.5C4 7.4 3.1 6.5 2 6.5ZM2 12.5C0.9 12.5 0 13.4 0 14.5C0 15.6 0.9 16.5 2 16.5C3.1 16.5 4 15.6 4 14.5C4 13.4 3.1 12.5 2 12.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </a>

                      <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu class="bg-0066FF border-radius-3px">
                          <li
                            nz-menu-item
                            class="text-center text-white font-14 font-openSans-bold cursor-pointer"
                            (click)="navigateToSwap(item)"
                          >
                            Exchange
                          </li>

                          <li
                            nz-menu-item
                            class="text-center text-white font-14 font-openSans-bold cursor-pointer"
                            (click)="navigateToTransfer(item)"
                          >
                            Send
                          </li>
                        </ul>
                      </nz-dropdown-menu>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>

        <ng-container *ngIf="!web3Service.isConnected()">
          <tr>
            <td [colSpan]="5">
              <div
                class="d-flex flex-column align-items-center justify-content-center mt-3 mb-3"
              >
                <img
                  src="assets/media/icons/wallet-not-connect.png"
                  width="200"
                />

                <span
                  class="font-bold d-flex mt-3 justify-center dark:text-textWhite"
                >
                  Wallet is not connected
                </span>

                <button
                  nz-button
                  nzType="default"
                  (click)="connectWallet()"
                  [nzSize]="'default'"
                  class="px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99"
                >
                  Connect wallet
                </button>
              </div>
            </td>
          </tr>
        </ng-container>
      </nz-table>
    </div>

    <div
      *ngIf="
        web3Service.isConnected() &&
        !loading &&
        displayTokens.length > viewMoreCount
      "
      class="w-full p-2"
    >
      <button
        nz-button
        nzType="default"
        (click)="isViewMore = !isViewMore"
        [nzSize]="'default'"
        class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-0 mt-3 border-2px shadow-none dark:bg-3385ff26"
      >
        <span class="px-1">
          {{
            !isViewMore
              ? "View more " +
                (displayTokens.length - viewMoreCount) +
                " assets"
              : "View less"
          }}
        </span>
      </button>
    </div>
  </div>
</div>
