import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input()
  notificationData;

  @Output()
  onClicked: EventEmitter<void> = new EventEmitter();

  @Output()
  onClosed: EventEmitter<void> = new EventEmitter();

  onClick() {
    this.onClicked.next();
  }

  onClose() {
    this.onClosed.next();
  }
}
