<div class="flex justify-center mt-2 md:ml-0">
  <div class="w-full md:w-96 p-2 p-md-0 z-0">
    <div class="box bg-gray-50 dark:bg-darkBlack">
      <div class="col-12 pt-3">
        <div class="flex items-center">
          <span
            class="text-sm ml-2"
            [ngClass]="!name ? 'text-gray-400' : 'blue-color'"
            >Your name<span *ngIf="!name" class="text-red-400"
              >&nbsp;*</span
            ></span
          >
        </div>
        <div
          class="flex items-center w-full border-2 rounded-sm text-base px-2"
          [ngClass]="
            name ? 'border-blue-500' : 'border-gray-300 dark:border-darkBorder'
          "
        >
          <input
            [(ngModel)]="name"
            id="name"
            class="font-bold text-sm blue-color p-1 placeholder-gray-300 dark:placeholder-gray-700 ml-2 w-full focus:border-none focus:outline-none bg-gray-50 dark:bg-darkBlack"
            (keyup)="validationCheck()"
            placeholder="Your name"
            autofocus
          />
        </div>
      </div>
      <div class="pt-2">
        <div class="flex items-center">
          <span
            class="text-sm ml-2"
            [ngClass]="!email ? 'text-gray-400' : 'blue-color'"
            >Your email address<span class="text-red-400">&nbsp;*</span></span
          >
        </div>
        <div
          class="flex items-center w-full border-2 rounded-sm text-base px-2"
          [ngClass]="
            email ? 'border-blue-500' : 'border-gray-300 dark:border-darkBorder'
          "
        >
          <input
            [(ngModel)]="email"
            class="font-bold text-sm blue-color p-1 ml-2 w-full focus:border-none placeholder-gray-300 dark:placeholder-gray-700 focus:outline-none bg-gray-50 dark:bg-darkBlack"
            [ngClass]="{
              'text-gray-400':
                state !== State.EditableEmail && state !== State.Creation,
              'dark:text-white':
                state === State.EditableEmail || state === State.Creation
            }"
            (focusout)="emailValidation()"
            placeholder="Your email"
          />
        </div>
      </div>
      <div *ngIf="isWrongEmail" class="flex">
        <div class="flex text-base px-2 dark:border-darkBorder">
          <span class="text-base text-xs-2 text-red-400"
            >Invalid email address</span
          >
        </div>
      </div>
      <div class="pt-2">
        <div class="flex items-center">
          <span class="blue-color text-sm ml-2"
            >Wallet address<span class="text-red-400">&nbsp;*</span></span
          >
        </div>
        <div
          class="flex items-center w-full border-2 rounded-sm text-base px-2"
          [ngClass]="
            !walletAddress || !isUserWalletConnected
              ? 'border-gray-300'
              : 'border-blue-200 dark:border-blue-600'
          "
        >
          <input
            [(ngModel)]="walletAddress"
            class="font-bold text-sm p-1 ml-2 w-full focus:border-none focus:outline-none bg-gray-50 dark:bg-darkBlack"
            [ngClass]="
              !walletAddress || !isUserWalletConnected
                ? 'text-gray-400'
                : 'text-blue-300 dark:text-blue-600'
            "
            readonly
          />
        </div>
      </div>
      <div class="pt-2">
        <div class="flex items-center">
          <span
            class="text-gray-400 text-sm ml-2"
            [ngClass]="!affiliateId ? 'text-gray-400' : 'blue-color'"
            >ID<span class="text-red-400">&nbsp;*</span></span
          >
        </div>
        <div
          class="flex items-center w-full border-2 rounded-sm text-base px-2"
          [ngClass]="
            affiliateId
              ? 'border-blue-500'
              : 'border-gray-300 dark:border-darkBorder'
          "
        >
          <input
            [(ngModel)]="affiliateId"
            class="font-bold text-sm blue-color p-1 ml-2 w-full focus:border-none focus:outline-none bg-gray-50 dark:bg-darkBlack"
            (keyup)="validationCheck()"
            oninput="this.value = this.value.replace(/[ ]/g, '');"
          />
        </div>
      </div>
      <div *ngIf="result?.status === 409" class="flex col-12">
        <div class="col-9 offset-3 flex text-base px-2 dark:border-darkBorder">
          <span class="text-base text-xs-2 text-red-400"
            >The affiliate ID already exists</span
          >
        </div>
      </div>
      <div class="pt-2">
        <div class="flex items-center">
          <span class="text-gray-400 text-sm ml-2">Personal link</span>
        </div>
        <div
          class="flex items-center w-full bg-gray-200 rounded-sm text-base px-1"
        >
          <div
            class="flex items-center h-9 text-xs blue-color p-1 w-full focus:border-none focus:outline-none"
          >
            https://app.crowdswap.org/exchange?referralId={{ affiliateId }}
          </div>
          <div
            class="absolute right-1"
            style="width: 20px; height: 20px"
            (mouseleave)="tooltip = false"
            (mouseover)="tooltip = true"
            (click)="copy()"
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 20 20"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>EB77DFD9-7BD6-4C46-A561-B2E7977998B8</title>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="6_1_Home_Account"
                  transform="translate(-444.000000, -290.000000)"
                  fill="#0066FF"
                >
                  <g id="Group-3" transform="translate(412.000000, 102.000000)">
                    <g
                      id="Group-4"
                      transform="translate(32.000000, 188.000000)"
                    >
                      <g
                        id="Icon/Redirect/Outline"
                        transform="translate(0.000000, 0.833333)"
                      >
                        <path
                          d="M11.6666667,0 C12.9980674,0 14.0863841,1.04076664 14.1624228,2.35310601 L14.1666667,2.5 L14.1666667,4.16666667 L17.5,4.16666667 C18.8314007,4.16666667 19.9197174,5.2074333 19.9957561,6.51977268 L20,6.66666667 L20,15.8333333 C20,17.1647341 18.9592334,18.2530507 17.646894,18.3290894 L17.5,18.3333333 L8.33333333,18.3333333 C7.0019326,18.3333333 5.91361593,17.2925667 5.83757724,15.9802273 L5.83333333,15.8333333 L5.83333333,14.1666667 L2.5,14.1666667 C1.16859926,14.1666667 0.0802826005,13.1259 0.00424391118,11.8135607 L0,11.6666667 L0,2.5 C0,1.16859926 1.04076664,0.0802826005 2.35310601,0.00424391118 L2.5,0 L11.6666667,0 Z M17.5,5.83333333 L8.33333333,5.83333333 C7.90597013,5.83333333 7.55374403,6.15503349 7.50560644,6.5694824 L7.5,6.66666667 L7.5,15.8333333 C7.5,16.2606965 7.82170016,16.6129226 8.23614906,16.6610602 L8.33333333,16.6666667 L17.5,16.6666667 C17.9273632,16.6666667 18.2795893,16.3449665 18.3277269,15.9305176 L18.3333333,15.8333333 L18.3333333,6.66666667 C18.3333333,6.23930347 18.0116332,5.88707737 17.5971843,5.83893978 L17.5,5.83333333 Z M11.7638509,1.67227311 L11.6666667,1.66666667 L2.5,1.66666667 C2.0726368,1.66666667 1.7204107,1.98836683 1.67227311,2.40281573 L1.66666667,2.5 L1.66666667,11.6666667 C1.66666667,12.0940299 1.98836683,12.446256 2.40281573,12.4943936 L2.5,12.5 L5.83333333,12.5 L5.83333333,6.66666667 C5.83333333,5.33526593 6.87409997,4.24694927 8.18643934,4.17091058 L8.33333333,4.16666667 L12.5,4.16666667 L12.5,2.5 C12.5,2.0726368 12.1782998,1.7204107 11.7638509,1.67227311 Z"
                          id="Combined-Shape"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div
            *ngIf="tooltip"
            class="relative mb-4"
            x-cloak
            x-show.transition.origin.top="tooltip"
          >
            <div
              class="absolute z-10 w-40 p-2 text-sm text-center leading-tight text-white transform -translate-x-1/2 -translate-y-full bg-black rounded-md shadow-lg"
            >
              <span> {{ copyLabel }}</span>
            </div>
            <svg
              class="absolute mt-1 pl-1 ml-5 z-10 w-6 h-6 text-black transform -translate-x-12 -translate-y-3 fill-current stroke-current"
              height="8"
              width="8"
            >
              <rect
                height="8"
                transform="rotate(45)"
                width="8"
                x="12"
                y="-10"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-col mt-2">
          <button
            *ngIf="isUserWalletConnected"
            (click)="save()"
            class="btn-swap-token blue-button dark:bg-darkBlueButton hover:bg-blue-700"
            [ngClass]="isLoading ? 'disable ' : 'cursor-pointer'"
          >
            <span>{{
              state === State.Creation ? "Create" : "Save and verify"
            }}</span>
          </button>
          <button
            *ngIf="!isUserWalletConnected"
            (click)="connectWallet()"
            class="btn-swap-token blue-button dark:bg-darkBlueButton hover:bg-blue-700"
          >
            <span> Connect wallet</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
