import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class DexInfoService {
  public static REQUEST_TIMEOUT = 1350000;
  constructor(private http: HttpClient) {}

  public async getAllDexesInfo() {
    const url = `${environment.PairInfoUrl}/dex-info`;
    // const url = "http://localhost:5543/coingecko/dex-info";

    return lastValueFrom(
      this.http.get(url).pipe(timeout(DexInfoService.REQUEST_TIMEOUT))
    );
  }
}
