import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  OnInit
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from '../../base.component';
import { UserInfo } from '../../../../model/user-info';
import {
  AffiliateService,
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../../services';

export enum State {
  None,
  Editable,
  Creation,
  Saved,
  EditableEmail
}

@Component({
  selector: 'app-affiliate-profile',
  templateUrl: './affiliate-profile.component.html',
  styleUrls: ['./affiliate-profile.component.scss']
})
export class AffiliateProfileComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  State = State;
  state?: State = State.None;
  name: string = '';
  email: string = '';
  affiliateId: string = '';
  isWrongEmail: boolean = false;
  message = '';
  result: any;
  copyLabel: string = 'Copy your personal link and share it in your channels';
  tooltip: boolean = false;

  userInfo: UserInfo | undefined = undefined;
  userInfo$ = new BehaviorSubject<UserInfo | undefined>(undefined);
  isLoading: boolean = false;

  @Input('userInfo')
  set setUserInfo(userInfo: UserInfo | undefined) {
    this.userInfo$.next(userInfo);
  }

  @Output()
  userInfoChanged = new EventEmitter<UserInfo>();

  @Input('isLoading')
  set setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Input('walletAddress')
  set setWalletAddress(walletAddress: string | undefined) {
    this.walletAddress = walletAddress || '';
  }

  constructor(
    protected web3Service: Web3Service,
    protected themeService: ThemeService,
    private affiliateService: AffiliateService,
    private toastr: ToastrService,
    public ref: ChangeDetectorRef,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.state = State.None;

    this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) {
        this.state = State.Creation;
        return;
      }
      this.state = State.None;
      this.name = userInfo.name;
      this.email = userInfo.email;
      this.affiliateId = userInfo.affiliateId;
      this.ref.detectChanges();
    });
  }

  emailValidation() {
    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    this.isWrongEmail = !regexp.test(this.email!);
    this.validationCheck();
    return this.isWrongEmail;
  }

  validationCheck() {
    this.isLoading = !(
      this.name !== '' &&
      !this.isWrongEmail &&
      this.walletAddress !== '' &&
      this.affiliateId !== ''
    );
  }

  async save() {
    if (
      this.name === '' ||
      this.email === '' ||
      this.walletAddress === '' ||
      this.affiliateId === ''
    ) {
      return;
    }

    this.userInfo = new UserInfo();
    this.userInfo.name = this.name;
    this.userInfo.email = this.email;
    this.userInfo.walletAddress = this.walletAddress!;
    this.userInfo.affiliateId = this.affiliateId;
    try {
      this.result = await this.affiliateService.saveUserInfo(this.userInfo);
      switch (this.result.status) {
        case 201:
          this.message = 'Your profile is updated successfully.';
          this.state = State.Saved;
          this.userInfo = this.result.body;
          break;
        case 403:
          this.message =
            'The affiliate service is not available in your country.';
          break;
        case 409:
          this.message = 'The affiliate ID already exists!';
          break;
        default:
          this.message = 'Failed';
          break;
      }
      this.userInfoChanged.emit(this.userInfo);
      this.showToast(
        this.message,
        '',
        this.result.status === 201 ? 'success' : 'error'
      );
    } catch (error: any) {
      console.error(
        `Unable to save address user profile for affiliateId ${this.affiliateId}, ${error.message}`
      );
    } finally {
      this.copyLabel = 'Copy your personal link and share it in your channels';
      this.ref.detectChanges();
    }
  }

  editName() {
    if (this.state === State.Creation) {
      return;
    }
    this.state = State.Editable;
    if (this.email === '') {
      this.state = State.EditableEmail;
    }
    (document.getElementById('name') as HTMLInputElement).select();
  }

  copy() {
    this.copyLabel = 'Copied!';
    navigator.clipboard.writeText(
      'https://app.crowdswap.org/exchange?referralId=' + this.affiliateId
    );
    this.ref.detectChanges();
  }

  private showToast(title: string, body: string, type: 'error' | 'success') {
    switch (type) {
      case 'error':
        this.toastr.error(body, title, {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          positionClass: 'custom-toast-top-right',
          enableHtml: true,
          timeOut: 5000,
          messageClass: 'failedClass'
        });
        break;
      case 'success':
        this.toastr.success(body, title, {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          positionClass: 'custom-toast-top-right',
          enableHtml: true,
          timeOut: 5000,
          messageClass: 'successClass'
        });
        break;
    }
  }
}
