<div class="container-fluid px-3 px-lt-24px items-center top-0 header-zIndex">
  <div class="d-flex w-full justify-content-between items-center">
    <div class="flex text-sm items-center">
      <div class="mr-4">
        <a href="https://crowdswap.org/" target="_blank">
          <img
            id="logo-image"
            class="d-none d-xl-block"
            alt="CrowdSwap"
            src="assets/media/logo/logo.svg"
          />

          <img
            id="logo-without-title-image"
            class="d-xl-none"
            alt="CrowdSwap"
            src="assets/media/logo/title-logo.svg"
          />
        </a>
      </div>

      <div class="d-none d-lt-block">
        <app-navigation></app-navigation>
      </div>
    </div>

    <div class="flex space-x-3 items-center">
      <!--      History icon-->
      <div class="relative rightAlign">
        <button
          (click)="changePage('history')"
          [ngClass]="
            navigatorService.getActiveMenu() === ActiveMenu.History
              ? 'text-0066FF dark:text-3385FF'
              : 'text-323232 dark:text-fff'
          "
          id="history"
          aria-expanded="false"
          aria-haspopup="true"
          class="inline-flex justify-center w-full rounded-md button-height px-sm-1 p-1 items-center font-16 font-openSans-bold"
          type="button"
        >
          <div class="flex md:px-1">
            <div
              class="pr-1 items-center justify-center d-flex d-lt-none d-lg-flex"
            >
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_917_16863)">
                  <path
                    d="M11.9141 3.25C7.35823 3.25 3.66406 6.94417 3.66406 11.5H0.914062L4.4799 15.0658L4.54406 15.1942L8.2474 11.5H5.4974C5.4974 7.9525 8.36656 5.08333 11.9141 5.08333C15.4616 5.08333 18.3307 7.9525 18.3307 11.5C18.3307 15.0475 15.4616 17.9167 11.9141 17.9167C10.1449 17.9167 8.54073 17.1925 7.38573 16.0283L6.08406 17.33C7.57823 18.8242 9.63156 19.75 11.9141 19.75C16.4699 19.75 20.1641 16.0558 20.1641 11.5C20.1641 6.94417 16.4699 3.25 11.9141 3.25ZM10.9974 7.83333V12.4167L14.8932 14.7267L15.5991 13.5533L12.3724 11.6375V7.83333H10.9974Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_917_16863">
                    <rect
                      width="22"
                      height="22"
                      fill="currentColor"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div class="d-none d-md-block grid grid-rows-1">
              <span class="row-span-1 text-left">History</span>
            </div>
          </div>
        </button>
      </div>

      <!--      Network select-->
      <div
        class="relative rightAlign"
        *ngIf="
          selectFlag ||
          (enableNetworkSelection &&
            navigatorService.getActiveMenu() === ActiveMenu.ClassicSwap)
        "
      >
        <button
          (click)="selectFlag = !selectFlag"
          id="network-dropdown"
          (clickOutside)="onClose('select')"
          *ngIf="
            enableNetworkSelection &&
            navigatorService.getActiveMenu() === ActiveMenu.ClassicSwap
          "
          aria-expanded="false"
          aria-haspopup="true"
          class="inline-flex justify-center w-full rounded-md bg-gray-100 dark:bg-121212 dark:border-1E1E1E dark:border-2 button-height px-sm-1 p-1 bg-white items-center text-sm font-medium text-gray-700"
          type="button"
        >
          <div class="flex md:px-1">
            <div class="flex items-center justify-center">
              <img
                class="rounded-full"
                [src]="web3Service.networkSpec[currentChainId].coinIcon"
                height="20"
                width="20"
              />
            </div>
            <!-- <div class="grid grid-rows-1">
              <span
                class="d-none d-md-block row-span-1 font-openSans-bold dark:text-textWhite text-left"
                >&nbsp;&nbsp;{{
                  web3Service.networkSpec[currentChainId].title
                }}</span
              >
            </div> -->
            <div *ngIf="!isDarkMode">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_917_16869)">
                  <path
                    d="M5.83594 8.83203L10.0026 12.9987L14.1693 8.83203H5.83594Z"
                    fill="#323232"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_917_16869">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div *ngIf="isDarkMode">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_917_16869)">
                  <path
                    d="M5.83594 8.83203L10.0026 12.9987L14.1693 8.83203H5.83594Z"
                    fill="#fff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_917_16869">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </button>

        <div
          *ngIf="selectFlag"
          aria-labelledby="menu-button"
          aria-orientation="vertical"
          [ngClass]="{ 'right-0': walletState }"
          class="origin-top-left w-max absolute w-66 z-20 h-auto overflow-hidden rounded-md shadow-lg p-sm-3 bg-white dark:bg-121212 ring-1 ring-black dark:ring-gray-700 ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
          role="menu"
        >
          <div class="overflow-x-hidden max-h-90 m-sm-2 m-1">
            <div
              (click)="networkMenuClickHandler(+item)"
              *ngFor="let item of chainIds"
              class="cursor-pointer rounded-md py-1 pl-1 pr-1 mr-1 hover:bg-gray-100 dark:hover:bg-hoverBackground"
            >
              <div class="flex">
                <div class="flex items-center justify-center">
                  <i class="iconify" data-icon="mdi-account-off"></i>
                  <img
                    class="rounded-full"
                    [src]="web3Service.networkSpec[item]?.coinIcon"
                    height="30"
                    width="20"
                  />
                </div>
                <div class="grid grid-rows-1 ml-3">
                  <span
                    id="spnNetworkItem"
                    class="row-span-1 dark:text-textWhite"
                    >{{ this.web3Service.networkSpec[item].title }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="right-header rounded-md bg-gray-100 dark:bg-121212 dark:border-1E1E1E dark:border-2"
      >
        <div class="button-height grid items-center" *ngIf="!walletState">
          <button
            *ngIf="!isWrongNetwork && !isMismatchNetwork"
            aria-expanded="true"
            aria-haspopup="true"
            class="inline-flex justify-center md:divide-x-2 divide-gray-300 dark:divide-gray-900 px-2 items-center w-full dark:text-white text-sm font-medium text-gray-700"
            type="button"
          >
            <div
              (mouseleave)="tooltip = false"
              (mouseover)="tooltip = true"
              class="d-none d-md-block pr-2"
              id="base-token"
            >
              <span class="font-bold">{{
                baseToken?.balance?.substring(0, 6)
              }}</span
              >&nbsp;{{ baseToken?.name }}
            </div>
            <div
              *ngIf="tooltip"
              class="absolute mb-4 tooltip-margin"
              x-cloak
              x-show.transition.origin.top="tooltip"
            >
              <div
                class="absolute top-11 mt-4 z-10 w-40 p-2 mt-1 text-sm text-center leading-tight text-white transform -translate-x-1/2 -translate-y-full bg-black rounded-md shadow-lg"
              >
                <span>{{ baseToken?.balance }} {{ baseToken?.name }}</span>
              </div>
              <svg
                class="absolute left-9 top-4 mt-4 z-10 w-6 h-6 text-black transform -translate-x-12 -translate-y-3 fill-current stroke-current"
                height="8"
                width="8"
              >
                <rect
                  height="8"
                  transform="rotate(45)"
                  width="8"
                  x="12"
                  y="-10"
                />
              </svg>
            </div>
            <div
              (mouseleave)="copyClipboard = ''"
              (mouseover)="copyClipboard = 'Copy to clipboard'"
              *ngIf="baseToken?.balance && pendingCounter === 0"
              class="inline-flex justify-center items-center text-green-500 w-full text-sm font-medium cursor-pointer pl-2"
            >
              <div
                (mouseleave)="crowdTooltip = false"
                (mouseover)="crowdTooltip = true"
                id="add-crowd"
                (click)="addCrowdToken()"
                *ngIf="
                  isCrowdTokenAvailable &&
                  !walletState &&
                  enableNetworkSelection &&
                  navigatorService.getActiveMenu() !== ActiveMenu.Exchange
                "
              >
                <img src="assets/media/icons/wallet-crowd.png" />
              </div>
              <div
                *ngIf="crowdTooltip"
                class="relative mb-4"
                x-cloak
                x-show.transition.origin.top="tooltip"
              >
                <div
                  class="absolute top-7 mt-5 z-10 w-60 p-2 mt-1 tooltip-left2 text-sm text-center leading-tight text-white transform -translate-x-1/2 -translate-y-full bg-black rounded-md shadow-lg"
                >
                  <span>Add CROWD to wallet</span>
                </div>
                <svg
                  class="absolute right-5 mt-5 z-10 w-6 h-6 text-black transform -translate-y-3 fill-current stroke-current"
                  height="8"
                  width="8"
                >
                  <rect
                    height="8"
                    transform="rotate(45)"
                    width="8"
                    x="12"
                    y="-10"
                  />
                </svg>
              </div>
              <div class="text-sm" (click)="changeAccountDialog()">
                {{ baseToken?.address?.substring(0, 5) }}..{{
                  baseToken?.address?.substring(38, 42)
                }}
              </div>
            </div>
            <label
              id="lblPendingCounter"
              *ngIf="pendingCounter > 0"
              class="inline-flex justify-center items-center w-full rounded-sm shadow-sm p-2 bg-white text-sm font-medium text-white in-button cursor-pointer orange"
            >
              <span>{{ pendingCounter }}&nbsp;Pending&nbsp;&nbsp;</span>
              <div class="flex justify-center items-center">
                <div
                  class="loader ease-linear rounded-full border-2 border-t-2 h-4 w-4 p-2"
                ></div>
              </div>
            </label>
          </button>

          <button
            *ngIf="isWrongNetwork || isMismatchNetwork"
            (click)="web3Service.changeNetwork(correctChainId)"
            aria-expanded="true"
            aria-haspopup="true"
            class="inline-flex justify-center items-center w-full rounded-md border-none shadow-sm px-1 py-2 text-white wrong-network-style font-medium hover:bg-blue-700"
            type="button"
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="23"
                height="23"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="white"
                  d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8l-1.4 1.4z"
                />
              </svg>
            </span>

            <span> &nbsp;{{ incorrectNetworkMessage }}</span>
          </button>
        </div>

        <div class="" *ngIf="walletState">
          <button
            (click)="connectWallet()"
            aria-expanded="true"
            aria-haspopup="true"
            class="inline-flex justify-center items-center w-full rounded border-none button-height shadow-sm p-1 text-white blue-button font-medium hover:bg-blue-700"
            type="button"
          >
            <span>Connect wallet</span>
          </button>
        </div>
      </div>

      <div class="p-2 cursor-pointer" (click)="changeDarkModeStatus()">
        <button
          id="darkMode"
          class="inline-flex justify-center rounded-md float-right"
          type="button"
        >
          <img [hidden]="isDarkMode" src="assets/media/icons/moon.png" />
          <img [hidden]="!isDarkMode" src="assets/media/icons/sun.png" />
        </button>
      </div>

      <div class="p-sm-0 flex items-center">
        <button
          (click)="changePage('dashboard')"
          class="justify-center rounded-md"
          type="button"
        >
          <img
            [hidden]="isDarkMode"
            src="assets/media/icons/profile-black.svg"
          />
          <img
            [hidden]="!isDarkMode"
            src="assets/media/icons/profile-white.svg"
          />
        </button>
      </div>
    </div>
  </div>
</div>
