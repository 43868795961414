import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { environment } from '../../../../../environments/environment';

const baseUrl = environment.apiBaseUrl || '';
@Component({
  selector: 'app-confirm-private-sale-dialog',
  templateUrl: './confirm-private-sale-dialog.component.html',
  styleUrls: ['./confirm-private-sale-dialog.component.scss']
})
export class ConfirmPrivateSaleDialogComponent extends DialogsComponent {
  @Output()
  confirmed = new EventEmitter<boolean>();

  public baseUrl = baseUrl;
  constructor() {
    super();
  }

  confirm() {
    this.confirmed.emit(true);
  }

  close() {
    this.confirmed.emit(false);
  }
}

@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [ConfirmPrivateSaleDialogComponent]
})
export class ConfirmPrivateSaleDialogComponentModule {}
