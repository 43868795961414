import { ethers } from 'ethers';
import { Dexchanges, DISTRIBUTION } from '@crowdswap/constant';
import { Web3Service } from './web3.service';
import { Injectable } from '@angular/core';
// import { CurrentNetwork } from '../model/current-network';
// import { filter, throttleTime } from 'rxjs/operators';
// import { asyncScheduler } from 'rxjs';

@Injectable()
export class DistributionService {
  crowdLinearDistributionAddress;
  constructor(private web3Service: Web3Service) {
    // web3Service.currentNetworkChangeSubject
    //   .pipe(
    //     filter((currentNetwork: CurrentNetwork) => {
    //       return currentNetwork.chainId > 0;
    //     }),
    //     throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
    //   )
    //   .subscribe((currentNetwork: CurrentNetwork) => {
    //     const chainId: number = currentNetwork.chainId;
    //     this.crowdLinearDistributionAddress =
    //       Dexchanges.CrowdswapAggregator.networks[
    //         chainId
    //       ].distributionContractAddress;
    //   });
  }

  async isBeneficiary(userAddress: string | undefined): Promise<any> {
    if (!this.web3Service.web3Provider || !this.crowdLinearDistributionAddress) {
      return undefined;
    }
    const contract = new ethers.Contract(
      this.crowdLinearDistributionAddress,
      DISTRIBUTION,
      this.web3Service.web3Provider
    );
    return (await contract._beneficiaryIndex(userAddress))._exist;
  }

  async getDistributedCrowdBalance(
    userAddress: string | undefined
  ): Promise<any> {
    // if (!this.web3Service.web3Provider || !this.crowdLinearDistributionAddress) {
    //   return undefined;
    // }
    // const contract = new ethers.Contract(
    //   this.crowdLinearDistributionAddress,
    //   DISTRIBUTION,
    //   this.web3Service.web3Provider
    // );
    // return contract.getBalance(userAddress);
    return 0;
  }

  async getReleasableCrowdBalance(
    userAddress: string | undefined
  ): Promise<any> {
    // if (!this.web3Service.web3Provider || !this.crowdLinearDistributionAddress) {
    //   return undefined;
    // }
    // const contract = new ethers.Contract(
    //   this.crowdLinearDistributionAddress,
    //   DISTRIBUTION,
    //   this.web3Service.web3Provider
    // );
    // return contract.getReleasable(userAddress);
    return 0;
  }

  async getVestingType(userAddress: string | undefined): Promise<any> {
    if (!this.web3Service.web3Provider || !this.crowdLinearDistributionAddress) {
      return undefined;
    }
    const contract = new ethers.Contract(
      this.crowdLinearDistributionAddress,
      DISTRIBUTION,
      this.web3Service.web3Provider
    );
    return contract.getVestingType(userAddress);
  }

  async releaseCrowdToken(userAddress: string | undefined): Promise<any> {
    if (!this.web3Service.web3Provider || !this.crowdLinearDistributionAddress) {
      return undefined;
    }
    const contract = new ethers.Contract(
      this.crowdLinearDistributionAddress,
      DISTRIBUTION,
      this.web3Service.web3Provider
    );
    return contract.populateTransaction.release(userAddress);
  }
}
