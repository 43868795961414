import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { WaitingDialogComponent } from '../../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ModalService } from '../../../modal/modal.service';
import { Router } from '@angular/router';
import { BaseComponent } from '../../base.component';
import {
  AffiliateService,
  AuthenticationService,
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from 'src/app/services';

@Component({
  selector: 'app-sign-in',
  templateUrl: './affiliate-terms-of-service.component.html',
  styleUrls: ['./affiliate-terms-of-service.component.scss']
})
export class AffiliateTermsOfServiceComponent
  extends BaseComponent
  implements OnInit
{
  private readonly message =
    '{' +
    '"message": "I have read and agree to the affiliate terms of service and privacy policy",' +
    '"nonce": #nonce' +
    '}';

  public isSignAccepted: boolean = false;

  constructor(
    public web3Service: Web3Service,
    public themeService: ThemeService,
    private authenticationService: AuthenticationService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  async onSignClicked(): Promise<void> {
    try {
      if (!this.isUserWalletConnected) {
        await this.connectWallet();
        return;
      }

      const nonce = (await this.web3Service.getNonce()) || '';
      await this.waitingDialogService.open(WaitingDialogComponent, {
        isSign: true
      });
      const walletAddress = await this.web3Service.getWalletAddress();
      if (!walletAddress) {
        this.showToast('Wallet address is not valid', '', 'error');
        return;
      }

      const signature = await this.web3Service.signMessage(
        this.message.replace('#nonce', nonce.toString())
      );
      if (!signature) {
        this.showToast('Signature is not valid', '', 'error');
        return;
      }
      const jwt = await this.authenticationService.login(
        this.web3Service.getCurrentChainId(),
        walletAddress,
        signature,
        true
      );
      this.cookieService?.set(
        AffiliateService.AUTH_KEY,
        `Bearer ${jwt.access_token}`,
        undefined,
        '/'
      );

      await this.router.navigate(['affiliate/profile']);

      this.showToast(
        'Sing-in succeeded',
        'Please complete the profile',
        'success'
      );
      //Redirect to the previous page/main route
    } catch (e: any) {
      console.log(e);
      this.showToast(
        'Sign-in failed',
        (e.error && (e.error.error || e.error.message)) ||
          'Something went wrong',
        'error'
      );
    } finally {
      await this.waitingDialogService.close();
    }
  }

  private showToast(title: string, body: string, type: 'error' | 'success') {
    switch (type) {
      case 'error':
        this.toastr.error(body, title, {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          positionClass: 'custom-toast-top-right',
          enableHtml: true,
          timeOut: 5000,
          messageClass: 'failedClass'
        });
        break;
      case 'success':
        this.toastr.success(body, title, {
          closeButton: true,
          tapToDismiss: false,
          progressBar: true,
          positionClass: 'custom-toast-top-right',
          enableHtml: true,
          timeOut: 5000,
          messageClass: 'successClass'
        });
        break;
    }
  }
}
