<div
  class="smart-wallet-dialog d-flex flex-column dark:text-fff text-000"
  *ngIf="nzModalData"
>
  <div class="w-full d-flex align-items-center justify-content-end">
    <span class="cursor-pointer" (click)="destroyModal()">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_8921_72775)">
          <path
            d="M14.25 4.8075L13.1925 3.75L9 7.9425L4.8075 3.75L3.75 4.8075L7.9425 9L3.75 13.1925L4.8075 14.25L9 10.0575L13.1925 14.25L14.25 13.1925L10.0575 9L14.25 4.8075Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </span>
  </div>

  <div>
    <div>
      <div class="flex flex-col items-center">
        <span class="font-openSans-bold font-20 text-center">
          Confirmation
        </span>

        <ng-container [ngSwitch]="nzModalData.type">
          <ng-container *ngSwitchCase="SmartWalletConfirmation.Swap">
            <span class="font-openSans-bold font-16 text-center mt-3">
              Swapping {{ nzModalData.amount }}
              {{ nzModalData.fromToken?.symbol }}
              {{
                nzModalData.fromToken
                  ? "on " +
                    web3Service.networkSpec[nzModalData.fromToken.chainId].title
                  : ""
              }}
              for {{ nzModalData.amountOut }} {{ nzModalData.toToken?.symbol }}
              {{
                nzModalData.toToken
                  ? "on " +
                    web3Service.networkSpec[nzModalData.toToken.chainId].title
                  : ""
              }}
            </span>
          </ng-container>

          <ng-container *ngSwitchCase="SmartWalletConfirmation.Transfer">
            <span class="font-openSans-bold font-16 text-center mt-3">
              Transfer {{ nzModalData.amount }}
              {{ nzModalData.fromToken?.symbol }}
              {{
                nzModalData.fromToken
                  ? "on " +
                    web3Service.networkSpec[nzModalData.fromToken.chainId].title
                  : ""
              }}
              to {{ nzModalData.destination }}
            </span>
          </ng-container>
        </ng-container>

        <div
          class="w-full border-1px border-00000010 dark:border-00000012 border-radius-4px bg-F7F7F7 dark:bg-121212 text-000 dark:text-fff d-flex flex-column mt-3 font-12"
        >
          <span class="font-openSans-bold font-16 text m-2"> Fees: </span>

          <div
            *ngFor="let fee of nzModalData.fees"
            class="d-flex align-items-center justify-content-between flex-basis-100% w-full flex-grow-1"
          >
            <div class="d-flex flex-column justify-content-between p-2">
              <div class="text-6b7280">Token</div>

              <div class="font-bold font-14">
                {{ fee.token.symbol }}
              </div>
            </div>

            <div
              class="d-flex flex-column align-items-center justify-content-between p-2"
            >
              <div class="text-6b7280">Amount</div>

              <div class="d-flex items-center font-bold justify-content-center">
                <div class="font-bold font-14 w-full">
                  {{ fee.amount ? (fee.amount | number) : "-" }}
                </div>
              </div>
            </div>

            <div
              class="d-flex flex-column align-items-end justify-content-between p-2"
            >
              <div class="text-6b7280">Amount in USDT</div>
              <div class="font-bold">
                {{ fee.amountInUSDTToDisplay ?? "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex flex-row mt-3" dir="rtl" *nzModalFooter>
  <button
    nz-button
    nzType="default"
    [nzSize]="'default'"
    class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px ms-2"
    (click)="destroyModal(true)"
  >
    <span class="px-1">Confirm</span>
  </button>

  <button
    nz-button
    nzType="default"
    [nzSize]="'default'"
    class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 me-2"
    (click)="destroyModal()"
  >
    <span class="px-1">Reject</span>
  </button>
</div>
