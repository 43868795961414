import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UnstakeDialogComponent } from '../../modal/dialogs/unstake-dialog/unstake-dialog.component';
import { ModalService } from '../../modal/modal.service';
import {
  asyncScheduler,
  interval,
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import { SuccessfulUnStakeDialogComponent } from '../../modal/dialogs/successful-unstake/successful-unstake-dialog.component';
import {
  commonConfig,
  Conversion,
  CrowdToken,
  OpportunitiesHolder,
  PriceService
} from '@crowdswap/constant';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  throttleTime
} from 'rxjs/operators';
import { WaitingDialogComponent } from '../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { CurrentNetwork } from '../../../model';
import { BaseComponent } from '../base.component';
import { Router } from '@angular/router';
import {
  NDDClientInfoServiceImpl,
  OpportunityService,
  StakeService,
  TagManagerService,
  ThemeService,
  UtilsService,
  Web3Service
} from '../../../services';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import { WithdrawExtendDialogComponent } from '../../modal/dialogs/withdraw-extend-dialog/withdraw-extend-dialog.component';
import { environment } from '../../../../environments/environment';

export enum StakingButtonState {
  Init,
  ApprovalNeeded,
  ApprovalConfirmed,
  WalletNotConnected
}

@Component({
  selector: 'app-locked-staking',
  templateUrl: './locked-staking.component.html',
  styleUrls: ['./locked-staking.component.scss']
})
export class LockedStakingComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  StakingButtonState = StakingButtonState;
  public static LIMITED_INTERVAL_TIME =
    commonConfig.STAKE_UNSTAKE.LIMITED_INTERVAL_TIME;
  public isSelected = false;
  public token!: CrowdToken;
  public stakedBalance = 0;
  public stakeAmount = 1000;
  public nextReward = 0;
  public stakeTime = 0;
  public stakeReward = 0;
  public apyString!: string;
  public minimumStakedBalance = 1;
  public stakeState = 'staked';
  public stakeMode: boolean = true;
  private existHolder;
  public activePlans;
  private plan;

  subscriptionList: Subscription[] = [];

  _opportunity;
  @Input()
  set opportunity(opportunity: any) {
    this._opportunity = opportunity;
  }

  @Output()
  updateDetails = new EventEmitter();
  @Output()
  onPlanClicked = new EventEmitter();

  get opportunity() {
    return this._opportunity;
  }

  @ViewChild('stakeAmountInput') stakeAmountInput!: ElementRef;

  public timeLeft$:
    | Observable<{
        minutesToNextReward: number;
        secondsToNextReward: number;
      }>
    | undefined = undefined;
  public stakeButtonState = StakingButtonState.Init;
  public amountNotifier = new Subject();
  public loading: boolean = false;
  private inUseProvider: any;
  private opportunities;

  constructor(
    public stakeService: StakeService,
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    private router: Router,
    protected themeService: ThemeService,
    private toastr: ToastrService,
    private unStakeDialogService: ModalService<UnstakeDialogComponent>,
    private successfulUnStakeDialogService: ModalService<SuccessfulUnStakeDialogComponent>,
    private withdrawExtendDialogService: ModalService<WithdrawExtendDialogComponent>,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private priceService: PriceService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    this.tagManagerService.sendStartViewTag('stake');
    this.opportunities = OpportunitiesHolder.Opportunities;

    this.activePlans = this.opportunity.plans.filter((item) => item.active);
    this.token = this._opportunity.tokenA;
    this.stakeButtonState = this.web3Service.isConnected()
      ? StakingButtonState.Init
      : StakingButtonState.WalletNotConnected;

    // On wallet connect/disconnect
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe((connection) => {
        this.stakeButtonState = connection
          ? StakingButtonState.Init
          : StakingButtonState.WalletNotConnected;
        this.token.balance = '0';
        this.stakedBalance = 0;
        this.setZero();
        this.checkAllowance();
        this.ref.detectChanges();
      })
    );
    // Todo we can remove throttleTime here
    // On network change
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          const chainId: number = currentNetwork.chainId;
          this.setProvider(chainId);

          await this.refreshStakeData();
          await this.checkAllowance();
          this.ref.detectChanges();
        })
    );
    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        this.token.balance = '0';
        this.stakedBalance = 0;
        this.setZero();
        await this.refreshStakeData();
        await this.checkAllowance();
      })
    );
    this.selectPlan(-1);

    this.setProvider(this.web3Service.getCurrentChainId());
    await this.refreshStakeData();
    this.timeLeft$ = interval(1000).pipe(
      map((x) => {
        const { secondsToNextReward, minutesToNextReward } =
          UtilsService.nextRewardTime(this.stakeTime);
        if (secondsToNextReward === 0 && minutesToNextReward === 0) {
          this.refreshStakeData();
          this.ref.detectChanges();
        }

        return { secondsToNextReward, minutesToNextReward };
      }),
      shareReplay(1)
    );

    this.amountNotifier
      .pipe(
        debounceTime(OpportunityService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async () => {
        this.loading = true;
        await this.checkAllowance();
        this.loading = false;
      });
  }

  private setProvider(chainId: number) {
    this.inUseProvider =
      this._opportunity.chainId === chainId
        ? this.web3Service.web3Provider
        : this.web3Service.getNetworkProvider(this._opportunity.chainId);
  }

  setZero() {
    this.stakeReward = 0;
    this.stakeTime = 0;
    this.nextReward = 0;
  }

  private async refreshStakeData() {
    try {
      this.stakedBalance = await this.getStakedTokenBalance();
      this.token.balance = await this.getBalance();
      this.ref.detectChanges();
    } catch (e) {
      console.log(e);
    }
  }

  public async getBalance() {
    let balance = await this.web3Service.getBalance(
      this.token,
      this.inUseProvider
    );
    if (balance) {
      return Conversion.convertStringFromDecimal(balance.toString(), 18);
    }
    return '0';
  }

  async changeAmount(amount: number) {
    this.stakeAmount = +Conversion.adjustFraction(amount, 5);
  }

  public async setMaxBalance(mode) {
    switch (mode) {
      case true: {
        await this.changeAmount(+this.token.balance);
        break;
      }
      case false: {
        await this.changeAmount(this.stakedBalance);
        break;
      }
    }
  }

  public async stake() {
    try {
      this.stakeState = 'staked';
      if (this.web3Service.getCurrentChainId() != this._opportunity.chainId) {
        await this.web3Service.changeNetwork(this._opportunity.chainId);
        this.setProvider(this._opportunity.chainId);
      }
      this.tagManager('stake_start');
      const data = {
        amountIn: this.stakeAmount,
        duration: this.plan.duration,
        endDate: this.plan.displayEndDate,
        tokenSymbol: this.opportunity.tokenA?.symbol,
        isDarkMode: this.isDarkMode,
        stakeState: this.stakeState,
        activeOpportunity: this.opportunity
      };
      const withdrawExtendDialogRef =
        await this.withdrawExtendDialogService.open(
          WithdrawExtendDialogComponent,
          data
        );
      const sub: Subscription = (<WithdrawExtendDialogComponent>(
        (<any>withdrawExtendDialogRef!.instance)
      )).confirmed.subscribe(async (event) => {
        await this.withdrawExtendDialogService.close();
        if (event) {
          await this.confirmStake();
        } else if (!event) {
          this.ref.detectChanges();
        }
      });
      withdrawExtendDialogRef!.onDestroy(() => {
        sub.unsubscribe();
      });
    } catch (e) {
      console.log(e);
      await this.tagManager('stake_error', 'global cache error');
      console.error(
        e +
          `LockedStakeComponent-stake-second: staked unsuccessfully.now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
      );
      await this.waitingDialogService.close();
    }
  }

  private async confirmStake(): Promise<any> {
    try {
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        await this.showWaitingDialog('Stak'); //Stak is sending to dialog to show correct message
        this.tagManager('stake_confirmation');

        // Get Stake tx
        let amount = Conversion.convertStringToDecimal(
          this.stakeAmount.toString(),
          18
        ).toString();
        const stakeTx = await this.stakeService.getLockedStakeTransaction(
          this._opportunity,
          this.plan.id,
          amount
        );
        const gasPrice = await this.web3Service.getGasPrice();
        const estimatedGasLimit =
          await this.stakeService.getLockedStakingEstimateGas(
            this._opportunity,
            amount,
            userAddress,
            'stake',
            this.plan.id
          );

        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction({
            ...stakeTx,
            ...(estimatedGasLimit
              ? {
                  gas: estimatedGasLimit.toHexString(),
                  gasLimit: estimatedGasLimit.toHexString()
                }
              : {}),
            gasPrice: gasPrice.toHexString()
          })
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(
              e +
                `LockedStakeComponent-stake-first: staked unsuccessfully.now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
            );
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                if (
                  this.token.symbol === 'CROWD' &&
                  parseFloat(
                    this.token.price !== '0'
                      ? this.token.price
                      : await this.priceService.getPrice(
                          this.token,
                          this.web3Service.getNetworkProvider(
                            this.token.chainId
                          )
                        )
                  ) *
                    this.stakeAmount >=
                    environment.airdrop.minimumLockedStakingInvestAmount &&
                  this.plan.duration >= 90
                ) {
                  this.runGleamScript('lockedStaking');
                }
                this.limitedInterval(
                  currentTransaction,
                  `LockedStakeComponent-stake: before staked successfully with value = ${amount} and now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
                );
              } else if (data?.status === 0) {
                await this.waitingDialogService.close();
                this.tagManager('stake_error', 'status is zero');
                this.showErrorToaster('Staking rejected!', 'Error');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          this.tagManager('stake_error', 'Staking rejected!');
          this.showErrorToaster('Staking rejected!', 'Error');
          console.log('Staking rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      await this.tagManager('stake_error', 'global cache error');
      console.error(
        e +
          `LockedStakeComponent-stake-second: staked unsuccessfully.now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
      );
      await this.waitingDialogService.close();
    }
  }
  private async getStakedTokenBalance() {
    try {
      let stakedBalance = await this.stakeService.getLockedStakingBalance(
        this._opportunity,
        this.web3Service.getWalletAddress(),
        this.inUseProvider
      );
      if (stakedBalance) {
        return +Conversion.convertStringFromDecimal(
          stakedBalance.toString(),
          18
        );
      }
    } catch (e) {
      console.error(
        e +
          `StakeComponent-getStakedTokenBalance: stakedBalance = ${this.stakedBalance} and now tokenBalance is = ${this.token.balance} and stakedBalance is  = ${this.stakedBalance}`
      );
      console.log(e);
    }
    return 0;
  }

  private limitedInterval(hash: string, msg: string = '') {
    let timesRun = 0;
    const scanTransactionUrl = this.web3Service.getScanTransactionUrl(
      hash,
      this.token.chainId
    );
    let limitedInterval = setInterval(async () => {
      timesRun += 1;
      if (timesRun <= LockedStakingComponent.LIMITED_INTERVAL_TIME) {
        const newStakedBalance = await this.getStakedTokenBalance();
        if (this.stakedBalance !== newStakedBalance) {
          clearInterval(limitedInterval);
          this.updateOppDetails();
          await this.refreshStakeData();
          await this.tagManager('stake_done', 'successful');
          await this.waitingDialogService.close();
          this.showSuccessToaster(
            `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
            'stake successfully!'
          );
          this.web3Service.assetChangeSubject.next(true);
          console.info(msg);
          return;
        }
      } else {
        clearInterval(limitedInterval);
        this.updateOppDetails();
        await this.waitingDialogService.close();
        const scanUrl = this.web3Service.getScanUrl(this.opportunity.chainId);
        this.showErrorToaster(
          `<a href='${scanUrl}' target='_blank'>Please follow the result at scanner</a>`,
          'Error'
        );
      }
    }, 1000);
  }

  private async showSuccessfulDialog() {
    const data = {
      stakeAmount: this.stakeAmount,
      stakeState: this.stakeState,
      isDarkMode: this.isDarkMode,
      tokenSymbol: this._opportunity.tokenA.symbol,
      withdrawTokensIcon: [this._opportunity.rewardToken.symbol]
    };
    this.successfulUnStakeDialogService
      .open(SuccessfulUnStakeDialogComponent, data)
      .then(async () => {
        await this.ref.detectChanges();
      });
  }

  private async checkAllowance(): Promise<any> {
    try {
      const userAddress = await this.web3Service.getWalletAddress();

      if (userAddress) {
        const allowance = await this.web3Service
          .getAllowanceBySpender(
            this._opportunity.contractAddress,
            this.token.address,
            userAddress,
            this.inUseProvider
          )
          .catch((e) => {
            console.log(e);
            this.changeStakingState(StakingButtonState.ApprovalNeeded);
            return;
          });

        const amountInValue = Conversion.convertStringToDecimal(
          this.stakeAmount.toString(),
          18
        );

        if (allowance.lt(amountInValue)) {
          await this.changeStakingState(StakingButtonState.ApprovalNeeded);
        } else {
          await this.changeStakingState(StakingButtonState.ApprovalConfirmed);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.ref.detectChanges();
    }
  }

  private isStakingState(stakingState: StakingButtonState) {
    return this.stakeButtonState == stakingState;
  }

  private async changeStakingState(stakingState: StakingButtonState) {
    if (this.isStakingState(StakingButtonState.WalletNotConnected)) {
      await this.refreshStakeData();
      return;
    }

    this.stakeButtonState = stakingState;
  }

  async approve(): Promise<any> {
    let readyToApprove = true;
    try {
      if (this.web3Service.getCurrentChainId() != this._opportunity.chainId) {
        await this.web3Service.changeNetwork(this._opportunity.chainId);
      }
      this.tagManager('stake_allow');
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        const data = {
          sourceTokenSymbol: this._opportunity.tokenA.symbol
        };
        await this.waitingDialogService.open(WaitingDialogComponent, data);

        // Get approval tx
        let approvalTx = await this.web3Service.getApprovalTransactionBySpender(
          this._opportunity.contractAddress,
          this.token.address,
          '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
        );
        // Send approval tx
        approvalTx = await this.web3Service
          .sendTransaction(approvalTx)
          .catch((e) => {
            console.log(e);
            this.changeStakingState(StakingButtonState.ApprovalNeeded);
            readyToApprove = false;
          });
        if (!approvalTx) {
          await this.changeStakingState(StakingButtonState.ApprovalNeeded);
          return false;
        }
        // Wait for approval tx confirmation
        await this.web3Service
          .waitForTransaction(approvalTx, 1)
          .then((data) => {
            if (data?.status === 1) {
              this.changeStakingState(StakingButtonState.ApprovalConfirmed);
              this.checkAllowance();
            } else {
              this.changeStakingState(StakingButtonState.ApprovalNeeded);
            }
          })
          .catch((e) => {
            console.log(e);
            this.changeStakingState(StakingButtonState.ApprovalNeeded);
            readyToApprove = false;
          });
      }
    } catch (e) {
      console.log(e);
      this.changeStakingState(StakingButtonState.ApprovalNeeded);
      readyToApprove = false;
    } finally {
      await this.waitingDialogService.close();
    }
    return readyToApprove;
  }

  private async showWaitingDialog(dialogType) {
    const data = {
      stakeState: dialogType,
      isDarkMode: this.isDarkMode,
      tokenSymbol: this._opportunity.tokenA.symbol
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  private showErrorToaster(message, title) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      enableHtml: true,
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  private showSuccessToaster(message, title) {
    this.toastr.success(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      enableHtml: true,
      timeOut: 10000,
      messageClass: 'successClass'
    });
  }

  private async tagManager(event: any, status: string = '') {
    const amount =
      parseFloat(
        this.token.price !== '0'
          ? this.token.price
          : await this.priceService.getPrice(
              this.token,
              this.web3Service.getNetworkProvider(this.token.chainId)
            )
      ) * this.stakeAmount;
    await this.tagManagerService.sendStakingTags(
      event,
      this._opportunity,
      this.stakeAmount.toString(),
      this.existHolder ? 'invested' : 'new',
      amount.toString(),
      status
    );
  }

  public notifyInput(event) {
    this.amountNotifier.next(event);
  }

  public keyUp(amount: number) {
    if (this.stakeAmount > 0 && amount != this.stakeAmount) {
      this.loading = true;
    }
  }

  public updateOppDetails() {
    this.updateDetails.emit(true);
  }

  public toFixFraction(num: number | string | undefined, fraction: number = 7) {
    return num ? Conversion.adjustFraction(num, 7) : '0';
  }

  public selectPlan(planId: any) {
    if (planId === -1) {
      this.opportunity.plans.sort((item1, item2) =>
        item1.apy > item2.apy ? 1 : -1
      );
      planId = this.opportunity.plans[this.opportunity.plans.length - 1].id;
    }
    this.plan = this.opportunity.plans.find((item) => item.id === planId);
    this.opportunity.plans.map((item) => {
      if (item.id === this.plan.id) {
        item.enable = true;
      } else {
        item.enable = false;
      }
    });
    if (this.plan) {
      this.opportunity.apy = this.plan.apy;
      this.opportunity.displayApy = formatNumber(
        !this.plan.apy ? 0 : this.plan.apy,
        NumberType.TokenNonTx
      );
      const endDate = new Date(
        Date.now() + parseFloat(this.plan.duration) * 24 * 3600 * 1000
      );
      this.plan['displayEndDate'] = endDate.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      this.onPlanClicked.emit(this.plan);
    }
  }
}
