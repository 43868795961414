<app-dialog
  [isSelectOption]="true"
  [className]="data?.className"
  *ngIf="this.toggle"
  [closable]="true"
  class="select-option-dialog position-relative"
>
  <div
    class="w-full px-20px d-flex flex-column select-option-dialog-section position-relative"
  >
    <div class="d-flex align-items-center justify-content-between">
      <label class="font-16 font-openSans-bold m-0 dark:text-fff text-000">
        Select a token
      </label>

      <span class="bg-EFEFEF p-1 border-radius-4px text-000">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          (click)="toggleDropdown()"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </span>
    </div>

    <p
      class="font-12 font-w-500 m-0 dark:text-fff text-000"
      *ngIf="data?.mode === ExchangeType.Limit"
    >
      At present cross-chain limit order is only for listed tokens.
    </p>

    <div
      class="d-flex align-items-center border-radius-6px border-1px border-0066FF dark:border-3385ff26 w-full bg-0066ff14 h-42px py-2 px-3 text-0066FF dark:bg-2D2D2D dark:text-fff mt-3"
    >
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>BB6A6DA6-4619-4FF0-AB89-86075C001114</title>
        <g
          id="Select-Token"
          stroke="none"
          stroke-width="1"
          fill="currentColor"
          fill-rule="evenodd"
        >
          <g
            id="Select-a-token"
            transform="translate(-474.000000, -179.000000)"
            fill="currentColor"
          >
            <g id="Group-4" transform="translate(441.000000, 98.000000)">
              <g
                id="Text-Field/_Resource/Box"
                transform="translate(20.000000, 64.000000)"
              >
                <g
                  id="Icon/Search/Oultine"
                  transform="translate(13.666667, 17.666667)"
                >
                  <path
                    d="M7.08333333,0 C10.9953503,0 14.1666667,3.17131636 14.1666667,7.08333333 C14.1666667,8.74182968 13.5966768,10.2671978 12.6419041,11.4742305 L16.7079314,15.5344799 C16.9684971,15.7946131 16.9688478,16.2167229 16.7087146,16.4772887 L16.4732083,16.7131867 C16.2130751,16.9737524 15.7909653,16.9741031 15.5303996,16.7139699 L11.4616951,12.6517969 C10.2566449,13.6006127 8.73609359,14.1666667 7.08333333,14.1666667 C3.17131636,14.1666667 0,10.9953503 0,7.08333333 C0,3.17131636 3.17131636,0 7.08333333,0 Z M7.08333333,1.66666667 C4.09179094,1.66666667 1.66666667,4.09179094 1.66666667,7.08333333 C1.66666667,10.0748757 4.09179094,12.5 7.08333333,12.5 C10.0748757,12.5 12.5,10.0748757 12.5,7.08333333 C12.5,4.09179094 10.0748757,1.66666667 7.08333333,1.66666667 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>

      <input
        #txtSearchTokenInput
        class="w-full bg-transparent border-0 shadow-none outline-none ms-2 font-16"
        (click)="$event.stopPropagation()"
        (ngModelChange)="filterDataSourceValue($event)"
        [(ngModel)]="filterValue"
        (keydown.enter)="onEnter()"
        (keydown.escape)="clearSearch()"
        placeholder="Search name or paste address"
      />

      <svg
        *ngIf="filterValue"
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="clearSearch()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
    
    <div
      class="base-network-tokens w-full mt-3 d-flex overflow-hidden align-items-center no-text-wrap flex-shrink-0 position-relative"
    >
      <div
        class="network-swiper-next gradient-right cursor-pointer swiper-button-next z-30"
      ></div>
      <div
        class="network-swiper-prev gradient-left cursor-pointer swiper-button-prev z-30"
      ></div>
      
      <swiper
        [config]="swiperConfig.networkConfig"
        class="mx-auto h-100"
        (realIndexChange)="slideChange($event, swiperConfig.allNetworksSlide)"
        >
        <ng-template swiperSlide *ngIf="!data?.dontShowAllNetworks">
          <button
            class="d-flex align-items-center border-0 border-radius-4px p-1 pe-2 hover:bg-e5e7eb dark:hover:bg-29355f flex-shrink-0"
            (click)="selectNetwork(-1)"
            [ngClass]="
              selectedNetwork === -1
                ? 'bg-0066FF-imp text-fff'
                : 'text-000 dark:text-fff'
            "
          >
            <span class="ml-1 font-openSans-regular font-14 font-openSans-bold">
              All networks
            </span>
          </button>
        </ng-template>

        <ng-template swiperSlide *ngFor="let item of allNetworks">
          <button
            (click)="selectNetwork(item)"
            class="d-flex align-items-center border-0 border-radius-4px p-1 pe-2 hover:bg-e5e7eb dark:hover:bg-29355f flex-shrink-0"
            [ngClass]="
              selectedNetwork === item
                ? 'bg-0066FF-imp text-fff'
                : 'text-000 dark:text-fff'
            "
          >
            <div
              [ngClass]="{
                'p-1px bg-fff rounded-full': selectedNetwork === item
              }"
            >
              <img
                [src]="
                  baseLogoIconsUrl + Networks[item]?.toLowerCase() + '.png'
                "
                (error)="updateUrl($event)"
                class="rounded-full"
                width="20"
              />
            </div>

            <span class="ml-1 font-openSans-regular font-14 font-openSans-bold">
              {{ this.web3Service.networkSpec[item].title }}
            </span>
          </button>
        </ng-template>
      </swiper>
    </div>

    <div
      class="base-network-tokens w-full mt-2 d-flex overflow-hidden align-items-center no-text-wrap flex-shrink-0 position-relative transition-none"
    >
      <div
        class="token-swiper-next gradient-right cursor-pointer swiper-button-next z-30"
      ></div>
      <div
        class="token-swiper-prev gradient-left cursor-pointer swiper-button-prev z-30"
      ></div>

      <swiper
        #baseNetworkTokensSwiper
        [config]="swiperConfig.tokenConfig"
        class="mx-auto h-100"
        (realIndexChange)="
          slideChange($event, swiperConfig.defaultTokensOfTheNetworkSlide)
        "
      >
        <ng-template swiperSlide *ngFor="let item of defaultTokensOfTheNetwork">
          <button
            (click)="selectToken(item)"
            class="d-flex align-items-center border-1px border-E7E7E7 dark:border-0d3e76 rounded-full p-1 pe-2 hover:bg-e5e7eb dark:hover:bg-29355f flex-shrink-0"
            [ngClass]="
              data?.currentTokenSymbol === item.symbol &&
              data?.currentNetwork === selectedNetwork.toString()
                ? 'bg-0066FF-imp text-fff'
                : 'text-000 dark:text-fff'
            "
          >
            <span class="token-icon m--8px position-relative">
              <img
                class="rounded-full"
                [src]="baseLogoIconsUrl + item.symbol?.toLowerCase() + '.png'"
                (error)="updateUrl($event)"
              />

              <div
                class="token-network-icon position-absolute right-0 bottom-0 bg-fff dark:bg-29355f d-flex align-items-center justify-content-center rounded-full"
              >
                <img
                  [src]="
                    baseLogoIconsUrl +
                    Networks[item.chainId]?.toLowerCase() +
                    '.png'
                  "
                  class="rounded-full"
                  (error)="updateUrl($event)"
                />
              </div>
            </span>

            <span class="ml-6px font-openSans-regular font-14 font-openSans-bold">
              {{ item.symbol }}
            </span>
            <img
              *ngIf="item.type === TokenType.Trending"
              src="assets/media/icons/fire-trend.svg"
              class="h-5 w-5"
            />
          </button>
        </ng-template>
      </swiper>
    </div>

    <div
      class="w-full d-flex align-items-center justify-content-between font-12 text-000 dark:text-fff mt-3"
    >
      <span>Token</span>

      <span *ngIf="!isNewToken">Balance</span>
    </div>

    <div class="select-option w-full mt-2 dark:border-2D2D2D dark:border-1">
      <ng-container
        *ngIf="isNewToken; then newTokenBlock; else tokenListBlock"
      ></ng-container>

      <ng-template #newTokenBlock>
        <div class="w-full d-flex flex-column">
          <div
            class="d-flex flex-column border-1px border-EAEAEA dark:border-2D2D2D border-radius-6px p-1 pt-0"
            *ngIf="importTokens && importTokens.length > 0"
          >
            <div
              *ngFor="let item of importTokens"
              class="cursor-pointer border-radius-6px p-1 hover:bg-f3f4f6 dark:hover:bg-29355f mt-1"
            >
              <div
                class="w-full d-flex align-items-center justify-content-between"
              >
                <div class="d-flex align-items-center">
                  <div class="symbol mx--8px">
                    <img
                      class="rounded-full"
                      [src]="
                        baseLogoIconsUrl + item.symbol?.toLowerCase() + '.png'
                      "
                      (error)="updateUrl($event)"
                    />
                  </div>

                  <div class="d-flex flex-column ms-2">
                    <span
                      class="font-openSans-semiBold font-16 text-000 dark:text-fff"
                    >
                      {{ item.symbol }}
                    </span>

                    <span class="d-flex align-items-center mt-2px">
                      <div
                        class="token-network-icon bg-fff dark:bg-29355f d-flex align-items-center justify-content-center rounded-full"
                      >
                        <img
                          [src]="
                            baseLogoIconsUrl +
                            Networks[item.chainId]?.toLowerCase() +
                            '.png'
                          "
                          (error)="updateUrl($event)"
                        />
                      </div>

                      <span class="font-12 text-000 dark:text-fff ms-1">
                        {{ web3Service.networkSpec[item.chainId].title }}
                      </span>
                    </span>
                  </div>
                </div>

                <div
                  class="d-flex align-items-center font-16 font-openSans-bold text-000 dark:text-fff"
                >
                  <button
                    class="flex items-center justify-center blue-button text-white rounded-md p-2 hover:bg-blue-700"
                    (click)="showImportTokenConfirmDialog(item)"
                  >
                    Import
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-container
          *ngIf="
            !newTokensLoading && (!importTokens || importTokens.length === 0);
            then notFound
          "
        ></ng-container>

        <ng-container *ngIf="newTokensLoading; then loading"></ng-container>
      </ng-template>

      <ng-template #tokenListBlock>
        <ng-container
          *ngIf="
            tokensTempDisplay &&
              (tokensTempDisplay.hasBalanceList.length > 0 ||
                tokensTempDisplay.zeroList.length > 0);
            then tokenList;
            else tokenListLoading
          "
        ></ng-container>

        <ng-template #tokenListLoading>
          <div
            class="d-flex align-items-center justify-content-center"
            *ngIf="tokensTempDisplay; then loading; else notFound"
          ></div>
        </ng-template>

        <ng-template #tokenList>
          <div class="w-full d-flex flex-column">
            <div
              *ngIf="
                tokensTempDisplay && tokensTempDisplay.hasBalanceList.length > 0
              "
              class="d-flex flex-column border-1px border-EAEAEA dark:border-2D2D2D border-radius-6px p-1 pt-0"
            >
              <div
                *ngFor="let item of tokensTempDisplay?.hasBalanceList"
                class="cursor-pointer border-radius-6px p-1 hover:bg-f3f4f6 dark:hover:bg-29355f mt-1"
              >
                <div
                  (click)="selectToken(item)"
                  class="w-full d-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol mx--8px">
                      <img
                        class="rounded-full"
                        [src]="
                          baseLogoIconsUrl + item.symbol.toLowerCase() + '.png'
                        "
                        (error)="updateUrl($event)"
                      />
                    </div>

                    <div class="d-flex flex-column ms-2">
                      <span
                        class="d-flex font-openSans-semiBold font-16 text-000 dark:text-fff"
                      >
                        {{ item.symbol }}
                        <img
                          *ngIf="item.type === TokenType.Trending"
                          src="assets/media/icons/fire-trend.svg"
                          class="h-5 w-5"
                        />
                      </span>

                      <span class="d-flex align-items-center mt-2px">
                        <div
                          class="token-network-icon bg-fff dark:bg-29355f d-flex align-items-center justify-content-center rounded-full"
                        >
                          <img
                            [src]="
                              baseLogoIconsUrl +
                              Networks[item?.chainId]?.toLowerCase() +
                              '.png'
                            "
                            (error)="updateUrl($event)"
                          />
                        </div>

                        <span class="font-12 text-000 dark:text-fff ms-1">
                          {{ web3Service.networkSpec[item?.chainId].title }}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    class="d-flex align-items-center font-16 font-openSans-bold text-000 dark:text-fff"
                  >
                    {{ item.balanceToDisplay }}
                  </div>
                </div>
              </div>
            </div>

            <div
              *ngIf="
                !filterValue &&
                tokensTempDisplay &&
                tokensTempDisplay.zeroList.length > 0 &&
                tokensTempDisplay.hasBalanceList.length !== 0
              "
              class="w-full text-center text-0066FF dark:text-fff mt-2 font-14 cursor-pointer"
              (click)="showZero = !showZero"
            >
              {{ showZero ? "Hide 0 Balances" : "More" }}
            </div>

            <div
              class="d-flex flex-column border-radius-6px p-1 pt-0"
              *ngIf="
                tokensTempDisplay &&
                tokensTempDisplay.zeroList.length > 0 &&
                (showZero ||
                  filterValue ||
                  tokensTempDisplay.hasBalanceList.length === 0)
              "
            >
              <div
                *ngFor="let item of tokensTempDisplay?.zeroList"
                class="cursor-pointer border-radius-6px p-1 hover:bg-f3f4f6 dark:hover:bg-29355f mt-1"
              >
                <div
                  (click)="selectToken(item)"
                  class="w-full d-flex align-items-center justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <div class="symbol mx--8px">
                      <img
                        class="rounded-full"
                        loading="lazy"
                        [src]="
                          baseLogoIconsUrl + item.symbol.toLowerCase() + '.png'
                        "
                        (error)="updateUrl($event)"
                      />
                    </div>

                    <div class="d-flex flex-column ms-2">
                      <span
                        class="d-flex font-openSans-semiBold font-16 text-000 dark:text-fff"
                      >
                        {{ item.symbol }}
                        <img
                          *ngIf="item.type === TokenType.Trending"
                          src="assets/media/icons/fire-trend.svg"
                          class="h-5 w-5"
                        />
                      </span>

                      <span class="d-flex align-items-center mt-2px">
                        <div
                          class="token-network-icon bg-fff dark:bg-29355f d-flex align-items-center justify-content-center rounded-full"
                        >
                          <img
                            [src]="
                              baseLogoIconsUrl +
                              Networks[item?.chainId]?.toLowerCase() +
                              '.png'
                            "
                            (error)="updateUrl($event)"
                          />
                        </div>

                        <span class="font-12 text-000 dark:text-fff ms-1">
                          {{ web3Service.networkSpec[item?.chainId].title }}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div
                    *ngIf="web3Service.isConnected()"
                    class="d-flex align-items-center font-16 font-openSans-bold text-000 dark:text-fff"
                  >
                    {{ item.balanceToDisplay }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </div>

    <button
      nz-button
      nzType="default"
      (click)="toggleDropdown()"
      class="close-section border-0066FF dark:border-3385ff26 border-2px border-solid text-0066FF dark:text-3385FF font-14 font-openSans-bold d-flex d-xs-none align-items-center border-radius-4px w-auto position-absolute bottom-0 right-0 me-3 mb-3 bg-fff"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="currentColor"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="toggleDropdown()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>

      <span close="text-0066FF dark:text-3385FF ml-6px"> close </span>
    </button>
  </div>
</app-dialog>

<ng-template #notFound>
  <div class="d-flex flex-column align-items-center dark:text-fff mt-20px">
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5329_92830)">
        <path
          d="M28.4164 25.6667H26.968L26.4547 25.1717C28.2514 23.0817 29.333 20.3683 29.333 17.4167C29.333 10.835 23.998 5.5 17.4164 5.5C11.1464 5.5 6.01302 10.34 5.55469 16.5H9.25802C9.71635 12.375 13.163 9.16667 17.4164 9.16667C21.9814 9.16667 25.6664 12.8517 25.6664 17.4167C25.6664 21.9817 21.9814 25.6667 17.4164 25.6667C17.1047 25.6667 16.8114 25.6117 16.4997 25.575V29.2783C16.8114 29.315 17.1047 29.3333 17.4164 29.3333C20.368 29.3333 23.0814 28.2517 25.1714 26.455L25.6664 26.9683V28.4167L34.833 37.565L37.5647 34.8333L28.4164 25.6667Z"
          fill="currentColor"
        />
        <path
          d="M11.8622 19.8359L7.33391 24.3643L2.80557 19.8359L1.50391 21.1376L6.03224 25.6659L1.50391 30.1943L2.80557 31.4959L7.33391 26.9676L11.8622 31.4959L13.1639 30.1943L8.63557 25.6659L13.1639 21.1376L11.8622 19.8359Z"
          fill="currentColor"
        />
      </g>
    </svg>

    <span class="mt-1"> Not found </span>
  </div>
</ng-template>

<ng-template #loading>
  <div class="d-flex align-items-center justify-content-center mt-20px">
    <span
      class="d-flex loader rounded-full border-2 border-t-2 h-10 w-10"
    ></span>
  </div>
</ng-template>
