import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SelectOptionDialogComponent } from '../../../modal/dialogs/select-option-dialog/select-option-dialog.component';
import { asyncScheduler, Subject, Subscription, throwError } from 'rxjs';
import {
  commonConfig,
  Conversion,
  CrowdToken,
  Networks,
  NetworksById,
  OpportunitiesHolder,
  OpportunityInvestmentType,
  PriceService,
  TokensHolder,
  UNUSUAL_MODE_PERCENTAGE
} from '@crowdswap/constant';
import { ModalService } from '../../../modal/modal.service';
import { Constants } from '../../../../constants';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  throttleTime
} from 'rxjs/operators';
import { ConfirmOpportunityDialogComponent } from '../../../modal/dialogs/confirm-opportunity-dialog/confirm-opportunity-dialog.component';
import { Builder } from 'builder-pattern';
import { CookieService } from 'ngx-cookie-service';
import { WaitingDialogComponent } from '../../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { BigNumber } from 'ethers';
import { PendingTransactionModel } from '../../../../model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {
  CrossChainState,
  CrowdSwapService,
  DebridgeService,
  LoggingService,
  NDDClientInfoServiceImpl,
  NavigatorService,
  OpportunityService,
  OpportunityState,
  PrivateSaleService,
  TagManagerService,
  ThemeService,
  TokensService,
  UtilsService,
  Web3Service
} from '../../../../services';
import { CurrentNetwork } from '../../../../model';
import { TransactionResponse } from '@ethersproject/providers';
import { ConfirmPrivateSaleDialogComponent } from '../../../modal/dialogs/confirm-private-sale-dialog/confirm-private-sale-dialog.component';
import { Router } from '@angular/router';
import { DetailsPrivateSaleComponent } from '../details-private-sale/details-private-sale.component';
import { AddressZero } from '@ethersproject/constants';
import { BaseComponent } from '../../base.component';

export enum LPState {
  srcToken,
  destToken,
  globalToken
}

export enum ApproveState {
  Init,
  token,
  srcToken
}

export enum OpportunityStatus {
  notStarted,
  running,
  finished
}

@Component({
  selector: 'app-invest-private-sale',
  templateUrl: './invest-private-sale.component.html',
  styleUrls: ['./invest-private-sale.component.scss']
})
export class InvestPrivateSaleComponent
  extends BaseComponent
  implements OnInit
{
  public dataSource = [
    {
      state: 'Invest',
      totalInvest: 0,
      totalIn: 0,
      totalOut: 0
    }
  ];
  public didRegistry: boolean = false;
  public didSign: boolean = false;
  public didChecked: boolean = false;
  public fractalId = 123;
  public lpDetails: boolean = false;
  public dialogOpened: boolean = false;
  public closedWaitingDialog: boolean = false;
  public currentTokenList: CrowdToken[] = [];
  public token!: CrowdToken;
  public tokenAmount = '0';
  public investmentAmount = '111111111111';
  public investTx: any;
  public tokenInputNotifier = new Subject();
  public loadingAmount: boolean = true;
  public disableConfirm: boolean = true;

  public InvestmentType = OpportunityInvestmentType;
  public investmentType: OpportunityInvestmentType =
    OpportunityInvestmentType.ByToken;
  public OpportunityStatus = OpportunityStatus;
  public opportunityStatus;
  public OpportunityState = OpportunityState;
  public opportunityState: OpportunityState = OpportunityState.Init;
  public crossChainState: CrossChainState = CrossChainState.SrcInit;
  public approveState: ApproveState = ApproveState.Init;

  public pendingTxList: PendingTransactionModel[] = [];
  public amount: any;
  public blockCrossChain: boolean = false;
  public fallbackToken!: CrowdToken;

  static activePresale: any;
  public activePresale = InvestPrivateSaleComponent.activePresale;
  public tooltip: boolean = false;
  public tooltipInvestmentAmount: boolean = false;

  private opportunities;
  private confirmationInterval: ReturnType<typeof setInterval> | undefined =
    undefined;
  private defaultGas = {
    MAINNET: {
      protocolFee: 0.001,
      price: 101000000000,
      cost: 710000
    },
    BSCMAIN: {
      protocolFee: 0.005,
      price: 5000000000,
      cost: 1600000
    },
    POLYGON_MAINNET: {
      protocolFee: 0.5,
      price: 56000000000,
      cost: 2400000
    },
    AVALANCHE: {
      protocolFee: 0.01,
      price: 100000000000,
      cost: 2600000
    }
  };
  public startTime: string = '';
  public finishTime: string = '';
  public currentTime;
  public totalInvested = 0;
  public totalIn = 0;
  public poolDetails;

  public startTimeRemain: {
    minutesLeft: number;
    daysLeft: number;
    secondsLeft: number;
    hoursLeft: number;
  } = { daysLeft: 0, hoursLeft: 0, minutesLeft: 0, secondsLeft: 0 };
  public finishTimeRemain: {
    minutesLeft: number;
    daysLeft: number;
    secondsLeft: number;
    hoursLeft: number;
  } = { daysLeft: 0, hoursLeft: 0, minutesLeft: 0, secondsLeft: 0 };
  static PRICE_FACTOR: number = 1000000;

  constructor(
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    private toastr: ToastrService,
    protected themeService: ThemeService,
    private priceService: PriceService,
    private cookieService: CookieService,
    private navigatorService: NavigatorService,
    private opportunityService: OpportunityService,
    private debridgeService: DebridgeService,
    private $gtmService: GoogleTagManagerService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private confirmPrivateSaleDialogService: ModalService<ConfirmPrivateSaleDialogComponent>,
    private selectOptionComponentModalService: ModalService<SelectOptionDialogComponent>,
    private utilsService: UtilsService,
    private logger: LoggingService,
    private privateSaleService: PrivateSaleService,
    private router: Router,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    // setInterval(() => {
    //   this.currentTime = Math.floor(Date.now() / 1000);
    //   this.checkPrivateSaleStartConditions();
    //   this.checkPrivateSaleFinishConditions();
    // }, 1000);

    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    this.opportunities = OpportunitiesHolder.Opportunities;

    this.currentTime = Math.floor(Date.now() / 1000);

    this.themeService.darkModeChangeSubject.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });

    if (!this.activePresale) {
      await this.router.navigate(['opportunity']);
    }

    this.web3Service.walletConnectionChangeSubject.subscribe(
      async (connection) => {
        this.opportunityState = connection
          ? OpportunityState.Init
          : OpportunityState.WalletNotConnected;
        this.ref.detectChanges();
      }
    );

    this.web3Service.currentNetworkChangeSubject
      .pipe(
        filter((currentNetwork: CurrentNetwork) => {
          this.loadingAmount = true;
          return currentNetwork.chainId > 0;
        }),
        throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
      )
      .subscribe(async (currentNetwork: CurrentNetwork) => {
        const chainId: number = currentNetwork.chainId;
        this.isWrongNetwork = false;
        this.blockCrossChain = false;
        if (+this.activePresale.chainId === chainId) {
          this.investmentType = OpportunityInvestmentType.ByToken;
        } else {
          this.investmentType = OpportunityInvestmentType.ByCrossChain;
        }

        const observableTokens =
          TokensHolder.ObservableTokenListBySymbol[Networks[chainId]];
        const localStoredTokens: CrowdToken[] =
          this.getLocalStoredTokens(chainId);

        this.currentTokenList = BaseComponent.removeDuplicateTokens(
          observableTokens,
          localStoredTokens
        ); //Remove duplicate tokens

        this.token =
          TokensHolder.ObservableTokenListBySymbol[
            Networks[this.web3Service.getCurrentChainId()]
          ][
            Constants.DEFAULT_PAIRS[
              this.web3Service.getCurrentChainId()
            ].fromToken
          ];
        await this.initialTokens();
        await this.checkKyc();
        this.ref.detectChanges();
      });

    this.web3Service.wrongNetworkSubject.subscribe(
      async (isWrongNetwork: boolean) => {
        if (isWrongNetwork) {
          this.opportunityState = OpportunityState.WrongNetwork;
          this.isWrongNetwork = true;
          this.ref.detectChanges();
        }
      }
    );

    this.web3Service.accountChangeSubject.subscribe(async () => {
      this.didSign = false;
      await this.checkKyc();
      await this.initialTokens();

      if (
        this.opportunityState !== OpportunityState.InsufficientTokenBBalance &&
        this.opportunityState !== OpportunityState.InsufficientTokenABalance
      ) {
        await this.checkIfAllowanceIsNeeded();
      }
    });

    this.tokenInputNotifier
      .pipe(
        debounceTime(OpportunityService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async () => {
        this.loadingAmount = true;
        this.tokenAmount = (+Conversion.adjustFraction(
          this.tokenAmount,
          7
        )).toString();
        await this.changeLPAmount(this.tokenAmount, LPState.globalToken);
      });

    this.poolDetails = await this.privateSaleService.getPoolDetails(
      this.activePresale
    );
    await this.checkPreSaleConditions();
    if (this.isConnected()) {
      await this.checkKyc();
    }

    this.ref.detectChanges();
  }

  async checkKyc() {
    this.didRegistry = await this.privateSaleService.didRegistry(
      this.activePresale,
      await this.web3Service.getWalletAddress()
    );
    this.didSign = await this.privateSaleService.didSign(
      this.activePresale.presaleName
    );
  }

  async updateBalances(withRefresh: boolean = false, investType: number = 1) {
    this.loadingAmount = true;
    this.lpDetails = false;
    this.currentTokenList = await this.web3Service.addBalanceToTokens(
      this.currentTokenList
    );
    this.token.balance = this.currentTokenList.filter(
      (token) => token.address === this.token.address
    )[0].balance;
    if (withRefresh) {
      this.investmentType = investType;

      this.tokenAmount = '1';
      await this.changeLPAmount(this.tokenAmount, LPState.globalToken);
    }
    this.disableConfirm = false;
    this.ref.detectChanges();
  }

  async initialTokens() {
    if (this.isConnected()) {
      await this.updateBalances();
    }
    if (this.investmentType !== OpportunityInvestmentType.ByCrossChain) {
      this.investmentType = OpportunityInvestmentType.ByToken;
    }
    this.tokenAmount = '1';

    await this.changeLPAmount(this.tokenAmount, LPState.globalToken);
  }

  getLocalStoredTokens(chainId): any {
    const tokenList: any[] = [];
    if (localStorage.getItem('tokens')) {
      const localTokens = JSON.parse(localStorage.getItem('tokens')!);
      if (localTokens[chainId]) {
        Object.keys(localTokens[chainId]).forEach((key) => {
          const token = Builder<CrowdToken>()
            .chainId(localTokens[chainId][key].chainId)
            .address(localTokens[chainId][key].address)
            .decimals(localTokens[chainId][key].decimals)
            .symbol(localTokens[chainId][key].symbol)
            .name(localTokens[chainId][key].name + ' • Added by user')
            .build();
          tokenList.push(token);
        });
      }
    }
    return tokenList;
  }

  async approve(): Promise<any> {
    await this.getApprove(this.token);
  }

  async getApprove(token: CrowdToken): Promise<any> {
    let readyToApprove = true;
    try {
      this.logger.info(
        `Start getting approve for token [${token.symbol}], opportunity name = [${this.activePresale.name}], opportunity state = [${OpportunityState.ApprovalNeeded}]`
      );
      const userAddress = this.web3Service.getWalletAddress();
      if (!userAddress) {
        return;
      }
      const data = {
        sourceTokenSymbol: token.symbol
      };
      await this.waitingDialogService.open(WaitingDialogComponent, data);

      // Get approval tx
      let contractAddress = Constants.DEBRIDGE_ADDRESS;
      if (this.investmentType !== this.InvestmentType.ByCrossChain) {
        contractAddress =
          this.opportunities.PRESALE.networks[
            this.web3Service.getCurrentChainId()
          ].contractAddress;
      }
      let approvalTx = await this.web3Service.getApprovalTransactionBySpender(
        contractAddress,
        token.address,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      );
      // Send approval tx
      approvalTx = await this.web3Service
        .sendTransaction(approvalTx)
        .catch((error) => {
          this.changeOpportunityState(OpportunityState.ApprovalNeeded);
          this.showErrorToaster('Error', 'Approval rejected!');
          readyToApprove = false;
          this.opportunityService.setConsoleLog(
            this.activePresale,
            OpportunityState.ApprovalNeeded,
            'Approval transaction is not submitted',
            error
          );
        });
      if (!approvalTx) {
        this.changeOpportunityState(OpportunityState.ApprovalNeeded);
        this.showErrorToaster('Error', 'Something went wrong!');
        this.opportunityService.setConsoleLog(
          this.activePresale,
          OpportunityState.ApprovalNeeded,
          'Approval transaction is empty'
        );
        return false;
      }
      this.opportunityService.setConsoleLog(
        this.activePresale,
        OpportunityState.ApprovalNeeded,
        'Approval transaction is submitted'
      );
      // Wait for approval tx confirmation
      await this.web3Service
        .waitForTransaction(approvalTx, 1)
        .then(async (data) => {
          if (data?.status === 1) {
            this.changeOpportunityState(OpportunityState.ApprovalConfirmed);
            await this.$gtmService.pushTag({
              event: 'successful_approve',
              category: 'opportunity',
              tokenSymbol: this.token.symbol,
              isConnected: this.web3Service.isConnected()
            });
            this.opportunityService.setConsoleLog(
              this.activePresale,
              OpportunityState.ApprovalConfirmed,
              'Approval transaction is confirmed'
            );
          } else {
            this.showErrorToaster('Error', 'Something went wrong!');
            this.changeOpportunityState(OpportunityState.ApprovalNeeded);
            await this.$gtmService.pushTag({
              event: 'failed_approve',
              category: 'opportunity',
              tokenSymbol: this.token.symbol,
              isConnected: this.web3Service.isConnected()
            });
            this.opportunityService.setConsoleLog(
              this.activePresale,
              OpportunityState.ApprovalNeeded,
              'Approval transaction is not confirmed'
            );
          }
        })
        .catch((error) => {
          this.showErrorToaster('Error', 'Something went wrong!');
          this.changeOpportunityState(OpportunityState.ApprovalNeeded);
          readyToApprove = false;
          this.opportunityService.setConsoleLog(
            this.activePresale,
            OpportunityState.ApprovalNeeded,
            'Approval transaction is not confirmed',
            error
          );
        });
      this.logger.info(
        `Finish getting approve for token [${token.symbol}], opportunity name = [${this.activePresale.name}], opportunity state = [${OpportunityState.ApprovalNeeded}]`
      );
    } catch (error) {
      this.showErrorToaster('Error', 'Something went wrong!');
      this.changeOpportunityState(OpportunityState.ApprovalNeeded);
      readyToApprove = false;

      this.opportunityService.setConsoleLog(
        this.activePresale,
        OpportunityState.ApprovalNeeded,
        'Approval transaction has error',
        error
      );
    } finally {
      await this.waitingDialogService.close();
    }
    return readyToApprove;
  }

  isConnected() {
    return this.web3Service.isConnected();
  }

  public async checkIfAllowanceIsNeeded(): Promise<any> {
    try {
      if (this.isCoin(this.token)) {
        return;
      }
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        let contractAddress = Constants.DEBRIDGE_ADDRESS;
        if (this.investmentType !== this.InvestmentType.ByCrossChain) {
          contractAddress =
            this.opportunities.PRESALE.networks[
              this.web3Service.getCurrentChainId()
            ].contractAddress;
        }
        let allowance: BigNumber = await this.web3Service
          .getAllowanceBySpender(
            contractAddress,
            this.token.address,
            userAddress
          )
          .catch((e) => {
            console.log(e);
            this.showErrorToaster('Error', 'Something went wrong!');
            this.changeOpportunityState(OpportunityState.ApprovalRejected);
          });
        let allowanceValue = Conversion.toSafeBigNumberByRemovingFraction(
          allowance.toString()
        );
        let amountInValue = Conversion.toSafeBigNumberByRemovingFraction(
          Conversion.convertStringToDecimal(
            this.tokenAmount,
            this.token.decimals
          ).toString()
        );
        if (allowanceValue.lt(amountInValue)) {
          this.approveState = ApproveState.token;

          console.log('allowance for 1 ' + this.token.symbol);
          this.changeOpportunityState(OpportunityState.ApprovalNeeded);
        }
      }
    } catch (e) {
      console.log(e);
      this.changeOpportunityState(OpportunityState.ApprovalRejected);
    } finally {
      this.ref.detectChanges();
    }
  }

  public async confirm() {
    try {
      this.investTx = undefined;
      this.changeOpportunityState(OpportunityState.Init);
      this.logger.info(
        `Start getting confirm opportunity name = [${this.activePresale.name}], opportunity state = [${OpportunityState.Init}]`
      );
      await this.showWaitingDialog(true);
      let totalPending = 0;
      const pendingList = this.pendingTxList.filter(
        (pending) => pending.sourceTokenSymbol === this.token.symbol
      );
      pendingList.forEach((pending) => (totalPending += +pending.amountIn));
      this.token.price = await this.priceService.getPrice(
        this.token,
        this.web3Service.getNetworkProvider(this.token.chainId)
      );

      const wToken =
        this.opportunities.PRESALE.networks[this.activePresale.chainId].wToken;
      if (this.investmentType === OpportunityInvestmentType.ByCrossChain) {
        this.fallbackToken = this.opportunities.PRESALE.networks[
          this.activePresale.chainId
        ].middleToken
          ? this.opportunities.PRESALE.networks[this.activePresale.chainId]
              .middleToken
          : this.opportunities.PRESALE.networks[this.activePresale.chainId]
              .wToken;

        wToken.price = await this.priceService.getPrice(
          wToken,
          this.web3Service.getNetworkProvider(wToken.chainId)
        );
      }

      this.investTx = await this.privateSaleService.getPresaleTX(
        this.activePresale,
        this.opportunities.PRESALE.name,
        this.web3Service.getWalletAddress(),
        this.token,
        Conversion.convertStringToDecimal(
          this.tokenAmount,
          this.token.decimals
        ).toString(),
        this.investmentType === OpportunityInvestmentType.ByCrossChain
          ? CrowdSwapService.OPPORTUNITY_SLIPPAGE
          : CrowdSwapService.SLIPPAGE,
        CrowdSwapService.DEADLINE,
        this.investmentType === OpportunityInvestmentType.ByCrossChain
          ? wToken.price
          : this.token.price,
        this.investmentType === OpportunityInvestmentType.ByCrossChain
          ? OpportunityInvestmentType.ByToken
          : this.investmentType
      );
      if (!this.investTx) {
        this.showErrorToaster('Error', 'Something went wrong!');
        await this.waitingDialogService.close();
        this.opportunityService.setConsoleLog(
          this.activePresale,
          this.opportunityState,
          'Confirmed transaction is empty'
        );
        return;
      }
      this.opportunityService.setConsoleLog(
        this.activePresale,
        this.opportunityState,
        'Confirmed transaction is received from backend'
      );

      const stage = await this.privateSaleService.getStage(this.activePresale);
      const amount = +Conversion.convertStringFromDecimal(
        this.investTx.amount,
        TokensHolder.TokenListByAddress[
          NetworksById[this.activePresale.chainId]
        ][this.activePresale.investToken].decimals
      );
      const amountOut = (
        (await this.isMinimumPrice())
          ? amount / this.poolDetails.priceStages[0][1]
          : amount /
            +Conversion.convertStringFromDecimal(stage[1].toString(), 6)
      )
        .toFixed(4)
        .toString();
      let minAmountOut;
      if (this.investmentType === this.InvestmentType.ByCrossChain) {
        minAmountOut = UtilsService.deductLimitMargin(
          this.investTx.amount.toString(),
          +CrowdSwapService.OPPORTUNITY_SLIPPAGE
        );
      } else {
        if (
          this.token.address.toLowerCase() ===
          this.activePresale.investToken.toLowerCase()
        ) {
          minAmountOut = this.investTx.amount;
        } else {
          minAmountOut = UtilsService.deductLimitMargin(
            this.investTx.amount.toString(),
            +CrowdSwapService.SLIPPAGE
          );
        }
      }
      minAmountOut = Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(
          minAmountOut.toString(),
          TokensHolder.TokenListByAddress[
            NetworksById[this.activePresale.chainId]
          ][this.activePresale.investToken].decimals
        )
      );

      const presaleToken = new CrowdToken(
        this.activePresale.chainId,
        AddressZero,
        this.activePresale.presaleTokenDecimal,
        this.activePresale.presaleTokenName,
        this.activePresale.presaleTokenName,
        (await this.isMinimumPrice())
          ? this.poolDetails.priceStages[0][1]
          : +Conversion.convertStringFromDecimal(stage[1].toString(), 6)
      );
      const [unusualMode, differenceInPercent, sourceValue, destValue] =
        this.checkUnusualSituation(
          this.token,
          presaleToken,
          Conversion.convertStringToDecimal(
            this.tokenAmount,
            this.token.decimals
          ),
          Conversion.convertStringToDecimal(
            amountOut,
            this.activePresale.presaleTokenDecimal
          )
        );
      if (unusualMode) {
        let message =
          `Invest in CrowdSale faced unusualMode,` +
          ` CrowdSaleName: ${this.activePresale.presaleDisplayName},` +
          ` CrowdSaleToken: ${this.activePresale.presaleTokenName}(${this.activePresale.chainId}),` +
          ` CrowdSaleInvestToken: ${
            TokensHolder.TokenListByAddress[
              NetworksById[this.activePresale.chainId]
            ][this.activePresale.investToken].symbol
          }(${this.activePresale.chainId})(${
            this.activePresale.investToken
          }),` +
          ` token: ${this.token.symbol}(${this.token.chainId})(${this.token.address}),` +
          ` amountIn: ${this.tokenAmount}, amountInInUSD: ${sourceValue},` +
          ` amountOut: ${amountOut}, amountOutInUSD: ${destValue}, minAmountOut: ${minAmountOut},` +
          ` delegatedDex: ${this.investTx.delegatedDex}, route: ${this.investTx.route}`;
        if (this.investmentType === OpportunityInvestmentType.ByCrossChain) {
          message =
            message + `, deUSDCAmountOut: ${this.investTx.deUSDCAmountOut}`;
        }
        console.info(message);
      }
      const data = {
        amountIn: this.tokenAmount,
        amountOut: amountOut,
        minAmountOut: minAmountOut,
        sourceValue: sourceValue,
        destValue: destValue,
        sourceTokenSymbol: this.token.symbol,
        destTokenSymbol: this.activePresale.presaleTokenName,
        activeOpportunity: this.activePresale,
        sourceNetworkName:
          this.web3Service.networkSpec[this.web3Service.getCurrentChainId()]
            .title,
        targetNetworkName:
          this.investmentType === this.InvestmentType.ByCrossChain
            ? this.web3Service.networkSpec[this.activePresale.chainId].title
            : '',
        unusualMode: unusualMode,
        differenceInPercent: Conversion.adjustFraction(
          differenceInPercent.toString()
        )
      };
      if (this.closedWaitingDialog) {
        await this.waitingDialogService.close();
        return;
      }

      await this.showConfirmationDialog(data);
      this.logger.info(
        `Finish getting confirm opportunity name = [${this.activePresale.name}], opportunity state = [${OpportunityState.Init}]`
      );
    } catch (error) {
      await this.waitingDialogService.close();
      this.showErrorToaster('Error', 'Something went wrong!');
      this.opportunityService.setConsoleLog(
        this.activePresale,
        OpportunityState.Init,
        'Confirming transaction is failed',
        error
      );
    }
  }

  private async isMinimumPrice() {
    if (
      this.currentTime - +this.startTime <
      +this.poolDetails.firstHoursBestPrice.toString() * 60 * 60
    ) {
      return true;
    }
    const userDetail = await this.privateSaleService.getUserDetails(
      this.activePresale
    );
    return userDetail.state === 1;
  }

  async confirmInvest(investTx: any) {
    try {
      this.logger.info(
        `Start getting final confirm opportunity name = [${this.activePresale.presaleName}], opportunity state = [${OpportunityState.Init}], transaction = [${investTx}] `
      );
      await this.showWaitingDialog();
      if (investTx.value) {
        investTx.value = BigNumber.from(investTx.value);
      }
      const presaleInvestTx = {
        from: investTx.from,
        to: investTx.to,
        data: investTx.data,
        value: investTx.value,
        gas: investTx.gasLimit,
        gasLimit: investTx.gasLimit
      };

      let currentTransaction: any;
      currentTransaction = await this.web3Service
        .sendTransaction(presaleInvestTx)
        .then(async (data) => {
          await this.waitingDialogService.close();
          this.changeOpportunityState(OpportunityState.Confirmed);
          if (this.investmentType === OpportunityInvestmentType.ByCrossChain) {
            this.lpDetails = true;
          }
          this.opportunityService.setConsoleLog(
            this.activePresale,
            this.opportunityState,
            `invest in presale ${this.activePresale.displayName} submitted`
          );
          const pendingTx: PendingTransactionModel = {
            sourceTokenSymbol: this.token.symbol!,
            destTokenSymbol: this.token.symbol!,
            amountIn: this.tokenAmount,
            amountOut: this.tokenAmount,
            chainId: +this.activePresale.chainId,
            hash: data
          };
          this.addToPendingTxList(pendingTx);
          this.ref.detectChanges();
          return data;
        })
        .catch(async (error) => {
          await this.waitingDialogService.close();
          this.showErrorToaster('Error', 'Investment rejected!');
          this.lpDetails = false;
          this.changeOpportunityState(OpportunityState.Rejected);
          this.crossChainState = CrossChainState.SrcFailed;
          this.opportunityService.setConsoleLog(
            this.activePresale,
            OpportunityState.Rejected,
            'Investment rejected!',
            error
          );
        });

      if (currentTransaction) {
        // Wait for swap tx confirmation
        await this.web3Service
          .waitForTransaction(currentTransaction, 1)
          .then(async (data) => {
            if (data?.status === 1) {
              if (
                this.investmentType === OpportunityInvestmentType.ByCrossChain
              ) {
                this.crossChainState = CrossChainState.SrcSuccessful;
                await this.observeCrossChainSwapStatus(currentTransaction);
              } else {
                this.changeOpportunityState(OpportunityState.Successful);
                this.opportunityService.setConsoleLog(
                  this.activePresale,
                  this.opportunityState,
                  'Opportunity successful.'
                );
                DetailsPrivateSaleComponent.activePresale = this.activePresale;
                await this.router.navigate([
                  'opportunity/private-sale-details'
                ]);
              }
              this.ref.detectChanges();
            } else {
              this.changeOpportunityState(OpportunityState.Failed);
              this.crossChainState = CrossChainState.SrcFailed;
              this.opportunityService.setConsoleLog(
                this.activePresale,
                this.opportunityState,
                'Opportunity failed.'
              );
            }
            try {
              let pendingTx = this.findFromPendingList(currentTransaction);
              if (!pendingTx) {
                return;
              }
              if (
                this.investmentType === OpportunityInvestmentType.ByCrossChain
              ) {
                const interval = setInterval(async () => {
                  if (this.confirmationInterval === undefined) {
                    this.removeFromPendingTxList(currentTransaction);
                    clearInterval(interval);
                  }
                }, 1000);
              } else {
                this.removeFromPendingTxList(currentTransaction);
              }
              await this.showToastr(currentTransaction);
            } catch (error) {
              this.opportunityService.setConsoleLog(
                this.activePresale,
                this.opportunityState,
                'Failed to show toaster or push tag',
                error
              );
            }
            return data;
          })
          .catch((error) => {
            this.changeOpportunityState(OpportunityState.Failed);
            this.removeFromPendingTxList(currentTransaction);
            this.opportunityService.setConsoleLog(
              this.activePresale,
              OpportunityState.Failed,
              'Opportunity failed.',
              error
            );
          });
      }
      this.logger.info(
        `Finish getting final confirm opportunity name = [${this.activePresale.name}], opportunity state = [${OpportunityState.Init}]`
      );
    } catch (error) {
      this.opportunityService.setConsoleLog(
        this.activePresale,
        OpportunityState.Failed,
        'Opportunity failed in external catch.',
        error
      );
    }
  }

  private async observeCrossChainSwapStatus(
    currentTransaction: TransactionResponse
  ) {
    let fallbackTokenInUSDT;
    let retryCount = 0;
    let validatorsCount = 0;
    this.confirmationInterval = setInterval(async () => {
      retryCount++;

      // The submissionInfo is used to get the swap status
      let submissionInfo;
      try {
        submissionInfo = <any>(
          await this.debridgeService.getFullSubmissionInfo(currentTransaction)
        );
      } catch (error) {
        this.opportunityService.setConsoleLog(
          this.activePresale,
          OpportunityState.Init,
          ' FullSubmissionInfo catch. ',
          error,
          CrossChainState.SrcSuccessful
        );
      }
      if (retryCount >= commonConfig.CROSS_CHAIN.CCLP_TIMEOUT) {
        this.clearConfirmationInterval();
        this.changeOpportunityState(OpportunityState.Failed);
        this.crossChainState = CrossChainState.DstFailed;
        this.opportunityService.setConsoleLog(
          this.activePresale,
          OpportunityState.Failed,
          ' Timeout after ' + retryCount + ' retry count',
          undefined,
          CrossChainState.DstFailed
        );
        return;
      }
      if (validatorsCount < 12) {
        try {
          if (!submissionInfo?.send?.submissionId) {
            return;
          }

          // Get validation count of the transaction
          validatorsCount = <any>(
            await this.debridgeService.getForSubmission(
              submissionInfo.send.submissionId
            )
          );

          // Get destination token balance while CCLP is in progress
          fallbackTokenInUSDT =
            fallbackTokenInUSDT ||
            Conversion.convertStringToDecimal(
              (
                +this.fallbackToken.balance * +this.fallbackToken.price
              ).toString(),
              this.fallbackToken.decimals
            );

          // There is enough validation or the transaction is sent to the destination network
          if (validatorsCount >= 11 || submissionInfo.send.isExecuted) {
            validatorsCount = 12;
            this.opportunityService.setConsoleLog(
              this.activePresale,
              OpportunityState.Init,
              ' Last step with validatorsCount or isExecuted. ',
              undefined,
              CrossChainState.SrcSuccessful
            );
            retryCount = 0;
          }
        } catch (error) {
          this.opportunityService.setConsoleLog(
            this.activePresale,
            OpportunityState.Failed,
            ' Failed CCLP transaction. ',
            error,
            CrossChainState.DstFailed
          );
        }
      } else {
        if (!submissionInfo.claim) {
          return;
        }
        this.opportunityService.setConsoleLog(
          this.activePresale,
          OpportunityState.Confirmed,
          ' Got claim and clear interval. ',
          undefined,
          CrossChainState.SrcSuccessful
        );
        this.clearConfirmationInterval();

        try {
          // Wait for claim tx confirmation
          const claimTxResult = await this.web3Service.waitForTransaction(
            submissionInfo.claim.transactionHash,
            1,
            this.web3Service.getNetworkProvider(this.activePresale.chainId)
          );
          if (!claimTxResult) {
            this.opportunityService.setConsoleLog(
              this.activePresale,
              OpportunityState.Failed,
              ' Failed claimTxResult. ',
              undefined,
              CrossChainState.DstFailed
            );
          }
          if (claimTxResult?.status !== 1) {
            this.changeOpportunityState(OpportunityState.Failed);
            this.crossChainState = CrossChainState.DstFailed;
            this.opportunityService.setConsoleLog(
              this.activePresale,
              OpportunityState.Failed,
              ' Failed last transaction.',
              undefined,
              CrossChainState.DstFailed
            );
            await this.showToastr(currentTransaction);
            return;
          } else {
            this.crossChainState = CrossChainState.DstSuccessful;
          }

          let lastBalanceInUSDT;
          try {
            const wTokenBalance = await this.web3Service.getBalance(
              this.fallbackToken,
              this.web3Service.getNetworkProvider(this.activePresale.chainId)
            );
            lastBalanceInUSDT = wTokenBalance!
              .mul((+this.fallbackToken.price * 10000).toFixed())
              .div(10000);
          } catch (error) {
            this.changeOpportunityState(OpportunityState.Failed);
            this.crossChainState = CrossChainState.DstWarningCatch;
            this.opportunityService.setConsoleLog(
              this.activePresale,
              OpportunityState.Failed,
              ' Failed last balance.',
              error,
              CrossChainState.DstFailed
            );
            await this.showToastr(currentTransaction);
            return;
          }
          if (lastBalanceInUSDT) {
            if (
              !lastBalanceInUSDT.lt(
                fallbackTokenInUSDT.add(
                  Conversion.convertStringToDecimal(
                    (+this.investmentAmount / 2).toString(),
                    this.opportunities.PRESALE.networks[
                      this.activePresale.chainId
                    ].wToken.decimals
                  )
                )
              )
            ) {
              this.changeOpportunityState(OpportunityState.Failed);
              this.crossChainState = CrossChainState.DstWarning;
              this.opportunityService.setConsoleLog(
                this.activePresale,
                OpportunityState.Failed,
                ' deUSDC received by fallback address.',
                undefined,
                CrossChainState.DstFailed
              );
            } else {
              this.changeOpportunityState(OpportunityState.Successful);
              this.opportunityService.setConsoleLog(
                this.activePresale,
                OpportunityState.Successful,
                ' Success last transaction. ',
                undefined,
                CrossChainState.DstSuccessful
              );
            }
          } else {
            this.changeOpportunityState(OpportunityState.Failed);
            this.crossChainState = CrossChainState.DstWarning;
            this.opportunityService.setConsoleLog(
              this.activePresale,
              OpportunityState.Failed,
              ' Failed last balance.',
              CrossChainState.DstFailed
            );
          }
          await this.showToastr(currentTransaction);
        } catch (error) {
          this.changeOpportunityState(OpportunityState.Failed);
          this.crossChainState = CrossChainState.DstFailed;
          this.opportunityService.setConsoleLog(
            this.activePresale,
            OpportunityState.Failed,
            ' Failed last transaction in catch.',
            error,
            CrossChainState.DstFailed
          );
        }
      }
    }, 10 * 1000);
  }

  private clearConfirmationInterval() {
    if (this.confirmationInterval) {
      clearInterval(this.confirmationInterval);
      this.confirmationInterval = undefined;
    }
  }

  private async showToastr(currentTransaction: any) {
    const scanTransactionUrl =
      this.web3Service.getScanTransactionUrl(currentTransaction);
    switch (this.opportunityState) {
      case OpportunityState.Successful: {
        this.toastr.success(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          'Transaction successful',
          // `Deposit of ${this.tokenB.symbol} and ${this.tokenA.symbol} ($${this.investmentAmount}) successfully executed!`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'successClass'
          }
        );
        break;
      }
      case OpportunityState.Failed: {
        this.toastr.error(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          `Transaction failed`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'failedClass'
          }
        );
        return;
      }
    }
  }

  private changeOpportunityState(opportunityState: OpportunityState) {
    this.opportunityState = opportunityState;
  }

  private async getSrcDestData(estimation: boolean = false) {
    if (!estimation) {
      return {
        stakeState: 'Invest'
      };
    }
    return {
      sourcePrice: this.investTx
        ? this.getAmount(this.investTx.amountDesiredTokenB, this.token.decimals)
        : this.tokenAmount,
      isEstimation: estimation,
      isDarkMode: this.isDarkMode
    };
  }

  private async showWaitingDialog(estimation: boolean = false) {
    this.closedWaitingDialog = false;
    const data = await this.getSrcDestData(estimation);
    let waitingDialogRef = await this.waitingDialogService.open(
      WaitingDialogComponent,
      data
    );
    const sub: Subscription = (<ConfirmOpportunityDialogComponent>(
      (<any>waitingDialogRef!.instance)
    )).closed.subscribe(async () => {
      this.closedWaitingDialog = true;
    });
    waitingDialogRef!.onDestroy(async () => {
      this.closedWaitingDialog = true;
      sub.unsubscribe();
    });
    this.ref.detectChanges();
  }

  private async showConfirmationDialog(data) {
    let confirmOppDialogRef = await this.confirmPrivateSaleDialogService.open(
      ConfirmPrivateSaleDialogComponent,
      data
    );

    const sub: Subscription = (<ConfirmPrivateSaleDialogComponent>(
      (<any>confirmOppDialogRef!.instance)
    )).confirmed.subscribe(async (event) => {
      if (event) {
        await this.confirmPrivateSaleDialogService.close();
        await this.waitingDialogService.close();
        await this.confirmInvest(this.investTx);
        this.opportunityService.setConsoleLog(
          this.activePresale,
          this.opportunityState,
          'Confirmed transaction has been sent to the provider'
        );
      } else {
        await this.waitingDialogService.close();
        await this.confirmPrivateSaleDialogService.close();
        this.opportunityService.setConsoleLog(
          this.activePresale,
          OpportunityState.Init,
          'Confirmed transaction has not been sent to the provider'
        );
      }
    });
    this.ref.detectChanges();
    confirmOppDialogRef!.onDestroy(async () => {
      await this.waitingDialogService.close();
      sub.unsubscribe();
    });
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  notifyTokenInput(event) {
    this.disableConfirm = true;
    this.tokenInputNotifier.next(event);
  }

  async openSelectOptionDialog(allTokens) {
    if (this.dialogOpened) {
      return;
    }
    this.loadingAmount = true;
    this.dialogOpened = true;
    const data = {
      allTokens: allTokens,
      filteredTokenList: this.currentTokenList,
      toggle: true,
      tokenSymbol: this.token.symbol,
      chainId: this.web3Service.getCurrentChainId(),
      isDarkMode: this.isDarkMode,
      withBalance: true
    };
    let selectOptionDialogRef =
      await this.selectOptionComponentModalService.open(
        SelectOptionDialogComponent,
        data
      );
    const sub: Subscription = (<SelectOptionDialogComponent>(
      (<any>selectOptionDialogRef!.instance)
    )).confirmed.subscribe(async (token) => {
      if (token) {
        this.token = <any>token;
        this.tokenAmount = '1';
        await this.changeLPAmount('1', LPState.globalToken);
        if (this.investmentType !== this.InvestmentType.ByCrossChain) {
          this.investmentType = this.InvestmentType.ByToken;
        }
      } else {
        this.loadingAmount = false;
      }
      this.dialogOpened = false;
      await this.selectOptionComponentModalService.close();
    });
    const subscribeImportNewToken: Subscription = (<
      SelectOptionDialogComponent
    >(<any>selectOptionDialogRef!.instance)).confirmedImportToken.subscribe(
      async (token: CrowdToken) => {
        this.currentTokenList.push(token);
      }
    );

    selectOptionDialogRef!.onDestroy(() => {
      this.dialogOpened = false;
      this.loadingAmount = false;
      sub.unsubscribe();
      subscribeImportNewToken.unsubscribe();
    });
  }

  async changeLPAmount(amount: string, lpState: number) {
    if (+amount === 0) {
      this.loadingAmount = false;
      this.investmentAmount = '0';
      return;
    }
    let investAmount = 0;
    this.lpDetails = false;
    this.opportunityState = OpportunityState.Init;
    this.investTx = undefined;
    if (this.investmentType === OpportunityInvestmentType.ByCrossChain) {
      if (this.activePresale.chainId === Networks.MAINNET) {
        this.blockCrossChain = true;
      }
      const networkCoin =
        TokensHolder.TokenListBySymbol[
          NetworksById[this.web3Service.getCurrentChainId()]
        ][
          this.web3Service.networkSpec[this.web3Service.getCurrentChainId()]
            .coin
        ];
      const networkCoinInUSDT = await this.priceService.getPrice(
        networkCoin,
        this.web3Service.getNetworkProvider(networkCoin.chainId)
      );
      await this.privateSaleService
        .getInvestAmountByCrossChain(
          this.opportunities.PRESALE,
          this.web3Service.getWalletAddress(),
          this.token,
          this.opportunities.PRESALE.networks[this.activePresale.chainId]
            .wToken,
          this.tokenAmount,
          '3',
          networkCoinInUSDT
        )
        ?.pipe(
          catchError((err) => {
            if (err === Constants.EXECUTION_FEE_ERROR) {
              this.opportunityState = OpportunityState.AmountInIsTooLow;
            } else {
              this.disableConfirm = true;
            }
            this.loadingAmount = false;
            this.investmentAmount = '0';
            return throwError(-1);
          }),
          map(async (minAmountOut: string) => {
            await this.setInvestAmount(+minAmountOut);
          })
        )
        ?.subscribe();
    } else {
      investAmount =
        parseFloat(
          await this.priceService.getPrice(
            this.token,
            this.web3Service.getNetworkProvider(this.token.chainId)
          )
        ) * +amount;
      this.tokenAmount = Conversion.adjustFraction(amount, 7);
    }
    if (+this.token.balance < +amount) {
      this.opportunityState = OpportunityState.InsufficientTokenBBalance;
    }
    await this.setInvestAmount(investAmount);
  }

  private async setInvestAmount(investAmount: number | undefined) {
    if (investAmount) {
      await this.checkIfAllowanceIsNeeded();

      this.investmentAmount = investAmount.toString();

      this.loadingAmount = false;
      this.disableConfirm = false;
      this.ref.detectChanges();
    }
  }

  public async setMaxBalance(token: CrowdToken) {
    if (this.loadingAmount) {
      return;
    }
    let state;
    let maxAmountInDecimal = Conversion.convertStringToDecimal(
      token.balance,
      token.decimals
    );
    if (TokensHolder.isBaseToken(token.chainId, token.address)) {
      if (this.investmentType === this.InvestmentType.ByCrossChain) {
        maxAmountInDecimal = maxAmountInDecimal.sub(
          Conversion.convertStringToDecimal(
            await this.getAllFeesForMaxAmountIn(token.chainId),
            token.decimals
          )
        );
      } else {
        maxAmountInDecimal = maxAmountInDecimal.sub(
          Conversion.convertStringToDecimal(
            Constants.REDUCE_MAX_AMOUNT_IN.toString(),
            token.decimals
          )
        );
      }
    }
    maxAmountInDecimal =
      maxAmountInDecimal > BigNumber.from(0)
        ? maxAmountInDecimal
        : BigNumber.from(0);

    const maxAmountIn = Conversion.convertStringFromDecimal(
      maxAmountInDecimal.toString(),
      token.decimals
    );

    state = LPState.globalToken;
    this.tokenAmount = Conversion.adjustFraction(maxAmountIn, 7);

    this.loadingAmount = true;
    await this.changeLPAmount(maxAmountIn.toString(), state);
  }

  public async getAllFeesForMaxAmountIn(chainId: number) {
    let gasPrice = +Conversion.convertStringFromDecimal(
      (await this.web3Service.getGasPrice()) ||
        this.defaultGas[NetworksById[chainId]].price,
      9
    );
    switch (chainId) {
      case Networks.MAINNET:
        gasPrice = gasPrice * 1.1;
        break;
      case Networks.BSCMAIN:
        gasPrice = gasPrice + 2;
        break;
      case Networks.POLYGON_MAINNET:
        gasPrice = gasPrice * 2;
        break;
      case Networks.AVALANCHE:
        gasPrice = gasPrice * 1.4;
        break;
    }
    return (
      +Conversion.adjustFraction(
        Conversion.convertStringFromDecimal(
          Math.floor(
            gasPrice * (this.defaultGas[NetworksById[chainId]].cost + 21000)
          ).toString(),
          9
        ),
        6
      ) + this.defaultGas[NetworksById[chainId]].protocolFee
    );
  }

  public toFixFraction(num: number | string | undefined, fraction: number = 7) {
    return num ? Conversion.adjustFraction(num, fraction) : '0';
  }

  private getAmount(amount: string, decimal: number, fraction: number = 6) {
    return Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(amount, decimal),
      fraction
    );
  }

  private addToPendingTxList(pendingTx: PendingTransactionModel) {
    this.pendingTxList.push(pendingTx);
    this.web3Service.pendingChangeSubject.next(this.pendingTxList.length);
  }

  private removeFromPendingTxList(hash: string) {
    let pendingTx = this.findFromPendingList(hash);
    if (pendingTx) {
      this.pendingTxList.splice(this.pendingTxList.indexOf(pendingTx), 1);
      this.web3Service.pendingChangeSubject.next(this.pendingTxList.length);
    }
  }

  private findFromPendingList(
    hash: string
  ): PendingTransactionModel | undefined {
    return this.pendingTxList.find((pending) => pending.hash === hash);
  }

  private async checkPreSaleConditions() {
    if (this.poolDetails) {
      this.startTime = this.poolDetails.startTime;
      this.finishTime = this.poolDetails.finishTime;
      if (this.startTime > this.currentTime) {
        this.opportunityStatus = OpportunityStatus.notStarted;
        this.startTimeRemain = this.privateSaleService.getRemainingTime(
          this.startTime,
          this.currentTime
        );
        this.finishTimeRemain = this.privateSaleService.getRemainingTime(
          this.finishTime,
          this.currentTime
        );
      }
      if (
        this.startTime < this.currentTime &&
        this.finishTime > this.currentTime
      ) {
        this.opportunityStatus = OpportunityStatus.running;

        this.startTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0
        };
        this.finishTimeRemain = UtilsService.getRemainingTime(
          this.finishTime,
          this.currentTime
        );
      }
      if (this.finishTime < this.currentTime) {
        this.opportunityStatus = OpportunityStatus.finished;
        this.startTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0
        };
        this.finishTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0
        };
      }
    }
  }

  public async sign() {
    const result = await this.privateSaleService.signMessage(
      this.activePresale.signMessage,
      this.activePresale.presaleName
    );

    console.log('Result : ', result);
    if (result) {
      this.didSign = true;
    }
  }

  public get compensatoryMinInvest(): number {
    //The extra invest required to
    if (!this.poolDetails?.minInvest) {
      return 0;
    }

    let fees = 0;
    if (this.investmentType === OpportunityInvestmentType.ByToken) {
      if (this.activePresale.investToken !== this.token.address) {
        fees =
          +CrowdSwapService.AGGREGATOR_FEE +
          +CrowdSwapService.CURVE_FEE +
          +CrowdSwapService.SLIPPAGE;
      }
    } else {
      fees =
        +CrowdSwapService.AGGREGATOR_FEE +
        +CrowdSwapService.CURVE_FEE +
        +CrowdSwapService.CROSS_CHAIN_ADAPTER_FEE +
        +CrowdSwapService.OPPORTUNITY_SLIPPAGE;
    }

    const com = (this.poolDetails.minInvest * fees) / 100;
    return (
      Math.ceil(com * InvestPrivateSaleComponent.PRICE_FACTOR) /
      InvestPrivateSaleComponent.PRICE_FACTOR
    );
  }

  public get netMinInvest(): number {
    if (!this.poolDetails?.minInvest) {
      return 0;
    }

    const netMin = this.poolDetails.minInvest + this.compensatoryMinInvest;
    return (
      Math.floor(netMin * InvestPrivateSaleComponent.PRICE_FACTOR) /
      InvestPrivateSaleComponent.PRICE_FACTOR
    );
  }

  public get maximumValueToInvest(): number {
    if (!this.poolDetails) {
      return 0;
    }
    const max = this.poolDetails?.hardCap - this.poolDetails?.investedAmount;
    return (
      Math.floor(max * InvestPrivateSaleComponent.PRICE_FACTOR) /
      InvestPrivateSaleComponent.PRICE_FACTOR
    );
  }

  private checkUnusualSituation(tokenIn, tokenOut, amountIn, amountOut) {
    let sourceValue: number;
    let destinationValue: number;

    let valueFrom = this.priceService.getTokenValueByAmount(tokenIn, amountIn);
    sourceValue = valueFrom === undefined ? 0 : +valueFrom;

    let valueTo = this.priceService.getTokenValueByAmount(tokenOut, amountOut);
    destinationValue = valueTo === undefined ? 0 : +valueTo;

    return [
      destinationValue <
        sourceValue - (sourceValue * UNUSUAL_MODE_PERCENTAGE) / 100,
      ((sourceValue - destinationValue) * 100) / sourceValue,
      sourceValue,
      destinationValue
    ];
  }

  async navigateToCrossChain() {
    await this.navigatorService.navigate('exchange', {
      from_chain_id: this.token.chainId,
      from_token_symbol: this.token.symbol,
      to_chain_id: this.activePresale.chainId,
      to_token_symbol: 'ETH'
    });
  }
}
