import {
  Conversion,
  CrowdToken,
  DEX,
  Dexchanges,
  PriceService
} from '@crowdswap/constant';
import { Builder } from 'builder-pattern';
import {
  CrossAndSameChainEstimateTrade,
  CrossChainSwapState,
  SearchParamModel
} from 'src/app/model';
import { Constants } from 'src/app/constants';
import {
  ConnectWalletService,
  DeviceType,
  INotificationData,
  NDDClientInfoServiceImpl,
  NotificationService,
  StorageService,
  TagManagerService,
  ThemeService,
  TokensService,
  Web3Service
} from 'src/app/services';
import { BaseComponent } from '../base.component';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TransactionSubmittedDialogComponent } from '../../modal/dialogs/transaction-submitted-dialog/transaction-submitted-dialog.component';
import { ModalService } from '../../modal/modal.service';
import { WaitingDialogComponent } from '../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ConfirmSwapDialogComponent } from '../../modal/dialogs/confirm-swap-dialog/confirm-swap-dialog.component';
import { environment } from 'src/environments/environment';
import { FeedbackService } from '../../../services/feedback.service';
import { ExchangeType, HistoryTabs } from './model/cross-chain-state.enum';
import { EstimateTrade } from '../../../model/estimate-trade.model';

export enum SwapState {
  Init,
  WalletNotConnected,
  ApprovalNeeded,
  ApprovalConfirmed,
  ApprovalRejected,
  SwapConfirmed,
  SwapFailed,
  Successful,
  InsufficientSourceBalance
}

@Component({
  template: ''
})
export abstract class BaseSwapComponent
  extends BaseComponent
  implements OnInit
{
  public static PENDING_TRADE_LIST_STORAGE_NAME = 'pendingTradeList';
  public setting: {
    zeroAddress: string;
    isDarkMode: boolean;
    baseLogoIconsUrl: string;
    isReceiverWalletSelected: boolean;
    minimize: boolean;
    dollarBase: boolean;
    slippage: string;
    crossChainSlippage: string;
    deadline: string;
    expertMode: boolean;
    countDownTimer: number;
    isMobile: boolean;
  } = {
    zeroAddress: '0x0000000000000000000000000000000000000000',
    isDarkMode: false,
    baseLogoIconsUrl: environment.BaseLogoIconsUrl,
    isReceiverWalletSelected: false,
    minimize: false,
    dollarBase: false,
    slippage: '',
    crossChainSlippage: '',
    deadline: '',
    expertMode: false,
    countDownTimer: 100,
    isMobile: false
  };

  public status: {
    chainId;
    supportedNetwork: boolean;
    walletAddress: string;
    isUserWalletConnected: boolean;
    isWrongNetwork: boolean;
    incorrectNetworkMessage: string;
    isAddressValid: boolean;
    isMismatchNetwork: boolean;
    showMoreFee: boolean;
    historyState: HistoryTabs;
    exchangeType: ExchangeType;
  } = {
    chainId: -1,
    supportedNetwork: false,
    walletAddress: '',
    isUserWalletConnected: false,
    isWrongNetwork: false,
    incorrectNetworkMessage: '',
    isAddressValid: false,
    isMismatchNetwork: false,
    historyState: HistoryTabs.All,
    exchangeType: ExchangeType.Market,
    showMoreFee: false
  };

  public state: {
    walletAddress: string | undefined;
    receiverAddress: string;
    activeEstimationTrade: CrossAndSameChainEstimateTrade;
    pendingTradeList: any[]; //TODO it must be CrossChainEstimateTrade[]
    searchParam?: SearchParamModel;
    estimationSubscription: Subscription | undefined;
    autoSearchSubscriber: any;
    hasAutoSearchSubscriber: boolean;
    exchangeTagHeight: number;
    hasPreviousMinAmountOut: boolean;
  } = {
    walletAddress: undefined,
    receiverAddress: '',
    activeEstimationTrade: { loading: true } as any,
    pendingTradeList: [],
    estimationSubscription: undefined,
    autoSearchSubscriber: null,
    hasAutoSearchSubscriber: true,
    exchangeTagHeight: 0,
    hasPreviousMinAmountOut: false
  };

  constructor(
    web3Service: Web3Service,
    themeService: ThemeService,
    tagManagerService: TagManagerService,
    protected waitingDialogService: ModalService<WaitingDialogComponent>,
    protected txSubmittedDialogService: ModalService<TransactionSubmittedDialogComponent>,
    protected notificationService: NotificationService,
    protected priceService: PriceService,
    protected confirmDialogService: ModalService<ConfirmSwapDialogComponent>,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    protected storageService: StorageService,
    protected tokensService: TokensService,
    protected feedbackService: FeedbackService,
    protected connectWalletService?: ConnectWalletService
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  protected abstract getEstimation(searchParam: SearchParamModel);
  protected abstract analyzeEstimationCost(trade: EstimateTrade);
  protected abstract getSwapTransaction(
    userAddress,
    trade: EstimateTrade,
    searchParam: SearchParamModel
  );
  protected abstract confirmSwapTransaction(trade: EstimateTrade);
  protected abstract observeSwapTransactionStatus(trade: EstimateTrade);
  protected abstract preparePatchNewAmount(
    pendingTrade: CrossAndSameChainEstimateTrade
  );
  protected abstract approvePatchNewAmount(
    pendingTrade: CrossAndSameChainEstimateTrade
  );
  protected abstract checkAllowance(trade: EstimateTrade);
  protected abstract getApprovalTransaction(trade: EstimateTrade);
  protected async sendApprovalTransaction(trade, approvalTx) {
    try {
      const approvalSignedTx = await this.web3Service
        .sendTransaction(approvalTx)
        .catch((e) => {
          console.log(e);
          this.changeCrossChainSwapState(
            trade,
            CrossChainSwapState.ApprovalRejected
          );
          this.notificationService.error({
            title: 'Error',
            content: 'Approval rejected!'
          });
          return false;
        });
      if (!approvalSignedTx) {
        return false;
      }

      await this.web3Service
        .waitForTransaction(
          approvalSignedTx,
          1,
          this.web3Service.getNetworkProvider(
            this.web3Service.getCurrentChainId()
          )
        )
        .then(async (data) => {
          if (data?.status === 1) {
            await this.checkAllowance(trade);
            this.changeCrossChainSwapState(
              trade,
              CrossChainSwapState.ApprovalConfirmed
            );
          } else {
            this.changeCrossChainSwapState(
              trade,
              CrossChainSwapState.ApprovalNeeded
            );
          }
        })
        .catch((e) => {
          console.log(e);
          this.changeCrossChainSwapState(
            trade,
            CrossChainSwapState.ApprovalRejected
          );
          this.notificationService.error({
            title: 'Error',
            content: 'Approval rejected!'
          });
        });
    } catch (e) {
      console.log(e);
      this.changeCrossChainSwapState(
        trade,
        CrossChainSwapState.ApprovalRejected
      );
      this.notificationService.error({
        title: 'Error',
        content: 'Approval rejected!'
      });
      return false;
    }
  }

  public ngOnInit(
    onCurrentNetworkChange: ((currentNetwork) => void) | undefined = undefined,
    onWalletConnectionChange: ((connection) => void) | undefined = undefined,
    onAccountChange: ((address) => Promise<void>) | undefined = undefined
  ): void {
    super.ngOnInit(
      onCurrentNetworkChange,
      onWalletConnectionChange,
      onAccountChange
    );

    // this.setting.isMobile =
    //   [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
    //     this.clientInfoServiceImpl.getDeviceType()
    //   ) > -1;
  }

  public static GetFeeErrorEstimation(estimation: {
    crossChainName: string;
    tokenIn: CrowdToken;
    tokenOut: CrowdToken;
    amountIn: string;
    status: number;
    msg: string;
  }): CrossAndSameChainEstimateTrade {
    return Builder<CrossAndSameChainEstimateTrade>()
      .dex(Dexchanges.CrossChain)
      .fromToken(estimation.tokenIn)
      .toToken(estimation.tokenOut)
      .amountIn(estimation.amountIn)
      .amountInDisplay(
        Conversion.adjustFraction(
          Conversion.convertStringFromDecimal(
            estimation.amountIn,
            estimation.tokenIn.decimals
          ),
          6
        )
      )
      .delegatedDex('')
      .swapFeeInUSDT('0')
      .networkFeeInUSDT('0') //TODO the network fee must be shown somehow
      .crowdswapFeeInUSDT('0')
      .totalFeeInUSDT('0')
      .totalPaidInUSDT('0')
      .totalIncomeInUSDT('0')
      .priceImpact('')
      .crossChainName(estimation.crossChainName)
      .status(estimation.status)
      .msg(estimation.msg)
      .loading(false)
      .expirationDate(new Date(Date.now() + Constants.CROSSCHAIN_TX_LIFE_TIME))
      .build();
  }

  protected async setBestEstimation(
    bestEstimation: CrossAndSameChainEstimateTrade | undefined
  ): Promise<CrossAndSameChainEstimateTrade> {
    if (!bestEstimation) {
      this.state.activeEstimationTrade = {
        ...BaseSwapComponent.getDummyEstimationTrade(),
        crossChainSwapState: CrossChainSwapState.Init,
        minimize: false
      };
      return this.state.activeEstimationTrade;
    }

    if (bestEstimation.loading) {
      this.state.activeEstimationTrade = {
        ...bestEstimation,
        minimize: false
      };
      return this.state.activeEstimationTrade;
    }

    const nextCrossChainSwapState =
      bestEstimation.crossChainSwapState === CrossChainSwapState.NotFound
        ? CrossChainSwapState.NotFound
        : !this.status.isUserWalletConnected
        ? CrossChainSwapState.WalletNotConnected
        : CrossChainSwapState.Init;

    const nextEstimation = {
      ...bestEstimation,
      crossChainSwapState: nextCrossChainSwapState,
      minimize: false,
      loading: false
    };

    const isNotStateToCheck = (state: CrossChainSwapState) =>
      !this.isCrossChainSwapState(nextEstimation, state);

    if (
      !bestEstimation.loading &&
      isNotStateToCheck(CrossChainSwapState.WalletNotConnected) &&
      isNotStateToCheck(CrossChainSwapState.InsufficientSourceBalance) &&
      isNotStateToCheck(CrossChainSwapState.NotFound)
    ) {
      await this.checkBalance(
        nextEstimation,
        nextEstimation.fromToken,
        this.getAmount(
          this.isEstimationCrossChain(nextEstimation)
            ? nextEstimation.cost ?? nextEstimation.amountIn
            : nextEstimation.amountIn,
          nextEstimation.fromToken.decimals
        )
      );
      await this.checkAllowance(nextEstimation);
    }

    // Update activeEstimation:
    //   - When the user requests a new estimation (loading is true)
    //   - When the amountOut differs from the current activeEstimation
    if (
      this.state.activeEstimationTrade.amountOut !== nextEstimation.amountOut ||
      this.state.activeEstimationTrade.loading
    ) {
      if (!this.state.activeEstimationTrade.loading) {
        this.state.activeEstimationTrade = {
          ...this.state.activeEstimationTrade,
          loading: true
        };
        await this.delay(300);
      }
      this.state.activeEstimationTrade = nextEstimation;
    }

    return this.state.activeEstimationTrade;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  protected isCrossChainSwapState(
    estimation: CrossAndSameChainEstimateTrade,
    crossChainSwapState: CrossChainSwapState
  ) {
    return estimation.crossChainSwapState == crossChainSwapState;
  }

  protected changeCrossChainSwapState(
    estimation: CrossAndSameChainEstimateTrade,
    crossChainSwapState: CrossChainSwapState
  ) {
    // Transition from 'insufficientSourceBalance' to other than 'init' is not allowed
    if (
      this.isCrossChainSwapState(
        estimation,
        CrossChainSwapState.InsufficientSourceBalance
      ) &&
      crossChainSwapState !== CrossChainSwapState.Init
    ) {
      return;
    }

    // Transition from 'NotFound' to 'Expired' is not allowed
    if (
      this.isCrossChainSwapState(estimation, CrossChainSwapState.NotFound) &&
      crossChainSwapState === CrossChainSwapState.Expired
    ) {
      return;
    }

    estimation.crossChainSwapState = crossChainSwapState;

    this.storageService.setItemToLocalStorage(
      BaseSwapComponent.PENDING_TRADE_LIST_STORAGE_NAME,
      this.state.pendingTradeList
    );
  }

  protected getAmount(amount: string, decimals: number) {
    return Conversion.adjustFraction(
      Conversion.convertStringFromDecimal(amount, decimals),
      6
    );
  }

  protected setConsoleLog(
    trade: CrossAndSameChainEstimateTrade,
    msg: string = '',
    e?: Error
  ) {
    console.log(
      `Cross chain SrcChainId= ${trade.fromToken.chainId} SrcTokenSymbol= ${
        trade.fromToken.symbol
      } DstChainId= ${trade.toToken.chainId} DstTokenSymbol= ${
        trade.toToken.symbol
      } AmountIn= ${trade.amountIn} TxState= ${
        CrossChainSwapState[trade.crossChainSwapState]
      } Message= ${msg} Error = ${e})`
    );
  }

  protected getRatio(
    amountIn: string,
    fromToken: CrowdToken,
    amountOut: string,
    toToken: CrowdToken
  ) {
    const decimal = fromToken.decimals - toToken.decimals;
    if (decimal !== 0) {
      if (decimal > 0) {
        amountOut = Conversion.convertStringToDecimal(
          amountOut,
          decimal
        ).toString();
      } else {
        amountIn = Conversion.convertStringToDecimal(
          amountIn,
          Math.abs(decimal)
        ).toString();
      }
    }
    const ratio = Conversion.adjustFraction(
      Conversion.toSafeBigNumberByRemovingFraction(amountIn)
        .div(Conversion.toSafeBigNumberByRemovingFraction(amountOut))
        .toString(),
      7
    );
    return ratio;
  }

  protected getTotalPendingAmount(
    estimation: CrossAndSameChainEstimateTrade,
    pendingTradeList
  ) {
    let totalPending = 0;
    if (pendingTradeList.length !== 0) {
      pendingTradeList.forEach((pending) => {
        if (pending.sourceTokenSymbol == estimation.fromToken.symbol) {
          totalPending += +pending.amountIn;
        }
      });
    }
    return totalPending;
  }

  protected addToPendingTradeList(
    pendingTrade: CrossAndSameChainEstimateTrade
  ) {
    this.state.pendingTradeList.push(pendingTrade);

    this.storageService.setItemToLocalStorage(
      BaseSwapComponent.PENDING_TRADE_LIST_STORAGE_NAME,
      this.state.pendingTradeList
    );
  }

  protected removeFromPendingTradeList(hash?: string, index?: number) {
    let pendingTrade = this.findFromPendingList(hash, index);

    if (pendingTrade) {
      this.clearConfirmationInterval(pendingTrade);
      this.state.pendingTradeList.splice(
        this.state.pendingTradeList.indexOf(pendingTrade),
        1
      );

      this.storageService.setItemToLocalStorage(
        BaseSwapComponent.PENDING_TRADE_LIST_STORAGE_NAME,
        this.state.pendingTradeList
      );
    }
  }

  protected findFromPendingList(
    hash?: string,
    index?: number
  ): CrossAndSameChainEstimateTrade | undefined {
    if (hash) {
      return this.state.pendingTradeList.find(
        (pending) => pending.swapTx!.hash === hash
      );
    }

    if (index != null) {
      return this.state.pendingTradeList[index];
    }
  }

  protected async showSuccessfulDialog(
    estimation: EstimateTrade,
    transaction: any
  ) {
    const data = {
      scanUrl: this.web3Service.getScanTransactionUrl(transaction),
      destTokenSymbol: estimation.toToken.symbol,
      targetNetworkName:
        this.web3Service.networkSpec[
          this.state.searchParam ? this.state.searchParam?.toToken.chainId : ''
        ].title,
      isDarkMode: this.setting.isDarkMode,
      isCrossChainSwap:
        estimation.fromToken.chainId !== estimation.toToken.chainId
    };

    const submitDialogRef = await this.txSubmittedDialogService.open(
      TransactionSubmittedDialogComponent,
      data
    );

    submitDialogRef!.instance.closed.subscribe(() => {
      this.feedbackService.feedbackRequestSubject.next('exchange');
    });
  }

  protected async showSwapWaitingDialog(
    estimation: CrossAndSameChainEstimateTrade,
    tagName: string,
    isEstimation: boolean = false,
    isCrossChain: boolean = false
  ) {
    await this.tagManagerService.sendCrossChainTags(
      tagName,
      estimation,
      this.web3Service.getWalletAddress()
    );

    const data = {
      sourceTokenSymbol: estimation.fromToken.symbol,
      destTokenSymbol: estimation.toToken.symbol,
      amountIn: this.getAmount(
        estimation.amountIn,
        estimation.fromToken.decimals
      ),
      amountOut: this.getAmount(
        estimation.amountOut,
        estimation.toToken.decimals
      ),
      sourceNetworkName:
        this.web3Service.networkSpec[
          this.state.searchParam
            ? this.state.searchParam?.fromToken.chainId
            : ''
        ].title,
      targetNetworkName:
        this.web3Service.networkSpec[
          this.state.searchParam ? this.state.searchParam?.toToken.chainId : ''
        ].title,
      isDarkMode: this.setting.isDarkMode,
      isCrossChainSwap: isCrossChain,
      isEstimation: isEstimation
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  protected unSubscribeAutoSearch() {
    if (this.state.autoSearchSubscriber) {
      this.state.autoSearchSubscriber.unsubscribe();
      this.state.autoSearchSubscriber = null;
      this.setting.countDownTimer = 100;
    }
  }

  protected async checkBalance(
    estimation: CrossAndSameChainEstimateTrade,
    token: CrowdToken,
    amountInValue: string
  ) {
    try {
      if (!this.status.walletAddress) {
        return;
      }

      const sourceBalance = await this.tokensService.getTokenBalance(token);
      if (+sourceBalance < +amountInValue) {
        this.changeCrossChainSwapState(
          estimation,
          CrossChainSwapState.InsufficientSourceBalance
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  protected async clearConfirmationInterval(
    estimation: CrossAndSameChainEstimateTrade
  ) {
    if (estimation.confirmationInterval) {
      clearInterval(estimation.confirmationInterval);
      estimation.confirmationInterval = undefined;
    }
  }

  public static GetNotFoundEstimate(searchParam: SearchParamModel) {
    const dummy = <CrossAndSameChainEstimateTrade>{
      ...BaseSwapComponent.getDummyEstimationTrade(),
      loading: false,
      minimize: false,
      fromToken: searchParam.fromToken,
      toToken: searchParam.toToken,
      amountInInUSDT: '0',
      amountInInUSDTToDisplay: '0',
      totalPaidInUSDT: '0',
      amountOutInUSDT: '--.--',
      amountOutInUSDTToDisplay: '--.--',
      routes: [],
      amountOutPerAmountInRatio: '',
      amountOut: '--.--',
      amountOutDisplay: '--.--',
      crossChainSwapState: CrossChainSwapState.NotFound
    };
    return dummy;
  }

  public static getDummyEstimationTrade(): EstimateTrade {
    const fromToken: CrowdToken = Builder<CrowdToken>()
      .symbol('USDT')
      .chainId(137)
      .decimals(18)
      .price('1')
      .build();

    const toToken: CrowdToken = Builder<CrowdToken>()
      .symbol('USDT')
      .chainId(137)
      .decimals(18)
      .price('1')
      .build();

    const dex: DEX = Builder<DEX>()
      .name(Constants.SHIMMER_FIX_NAME)
      .displayName('')
      .iconUrl('')
      .liquidityUSD('')
      .volumeUSD('')
      .networks([])
      .build();

    const estimateTrade = Builder<EstimateTrade>()
      .correlationId('')
      .dex(dex)
      .amountIn('111111')
      .amountOut('111111111')
      .minAmountOut('111111111')
      .swapFee('11111')
      .networkFee('11111')
      .crowdswapFee('11111')
      .pricePerToken(0)
      .cost('0')
      .crowdswapFeePercentage('111')
      .currentDexFeePercentage('111')
      .fromToken(fromToken)
      .toToken(toToken)
      .delegatedDex('')
      .routes([fromToken, toToken])
      .swapFeeInUSDT('1111')
      .networkFeeInUSDT('1111')
      .crowdswapFeeInUSDT('1111')
      .totalFeeInUSDT('1111')
      .totalPaidInUSDT('1111')
      .totalIncomeInUSDT('1111')
      .amountOutInUSDT('1111')
      .amountInInUSDT('1111')
      .amountOutPerAmountInRatio('1')
      .amountInPerAmountOutRatio('1')
      .priceImpact('')
      .loading(true)
      .build();

    return estimateTrade;
  }
}
