import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output
} from '@angular/core';
import { DialogsComponent } from '../../../modal/dialogs/dialogs.component';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../../modal/modal.module';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'mt-pelerin-dialog',
  templateUrl: './mt-pelerin-dialog.component.html',
  styleUrls: ['./mt-pelerin-dialog.component.scss']
})
export class MtPelerinDialogComponent
  extends DialogsComponent
  implements OnInit
{
  @Output()
  confirmed = new EventEmitter<object>();

  mtPelerinURLEncoded: string = '';
  public toggle: boolean;

  constructor() {
    super();
    this.toggle = true;
  }

  ngOnInit() {
    this.toggle = this.data?.toggle as boolean;
    this.mtPelerinURLEncoded = this.data?.scanUrl as string;
    this.isSelectOption = false;
    this.dialogWidth = true;
  }

  public ngAfterViewInit(): void {
    const htmlIFrameElement = <HTMLIFrameElement>(
      document.getElementById('iframeMtPelerin')
    );
    if (htmlIFrameElement.src !== this.mtPelerinURLEncoded) {
      htmlIFrameElement.src = this.mtPelerinURLEncoded;
    }
  }

  toggleDropdown() {
    this.confirmed.emit(undefined);
    this.toggle = !this.toggle;
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule],
  declarations: [MtPelerinDialogComponent],
  exports: [MtPelerinDialogComponent]
})
export class MtPelerinDialogComponentModule {}
