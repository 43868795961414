import {
  Component,
  ElementRef,
  inject,
  NgModule,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  ConnectWalletService,
  CrowdSwapService,
  NavigatorService,
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from 'src/app/services';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { SharedModule } from 'src/app/share/shared.module';
import { IGetAddressReqMdl } from 'src/app/model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { sha256 } from 'js-sha256';

export enum DrawerPage {
  Close,
  Email,
  WelcomeBack,
  LoginWithVerification,
  VerifiedCode,
  CreatePassword,
  RepeatPassword,
  Successful,
  AddFund
}

export interface IConnectWalletRespMdl {
  type: 'DefiWallet' | 'SmartWallet';
  address?: `0x${string}`;
}

@Component({
  selector: 'app-connect-wallet-dialog',
  templateUrl: './connect-wallet-dialog.component.html',
  styleUrls: ['./connect-wallet-dialog.component.scss']
})
export class ConnectWalletDialogComponent implements OnInit {
  visible = false;
  height = window.innerHeight;

  public setPasswordFG!: FormGroup;
  readonly #modal = inject(NzModalRef);
  public isReadAndAccept: boolean = false;
  public DrawerPage = DrawerPage;
  public drawerPage: DrawerPage = DrawerPage.Email;
  public user: {
    isUserExist: boolean;
    emailAddress: string;
    verificationCode: string;
  } = {
    isUserExist: false,
    emailAddress: '',
    verificationCode: ''
  };

  public password = new FormControl('', [
    Validators.required,
    Validators.pattern(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!_@#$%^&*()-]).{8,}$/
    )
  ]);

  passwordVisible: boolean = false;
  private verificationNotifier = new Subject();
  walletInstalled = false;

  private walletInfo: { name: string; icon: string } | null = null;
  public isDarkMode: boolean = false;

  constructor(
    public connectWalletService: ConnectWalletService,
    private formBuilder: FormBuilder,
    public web3Service: Web3Service,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    public navigatorService: NavigatorService
  ) {}

  @ViewChildren('input0, input1, input2, input3, input4, input5')
  inputs!: QueryList<ElementRef>;

  code: string[] = ['', '', '', '', '', ''];

  async ngOnInit() {
    this.themeService.darkModeChangeSubject.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });
    this.walletInstalled = this.isWalletInstalled();
    if (this.walletInstalled) {
      this.walletInfo = this.getWalletInfo();
      console.log('Wallet is installed:', this.walletInfo?.name);
    } else {
      console.log('Wallet is not installed');
    }

    this.setPasswordFG = this.formBuilder.group(
      {
        setPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!_@#$%^&*()-]).{8,}$/
            )
          ]
        ],
        reEnterPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!_@#$%^&*()-]).{8,}$/
            )
          ]
        ]
      },
      {
        validators: ConnectWalletDialogComponent.passwordMatchValidator
      }
    );

    this.verificationNotifier
      .pipe(
        debounceTime(CrowdSwapService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(async () => {
        await this.changeDrawerPage(DrawerPage.CreatePassword);
      });
  }

  destroyModal(data?: IConnectWalletRespMdl): void {
    this.#modal.destroy(data);
  }

  public onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;

    if (input.value.length === 1 && index < this.inputs.length - 1) {
      this.inputs.toArray()[index + 1].nativeElement.focus();
    } else if (input.value.length === 0 && index > 0) {
      this.inputs.toArray()[index - 1].nativeElement.focus();
    }
  }

  get setPassword() {
    return this.setPasswordFG.get('setPassword');
  }
  get reEnterPassword() {
    return this.setPasswordFG.get('reEnterPassword');
  }

  private static passwordMatchValidator(formGroup: FormGroup) {
    if (
      formGroup.get('setPassword')?.value !==
      formGroup.get('reEnterPassword')?.value
    ) {
      formGroup.get('reEnterPassword')?.setErrors({ mismatch: true });
    }
  }

  getCode(): void {
    this.user.verificationCode = this.code.join('');
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  get isContinueDisabled(): boolean {
    return !this.isReadAndAccept || this.code.some((char) => !char);
  }

  confirmDefiWallet() {
    this.destroyModal({ type: 'DefiWallet' });
  }

  notifyVerification() {
    this.verificationNotifier.next(true);
  }

  async confirmSmartWallet() {
    try {
      const data: IGetAddressReqMdl = {
        email: this.user.emailAddress,
        passwordHash: ConnectWalletDialogComponent.createHash(
          this.password.value
        )
      };

      this.connectWalletService.getAddress(data).then((resp: { address }) => {
        if (resp?.address) {
          this.destroyModal({
            type: 'SmartWallet',
            address: resp.address
          });
          this.changePage();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  public async registerUser() {
    const result = await this.connectWalletService.registerUser(
      this.user.emailAddress,
      this.user.verificationCode,
      ConnectWalletDialogComponent.createHash(this.setPassword?.value)
    );
    if (result) {
      await this.changeDrawerPage(DrawerPage.Successful);
      await this.confirmSmartWallet();
    }
  }

  private static createHash(password) {
    return sha256.create().update(password).hex();
  }

  openDrawer(): void {
    this.visible = true;
  }

  closeDrawer(): void {
    this.visible = false;
  }

  async changeDrawerPage(drawerPage: DrawerPage) {
    switch (drawerPage) {
      case DrawerPage.Close:
        this.closeDrawer();
        break;

      case DrawerPage.WelcomeBack:
        this.user.isUserExist = await this.isUserExist(this.user.emailAddress);
        this.drawerPage = this.user.isUserExist
          ? DrawerPage.WelcomeBack
          : DrawerPage.LoginWithVerification;

        this.clearData(this.drawerPage);
        break;

      case DrawerPage.VerifiedCode:
        this.getCode();
        this.drawerPage = (await this.isVerificationCodeCorrect(
          this.user.emailAddress,
          this.user.verificationCode
        ))
          ? DrawerPage.VerifiedCode
          : DrawerPage.LoginWithVerification;
        this.clearData(this.drawerPage);
        break;

      default:
        this.drawerPage = drawerPage;
        break;
    }
  }

  public clearData(drawerPage: DrawerPage) {
    if (drawerPage !== DrawerPage.LoginWithVerification) {
      return;
    }
    this.code = ['', '', '', '', '', ''];
    this.user.verificationCode = '';
  }

  private async isUserExist(emailAddress: string) {
    return await this.connectWalletService.isUserExistOrSendCode(emailAddress);
  }

  public async resendVerificationCode() {
    await this.connectWalletService.isUserExistOrSendCode(
      this.user.emailAddress
    );
  }

  changePage() {
    this.closeDrawer();
    this.destroyModal({
      type: 'SmartWallet',
      address: '0xaa'
    });
    this.navigatorService.changePage('portfolio');
    document
      .querySelector('.content-section')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private async isVerificationCodeCorrect(
    emailAddress: string,
    verificationCode: string
  ) {
    return await this.connectWalletService.isVerificationCodeCorrect(
      emailAddress,
      verificationCode
    );
  }

  async connectInstalledWallet(): Promise<string[] | null> {
    if (typeof (window as any).ethereum !== 'undefined') {
      try {
        return await (window as any).ethereum.request({
          method: 'eth_requestAccounts'
        }); // Returns the user's account address
      } catch (error) {
        console.error(
          'User denied account access or an error occurred:',
          error
        );
        return null;
      }
    }
    return null;
  }

  isWalletInstalled(): boolean {
    return (
      typeof (window as any).ethereum !== 'undefined' ||
      typeof (window as any).web3 !== 'undefined'
    );
  }

  getWalletInfo(): { name: string; icon: string } | null {
    if (typeof (window as any).ethereum !== 'undefined') {
      const provider = (window as any).ethereum;

      if (provider.isMetaMask) {
        return { name: 'MetaMask', icon: 'assets/media/wallets/MetaMask.svg' };
      } else if (provider.isCoinbaseWallet) {
        return {
          name: 'Coinbase Wallet',
          icon: 'assets/media/wallets/coinbase.svg'
        };
      } else if (provider.isTrust) {
        return { name: 'Trust Wallet', icon: 'assets/media/wallets/trust.png' };
      } else if (provider.isBraveWallet) {
        return { name: 'Brave Wallet', icon: 'assets/media/wallets/brave.png' };
      }
      // Add more wallet detection as needed
      return {
        name: 'Unknown Ethereum Wallet',
        icon: 'assets/media/wallets/unknown.png'
      };
    } else if (typeof (window as any).web3 !== 'undefined') {
      return { name: 'Legacy dApp Browser', icon: 'assets/legacy.png' };
    }

    return null;
  }

  public changeDarkModeStatus() {
    if (localStorage.getItem('darkMode') === 'true') {
      localStorage.setItem('darkMode', 'false');
      this.isDarkMode = false;
    } else {
      localStorage.setItem('darkMode', 'true');
      this.isDarkMode = true;
    }

    this.themeService.darkModeChangeSubject.next(this.isDarkMode);
  }
}

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot()
  ],
  declarations: [ConnectWalletDialogComponent]
})
export class ConnectWalletDialogModule {}
