import { Component, Input, OnInit } from '@angular/core';
import {
  ActiveMenu,
  NavigatorService
} from '../../../services/navigator.service';
import { BaseComponent } from '../../pages/base.component';
import {
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../../services';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent extends BaseComponent implements OnInit {
  @Input() isMobile: boolean = false;

  ActiveMenu = ActiveMenu;

  constructor(
    public web3Service: Web3Service,
    protected themeService: ThemeService,
    public navigatorService: NavigatorService,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  changePage(page: string) {
    this.navigatorService.changePage(page);
    document
      .querySelector('.content-section')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
