import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  NgModule,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { ModalService } from '../../modal.service';
import { WithdrawDialogComponent } from '../withdraw-dialog/withdraw-dialog.component';
import { Conversion, Dexchanges } from '@crowdswap/constant';
import { DistributionService } from '../../../../services/distribution.service';
import { WaitingDialogComponent } from '../waiting-dialog/waiting-dialog.component';
import { TransactionSubmittedDialogComponent } from '../transaction-submitted-dialog/transaction-submitted-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Web3Service } from '../../../../services/web3.service';

const allProviders = [
  {
    name: 'MetaMask',
    icon: 'assets/media/icons/metamask-logo.svg'
  }
];

export enum returnState {
  None,
  Close,
  Disconnected,
  Changed
}

@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss']
})
export class AccountDialogComponent extends DialogsComponent implements OnInit {
  copyAddress: string = 'Copy Address';
  address: any;
  @Output()
  returnValue = new EventEmitter<returnState>();
  distributionCrowdBalance = 0;
  releasableCrowdBalance = 0;
  isDistributionContractValid: string | undefined;
  isUserBeneficiary: boolean = false;
  private withdrawDialogRef: ComponentRef<WithdrawDialogComponent> | undefined;
  scanUrl = '';
  scanName = '';

  constructor(
    private web3Service: Web3Service,
    private ref: ChangeDetectorRef,
    private distributionService: DistributionService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private withdrawDialogComponentModalService: ModalService<WithdrawDialogComponent>,
    private txSubmittedDialogService: ModalService<TransactionSubmittedDialogComponent>,
    private toastr: ToastrService
  ) {
    super();
    this.returnValue.emit(0);
    this.address = this.web3Service.getWalletAddress();
    this.isDistributionContractValid =
      Dexchanges.CrowdswapAggregator.networks[
        this.web3Service.getCurrentChainId()
      ]?.distributionContractAddress || undefined;
  }

  async ngOnInit() {
    if (this.isDistributionContractValid) {
      this.isUserBeneficiary = await this.isBeneficiary();
      this.distributionCrowdBalance =
        (await this.getDistributedCrowdTokenBalance()) || 0;
      this.releasableCrowdBalance =
        (await this.getReleasableCrowdBalance()) || 0;
    }

    this.web3Service.accountChangeSubject.subscribe(async (address) => {
      this.address = address;
      if (this.withdrawDialogRef) {
        await this.withdrawDialogComponentModalService.closeByRef(
          this.withdrawDialogRef
        );
      }
      if (this.isDistributionContractValid) {
        this.isUserBeneficiary = await this.isBeneficiary();
        this.distributionCrowdBalance =
          (await this.getDistributedCrowdTokenBalance()) || 0;
        this.releasableCrowdBalance =
          (await this.getReleasableCrowdBalance()) || 0;
      }
    });
    this.scanUrl = this.web3Service.getScanAddressUrl(this.address);
    this.scanName = this.web3Service.getNetworkScanName();
  }

  private async isBeneficiary() {
    try {
      return await this.distributionService.isBeneficiary(this.address);
    } catch (e) {
      console.log(e);
    }
  }

  private async getDistributedCrowdTokenBalance() {
    try {
      const distributedBalance =
        await this.distributionService.getDistributedCrowdBalance(this.address);
      if (distributedBalance) {
        return +Conversion.convertStringFromDecimal(
          distributedBalance.toString(),
          18
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  private async getReleasableCrowdBalance() {
    try {
      const releasableToken =
        await this.distributionService.getReleasableCrowdBalance(this.address);
      if (releasableToken) {
        return +Conversion.convertStringFromDecimal(
          releasableToken.toString(),
          18
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  async changeWallet() {
    this.returnValue.emit(3);
  }

  async close() {
    this.returnValue.emit(1);
  }

  copyAddressToClipboard() {
    this.copyAddress = 'Copied';
    navigator.clipboard.writeText(this.address);
  }

  getCopyAddress() {
    return this.copyAddress == 'Copy Address'
      ? 'assets/media/icons/copy-logo.svg'
      : 'assets/media/icons/copied-logo.svg';
  }

  getScanUrl() {
    return this.web3Service.getScanAddressUrl(this.address);
  }

  getIconURL() {
    return allProviders[0].icon;
  }

  getProviderName() {
    return this.web3Service.getProviderName();
  }

  getAddress() {
    return (
      this.address?.substring(0, 6) + '..' + this.address?.substring(38, 42)
    );
  }

  async exitWallet() {
    this.returnValue.emit(2);
  }

  async openWithdrawDialog() {
    const data = {
      distributionCrowdBalance: this.distributionCrowdBalance,
      releasableCrowdBalance: this.releasableCrowdBalance,
      isDarkMode: this.data?.isDarkMode
    };
    this.withdrawDialogRef =
      await this.withdrawDialogComponentModalService.open(
        WithdrawDialogComponent,
        data
      );

    (<WithdrawDialogComponent>(
      (<any>this.withdrawDialogRef!.instance)
    )).confirmed.subscribe(async (event) => {
      await this.withdrawDialogComponentModalService.close();
      if (event) {
        await this.releaseCrowdToken();
      }
    });
    this.ref.detectChanges();
  }

  private async releaseCrowdToken() {
    try {
      const releaseTx = await this.distributionService.releaseCrowdToken(
        this.address
      );
      const param = {
        //Todo need to change this param (we must remove "stakeAmount" from waiting dialog)
        stakeState: 'Withdraw'
      };
      await this.waitingDialogService.open(WaitingDialogComponent, param);

      const currentTransaction = await this.web3Service
        .sendTransaction(releaseTx)
        .then(async (data) => {
          await this.waitingDialogService.close();
          return data;
        })
        .catch(async (e) => {
          console.log(e);
          await this.waitingDialogService.close();
          this.showErrorToastr('Withdraw rejected!');
        });

      if (currentTransaction) {
        const scanTransactionUrl =
          this.web3Service.getScanTransactionUrl(currentTransaction);
        await this.showSuccessfulDialog(scanTransactionUrl);

        await this.web3Service
          .waitForTransaction(currentTransaction, 1)
          .then(async (data) => {
            if (data?.status === 1) {
              this.showSuccessToastr(scanTransactionUrl);
            } else {
              this.showFailedToastr(scanTransactionUrl);
            }
          })
          .catch((e) => {
            console.log(e);
            this.showErrorToastr('Withdraw was unsuccessful!');
          });
      }
    } catch (e) {
      console.log(e);
      await this.waitingDialogService.close();
    }
    this.ref.detectChanges();
  }

  private async showSuccessfulDialog(transactionUrl: any) {
    const data = {
      scanUrl: transactionUrl,
      releasableCrowdBalance: this.releasableCrowdBalance,
      isDarkMode: this.data?.isDarkMode
    };
    await this.txSubmittedDialogService.open(
      TransactionSubmittedDialogComponent,
      data
    );
  }

  private showSuccessToastr(txUrl: string) {
    this.toastr.success(
      `<a href='${txUrl}' target='_blank'>View in explorer</a>`,
      `Withdraw exactly ${this.releasableCrowdBalance} `,
      {
        closeButton: true,
        tapToDismiss: false,
        progressBar: true,
        positionClass: 'custom-toast-top-right',
        enableHtml: true,
        timeOut: 10000,
        messageClass: 'successClass'
      }
    );
  }

  private showFailedToastr(txUrl: string) {
    this.toastr.error(
      `<a href='${txUrl}' target='_blank'>View in explorer</a>`,
      `Withdraw failed`,
      {
        closeButton: true,
        tapToDismiss: false,
        progressBar: true,
        positionClass: 'custom-toast-top-right',
        enableHtml: true,
        timeOut: 10000,
        messageClass: 'failedClass'
      }
    );
  }

  private showErrorToastr(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }
}

@NgModule({
  imports: [CommonModule, ModalModule],
  declarations: [AccountDialogComponent]
})
export class AccountDialogComponentModule {}
