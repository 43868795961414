export class UserInfo {
  public name: string = '';
  public email: string = '';
  public walletAddress: string = '';
  public affiliateId: string = '';
  public isEmailVerified: boolean = false;
  public displayEmailVerificationNotify: boolean = true;

  constructor() {}
}
