import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Conversion,
  CrowdToken,
  Networks,
  PriceService,
  TokensHolder
} from '@crowdswap/constant';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Builder } from 'builder-pattern';
import { CookieService } from 'ngx-cookie-service';
import { Subject, Subscription, asyncScheduler } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  throttleTime
} from 'rxjs/operators';
import { Constants } from '../../../../constants';
import { CurrentNetwork } from '../../../../model/current-network';
import { SearchParamModel } from '../../../../model/search-param.model';
import {
  CrossChainSwapService,
  CrowdSwapService,
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  TokensService,
  UtilsService,
  Web3Service
} from '../../../../services';
import { SelectOptionDialogComponent } from '../../../modal/dialogs/select-option-dialog/select-option-dialog.component';
import { ModalService } from '../../../modal/modal.service';
import { BaseComponent } from '../../base.component';
import { BigNumber } from 'ethers';
import { EstimateTrade } from '../../../../model/estimate-trade.model';

@Component({
  selector: 'app-token-selection',
  templateUrl: './token-selection.component.html',
  styleUrls: ['./token-selection.component.scss']
})
export class TokenSelectionComponent extends BaseComponent implements OnInit {
  tokenList: CrowdToken[] = [];
  fromTokenList: CrowdToken[] = [];
  toTokenList: CrowdToken[] = [];
  fromToken!: CrowdToken;
  toToken!: CrowdToken;
  fromBalance: string | undefined;
  toBalance: string | undefined;
  swapSearchValue = '0';
  swapSearchValueToDisplay = '0';
  swapAmount = 1;
  swapAmountOut = '11111111';
  totalDestinationPaid: string | undefined = '0';
  swapAmountInPerAmountOutRatio: string | undefined = '';
  swapAmountOutPerAmountInRatio: string | undefined = '';
  searchNotifier = new Subject();
  amountOutUSDT: string | undefined = '1111111';
  _estimation: EstimateTrade | undefined = undefined;

  dialogOpened: boolean = false;
  dollarBase: boolean = false;
  loadingAmountOut: boolean = true;

  public isSelected: boolean;
  public isOpenSrcTokenSelection: boolean;

  private currentNetworkChangeSubscription: Subscription | undefined;
  public lostPercentage: number | undefined;

  @Output()
  onPairSelected = new EventEmitter<SearchParamModel>();

  @Input()
  set estimation(estimation: EstimateTrade | undefined) {
    this._estimation = estimation;
    this.totalDestinationPaid = estimation?.totalPaidInUSDT;
    this.amountOutUSDT = estimation?.amountOutInUSDT;
    this.swapAmountInPerAmountOutRatio = estimation?.amountInPerAmountOutRatio;
    this.swapAmountOutPerAmountInRatio = estimation?.amountOutPerAmountInRatio;
    this.loadingAmountOut = estimation ? estimation.loading : true;
    if (!estimation?.loading && estimation?.amountOut) {
      this.swapAmountOut = formatNumber(
        parseFloat(
          Conversion.convertStringFromDecimal(
            estimation.amountOut.toString(),
            estimation.toToken.decimals
          )
        ),
        NumberType.SwapTradeAmount
      );
      //Update token price for unbalanced pairs. In this case, the price is directly extracted from its pair
      this.fromToken.price = estimation.fromToken.price;
      this.toToken.price = estimation.toToken.price;
      this.swapSearchValue = this._calculateSwapSearchValue();
      this.swapSearchValueToDisplay = estimation.amountInInUSDTToDisplay;
      this.lostPercentage =
        this.crossChainSwapService.calculatePriceImpact(estimation);
    } else {
      this.swapAmountOut = '11111111';
    }
  }

  get estimation() {
    return this._estimation;
  }

  constructor(
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    public priceService: PriceService,
    public activatedRoute: ActivatedRoute,
    protected themeService: ThemeService,
    private cookieService: CookieService,
    private $gtmService: GoogleTagManagerService,
    private selectOptionComponentModalService: ModalService<SelectOptionDialogComponent>,
    private tokensService: TokensService,
    protected tagManagerService: TagManagerService,
    private crossChainSwapService: CrossChainSwapService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
    this.isOpenSrcTokenSelection = false;
    this.isSelected = false;
  }

  async ngOnInit(): Promise<any> {
    [this.fromToken, this.toToken] = this._getDefaultTokens(
      Constants.SWAP_INITIAL_CHAIN_ID
    );
    super.ngOnInit();

    this.currentNetworkChangeSubscription =
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          const chainId: number = currentNetwork.chainId;

          if (
            currentNetwork.defaultFromToken &&
            currentNetwork.defaultToToken
          ) {
            [this.fromToken, this.toToken] = [
              currentNetwork.defaultFromToken,
              currentNetwork.defaultToToken
            ];
          } else {
            [this.fromToken, this.toToken] = this._getDefaultTokens(chainId);
          }

          await this.checkUrlParams(chainId);
          await this.selectTokens(this.fromToken, this.toToken);
          this.ref.detectChanges();
        });

    this.searchNotifier
      .pipe(
        debounceTime(CrowdSwapService.SEARCH_NOTIFIER_TIME),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.changeSwapAmount(this.swapAmount.toString());
      });

    this.web3Service.pendingChangeSubject.subscribe(async () => {
      this.fromBalance = formatNumber(
        parseFloat(await this.tokensService.getTokenBalance(this.fromToken)),
        NumberType.SwapTradeAmount
      );
      this.toBalance = formatNumber(
        parseFloat(await this.tokensService.getTokenBalance(this.toToken)),
        NumberType.SwapTradeAmount
      );
      this.ref.detectChanges();
    });
    this.web3Service.accountChangeSubject.subscribe(async () => {
      this.fromBalance = formatNumber(
        parseFloat(await this.tokensService.getTokenBalance(this.fromToken)),
        NumberType.SwapTradeAmount
      );
      this.toBalance = formatNumber(
        parseFloat(await this.tokensService.getTokenBalance(this.toToken)),
        NumberType.SwapTradeAmount
      );
      await this.selectOptionComponentModalService.close();
      this.ref.detectChanges();
    });
  }

  async selectTokens(fromToken: CrowdToken, toToken: CrowdToken) {
    try {
      const chainId: number = fromToken.chainId;

      let allTokens =
        TokensHolder.ObservableTokenListBySymbol[
          Networks[this.web3Service.getCurrentChainId()]
        ];

      let localStoredTokens = this.tokensService.getLocalStoredTokens(
        this.web3Service.getCurrentChainId()
      );

      this.tokenList = BaseComponent.removeDuplicateTokens(
        allTokens,
        localStoredTokens
      );

      this.fromTokenList = this.filterToken(toToken);
      this.toTokenList = this.filterToken(fromToken);

      this.swapAmount = this.dollarBase ? 1000 : 1;
      await this.resetSearch();
      this.fromBalance = formatNumber(
        parseFloat(await this.tokensService.getTokenBalance(this.fromToken)),
        NumberType.SwapTradeAmount
      );
      this.toBalance = formatNumber(
        parseFloat(await this.tokensService.getTokenBalance(this.toToken)),
        NumberType.SwapTradeAmount
      );
      this.fromToken.price = await this.priceService.getPrice(
        this.fromToken,
        this.web3Service.getNetworkProvider(this.fromToken.chainId)
      );
      this.toToken.price = await this.priceService.getPrice(
        this.toToken,
        this.web3Service.getNetworkProvider(this.toToken.chainId)
      );
    } catch (e) {
      console.log(e);
    }
  }

  async resetSearch() {
    if (this.swapAmount === 0) {
      return;
    }
    await this.updateAmountInUSDT();
    this.loadingAmountOut = true;

    const swapParameter: SearchParamModel = Builder<SearchParamModel>()
      .fromToken(this.fromToken)
      .toToken(this.toToken)
      .swapValue(this.swapAmount.toString())
      .build();

    this.onPairSelected.emit(swapParameter);
  }

  notifySearch(event) {
    this.searchNotifier.next(event);
  }

  async changeSwapAmount(amount: string) {
    this.swapAmount = parseFloat(Conversion.adjustFraction(amount, 7));
    await this.resetSearch();
  }

  async switchTokens() {
    await this.$gtmService.pushTag({
      event: 'switch_tokens',
      category: 'tokenSelection',
      sourceTokenSymbol: this.fromToken.symbol,
      destinationTokenSymbol: this.toToken.symbol,
      isConnected: this.web3Service.isConnected()
    });

    const tempToken = this.fromToken;
    this.fromToken = this.toToken;
    this.toToken = tempToken;
    this.swapAmount = parseFloat(this.swapAmountOut ?? '1');
    await this.updateAmountInUSDT();
    await this.changeTokens(true);
  }

  async setMaxBalance() {
    await this.$gtmService.pushTag({
      event: 'use_max_balance',
      category: 'tokenSelection',
      sourceTokenSymbol: this.fromToken.symbol,
      destinationTokenSymbol: this.toToken.symbol,
      isConnected: this.web3Service.isConnected()
    });

    let amount = await this.web3Service.getBalance(
      this.fromToken,
      this.web3Service.getNetworkProvider(this.fromToken.chainId)
    );
    if (amount) {
      if (
        TokensHolder.isBaseToken(this.fromToken.chainId, this.fromToken.address)
      ) {
        amount = amount.sub(
          Conversion.convertStringToDecimal(
            Constants.REDUCE_MAX_AMOUNT_IN.toString(),
            this.fromToken.decimals
          )
        );
      }
      if (amount.isNegative()) {
        amount = BigNumber.from(0);
      }
      let maxAmountIn = Conversion.convertStringFromDecimal(
        amount.toString(),
        this.fromToken.decimals
      );
      await this.changeSwapAmount(Conversion.adjustFraction(maxAmountIn, 8));
    }
  }

  async changeTokens(callUpdate = false, tokenType = 0) {
    if (tokenType <= 0) {
      this.toTokenList = this.tokenList;
      this.toTokenList = this.filterToken(this.fromToken);
      this.fromBalance = formatNumber(
        parseFloat(await this.tokensService.getTokenBalance(this.fromToken)),
        NumberType.SwapTradeAmount
      );
    }
    if (tokenType >= 0) {
      this.fromTokenList = this.tokenList;
      this.fromTokenList = this.filterToken(this.toToken);
      this.toBalance = formatNumber(
        parseFloat(await this.tokensService.getTokenBalance(this.toToken)),
        NumberType.SwapTradeAmount
      );
    }
    if (callUpdate) {
      await this.changeSwapAmount(this.swapAmount.toString());
    }
  }

  async updateAmountInUSDT(): Promise<any> {
    this.fromToken.price = await this.priceService.getPrice(
      this.fromToken,
      this.web3Service.getNetworkProvider(this.fromToken.chainId)
    );
    this.toToken.price = formatNumber(
      parseFloat(
        await this.priceService.getPrice(
          this.toToken,
          this.web3Service.getNetworkProvider(this.toToken.chainId)
        )
      ),
      NumberType.PortfolioBalance
    );
    this.swapSearchValue = this._calculateSwapSearchValue();

    this.ref.detectChanges();
  }

  private _calculateSwapSearchValue(): string {
    if (this.fromToken.price) {
      return Conversion.adjustFraction(
        +Conversion.adjustFraction(this.fromToken.price, 8) * this.swapAmount,
        4
      ).toString();
    } else {
      return 'NaN';
    }
  }

  isConnected() {
    return this.web3Service.isConnected();
  }

  /**
   * When select option notify items this function get it
   */
  public selectedFromToken(token: any) {
    this.fromToken = token;
    this.changeTokens(true, -1);
    UtilsService._clearQueryString();
  }

  /**
   * When select option notify items this function get it
   */
  public selectedToToken(event: any) {
    this.toToken = event;
    this.changeTokens(true, 1);
    UtilsService._clearQueryString();
  }

  private filterToken(token: any) {
    let filteredToken: typeof token = null;
    const networkCoin = this.web3Service.networkSpec[token.chainId].coin;
    if (token.symbol === 'W' + networkCoin) {
      filteredToken =
        TokensHolder.ObservableTokenListBySymbol[Networks[token.chainId]][
          networkCoin
        ];
    } else if (token.symbol === networkCoin) {
      filteredToken =
        TokensHolder.ObservableTokenListBySymbol[Networks[token.chainId]][
          'W' + networkCoin
        ];
    }
    if (filteredToken != null) {
      filteredToken = this.tokenList.filter(
        (current) =>
          current.address !== token.address &&
          current.address !== filteredToken.address
      );
    } else {
      filteredToken = this.tokenList.filter(
        (current) => current.address !== token.address
      );
    }
    return filteredToken;
  }

  private async checkUrlParams(currentChainId) {
    this.activatedRoute.queryParams.subscribe((params) => {
      const sourceUrlToken = params['source'] && params['source'];
      const targetUrlToken = params['target'] && params['target'];
      if (
        !TokensHolder.ObservableTokenListBySymbol[Networks[currentChainId]][
          sourceUrlToken
        ] ||
        !TokensHolder.ObservableTokenListBySymbol[Networks[currentChainId]][
          targetUrlToken
        ] ||
        sourceUrlToken === targetUrlToken ||
        sourceUrlToken === 'W' + targetUrlToken ||
        'W' + sourceUrlToken === targetUrlToken
      ) {
        return;
      }
      this.fromToken =
        TokensHolder.ObservableTokenListBySymbol[Networks[currentChainId]][
          sourceUrlToken
        ];
      this.toToken =
        TokensHolder.ObservableTokenListBySymbol[Networks[currentChainId]][
          targetUrlToken
        ];
    });
  }

  async openSelectOptionDialog(allTokens, filteredTokenList, token) {
    if (this.dialogOpened) {
      return;
    }
    this.dialogOpened = true;
    const data = {
      allTokens: allTokens,
      filteredTokenList: filteredTokenList,
      toggle: true,
      tokenSymbol: token.symbol,
      chainId: token.chainId,
      isDarkMode: this.isDarkMode
    };
    let selectOptionDialogRef =
      await this.selectOptionComponentModalService.open(
        SelectOptionDialogComponent,
        data
      );
    const sub: Subscription = (<SelectOptionDialogComponent>(
      (<any>selectOptionDialogRef!.instance)
    )).confirmed.subscribe(async (token) => {
      await this.selectOptionComponentModalService.close();
      this.dialogOpened = false;
      if (token) {
        switch (filteredTokenList) {
          case this.fromTokenList: {
            this.selectedFromToken(token);
            break;
          }
          case this.toTokenList: {
            this.selectedToToken(token);
            break;
          }
        }
      }
    });

    const subscribeImportNewToken: Subscription = (<
      SelectOptionDialogComponent
    >(<any>selectOptionDialogRef!.instance)).confirmedImportToken.subscribe(
      async (token: CrowdToken) => {
        this.tokenList.push(token);
        switch (filteredTokenList) {
          case this.fromTokenList: {
            this.fromTokenList.push(token);
            break;
          }
          case this.toTokenList: {
            this.toTokenList.push(token);
            break;
          }
        }
      }
    );

    selectOptionDialogRef!.onDestroy(() => {
      this.dialogOpened = false;
      sub.unsubscribe();
      subscribeImportNewToken.unsubscribe();
    });
  }

  private _getDefaultTokens(fromChainId: number): CrowdToken[] {
    let toChainId: number = fromChainId;

    let _fromToken =
      TokensHolder.ObservableTokenListBySymbol[Networks[fromChainId]][
        Constants.DEFAULT_PAIRS[fromChainId].fromToken
      ];
    let _toToken =
      TokensHolder.ObservableTokenListBySymbol[Networks[toChainId]][
        Constants.DEFAULT_PAIRS[toChainId].toToken
      ];

    const defaultFromToken = Builder<CrowdToken>()
      .address(_fromToken.address)
      .chainId(_fromToken.chainId)
      .decimals(_fromToken.decimals)
      .name(_fromToken.name)
      .symbol(_fromToken.symbol)
      .build();

    const defaultToToken = Builder<CrowdToken>()
      .address(_toToken.address)
      .chainId(_toToken.chainId)
      .decimals(_toToken.decimals)
      .name(_toToken.name)
      .symbol(_toToken.symbol)
      .build();

    return [defaultFromToken, defaultToToken];
  }
}
