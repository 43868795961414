<app-dialog>
  <div class="flex justify-start"></div>
  <div class="divide-y">
    <div>
      <div class="flex justify-center mt-48-px">
        <img
          *ngIf="!data?.isDarkMode"
          src="/assets/media/icons/success-check-black.svg"
          height="85"
          width="85"
        />
        <img
          *ngIf="data?.isDarkMode"
          src="/assets/media/icons/success-check-white.svg"
          height="85"
          width="85"
        />
      </div>
      <div class="flex justify-center mt-3 tx-submitted dark:text-white">
        <p>Transaction submitted</p>
        <div class="icon">
          <img
            alt="alert error"
            src="assets/media/icons/party.png"
            height="28"
            width="28"
          />
        </div>
      </div>
      <div *ngIf="!data?.isCrossChainSwap" class="flex justify-center mt-3">
        <a
          id="lnkScanUrl"
          class="text-blue-600 hover:text-blue-800"
          target="_blank"
          href="{{ data?.scanUrl }}"
        >
          View on Explorer
        </a>
      </div>
      <div
        *ngIf="!data?.targetNetworkName && data?.destTokenSymbol"
        class="flex flex-col justify-center mt-10 hideMoz"
      >
        <button class="flex justify-center" (click)="addToken()">
          <a class="flex text-blue-600 hover:text-blue-800">
            <span class="pr-1"> Add </span>
            <span class="icon-size align-items-center">
              <img
                class="rounded-full"
                [src]="
                  baseLogoIconsUrl +
                  data?.destTokenSymbol?.toLowerCase() +
                  '.png'
                "
                (error)="updateUrl($event)"
              />
            </span>
            <span class="pl-1"> {{ data?.destTokenSymbol }} to wallet </span>
          </a>
        </button>
      </div>
      <div
        *ngIf="data?.releasableCrowdBalance"
        class="flex flex-col justify-center mt-10"
      >
        <div class="flex justify-center">
          <div class="flex crowd-icon">
            <img
              src="assets/media/logo/Logos_CrowdSwap_CROWD%20Token.svg"
              height="28"
              width="28"
            />
          </div>
          <div class="flex crowd-amount-symbol font-openSans-semiBold">
            <span>{{ data?.releasableCrowdBalance }}</span>
          </div>
          <div class="flex crowd-amount-symbol text-gray-400">
            <span>CROWD</span>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-3 mb-5">
        <button
          nz-button
          nzType="default"
          (click)="close()"
          [nzSize]="'large'"
          class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-transparent hover:bg-0066ff1a focus:bg-0066ff1a text-0066FF dark:text-3385FF border-radius-5px border-2px border-0066FF dark:focus:border-3385ff dark:bg-3385ff26 dark:border-3385ff focus:border-3385ff focus:outline-solid focus:outline-3px focus:outline-0066FF99 mt-3"
        >
          <span class="px-1"> Close </span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
