import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FooterComponent } from './views/layout/footer/footer.component';
import { HeaderComponent } from './views/layout/header/header.component';
import { ChristmasComponent } from './views/layout/christmas/christmas.component';
import { EstimationComponent } from './views/pages/estimation/estimation.component';
import { EstimationItemComponent } from './views/pages/estimation/estimation-item/estimation-item.component';
import { TokenSelectionComponent } from './views/pages/swap/token-selection/token-selection.component';
import { SwapComponent } from './views/pages/swap/swap.component';
import { MainComponent } from './views/pages/main/main.component';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ToastrModule } from 'ngx-toastr';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { CrossAndSameChainSwapComponent } from './views/pages/cross-and-same-chain-swap/cross-and-same-chain-swap.component';
import { CrossChainTokenSelectionComponent } from './views/pages/cross-and-same-chain-swap/token-selection/cross-chain-token-selection.component';
import { WhitelistingComponent } from './views/pages/whitelisting/whitelisting.component';
import { StakeComponent } from './views/pages/stake/stake.component';
import { LockedStakingComponent } from './views/pages/locked-staking/locked-staking.component';
import { OpportunityComponent } from './views/pages/opportunity/opportunity.component';
import { OpportunityStakeComponent } from './views/pages/opportunity/stake/opportunity-stake.component';
import { ChooseAssetComponent } from './views/pages/opportunity/choose-asset/choose-asset.component';
import { OpportunityConfirmationComponent } from './views/pages/opportunity/confirmation/confirmation.component';
import { AffiliateRevenueComponent } from './views/pages/affiliate/affiliate-revenue/affiliate-revenue.component';
import { CircularProgressBarComponent } from './views/pages/affiliate/affiliate-revenue/circular-progress-bar/circular-progress-bar.component';
import { IconicValueComponent } from './views/pages/affiliate/affiliate-revenue/iconic-value/iconic-value.component';
import { NavigationComponent } from './views/layout/navigation/navigation.component';
import { CaptionComponent } from './views/layout/caption/caption.component';
import { UserPortfolioComponent } from './views/pages/user-portfolio/user-portfolio.component';
import { OpportunityItemComponent } from './views/pages/opportunity/opportunity-item/opportunity-item.component';
import { InvestmentComponent } from './views/pages/opportunity/investment/investment.component';
import { UserAssetsComponent } from './views/pages/user-portfolio/user-assets/user-assets.component';
import { InvestPrivateSaleComponent } from './views/pages/private-sale/invest-private-sale/invest-private-sale.component';
import { DetailsPrivateSaleComponent } from './views/pages/private-sale/details-private-sale/details-private-sale.component';
import { BannerPrivateSaleComponent } from './views/pages/private-sale/banner-private-sale/banner-private-sale.component';
import { FormPrivateSaleComponent } from './views/pages/private-sale/form-private-sale/form-private-sale.component';
import { ConfirmationPrivateSaleComponent } from './views/pages/private-sale/confirmation-private-sale/confirmation-private-sale.component';
import { LiquidityComponent } from './views/pages/liquidity/liquidity.component';
import { LiquidityListComponent } from './views/pages/liquidity/liquidity-list/liquidity-list.component';
import { AddLiquidityComponent } from './views/pages/liquidity/add-liquidity/add-liquidity.component';
import { RemoveLiquidityComponent } from './views/pages/liquidity/remove-liquidity/remove-liquidity.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { LiquidityItemComponent } from './views/pages/liquidity/liquidity-list/liquidity-item/liquidity-item.component';
import { SwiperModule } from 'swiper/angular';
import { MulticallService, PriceService } from '@crowdswap/constant';
import { RegExpDirective } from './directives/reg-exp.directive';
import { AffiliateComponent } from './views/pages/affiliate/affiliate.component';
import { AffiliateProfileComponent } from './views/pages/affiliate/affiliate-profile/affiliate-profile.component';
import { ModalModule } from './views/modal/modal.module';
import { SharedModule } from './share/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AffiliateTermsOfServiceComponent } from './views/pages/affiliate/affiliate-terms-of-service/affiliate-terms-of-service.component';
import { VipRankingComponent } from './views/pages/user-portfolio/vip-ranking/vip-ranking.component';
import { VipCardComponent } from './views/pages/user-portfolio/vip-ranking/vip-card/vip-card.component';
import { FocusAndSelectValueDirective } from './directives/FocusAndSelectValue.directive';
import { FiatOnRampComponent } from './views/pages/fiat-on-ramp/fiat-on-ramp.component';
import { OpportunitiesService } from './services/opportunities.service';
import { InitializeService } from './services/initialize.service';
import { TimeService } from './services/time.service';
import { DexInfoComponent } from './views/pages/dex-info/dex-info.component';
import { DexInfoService } from './views/pages/dex-info/dex-info.service';
import {
  StorageService,
  HistoryService,
  VipService,
  TagManagerService,
  PortfolioService,
  TokensService,
  PrivateSaleService,
  LiquidityService,
  NavigatorService,
  Web3Service,
  NetworksService,
  OpportunityService,
  CrowdSwapService,
  WhitelistingService,
  AffiliateService,
  CrossChainSwapService,
  DistributionService,
  StakeService,
  UniswapV2FactoryService,
  UniswapV3FactoryService,
  DebridgeService,
  ExchangeInfoService,
  AuthenticationInterceptor,
  OneInchService,
  ThemeService,
  UtilsService,
  AuthenticationService,
  NotificationService
} from './services';
import { HistoryComponent } from './views/pages/user-portfolio/history/history.component';
import { Router } from '@angular/router';
import { ListingBannerComponent } from './views/layout/listing-banner/listing-banner.component';
import { TransferAssetsComponent } from './views/pages/transfer-assets/transfer-assets.component';
import { HelpCircleComponent } from './views/layout/help-circle/help-circle.component';
import { PushNotificationComponent } from './views/modal/dialogs/push-notification/push-notification.component';
import { FeedbackService } from './services/feedback.service';
import { ButtonComponent } from './share/components/button-component/button-component';
import { EtfInvestComponent } from './views/pages/etf-opportunity/etf-invest/etf-invest.component';
import { EtfWithdrawComponent } from './views/pages/etf-opportunity/etf-withdraw/etf-withdraw.component';
import { DetailsCardComponent } from './views/pages/etf-opportunity/details-card/details-card.component';
import { EtfOpportunitiesService } from './services/etf-opportunities.service';
import { EtfItemComponent } from './views/pages/etf-opportunity/etf-item/etf-item.component';
import { HeaderBannerComponent } from './views/layout/header-banner/header-banner.component';

function initializeAppFactory(
  initializeService: InitializeService
): () => Promise<void> {
  return async () => {
    await initializeService.Initilize();
  };
}

PriceService.PRICE_URL = 'fff';

export const declarations = [
  AppComponent,
  FooterComponent,
  HeaderComponent,
  SwapComponent,
  MainComponent,
  TokenSelectionComponent,
  EstimationComponent,
  EstimationItemComponent,
  CrossAndSameChainSwapComponent,
  CrossChainTokenSelectionComponent,
  WhitelistingComponent,
  StakeComponent,
  LockedStakingComponent,
  OpportunityComponent,
  UserPortfolioComponent,
  DexInfoComponent,
  OpportunityStakeComponent,
  ChooseAssetComponent,
  OpportunityConfirmationComponent,
  AffiliateRevenueComponent,
  AffiliateProfileComponent,
  IconicValueComponent,
  CircularProgressBarComponent,
  AffiliateTermsOfServiceComponent,
  NavigationComponent,
  CaptionComponent,
  OpportunityItemComponent,
  InvestmentComponent,
  UserAssetsComponent,
  ChristmasComponent,
  LiquidityComponent,
  LiquidityListComponent,
  AddLiquidityComponent,
  RemoveLiquidityComponent,
  LiquidityItemComponent,
  LiquidityItemComponent,
  InvestPrivateSaleComponent,
  DetailsPrivateSaleComponent,
  BannerPrivateSaleComponent,
  FormPrivateSaleComponent,
  ConfirmationPrivateSaleComponent,
  RegExpDirective,
  AffiliateComponent,
  VipRankingComponent,
  VipCardComponent,
  FocusAndSelectValueDirective,
  FiatOnRampComponent,
  HistoryComponent,
  ListingBannerComponent,
  TransferAssetsComponent,
  HelpCircleComponent,
  PushNotificationComponent,
  ButtonComponent,
  EtfInvestComponent,
  EtfWithdrawComponent,
  EtfItemComponent,
  DetailsCardComponent,
  HeaderBannerComponent
];

export const imports = [
  BrowserModule,
  AppRoutingModule,
  HttpClientModule,
  FormsModule,
  CommonModule,
  ClickOutsideModule,
  NgxMaskDirective,
  NgxMaskPipe,
  NgCircleProgressModule.forRoot({
    backgroundOpacity: 0,
    backgroundPadding: 0,
    radius: 60,
    space: -10,
    maxPercent: 100,
    outerStrokeWidth: 8,
    outerStrokeColor: '#5cabff',
    outerStrokeGradientStopColor: '#53a9ff',
    outerStrokeLinecap: 'inherit',
    innerStrokeColor: '#e7e8ea',
    innerStrokeWidth: 10,
    title: 'UI',
    animateTitle: false,
    animationDuration: 1000,
    showTitle: false,
    showSubtitle: false,
    showUnits: false,
    showBackground: false,
    showInnerStroke: false,
    startFromZero: false
  }),
  ToastrModule.forRoot(),
  GoogleTagManagerModule.forRoot({ id: environment.gtm }),
  NgxSliderModule,
  SwiperModule,
  ModalModule,
  BrowserAnimationsModule,
  SharedModule.forRoot()
];

export const providers = [
  NavigatorService,
  Web3Service,
  NetworksService,
  { provide: PriceService, useValue: new PriceService() },
  MulticallService,
  CrowdSwapService,
  OpportunityService,
  EtfOpportunitiesService,
  WhitelistingService,
  AffiliateService,
  NgCircleProgressModule,
  CrossChainSwapService,
  DistributionService,
  StakeService,
  UniswapV2FactoryService,
  UniswapV3FactoryService,
  provideNgxMask(),
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false
    })
  },
  {
    provide: Sentry.TraceService,
    deps: [Router]
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true
  },
  DebridgeService,
  OneInchService,
  ThemeService,
  UtilsService,
  AuthenticationService,
  OpportunitiesService,
  PortfolioService,
  ExchangeInfoService,
  DexInfoService,
  TokensService,
  TimeService,
  PrivateSaleService,
  LiquidityService,
  TagManagerService,
  VipService,
  InitializeService,
  HistoryService,
  FeedbackService,
  {
    provide: APP_INITIALIZER,
    useFactory: initializeAppFactory,
    deps: [InitializeService],
    multi: true
  },
  StorageService,
  NotificationService
];

@NgModule({
  declarations: [declarations],
  imports: [imports],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule {}
