import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Web3Service } from './web3.service';
import { BehaviorSubject } from 'rxjs';

const baseUrl = environment.PORTFOLIO_BASEURL || '';

@Injectable()
export class VipService {
  public rankings: Rankings[] = [
    {
      stage: 0,
      name: 'No VIP',
      captionName: 'Your VIP Stage',
      benefits: ['Just a few steps before you are a VIP Club Member', ' '],
      avatar: 'assets/media/icons/vip/vip-0.svg',
      colorClass: 'color-white'
    },
    {
      stage: 1,
      name: 'VIP Stage',
      captionName: 'Your VIP Stage',
      benefits: ['Benefits coming soon', ''],
      avatar: 'assets/media/icons/vip/vip-1.svg',
      colorClass: 'color-silver-1'
    },
    {
      stage: 2,
      name: 'VIP Stage',
      captionName: 'Your VIP Stage',
      benefits: ['Benefits coming soon', ''],
      avatar: 'assets/media/icons/vip/vip-2.svg',
      colorClass: 'color-silver-2'
    },
    {
      stage: 3,
      name: 'VIP Stage',
      captionName: 'Your VIP Stage',
      benefits: ['Benefits coming soon', ''],
      avatar: 'assets/media/icons/vip/vip-3.svg',
      colorClass: 'color-gold'
    },
    {
      stage: 4,
      name: 'VIP Stage',
      captionName: 'Your VIP Stage',
      benefits: ['Benefits coming soon', ''],
      avatar: 'assets/media/icons/vip/vip-4.svg',
      colorClass: 'color-gold'
    },
    {
      stage: 5,
      name: 'VIP Stage',
      captionName: 'Your VIP Stage',
      benefits: ['Benefits coming soon', ''],
      avatar: 'assets/media/icons/vip/vip-5.svg',
      colorClass: 'color-gold'
    }
  ];
  public userStatus;

  constructor(private web3Service: Web3Service, private http: HttpClient) {}

  async getVipStatus(address?: string): Promise<any> {
    if (!address) {
      return null;
    }
    try {
      let result;
      const url = `${baseUrl}/vip/score/${address}`;

      const data = {
        address: address
      };
      result = await this.http
        .get(url, {
          params: data
        })
        .toPromise();
      return result
        ? result
        : {
            score: 0,
            stage: 0,
            rules: {}
          };
    } catch (err: any) {
      console.log(`VIP status not found`);
      return {
        score: 0,
        stage: 0,
        rules: {}
      };
    }
  }
}

interface Rankings {
  stage: number;
  name: string;
  captionName: string;
  benefits: string[];
  avatar: string;
  colorClass: string;
}
