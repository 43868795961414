<div
  class="row justify-center w-full mt-2 ml-2 md:ml-0"
>
  <!--  Left Panel-->
  <div class="col-12 col-sm-12 col-md-6 col-xl-4 col-xxl-4 z-0">
    <div class="grid grid-rows-3 grid-cols-2 gap-x-4">
      <app-iconic-value
        id="total_volume"
        [iconPath]="'assets/media/icons/bar-chart.svg'"
        [title]="'Total volume'"
        [value]="revenue.totalVolume"
        [isLoading]="isLoading"
      ></app-iconic-value>
      <app-iconic-value
        id="current_month_volume"
        [iconPath]="'assets/media/icons/bar-chart.svg'"
        [title]="'This month volume'"
        [value]="revenue.currentMonthVolume"
        [isLoading]="isLoading"
      ></app-iconic-value>
      <app-iconic-value
        id="total_fees"
        [iconPath]="'assets/media/icons/dollar.svg'"
        [title]="'Total fees'"
        [value]="revenue.totalFee"
        [isLoading]="isLoading"
      ></app-iconic-value>
      <app-iconic-value
        id="current_month_fees"
        [iconPath]="'assets/media/icons/dollar.svg'"
        [title]="'This month fees'"
        [value]="revenue.currentMonthFee"
        [isLoading]="isLoading"
      ></app-iconic-value>
      <app-iconic-value
        id="total_register"
        [iconPath]="'assets/media/icons/bar-chart.svg'"
        [title]="'Total register'"
        [value]="revenue.totalRegistration.toString()"
        [isLoading]="isLoading"
      ></app-iconic-value>
      <app-iconic-value
        id="current_month_register"
        [iconPath]="'assets/media/icons/bar-chart.svg'"
        [title]="'This month register'"
        [value]="revenue.currentMonthRegistration.toString()"
        [isLoading]="isLoading"
      ></app-iconic-value>
    </div>
  </div>
  <!--  Right Panel-->
  <div class="col-12 col-sm-12 col-md-6 col-xl-3 col-xxl-3 z-0">
    <div class="grid grid-rows-2 grid-cols-1 gap-y-4 height-full">
      <div id="percentage_for_running_month" class="box dark:bg-darkBlack">
        <app-circular-progress-bar
          [foregroundColor]="'#1CD875'"
          [backgroundColor]="'#979797'"
          [thick]="20"
          [radius]="80"
          [title]="'Percentage for running month'"
          [percentage]="revenue.runningMonthPercentage"
          [isLoading]="isLoading"
        ></app-circular-progress-bar>
      </div>
      <div
        id="percentage_for_following_month"
        class="box dark:bg-darkBlack"
      >
        <app-circular-progress-bar
          [foregroundColor]="'#0B9ED9'"
          [backgroundColor]="'#979797'"
          [thick]="20"
          [radius]="80"
          [title]="'Percentage for following month'"
          [percentage]="revenue.followingMonthPercentage"
          [isLoading]="isLoading"
        ></app-circular-progress-bar>
      </div>
    </div>
  </div>
</div>
