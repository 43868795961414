<app-dialog [isSelectOption]="true" *ngIf="this.toggle">
  <div>
    <div class="pl-6 flex justify-start bp-2 dark:text-textWhite">
      <label class="title font-openSans-regular dark:text-textWhite">
        Select a token
      </label>
      <div class="absolute right-5 mr-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          (click)="toggleDropdown()"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </div>
    </div>
    <div class="divide-y dark:divide-appLightBackground">
      <div class="px-6 pt-2">
        <div class="flex flex-col items-center mt-3">
          <div
            class="flex align-items-center rounded-md border-2 border-color py-2 pr-3 pl-2 w-full"
          >
            <svg
              width="18px"
              height="18px"
              viewBox="0 0 18 18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>BB6A6DA6-4619-4FF0-AB89-86075C001114</title>
              <g
                id="Select-Token"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Select-a-token"
                  transform="translate(-474.000000, -179.000000)"
                  fill="#9DD8F0"
                >
                  <g id="Group-4" transform="translate(441.000000, 98.000000)">
                    <g
                      id="Text-Field/_Resource/Box"
                      transform="translate(20.000000, 64.000000)"
                    >
                      <g
                        id="Icon/Search/Oultine"
                        transform="translate(13.666667, 17.666667)"
                      >
                        <path
                          d="M7.08333333,0 C10.9953503,0 14.1666667,3.17131636 14.1666667,7.08333333 C14.1666667,8.74182968 13.5966768,10.2671978 12.6419041,11.4742305 L16.7079314,15.5344799 C16.9684971,15.7946131 16.9688478,16.2167229 16.7087146,16.4772887 L16.4732083,16.7131867 C16.2130751,16.9737524 15.7909653,16.9741031 15.5303996,16.7139699 L11.4616951,12.6517969 C10.2566449,13.6006127 8.73609359,14.1666667 7.08333333,14.1666667 C3.17131636,14.1666667 0,10.9953503 0,7.08333333 C0,3.17131636 3.17131636,0 7.08333333,0 Z M7.08333333,1.66666667 C4.09179094,1.66666667 1.66666667,4.09179094 1.66666667,7.08333333 C1.66666667,10.0748757 4.09179094,12.5 7.08333333,12.5 C10.0748757,12.5 12.5,10.0748757 12.5,7.08333333 C12.5,4.09179094 10.0748757,1.66666667 7.08333333,1.66666667 Z"
                          id="Combined-Shape"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <input
              id="txtSearchTokenInput"
              class="w-full focus:outline-none pl-1 dark:bg-1E1E1E dark:text-textWhite"
              (click)="$event.stopPropagation()"
              (ngModelChange)="filterDataSourceValue($event)"
              [(ngModel)]="filterValue"
              (keydown.enter)="onEnter()"
              (keydown.escape)="clearSearch()"
              placeholder="Search name or paste address"
              appAutofocus
            />
            <div class="dark:text-textWhite">
              <svg
                *ngIf="filterValue"
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                (click)="clearSearch()"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </div>
          </div>
          <div class="rounded-xl pt-2 pb-1 w-full">
            <div>
              <div class="w-full pt-1">
                <button
                  *ngFor="let item of baseNetworkTokens"
                  class="border border-gray-200 dark:border-dialogBoxBackground rounded-full w-fix px-1 mr-1 hover:bg-gray-200 dark:hover:bg-hoverBackground"
                >
                  <div
                    (click)="selectedFromBaseCoins(item)"
                    class="flex align-items-center max-height"
                  >
                    <span class="scaled">
                      <img
                        class="rounded-full"
                        [src]="baseLogoIconsUrl + item.toLowerCase() + '.png'"
                        (error)="updateUrl($event)"
                      />
                    </span>
                    <span
                      class="pl-2 pr-1 font-openSans-regular text-sm dark:text-white"
                      >{{ item }}</span
                    >
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="select-option rounded-xl pt-1 px-1 md:px-4 pb-2 w-full">
          <div
            *ngIf="isNewToken; then newTokenBlock; else tokenListBlock"
          ></div>
          <ng-template #newTokenBlock>
            <div
              class="cursor-pointer rounded-md py-1 pl-1 pr-1 mr-1"
              *ngIf="newToken && newToken.symbol"
            >
              <div class="flex">
                <div class="flex items-center justify-center">
                  <img
                    class="rounded-full"
                    [src]="
                      baseLogoIconsUrl + newToken.symbol.toLowerCase() + '.png'
                    "
                    (error)="updateUrl($event)"
                  />
                </div>
                <div class="grid grid-rows-2 ml-3 w-full">
                  <span
                    class="row-span-1 font-openSans-semiBold text-left dark:text-white"
                    >{{ newToken.symbol }}</span
                  >
                  <span
                    class="row-span-1 font-openSans-regular text-gray-400 text-xs-2 text-left"
                    >{{ newToken.name }}</span
                  >
                </div>
                <div>
                  <button
                    class="flex items-center justify-center blue-button text-white rounded-md p-2 hover:bg-blue-700"
                    (click)="showImportTokenConfirmDialog(newToken)"
                  >
                    import
                  </button>
                </div>
              </div>
            </div>
            <div
              class="flex items-center justify-center mt-10 dark:text-textWhite"
              *ngIf="newToken && !newToken.symbol"
            >
              <span>Not found</span>
            </div>
            <div
              class="flex items-center justify-center mt-10"
              *ngIf="!newToken"
            >
              <span
                class="flex loader rounded-full border-2 border-t-2 h-10 w-10"
              ></span>
            </div>
          </ng-template>

          <ng-template #tokenListBlock>
            <div
              *ngIf="
                tokensTemp && tokensTemp.length > 0;
                then tokenList;
                else tokenListLoading
              "
            ></div>

            <ng-template #tokenListLoading>
              <div class="flex items-center justify-center mt-10">
                <span
                  *ngIf="tokensTemp"
                  class="flex loader rounded-full border-2 border-t-2 h-10 w-10"
                ></span>
                <span class="dark:text-textWhite" *ngIf="!tokensTemp"
                  >Not found</span
                >
              </div>
            </ng-template>

            <ng-template #tokenList>
              <div
                *ngFor="let item of tokensTemp"
                class="cursor-pointer rounded-md py-2 pl-3 hover:bg-gray-100 dark:hover:bg-hoverBackground"
              >
                <div (click)="selectedToken(item)" class="select-list">
                  <div class="symbol items-center justify-center">
                    <img
                      class="rounded-full"
                      loading="lazy"
                      [src]="
                        baseLogoIconsUrl + item.symbol.toLowerCase() + '.png'
                      "
                      (error)="updateUrl($event)"
                    />
                  </div>
                  <div class="symbol grid grid-rows-2 ml-3">
                    <span
                      id="spnItemSymbol"
                      class="row-span-1 font-openSans-semiBold text-left dark:text-textWhite"
                      >{{ item.symbol }}</span
                    >
                    <span
                      class="row-span-1 font-openSans-regular text-gray-400 text-xs-2 text-left"
                      >{{ item.name }}</span
                    >
                  </div>
                  <div
                    class="balance pr-3 text-sm text-gray-500 dark:text-darkGrayText"
                  >
                    {{ item.balance }}
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</app-dialog>
