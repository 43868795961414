<div class="p-6 dark:bg-darkBlack h-screen bg-cover">
  <div class="row justify-center">
    <div
      class="items-start justify-center col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 px-4 mb-4 order-1 order-md-0"
    >
      <div
        class="row justify-center dark:text-white bg-white dark:bg-appLightBackground rounded-md shadow-md"
      >
        <div *ngIf="finishTime > currentTime" class="dark:text-white">
          {{ activePresale.presaleDisplayName }} Crowd-sale will
          {{ opportunityStatus === 0 ? "start" : "finish" }} in:
        </div>
        <div
          *ngIf="finishTime < currentTime"
          class="dark:text-white font-openSans-semiBold"
        >
          {{ activePresale.presaleDisplayName }} Crowd-sale is finished
        </div>
        <div class="col-12 font-openSans-semiBold row px-1 mb-2">
          <div class="col-3 col-md-6 flex p-1 items-center justify-center">
            <div
              class="w-full rounded-md dark:text-white bg-green-300 icon-x-btn"
            >
              <div class="flex items-center justify-center">
                {{
                  opportunityStatus === 0
                    ? startTimeRemain.daysLeft
                    : finishTimeRemain.daysLeft
                }}
              </div>
              <div
                class="flex items-center justify-center font-openSans-regular text-xs"
              >
                DAYS
              </div>
            </div>
          </div>
          <div class="col-3 col-md-6 flex p-1 items-center justify-center">
            <div
              class="w-full rounded-md dark:text-white bg-green-300 icon-x-btn"
            >
              <div class="flex items-center justify-center">
                {{
                  opportunityStatus === 0
                    ? startTimeRemain.hoursLeft
                    : finishTimeRemain.hoursLeft
                }}
              </div>
              <div
                class="flex items-center justify-center font-openSans-regular text-xs"
              >
                HOURS
              </div>
            </div>
          </div>
          <div class="col-3 col-md-6 flex p-1 items-center justify-center">
            <div
              class="w-full rounded-md dark:text-white bg-green-300 icon-x-btn"
            >
              <div class="flex items-center justify-center">
                {{
                  opportunityStatus === 0
                    ? startTimeRemain.minutesLeft
                    : finishTimeRemain.minutesLeft
                }}
              </div>
              <div
                class="flex items-center justify-center font-openSans-regular text-xs"
              >
                MINUTES
              </div>
            </div>
          </div>
          <div class="col-3 col-md-6 flex p-1 items-center justify-center">
            <div
              class="w-full rounded-md dark:text-white bg-green-300 icon-x-btn"
            >
              <div class="flex items-center justify-center">
                {{
                  opportunityStatus === 0
                    ? startTimeRemain.secondsLeft
                    : finishTimeRemain.secondsLeft
                }}
              </div>
              <div
                class="flex items-center justify-center font-openSans-regular text-xs"
              >
                SECONDS
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="poolDetail"
        class="flex text-xs-2 col-11 mt-5 mb-2 px-1 col-md-12"
      >
        <div
          *ngFor="let item of poolDetail.priceStages; let i = index"
          class="flex col-{{ (12 / poolDetail.priceStages.length).toFixed(0) }}"
        >
          <div class="col-12">
            <div class="pl-1">
              <div class="dark:text-white p-1">ROUND {{ i + 1 }}</div>
              <div class="bg-gray-200">
                <div
                  class="bg-blue-500 h-3.5"
                  [style.width.%]="
                    (poolDetail.investedAmount > poolDetail.priceStages[i][0]
                      ? 100
                      : poolDetail.investedAmount -
                          (i === 0 ? 0 : poolDetail.priceStages[i - 1][0]) >
                        0
                      ? ((poolDetail.investedAmount -
                          (i === 0 ? 0 : poolDetail.priceStages[i - 1][0])) /
                          (poolDetail.priceStages[i][0] -
                            (i === 0 ? 0 : poolDetail.priceStages[i - 1][0]))) *
                        100
                      : 0
                    ).toFixed(0)
                  "
                ></div>
              </div>
              <div
                [ngClass]="
                  i === 0 ? 'justify-content-between' : 'justify-content-end'
                "
                class="flex dark:text-white"
              >
                <div *ngIf="i === 0">0</div>
                <div>
                  ${{ poolDetail ? poolDetail.priceStagesConverted[i] : "" }}
                </div>
              </div>
              <div
                [ngClass]="
                  (
                    !isMinimum
                      ? i === 0
                        ? poolDetail.investedAmount <
                            poolDetail.priceStages[i][0] &&
                          0 <= poolDetail.investedAmount
                        : poolDetail.investedAmount <
                            poolDetail.priceStages[i][0] &&
                          poolDetail.priceStages[i - 1][0] <
                            poolDetail.investedAmount
                      : i === 0
                  )
                    ? 'text-green-500 font-openSans-semiBold'
                    : 'text-gray-400'
                "
              >
                <span class="text-xs">Token price:</span>
                ${{ poolDetail ? poolDetail.priceStages[i][1] : "" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="poolDetail" class="flex dark:text-white items-center px-md-0">
        Total investment:&nbsp;$<span class="font-openSans-semiBold">{{
          poolDetail?.investedAmount | mask : "separator.3" : {thousandSeparator: ","}
        }}</span>
      </div>
    </div>
    <div
      class="justify-center flex row col-12 col-sm-12 col-md-5 col-xl-5 col-xxl-5 px-4 mb-5 order-0 order-md-1"
    >
      <div class="justify-center dark:text-white rounded-md p-1">
        <div class="flex m-1 rounded-md">
          <div class="text-xl text-center">
            <span>{{ activePresale.presaleDisplayName }} Crowd-sale</span>
          </div>
        </div>
        <div class="flex m-1 rounded-md pb-3">
          <div class="text-xs">
            <span [innerHTML]="activePresale.detailsDescription"></span>
          </div>
        </div>
        <div class="flex m-1 rounded-md pb-4">
          <div class="text-xs">
            <span [innerHTML]="activePresale.riskDescription"></span>
          </div>
        </div>
        <div *ngIf="isVip" class="m-1 mb-4 rounded-md">
          <div class="text-sm text-green-300">
            <span>You are in VIP group. no need to buy more ticket</span>
          </div>
        </div>
        <div
          *ngIf="!isVip && canBuyTicket && !isWrongNetwork"
          class="m-1 mb-4 rounded-md"
        >
          <div class="text-sm dark:text-white pb-2">
            <span
              >If you want to take advantage of lower
              {{ activePresale.presaleTokenName }} token price you can buy VIP
              ticket from here.</span
            >
          </div>
          <div
            *ngIf="canBuyTicket"
            class="text-sm justify-center relative pb-2"
          >
            <div class="flex justify-center">
              <div class="cursor-pointer" (click)="buyTicket()">
                <img *ngIf="isDarkMode" src="assets/media/icons/ticket.png" />
                <img
                  *ngIf="!isDarkMode"
                  src="assets/media/icons/ticket-black.png"
                />
              </div>
            </div>
            <div
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
              <div
                class="font-openSans-semiBold text-xs-2"
                *ngIf="isTicketApproved"
              >
                <div class="flex justify-center items-center">Ticket price</div>
                <div class="flex justify-center items-center">
                  {{ spot ? spot : 0 }} Crowd
                </div>
              </div>
              <div
                class="font-openSans-semiBold text-xs-2"
                *ngIf="!isTicketApproved"
              >
                <div class="flex justify-center items-center">
                  Approve {{ spot ? spot : 0 }} Crowd
                </div>
                <div class="flex justify-center items-center">for ticket</div>
              </div>
            </div>
          </div>
          <div *ngIf="canBuyTicket" class="text-sm dark:text-white">
            <div class="flex items-center justify-center">
              <img src="assets/media/icons/ticket-left.png" />
              <span class="ml-1 flex item-center font-openSans-semiBold"
                >{{ spotList ? spotList.length : 0 }} Tickets left</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="justify-center flex row col-12 col-sm-12 col-md-3 col-xl-3 col-xxl-3 px-2 order-2 order-md-2"
    >
      <div class="swap-box z-0 col-md-10 rounded-md px-0 mb-3">
        <div
          class="rounded-md p-1 m-1 mt-3 flex row justify-center shadow-md bg-white dark:bg-appLightBackground"
        >
          <div class="flex row justify-center">
            <div
              class="font-openSans-regular text-xs p-1 flex justify-center dark:text-white"
            >
              <span>Your all investment:</span>
            </div>
            <div
              class="font-openSans-semiBold p-2 flex justify-center dark:text-white"
            >
              $
              {{
                investorDetail
                  ? (investorDetail.balance | mask : "separator.3" : {thousandSeparator: ","})
                  : "0"
              }}
            </div>
            <div
              class="font-openSans-regular text-xs p-1 flex justify-center dark:text-white"
            >
              <span>Your presaled {{ activePresale.presaleTokenName }}:</span>
            </div>
            <div
              class="font-openSans-semiBold p-2 flex justify-center dark:text-white"
            >
              {{ investorDetail ? investorDetail.investorShare : "0" }}
            </div>
            <div
              class="font-openSans-regular text-xs p-1 flex justify-center dark:text-white"
            >
              <span>You released:</span>
            </div>
            <div
              class="font-openSans-semiBold p-2 flex justify-center dark:text-white"
            >
              {{ investorDetail ? investorDetail.released : "0" }}
            </div>
          </div>
          <div class="flex row justify-center mb-2">
            <div>
              <div
                *ngIf="
                  poolState !== PoolState.PREOPEN &&
                  finishTime > currentTime &&
                  startTime < currentTime
                "
                class="flex flex-col mt-4"
              >
                <button
                  (click)="navigateToInvest()"
                  class="btn-swap-token border border-blue-400 dark:text-white"
                >
                  <span>Invest more</span>
                </button>
              </div>
            </div>
            <div
              *ngIf="
                finishTime < currentTime &&
                poolState !== PoolState.FAILURE &&
                poolState !== PoolState.PREOPEN &&
                !isWrongNetwork
              "
            >
              <div *ngIf="poolDetail.tgeDate" class="mt-4">
                <div *ngIf="currentTime * 1000 < nextReleaseTime && poolDetail.vestingType === 0" class="flex">
                  <div class="text-xs-2 dark:text-white">
                    Next release:&nbsp;
                  </div>
                  <div class="text-xs-2 mb-1 dark:text-white">
                    {{ nextReleaseTime | date : "dd/MM/yyyy HH:mm " }}
                  </div>
                </div>
                <div *ngIf="currentTime * 1000 < nextReleaseTime && poolDetail.vestingType === 0" class="flex">
                  <div class="text-xs-2 dark:text-white">For&nbsp;</div>
                  <div class="text-xs-2 mb-1 dark:text-white">
                    {{
                      nextReleaseAmount - investorDetail.released - +releasable
                    }}&nbsp;{{ activePresale.presaleTokenName }}
                  </div>
                </div>
                <button
                  (click)="release()"
                  [ngClass]="{
                    'text-gray-300 dark:text-gray-600 pointer-events-none':
                      +releasable === 0
                  }"
                  class="btn-swap-token border border-blue-400 dark:text-white"
                >
                  <span
                    >Click to release {{ releasable ? releasable : 0 }}
                    {{ activePresale.presaleTokenName }}</span
                  >
                </button>
              </div>
            </div>
            <div
              *ngIf="
                finishTime < currentTime &&
                poolState === PoolState.FAILURE &&
                investorDetail &&
                investorDetail.balance > 0 &&
                !isWrongNetwork
              "
            >
              <div class="mt-4">
                <button
                  (click)="withdraw()"
                  class="btn-swap-token border border-red-400 dark:text-white"
                >
                  <span> Withdraw ${{ investorDetail.balance }} </span>
                </button>
              </div>
            </div>
            <div *ngIf="isWrongNetwork">
              <div class="mt-4">
                <button
                  (click)="switchToNetwork()"
                  class="btn-swap-token border border-red-400 bg-red-400 text-white"
                >
                  <span> Wrong network</span>
                </button>
              </div>
            </div>
            <div *ngIf="isOwner && 
            finishTime < currentTime &&
            poolState !== PoolState.FAILURE &&
            poolState !== PoolState.PREOPEN">
              <div class="mt-4">
                <button
                  (click)="withdrawBeneficiaryFunds()"
                  class="btn-swap-token border border-red-400 dark:text-white"
                >
                  <span> Withdraw Funds</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swap-box z-0 col-md-10 custom-margin-bottom rounded-md px-0">
        <div
          class="rounded-md p-1 m-1 mt-3 flex row bg-white shadow-md dark:bg-appLightBackground"
        >
          <div class="flex row">
            <div class="font-openSans-regular text-xs p-1 flex dark:text-white">
              <span
                >Want to know more about
                {{ activePresale.presaleDisplayName }}?</span
              >
            </div>
          </div>
          <div
            class="dark:bg-darkBlack bg-blue-400 rounded-md justify-center mb-2"
          >
            <div *ngFor="let item of activePresale.links; let i = index">
              <div class="flex items-center py-1">
                <span class="col-2">
                  <div [hidden]="item.split('$')[0] !== 'website'">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="9"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.59961 9H20.3996"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.59961 15H20.3996"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.4997 3C8.06261 8.50778 8.06261 15.4922 11.4997 21"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5 3C15.9371 8.50778 15.9371 15.4922 12.5 21"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div [hidden]="item.split('$')[0] !== 'instagram'">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="4"
                        y="4"
                        width="16"
                        height="16"
                        rx="4"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="12"
                        cy="12"
                        r="3"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.5 7.4995V7.5005"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div [hidden]="item.split('$')[0] !== 'reddit'">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.0002 8C14.6482 8 17.0282 8.826 18.6752 10.14C19.8394 9.73191 21.1273 10.2341 21.7079 11.3225C22.2886 12.411 21.9885 13.7603 21.0012 14.5C21.0012 18.09 16.9712 21 12.0012 21C7.12619 21 3.15619 18.2 3.00119 14.706L2.0012 14.5C1.0139 13.7603 0.71378 12.411 1.29446 11.3225C1.87513 10.2341 3.16298 9.73191 4.32719 10.14C5.97319 8.827 8.35319 8 11.0012 8H12.0002Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 8L13 3L19 4"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="19"
                        cy="4"
                        r="1"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="9"
                        cy="13"
                        r="0.5"
                        fill="white"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <circle
                        cx="15"
                        cy="13"
                        r="0.5"
                        fill="white"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 17C10.667 17.333 11.333 17.5 12 17.5C12.667 17.5 13.333 17.333 14 17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div [hidden]="item.split('$')[0] !== 'twitter'">
                    <svg
                      width="22"
                      height="19"
                      viewBox="0 0 22 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21 2.01001C20 2.50001 19.02 2.69901 18 3.00001C16.879 1.73501 15.217 1.66501 13.62 2.26301C12.023 2.86101 10.977 4.32301 11 6.00001V7.00001C7.755 7.08301 4.865 5.60501 3 3.00001C3 3.00001 -1.182 10.433 7 14C5.128 15.247 3.261 16.088 1 16C4.308 17.803 7.913 18.423 11.034 17.517C14.614 16.477 17.556 13.794 18.685 9.77501C19.0218 8.55268 19.189 7.28987 19.182 6.02201C19.18 5.77301 20.692 3.25001 21 2.00901V2.01001Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div [hidden]="item.split('$')[0] !== 'pitchDeck'">
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H10L15 6V17C15 18.1046 14.1046 19 13 19Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div [hidden]="item.split('$')[0] !== 'telegram'">
                    <svg
                      fill="white"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>telegram</title>
                      <path
                        d="M22.26465,2.42773a2.04837,2.04837,0,0,0-2.07813-.32421L2.26562,9.33887a2.043,2.043,0,0,0,.1045,3.81836l3.625,1.26074,2.0205,6.68164A.998.998,0,0,0,8.134,21.352c.00775.012.01868.02093.02692.03259a.98844.98844,0,0,0,.21143.21576c.02307.01758.04516.03406.06982.04968a.98592.98592,0,0,0,.31073.13611l.01184.001.00671.00287a1.02183,1.02183,0,0,0,.20215.02051c.00653,0,.01233-.00312.0188-.00324a.99255.99255,0,0,0,.30109-.05231c.02258-.00769.04193-.02056.06384-.02984a.9931.9931,0,0,0,.20429-.11456,250.75993,250.75993,0,0,1,.15222-.12818L12.416,18.499l4.03027,3.12207a2.02322,2.02322,0,0,0,1.24121.42676A2.05413,2.05413,0,0,0,19.69531,20.415L22.958,4.39844A2.02966,2.02966,0,0,0,22.26465,2.42773ZM9.37012,14.73633a.99357.99357,0,0,0-.27246.50586l-.30951,1.504-.78406-2.59307,4.06525-2.11695ZM17.67188,20.04l-4.7627-3.68945a1.00134,1.00134,0,0,0-1.35352.11914l-.86541.9552.30584-1.48645,7.083-7.083a.99975.99975,0,0,0-1.16894-1.59375L6.74487,12.55432,3.02051,11.19141,20.999,3.999Z"
                      />
                    </svg>
                  </div>
                  <div [hidden]="item.split('$')[0] !== 'youtube'">
                    <svg
                      fill="white"
                      height="24px"
                      width="24px"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 49 49"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M39.256,6.5H9.744C4.371,6.5,0,10.885,0,16.274v16.451c0,5.39,4.371,9.774,9.744,9.774h29.512
			c5.373,0,9.744-4.385,9.744-9.774V16.274C49,10.885,44.629,6.5,39.256,6.5z M47,32.726c0,4.287-3.474,7.774-7.744,7.774H9.744
			C5.474,40.5,2,37.012,2,32.726V16.274C2,11.988,5.474,8.5,9.744,8.5h29.512c4.27,0,7.744,3.488,7.744,7.774V32.726z"
                          />
                          <path
                            d="M33.36,24.138l-13.855-8.115c-0.308-0.18-0.691-0.183-1.002-0.005S18,16.527,18,16.886v16.229
			c0,0.358,0.192,0.69,0.502,0.868c0.154,0.088,0.326,0.132,0.498,0.132c0.175,0,0.349-0.046,0.505-0.137l13.855-8.113
			c0.306-0.179,0.495-0.508,0.495-0.863S33.667,24.317,33.36,24.138z M20,31.37V18.63l10.876,6.371L20,31.37z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div [hidden]="item.split('$')[0] !== 'linkedIn'">
                    <svg
                      fill="white"
                      width="24px"
                      height="24px"
                      viewBox="-2 -2 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="xMinYMin"
                      class="jam jam-linkedin-square"
                    >
                      <path
                        d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457-.592 0-.945.398-1.1.784-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066 1.41 0 2.468.922 2.468 2.902zM6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115-.014-.632-.465-1.114-1.199-1.114zm-1.086 9.556h2.144V8.38H5.127v6.447z"
                      />
                      <path
                        d="M4 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h12a4 4 0 0 1 4 4v12a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                      />
                    </svg>
                  </div>
                  <div [hidden]="item.split('$')[0] !== 'medium'">
                    <svg
                      fill="white"
                      width="24px"
                      height="24px"
                      viewBox="0 0 256 256"
                      id="Flat"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M70,56C33.60742,56,4,88.29885,4,128s29.60742,72,66,72,66-32.29883,66-72S106.39258,56,70,56Zm0,120c-23.15918,0-42-21.5332-42-48S46.84082,80,70,80s42,21.5332,42,48S93.15918,176,70,176ZM182,60c-23.36035,0-34,35.249-34,68s10.63965,68,34,68,34-35.249,34-68S205.36035,60,182,60Zm0,111.26367c-3.65674-4.06347-10-18.89746-10-43.26367s6.34326-39.20019,10-43.26367c3.65674,4.06348,10,18.89746,10,43.26367S185.65674,167.20022,182,171.26369ZM252,72V184a12,12,0,0,1-24,0V72a12,12,0,1,1,24,0Z"
                      />
                    </svg>
                  </div>

                  <div [hidden]="item.split('$')[0] !== 'terms & conditions'">
                    <svg
                      width="16"
                      height="20"
                      viewBox="0 0 16 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H10L15 6V17C15 18.1046 14.1046 19 13 19Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </span>
                <a
                  class="text-white text-xs"
                  href="{{ item.split('$')[1] }}"
                  target="_blank"
                >
                  {{ item.split("$")[0] }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
