import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  commonConfig,
  CrosschainFeeType,
  CrowdToken,
  NetworksById,
  PriceService,
  TokensHolder,
  Networks,
  Conversion
} from '@crowdswap/constant';
import { ModalService } from '../../modal/modal.service';
import { WaitingDialogComponent } from '../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ConfirmSwapDialogComponent } from '../../modal/dialogs/confirm-swap-dialog/confirm-swap-dialog.component';
import { TransactionSubmittedDialogComponent } from '../../modal/dialogs/transaction-submitted-dialog/transaction-submitted-dialog.component';
import { Constants } from '../../../constants';
import {
  ExchangeInfoService,
  CrossChainSwapService,
  CrowdSwapService,
  NDDClientInfoServiceImpl,
  NotificationService,
  PortfolioService,
  StorageService,
  TagManagerService,
  ThemeService,
  TokensService,
  UtilsService,
  Web3Service,
  ConnectWalletService
} from '../../../services';
import {
  CrossAndSameChainEstimateTrade,
  CrossChainSwapState,
  DialogDataModel,
  SearchParamModel
} from '../../../model';
import { SettingDialogComponent } from '../../modal/dialogs/setting-dialog/setting-dialog.component';
import { CrossChainTokenSelectionComponent } from './token-selection/cross-chain-token-selection.component';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import { CrossChainSwapComponent } from './cross-chain-swap/cross-chain-swap.component';
import { SameChainSwapComponent } from './same-chain-swap/same-chain-swap.component';
import { BaseSwapComponent } from './base-swap.component';
import { Subscription, interval } from 'rxjs';
import { SettingModel } from '../../modal/dialogs/setting-dialog/setting-models';
import { ExchangeTourDialogComponent } from '../../modal/dialogs/exchange-tour/exchange-tour.component';
import { ExchangeType } from './model/cross-chain-state.enum';
import { HistoryComponent } from '../user-portfolio/history/history.component';
import { Builder } from 'builder-pattern';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { NgxMaskService } from 'ngx-mask';
import { FeedbackService } from '../../../services/feedback.service';
import { UserFeedbackComponent } from '../../modal/dialogs/user-feedback/user-feedback.component';
import { ImportTokenDialogComponent } from '../../modal/dialogs/import-token-dialog/import-token-dialog.component';
import { EstimateTrade } from '../../../model/estimate-trade.model';

export const exchangeTourDialogOpened = 'exchangeTourDialogOpened';

@Component({
  selector: 'app-cross-and-same-chain-swap',
  templateUrl: './cross-and-same-chain-swap.component.html',
  styleUrls: ['./cross-and-same-chain-swap.component.scss']
})
export class CrossAndSameChainSwapComponent
  extends BaseSwapComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public CrossChainSwapState = CrossChainSwapState;
  public arrow: string = Constants.ARROW_UNICODE;
  public transactionType: 'Fast Route' | 'Cheap Route' = 'Cheap Route';
  public ExchangeType = ExchangeType;

  public CrosschainFeeType = CrosschainFeeType;
  private crossChainSwapComponent: CrossChainSwapComponent;
  private sameChainSwapComponent: SameChainSwapComponent;
  private walletAddressInput: ElementRef | undefined;
  public Networks = Networks;
  public Constants = Constants;
  public successTXCount = '0';

  @Output()
  searchParamsChanged = new EventEmitter();

  @ViewChild(CrossChainTokenSelectionComponent) childTokenSelect:
    | CrossChainTokenSelectionComponent
    | undefined;

  @ViewChild('walletAddressInput', { static: false })
  public set content(content: ElementRef) {
    if (content) {
      this.walletAddressInput = content;
    }
  }

  @ViewChild('Exchange', { static: false }) exchangeContent:
    | ElementRef
    | undefined;

  public contentSection!: HTMLElement;
  public affixed = true;
  @ViewChild('buttonSection', { static: false }) buttonSection:
    | ElementRef
    | undefined;

  // @ViewChild('History', { static: false }) historyComponent!: HistoryComponent;

  constructor(
    public web3Service: Web3Service,
    protected waitingDialogService: ModalService<WaitingDialogComponent>,
    protected txSubmittedDialogService: ModalService<TransactionSubmittedDialogComponent>,
    protected notificationService: NotificationService,
    protected priceService: PriceService,
    protected themeService: ThemeService,
    protected tagManagerService: TagManagerService,
    protected confirmDialogService: ModalService<ConfirmSwapDialogComponent>,
    protected portfolioService: PortfolioService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    protected storageService: StorageService,
    private settingDialogService: ModalService<SettingDialogComponent>,
    private crossChainSwapService: CrossChainSwapService,
    private location: Location,
    private crowdSwapService: CrowdSwapService,
    private exchangeInfoService: ExchangeInfoService,
    private exchangeTourModalService: ModalService<ExchangeTourDialogComponent>,
    private utilsService: UtilsService,
    private maskService: NgxMaskService,
    protected tokensService: TokensService,
    protected feedbackService: FeedbackService,
    protected connectWalletService: ConnectWalletService,
    protected userFeedbackService: ModalService<UserFeedbackComponent>,
    private importTokenDialogComponent: ModalService<ImportTokenDialogComponent>,
    public ref: ChangeDetectorRef
  ) {
    super(
      web3Service,
      themeService,
      tagManagerService,
      waitingDialogService,
      txSubmittedDialogService,
      notificationService,
      priceService,
      confirmDialogService,
      clientInfoServiceImpl,
      storageService,
      tokensService,
      feedbackService
    );

    this.crossChainSwapComponent = new CrossChainSwapComponent(
      this.state,
      this.status,
      this.setting,
      this.web3Service,
      this.themeService,
      this.tagManagerService,
      this.waitingDialogService,
      this.txSubmittedDialogService,
      this.notificationService,
      this.priceService,
      this.confirmDialogService,
      this.clientInfoServiceImpl,
      this.storageService,
      this.portfolioService,
      this.crossChainSwapService,
      this.utilsService,
      this.tokensService,
      this.feedbackService
    );
    this.sameChainSwapComponent = new SameChainSwapComponent(
      this.state,
      this.status,
      this.setting,
      this.web3Service,
      this.themeService,
      this.tagManagerService,
      this.waitingDialogService,
      this.txSubmittedDialogService,
      this.notificationService,
      this.priceService,
      this.confirmDialogService,
      this.clientInfoServiceImpl,
      this.storageService,
      this.connectWalletService,
      this.portfolioService,
      this.crowdSwapService,
      this.tokensService,
      this.feedbackService
    );

    const categoryOfURL = UtilsService.getURLCategory();
    if (categoryOfURL) {
      if (categoryOfURL === 'exchange') {
        const URLPattern = UtilsService.getPattern();
        switch (URLPattern) {
          case 'symbol': {
            const [fromToken, toToken] = UtilsService.getSwapInfoFromUrl();
            if (fromToken && toToken) {
              this.state.searchParam = Builder<SearchParamModel>()
                .fromToken(fromToken)
                .toToken(toToken)
                .swapValue('1')
                .slippage(CrowdSwapService.SLIPPAGE)
                .deadline(CrowdSwapService.DEADLINE)
                .build();
            }
            break;
          }
          case 'address': {
            this.utilsService.getSwapInfoFromUrlByAddress().then((result) => {
              if (!result.from.token || !result.to.token) {
                return;
              }
              if (!result.from.imported && !result.to.imported) {
                this.state.searchParam = Builder<SearchParamModel>()
                  .fromToken(result.from.token)
                  .toToken(result.to.token)
                  .swapValue('1')
                  .slippage(CrowdSwapService.SLIPPAGE)
                  .deadline(CrowdSwapService.DEADLINE)
                  .build();
              } else {
                const imported: CrowdToken[] = [];
                result.from.imported ? imported.push(result.from.token) : 0;
                result.to.imported ? imported.push(result.to.token) : 0;
                if (imported.length === 0) {
                  return;
                }
                this.showImportTokenConfirmDialog(imported).then(
                  (confirmed) => {
                    if (!confirmed) {
                      return;
                    }
                    const newSearchParam = Builder<SearchParamModel>()
                      .fromToken(result.from.token!)
                      .toToken(result.to.token!)
                      .swapValue('1')
                      .slippage(CrowdSwapService.SLIPPAGE)
                      .deadline(CrowdSwapService.DEADLINE)
                      .build();
                    this.updateSearchParams(newSearchParam);
                  }
                );
              }
            });
            break;
          }
        }
        this.location.replaceState('/exchange');
      }
    } else if (this.storageService.getItemFromLocalStorage('searchParam')) {
      this.state.searchParam = JSON.parse(
        this.storageService.getItemFromLocalStorage('searchParam')
      );
    }

    if (
      this.storageService.getItemFromLocalStorage(
        BaseSwapComponent.PENDING_TRADE_LIST_STORAGE_NAME
      )
    ) {
      const pendingList = this.storageService.getItemFromLocalStorage(
        BaseSwapComponent.PENDING_TRADE_LIST_STORAGE_NAME
      );
      this.state.pendingTradeList = JSON.parse(pendingList);
      this.state.pendingTradeList.forEach(
        (pendingTrade: CrossAndSameChainEstimateTrade, index: number) => {
          try {
            pendingTrade.minimize = true;
            if (
              !this.isCrossChainSwapState(
                pendingTrade,
                CrossChainSwapState.PassedPostCCStep
              )
            ) {
              this.observeSwapTransactionStatus(pendingTrade, index);
            }
          } catch (e) {
            this.setConsoleLog(
              pendingTrade,
              'An error occurred while observing the pending trade'
            );
          }
        }
      );
      // If the maximum parallel transaction is exceeded
      // if (this.state.pendingTradeList.length >= this.MAX_PARALLEL_TRANSACTION) {
      if (this.state.pendingTradeList.length > 0) {
        this.maximizeProgress(this.state.pendingTradeList[0]);
      }
    }

    const contentSection = document.getElementById('contentSection');
    if (contentSection) {
      this.contentSection = contentSection;
    }
  }

  public async ngOnInit() {
    super.ngOnInit(
      undefined,
      async (connection) => {
        this.changeCrossChainSwapState(
          this.state.activeEstimationTrade,
          connection
            ? CrossChainSwapState.Init
            : CrossChainSwapState.WalletNotConnected
        );
      },
      async (address) => {
        if (address && this.state.searchParam) {
          this.onPairSelected(this.state.searchParam);
        }

        if (this.state.searchParam) {
          this.changeCrossChainSwapState(
            this.state.activeEstimationTrade,
            CrossChainSwapState.Init
          );
          await this.checkBalance(
            this.state.activeEstimationTrade,
            this.state.searchParam.fromToken,
            this.state.searchParam.swapValue
          );
        }
        if (this.state.activeEstimationTrade) {
          if (
            !this.isCrossChainSwapState(
              this.state.activeEstimationTrade,
              CrossChainSwapState.WalletNotConnected
            ) &&
            !this.isCrossChainSwapState(
              this.state.activeEstimationTrade,
              CrossChainSwapState.InsufficientSourceBalance
            ) &&
            this.state.activeEstimationTrade.fromToken?.chainId ===
              this.web3Service.getCurrentChainId()
          ) {
            await this.checkAllowance(this.state.activeEstimationTrade);
          }
        }
      }
    );

    await this.tagManagerService.sendStartViewTag('cross_chain');

    this.successTXCount = this.maskService.applyMask(
      (
        (await this.exchangeInfoService.getSwapCount()) +
        (await this.exchangeInfoService.getOpportunitiesCount()) +
        (await this.exchangeInfoService.getCrossChainCount())
      ).toString(),
      'separator'
    );

    this.changeCrossChainSwapState(
      this.state.activeEstimationTrade,
      this.web3Service.isConnected()
        ? CrossChainSwapState.Init
        : CrossChainSwapState.WalletNotConnected
    );
  }

  ngAfterViewInit(): void {
    this.openExchangeTourDialog();
  }

  public isSwapParamCrossChain(fromToken?: CrowdToken, toToken?: CrowdToken) {
    if (!fromToken) {
      fromToken = this.state.searchParam?.fromToken;
    }
    if (!toToken) {
      toToken = this.state.searchParam?.toToken;
    }
    return this.utilsService.isSwapParamCrossChain(fromToken, toToken);
  }

  public isBridge(fromToken?: CrowdToken, toToken?: CrowdToken) {
    if (!fromToken) {
      fromToken = this.state.searchParam?.fromToken;
    }
    if (!toToken) {
      toToken = this.state.searchParam?.toToken;
    }

    return this.utilsService.isBridge(fromToken, toToken);
  }

  public override getEstimation(searchParam: SearchParamModel) {
    const setting = this.getSetting();
    searchParam.slippage = this.isSwapParamCrossChain()
      ? setting.crossChainSlippage
      : setting.slippage;
    searchParam.deadline = setting.deadline;

    if (this.walletAddress && this.walletAddress !== '')
      searchParam.userAddress = this.walletAddress;

    return this.isSwapParamCrossChain()
      ? this.crossChainSwapComponent.getEstimation(searchParam)
      : this.sameChainSwapComponent.getEstimation(searchParam);
  }

  public override analyzeEstimationCost(trade: CrossAndSameChainEstimateTrade) {
    return this.isSwapParamCrossChain()
      ? this.crossChainSwapComponent.analyzeEstimationCost(trade)
      : this.sameChainSwapComponent.analyzeEstimationCost(trade);
  }

  public override async getSwapTransaction(
    userAddress,
    trade: CrossAndSameChainEstimateTrade,
    searchParam?: SearchParamModel
  ) {
    if (this.isSwapParamCrossChain()) {
      return await this.crossChainSwapComponent.getSwapTransaction(
        userAddress,
        <CrossAndSameChainEstimateTrade>trade,
        searchParam
      );
    } else {
      return await this.sameChainSwapComponent.getSwapTransaction(
        userAddress,
        trade
      );
    }
  }

  public override async confirmSwapTransaction(
    trade: CrossAndSameChainEstimateTrade
  ) {
    this.isSwapParamCrossChain()
      ? await this.crossChainSwapComponent.confirmSwapTransaction(trade)
      : await this.sameChainSwapComponent.confirmSwapTransaction(trade);
  }

  public override async observeSwapTransactionStatus(
    trade: CrossAndSameChainEstimateTrade,
    index?: number
  ) {
    this.isEstimationCrossChain(trade)
      ? await this.crossChainSwapComponent.observeSwapTransactionStatus(
          trade,
          index
        )
      : await this.sameChainSwapComponent.observeSwapTransactionStatus(
          trade,
          index
        );
  }

  public override async checkAllowance(trade: CrossAndSameChainEstimateTrade) {
    try {
      this.isSwapParamCrossChain()
        ? await this.crossChainSwapComponent.checkAllowance(trade)
        : await this.sameChainSwapComponent.checkAllowance(trade);
    } catch (e) {
      console.log(e);
    }
  }

  public override async getApprovalTransaction(trade: EstimateTrade) {
    return this.isSwapParamCrossChain()
      ? this.crossChainSwapComponent.getApprovalTransaction(trade)
      : this.sameChainSwapComponent.getApprovalTransaction(trade);
  }

  public override async sendApprovalTransaction(trade, approvalTx) {
    return this.isSwapParamCrossChain()
      ? this.crossChainSwapComponent.sendApprovalTransaction(trade, approvalTx)
      : this.sameChainSwapComponent.sendApprovalTransaction(trade, approvalTx);
  }

  public async settingDialog() {
    const data = {
      slippage: CrowdSwapService.SLIPPAGE,
      crossChainSlippage: commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE,
      isCrossChainSwap: this.isSwapParamCrossChain(),
      isDarkMode: this.isDarkMode
    };
    const settingDialogRef = await this.settingDialogService.open(
      SettingDialogComponent,
      data,
      false
    );

    <SettingDialogComponent>(<any>settingDialogRef!.instance).closed.subscribe(
      async (isChanged) => {
        await this.settingDialogService.close();
        if (isChanged) {
          await this.refreshSearch();
        }
      }
    );
  }

  public async onPairSelected(
    searchParam: SearchParamModel,
    userRefresh: boolean = true
  ) {
    try {
      if (
        !this.isSwapParamCrossChain() &&
        this.status.exchangeType === ExchangeType.Limit
      ) {
        this.status.exchangeType = ExchangeType.Market;
      }

      this.clearCrossChainSwapSubscription();
      this.unSubscribeAutoSearch();
      this.setSearchParamForExactOutput(searchParam);

      if (userRefresh) {
        this.setBestEstimation({
          ...this.state.activeEstimationTrade,
          loading: true
        });
      }
      this.setting.slippage = searchParam.slippage;
      this.setting.deadline = searchParam.deadline;

      searchParam.toToken.price = await this.priceService.getPrice(
        searchParam.toToken,
        this.web3Service.getNetworkProvider(searchParam.toToken.chainId)
      );

      if (searchParam.toToken.price) {
        searchParam.toTokenPriceToDisplay = formatNumber(
          parseFloat(searchParam.toToken.price),
          NumberType.FiatTokenPrice
        );
      } else {
        searchParam.toTokenPriceToDisplay = 'NaN';
      }

      searchParam.fromToken.price = await this.priceService.getPrice(
        searchParam.fromToken,
        this.web3Service.getNetworkProvider(searchParam.fromToken.chainId)
      );

      const chainId = searchParam.fromToken.chainId;

      const gasPrice = await this.web3Service.getGasPrice(
        this.web3Service.getNetworkProvider(searchParam.fromToken.chainId)
      );
      searchParam.gasPrice = gasPrice.toString();
      searchParam.networkCoinInUSDT = await this.priceService.getPrice(
        TokensHolder.TokenListBySymbol[NetworksById[chainId]][
          this.web3Service.networkSpec[chainId].coin
        ],
        this.web3Service.getNetworkProvider(chainId)
      );

      this.state.searchParam = searchParam;
      this.storageService.setItemToLocalStorage('searchParam', searchParam);

      this.state.estimationSubscription = this.getEstimation(
        searchParam
      )?.subscribe(() => {
        this.startAutoRefresh();
      });
    } catch (e) {
      console.log('Unknown estimation ' + e);
      this.startAutoRefresh();
    }
  }

  public get swapButtonVisible() {
    const excludedStates = [
      CrossChainSwapState.WalletNotConnected,
      CrossChainSwapState.InsufficientSourceBalance,
      CrossChainSwapState.InsufficientCoinBalance,
      CrossChainSwapState.AmountInIsTooLow,
      CrossChainSwapState.InsufficientTakerBalance
    ];

    return (
      excludedStates.indexOf(
        this.state.activeEstimationTrade.crossChainSwapState
      ) === -1
    );
  }

  public get swapButtonDisable() {
    const { activeEstimationTrade, receiverAddress } = this.state;
    const { crossChainSwapState, costAnalyze } = activeEstimationTrade;

    const isNotInitState =
      crossChainSwapState !== CrossChainSwapState.Init &&
      crossChainSwapState !== CrossChainSwapState.ApprovalConfirmed &&
      crossChainSwapState !== CrossChainSwapState.FailedPreCCStep &&
      crossChainSwapState !== CrossChainSwapState.FailedCCStep &&
      crossChainSwapState !== CrossChainSwapState.FailedPostCCStep;

    const isUnusualAmountOrFee =
      costAnalyze?.isUnusualAmountOut || costAnalyze?.isUnusualFee;

    const isUnusualAndNotConfirmed =
      isUnusualAmountOrFee && !costAnalyze?.confirmed;

    const loading = this.state.activeEstimationTrade.loading;

    const isReceiverWalletSelected =
      this.setting.isReceiverWalletSelected &&
      (receiverAddress === this.web3Service.getWalletAddress() ||
        !this.status.isAddressValid);

    return (
      isNotInitState ||
      isUnusualAndNotConfirmed ||
      loading ||
      isReceiverWalletSelected
    );
  }

  public get approveButtonVisible() {
    const { activeEstimationTrade } = this.state;
    const { crossChainSwapState, costAnalyze } = activeEstimationTrade;

    const isApprovalState =
      crossChainSwapState === CrossChainSwapState.ApprovalNeeded ||
      crossChainSwapState === CrossChainSwapState.ApprovalRejected;

    const isWrongNetwork = this.isWrongNetwork;

    return isApprovalState && !isWrongNetwork;
  }

  public walletAddressInputFocus() {
    if (this.setting.isReceiverWalletSelected) {
      setTimeout(() => {
        this.walletAddressInput?.nativeElement.focus();
      }, 1);
    }
  }
  public async approve(): Promise<any> {
    try {
      if (!this.state.activeEstimationTrade) {
        throw new Error(
          "CrossChainSwapComponent: it's not possible to get approval when active estimation trade is empty"
        );
      }

      this.unSubscribeAutoSearch();

      if (
        !this.web3Service.usingCrowdWallet &&
        this.web3Service.getWalletChainId() !==
          this.state.activeEstimationTrade.fromToken.chainId
      ) {
        await this.changeNetworkTo(
          this.state.activeEstimationTrade.fromToken.chainId
        );
      }

      if (!this.isCoin(this.state.activeEstimationTrade.fromToken)) {
        await this.showApproveWaitingDialog(this.state.activeEstimationTrade);

        let approvalTx = await this.getApprovalTransaction(
          this.state.activeEstimationTrade
        );
        if (!approvalTx) {
          throw new Error('Approve transaction is undefined');
        }
        await this.sendApprovalTransaction(
          this.state.activeEstimationTrade,
          approvalTx
        );
        await this.waitingDialogService.close();
      }
    } catch (e) {
      console.log(e);
      this.changeCrossChainSwapState(
        this.state.activeEstimationTrade,
        CrossChainSwapState.ApprovalRejected
      );
      await this.waitingDialogService.close();
    } finally {
      this.startAutoRefresh();
    }
  }
  public async swap(): Promise<any> {
    this.tagManagerService.sendCrossChainTags(
      'exchange_start',
      this.state.activeEstimationTrade,
      this.web3Service.getWalletAddress()
    );
    try {
      this.unSubscribeAutoSearch();

      if (!this.state.activeEstimationTrade) {
        this.setConsoleLog(
          this.state.activeEstimationTrade,
          ' ActiveEstimationTrade dose not exists, start swap estimation failed. '
        );
        return;
      }

      if (
        !this.web3Service.usingCrowdWallet &&
        this.web3Service.getWalletChainId() !==
          this.state.activeEstimationTrade.fromToken.chainId
      ) {
        await this.changeNetworkTo(
          this.state.activeEstimationTrade.fromToken.chainId
        );
      }

      this.setConsoleLog(
        this.state.activeEstimationTrade,
        ' Start getting transaction. '
      );

      const userAddress = this.walletAddress;
      if (!userAddress) {
        return;
      }

      //Select swap or CrossChain swap
      let swapTx: any;

      swapTx = await this.useSwapTxOrDoTransaction();

      if (!swapTx) {
        throw new Error('SwapTx is empty');
      }
      this.state.activeEstimationTrade.swapTx = swapTx;
      //TODO: what if swapTx is undefined

      this.setConsoleLog(
        this.state.activeEstimationTrade,
        ' Start confirm swap in expert mode. '
      );
      await this.showSwapWaitingDialog(
        this.state.activeEstimationTrade!,
        'cross_est_waiting',
        false,
        this.isSwapParamCrossChain()
      );
      await this.confirmSwapTransaction({
        ...this.state.activeEstimationTrade
      });
    } catch (e) {
      console.log(e);
      await this.waitingDialogService.close();
    } finally {
      this.startAutoRefresh();
    }
  }

  public isAddress() {
    this.status.isAddressValid = this.web3Service.isAddress(
      this.state.receiverAddress
    );
  }
  protected preparePatchNewAmount(
    pendingTrade: CrossAndSameChainEstimateTrade
  ) {
    if (this.isSwapParamCrossChain())
      this.crossChainSwapComponent.preparePatchNewAmount(pendingTrade);
  }
  public minimizeProgress(trade: CrossAndSameChainEstimateTrade) {
    trade.minimize = true;
    this.state.searchParam && this.onPairSelected(this.state.searchParam);
  }

  public maximizeProgress(trade: CrossAndSameChainEstimateTrade) {
    this.state.pendingTradeList.forEach((pendingTrade) => {
      pendingTrade.minimize = true;
    });
    trade.minimize = false;
  }

  public removePendingTrade(
    trade: CrossAndSameChainEstimateTrade,
    index: number
  ) {
    if ((trade.swapTx && trade.swapTx.hash) || index !== null) {
      this.removeFromPendingTradeList(trade.swapTx?.hash, index);
      // If the trade is not minimized, reset research
      !trade.minimize &&
        this.state.searchParam &&
        this.onPairSelected(this.state.searchParam);
    }
  }

  public isAllTransactionsMinimized(
    pendingTradeList: CrossAndSameChainEstimateTrade[]
  ) {
    if (!pendingTradeList || pendingTradeList.length === 0) {
      return true;
    }
    return pendingTradeList.every((pendingTrade) => {
      return pendingTrade.minimize;
    });
  }

  stopRefresh() {
    if (!this.state.hasAutoSearchSubscriber) {
      return;
    }

    this.switchRefreshSearch();
  }

  public async switchRefreshSearch(event?: any) {
    event?.stopPropagation();

    this.state.hasAutoSearchSubscriber = !this.state.hasAutoSearchSubscriber;
    if (this.state.hasAutoSearchSubscriber) {
      this.startAutoRefresh();
    } else {
      this.state.autoSearchSubscriber &&
        typeof this.state.autoSearchSubscriber.unsubscribe === 'function' &&
        this.state.autoSearchSubscriber.unsubscribe();
      this.startAutoRefresh(true);
    }
  }

  public async refreshSearch(userRefresh: boolean = true) {
    if (!this.state.searchParam) {
      return;
    }

    if (userRefresh && this.state.searchParam) {
      this.state.searchParam.lastUserChangeTime = Date.now();
    }
    await this.onPairSelected(this.state.searchParam, userRefresh);
  }

  public async setToMarket() {
    this.childTokenSelect?.setToMarket();
  }

  public async cancelTransaction(pendingTrade: CrossAndSameChainEstimateTrade) {
    try {
      await this.crossChainSwapComponent.getCancelationTransaction(
        pendingTrade
      );
    } catch (e) {
      console.log(e);
    }
  }

  public async approvePatchNewAmount(
    pendingTrade: CrossAndSameChainEstimateTrade
  ) {
    try {
      await this.crossChainSwapComponent.approvePatchNewAmount(pendingTrade);
    } catch (e) {
      console.log(e);
    }
  }
  public ngOnDestroy() {
    super.ngOnDestroy();
    this.clearCrossChainSwapSubscription();
    this.unSubscribeAutoSearch();

    this.state.pendingTradeList.forEach((pendingTrade) => {
      this.clearConfirmationInterval(pendingTrade);
    });
  }

  private async showApproveWaitingDialog(trade: EstimateTrade) {
    const data = {
      sourceTokenSymbol: trade.fromToken.symbol,
      isDarkMode: this.isDarkMode
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  private startAutoRefresh(ignoreAutoRefresh: boolean = false) {
    this.unSubscribeAutoSearch();

    const timer$: any = interval(100);
    this.state.autoSearchSubscriber = timer$.subscribe(async (sec) => {
      this.setting.countDownTimer =
        100 - (sec * 10) / CrowdSwapService.INTERVAL_REFRESH_TIME;

      const timeElapsed =
        Date.now() - (this.state.searchParam?.lastUserChangeTime ?? 0);

      this.doResetEstimation(timeElapsed);
      await this.doRefreshEstimation(timeElapsed, sec, ignoreAutoRefresh);
    });
  }
  private doResetEstimation(timeElapsed: number) {
    if (
      this.state.searchParam?.lastUserChangeTime &&
      timeElapsed >= CrowdSwapService.END_REFRESH_TIME
    ) {
      this.setting.countDownTimer = 100;
      this.state.autoSearchSubscriber.unsubscribe();
      this.changeCrossChainSwapState(
        this.state.activeEstimationTrade,
        CrossChainSwapState.Expired
      );
    }
  }
  private async doRefreshEstimation(
    timeElapsed: number,
    sec: any,
    ignoreAutoRefresh: boolean
  ) {
    if (CrowdSwapService.INTERVAL_REFRESH_TIME * 10 === sec) {
      if (
        this.state.searchParam?.lastUserChangeTime &&
        timeElapsed < CrowdSwapService.END_REFRESH_TIME &&
        this.state.hasAutoSearchSubscriber &&
        !ignoreAutoRefresh
      ) {
        await this.refreshSearch(false);
      }
    }
  }
  private clearCrossChainSwapSubscription() {
    if (
      this.state.estimationSubscription &&
      typeof this.state.estimationSubscription.unsubscribe === 'function'
    ) {
      this.state.estimationSubscription.unsubscribe();
    }
    this.state.estimationSubscription = undefined;
  }

  private getSetting(): SettingModel {
    let settingJson = localStorage.getItem('user-setting');
    return settingJson ? JSON.parse(settingJson) : this.getDefaultSetting();
  }
  private async useSwapTxOrDoTransaction() {
    if (
      !this.state.activeEstimationTrade.swapTx ||
      this.setting.isReceiverWalletSelected
    ) {
      return await this.getSwapTransaction(
        this.walletAddress,
        this.state.activeEstimationTrade
      );
    } else {
      return this.state.activeEstimationTrade.swapTx;
    }
  }
  getDefaultSetting(): SettingModel {
    return {
      unitTokenDollar: true,
      expertMode: false,
      slippage: CrowdSwapService.SLIPPAGE,
      crossChainSlippage: commonConfig.CROSS_CHAIN.MINIMUM_SLIPPAGE,
      deadline: CrowdSwapService.DEADLINE
    };
  }

  async openExchangeTourDialog() {
    let exchangeTour: { status: boolean; version: string };

    if (this.storageService.getItemFromLocalStorage(exchangeTourDialogOpened)) {
      exchangeTour = JSON.parse(
        this.storageService.getItemFromLocalStorage(exchangeTourDialogOpened)
      );

      if (
        exchangeTour &&
        exchangeTour.version === environment.version &&
        exchangeTour.status
      ) {
        return;
      }
    }

    const data: DialogDataModel = {
      className: 'exchange-tour-dialog',
      isDarkMode: this.isDarkMode
    };

    let exchangeTourDialogRef = await this.exchangeTourModalService.open(
      ExchangeTourDialogComponent,
      data
    );

    const sub: Subscription = (<ExchangeTourDialogComponent>(
      (<any>exchangeTourDialogRef!.instance)
    )).confirmed.subscribe(async (data) => {
      await this.exchangeTourModalService.close();

      this.storageService.setItemToLocalStorage(exchangeTourDialogOpened, {
        status: true,
        version: environment.version
      });
    });

    exchangeTourDialogRef!.onDestroy(() => {
      sub.unsubscribe();
    });
  }

  public refresh() {
    window.location.reload();
  }

  public showMore() {
    this.status.showMoreFee = !this.status.showMoreFee;
  }

  async showImportTokenConfirmDialog(newToken: CrowdToken[]): Promise<boolean> {
    // Declare a variable to store the resolve function
    let resolver: (value: PromiseLike<boolean> | boolean) => void;
    // Create a new promise and assign the resolve function to the variable
    let promise = new Promise<boolean>((resolve, reject) => {
      resolver = resolve;
    });
    const data = {
      allTokens: newToken,
      isDarkMode: this.isDarkMode
    };
    let importTokenDialogRef = await this.importTokenDialogComponent.open(
      ImportTokenDialogComponent,
      data
    );
    const sub: Subscription = (<ImportTokenDialogComponent>(
      (<any>importTokenDialogRef!.instance)
    )).confirmed.subscribe(async (isImportConfirmed: boolean) => {
      if (isImportConfirmed) {
        for (let i = 0; i < newToken.length; i++) {
          this.importNewToken(newToken[i]);
        }
        resolver(true);
      } else {
        resolver(false);
      }
      await this.importTokenDialogComponent.close();
    });
    importTokenDialogRef!.onDestroy(() => {
      sub.unsubscribe();
    });

    return promise;
  }

  private importNewToken(token: any) {
    const chainId = token.chainId;
    const address = token.address.toLowerCase();
    const tokenObj = localStorage.getItem('tokens')
      ? JSON.parse(localStorage.getItem('tokens')!)
      : {};
    tokenObj[chainId] = tokenObj[chainId] || { [address]: token };
    tokenObj[chainId][address] = tokenObj[chainId][address] || token;
    localStorage.setItem('tokens', JSON.stringify(tokenObj));
  }

  private updateSearchParams(newSearchParams: SearchParamModel) {
    this.state.searchParam = newSearchParams;
    this.ref.detectChanges();
    this.childTokenSelect?.updateState(this.web3Service.getCurrentChainId());
    this.childTokenSelect?.resetSearch();
  }

  /**
   * Switches the exchange type, updating the search parameters if applicable for limit orders.
   * If the exchange type is not 'Limit' or there is no search parameter, it simply updates the exchange type.
   *
   * @param type - The target exchange type to switch to.
   */
  public async switchExchangeType(type: ExchangeType) {
    // Check if the target exchange type is 'Limit' and there is a valid search parameter.
    if (type !== ExchangeType.Limit || !this.state.searchParam) {
      // Update the exchange type and exit the function.
      this.status.exchangeType = type;
      return;
    }

    // Define the list of best chain IDs for reference.
    const bestChainIds = [
      Networks.POLYGON_MAINNET,
      Networks.BSCMAIN,
      Networks.ARBITRUM
    ];

    // Retrieve the limit order tokens asynchronously.
    const limitOrderTokens = this.tokensService.LimitOrderTokensList;

    // Destructure the fromToken and toToken from the search parameter.
    const { fromToken, toToken } = this.state.searchParam;

    // Check if the fromToken and toToken chain IDs are unsupported for limit orders.
    const fromChainIdInvalid = this.utilsService.isUnsupportedLimitOrderChainId(
      fromToken,
      limitOrderTokens
    );
    const toChainIdInvalid = this.utilsService.isUnsupportedLimitOrderChainId(
      toToken,
      limitOrderTokens
    );

    // Initialize variables to store updated fromToken and toToken.
    let updatedFromToken = fromToken;
    let updatedToToken = toToken;

    // Update fromToken if its chain ID is unsupported.
    if (fromChainIdInvalid) {
      const fromChainId = bestChainIds.find(
        (chain) => chain !== toToken.chainId
      )!;
      updatedFromToken =
        TokensHolder.TokenListBySymbol[NetworksById[fromChainId]][
          this.web3Service.networkSpec[fromChainId].coin
        ];
    }

    // Update toToken if its chain ID is unsupported or the swap is not cross-chain.
    if (toChainIdInvalid || !this.isSwapParamCrossChain()) {
      const toChainId = bestChainIds.find(
        (chain) => chain !== updatedFromToken.chainId
      )!;
      updatedToToken =
        TokensHolder.TokenListBySymbol[NetworksById[toChainId]]['CROWD'];
    }

    // Check if the updated fromToken is unsupported.
    const fromTokenInvalid =
      this.utilsService.isUnsupportedLimitOrderFromTokens(
        updatedFromToken,
        limitOrderTokens && limitOrderTokens[updatedFromToken.chainId]
      );

    // Update fromToken if it is unsupported.
    if (fromTokenInvalid) {
      updatedFromToken =
        TokensHolder.TokenListBySymbol[NetworksById[updatedFromToken.chainId]][
          this.web3Service.networkSpec[updatedFromToken.chainId].coin
        ];
    }

    // Check if the updated toToken is unsupported.
    const toTokenInvalid = this.utilsService.isUnsupportedLimitOrderToTokens(
      updatedToToken,
      limitOrderTokens && limitOrderTokens[updatedToToken.chainId]
    );

    // Update toToken if it is unsupported.
    if (toTokenInvalid) {
      const toChainId = bestChainIds.find(
        (chain) => chain !== updatedFromToken.chainId
      )!;
      updatedToToken =
        TokensHolder.TokenListBySymbol[NetworksById[toChainId]]['CROWD'];
    }

    // Build and update the search parameters with the updated tokens.
    this.state.searchParam = Builder<SearchParamModel>()
      .fromToken(updatedFromToken)
      .toToken(updatedToToken)
      .swapValue(this.state.searchParam.swapValue ?? '1')
      .exchangeType(type)
      .slippage(CrowdSwapService.SLIPPAGE)
      .deadline(CrowdSwapService.DEADLINE)
      .limitAmountDifference(this.state.searchParam.limitAmountDifference)
      .build();

    // Update the exchange type.
    this.status.exchangeType = type;
  }

  protected setSearchParamForExactOutput(searchParam: SearchParamModel) {}

  public affixChange(status: boolean): void {
    this.affixed = status;
  }

  public scrollToBtn() {
    this.contentSection.scrollTo({
      top:
        this.buttonSection?.nativeElement.offsetTop -
        this.contentSection.offsetHeight / 2,
      behavior: 'smooth'
    });
  }
}
