<app-dialog
  [className]="data?.className"
  class="exchange-tour-dialog position-relative"
>
  <div
    class="w-full d-flex flex-column exchange-tour-dialog-section position-relative"
  >
    <div class="d-flex align-items-center justify-content-between px-20px">
      <label class="font-16 font-openSans-bold m-0 dark:text-fff text-000">
        Welcome to CrowdSwap
      </label>

      <span class="bg-EFEFEF p-1 border-radius-4px text-000">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          (click)="toggleDropdown()"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </span>
    </div>

    <span class="font-10 font-w-500 dark:text-fff text-000 px-20px">
      Release {{ version }}
    </span>

    <swiper
      #swiper
      (slideChange)="beforeSlideChangeStart($event)"
      [config]="config"
      class="w-full h-100 mt-3 bg-fff"
    >
      <ng-template swiperSlide *ngFor="let item of tourList">
        <div class="w-full d-flex flex-column">
          <div class="w-full bg-fff dark:bg-121212">
            <img
              class="w-full d-block"
              [src]="
                item.darkImg && data?.isDarkMode
                  ? 'assets/media/exchange-tour/' + item.darkImg + '.png'
                  : 'assets/media/exchange-tour/' + item.img + '.png'
              "
              alt="High Speed Cross-Chain Transaction"
            />
          </div>

          <div class="w-full d-flex flex-column">
            <span class="font-24 text-000 font-openSans-bold mt-2 px-20px"
              >{{ item.title }}
            </span>

            <span
              *ngFor="let desc of item.desc"
              class="font-14 flex text-000 mt-2 px-20px"
            >
              <span class="mr-1 mt-1">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_8046_86659)">
                    <path
                      d="M6.75078 12.1502L3.60078 9.0002L2.55078 10.0502L6.75078 14.2502L15.7508 5.2502L14.7008 4.2002L6.75078 12.1502Z"
                      fill="#13B9AA"
                    />
                    <path
                      d="M6.75078 12.1502L3.60078 9.0002L2.55078 10.0502L6.75078 14.2502L15.7508 5.2502L14.7008 4.2002L6.75078 12.1502Z"
                      fill="black"
                      fill-opacity="0.2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8046_86659">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>

              {{ desc }}
            </span>
          </div>
        </div>
      </ng-template>
    </swiper>

    <div
      class="w-full d-flex align-items-center justify-content-between px-20px pt-3 bg-fff pb-20px"
    >
      <button
        [ngClass]="{ 'opacity-0 pointer-events-none': swiperSlideIsEnd }"
        nz-button
        nzType="default"
        class="ant-btn button-d-0066FF border-2px border-solid border-radius-4px text-0066FF dark:text-3385FF font-14 font-openSans-bold py-2 d-flex align-items-center justify-content-center h-42px"
        (click)="toggleDropdown()"
      >
        Skip
      </button>

      <div
        class="swiper-custome-pagination d-flex justify-content-center align-items-center"
      ></div>

      <button
        *ngIf="!swiperSlideIsEnd"
        nz-button
        nzType="default"
        class="button-0066FF next-btn border-radius-4px text-white font-14 font-openSans-bold text-center hover:bg-blue-700 py-2 d-flex align-items-center justify-content-center h-42px"
      >
        Next
      </button>

      <button
        *ngIf="swiperSlideIsEnd"
        nz-button
        nzType="default"
        class="button-0066FF border-radius-4px text-white font-14 font-openSans-bold text-center hover:bg-blue-700 py-2 d-flex align-items-center justify-content-center h-42px"
        (click)="toggleDropdown()"
      >
        Done
      </button>
    </div>
  </div>
</app-dialog>
