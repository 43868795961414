import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from './modal.service';
import { BaseComponent } from '../pages/base.component';
import {
  NDDClientInfoServiceImpl,
  TagManagerService,
  ThemeService,
  Web3Service
} from '../../services';

@Component({
  selector: 'app-dialog',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent<T> extends BaseComponent {
  display = true;
  @Input('closable')
  public closable: boolean = true;

  @Input('isSelectOption')
  public isSelectOption: boolean = false;

  @Input('dialogWidth')
  public dialogWidth: boolean = false;

  @Input('className')
  public className!: string | undefined;

  @Input('disableMode')
  public disableMode: boolean = false;

  constructor(
    private dialogService: ModalService<T>,
    protected themeService: ThemeService,
    protected web3Service: Web3Service,
    protected tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  async close(): Promise<void> {
    if (!this.closable) {
      return;
    }
    this.display = false;
    setTimeout(async () => {
      await this.dialogService.close();
    }, 300);
  }
}
