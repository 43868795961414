import { Injectable } from '@angular/core';
import { Web3Service } from './web3.service';
import { LoggingService } from './log';
import { PriceService } from '@crowdswap/constant';
import { TokensService } from './tokens.service';
import { StakeService } from './stake.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

const baseUrl = environment.PORTFOLIO_BASEURL || '';

@Injectable()
export class FeedbackService {
  private _feedbackRequest: Subject<string> = new Subject<string>();

  constructor(
    private web3Service: Web3Service,
    private stakeService: StakeService,
    private priceService: PriceService,
    private tokensService: TokensService,
    private logger: LoggingService,
    private http: HttpClient
  ) {}

  get feedbackRequestSubject(): Subject<string> {
    return this._feedbackRequest;
  }

  public async insertUserFeedback(userFeedback): Promise<void> {
    try {
      const url = `${baseUrl}/api/v1/feedback/user-feedback`;
      const data = {
        address: userFeedback.address,
        feelLevel: userFeedback.feelLevel.toString(),
        description: userFeedback.description,
        email: userFeedback.email
      };

      await this.http.post(url, data).toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
      throw new Error(`Unable to set feedback for ${userFeedback.address}`);
    }
  }

  public async hasNewFeedback(userAddress: string | undefined): Promise<any> {
    if (!userAddress) {
      return false;
    }
    try {
      let hasNewFeedback: boolean | undefined;
      const url = `${baseUrl}/api/v1/feedback/has-feedback`;

      const data = {
        address: userAddress
      };
      hasNewFeedback = await this.http
        .get<boolean>(url, {
          params: data
        })
        .toPromise();
      return hasNewFeedback;
    } catch (err: any) {
      console.log(`Cannot get validation`);
    }
  }
}
