import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Networks, TokensHolder } from '@crowdswap/constant';
import { DialogsComponent } from '../dialogs.component';
import { Web3Service } from '../../../../services';
import { ModalModule } from '../../modal.module';
import { SharedModule } from 'src/app/share/shared.module';

@Component({
  selector: 'app-transaction-submitted-dialog',
  templateUrl: './transaction-submitted-dialog.component.html',
  styleUrls: ['./transaction-submitted-dialog.component.scss']
})
export class TransactionSubmittedDialogComponent extends DialogsComponent {
  constructor(private web3Service: Web3Service) {
    super();
  }

  close() {
    this.closed.emit(true);
  }

  async addToken() {
    const currentNetwork = this.web3Service.getCurrentChainId();
    const destTokenOnNetwork =
      TokensHolder.TokenListBySymbol[Networks[currentNetwork]][
        this.data?.destTokenSymbol ?? '-'
      ];
    await this.web3Service.addTokenToWallet(destTokenOnNetwork);
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, SharedModule],
  declarations: [TransactionSubmittedDialogComponent]
})
export class TransactionSubmittedDialogComponentModule {}
