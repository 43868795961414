<div
  class="container flex font-12 justify-center px-3 px-lt-24px"
  id="investmentList"
>
  <div class="z-0 rounded-md w-full pt-5">
    <div>
      <div
        *ngIf="!showInvestment"
        class="relative rounded-xl pt-1 dark:text-textWhite"
      >
        <div
          *ngIf="isViewMore && activePresales && activePresales.length > 0"
          class="pb-4 justify-center"
        >
          <swiper
            [preventClicks]="false"
            [slidesPerView]="1"
            [spaceBetween]="50"
            [navigation]="true"
            [pagination]="{ type: 'fraction' }"
            [centeredSlides]="true"
            [keyboard]="{ enabled: true }"
            [virtual]="true"
            class="my-swiper"
          >
            <ng-template
              swiperSlide
              *ngFor="let item of activePresales; index as index"
            >
              <app-banner-private-sale
                [presale]="item"
              ></app-banner-private-sale>
            </ng-template>
          </swiper>

          <div></div>
          <!-- {{result}} -->
        </div>

        <ul
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
          class="flex sm:space-x-2 sm:justify-content-between md:flex-wrap px-2 -mb-px text-sm font-medium tab-margin text-center text-gray-500 dark:text-gray-400"
          [ngClass]="{
            'mt-4': isViewMore && activePresales && activePresales.length > 0
          }"
        >
          <li class="mr-3 w-full md:w-auto" role="presentation">
            <a
              id="invest-tab"
              data-tabs-target="#invest"
              role="tab"
              aria-controls="invest"
              aria-selected="true"
              class="inline-flex px-2 py-1 p-md-2 w-full justify-center md:w-auto rounded-md cursor-pointer border-2 border-transparent font-bold group hover:border-gray-300"
              aria-current="page"
              (click)="toggleTabs(selectedOpportunityTab.InvestedOpportunities)"
              [ngClass]="
                selectedTab === selectedOpportunityTab.InvestedOpportunities
                  ? 'text-fff button-0066FF'
                  : 'text-000 bg-blue-100 dark:bg-2D2D2D dark:text-fff'
              "
            >
              Your Invests
            </a>
          </li>

          <li
            class="ml-3 w-full md:w-auto"
            role="presentation"
            *ngIf="!isPortfolio"
          >
            <a
              id="opportunity-tab"
              data-tabs-target="#opportunity"
              role="tab"
              aria-controls="opportunity"
              aria-selected="true"
              class="inline-flex px-2 py-1 p-md-2 w-full justify-center md:w-auto rounded-md cursor-pointer border-2 border-transparent font-bold group hover:border-gray-300"
              (click)="toggleTabs(selectedOpportunityTab.AllOpportunities)"
              [ngClass]="
                selectedTab === selectedOpportunityTab.AllOpportunities
                  ? 'text-fff button-0066FF'
                  : 'text-000 bg-blue-100 dark:bg-2D2D2D dark:text-fff'
              "
            >
              Opportunities
            </a>
          </li>
        </ul>

        <div
          *ngIf="startTime < currentTime && finishTime > currentTime"
          class="d-md-flex rounded-md px-2 mb-md-3 justify-center"
        >
          <a
            href="https://www.crowdswap.org/2022/11/24/crowdswap-launches-defi-black-friday-to-give-away-10000-crowd-as-prize/"
            target="_blank"
          >
            <img
              class="d-none d-md-inline rounded-md"
              src="assets/media/banners/black-friday.gif"
            />
            <img
              class="d-md-none rounded-md"
              src="assets/media/banners/black-friday-mobile.gif"
            />
          </a>
        </div>

        <div
          class="investment-list bg-fff dark:bg-121212 rounded-md p-1 p-sm-3 dark:border-1E1E1E dark:border-2"
        >
          <div
            *ngIf="!isViewMore"
            class="w-full pl-2 pr-2 d-md-flex pt-2 justify-center"
          >
            <div
              (click)="searchOppInput.focus()"
              class="col-12 col-md-5 h-10 d-flex align-items-center rounded-md border-1px bg-fff dark:bg-1E1E1E border-323232 dark:border-2D2D2D border-2px-hover border-2px-focus text-base pl-3 pr-3 mt-3"
            >
              <svg
                width="18px"
                height="18px"
                viewBox="0 0 18 18"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>BB6A6DA6-4619-4FF0-AB89-86075C001114</title>
                <g
                  id="Select-Token"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Select-a-token"
                    transform="translate(-474.000000, -179.000000)"
                    fill="currentColor"
                  >
                    <g
                      id="Group-4"
                      transform="translate(441.000000, 98.000000)"
                    >
                      <g
                        id="Text-Field/_Resource/Box"
                        transform="translate(20.000000, 64.000000)"
                      >
                        <g
                          id="Icon/Search/Oultine"
                          transform="translate(13.666667, 17.666667)"
                        >
                          <path
                            d="M7.08333333,0 C10.9953503,0 14.1666667,3.17131636 14.1666667,7.08333333 C14.1666667,8.74182968 13.5966768,10.2671978 12.6419041,11.4742305 L16.7079314,15.5344799 C16.9684971,15.7946131 16.9688478,16.2167229 16.7087146,16.4772887 L16.4732083,16.7131867 C16.2130751,16.9737524 15.7909653,16.9741031 15.5303996,16.7139699 L11.4616951,12.6517969 C10.2566449,13.6006127 8.73609359,14.1666667 7.08333333,14.1666667 C3.17131636,14.1666667 0,10.9953503 0,7.08333333 C0,3.17131636 3.17131636,0 7.08333333,0 Z M7.08333333,1.66666667 C4.09179094,1.66666667 1.66666667,4.09179094 1.66666667,7.08333333 C1.66666667,10.0748757 4.09179094,12.5 7.08333333,12.5 C10.0748757,12.5 12.5,10.0748757 12.5,7.08333333 C12.5,4.09179094 10.0748757,1.66666667 7.08333333,1.66666667 Z"
                            id="Combined-Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <input
                #searchOppInput
                [(ngModel)]="searchOpp"
                (ngModelChange)="filterDataSourceValue($event)"
                placeholder="Search opportunity"
                autofocus
                class="w-full pl-1 ml-2 text-000 dark:text-fff border-0 outline-none bg-transparent placeholder-opacity-100 placeholder-opacity-000 dark:placeholder-opacity-fff"
              />

              <div
                *ngIf="false"
                class="text-right w-full font-12-2 opacity-50 text-000 dark:text-fff dark:opacity-100"
              >
                Filters
              </div>
            </div>

            <div class="col-12 col-md-7 mt-2 mt-md-3 ps-md-2">
              <div
                class="d-flex align-items-center position-relative justify-content-end"
              >
                <span class="pr-2 text-000 dark:text-fff">Sort&nbsp;by:</span>

                <button
                  (click)="selectFlag = !selectFlag"
                  id="network-dropdown"
                  (clickOutside)="onClose()"
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="inline-flex justify-content-between items-center h-10 w-full md:w-40 rounded-md border-1px border-323232 dark:border-2D2D2D shadow-sm p-2 bg-fff dark:bg-1E1E1E text-sm font-medium border-2px-hover border-2px-focus text-000 dark:text-fff"
                  type="button"
                >
                  <div class="grid grid-rows-1">
                    <span
                      class="row-span-1 font-openSans-bold text-000 dark:text-fff text-left"
                      >{{ sort }}</span
                    >
                  </div>

                  <div class="flex justify-end">
                    <svg
                      class="-mr-1 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                </button>

                <div
                  *ngIf="selectFlag"
                  aria-labelledby="menu-button"
                  aria-orientation="vertical"
                  class="w-max absolute top-0 w-66 z-20 h-auto rounded-md shadow-lg bg-fff dark:bg-121212 ring-1 ring-black dark:ring-gray-700 ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                  role="menu"
                >
                  <div class="overflow-x-hidden max-h-90 m-sm-2 m-1">
                    <div
                      (click)="sortList(item, false)"
                      *ngFor="let item of SortList"
                      class="cursor-pointer rounded-md py-1 pl-1 pr-1 mr-1 hover:bg-gray-100 dark:hover:bg-hoverBackground"
                    >
                      <div class="flex">
                        <div class="grid grid-rows-1 ml-3">
                          <span
                            id="spnNetworkItem"
                            class="row-span-1 text-000 dark:text-fff"
                          >
                            {{ item }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              selectedTab === selectedOpportunityTab.AllOpportunities ||
              (selectedTab === selectedOpportunityTab.InvestedOpportunities &&
                !isWalletNotConnected)
            "
            class="pt-2 d-flex flex-wrap w-full"
          >
            <ng-container
              *ngFor="let item of activeOppTempList; index as index"
            >
              <ng-container [ngSwitch]="item.opportunityType">
                <ng-container *ngSwitchCase="OpportunityType.ETF">
                  <div class="col-lg-6 col-12">
                    <app-etf-item
                      (onInvestClicked)="invest($event)"
                      [opportunity]="item"
                      [selectedTab]="selectedTab"
                      [loading]="loading"
                      [itemsPage]="true"
                      [index]="
                        etfOpportunitiesInvested &&
                        etfOpportunitiesInvested.length > 0
                          ? item?.tokenId
                          : undefined
                      "
                      class="w-full d-block h-100"
                    >
                    </app-etf-item>
                  </div>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <div class="col-lg-6 col-12">
                    <app-opportunity-item
                      (onInvestClicked)="invest($event)"
                      [opportunity]="item"
                      [selectedTab]="selectedTab"
                      [currentTime]="currentTime"
                      [loading]="loading"
                      [itemsPage]="true"
                      class="w-full d-block h-100"
                    >
                    </app-opportunity-item>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>

          <div
            *ngIf="
              selectedTab === selectedOpportunityTab.InvestedOpportunities &&
              investOpportunityCount === 0 &&
              allOpportunityCount === 0 &&
              !isWalletNotConnected
            "
            class="flex flex-col pt-2"
          >
            <div class="flex justify-center">
              <img
                class="flex w-64 mx-2"
                src="assets/media/icons/empty-invest.png"
              />
            </div>

            <div class="font-bold flex justify-center py-2 dark:text-textWhite">
              <span>No investment found!</span>
            </div>
          </div>

          <div
            *ngIf="
              selectedTab === selectedOpportunityTab.InvestedOpportunities &&
              isWalletNotConnected
            "
            class="flex flex-col mt-3"
          >
            <div class="flex justify-center">
              <img
                class="flex w-52 mx-2"
                src="assets/media/icons/wallet-not-connect.png"
              />
            </div>
            <div
              class="font-bold flex py-3 justify-center cursor-pointer dark:text-textWhite"
            >
              <span>Wallet is not connected</span>
            </div>
          </div>

          <div class="w-full p-2">
            <button
              nz-button
              nzType="default"
              (click)="goToOpportunities(selectedTab)"
              *ngIf="isViewMore"
              class="button-d w-full border-2px border-solid border-00000010 dark:border-darkBorder border-radius-6px text-0066FF dark:text-3385FF font-14 font-openSans-bold"
            >
              <ng-container
                *ngIf="
                  selectedTab === selectedOpportunityTab.AllOpportunities &&
                  allOpportunityCount - countLimit > 0
                "
              >
                View more ({{ allOpportunityCount - countLimit }})
              </ng-container>

              <ng-container
                *ngIf="
                  !isWalletNotConnected &&
                  selectedTab ===
                    selectedOpportunityTab.InvestedOpportunities &&
                  investOpportunityCount - countLimit > 0
                "
              >
                View more ({{ investOpportunityCount - countLimit }})
              </ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showInvestment">
      <div
        class="investment-list bg-fff dark:bg-121212 dark:border-1E1E1E dark:border-2 dark:text-textWhite rounded-md py-3 md:p-3"
      >
        <div
          *ngIf="selectedOpportunity.opportunityType !== OpportunityType.ETF"
          [ngClass]="{
            'd-flex align-items-center justify-content-between flex-basis-100% w-full flex-grow-1':
              setting.isMobile
          }"
        >
          <div class="d-flex flex-column p-2" *ngIf="setting.isMobile">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center scale-60 mx--14">
                <img
                  class="z-1 rounded-full"
                  [ngClass]="{ 'mr-2': !selectedOpportunity.tokenB }"
                  [src]="
                    baseLogoIconsUrl +
                    selectedOpportunity.tokenA.symbol?.toLowerCase() +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                />
                <img
                  class="rounded-full ml--10"
                  *ngIf="selectedOpportunity.tokenB"
                  [src]="
                    baseLogoIconsUrl +
                    selectedOpportunity.tokenB.symbol?.toLowerCase() +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                />
              </div>

              <div class="d-flex flex-column ml-8px">
                <div class="d-flex align-items-center">
                  <div class="font-16 font-w-800">
                    {{ selectedOpportunity.displayName }}
                  </div>

                  <div
                    *ngIf="selectedOpportunity.type"
                    class="rounded-full font-openSans-semiBold font-w-500 px-2 py-1 font-11 ml-1"
                    [ngClass]="
                      selectedOpportunity.type === 'NEW'
                        ? 'text-000 bg-DEF7EC'
                        : 'text-fff bg-005374'
                    "
                  >
                    {{ selectedOpportunity.type }}
                  </div>
                </div>

                <div class="d-flex align-items-center py-1">
                  <img
                    width="16"
                    *ngIf="
                      !(
                        loading &&
                        selectedTab ===
                          selectedOpportunityTab.InvestedOpportunities
                      )
                    "
                    class="rounded-full"
                    [src]="
                      web3Service.networkSpec[selectedOpportunity.chainId]
                        ?.coinIcon
                    "
                  />

                  <span class="font-thin ml-1">
                    {{
                      web3Service.networkSpec[selectedOpportunity.chainId]
                        ?.title
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column align-items-end p-2 cursor-pointer">
            <svg
              *ngIf="!isDarkMode"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              (click)="close()"
            >
              <g clip-path="url(#clip0_2689_123992)">
                <rect
                  width="32"
                  height="32"
                  rx="4"
                  fill="black"
                  fill-opacity="0.05"
                />
                <path
                  d="M25.3327 8.54602L23.4527 6.66602L15.9993 14.1193L8.54602 6.66602L6.66602 8.54602L14.1193 15.9993L6.66602 23.4527L8.54602 25.3327L15.9993 17.8793L23.4527 25.3327L25.3327 23.4527L17.8793 15.9993L25.3327 8.54602Z"
                  fill="#323232"
                />
              </g>
              <defs>
                <clipPath id="clip0_2689_123992">
                  <rect width="32" height="32" rx="4" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              *ngIf="isDarkMode"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              (click)="close()"
            >
              <g clip-path="url(#clip0_2689_123992)">
                <rect
                  width="32"
                  height="32"
                  rx="4"
                  fill="black"
                  fill-opacity="0.05"
                />
                <path
                  d="M25.3327 8.54602L23.4527 6.66602L15.9993 14.1193L8.54602 6.66602L6.66602 8.54602L14.1193 15.9993L6.66602 23.4527L8.54602 25.3327L15.9993 17.8793L23.4527 25.3327L25.3327 23.4527L17.8793 15.9993L25.3327 8.54602Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2689_123992">
                  <rect width="32" height="32" rx="4" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <app-investment
          (updateDetails)="refreshInvestments(true)"
          [opportunity]="selectedOpportunity"
          [opportunities]="opportunities"
          [selectedOpportunityTab]="selectedTab"
          (close)="close()"
        >
        </app-investment>
      </div>
    </div>
  </div>
</div>
