import {
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { Constants } from '../../../../constants';
import { Web3Service } from '../../../../services/web3.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { FormsModule } from '@angular/forms';
import { CrowdToken, Networks, TokensHolder } from '@crowdswap/constant';
import { isAddress } from 'ethers/lib/utils';
import { ERC20Service } from '../../../../services/erc20.service';
import { Builder } from 'builder-pattern';
import { ModalService } from '../../modal.service';
import { ImportTokenDialogComponent } from '../import-token-dialog/import-token-dialog.component';
import { Subscription } from 'rxjs';
import { SharedModule } from '../../../../share/shared.module';

@Component({
  selector: 'select-option-dialog',
  templateUrl: './select-option-dialog.component.html',
  styleUrls: ['./select-option-dialog.component.scss']
})
export class SelectOptionDialogComponent
  extends DialogsComponent
  implements OnInit
{
  @Input() token: any;

  @Output()
  confirmed = new EventEmitter<object>();
  confirmedImportToken = new EventEmitter<object>();
  baseNetworkTokens;
  isNewToken;
  newToken;
  private _tokens: any[];
  public filterValue: string;
  public toggle: boolean;
  public tokensTemp: any;
  public allTokens: any;

  constructor(
    private $gtmService: GoogleTagManagerService,
    private web3Service: Web3Service,
    private importTokenDialogComponent: ModalService<ImportTokenDialogComponent>
  ) {
    super();
    this.toggle = false;
    this.filterValue = '';
    this._tokens = [];
    this.tokensTemp = [];
  }

  get tokens() {
    return this._tokens || [];
  }

  @Input()
  set tokens(value: any[]) {
    this._tokens = value;
    this.tokensTemp = value;
  }

  async ngOnInit() {
    this.allTokens = this.data?.allTokens;

    this.addBalanceToTokens(this.data?.filteredTokenList).then(
      (tokensWithBalance) => {
        this.tokens = tokensWithBalance;
      }
    );
    let tokenSymbol = this.data?.tokenSymbol;
    this.baseNetworkTokens = Constants.DEFAULT_TOKENS[
      this.data?.chainId
    ].filter((baseNetworkToken) => {
      if (
        !this.data?.filteredTokenList?.find(
          (filteredToken) =>
            filteredToken && filteredToken.symbol === baseNetworkToken
        )
      ) {
        return false;
      }
      if (tokenSymbol.startsWith('W')) {
        tokenSymbol = tokenSymbol.substring(1);
      }
      return (
        baseNetworkToken !== tokenSymbol &&
        baseNetworkToken !== 'W' + tokenSymbol
      );
    });

    this.toggle = this.data?.toggle || false;
  }

  /**
   * Select items and set to button title
   */
  public async selectedToken(item: any) {
    // await this.$gtmService.pushTag({
    //   event: 'select_token',
    //   category: this.data?.withBalance
    //     ? 'opportunity'
    //     : this.data?.isCrossChainSwap
    //     ? 'cross_chain'
    //     : 'classicSwap',
    //   tokenSymbol: item.symbol,
    //   isConnected: this.web3Service.isConnected(),
    // });

    this.toggle = !this.data?.toggle || false;
    this.token = item;
    this.confirmed.emit(item);
    this.tokensTemp = this.tokens;
  }

  private getTokenByAddress(address: any) {
    for (const token of this.allTokens) {
      if (token.address.toLowerCase() === address) {
        return token;
      }
    }
    return null;
  }

  private isTokenSelectedOtherSide(tokenAddress: any) {
    for (const token of this.tokens) {
      if (token.address.toLowerCase() === tokenAddress) {
        return false;
      }
    }
    return true;
  }

  public isIconExist(className: any) {
    return true;
  }
  /**
   * Filter data source value
   */
  public async filterDataSourceValue(filterValue) {
    filterValue = filterValue.toLowerCase();
    this.tokensTemp = [];
    this.newToken = null;
    this.isNewToken = false;
    if (isAddress(filterValue)) {
      const tokenByAddress = this.getTokenByAddress(filterValue);
      if (!tokenByAddress) {
        this.isNewToken = true;
        this.newToken = await this.fetchNewTokenDetailsByAddress(
          filterValue
        ).catch(async () => {
          await this.$gtmService.pushTag({
            event: 'token_not_found',
            category: 'tokenSelection',
            token: filterValue,
            isConnected: this.web3Service.isConnected()
          });

          return {};
        });
      } else if (!this.isTokenSelectedOtherSide(filterValue)) {
        this.tokensTemp = [tokenByAddress];
      }
    } else {
      this.tokensTemp = this.tokens.filter((item: any) => {
        return item.symbol.toLowerCase().indexOf(filterValue) >= 0;
      });
      if (this.tokensTemp.length === 0) {
        this.tokensTemp = undefined; //Show not found message
        await this.$gtmService.pushTag({
          event: 'token_not_found',
          category: 'tokenSelection',
          token: filterValue,
          isConnected: this.web3Service.isConnected()
        });
      }
    }
  }

  async fetchNewTokenDetailsByAddress(address: string) {
    const erc20 = new ERC20Service(
      this.web3Service.getNetworkProvider(this.data?.chainId)
    );
    return await erc20.getToken(address);
  }

  importNewToken(token: any) {
    const chainId = token.chainId;
    const address = token.address.toLowerCase();
    const tokenObj = localStorage.getItem('tokens')
      ? JSON.parse(localStorage.getItem('tokens')!)
      : {};
    tokenObj[chainId] = tokenObj[chainId] || { [address]: token };
    tokenObj[chainId][address] = tokenObj[chainId][address] || token;
    localStorage.setItem('tokens', JSON.stringify(tokenObj));
    this.selectedToken(token);
    const newToken = Builder<CrowdToken>()
      .chainId(token.chainId)
      .address(token.address)
      .decimals(token.decimals)
      .symbol(token.symbol)
      .name(token.name + ' • Added by user')
      .build();

    this.confirmedImportToken.emit(newToken);
    this.toggle = false;
  }

  /**
   * Close Filter box and reset value
   */
  public closeFilter() {
    this.toggle = false;
    this.filterValue = '';
    this.tokensTemp = this.tokens;
  }

  onEnter() {
    if (!this.tokensTemp || !this.tokensTemp[0]) {
      return;
    }
    this.selectedToken(this.tokensTemp[0]);
  }

  toggleDropdown() {
    this.confirmed.emit(undefined);
    this.toggle = !this.toggle;
  }

  selectedFromBaseCoins(item: any) {
    let coin =
      TokensHolder.ObservableTokenListBySymbol[Networks[this.data?.chainId]][
        item
      ];
    this.selectedToken(coin);
  }

  async showImportTokenConfirmDialog(newToken: CrowdToken) {
    const data = {
      allTokens: [newToken],
      isDarkMode: this.data?.isDarkMode
    };
    let importTokenDialogRef = await this.importTokenDialogComponent.open(
      ImportTokenDialogComponent,
      data
    );
    const sub: Subscription = (<ImportTokenDialogComponent>(
      (<any>importTokenDialogRef!.instance)
    )).confirmed.subscribe(async (isImportConfirmed: boolean) => {
      if (isImportConfirmed) {
        this.importNewToken(newToken);
      } else if (!isImportConfirmed) {
        await this.importTokenDialogComponent.close();
      }
    });
    importTokenDialogRef!.onDestroy(() => {
      sub.unsubscribe();
    });
  }

  async addBalanceToTokens(tokens: any) {
    let result: CrowdToken[] = await this.web3Service.addBalanceToTokens(
      tokens
    );
    if (this.data?.withBalance) {
      result = result.filter((token: CrowdToken) => {
        return token.balance && token.balance != '' && +token.balance > 0;
      });
      this.baseNetworkTokens = this.baseNetworkTokens.filter((token) =>
        result.some(({ symbol }) => token === symbol)
      );
    }
    return result;
  }

  toFixed(value: string, numberOfDecimals: number): string {
    const valueInFloat: number = parseFloat(value);
    return isNaN(valueInFloat) ? '' : valueInFloat.toFixed(numberOfDecimals);
  }

  public async clearSearch() {
    this.filterValue = '';
    await this.filterDataSourceValue('');
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule, SharedModule.forRoot()],
  declarations: [SelectOptionDialogComponent],
  exports: [SelectOptionDialogComponent]
})
export class SelectOptionDialogComponentModule {}
