<div
  class="header-banner position-relative"
  *ngIf="startTime < currentTime && finishTime > currentTime && !isClosed"
>
  <swiper class="mySwiper" [config]="bannerConfig">
    <ng-template
      swiperSlide
      *ngFor="let item of defaultOptions.banner[defaultOptions.device]"
    >
      <a
        #headerBanner
        *ngIf="startTime < currentTime && finishTime > currentTime"
        class="w-full d-flex justify-content-center"
        [href]="item.link"
        target="_blank"
      >
        <img [src]="item.path" />
      </a>
    </ng-template>
  </swiper>

  <div class="position-fixed z-20 right-1 top-1 mr-1 dark:text-textWhite">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-5 flex items-center text-fff cursor-pointer"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      (click)="close()"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  </div>
</div>
