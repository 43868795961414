import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  commonConfig,
  Conversion,
  TokenDescription
} from '@crowdswap/constant';
import { filter, map, shareReplay } from 'rxjs/operators';
import { CurrentNetwork } from '../../../../model/current-network';
import { Subscription, Observable, interval } from 'rxjs';
import { BaseComponent } from '../../base.component';
import {
  DeviceType,
  NavigatorService,
  NDDClientInfoServiceImpl,
  StakeService,
  TagManagerService,
  ThemeService,
  UtilsService,
  Web3Service
} from '../../../../services';
import {
  OpportunityType,
  SelectedInvestmentTab,
  SelectedOpportunityTab
} from '../model/opportunity-state.enum';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import { ModalService } from '../../../modal/modal.service';
import { WithdrawExtendDialogComponent } from '../../../modal/dialogs/withdraw-extend-dialog/withdraw-extend-dialog.component';
import { WaitingDialogComponent } from '../../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-opportunity-item',
  templateUrl: './opportunity-item.component.html',
  styleUrls: ['./opportunity-item.component.scss']
})
export class OpportunityItemComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public unstakeFee!: string;
  public static LIMITED_INTERVAL_TIME =
    commonConfig.STAKE_UNSTAKE.LIMITED_INTERVAL_TIME;

  protected subscriptionList: Subscription[] = [];
  canLoadStake = false;
  stakedBalance: any;
  public activePlans;
  public tableScrollY = '220px';
  public nextReward = 0;
  public stakeTime = 0;
  public timeLeft$:
    | Observable<{
        minutesToNextReward: number;
        secondsToNextReward: number;
      }>
    | undefined = undefined;

  @Input()
  public currentTime;
  @Input()
  public isFundingAllowed: boolean = true;
  @Input()
  public opportunity;
  @Input()
  public plan;
  @Input()
  loading;
  @Input()
  itemsPage;
  @Input()
  selectedTab;
  @Input() selectedActionTab!: SelectedInvestmentTab | undefined;

  @Output()
  onInvestClicked = new EventEmitter();
  @Output()
  onDetailClicked = new EventEmitter();
  @Output()
  updateDetails = new EventEmitter();

  public isLoading: boolean = false;
  public detailView: boolean = false;
  public tooltip: boolean = false;
  public selectedOpportunityTab = SelectedOpportunityTab;
  public tokenDescription = TokenDescription;
  public opportunityType = OpportunityType;
  public rightNowDate: number = Math.trunc(Date.now() / 1000);

  constructor(
    protected themeService: ThemeService,
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    public stakeService: StakeService,
    public navigatorService: NavigatorService,
    protected tagManagerService: TagManagerService,
    private toastr: ToastrService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private withdrawExtendDialogService: ModalService<WithdrawExtendDialogComponent>
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit() {
    super.ngOnInit();

    if (!this.selectedTab) {
      this.selectedTab = this.selectedOpportunityTab.AllOpportunities;
    }
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe(
        async (connection) => {
          if (connection) {
            this.stakedBalance = await this.getStakedTokenBalance();
            this.ref.detectChanges();
          }
        }
      )
    );

    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          })
          // throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          this.ref.detectChanges();
        })
    );

    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        async (isWrongNetwork: boolean) => {
          if (!isWrongNetwork) {
            this.ref.detectChanges();
          }
        }
      )
    );

    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        this.ref.detectChanges();
      })
    );

    this.setting.isMobile =
      [DeviceType.MOBILE, DeviceType.TABLET].indexOf(
        this.clientInfoServiceImpl.getDeviceType()
      ) > -1;

    if (this.opportunity.lockedStaking) {
      this.activePlans = this.opportunity.plans.filter((item) => item.active);
      this.selectPlan(-1);
    }
    this.timeLeft$ = interval(1000).pipe(
      map((x) => {
        const { secondsToNextReward, minutesToNextReward } =
          UtilsService.nextRewardTime(this.opportunity.stakeTime);
        if (secondsToNextReward === 0 && minutesToNextReward === 0) {
          this.ref.detectChanges();
        }

        return { secondsToNextReward, minutesToNextReward };
      }),
      shareReplay(1)
    );
  }

  setZero() {
    this.stakeTime = 0;
    this.nextReward = 0;
  }

  public async getStakeTime() {
    try {
      let stakeTime = await this.stakeService.getStakeTime(
        this.opportunity,
        this.web3Service.getWalletAddress()
      );
      this.stakeTime = +stakeTime.toString();
      return this.stakeTime;
    } catch (e) {
      console.log(e);
    }
  }

  async invest(item: any) {
    item.opportunityType === OpportunityType.TypeStaking
      ? await this.tagManagerService.sendStakingTags(
          'staking',
          item,
          '',
          this.selectedTab === this.selectedOpportunityTab.InvestedOpportunities
            ? 'invested'
            : 'new'
        )
      : await this.tagManagerService.sendOpportunitiesTags(
          item,
          this.selectedTab === this.selectedOpportunityTab.InvestedOpportunities
            ? 'invested'
            : 'new',
          'select_opportunity_farm'
        );
    this.onInvestClicked.emit(item);
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  selectPlan(planId: any) {
    if (this.opportunity.plans && this.opportunity.plans.length > 0) {
      if (planId === -1) {
        this.activePlans.sort((item1, item2) =>
          item1.apy > item2.apy ? 1 : -1
        );
        planId = this.activePlans[this.activePlans.length - 1].id;
      }
      this.plan = this.opportunity.plans.find((item) => item.id === planId);
      this.opportunity.plans.map((item) => {
        if (item.id === this.plan.id) {
          item.enable = true;
        } else {
          item.enable = false;
        }
      });
      if (this.plan) {
        this.opportunity.apy = this.plan.apy;
        this.opportunity.displayApy = formatNumber(
          !this.plan.apy ? 0 : this.plan.apy,
          NumberType.TokenNonTx
        );
      }
    }
  }

  public async extendStaking(item: any) {
    try {
      let data;
      if (
        this.opportunity.opportunityType === OpportunityType.TypeStaking &&
        this.opportunity.lockedStaking
      ) {
        this.tagManager('extend_start', item);
        const endDate = new Date(
          Date.now() + parseFloat(item.duration) * 24 * 3600 * 1000
        );
        const displayEndDate = endDate.toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        });
        data = {
          amountIn: item.amount,
          releasableCrowdBalance:
            parseFloat(item.amount) + parseFloat(item.rewards),
          displayTvl: this.opportunity.displayTvl,
          displayApy: this.opportunity.displayApy,
          nextReward: item.rewards,
          duration: item.duration,
          endDate: displayEndDate,
          tokenSymbol: this.opportunity.tokenA?.symbol,
          isDarkMode: this.isDarkMode,
          isWithdrawExtend: false,
          activeOpportunity: this.opportunity
        };
        const withdrawExtendDialogRef =
          await this.withdrawExtendDialogService.open(
            WithdrawExtendDialogComponent,
            data
          );
        this.tagManager('extend_view', item);
        const sub: Subscription = (<WithdrawExtendDialogComponent>(
          (<any>withdrawExtendDialogRef!.instance)
        )).confirmed.subscribe(async (event) => {
          await this.withdrawExtendDialogService.close();
          if (event) {
            await this.confirmExtend(item);
          } else if (!event) {
            this.ref.detectChanges();
          }
        });
        withdrawExtendDialogRef!.onDestroy(() => {
          sub.unsubscribe();
        });
      }
    } catch (e) {
      console.error(
        e +
          `LockedStakeComponent-withdraw: withdraw unsuccessfully.now reward is = ${item.rewards} and stakedBalance is  = ${item.stakedBalance}`
      );
      console.log(e);
      await this.withdrawExtendDialogService.close();
    }
  }

  public async withdrawStaking(item: any) {
    try {
      let data;
      if (
        this.opportunity.opportunityType === OpportunityType.TypeStaking &&
        this.opportunity.lockedStaking
      ) {
        this.tagManager('withdraw_start', item);
        item.totalBalance;
        data = {
          releasableCrowdBalance:
            parseFloat(item.amount) + parseFloat(item.rewards),
          nextReward: item.rewards,
          tokenSymbol: this.opportunity.tokenA?.symbol,
          isDarkMode: this.isDarkMode,
          isWithdrawExtend: true,
          activeOpportunity: this.opportunity
        };
        const withdrawExtendDialogRef =
          await this.withdrawExtendDialogService.open(
            WithdrawExtendDialogComponent,
            data
          );
        this.tagManager('withdraw_view', item);
        const sub: Subscription = (<WithdrawExtendDialogComponent>(
          (<any>withdrawExtendDialogRef!.instance)
        )).confirmed.subscribe(async (event) => {
          await this.withdrawExtendDialogService.close();
          if (event) {
            await this.confirmWithdraw(item);
          } else if (!event) {
            this.ref.detectChanges();
          }
        });
        withdrawExtendDialogRef!.onDestroy(() => {
          sub.unsubscribe();
        });
      }
    } catch (e) {
      console.error(
        e +
          `LockedStakeComponent-withdraw: withdraw unsuccessfully.now reward is = ${item.rewards} and stakedBalance is  = ${item.stakedBalance}`
      );
      console.log(e);
      await this.withdrawExtendDialogService.close();
    }
  }

  private async confirmExtend(item: any): Promise<any> {
    try {
      if (this.web3Service.getCurrentChainId() != this.opportunity.chainId) {
        await this.web3Service.changeNetwork(this.opportunity.chainId);
      }

      this.tagManager('extend_confirmation', item);
      await this.showWaitingDialog('Extend');

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Extend tx
        let amount = Conversion.convertStringToDecimal(
          (parseFloat(item.amount) + parseFloat(item.rewards)).toString(),
          18
        ).toString();
        const extendTx =
          await this.stakeService.getExtendLockedStakingTransaction(
            this.opportunity,
            item.stakeId
          );

        const gasPrice = await this.web3Service.getGasPrice();
        const estimatedGasLimit =
          await this.stakeService.getLockedStakingEstimateGas(
            this.opportunity,
            amount,
            userAddress,
            'extend',
            item.stakeId
          );

        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction({
            ...extendTx,
            ...(estimatedGasLimit
              ? {
                  gas: estimatedGasLimit.toHexString(),
                  gasLimit: estimatedGasLimit.toHexString()
                }
              : {}),
            gasPrice: gasPrice.toHexString()
          })
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(
              e +
                `StakeComponent-confirmExtend-first: extend unsuccessfully and now tokenBalance is = ${this.opportunity.tokenA.balance} and stakedBalance is  = ${item.amount}`
            );
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                this.limitedInterval(
                  currentTransaction,
                  'extend',
                  `StakeComponent-extend: before extend successfully with value = ${amount}  and stakedBalance is  = ${item.amount}`
                );
              } else if (data?.status === 0) {
                await this.waitingDialogService.close();
                this.tagManager('extend_error', 'status is zero');
                this.showErrorToaster('Error', 'Extending rejected!');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          this.tagManager('extend_error', 'Extending rejected!');
          this.showErrorToaster('Error', 'Extending rejected!');
          console.log('Extending rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      this.tagManager('extend_error', 'global cache error');
      console.error(
        e +
          `StakeComponent-confirmExtend-second: extend unsuccessfully.now tokenBalance is = ${this.opportunity.tokenA.balance} and stakedBalance is  = ${item.amount}`
      );
      await this.waitingDialogService.close();
    }
  }

  private async confirmWithdraw(item: any): Promise<any> {
    try {
      if (this.web3Service.getCurrentChainId() != this.opportunity.chainId) {
        await this.web3Service.changeNetwork(this.opportunity.chainId);
      }

      this.tagManager('withdraw_confirmation', item);
      await this.showWaitingDialog('Withdraw'); //Unstak is sending to dialog to show correct message

      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        // Get Withdraw tx
        let amount = Conversion.convertStringToDecimal(
          (parseFloat(item.amount) + parseFloat(item.rewards)).toString(),
          18
        ).toString();
        const withdrawTx =
          await this.stakeService.getWithdrawLockedStakingTransaction(
            this.opportunity,
            item.stakeId,
            amount
          );

        const gasPrice = await this.web3Service.getGasPrice();
        const estimatedGasLimit =
          await this.stakeService.getLockedStakingEstimateGas(
            this.opportunity,
            amount,
            userAddress,
            'withdraw',
            item.stakeId
          );

        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction({
            ...withdrawTx,
            ...(estimatedGasLimit
              ? {
                  gas: estimatedGasLimit.toHexString(),
                  gasLimit: estimatedGasLimit.toHexString()
                }
              : {}),
            gasPrice: gasPrice.toHexString()
          })
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.error(
              e +
                `StakeComponent-confirmWithdraw-first: withdraw unsuccessfully and now tokenBalance is = ${this.opportunity.tokenA.balance} and stakedBalance is  = ${item.amount}`
            );
            console.log(e);
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              if (data?.status === 1) {
                this.limitedInterval(
                  currentTransaction,
                  'withdraw',
                  `StakeComponent-withdraw: before Withdraw successfully with value = ${amount} and stakedBalance is  = ${item.amount}`
                );
              } else if (data?.status === 0) {
                await this.waitingDialogService.close();
                this.tagManager('Withdraw_error', 'status is zero');
                this.showErrorToaster('Error', 'Withdrawing rejected!');
                return;
              }
            });
        } else {
          await this.waitingDialogService.close();
          this.tagManager('withdraw_error', 'Withdrawing rejected!');
          this.showErrorToaster('Error', 'Withdrawing rejected!');
          console.log('Withdrawing rejected!');
          return;
        }
      }
    } catch (e) {
      console.log(e);
      this.tagManager('Withdraw_error', 'global cache error');
      console.error(
        e +
          `StakeComponent-confirmWithdraw-second: Withdraw unsuccessfully.now tokenBalance is = ${this.opportunity.tokenA.balance} and stakedBalance is  = ${item.amount}`
      );
      await this.waitingDialogService.close();
    }
  }

  private async tagManager(event: any, item) {
    await this.tagManagerService.sendWithdrawTags(event, this.opportunity);
  }

  private async showWaitingDialog(dialogType) {
    const data = {
      stakeState: dialogType,
      isDarkMode: this.isDarkMode,
      tokenSymbol: this.opportunity.tokenA.symbol
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  private showSuccessToaster(message, title) {
    this.toastr.success(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      enableHtml: true,
      timeOut: 10000,
      messageClass: 'successClass'
    });
  }

  private async getStakedTokenBalance() {
    try {
      let stakedBalance = await this.stakeService.getLockedStakingBalance(
        this.opportunity,
        this.web3Service.getWalletAddress(),
        this.web3Service.getNetworkProvider(this.opportunity.chainId)
      );
      if (stakedBalance) {
        return +Conversion.convertStringFromDecimal(
          stakedBalance.toString(),
          18
        );
      }
    } catch (e) {
      console.error(
        e +
          `StakeComponent-getStakedTokenBalance: stakedBalance = ${this.stakedBalance} and stakedBalance is  = ${this.stakedBalance}`
      );
      console.log(e);
    }
    return 0;
  }

  private limitedInterval(hash: string, event: string, msg: string = '') {
    let timesRun = 0;
    const scanTransactionUrl = this.web3Service.getScanTransactionUrl(
      hash,
      this.opportunity.chainId
    );
    let limitedInterval = setInterval(async () => {
      timesRun += 1;
      if (timesRun <= OpportunityItemComponent.LIMITED_INTERVAL_TIME) {
        const newStakedBalance = await this.getStakedTokenBalance();
        if (this.stakedBalance !== newStakedBalance) {
          clearInterval(limitedInterval);
          this.updateOppDetails();
          await this.tagManager(event + '_done', 'successful');
          this.showSuccessToaster(
            `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
            event + ' successfully!'
          );
          await this.waitingDialogService.close();
          this.web3Service.assetChangeSubject.next(true);
          console.info(msg);
          return;
        }
      } else {
        clearInterval(limitedInterval);
        this.updateOppDetails();
        await this.waitingDialogService.close();
        this.showErrorToaster(
          'Error',
          'Please follow the result at https://polygonscan.com '
        );
      }
    }, 1000);
  }

  updateOppDetails() {
    this.updateDetails.emit(false);
  }
}
