<div class="justify-center text-center">
  <div class="row">
    <div>
      <svg
        class="circular_progressbar"
        [attr.width]="2 * (radius + thick)"
        [attr.height]="radius"
      >
        <circle
          [attr.cx]="cx"
          [attr.cy]="cy"
          [attr.r]="radius"
          [attr.stroke-width]="thick"
          [attr.stroke]="backgroundColor"
          fill="transparent"
        ></circle>
        <circle
          id="_bar"
          [attr.cx]="cx"
          [attr.cy]="cy"
          [attr.r]="radius"
          [attr.stroke-width]="thick"
          [attr.stroke]="foregroundColor"
          fill="transparent"
          [style.stroke-dasharray]="perimeter"
          [style.stroke-dashoffset]="offset"
        ></circle>
        <text
          [attr.x]="radius + thick / 2"
          [attr.y]="radius"
          [attr.fill]="isDarkMode ? 'white' : 'black'"
          class="text"
          text-anchor="middle"
          [attr.visibility]="isLoading ? 'hidden' : 'visible'"
        >
          {{ percentage + "%" }}
        </text>
        <rect
          [attr.x]="(radius + thick) / 2"
          [attr.y]="(2 * radius) / 3"
          [attr.width]="radius"
          [attr.height]="radius / 3"
          style="fill: rgb(209, 213, 219)"
          rx="2"
          class="animate-pulse"
          [attr.visibility]="isLoading ? 'visible' : 'hidden'"
        />
      </svg>
    </div>
  </div>
  <div class="mt-3">
    <span
      [ngStyle]="{ 'background-color': foregroundColor }"
      class="w-3 h-3"
    ></span>
    <a class="mx-2 dark:text-white">{{ title }}!</a>
  </div>
</div>
