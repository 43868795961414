<app-dialog [className]="data?.className">
  <div class="flex justify-start">
    <label class="title dark:text-textWhite">
      Confirmation (Your investment consist of
      {{ data?.sourcePrice }} {{ data?.activeOpportunity.tokenB.symbol }},
      {{ data?.destinationPrice }}
      {{ data?.activeOpportunity.tokenA.symbol }})
    </label>
    <div class="absolute right-5 mr-1 dark:text-textWhite">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-5 h-5 cursor-pointer"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        (click)="close()"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </div>
  </div>
  <div>
    <div>
      <div class="flex flex-col items-center mt-2">
        <div class="bg-gray-50 dark:bg-darkBlack rounded p-1 w-full">
          <div class="row mb-1 text-gray-400 text-xs-2">
            <div
              class="flex mb-1 d-md-inline md:text-center items-center col-12 col-md-3 col-xl-3 col-xxl-3"
            >
              <div class="col-6 col-md-12">
                <label> Name </label>
              </div>
              <div class="col-6 col-md-12 mt-md-3">
                <label class="dark:text-textWhite text-sm">
                  {{ data?.activeOpportunity.displayName }}
                </label>
              </div>
            </div>
            <div
              class="flex mb-1 d-md-inline md:text-center items-center col-12 col-md-2 col-xl-2 col-xxl-2"
            >
              <div class="col-6 col-md-12">
                <label> Asset </label>
              </div>
              <div class="col-6 col-md-12 mt-md-3">
                <label class="dark:text-textWhite text-sm">
                  {{ data?.activeOpportunity.asset }}
                </label>
              </div>
            </div>
            <div
              class="flex mb-1 d-md-inline md:text-center items-center col-12 col-md-2 col-xl-2 col-xxl-2"
            >
              <div class="col-6 col-md-12">
                <label> APY </label>
              </div>
              <div class="col-6 col-md-12 mt-md-3">
                <label class="dark:text-textWhite text-sm">
                  {{
                    !data?.activeOpportunity.displayApy
                      ? "-"
                      : data?.activeOpportunity.displayApy + "%"
                  }}
                </label>
              </div>
            </div>
            <div
              class="flex mb-1 d-md-inline md:text-center items-center col-12 col-md-2 col-xl-2 col-xxl-2"
            >
              <div class="col-6 col-md-12">
                <label> Daily Interest </label>
              </div>
              <div class="col-6 col-md-12 mt-md-3">
                <label class="dark:text-textWhite text-sm">
                  {{
                    !data?.activeOpportunity.displayDailyInterest
                      ? "-"
                      : data?.activeOpportunity.displayDailyInterest + "%"
                  }}
                </label>
              </div>
            </div>
            <div
              class="flex mb-1 d-md-inline md:text-center items-center col-12 col-md-1 col-xl-1 col-xxl-1"
            >
              <div class="col-6 col-md-12">
                <label> TVL </label>
              </div>
              <div class="col-6 col-md-12 mt-md-3">
                <label class="dark:text-textWhite text-sm">
                  {{
                    !data?.activeOpportunity.displayTvl
                      ? "-"
                      : "$" + data?.activeOpportunity.displayTvl
                  }}
                </label>
              </div>
            </div>
            <div
              class="flex mb-1 d-md-inline md:text-center items-center col-12 col-md-2 col-xl-2 col-xxl-2"
            >
              <div class="col-6 col-md-12">
                <label> Network </label>
              </div>
              <div class="col-6 col-md-12 mt-md-3">
                <label class="dark:text-textWhite text-sm">
                  {{ data?.activeOpportunity.Network }}
                </label>
              </div>
            </div>
          </div>
          <div class="flex items-center w-full">
            <div
              class="mt-1 d-none d-md-block text-sm pr-2 dark:text-darkGrayText"
            >
              <span>Investment</span>
            </div>
            <div
              class="flex items-center w-full mt-3 mb-2 border-2 border-gray-300 rounded text-base px-2 h-8 hover:border-gray-300 dark:border-darkBorder"
            >
              <div class="row">
                <!--                <div class="dark:text-darkGrayText text-xs col-10">-->
                <!--                  <span>USDT:  {{data?.sourcePrice}}  < >  CROWD: {{data?.destinationPrice}}</span>-->
                <!--                  <span>&nbsp;(LP Amount:  {{data?.amountOut}})</span>-->
                <!--                </div>-->
                <!--                <div class="dark:text-darkGrayText text-xs col-4">-->
                <!--                  <span>LP Amount:  {{+data?.amountOut! > 0 ? data?.amountOut : '< 1e-6'}}</span>-->
                <!--                </div>-->
                <div class="dark:text-darkGrayText text-xs">
                  <span
                    >{{ data?.activeOpportunity.tokenB.symbol }}:
                    {{ data?.sourcePrice }} < >
                    {{ data?.activeOpportunity.tokenA.symbol }}:
                    {{ data?.destinationPrice }}</span
                  >
                </div>
              </div>
              <!--              <div class="text-right text-xs opacity-50 dark:text-darkGrayText dark:opacity-100 w-1/3">-->
              <!--                ~${{data?.stakeAmount}}-->
              <!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="data?.unusualMode"
      class="flex flex-col font-openSans-semiBold mt-3 mb-3"
    >
      <div class="block">
        <div class="mt-1 d-none">
          <input
            type="checkbox"
            id="isUnusual"
            name="isUnusual"
            [(ngModel)]="isUnusualConfirmed"
          />
          <label>
            <span class="text-xs-2 ml-2 font-openSans-regular text-red-500">
              I understand the risk, but would like to complete the invest at
              the displayed rate.
            </span>
          </label>
        </div>
        <label>
          <span class="text-xs-2 font-openSans-regular text-red-500">
            This investment could result in significant losses. Please consider
            using another token or swapping to your desired pair.
          </span>
        </label>
      </div>
    </div>
    {{ data?.differenceInPercent }}
    <div>
      <div class="flex flex-col mt-3 mb-3">
        <button
          (click)="confirm()"
          [disabled]="data?.unusualMode && !isUnusualConfirmed"
          class="confirm-button blue-button py-2 px-4 rounded h-10 flex justify-center text-white text-sm items-center text-center hover:bg-blue-700"
          [ngClass]="
            data?.unusualMode && !isUnusualConfirmed
              ? 'disable'
              : 'cursor-pointer'
          "
        >
          <span>Confirm</span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
