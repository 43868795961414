<app-dialog
  [className]="data?.className"
  [closable]="false"
  class="internal-wallet-browser-dialog position-relative"
>
  <div
    class="w-full d-flex flex-column internal-wallet-browser-dialog-section position-relative"
  >
    <div class="w-100 d-flex align-items-start">
      <span class="d-flex flex-shrink-0">
        <img src="../../../../../assets/media/icons/info-circle.svg" alt="" />
      </span>

      <span class="w-100 font-14 text-000 ms-2">
        For having a better experience please use internal wallet browser.
      </span>
    </div>

    <div
      class="w-full d-flex align-items-center justify-content-end px-20px pt-3 bg-fff"
    >
      <button
        nz-button
        nzType="default"
        class="button-0066FF border-radius-4px text-white font-14 font-openSans-bold text-center hover:bg-blue-700 px-3 py-2 d-flex align-items-center justify-content-center me-3"
        (click)="toggleDropdown()"
      >
        OK
      </button>

      <button
        nz-button
        nzType="default"
        class="ant-btn button-d-0066FF border-0 text-0066FF dark:text-3385FF font-14 font-openSans-bold px-3 py-2 d-flex align-items-center justify-content-center shadow-none"
        (click)="toggleDropdown(true)"
      >
        Help
      </button>
    </div>
  </div>
</app-dialog>
