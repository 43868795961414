import { Component, EventEmitter, NgModule, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { FormsModule } from '@angular/forms';
import { DialogsComponent } from '../dialogs.component';

@Component({
  selector: 'app-confirm-opportunity-dialog',
  templateUrl: './confirm-opportunity-dialog.component.html',
  styleUrls: ['./confirm-opportunity-dialog.component.scss']
})
export class ConfirmOpportunityDialogComponent extends DialogsComponent {
  @Output()
  confirmed = new EventEmitter<boolean>();

  public isUnusualConfirmed: boolean = false;

  constructor() {
    super();
  }

  confirm() {
    this.confirmed.emit(true);
  }

  close() {
    this.confirmed.emit(false);
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule],
  declarations: [ConfirmOpportunityDialogComponent],
  exports: [ConfirmOpportunityDialogComponent]
})
export class ConfirmOpportunityDialogComponentModule {}
