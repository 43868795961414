import { Contract } from 'ethers';
import QuoterABI from '@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json';
import { Injectable } from '@angular/core';
import { Web3Service } from './web3.service';
import { encodeRouteToPath } from '@uniswap/v3-sdk';

@Injectable()
export class UniswapV3FactoryService {
  QUOTER_ADDRESS = '0xb27308f9f90d607463bb33ea1bebb41c27ce5ab6';

  constructor(private provider: Web3Service) {}

  public async getQuotesResultsByCallContract(amountIn, route): Promise<any> {
    const contract = new Contract(
      this.QUOTER_ADDRESS,
      QuoterABI.abi,
      this.provider.web3Provider
    );

    return await contract.callStatic.quoteExactInput(
      encodeRouteToPath(route, false),
      amountIn ? `${amountIn.toHexString()}` : undefined
    );
  }
}
