<div
  class="w-full etf_items h-100"
  [ngClass]="{ 'p-2': selectedActionTab === undefined }"
>
  <!-- *ngIf="!detailView" -->
  <div
    class="w-full d-flex flex-column justify-content-between border-radius-6px h-100"
    [ngClass]="{
      'bg-1E1E1E border-2D2D2D': isDarkMode && selectedActionTab === undefined,
      'border-1E1E1E': isDarkMode && selectedActionTab !== undefined,
      'border-00000010': !isDarkMode,
      'py-25px px-sm-25px px-0 border-md-2px': selectedActionTab !== undefined,
      'p-2 border-2px': selectedActionTab === undefined
    }"
  >
    <div class="w-full d-flex flex-column">
      <div class="w-full d-flex align-items-center justify-content-center">
        <div
          class="title-section w-full d-flex align-items-center font-12 px-2 w-full"
          *ngIf="
            selectedActionTab === undefined ||
            (!setting.isMobile && selectedActionTab !== undefined)
          "
        >
          <img
            class="z-1 rounded-full mr-2"
            [src]="
              opportunity.etfName
                | getETFImage : opportunity.planName : isDarkMode
            "
            (error)="updateUrl($event)"
          />

          <div class="d-flex flex-column w-full">
            <div
              class="w-full d-flex align-items-center justify-content-between"
            >
              <div class="d-flex align-items-center">
                <div class="font-16 font-openSans-bold">
                  {{ opportunity.planName }}

                  <span
                    class="ms-1"
                    *ngIf="
                      index &&
                      selectedTab ===
                        SelectedOpportunityTab.InvestedOpportunities
                    "
                  >
                    #{{ index }}
                  </span>
                </div>

                <div
                  *ngIf="opportunity.type"
                  class="rounded-full font-openSans-semiBold px-2 py-1 font-11 ml-1"
                  [ngClass]="
                    opportunity.type === 'NEW'
                      ? 'text-000 bg-fef5e6'
                      : 'text-fff bg-005374'
                  "
                >
                  {{ opportunity.type }}
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <img
                class="rounded-full"
                [src]="web3Service.networkSpec[opportunity.chainId].coinIcon"
                width="16"
              />

              <span class="font-12 text-000 dark:text-fff ml-1">
                {{ web3Service.networkSpec[opportunity.chainId].title }}
              </span>
            </div>
          </div>
        </div>

        <span
          class="font-openSans-bold font-16 text-000 dark:text-fff"
          *ngIf="
            selectedTab === SelectedOpportunityTab.InvestedOpportunities &&
            selectedActionTab === SelectedInvestmentTab.Withdraw
          "
        >
          {{ opportunity.createdDateToDisplay }}
        </span>
      </div>

      <div
        *ngIf="
          (selectedActionTab === SelectedInvestmentTab.Invest ||
            selectedTab === SelectedOpportunityTab.AllOpportunities) &&
          false
        "
        class="w-full d-flex justify-content-center align-items-center border-1px border-00000010 dark:border-00000012 border-radius-4px text-fff mt-3 font-12 bg-F7F7F7 dark:bg-121212 position-relative"
      >
        <img
          src="assets/media/zero-fee/zero-fee-etf.png"
          class="w-full border-radius-4px"
        />

        <span
          class="font-14 font-openSans-bold mx-12px absolute-center w-full text-center"
        >
          Zero Investment fee
        </span>
      </div>

      <div
        class="w-full d-flex border-1px border-00000010 dark:border-00000012 border-radius-4px text-000 dark:text-fff mt-3 font-12 p-2"
        [ngClass]="{
          'flex-column': setting.isMobile,
          'justify-content-between': !setting.isMobile,
          'bg-F7F7F7 dark:bg-121212': selectedActionTab === undefined
        }"
      >
        <div class="d-flex align-items-center flex-wrap w-full">
          <ng-container
            *ngIf="
              selectedTab === SelectedOpportunityTab.InvestedOpportunities ||
                selectedActionTab !== undefined;
              else InvestTemplate
            "
          >
            <div
              class="d-flex align-items-center px-2"
              *ngFor="let item of opportunity.tokenShares"
            >
              <div class="d-flex align-items-center scale-60 mx--14">
                <img
                  class="z-1 rounded-full mr-2 p-2px bg-fff"
                  [src]="
                    baseLogoIconsUrl +
                    item?.token?.symbol?.toLowerCase() +
                    '.png'
                  "
                  (error)="updateUrl($event)"
                />
              </div>

              <span class="font-12 text-000 dark:text-fff mx-1">
                {{ normalizeSymbol(item?.token?.symbol) }}
              </span>

              <span
                class="font-12 font-openSans-bold text-000 dark:text-fff"
                *ngIf="
                  selectedTab === SelectedOpportunityTab.AllOpportunities ||
                    (selectedTab ===
                      SelectedOpportunityTab.InvestedOpportunities &&
                      selectedActionTab !== undefined);
                  else Amount
                "
              >
                {{ item.share.toString() | mask : "percent.2" }}%
              </span>

              <ng-template #Amount>
                <span class="font-12 font-openSans-bold text-000 dark:text-fff">
                  {{ item.amountToDisplay }}
                </span>
              </ng-template>
            </div>
          </ng-container>

          <ng-template #InvestTemplate>
            <div
              class="font-14 font-openSans-regular text-000 dark:text-fff line-height-24px text-center"
            >
              <nz-space nzWrap="true" [nzSplit]="spaceSplit">
                <ng-template #spaceSplit> , </ng-template>

                <span class="me-1">
                  Diversify with Leading Cryptos Including
                </span>

                <ng-container
                  *ngFor="
                    let item of opportunity.tokenShares | limitTo : 6;
                    index as i
                  "
                >
                  <span *nzSpaceItem>
                    {{ item.token?.symbol | renameSymbol }}

                    <span *ngIf="i === 5 && opportunity.tokenShares.length > 6">
                      and More
                    </span>
                  </span>
                </ng-container>
              </nz-space>
            </div>
          </ng-template>
        </div>

        <nz-divider
          *ngIf="!setting.isMobile"
          nzType="vertical"
          class="opportunities-divider dark:border-fff border-000 opacity-10 d-flex h-100"
        ></nz-divider>

        <div
          class="d-flex flex-column"
          [ngClass]="
            setting.isMobile ? 'align-items-center' : 'align-items-end'
          "
        >
          <div class="text-6b7280">TVL</div>

          <nz-skeleton-element
            nzType="button"
            [nzActive]="true"
            [nzSize]="'small'"
            *ngIf="loading"
          ></nz-skeleton-element>

          <div
            *ngIf="!loading"
            class="font-openSans-bold font-14 w-full"
            [ngClass]="{
              'text-center':
                setting.isMobile ||
                !opportunity.totalTVLToDisplay ||
                opportunity.totalTVLToDisplay === '-'
            }"
          >
            {{ opportunity.totalTVLToDisplay }}
          </div>
        </div>
      </div>

      <div
        class="w-full d-flex flex-column mt-3"
        *ngIf="selectedTab === SelectedOpportunityTab.InvestedOpportunities"
      >
        <span
          *ngIf="selectedActionTab === SelectedInvestmentTab.Withdraw"
          class="mb-2 mt-1 font-12 font-openSans-bold text-000 dark:text-fff"
        >
          Balance
        </span>

        <div
          class="w-full d-flex align-items-center justify-content-between border-1px border-00000010 dark:border-00000012 border-radius-4px font-12 p-2"
          [ngClass]="{
            'bg-F7F7F7 dark:bg-121212': selectedActionTab === undefined
          }"
        >
          <div class="d-flex flex-column">
            <div class="text-6b7280">
              {{
                selectedTab !== undefined && selectedActionTab === undefined
                  ? "Invested Amount"
                  : selectedActionTab === SelectedInvestmentTab.Withdraw
                  ? "Amount"
                  : ""
              }}
            </div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="loading"
            ></nz-skeleton-element>

            <div
              class="font-openSans-bold font-14 text-000 dark:text-fff"
              *ngIf="!loading"
            >
              {{ opportunity.totalInvestedAmountUSDToDisplay ?? "-" }}
            </div>
          </div>

          <div class="d-flex flex-column align-items-center">
            <div class="text-6b7280">Profit</div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="loading"
            ></nz-skeleton-element>

            <div
              *ngIf="!loading && opportunity.totalProfit"
              class="d-flex items-center font-openSans-bold justify-content-center"
              [ngClass]="{
                'text-13B9AA': opportunity.totalProfit >= 0,
                'text-ef4444': opportunity.totalProfit < 0
              }"
            >
              {{
                opportunity.totalProfit < 0
                  ? "-"
                  : opportunity.totalProfit > 0
                  ? "+"
                  : ""
              }}
              {{ opportunity.totalProfitToDisplay }}%
            </div>
          </div>

          <div
            class="d-flex flex-column align-items-end"
            *ngIf="selectedActionTab !== SelectedInvestmentTab.Withdraw"
          >
            <div class="text-6b7280">Date</div>

            <nz-skeleton-element
              nzType="button"
              [nzActive]="true"
              [nzSize]="'small'"
              *ngIf="loading"
            ></nz-skeleton-element>

            <div
              class="font-openSans-bold text-000 dark:text-fff"
              *ngIf="!loading"
              [ngClass]="{ 'text-center': !opportunity.createdDate }"
            >
              {{ opportunity.createdDateToDisplay }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full d-flex flex-column mt-20px"
        *ngIf="selectedActionTab === SelectedInvestmentTab.Withdraw"
      >
        <div class="flex w-full justify-content-between px-1">
          <span class="font-12 font-openSans-bold text-000 dark:text-fff">
            Detail Balance
          </span>
          <span class="font-12 font-openSans-bold text-000 dark:text-fff">
            Profit
          </span>
        </div>

        <div
          class="border-1px border-00000010 dark:border-00000012 border-radius-4px mt-2"
        >
          <nz-table
            #tokensTable
            [nzData]="opportunity.tokenShares"
            [nzShowPagination]="false"
            [nzTotal]="opportunity.tokenShares.length"
            nzTableLayout="fixed"
            class="no-text-wrap bg-white dark:bg-darkBlack px-10px py-6px"
          >
            <!-- class="bg-F7F7F7 dark:bg-11182c" -->
            <tbody>
              <tr *ngFor="let item of tokensTable.data">
                <td class="px-0" [colSpan]="3">
                  <div class="d-flex pl-2 align-items-center">
                    <div class="d-flex align-items-center scale-60 mx--14">
                      <img
                        class="z-1 rounded-full mr-2"
                        [src]="
                          baseLogoIconsUrl +
                          item?.token?.symbol?.toLowerCase() +
                          '.png'
                        "
                        (error)="updateUrl($event)"
                      />
                    </div>

                    <span class="font-12 text-000 dark:text-fff mx-1">
                      {{ normalizeSymbol(item?.token?.symbol) }}
                    </span>
                  </div>
                </td>

                <td class="px-0">
                  <span
                    class="font-12 font-openSans-bold text-000 dark:text-fff w-100 text-left d-block"
                  >
                    {{ item.amountToDisplay }}
                  </span>
                </td>

                <td class="px-0" [colSpan]="2">
                  <div class="d-flex flex-column align-items-end">
                    <!--                    <span class="font-12 text-6b7280"> Profit </span>-->

                    <nz-skeleton-element
                      nzType="button"
                      [nzActive]="true"
                      [nzSize]="'small'"
                      *ngIf="loading"
                    ></nz-skeleton-element>

                    <div
                      *ngIf="!loading && item.profit"
                      class="font-openSans-bold font-14"
                      [ngClass]="{
                        'text-13B9AA': item.profit >= 0,
                        'text-ef4444': item.profit < 0
                      }"
                    >
                      {{ item.profit < 0 ? "-" : item.profit > 0 ? "+" : "" }}
                      {{ item.profitToDisplay }}%
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>

      <p
        class="w-full font-14 text-323232 dark:text-DADADA mt-20px"
        *ngIf="selectedActionTab !== undefined"
      >
        Dive into the future of finance with our exclusive DeFi ETF, featuring a
        strategic blend of top cryptocurrencies. This specially curated ETF
        grants diversified exposure to the pivotal assets of the decentralized
        finance realm, blending the proven performance of Bitcoin and Ethereum
        with the forward-thinking solutions like Chainlink and Avalanche. Ideal
        for those looking to invest in the future of finance without the hassle
        of managing individual assets.
      </p>
    </div>

    <button
      *ngIf="selectedActionTab === undefined"
      nz-button
      nzType="default"
      (click)="invest(opportunity)"
      [nzSize]="'default'"
      class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-16 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
    >
      <span class="px-1"> Funding </span>
    </button>
  </div>
</div>
