import { Injectable } from '@angular/core';
import { MainNetworksById, Networks } from '@crowdswap/constant';
import { environment } from '../environments/environment';

@Injectable()
export class Constants {
  public static DEBRIDGE_ADDRESS: string =
    '0x663DC15D3C1aC63ff12E45Ab68FeA3F0a883C251';
  public static ARROW_UNICODE: string = '➞';
  public static EXECUTION_FEE_ERROR: number = 159753;
  public static GAS_LIMIT_MARGIN_PERCENT: number = 30;
  public static REDUCE_MAX_AMOUNT_IN: number = 0.01;
  public static DEFAULT_PAIRS = {
    [Networks.MAINNET]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.ROPSTEN]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.RINKEBY]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.GOERLI]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.KOVAN]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.BSCMAIN]: {
      fromToken: 'BNB',
      toToken: 'BUSD'
    },
    [Networks.BSCTEST]: {
      fromToken: 'BNB',
      toToken: 'BUSD'
    },
    [Networks.POLYGON_MAINNET]: {
      fromToken: 'MATIC',
      toToken: 'CROWD'
    },
    [Networks.POLYGON_MUMBAI]: {
      fromToken: 'MATIC',
      toToken: 'CROWD'
    },
    [Networks.AVALANCHE]: {
      fromToken: 'AVAX',
      toToken: 'DAI.e'
    },
    [Networks.AVALANCHE_FUJI]: {
      fromToken: 'AVAX',
      toToken: 'DAI'
    },
    [Networks.APEX]: {
      fromToken: 'OMNIA',
      toToken: 'SPHYNX'
    },
    [Networks.ARBITRUM]: {
      fromToken: 'ETH',
      toToken: 'ARB'
    },
    [Networks.ZKSYNC]: {
      fromToken: 'ETH',
      toToken: 'USDC'
    },
    [Networks.ZKSYNCTEST]: {
      fromToken: 'ETH',
      toToken: 'USDC'
    },
    [Networks.OPTIMISM]: {
      fromToken: 'ETH',
      toToken: 'USDC'
    },
    [Networks.LINEA]: {
      fromToken: 'ETH',
      toToken: 'USDC.e'
    },
    [Networks.BASE]: {
      fromToken: 'ETH',
      toToken: 'USDC'
    },
    [Networks.DEFI]: {
      fromToken: 'DFI',
      toToken: 'CROWD'
    },
    [Networks.ROOTSTOCK]: {
      fromToken: 'RBTC',
      toToken: 'DOC'
    }
  };
  public static CROSSCHAIN_DEFAULT_PAIRS = {
    [Networks.MAINNET]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.ROPSTEN]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.RINKEBY]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.GOERLI]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.KOVAN]: {
      fromToken: 'ETH',
      toToken: 'DAI'
    },
    [Networks.BSCMAIN]: {
      fromToken: 'BNB',
      toToken: 'BUSD'
    },
    [Networks.BSCTEST]: {
      fromToken: 'BNB',
      toToken: 'BUSD'
    },
    [Networks.POLYGON_MAINNET]: {
      fromToken: 'MATIC',
      toToken: 'CROWD'
    },
    [Networks.POLYGON_MUMBAI]: {
      fromToken: 'MATIC',
      toToken: 'CROWD'
    },
    [Networks.AVALANCHE]: {
      fromToken: 'AVAX',
      toToken: 'DAI.e'
    },
    [Networks.AVALANCHE_FUJI]: {
      fromToken: 'AVAX',
      toToken: 'DAI'
    },
    [Networks.APEX]: {
      fromToken: 'OMNIA',
      toToken: 'SPHYNX'
    },
    [Networks.ARBITRUM]: {
      fromToken: 'ETH',
      toToken: 'ARB'
    },
    [Networks.ZKSYNC]: {
      fromToken: 'ETH',
      toToken: 'CROWD'
    },
    [Networks.OPTIMISM]: {
      fromToken: 'ETH',
      toToken: 'USDC'
    },
    [Networks.LINEA]: {
      fromToken: 'ETH',
      toToken: 'USDC'
    },
    [Networks.BASE]: {
      fromToken: 'ETH',
      toToken: 'USDC'
    },
    [Networks.DEFI]: {
      fromToken: 'DFI',
      toToken: 'CROWD'
    },
    [Networks.ROOTSTOCK]: {
      fromToken: 'RBTC',
      toToken: 'DOC'
    }
  };
  public static DEFAULT_TOKENS = {
    [Networks.MAINNET]: ['CROWD', 'ETH', 'USDT'],
    [Networks.BSCMAIN]: ['CROWD', 'BNB', 'USDT'],
    [Networks.POLYGON_MAINNET]: ['CROWD', 'MATIC', 'USDT'],
    [Networks.AVALANCHE]: ['CROWD', 'AVAX', 'USDT.e'],
    [Networks.APEX]: ['OMNIA', 'USDT', 'SPHYNX', 'WETH'],
    [Networks.ARBITRUM]: ['CROWD', 'ETH', 'ARB', 'USDT'],
    [Networks.ZKSYNC]: ['CROWD', 'ETH', 'USDC'],
    [Networks.OPTIMISM]: ['ETH', 'USDT', 'OP'],
    [Networks.LINEA]: ['ETH', 'USDC.e'],
    [Networks.BASE]: ['ETH', 'USDC'],
    [Networks.DEFI]: ['CROWD', 'DFI', 'dUSDT'],
    [Networks.ROOTSTOCK]: ['WRBTC', 'RBTC', 'DOC']
  };
  public static SWAP_INITIAL_CHAIN_ID = Networks.POLYGON_MAINNET;

  public static CROSS_CHAIN_FEE_TOLERANCE_PERCENTAGE: number = 20;
  public static CROSSCHAIN_TX_LIFE_TIME: 60000; //in millisecond
  public static CROSSCHAIN_CHECK_STATUS_INTERVAL = 30000; //in millisecond
  public static CROSSCHAIN_NETWORKS_BY_ID: { [key: string]: string } = (() => {
    const result: { [key: string]: string } = {};

    Object.entries(MainNetworksById).forEach(
      ([chainId, chainName]: [string, string]) => {
        if (environment.ACTIVE_NETWORK.includes(chainId)) {
          result[chainId] = chainName;
        }
      }
    );

    return result;
  })();
  public static CROSSCHAIN_INITIAL_CHAIN_ID = Networks.MAINNET;
  public static SHIMMER_FIX_NAME: string = 'Shimmer';

  public static CrosschainsMap = {
    1: 'Debridge_CROWDSWAP',
    2: 'Debridge_ONEINCHE',
    3: 'Debridge_CROSSDEX',
    4: 'Multichain_CROWD',
    5: 'Rango',
    6: 'CROWDSWAP_BRIDGE',
    7: 'CROWDSWAP_NATIVE',
    8: 'OKX',
    9: 'CROWDSWAP_AXELAR_BASED',
    10: 'UNIZEN',
    102: 'Debridge_ONEINCHE_PURE',
    Debridge_CROWDSWAP: 1,
    Debridge_ONEINCHE: 2,
    Debridge_CROSSDEX: 3,
    Multichain_CROWD: 4,
    CROWDSWAP_BRIDGE: 6,
    CROWDSWAP_NATIVE: 7,
    OKX: 8,
    CROWDSWAP_AXELAR_BASED: 9,
    UNIZEN: 10,
    Debridge_ONEINCHE_PURE: 102
  };
}
