import {
  AfterViewInit,
  Component,
  EventEmitter,
  NgModule,
  OnInit,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../../modal.module';
import { DialogsComponent } from '../dialogs.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../../../share/shared.module';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'exchange-tour-dialog',
  templateUrl: './exchange-tour.component.html',
  styleUrls: ['./exchange-tour.component.scss']
})
export class ExchangeTourDialogComponent
  extends DialogsComponent
  implements OnInit
{
  @Output()
  public confirmed = new EventEmitter<object>();
  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 30,
    direction: 'horizontal',
    pagination: {
      el: '.swiper-custome-pagination',
      type: 'bullets'
    },
    navigation: {
      nextEl: '.next-btn',
      prevEl: '.prev-btn'
    }
  };
  public tourList = [
    {
      title: 'Upgraded Cross-Chain Solution',
      desc: [
        'Faster',
        'More cost-effective',
        'Has exclusive bridge to DefiMetaChain',
        'Provides even better prices ',
        'Open for integration with any blockchain '
      ],
      img: 'Upgraded-Cross-Chain-Solution'
    },
    {
      title: 'Track Your Transaction with History V2',
      desc: [
        'Easy access to comprehensive transaction information.',
        'Eliminate the need to navigate between different network scanners.',
        'Enjoy the ability to export data.'
      ],
      img: 'Track-Your-Transaction-with-History-V2'
    },
    {
      title: 'Explore the Utility of CROWD',
      desc: [
        'Witness the utilization of CROWD in facilitating cross-chain transactions.',
        'Provide insights for ambassadors to explain the utility of CROWD.'
      ],
      img: 'Explore-the-Utility-of-CROWD'
    },
    {
      title: 'First Ever DeFi ETF',
      desc: [
        'Diversify your portfolio with the best crypto project.',
        'Save your time and money.',
        'Experience a simple investment process.'
      ],
      img: 'First-Ever-DeFi-ETF',
      darkImg: ''
    },
    {
      title: 'Trend Tokens',
      desc: [
        'Discover Trend Tokens across 7 networks directly.',
        'Simplify the process of buying these tokens.'
      ],
      img: 'Trend-Token'
    }
  ];
  public swiperSlideIsEnd = false;
  public version = environment.version;

  constructor() {
    super();
  }

  public toggleDropdown() {
    this.confirmed.emit(undefined);
  }

  public async ngOnInit() {}

  beforeSlideChangeStart(swiper: Swiper[]) {
    this.swiperSlideIsEnd = swiper[0].isEnd;
  }
}

@NgModule({
  imports: [CommonModule, ModalModule, FormsModule, SharedModule.forRoot()],
  declarations: [ExchangeTourDialogComponent],
  exports: [ExchangeTourDialogComponent]
})
export class SelectOptionDialogComponentModule {}
