<div class="p-2 px-md-4 items-center top-0 container-xxl header-zIndex">
  <div class="flex items-center justify-content-end">
    <span
      nz-popconfirm
      [nzPopconfirmTitle]="helperContext"
      [(nzPopconfirmVisible)]="popConfirmVisible"
      [nzPopconfirmTrigger]="null"
      [nzPopconfirmPlacement]="'topRight'"
      [nzPopconfirmOverlayClassName]="'help-pop-confirm'"
    >
      <span
        (click)="openHelp()"
        (clickOutside)="click($event)"
        nz-tooltip
        [nzTooltipOverlayClassName]="'open-help-tooltip'"
        [nzTooltipTitle]="helperTooltip"
        [nzTooltipTrigger]="isMobile ? 'click' : 'hover'"
        [(nzTooltipVisible)]="mobileTooltip"
        class="dot bg-0066FF text-fff font-16 flex items-center cursor-pointer justify-center"
      >
        <svg
          class="svg"
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="14"
          viewBox="0 0 8 14"
        >
          <path
            fill="#fff"
            fill-opacity="1"
            fill-rule="nonzero"
            stroke="none"
            d="M5 13H4v1h1v-1zM4 1C2.343 1 1 2.343 1 4H0c0-2.21 1.79-4 4-4 2.21 0 4 1.79 4 4 0 1.268-.59 2.398-1.51 3.13l-.083.065C5.553 7.833 5 8.852 5 10H4c0-1.424.662-2.694 1.694-3.518l.083-.064C6.519 5.87 7 4.992 7 4c0-1.657-1.343-3-3-3z"
          ></path>
        </svg>
      </span>
    </span>
  </div>
</div>

<ng-template #helperContext>
  <div class="text-fff">
    <div class="space-y-2">
      <!--    FAQ-->
      <div>
        <a
          href="https://crowdswap.org/faq1/"
          target="_blank"
          class="flex wrapper items-center font-openSans-semiBold space-x-2"
        >
          <p>Related FAQs</p>
          <span><img src="assets/media/icons/etherscan-logo-white.svg" /></span>
          <div class="line"></div>
        </a>
        <div class="my-1" *ngFor="let item of helpCenterData">
          <a
            *ngIf="item.topicType === 'faq'"
            href="{{ item.link }}"
            target="_blank"
            >{{ item.text }}</a
          >
        </div>
      </div>

      <!--    VIDEOS-->
      <div>
        <a
          href="https://www.youtube.com/@crowdswap40"
          target="_blank"
          class="flex wrapper items-center font-openSans-semiBold space-x-2"
        >
          <span> Related Guide Videos </span>
          <span><img src="assets/media/icons/etherscan-logo-white.svg" /></span>
          <div class="line"></div>
        </a>
        <div class="my-1" *ngFor="let item of helpCenterData">
          <a
            class="flex items-center space-x-2"
            *ngIf="item.topicType === 'video'"
            href="{{ item.link }}"
            target="_blank"
          >
            <span class="mr-1">
              <svg
                fill="#FFFFFF"
                height="16px"
                width="16px"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 58.752 58.752"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M52.524,23.925L12.507,0.824c-1.907-1.1-4.376-1.097-6.276,0C4.293,1.94,3.088,4.025,3.088,6.264v46.205
		c0,2.24,1.204,4.325,3.131,5.435c0.953,0.555,2.042,0.848,3.149,0.848c1.104,0,2.192-0.292,3.141-0.843l40.017-23.103
		c1.936-1.119,3.138-3.203,3.138-5.439C55.663,27.134,54.462,25.05,52.524,23.925z M49.524,29.612L9.504,52.716
		c-0.082,0.047-0.18,0.052-0.279-0.005c-0.084-0.049-0.137-0.142-0.137-0.242V6.263c0-0.1,0.052-0.192,0.14-0.243
		c0.042-0.025,0.09-0.038,0.139-0.038c0.051,0,0.099,0.013,0.142,0.038l40.01,23.098c0.089,0.052,0.145,0.147,0.145,0.249
		C49.663,29.47,49.611,29.561,49.524,29.612z"
                  />
                </g>
              </svg>
            </span>
            {{ item.text }}</a
          >
        </div>
      </div>

      <!--    Wrapper line-->
      <div class="wrapper">
        <div class="line"></div>
      </div>

      <!--    Discord-->
      <a
        href="https://discord.com/invite/qEndDPvjNK"
        target="_blank"
        class="flex items-center font-openSans-semiBold space-x-2"
      >
        <span
          ><img style="width: 15px" src="assets/media/icons/Discord-D.svg"
        /></span>
        <span> Ask the community </span>
        <span><img src="assets/media/icons/etherscan-logo-white.svg" /></span>
      </a>

      <!--    Help center-->
      <a
        href="https://docs.crowdswap.org/"
        target="_blank"
        class="flex items-center font-openSans-semiBold space-x-2"
      >
        <span> Help center </span>
        <span><img src="assets/media/icons/etherscan-logo-white.svg" /></span>
      </a>

      <!--    Blog-->
      <a
        href="https://crowdswap.org/blog/"
        target="_blank"
        class="flex items-center font-openSans-semiBold space-x-2"
      >
        <span> Blog </span>
        <span><img src="assets/media/icons/etherscan-logo-white.svg" /></span>
      </a>
    </div>
  </div>
</ng-template>

<ng-template #helperTooltip>
  <div class="text-sm text-center text-white bg-black">
    <div>Need help?</div>
    <div
      title="confirm-open"
      (click)="openHelp(true)"
      class="blue-button p-1 rounded-sm"
      *ngIf="isMobile"
    >
      Confirm
    </div>
  </div>
</ng-template>
