<div class="md:mx-9 flex justify-center md:px-6">
  <div
    class="flex items-center w-4/5 blue-color md:mx-9 md:px-6 font-openSans-semiBold"
  >
    <svg
      class="cursor-pointer"
      onclick="history.back()"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6654 6.16536H3.5237L8.18203 1.50703L6.9987 0.332031L0.332031 6.9987L6.9987 13.6654L8.1737 12.4904L3.5237 7.83203H13.6654V6.16536Z"
        fill="#0066FF"
      />
    </svg>
    <span onclick="history.back()" class="pl-2 cursor-pointer">Back</span>
  </div>
</div>
<div class="mt-4 dark:text-white">
  <div class="flex justify-center mb-3 text-center text-2xl md:text-4xl">
    VIP Ranking CrowdSwap
  </div>
  <div class="flex mb-2 justify-center text-center text-sm">
    Earn points with our VIP ranking system and look forward to upcoming
    benefits
  </div>
</div>
<div class="mt-2 dark:text-white bottom flex justify-center">
  <div class="justify-center">
    <div class="row justify-center parent-section">
      <div *ngIf="showBanner" class="md:mx-9 mb-5 flex justify-center md:px-6">
        <div
          class="flex justify-content-between rounded-md text-fff items-center font-14 mx-2 px-2 font-openSans-regular"
          [ngClass]="isDarkMode ? 'bg-0B9ED9-dark' : 'bg-0B9ED9'"
        >
          <div class="flex items-center">
            <div class="flex-shirink-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="9"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.9989 8H12.0089"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 12H12V16H13"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <span class="p-2"
              >Please be aware that this is an early version of the CrowdSwap
              VIP scoring system. It is not final and the data may not be
              accurate.</span
            >
          </div>
          <div class="cursor-pointer" (click)="disableBanner()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap w-full justify-center">
        <div
          *ngFor="let item of vipService.rankings; let i = index"
          class="p-2"
        >
          <app-vip-card
            class="flex justify-center"
            [vip]="item"
            [userStatus]="userStatus"
          ></app-vip-card>
        </div>
      </div>
    </div>
  </div>
</div>
