import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Web3Service } from '../../../../services/web3.service';
import { ThemeService } from '../../../../services/theme.service';
import { filter, throttleTime } from 'rxjs/operators';
import { CurrentNetwork } from '../../../../model/current-network';
import { asyncScheduler, Subscription } from 'rxjs';
import { PrivateSaleService } from '../../../../services/private-sale.service';
import { ToastrService } from 'ngx-toastr';
import {
  Conversion,
  Networks,
  OpportunitiesHolder,
  TokensHolder
} from '@crowdswap/constant';
import { Router } from '@angular/router';
import { WaitingDialogComponent } from '../../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { ModalService } from '../../../modal/modal.service';
import { SuccessfulUnStakeDialogComponent } from '../../../modal/dialogs/successful-unstake/successful-unstake-dialog.component';
import { BaseComponent } from '../../base.component';
import {
  NDDClientInfoServiceImpl,
  OpportunityService,
  TagManagerService
} from '../../../../services';

export enum Tabs {
  Information,
  Kyc,
  Price,
  Vesting,
  Time,
  Ticket,
  Links,
  Rules
}

@Component({
  selector: 'app-form-private-sale',
  templateUrl: './form-private-sale.component.html',
  styleUrls: ['./form-private-sale.component.scss']
})
export class FormPrivateSaleComponent extends BaseComponent implements OnInit {
  Tabs = Tabs;
  public decimalFlag: boolean = false;
  public tokenFlag: boolean = false;
  public currentChainId: number = 1;
  public firstHours = 3;
  public ticketNumbers = 0;
  public tokenList = ['USDT', 'USDC'];
  public investToken = this.tokenList[0];
  public tab = Tabs.Information;
  subscriptionList: Subscription[] = [];
  public projectName: string = '';
  public presaleTokenName: string = '';
  public presaleTokenDecimal: string = '18';
  public presaleTokenAddress: string = '';
  public description: string = '';
  public bannerDescription: string = '';
  public risk: string = '';
  public softCap;
  public hardCap;
  public maximumOfRound1;
  public priceOfRound1;
  public maximumOfRound2;
  public priceOfRound2;
  public maximumOfRound3;
  public priceOfRound3;
  public websiteUrl: string = '';
  public instagramUrl: string = '';
  public redditUrl: string = '';
  public twitterUrl: string = '';
  public pitchDeckUrl: string = '';
  public telegramUrl: string = '';
  public youtubeUrl: string = '';
  public linkedInUrl: string = '';
  public mediumUrl: string = '';
  public isReadAndAccept: boolean = false;
  public isFirstHourActive: boolean = false;
  public isRequired: boolean = false;
  public isNotValid: boolean = false;
  public iconUrl: string = '';
  public signMessage: string = '';
  public fractalUrl = '';
  public kycLevel;
  public fractalListId = '';
  public minInvest;
  public endDateDay;
  public endDateHour;
  public startDateDay;
  public startDateHour;
  public tgeDateDay;
  public tgeDateHour;
  public rulesetList: any[] = [{ month: '', value: 0 }];
  public isVestingLinear: boolean = false;
  loading: boolean = false;
  shortLink: string = '';
  file: any;
  public isIconUploaded: boolean = false;
  public rulesetPercentage: number = 0;
  public rulesetMonth: number = 0;
  public currentTime;
  public isMonthsValid: boolean = true;
  public isPercentageValid: boolean = true;
  public maxStartTime;
  public minStartTime;
  public decimals = [6, 9, 18];
  public maxEndTime;
  public minEndTime;
  public twoDigitDecimalNumberRegex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  public termsOfService;
  public didSign: boolean = false;
  public presale;

  constructor(
    public web3Service: Web3Service,
    public themeService: ThemeService,
    private privateSaleService: PrivateSaleService,
    private toastr: ToastrService,
    private router: Router,
    private opportunityService: OpportunityService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private successfulUnStakeDialogService: ModalService<SuccessfulUnStakeDialogComponent>,
    public ref: ChangeDetectorRef,
    public tagManagerService: TagManagerService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(web3Service, themeService, tagManagerService, clientInfoServiceImpl);
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    this.termsOfService =
      OpportunitiesHolder.OpportunitiesData.PRESALE.termsOfService;
    this.currentTime = Math.floor(Date.now() / 1000);
    this.minStartTime = new Date(this.currentTime * 1000)
      .toISOString()
      .split('T')[0];
    this.maxStartTime = new Date((this.currentTime + 5184000) * 1000)
      .toISOString()
      .split('T')[0];
    this.themeService.darkModeChangeSubject.subscribe((isDark) => {
      this.isDarkMode = isDark;
    });

    if (localStorage.getItem('presale') !== null) {
      const presaleObj = localStorage.getItem('presale')
        ? JSON.parse(localStorage.getItem('presale')!)
        : {};
      this.projectName = presaleObj.projectName;
      this.presaleTokenName = presaleObj.presaleTokenName;
      this.presaleTokenDecimal = presaleObj.presaleTokenDecimal;
      this.presaleTokenAddress = presaleObj.presaleTokenAddress;
      this.investToken = presaleObj.investToken;
      this.description = presaleObj.description;
      this.bannerDescription = presaleObj.bannerDescription;
      this.risk = presaleObj.risk;
      this.signMessage = presaleObj.signMessage;
      this.kycLevel = presaleObj.kycLevel;
      this.fractalListId = presaleObj.fractalListId;
      this.fractalUrl = presaleObj.fractalUrl;
      this.softCap = presaleObj.softCap;
      this.hardCap = presaleObj.hardCap;
      this.minInvest = presaleObj.minInvest;
      this.maximumOfRound1 = presaleObj.maximumOfRound1;
      this.maximumOfRound2 = presaleObj.maximumOfRound2;
      this.maximumOfRound3 = presaleObj.maximumOfRound3;
      this.priceOfRound1 = presaleObj.priceOfRound1;
      this.priceOfRound2 = presaleObj.priceOfRound2;
      this.priceOfRound3 = presaleObj.priceOfRound3;
      this.rulesetList = presaleObj.rulesetList;
      this.startDateDay = presaleObj.startDateDay;
      this.startDateHour = presaleObj.startDateHour;
      this.minStartTime = presaleObj.minStartTime;
      this.maxStartTime = presaleObj.maxStartTime;
      this.endDateDay = presaleObj.endDateDay;
      this.endDateHour = presaleObj.endDateHour;
      this.tgeDateDay = presaleObj.tgeDateDay;
      this.tgeDateHour = presaleObj.tgeDateHour;
      this.isFirstHourActive = presaleObj.isFirstHourActive;
      this.firstHours = presaleObj.firstHours;
      this.ticketNumbers = presaleObj.ticketNumbers;
      this.websiteUrl = presaleObj.websiteUrl;
      this.instagramUrl = presaleObj.instagramUrl;
      this.redditUrl = presaleObj.redditUrl;
      this.twitterUrl = presaleObj.twitterUrl;
      this.pitchDeckUrl = presaleObj.pitchDeckUrl;
      this.telegramUrl = presaleObj.telegramUrl;
      this.youtubeUrl = presaleObj.youtubeUrl;
      this.linkedInUrl = presaleObj.linkedInUrl;
      this.mediumUrl = presaleObj.mediumUrl;
      this.isMonthsValid = presaleObj.isMonthsValid;
      this.isPercentageValid = presaleObj.isPercentageValid;
      this.rulesetPercentage = presaleObj.rulesetPercentage;
      this.rulesetMonth = presaleObj.rulesetMonth;
      this.isVestingLinear = presaleObj.isVestingLinear ?? false;
    }

    // On wallet connect/disconnect
    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe(
        (connection) => {}
      )
    );
    // On network change
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          this.currentChainId = currentNetwork.chainId;
          this.presale =
            OpportunitiesHolder.OpportunitiesData.PRESALE.networks[
              this.currentChainId
            ];
        })
    );
    // On account change
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async (address) => {
        this.didSign = await this.privateSaleService.didSign(
          OpportunitiesHolder.OpportunitiesData.PRESALE.name
        );
      })
    );
  }

  onClose(item) {
    switch (item) {
      case this.tokenFlag: {
        this.tokenFlag = false;
        break;
      }
      case this.decimalFlag: {
        this.decimalFlag = false;
        break;
      }
    }
  }

  tokenSelect(tokenSymbol) {
    this.investToken = tokenSymbol;
  }

  decimalSelect(tokenDecimal) {
    this.presaleTokenDecimal = tokenDecimal;
  }

  public scroll(element: any, tab) {
    this.tab = tab;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  public changeTicketNumbers(action) {
    switch (action) {
      case 'increase': {
        this.ticketNumbers += 1;
        break;
      }
      case 'decrease': {
        this.ticketNumbers -= 1;
        break;
      }
      case 'max': {
        this.ticketNumbers = 10;
        break;
      }
    }
  }

  public changeFirstHours(action) {
    switch (action) {
      case 'increase': {
        this.firstHours += 1;
        break;
      }
      case 'decrease': {
        this.firstHours -= 1;
        break;
      }
    }
  }

  saveAs() {
    const presaleObj = {
      projectName: this.projectName,
      presaleTokenName: this.presaleTokenName,
      presaleTokenDecimal: this.presaleTokenDecimal,
      presaleTokenAddress: this.presaleTokenAddress,
      investToken: this.investToken,
      description: this.description,
      bannerDescription: this.bannerDescription,
      risk: this.risk,
      signMessage: this.signMessage,
      kycLevel: this.kycLevel,
      fractalListId: this.fractalListId,
      fractalUrl: this.fractalUrl,
      softCap: this.softCap,
      hardCap: this.hardCap,
      minInvest: this.minInvest,
      maximumOfRound1: this.maximumOfRound1,
      maximumOfRound2: this.maximumOfRound2,
      maximumOfRound3: this.maximumOfRound3,
      priceOfRound1: this.priceOfRound1,
      priceOfRound2: this.priceOfRound2,
      priceOfRound3: this.priceOfRound3,
      rulesetList: this.rulesetList,
      startDateDay: this.startDateDay,
      startDateHour: this.startDateHour,
      minStartTime: this.minStartTime,
      maxStartTime: this.maxStartTime,
      endDateDay: this.endDateDay,
      endDateHour: this.endDateHour,
      tgeDateDay: this.tgeDateDay,
      tgeDateHour: this.tgeDateHour,
      isFirstHourActive: this.isFirstHourActive,
      firstHours: this.firstHours,
      ticketNumbers: this.ticketNumbers,
      websiteUrl: this.websiteUrl,
      instagramUrl: this.instagramUrl,
      redditUrl: this.redditUrl,
      twitterUrl: this.twitterUrl,
      pitchDeckUrl: this.pitchDeckUrl,
      telegramUrl: this.telegramUrl,
      youtubeUrl: this.youtubeUrl,
      linkedInUrl: this.linkedInUrl,
      mediumUrl: this.mediumUrl,
      isMonthsValid: this.isMonthsValid,
      isPercentageValid: this.isPercentageValid,
      rulesetPercentage: this.rulesetPercentage,
      rulesetMonth: this.rulesetMonth,
      isVestingLinear: this.isVestingLinear
    };
    localStorage.setItem('presale', JSON.stringify(presaleObj));
    this.toastr.success(
      'All data are saved for next time.',
      'Successful save!',
      {
        closeButton: true,
        tapToDismiss: false,
        progressBar: true,
        positionClass: 'custom-toast-top-right',
        enableHtml: true,
        timeOut: 10000,
        messageClass: 'successClass'
      }
    );
  }

  public clearSale() {
    localStorage.removeItem('presale');
    (this.projectName = ''),
      (this.presaleTokenName = ''),
      (this.presaleTokenDecimal = '18'),
      (this.presaleTokenAddress = ''),
      (this.investToken = 'USDT'),
      (this.description = ''),
      (this.bannerDescription = ''),
      (this.risk = ''),
      (this.signMessage = ''),
      (this.kycLevel = ''),
      (this.softCap = ''),
      (this.hardCap = ''),
      (this.minInvest = ''),
      (this.maximumOfRound1 = ''),
      (this.maximumOfRound2 = ''),
      (this.maximumOfRound3 = ''),
      (this.priceOfRound1 = ''),
      (this.priceOfRound2 = ''),
      (this.priceOfRound3 = ''),
      (this.rulesetList = [{ month: 0, value: 0 }]),
      (this.startDateDay = ''),
      (this.startDateHour = ''),
      (this.minStartTime = ''),
      (this.maxStartTime = ''),
      (this.endDateDay = ''),
      (this.endDateHour = ''),
      (this.tgeDateDay = ''),
      (this.tgeDateHour = ''),
      (this.isFirstHourActive = false),
      (this.firstHours = 3),
      (this.ticketNumbers = 0),
      (this.websiteUrl = ''),
      (this.instagramUrl = ''),
      (this.redditUrl = ''),
      (this.twitterUrl = ''),
      (this.pitchDeckUrl = ''),
      (this.telegramUrl = ''),
      (this.youtubeUrl = ''),
      (this.linkedInUrl = ''),
      (this.mediumUrl = ''),
      (this.isMonthsValid = false),
      (this.isPercentageValid = false),
      (this.rulesetPercentage = 0),
      (this.rulesetMonth = 0);
    this.isVestingLinear = false;
  }

  async createSale(): Promise<any> {
    this.isRequired = false;
    this.isNotValid = false;
    if (!this.checkRequirement()) {
      this.isRequired = true;
      return;
    }
    if (!this.checkValidation()) {
      this.isNotValid = true;
      return;
    }
    try {
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        const links: any[] = [];
        links.push('website$' + this.websiteUrl);
        if (this.instagramUrl) {
          links.push('instagram$' + this.instagramUrl);
        }
        if (this.redditUrl) {
          links.push('reddit$' + this.redditUrl);
        }
        if (this.twitterUrl) {
          links.push('twitter$' + this.twitterUrl);
        }
        if (this.pitchDeckUrl) {
          links.push('pitchDeck$' + this.pitchDeckUrl);
        }
        if (this.telegramUrl) {
          links.push('telegram$' + this.telegramUrl);
        }
        if (this.youtubeUrl) {
          links.push('youtube$' + this.youtubeUrl);
        }
        if (this.linkedInUrl) {
          links.push('linkedIn$' + this.linkedInUrl);
        }
        if (this.mediumUrl) {
          links.push('medium$' + this.mediumUrl);
        }

        const investToken =
          TokensHolder.ObservableTokenListBySymbol[
            Networks[this.web3Service.getCurrentChainId()]
          ][this.investToken];

        const priceStages: any[] = [];
        priceStages.push({
          amount: Conversion.convertStringToDecimal(
            this.maximumOfRound1,
            investToken.decimals
          ).toString(),
          price: Conversion.convertStringToDecimal(
            this.priceOfRound1,
            6
          ).toString()
        });
        if (this.maximumOfRound2) {
          priceStages.push({
            amount: Conversion.convertStringToDecimal(
              this.maximumOfRound2,
              investToken.decimals
            ).toString(),
            price: Conversion.convertStringToDecimal(
              this.priceOfRound2,
              6
            ).toString()
          });
        }
        if (this.maximumOfRound3) {
          priceStages.push({
            amount: Conversion.convertStringToDecimal(
              this.maximumOfRound3,
              investToken.decimals
            ).toString(),
            price: Conversion.convertStringToDecimal(
              this.priceOfRound3,
              6
            ).toString()
          });
        }

        const createTx = await this.privateSaleService.createNewPresale({
          beneficiaryAddress: userAddress,
          projectUrl: this.websiteUrl,
          presaleDisplayName: this.projectName,
          iconUrl: this.iconUrl, //no need
          chainId: this.web3Service.getCurrentChainId(),
          signMessage: this.signMessage,
          fractalUrl: this.fractalUrl,
          bannerDescription: this.bannerDescription,
          detailsDescription: this.description,
          riskDescription: this.risk,
          KYCLevel: +this.kycLevel,
          links: links,

          //contract data
          presaleName: this.projectName.replace(/ /g, '_').toLowerCase(),
          firstHoursBestPrice: this.isFirstHourActive ? this.firstHours : 0,
          rulesetList: this.rulesetList.map((ruleset) => ({
            month: ruleset.month,
            value: ruleset.value * 100
          })),
          spots: this.isFirstHourActive ? this.ticketNumbers : 0,
          investToken: investToken,
          presaleTokenName: this.presaleTokenName,
          presaleTokenDecimal: +this.presaleTokenDecimal,
          presaleTokenAddress: this.presaleTokenAddress,
          ticketToken:
            this.web3Service.getCurrentChainId() === Networks.MAINNET
              ? TokensHolder.TokenListBySymbol[
                  Networks[this.web3Service.getCurrentChainId()]
                ]['USDT']
              : TokensHolder.TokenListBySymbol[
                  Networks[this.web3Service.getCurrentChainId()]
                ]['CROWD'],
          fractalListId: this.fractalListId,
          priceStages: priceStages,
          startDate:
            new Date(this.startDateDay + 'T' + this.startDateHour).getTime() /
            1000,
          tgeDate:
            new Date(this.tgeDateDay + 'T' + this.tgeDateHour).getTime() / 1000,
          endDate:
            new Date(this.endDateDay + 'T' + this.endDateHour).getTime() / 1000,
          hardCap: Conversion.convertStringToDecimal(
            this.hardCap,
            investToken.decimals
          ).toString(),
          softCap: Conversion.convertStringToDecimal(
            this.softCap,
            investToken.decimals
          ).toString(),
          minInvest: Conversion.convertStringToDecimal(
            this.minInvest,
            investToken.decimals
          ).toString(),
          incentiveStructList: [], //no need
          isVestingLinear: this.isVestingLinear
        });

        if (!createTx) {
          this.toastr.error('Error', 'Something went wrong!', {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            timeOut: 10000,
            messageClass: 'errorClass'
          });
          return;
        }
        await this.showSwapWaitingDialog(this.presaleTokenName);

        let currentTransaction: any;
        currentTransaction = await this.web3Service
          .sendTransaction(createTx)
          .then(async (data) => {
            return data;
          })
          .catch(async (e) => {
            await this.waitingDialogService.close();
            console.log(e.message);
            this.toastr.error('Error', 'Creation rejected!', {
              closeButton: true,
              tapToDismiss: false,
              progressBar: true,
              positionClass: 'custom-toast-top-right',
              timeOut: 10000,
              messageClass: 'errorClass'
            });
          });
        if (currentTransaction) {
          await this.web3Service
            .waitForTransaction(currentTransaction, 1)
            .then(async (data) => {
              const scanTransactionUrl =
                this.web3Service.getScanTransactionUrl(currentTransaction);
              if (data?.status === 1) {
                await this.waitingDialogService.close();
                await this.showSuccessfulDialog();
                this.toastr.success(
                  `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
                  `Contract created!`,
                  {
                    closeButton: true,
                    tapToDismiss: false,
                    progressBar: true,
                    positionClass: 'custom-toast-top-right',
                    enableHtml: true,
                    timeOut: 10000,
                    messageClass: 'successClass'
                  }
                );
                this.isReadAndAccept = false;
              } else {
                await this.waitingDialogService.close();
                this.toastr.error(
                  `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
                  `Contract creation failed!`,
                  {
                    closeButton: true,
                    tapToDismiss: false,
                    progressBar: true,
                    positionClass: 'custom-toast-top-right',
                    enableHtml: true,
                    timeOut: 10000,
                    messageClass: 'failedClass'
                  }
                );
              }
              return data;
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  public async showSuccessfulDialog() {
    const data = {
      stakeState:
        'created contract. Your sale will be visible in the opportunities page after CrowdSwap checks have been done!',
      isDarkMode: this.isDarkMode,
      withdrawTokensIcon: []
    };

    this.successfulUnStakeDialogService
      .open(SuccessfulUnStakeDialogComponent, data)
      .then(async () => {
        this.ref.detectChanges();
      });
  }

  private async showSwapWaitingDialog(presale: string) {
    const data = {
      stakeState: 'Creat',
      tokenSymbol: 'presale for ' + presale,
      isDarkMode: this.isDarkMode
    };
    await this.waitingDialogService.open(WaitingDialogComponent, data);
  }

  onUpload() {
    this.loading = !this.loading;
    this.isIconUploaded = false;
    console.log(this.file);
    this.privateSaleService
      .uploadPresaleIcon(
        this.file,
        this.projectName.replace(/ /g, '_').toLowerCase()
      )
      .subscribe((event: any) => {
        if (typeof event === 'object') {
          // Short link via api response
          this.shortLink = event.link;
          this.loading = false; // Flag variable
          this.isIconUploaded = true;
        }
      });
  }

  onChange(event) {
    this.file = event.target.files[0];
  }

  removeRuleset(index) {
    this.rulesetList.splice(index, 1);
  }

  addRuleset() {
    this.rulesetList.push({ month: 0, value: 0 });
    this.calculatePercentageAndMonth();
  }

  calculatePercentageAndMonth() {
    this.rulesetPercentage = 0;
    this.rulesetMonth = 0;
    this.isMonthsValid = true;
    this.isPercentageValid = true;
    for (let i = 0; i < this.rulesetList.length; i++) {
      this.rulesetPercentage += +this.rulesetList[i].value;
      this.rulesetMonth = +this.rulesetList[i].month;
      if (
        (i > 0 &&
          +this.rulesetList[i].month <= +this.rulesetList[i - 1].month) ||
        this.rulesetList[i].month === ''
      ) {
        this.isMonthsValid = false;
      }
      if (+this.rulesetList[i].value === 0) {
        this.isPercentageValid = false;
      }
    }
  }

  private checkValidation() {
    return (
      this.currentTime <
        new Date(this.startDateDay + 'T' + this.startDateHour).getTime() /
          1000 &&
      this.currentTime <
        new Date(this.endDateDay + 'T' + this.endDateHour).getTime() / 1000 &&
      new Date(this.startDateDay + 'T' + this.startDateHour).getTime() <
        new Date(this.endDateDay + 'T' + this.endDateHour).getTime() &&
      +this.rulesetPercentage === 100 &&
      this.rulesetList[0].month !== 0 &&
      this.isMonthsValid &&
      this.isPercentageValid
    );
  }

  private checkRequirement() {
    return (
      this.projectName !== undefined &&
      this.projectName !== '' &&
      this.presaleTokenName !== undefined &&
      this.presaleTokenName !== '' &&
      this.description !== undefined &&
      this.description !== '' &&
      this.bannerDescription !== undefined &&
      this.bannerDescription !== '' &&
      this.risk !== undefined &&
      this.risk !== '' &&
      this.signMessage !== undefined &&
      this.signMessage !== '' &&
      this.softCap !== undefined &&
      this.softCap !== '' &&
      this.hardCap !== undefined &&
      this.hardCap !== '' &&
      this.minInvest !== undefined &&
      this.minInvest !== '' &&
      this.rulesetList[0].month !== undefined &&
      this.rulesetList[0].month !== '' &&
      this.rulesetList[0].value !== undefined &&
      this.rulesetList[0].value !== '' &&
      this.maximumOfRound1 !== undefined &&
      this.maximumOfRound1 !== '' &&
      this.priceOfRound1 !== undefined &&
      this.priceOfRound1 !== '' &&
      this.websiteUrl !== undefined &&
      this.websiteUrl !== '' &&
      this.kycLevel !== undefined &&
      this.kycLevel !== '' &&
      this.startDateDay !== undefined &&
      this.startDateDay !== '' &&
      this.startDateHour !== undefined &&
      this.startDateHour !== '' &&
      this.endDateDay !== undefined &&
      this.endDateDay !== '' &&
      this.endDateHour !== undefined &&
      this.endDateHour !== '' &&
      this.isIconUploaded &&
      this.didSign
    );
  }

  setEndTime() {
    this.minEndTime = new Date(new Date(this.startDateDay).getTime())
      .toISOString()
      .split('T')[0];
    this.maxEndTime = new Date(
      new Date(this.startDateDay).getTime() + 604800000
    )
      .toISOString()
      .split('T')[0];
  }

  async sign(): Promise<void> {
    const result = await this.privateSaleService.signMessage(
      OpportunitiesHolder.OpportunitiesData.PRESALE.signMessage,
      OpportunitiesHolder.OpportunitiesData.PRESALE.name
    );

    if (result) {
      this.didSign = true;
    }
  }
}
