<div class="w-full opportunity_page_items marginInput2">
  <div
    class="w-full d-flex flex-column justify-center p-1 md:p-3 border-radius-6px border-00000010 dark:border-1E1E1E dark:bg-121212 border-2"
  >
    <ul
      id="stakeTab"
      data-tabs-toggle="#stakeTabContent"
      role="tablist"
      class="flex space-x-2 justify-content-between -mb-px text-sm font-medium tab-margin text-center text-gray-500 dark:text-gray-400"
    >
      <li class="mr-2 w-full" role="presentation">
        <a
          id="stake-tab"
          role="tab"
          aria-controls="stake"
          aria-selected="true"
          class="inline-flex px-2 py-1 p-md-2 w-full justify-center rounded-md cursor-pointer border-2 border-transparent font-bold hover:border-gray-300 group"
          (click)="switchStakeUnStake('stake')"
          [ngClass]="
            _stakeMode
              ? 'text-fff button-0066FF'
              : 'text-000 bg-blue-100 dark:bg-2D2D2D dark:text-fff'
          "
        >
          Stake
        </a>
      </li>
      <li class="mr-2 w-full" role="presentation">
        <a
          id="unstake-tab"
          data-tabs-target="#unstake"
          role="tab"
          aria-controls="unstake"
          aria-selected="true"
          class="inline-flex px-2 py-1 p-md-2 w-full justify-center rounded-md cursor-pointer border-2 border-transparent font-bold hover:border-gray-300 group"
          aria-current="page"
          (click)="switchStakeUnStake('unStake')"
          [ngClass]="
            !_stakeMode
              ? 'text-fff button-0066FF'
              : 'text-000 bg-blue-100 dark:bg-2D2D2D dark:text-fff'
          "
        >
          Unstake
        </a>
      </li>
    </ul>
    <div class="col-12 pt-4 pb-5 px-3">
      <div class="row">
        <div class="mb-2 flex items-center relative">
          <span class="pr-1 scale-50">
            <i
              class="rounded-full logo-icons logo-icons-{{
                token?.symbol?.toLowerCase()
              }}"
            ></i>
          </span>

          <span class="font-openSans-semiBold dark:text-white">
            {{ token?.symbol }}
          </span>
        </div>
      </div>

      <div class="col-12">
        <div
          [ngClass]="{ 'input-selected': isSelected }"
          class="flex items-center w-full border-2 rounded text-base px-2 h-12 hover:border-gray-300 dark:border-2D2D2D"
        >
          <div class="col-6">
            <input
              appFocusAndSelectValue
              [el]="stakeAmountInput"
              type="text"
              inputmode="decimal"
              #stakeAmountInput
              [(ngModel)]="stakeAmount"
              (ngModelChange)="notifyInput($event)"
              (keyup)="keyUp(stakeAmount)"
              (blur)="isSelected = false"
              (click)="isSelected = true"
              mask="separator"
              class="font-bold font-16 ml-2 border-0 shadow-none outline-none bg-transparent dark:text-white"
            />
          </div>

          <div
            *ngIf="stakeButtonState !== StakingButtonState.WalletNotConnected"
            class="text-right w-full text-xs-2 opacity-50 dark:text-darkGrayText dark:opacity-100"
          >
            Balance:
            <span *ngIf="_stakeMode">
              {{ (token ? token.balance : "0") | mask : "separator.3" }}
            </span>

            <span *ngIf="!_stakeMode">
              {{ stakedBalance | mask : "separator.3" }}
            </span>
          </div>

          <div
            *ngIf="stakeButtonState !== StakingButtonState.WalletNotConnected"
            (click)="setMaxBalance(_stakeMode)"
            class="max-section text-right mx-2 text-xs-2 text-0066FF dark:text-3385FF cursor-pointer"
          >
            Max
          </div>
        </div>

        <span class="text-gray-400 text-xs-2"></span>
      </div>

      <div *ngIf="!loading" class="col-12 mt-4">
        <button
          *ngIf="
            _stakeMode &&
            stakeButtonState === StakingButtonState.ApprovalNeeded &&
            stakeAmount <= (token ? +token.balance : 0) &&
            stakeAmount > 0
          "
          (click)="approve()"
          class="btn-swap-allow blue-button hover:bg-blue-700"
        >
          <svg
            class="bi bi-exclamation-circle text-white"
            fill="currentColor"
            height="20"
            viewBox="0 0 16 16"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
            />
          </svg>

          <span class="font-openSans-semiBold"
            >Allow to use your {{ token?.symbol }}</span
          >
        </button>
      </div>

      <div class="col-12 mt-2">
        <button
          *ngIf="
            _stakeMode &&
            stakeButtonState !== StakingButtonState.WalletNotConnected
          "
          nz-button
          nzType="default"
          (click)="stake()"
          [nzSize]="'large'"
          class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          [ngClass]="{
            'opacity-4':
              (token ? +token.balance : 0) < stakeAmount ||
              stakeButtonState === StakingButtonState.ApprovalNeeded ||
              stakeButtonState === StakingButtonState.Init ||
              stakeAmount <= 0 ||
              loading
          }"
          [disabled]="
            (token ? +token.balance : 0) < stakeAmount ||
            stakeButtonState === StakingButtonState.ApprovalNeeded ||
            stakeButtonState === StakingButtonState.Init ||
            stakeAmount <= 0 ||
            loading
          "
        >
          <span class="px-1">
            <ng-container *ngIf="stakeAmount > 0">
              {{
                stakeAmount <= (token ? +token.balance : 0)
                  ? "Stake"
                  : "Insufficient Balance"
              }}
            </ng-container>

            <ng-container *ngIf="stakeAmount <= 0">
              Enter an amount
            </ng-container>
          </span>
        </button>

        <button
          nz-button
          nzType="default"
          (click)="connectWallet()"
          *ngIf="stakeButtonState === StakingButtonState.WalletNotConnected"
          [nzSize]="'large'"
          class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
        >
          <span class="px-1"> Connect Wallet </span>
        </button>

        <button
          *ngIf="
            !_stakeMode &&
            stakeButtonState !== StakingButtonState.WalletNotConnected
          "
          nz-button
          nzType="default"
          (click)="unStake()"
          [nzSize]="'large'"
          class="w-full px-20px d-flex align-items-center justify-content-center font-openSans-bold font-20 bg-0066FF hover:bg-0066ff26 focus:bg-0066ff26 text-fff border-radius-5px border-0 outline-solid focus:outline-0055d399 focus:outline-3px mt-3"
          [ngClass]="{
            'opacity-4': stakedBalance < stakeAmount || stakeAmount <= 0
          }"
          [disabled]="stakedBalance < stakeAmount || stakeAmount <= 0"
        >
          <span class="px-1">
            <ng-container *ngIf="stakeAmount > 0">
              {{
                stakeAmount <= stakedBalance
                  ? "Unstake"
                  : "Insufficient Balance"
              }}
            </ng-container>

            <ng-container *ngIf="stakeAmount <= 0">
              Enter an amount
            </ng-container>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
