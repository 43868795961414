<div class="row justify-center w-full m-2">
  <div
    class="confirmation-box z-0 rounded-md px-3 bg-gray-50 dark:bg-darkBlack"
  >
    <div class="confirmation-header">
      <div
        class="font-openSans-regular warning-color flex justify-center py-1"
        *ngIf="
          (opportunityState !== OpportunityState.Successful &&
            opportunityState !== OpportunityState.Failed) ||
          crossChainState === CrossChainState.DstWarning ||
          crossChainState === CrossChainState.DstWarningCatch
        "
      >
        <img
          class="init w-5 h-5"
          alt="attention"
          src="assets/media/icons/attention.png"
        />
        <span
          *ngIf="
            opportunityState !== OpportunityState.Successful &&
            opportunityState !== OpportunityState.Failed
          "
        >
          &nbsp;Stay on this page and don't close the browser until you see the
          final result.</span
        >
        <span
          class="text-xs-2"
          *ngIf="crossChainState === CrossChainState.DstWarning"
        >
          &nbsp;Unfortunately the transaction on
          {{ web3Service.networkSpec[activePresale.chainId].title }}
          network did not execute completely and you have received
          {{ fallbackToken.symbol }} token.<br />
          You can restart the process by investing these
          {{ fallbackToken.symbol }}.<br />
          To do so, switch to
          {{ web3Service.networkSpec[activePresale.chainId].title }}
          network, select your desired CrowdSale and choose
          {{ fallbackToken.symbol }} from the token list.
        </span>
        <span
          class="text-xs-2"
          *ngIf="crossChainState === CrossChainState.DstWarningCatch"
        >
          &nbsp;Unfortunately we are not able to query the latest status of the
          transaction. Either your investment has been successful (check details
          page to make sure) or you have received
          {{ fallbackToken.symbol }} (You can restart the process by investing
          these {{ fallbackToken.symbol }}. To do so, switch to
          {{ web3Service.networkSpec[activePresale.chainId].title }}
          network, select your desired CrowdSale and choose
          {{ fallbackToken.symbol }} from the token list).
        </span>
      </div>
      <div class="progressbar-box row">
        <a class="step-label pl-xl-2 dark:text-textWhite"> Step 1 </a>
        <ul class="progressbar dark:text-textWhite">
          <li [ngClass]="{ active: true }">
            Swap on
            {{ web3Service.networkSpec[web3Service.getCurrentChainId()].title }}
          </li>
          <li
            [ngClass]="{
              active: crossChainState >= CrossChainState.SrcSuccessful
            }"
          >
            Cross chain on
            {{ web3Service.networkSpec[activePresale.chainId].title }}<br />
          </li>
        </ul>
      </div>
      <div class="mt-2 row">
        <ul class="success-list">
          <li class="active mt-3">
            <div *ngIf="crossChainState >= CrossChainState.SrcSuccessful">
              <img
                class="init"
                alt="successful"
                src="assets/media/icons/success.png"
              />
              Successful
            </div>
            <div *ngIf="crossChainState === CrossChainState.SrcFailed">
              <img
                class="init"
                alt="failed"
                src="assets/media/icons/error-logo.png"
                style="width: 16px"
              />
              <span class="text-red-600">Failed</span>
            </div>
            <div
              *ngIf="crossChainState === CrossChainState.SrcInit"
              class="flex items-center justify-center mt-2"
            >
              <span
                class="flex loader ease-linear loading rounded-full border-2 border-t-2 h-4 w-4 p-2"
              >
              </span>
            </div>
          </li>
          <li class="active mt-3">
            <div *ngIf="crossChainState >= CrossChainState.DstSuccessful">
              <img
                class="init"
                alt="successful"
                src="assets/media/icons/success.png"
              />
              Successful
            </div>
            <div
              *ngIf="
                crossChainState === CrossChainState.SrcFailed ||
                crossChainState === CrossChainState.DstFailed
              "
            >
              <img
                class="init"
                alt="failed"
                src="assets/media/icons/error-logo.png"
                style="width: 16px"
              />
              <span class="text-red-600">Failed</span>
            </div>
            <div
              class="mb-2"
              *ngIf="
                crossChainState === CrossChainState.DstWarning ||
                crossChainState === CrossChainState.DstWarningCatch
              "
            >
              <img
                class="init"
                alt="warning"
                src="assets/media/icons/warning-icon.png"
                style="width: 16px"
              />
              <span class="warning-color">Warning</span>
            </div>
            <div
              *ngIf="
                crossChainState === CrossChainState.SrcInit ||
                crossChainState === CrossChainState.SrcSuccessful
              "
              class="flex items-center justify-center mt-2"
            >
              <span
                class="flex loader ease-linear loading rounded-full border-2 border-t-2 h-4 w-4 p-2"
              >
              </span>
            </div>
          </li>
        </ul>
      </div>
      <hr class="mt-3" />
      <div class="progressbar-box row">
        <a class="step-label pl-xl-2 dark:text-textWhite"> Step 2 </a>
        <ul class="progressbar dark:text-textWhite">
          <li
            [ngClass]="{
              active:
                crossChainState === CrossChainState.DstSuccessful ||
                opportunityState >= OpportunityState.Failed
            }"
          >
            {{
              "Converting $" +
                investmentAmount +
                " from " +
                investmentToken +
                " to " +
                investToken.symbol
            }}
          </li>
          <li
            [ngClass]="{
              active: opportunityState >= OpportunityState.Failed
            }"
          >
            {{
              "Investing $" +
                investmentAmount +
                " " +
                investToken.symbol +
                " in CrowdSale of project " +
                activePresale.presaleDisplayName
            }}
          </li>
        </ul>
      </div>
      <div class="mt-2 row">
        <ul class="success-list">
          <li class="active mt-3">
            <div *ngIf="opportunityState === OpportunityState.Successful">
              <img
                class="init"
                alt="successful"
                src="assets/media/icons/success.png"
              />
              Successful
            </div>
            <div *ngIf="opportunityState === OpportunityState.Failed">
              <img
                class="init"
                alt="failed"
                src="assets/media/icons/error-logo.png"
                style="width: 16px"
              />
              <span class="text-red-600">Failed</span>
            </div>
            <div
              *ngIf="
                opportunityState !== OpportunityState.Successful &&
                opportunityState !== OpportunityState.Failed
              "
              class="flex items-center justify-center mt-2"
            >
              <span
                class="flex loader ease-linear loading rounded-full border-2 border-t-2 h-4 w-4 p-2"
              >
              </span>
            </div>
          </li>
          <li class="active mt-3">
            <div *ngIf="opportunityState === OpportunityState.Successful">
              <img
                class="init"
                alt="successful"
                src="assets/media/icons/success.png"
              />
              Successful
            </div>
            <div *ngIf="opportunityState === OpportunityState.Failed">
              <img
                class="init"
                alt="failed"
                src="assets/media/icons/error-logo.png"
                style="width: 16px"
              />
              <span class="text-red-600">Failed</span>
            </div>
            <div
              *ngIf="
                opportunityState !== OpportunityState.Successful &&
                opportunityState !== OpportunityState.Failed
              "
              class="flex items-center justify-center mt-2"
            >
              <span
                class="flex loader ease-linear loading rounded-full border-2 border-t-2 h-4 w-4 p-2"
              >
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    *ngIf="opportunityState === OpportunityState.Successful"
    class="flex justify-center text-sm"
  >
    <button
      class="detail-btn blue-button hover:bg-blue-700 font-openSans-semiBold"
      (click)="openDetail()"
    >
      <span>Details</span>
    </button>
  </div>
</div>
