<app-dialog class="w-20" [closable]="false">
  <div class="flex justify-start pb-3 px-3">
    <div class="mt-2 cursor-pointer" (click)="closeDialog()">
      <img
        *ngIf="!data?.isDarkMode"
        src="assets/media/icons/back-logo-black.png"
        height="14"
        width="16"
      />
      <img
        *ngIf="data?.isDarkMode"
        src="assets/media/icons/back-logo-white.png"
        height="14"
        width="16"
      />
    </div>
    <label class="font-openSans-regular ml-67-px dark:text-textWhite">
      Withdraw CROWD
    </label>
  </div>
  <div class="flex justify-start"></div>
  <div class="divide-y">
    <div>
      <div class="rounded-xl pt-6 pr-1 pb-5 w-full">
        <div class="row">
          <div class="col-6 flex px-3">
            <div class="flex items-center justify-center">
              <img
                alt="crowd-token"
                style="width: 36px; height: 36px"
                src="assets/media/logo/Logos_CrowdSwap_CROWD%20Token.svg"
              />
            </div>
            <div
              class="flex grid grid-rows-2 pl-1 text-xs-2 dark:text-textWhite"
            >
              <span>CROWD Inventory</span>
              <span
                class="font-openSans-semiBold text-gray-900 dark:text-textWhite"
                >{{ data?.distributionCrowdBalance }}</span
              >
            </div>
          </div>
          <div class="col-6 flex px-0">
            <div class="flex grid grid-rows-2 text-xs-2 dark:text-textWhite">
              <span>Amount you can withdraw</span>
              <span
                class="font-openSans-semiBold text-gray-900 dark:text-textWhite"
                >{{ data?.releasableCrowdBalance }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-3 mb-3 px-2">
        <button
          (click)="closeDialog()"
          class="py-2 px-4 border rounded-md h-10 mr-1 w-1/2 flex justify-center font-openSans-semiBold text-sm items-center text-center dark:text-textWhite"
        >
          <span>Cancel</span>
        </button>
        <button
          (click)="confirm()"
          class="withdraw-btn blue-button hover:bg-blue-700 py-2 px-4 rounded-md h-10 ml-1 w-1/2 font-openSans-semiBold flex justify-center text-white text-sm items-center text-center"
        >
          <span>Withdraw</span>
        </button>
      </div>
    </div>
  </div>
</app-dialog>
