import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Web3Service } from './web3.service';
import { environment } from '../../environments/environment';

const baseUrl = environment.apiBaseUrl || '';

@Injectable()
export class WhitelistingService {
  constructor(private http: HttpClient, private w3Service: Web3Service) {}

  public async saveCustomer(
    name: string,
    surName: string,
    email: string,
    walletAddress: string
  ): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/customer/`;
      const param = {
        firstName: name,
        lastName: surName,
        email: email,
        walletAddress: walletAddress
      };
      return this.http
        .post(url, param)
        .toPromise()
        .then((data) => {
          return data;
        })
        .catch((e) => {
          return e.status;
        });
    } catch (err: any) {
      console.error(`Error message: ${err.message}`);
    }
    throw new Error('Unable to add customer');
  }
  public async getCustomer(walletAddress: string): Promise<any> {
    try {
      const url = `${baseUrl}/api/v1/customer/${walletAddress}`;
      return this.http.get(url).toPromise();
    } catch (err: any) {
      console.error(`Error message: ${err.message}`);
    }
    throw new Error('Unable to get customer');
  }
}
