import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  constructor() {}

  setItemToLocalStorage(key: string, value: any) {
    // if json, stringify it
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItemFromLocalStorage(key: string): any {
    // detect and parse json later
    return localStorage.getItem(key);
  }

  removeItemFromLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  clearToLocalStorage(): void {
    localStorage.clear();
  }

  setItemToSessionStorage(key: string, value: any) {
    // if json, stringify it
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItemFromSessionStorage(key: string): any {
    // detect and parse json later
    return sessionStorage.getItem(key);
  }

  removeItemFromSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

  clearToSessionStorage(): void {
    sessionStorage.clear();
  }
}
