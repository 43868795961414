<div [ngClass]="isDarkMode && !disableMode  ? 'dark' : ''">
  <section
    class="modal-section fixed z-40 top-0 left-0 w-full overflow-y-auto"
    [class.open]="display"
    (click)="close()"
  >
    <div
      class="modal-content flex align-items-center justify-content-center text-center bg-gray-900 bg-opacity-40 backdrop-blur-sm"
    >
      <div
        class="modal-content-detail dark:bg-1E1E1E inline-block align-bottom bg-white rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full sm:max-w-sm"
        [ngClass]="className"
        (click)="$event.stopPropagation()"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div
          class="dark:bg-1E1E1E modal-component-content"
          [ngClass]="isSelectOption ? 'select-option-modal' : 'default-modal'"
        >
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </section>
</div>
