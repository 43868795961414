import { Component, Input, OnInit } from '@angular/core';
import {
  OpportunityState,
  UtilsService
} from '../../../../services/utils.service';
import { PrivateSaleService } from '../../../../services/private-sale.service';
import { OpportunitiesHolder } from '@crowdswap/constant';
import { Router } from '@angular/router';
import { OpportunityService } from '../../../../services/opportunity.service';
import { filter } from 'rxjs/operators';
import { CurrentNetwork } from '../../../../model/current-network';
import { Subscription } from 'rxjs';
import { Web3Service } from '../../../../services/web3.service';
import { DetailsPrivateSaleComponent } from '../details-private-sale/details-private-sale.component';
import { InvestPrivateSaleComponent } from '../invest-private-sale/invest-private-sale.component';
import { environment } from '../../../../../environments/environment';

const baseUrl = environment.apiBaseUrl || '';

@Component({
  selector: 'app-banner-private-sale',
  templateUrl: './banner-private-sale.component.html',
  styleUrls: ['./banner-private-sale.component.scss']
})
export class BannerPrivateSaleComponent implements OnInit {
  public _presale;

  @Input()
  set presale(presale: any) {
    this._presale = presale;
  }

  get presale() {
    return this._presale;
  }
  public currentTime = 1;
  public startTime;
  public finishTime = 0; //it should be less than default currentTime
  public startTimeRemain: {
    minutesLeft: number;
    daysLeft: number;
    secondsLeft: number;
    hoursLeft: number;
  } = { daysLeft: 0, hoursLeft: 0, minutesLeft: 0, secondsLeft: 0 };

  public finishTimeRemain: {
    minutesLeft: number;
    daysLeft: number;
    secondsLeft: number;
    hoursLeft: number;
  } = { daysLeft: 0, hoursLeft: 0, minutesLeft: 0, secondsLeft: 0 };
  public poolDetail;
  public isMinimum;
  public stage: any[] = [];
  subscriptionList: Subscription[] = [];
  public baseUrl = baseUrl;

  constructor(
    private privateSaleService: PrivateSaleService,
    private router: Router,
    private opportunityService: OpportunityService,
    public web3Service: Web3Service
  ) {
    setInterval(() => {
      this.currentTime = Math.floor(Date.now() / 1000);
      this.checkPreSaleConditions();
    }, 1000);
  }

  async ngOnInit() {
    this.currentTime = Math.floor(Date.now() / 1000);
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          this.poolDetail = await this.privateSaleService.getPoolDetails(
            this._presale
          );
          this.isMinimum = await this.isMinimumPrice();
        })
    );
    this.poolDetail = await this.privateSaleService.getPoolDetails(
      this._presale
    );
    this.calculateStage();
  }

  public calculateStage() {
    for (let i = 0; i < this.poolDetail.priceStages.length; i++) {
      this.stage.push(
        this.poolDetail.investedAmount > this.poolDetail.priceStages[i][0]
          ? 100
          : this.poolDetail.investedAmount -
              (i === 0 ? 0 : this.poolDetail.priceStages[i - 1][0]) >
            0
          ? ((this.poolDetail.investedAmount -
              (i === 0 ? 0 : this.poolDetail.priceStages[i - 1][0])) /
              (this.poolDetail.priceStages[i][0] -
                (i === 0 ? 0 : this.poolDetail.priceStages[i - 1][0]))) *
            100
          : 0
      );
    }
  }

  private async checkPreSaleConditions() {
    if (this.poolDetail) {
      this.startTime = this.poolDetail.startTime;
      this.finishTime = this.poolDetail.finishTime;
      if (this.startTime > this.currentTime) {
        this.startTimeRemain = this.privateSaleService.getRemainingTime(
          this.startTime,
          this.currentTime
        );
        this.finishTimeRemain = this.privateSaleService.getRemainingTime(
          this.finishTime,
          this.currentTime
        );
      }
      if (
        this.startTime < this.currentTime &&
        this.finishTime > this.currentTime
      ) {
        this.startTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0
        };
        this.finishTimeRemain = UtilsService.getRemainingTime(
          this.finishTime,
          this.currentTime
        );
      }
      if (this.finishTime < this.currentTime) {
        this.startTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0
        };
        this.finishTimeRemain = {
          daysLeft: 0,
          hoursLeft: 0,
          minutesLeft: 0,
          secondsLeft: 0
        };
      }
    }
  }

  public async invest(opportunity) {
    InvestPrivateSaleComponent.activePresale = opportunity;
    this.opportunityService.setConsoleLog(
      opportunity.name,
      OpportunityState.Init,
      'pushed invest button'
    );
    await this.router.navigate([
      OpportunitiesHolder.OpportunitiesData.PRESALE.investPage
    ]);
  }

  private async isMinimumPrice() {
    if (
      this.currentTime - +this.poolDetail.startTime <
      +this.poolDetail.firstHoursBestPrice.toString() * 60 * 60
    ) {
      return true;
    }
    const userDetail = await this.privateSaleService.getUserDetails(
      this._presale
    );
    if (userDetail.state === 1) {
      return true;
    }
    return false;
  }

  async openDetail(presale) {
    DetailsPrivateSaleComponent.activePresale = presale;
    this.opportunityService.setConsoleLog(
      presale.name,
      OpportunityState.Init,
      'pushed details button'
    );
    await this.router.navigate([
      OpportunitiesHolder.OpportunitiesData.PRESALE.stakePage
    ]);
  }
}
