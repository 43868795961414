import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { asyncScheduler, Subject, Subscription } from 'rxjs';
import {
  Conversion,
  CrowdToken,
  MainNetworksById,
  Networks,
  OpportunitiesHolder,
  PriceService,
  TokensHolder
} from '@crowdswap/constant';
import { ModalService } from '../../../modal/modal.service';
import { Constants } from '../../../../constants';
import { filter, throttleTime } from 'rxjs/operators';

import { WaitingDialogComponent } from '../../../modal/dialogs/waiting-dialog/waiting-dialog.component';
import { BigNumber } from 'ethers';
import {
  CurrentNetwork,
  DialogDataModel,
  PendingTransactionModel
} from '../../../../model';
import { Router } from '@angular/router';
import {
  CrowdSwapService,
  LoggingService,
  OpportunityState,
  TagManagerService,
  ThemeService,
  TokensService,
  UtilsService,
  Web3Service
} from '../../../../services';
import { CrossChainSelectOptionDialogComponent } from '../../../modal/dialogs/cross-chain-select-option-dialog/cross-chain-select-option-dialog.component';
import { BaseEtfComponent } from '../base-etf.component';
import { EtfOpportunityState } from '../model/etf-opportunity-state.enum';
import { EtfOpportunitiesService } from '../../../../services/etf-opportunities.service';
import { NDDClientInfoServiceImpl } from 'src/app/services/client-info';
import { NumberType, formatNumber } from '@uniswap/conedison/format';
import { ConfirmEtfOpportunityDialogComponent } from '../../../modal/dialogs/confirm-etf-opportunity-dialog/confirm-etf-opportunity-dialog.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-etf-withdraw',
  templateUrl: './etf-withdraw.component.html',
  styleUrls: ['./etf-withdraw.component.scss']
})
export class EtfWithdrawComponent
  extends BaseEtfComponent
  implements OnInit, OnDestroy
{
  public EtfOpportunityState = EtfOpportunityState;
  public dialogOpened: boolean = false;
  public closedWaitingDialog: boolean = false;
  public subscriptionList: Subscription[] = [];
  public currentTokenList: CrowdToken[] = [];
  public opportunityTokenList: CrowdToken[] = [];
  public token!: any;
  public opportunityTx: any;
  private readonly hasSevenDecimalLength = /^\d+(\.\d{7,})$/;
  public slippage: string = CrowdSwapService.ETF_WITHDRAW_SLIPPAGE;
  public deadline: string = CrowdSwapService.DEADLINE;
  public loadingAmount: boolean = true;
  public disableConfirm: boolean = true;
  public currentChainId: number = Networks.POLYGON_MAINNET;
  public etfOpportunityState: EtfOpportunityState = EtfOpportunityState.Init;
  public selectedOpportunity;
  public detailsMsg: string = '';

  public originTxUrl: any = undefined;
  public claimTxUrl: any = undefined;
  public tooltip: boolean = false;
  public Networks = Networks;

  public percents = ['10', '25', '50', '75', '100'];

  public investedAmountUSD: string = '';

  _opportunity;

  @Output()
  close = new EventEmitter();

  @Input()
  set opportunity(opportunity: any) {
    this._opportunity = opportunity;
  }

  @Output()
  updateDetails = new EventEmitter();
  removePercentage: string = '100';
  selectedWithdrawToken: boolean = true;

  get opportunity() {
    return this._opportunity;
  }

  constructor(
    public web3Service: Web3Service,
    public ref: ChangeDetectorRef,
    protected themeService: ThemeService,
    private router: Router,
    private toastr: ToastrService,
    private priceService: PriceService,
    private waitingDialogService: ModalService<WaitingDialogComponent>,
    private confirmEtfOppDialogService: ModalService<ConfirmEtfOpportunityDialogComponent>,
    private crossChainSelectOptionDialogComponentModalService: ModalService<CrossChainSelectOptionDialogComponent>,
    private utilsService: UtilsService,
    public logger: LoggingService,
    private tokensService: TokensService,
    protected tagManagerService: TagManagerService,
    private etfOpportunitiesService: EtfOpportunitiesService,
    protected clientInfoServiceImpl: NDDClientInfoServiceImpl
  ) {
    super(
      web3Service,
      themeService,
      tagManagerService,
      logger,
      clientInfoServiceImpl
    );
  }

  async ngOnInit(): Promise<any> {
    super.ngOnInit();
    // this.tagManagerService.sendStartViewTag('ETF_opportunity_withdraw');
    this.selectedOpportunity = this._opportunity;
    this.etfOpportunityState = this.web3Service.isConnected()
      ? EtfOpportunityState.Init
      : EtfOpportunityState.WalletNotConnected;

    this.subscriptionList.push(
      this.web3Service.walletConnectionChangeSubject.subscribe(
        async (connection) => {
          this.etfOpportunityState = EtfOpportunityState.Init;
          if (!connection) {
            await this.router.navigate(['opportunity']);
            this.etfOpportunityState = EtfOpportunityState.WalletNotConnected;
          }
          this.ref.detectChanges();
        }
      )
    );
    this.subscriptionList.push(
      this.web3Service.currentNetworkChangeSubject
        .pipe(
          filter((currentNetwork: CurrentNetwork) => {
            return currentNetwork.chainId > 0;
          }),
          throttleTime(1500, asyncScheduler, { leading: false, trailing: true })
        )
        .subscribe(async (currentNetwork: CurrentNetwork) => {
          this.currentChainId = currentNetwork.chainId;
          this.isWrongNetwork = false;

          this.ref.detectChanges();
        })
    );
    this.subscriptionList.push(
      this.web3Service.wrongNetworkSubject.subscribe(
        async (isWrongNetwork: boolean) => {
          if (isWrongNetwork) {
            this.etfOpportunityState = EtfOpportunityState.WrongNetwork;
            this.isWrongNetwork = true;
            this.ref.detectChanges();
          }
        }
      )
    );
    this.subscriptionList.push(
      this.web3Service.accountChangeSubject.subscribe(async () => {
        await this.updateBalances();
      })
    );

    this.currentTokenList = this.tokensService.getAllTokens();
    this.initialTokensList();
    this.token =
      TokensHolder.ObservableTokenListBySymbol[
        Networks[this.selectedOpportunity.chainId]
      ][Constants.DEFAULT_PAIRS[this.selectedOpportunity.chainId].fromToken];
    await this.checkAllowance();
    await this.updateBalances();
    this.changeValue(100);
    this.ref.detectChanges();
  }

  getCorrectAmount(amount: number) {
    let result = amount;
    if (!result) {
      result = 0;
    }
    if (this.hasSevenDecimalLength.test(amount.toString())) {
      result = parseFloat(Conversion.adjustFraction(amount, 7));
    }
    return result.toString();
  }

  async updateBalances() {
    this.loadingAmount = true;
    this.currentTokenList = await this.addBalanceToTokens(
      this.currentTokenList
    );
    this.initialTokensList();

    this.disableConfirm = false;
    this.ref.detectChanges();
  }

  initialTokensList() {
    this.opportunityTokenList = this.currentTokenList.filter(
      (item) =>
        item.chainId === this.selectedOpportunity.chainId &&
        this.symbolsToInclude.includes(item.symbol!)
    );
  }

  async approve(): Promise<any> {
    try {
      if (this.currentChainId != this._opportunity.chainId) {
        await this.web3Service.changeNetwork(this._opportunity.chainId);
      }
      const token = OpportunitiesHolder.OpportunitiesData.ETF.etfList.BSC_ETF;

      await this.getApprove(token);
    } catch (error) {
      console.log(error);
    }
  }

  async getApprove(nftToken: any): Promise<any> {
    // this.tagManager(this.selectedOpportunity, this.token, 'withdraw_approve');

    let readyToApprove = true;
    try {
      // this.logger.info(
      //   `Start getting approve for token [${nftToken.symbol}], opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.ApprovalNeeded}]`
      // );
      const userAddress = this.web3Service.getWalletAddress();
      if (!userAddress) {
        return;
      }
      const data = {
        hasWaitingText: true,
        hasTransaction: true,
        text1: `Allowing CrowdSwap to use your CrowdETF`
      };

      await this.showWaitingDialog(data);
      // this.tagManager(
      //   this.selectedOpportunity,
      //   this.token,
      //   'withdraw_approve_waiting'
      // );
      // Get approval tx
      let approvalTx =
        await this.web3Service.getNFTApprovalTransactionBySpender(
          nftToken.contractAddress,
          nftToken.nftAddress
        );
      // Send approval tx
      approvalTx = await this.web3Service
        .sendTransaction(approvalTx)
        .catch((error) => {
          this.changeWithdrawState(EtfOpportunityState.ApprovalNeeded);
          this.showErrorToaster('Error', 'Approval rejected!');
          readyToApprove = false;
          this.setConsoleLog(
            this.selectedOpportunity,
            EtfOpportunityState.ApprovalNeeded,
            'Approval transaction is not submitted',
            error
          );
        });
      if (!approvalTx) {
        this.changeWithdrawState(EtfOpportunityState.ApprovalNeeded);
        this.showErrorToaster('Error', 'Something went wrong!');
        this.setConsoleLog(
          this.selectedOpportunity,
          EtfOpportunityState.ApprovalNeeded,
          'Approval transaction is empty'
        );
        return false;
      }
      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.ApprovalNeeded,
        'Approval transaction is submitted'
      );
      // Wait for approval tx confirmation
      await this.web3Service
        .waitForTransaction(approvalTx, 1)
        .then(async (data) => {
          if (data?.status === 1) {
            this.changeWithdrawState(EtfOpportunityState.ApprovalConfirmed);
            this.setConsoleLog(
              this.selectedOpportunity,
              EtfOpportunityState.ApprovalConfirmed,
              'Approval transaction is confirmed'
            );
            await this.checkAllowance();
          } else {
            this.showErrorToaster('Error', 'Something went wrong!');
            this.changeWithdrawState(EtfOpportunityState.ApprovalNeeded);
            this.setConsoleLog(
              this.selectedOpportunity,
              EtfOpportunityState.ApprovalNeeded,
              'Approval transaction is not confirmed'
            );
          }
        })
        .catch((error) => {
          this.showErrorToaster('Error', 'Something went wrong!');
          this.changeWithdrawState(EtfOpportunityState.ApprovalNeeded);
          readyToApprove = false;
          this.setConsoleLog(
            this.selectedOpportunity,
            EtfOpportunityState.ApprovalNeeded,
            'Approval transaction is not confirmed',
            error
          );
        });
    } catch (error) {
      this.showErrorToaster('Error', 'Something went wrong!');
      this.changeWithdrawState(EtfOpportunityState.ApprovalNeeded);
      readyToApprove = false;

      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.ApprovalNeeded,
        'Approval transaction has error',
        error
      );
    } finally {
      await this.waitingDialogService.close();
    }
    return readyToApprove;
  }
  private async checkAllowance(): Promise<any> {
    try {
      const userAddress = this.web3Service.getWalletAddress();
      if (userAddress) {
        let contractAddress = this._opportunity.contractAddress;
        let provider = this.web3Service.web3Provider;
        if (this.currentChainId !== this.token.chainId) {
          provider = this.web3Service.getNetworkProvider(this.token.chainId);
        }
        let allowance = await this.web3Service
          .getNFTAllowanceBySpender(
            contractAddress,
            this._opportunity.nftAddress,
            userAddress,
            provider
          )
          .catch((e) => {
            console.log(e);
            this.showErrorToaster('Error', 'Something went wrong!');
            this.changeWithdrawState(EtfOpportunityState.ApprovalRejected);
            return;
          });
        if (!allowance) {
          this.changeWithdrawState(EtfOpportunityState.ApprovalNeeded);
        }
      }
    } catch (e) {
      console.log(e);
      this.changeWithdrawState(EtfOpportunityState.ApprovalRejected);
    } finally {
      this.ref.detectChanges();
    }
  }

  public async confirm() {
    try {
      this.opportunityTx = undefined;
      this.changeWithdrawState(EtfOpportunityState.Init);
      this.logger.info(
        `Start getting confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.Init}]`
      );

      // ${
      //   this.selectedOpportunity.planName
      // }
      const text = `Withdrawing ${this.removePercentage}% of ${
        this.selectedOpportunity.planName
      } for ${
        this.selectedWithdrawToken ? this.token.symbol : 'Distribution tokens'
      }`;
      const data = {
        hasWaitingText: true,
        hasTransaction: true,
        text1: text
      };
      await this.showWaitingDialog(data);

      this.tagManager(
        'ETF_withdraw_start',
        this.removePercentage,
        this.investedAmountUSD
      );
      this.opportunityTx = this.selectedWithdrawToken
        ? await this.etfOpportunitiesService.withdrawWithSwap(
            this.walletAddress,
            this.selectedOpportunity,
            this.selectedOpportunity.tokenId,
            this.removePercentage,
            this.token,
            this.slippage
          )
        : await this.etfOpportunitiesService.withdrawWithoutSwap(
            this.walletAddress,
            this.selectedOpportunity,
            this.selectedOpportunity.tokenId,
            this.removePercentage
          );
      if (!this.opportunityTx) {
        this.showErrorToaster('Error', 'Something went wrong!');
        await this.waitingDialogService.close();
        this.setConsoleLog(
          this.selectedOpportunity,
          EtfOpportunityState.Init,
          'Confirmed transaction is empty'
        );
        return;
      }
      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.Init,
        'Confirmed transaction is received from backend'
      );

      if (this.closedWaitingDialog) {
        await this.waitingDialogService.close();
        return;
      }

      this.selectedWithdrawToken
        ? await this.confirmWithdraw(this.opportunityTx.transaction)
        : await this.confirmWithdraw(this.opportunityTx);
      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.Init,
        'Confirmed transaction has been sent to the provider'
      );

      this.ref.detectChanges();

      this.logger.info(
        `Finish getting confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.Init}]`
      );
    } catch (error) {
      await this.waitingDialogService.close();
      this.showErrorToaster('Error', 'Something went wrong!');
      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.Init,
        'Confirming transaction is failed',
        error
      );
    }
  }

  /**
   * On confirm button clicked.
   * The given transaction has been received from backend
   * @param opportunityTx
   */
  async confirmWithdraw(opportunityTx: any) {
    try {
      if (this.currentChainId != this.token.chainId) {
        await this.web3Service.changeNetwork(this.token.chainId);
      }
      this.logger.info(
        `Start getting final confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.Init}], transaction = [${opportunityTx}] `
      );

      this.detailsMsg = ` selectedToken: ${this.token.symbol}(${this.token.chainId})(${this.token.address})`;

      if (opportunityTx.value) {
        opportunityTx.value = BigNumber.from(opportunityTx.value);
      }

      // Send transaction to the MM
      const oppTx = {
        from: this.walletAddress,
        to: opportunityTx.to,
        data: opportunityTx.data,
        value: opportunityTx.value,
        gas: BigNumber.from(opportunityTx.gasLimit).toString(),
        gasLimit: BigNumber.from(opportunityTx.gasLimit).toString()
      };

      this.tagManager(
        'ETF_withdraw_confirmation_view',
        this.removePercentage,
        this.investedAmountUSD
      );

      let currentTransaction: any;
      currentTransaction = await this.web3Service
        .sendTransaction(oppTx)
        .then(async (data) => {
          this.changeWithdrawState(EtfOpportunityState.Confirmed);
          this.setConsoleLog(
            this.selectedOpportunity,
            EtfOpportunityState.Confirmed,
            'Opportunity submitted'
          );
          this.ref.detectChanges();
          return data;
        })
        .catch(async (error) => {
          await this.waitingDialogService.close();
          this.showErrorToaster('Error', 'Opportunity rejected!');
          this.changeWithdrawState(EtfOpportunityState.Rejected);
          this.tagManager(
            'ETF_withdraw_confirmation_reject',
            this.removePercentage,
            this.investedAmountUSD,
            ''
          );
          this.setConsoleLog(
            this.selectedOpportunity,
            EtfOpportunityState.Rejected,
            'Opportunity rejected!' + this.detailsMsg,
            error
          );
        });

      // If the transaction is confirmed by user
      if (currentTransaction) {
        this.tagManager(
          'ETF_withdraw_confirmation_confirm',
          this.removePercentage,
          this.investedAmountUSD,
          ''
        );
        // Wait for swap tx confirmation on blockchain
        await this.web3Service
          .waitForTransaction(currentTransaction, 1)
          .then(async (data) => {
            if (data?.status === 1) {
              await this.waitingDialogService.close();
              this.tagManager(
                'ETF_withdraw_done',
                this.removePercentage,
                this.investedAmountUSD,
                '',
                'Success.'
              );
              this.close.emit();
              {
                this.setConsoleLog(
                  this.selectedOpportunity,
                  EtfOpportunityState.Successful,
                  'Opportunity successful.' + this.detailsMsg
                );
                this.changeWithdrawState(EtfOpportunityState.Successful);
              }
            } else {
              this.changeWithdrawState(EtfOpportunityState.Failed);
              this.tagManager(
                'ETF_withdraw_error',
                this.removePercentage,
                this.investedAmountUSD,
                '',
                'Something went wrong.'
              );
              this.setConsoleLog(
                this.selectedOpportunity,
                EtfOpportunityState.Failed,
                'Opportunity failed.' + this.detailsMsg
              );
            }
            try {
              this.updateOppDetails();
              this.showingToastr(currentTransaction);
            } catch (error) {
              console.log(
                this.selectedOpportunity,
                this.etfOpportunityState,
                'Failed to show toaster or push tag' + this.detailsMsg,
                error
              );
            }
            return data;
          })
          .catch(async (error) => {
            this.changeWithdrawState(EtfOpportunityState.Failed);
            this.tagManager(
              'ETF_withdraw_error',
              this.removePercentage,
              this.investedAmountUSD,
              '',
              'Something went wrong.'
            );
            console.log(
              this.selectedOpportunity,
              EtfOpportunityState.Failed,
              'Opportunity failed in internal catch.' + this.detailsMsg,
              error
            );
          });
      }
      this.logger.info(
        `Finish getting final confirm opportunity name = [${this.selectedOpportunity.name}], opportunity state = [${OpportunityState.Init}]`
      );
    } catch (error) {
      await this.waitingDialogService.close();
      this.tagManager(
        'ETF_withdraw_error',
        this.removePercentage,
        this.investedAmountUSD,
        '',
        'Something went wrong.'
      );
      this.setConsoleLog(
        this.selectedOpportunity,
        EtfOpportunityState.Failed,
        'Opportunity failed in external catch.' + this.detailsMsg,
        error
      );
    }
  }

  private async showingToastr(currentTransaction: any) {
    const scanTransactionUrl =
      this.web3Service.getScanTransactionUrl(currentTransaction);
    switch (this.etfOpportunityState) {
      case EtfOpportunityState.Successful: {
        this.toastr.success(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          `Withdraw of ${this.removePercentage}% successfully executed!`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'successClass'
          }
        );
        this.web3Service.assetChangeSubject.next(true);
        break;
      }
      case EtfOpportunityState.Failed: {
        this.toastr.error(
          `<a href='${scanTransactionUrl}' target='_blank'>View in explorer</a>`,
          `Transaction(s) failed`,
          {
            closeButton: true,
            tapToDismiss: false,
            progressBar: true,
            positionClass: 'custom-toast-top-right',
            enableHtml: true,
            timeOut: 10000,
            messageClass: 'failedClass'
          }
        );
        break;
      }
    }
  }

  private changeWithdrawState(opportunityState: EtfOpportunityState) {
    this.etfOpportunityState = opportunityState;
  }

  private async showWaitingDialog(data) {
    this.closedWaitingDialog = false;
    let waitingDialogRef = await this.waitingDialogService.open(
      WaitingDialogComponent,
      data
    );
    const sub: Subscription = (<ConfirmEtfOpportunityDialogComponent>(
      (<any>waitingDialogRef!.instance)
    )).closed.subscribe(async () => {
      this.closedWaitingDialog = true;
    });
    waitingDialogRef!.onDestroy(async () => {
      this.closedWaitingDialog = true;
      sub.unsubscribe();
    });
    this.ref.detectChanges();
  }

  private showErrorToaster(title, message?) {
    this.toastr.error(message, title, {
      closeButton: true,
      tapToDismiss: false,
      progressBar: true,
      positionClass: 'custom-toast-top-right',
      timeOut: 10000,
      messageClass: 'errorClass'
    });
  }

  async openSelectOptionDialog(allTokens) {
    if (this.dialogOpened) {
      return;
    }
    this.loadingAmount = true;
    this.dialogOpened = true;
    const data: DialogDataModel = {
      filteredTokenList: allTokens,
      toggle: true,
      chainId: -1,
      className: 'choose-asset-component',
      dontShowAllNetworks: true
    };

    let selectOptionDialogRef =
      await this.crossChainSelectOptionDialogComponentModalService.open(
        CrossChainSelectOptionDialogComponent,
        data
      );
    const sub: Subscription = (<CrossChainSelectOptionDialogComponent>(
      (<any>selectOptionDialogRef!.instance)
    )).confirmed.subscribe(async (token) => {
      if (token) {
        this.token = <any>token;

        this.token.price = await this.priceService.getPrice(
          this.token,
          this.web3Service.getNetworkProvider(this.token.chainId)
        );
      } else {
        this.loadingAmount = false;
      }
      this.dialogOpened = false;
      await this.crossChainSelectOptionDialogComponentModalService.close();
    });
    const subscribeImportNewToken: Subscription = (<
      CrossChainSelectOptionDialogComponent
    >(<any>selectOptionDialogRef!.instance)).confirmedImportToken.subscribe(
      async (token: CrowdToken) => {
        this.currentTokenList.push(token);
      }
    );

    selectOptionDialogRef!.onDestroy(() => {
      this.dialogOpened = false;
      this.loadingAmount = false;
      sub.unsubscribe();
      subscribeImportNewToken.unsubscribe();
    });
  }

  public toFixFraction(num: number | string | undefined, fraction: number = 7) {
    return num ? Conversion.adjustFraction(num, 7) : '0';
  }

  updateOppDetails() {
    this.updateDetails.emit(false);
  }

  public async addBalanceToTokens(tokens: any, withBalance: boolean = false) {
    const promises: any[] = [];
    let result: any[] = [];

    for (const chainId in MainNetworksById) {
      if (environment.ACTIVE_NETWORK.includes(chainId)) {
        let tempTokens = tokens.filter(
          (token: CrowdToken) => token.chainId.toString() === chainId
        );
        promises.push(this.web3Service.addBalanceToTokens(tempTokens));
      }
    }

    let promiseResult = await Promise.allSettled(promises);
    promiseResult.forEach((item, index, array) => {
      if (item && item.status === 'fulfilled' && item.value.length > 0) {
        result.push(...item.value);
      }
    });

    if (withBalance) {
      result = result.filter((token: CrowdToken) => {
        return token.balance && token.balance != '' && +token.balance > 0;
      });
      result = result.filter((token) =>
        result.some(({ symbol }) => token === symbol)
      );
    }
    return result;
  }

  public changeValue(percentage) {
    const amount =
      (parseFloat(this._opportunity.totalInvestedAmountUSD) *
        parseFloat(percentage)) /
      100;
    this.investedAmountUSD = formatNumber(amount, NumberType.FiatTokenPrice);
    this.removePercentage = percentage;
    this.loadingAmount = false;
    this.setRemovablePercentAmount(percentage);
  }

  private setRemovablePercentAmount(percentage) {
    let investedTokens: any[] = [];
    for (let item of this._opportunity.tokenShares) {
      const amount = (parseFloat(item.amount) * parseFloat(percentage)) / 100;
      item.amountPercentToDisplay = formatNumber(amount, NumberType.TokenTx);
    }
    return investedTokens;
  }

  public async tagManager(
    event: any,
    tokenAmount: string = '',
    amountInUsd: string = '',
    crowdswapFee: string = '',
    status: string = ''
  ) {
    await this.tagManagerService.sendEtfOpportunityTags(
      event,
      this.selectedOpportunity,
      'etf_withdraw',
      this.selectedWithdrawToken ? this.token : '',
      tokenAmount,
      amountInUsd,
      crowdswapFee,
      status
    );
  }
}
