import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoggingService } from '../log/service/logging.service';

const baseUrl = environment.AFFILIATE_BASEURL || '';

@Injectable()
export class AuthenticationService {
  public static readonly AUTH_COOKIE_KEY = 'Authorization';

  constructor(private http: HttpClient, private logger: LoggingService) {}

  public async login(
    chainId: number,
    walletAddress: string,
    signature: string,
    isTermsOfServiceSigned: boolean
  ): Promise<any> {
    try {
      this.logger.debug(`Start login the user wallet address ${walletAddress}`);
      const url = `${baseUrl}/api/v1/auth/login/${
        isTermsOfServiceSigned
          ? 'signingTermsOfService'
          : 'signingSimpleMessage'
      }?chainId=${chainId}`;
      return this.http
        .post(url, { address: walletAddress, signature: signature })
        .toPromise();
    } catch (err) {
      console.error(`Error message: ${err}`);
    }
    throw new Error(`Unable to login the user wallet address ${walletAddress}`);
  }
}
