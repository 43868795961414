<div class="bg-white rounded-md p-4 pt-0 pt-sm-4 dark:bg-121212 dark:border-1E1E1E dark:border-2">
  <div *ngIf="!errorMessage">
    <div
      id="divEstimationItemContainer"
      *ngFor="let estimationItem of estimations; index as index"
    >
      <ng-container *ngIf="index < 3">
        <app-estimation-item
          (onEstimationSelected)="onEstimationSelected($event)"
          [bestPrice]="index === 0"
          [compareWithEstimation]="compareWithEstimation"
          [estimation]="estimationItem"
          [selectedEstimation]="selectedEstimation"
        >
        </app-estimation-item>
      </ng-container>
      <ng-container *ngIf="index >= 3 && isViewMore">
        <app-estimation-item
          (onEstimationSelected)="onEstimationSelected($event)"
          [bestPrice]="index === 0"
          [compareWithEstimation]="compareWithEstimation"
          [estimation]="estimationItem"
          [selectedEstimation]="selectedEstimation"
        >
        </app-estimation-item>
      </ng-container>
    </div>
    <div
      id="divToggleViewMoreEstimation"
      (click)="toggleViewMore()"
      *ngIf="estimations.length > 3"
      class="view-more border border-2 border-gray-200 dark:border-0 dark:bg-1E1E1E"
    >
      <div class="row">
        <div class="col-10 col-sm-11 col-md-11 col-xl-11 col-xxl-11">
          <div
            id="divViewMoreEstimation"
            class="font-openSans-medium text-sm text-gray-500"
            *ngIf="!isViewMore"
          >
            View more ({{ estimations.length - 3 }})
          </div>
          <div
            id="divViewLessEstimation"
            class="font-openSans-medium text-sm text-gray-500"
            *ngIf="isViewMore"
          >
            View less
          </div>
        </div>
        <div
          class="col-2 col-sm-1 col-md-1 col-xl-1 col-xxl-1 flex items-center"
        >
          <svg
            class="h-5 w-5 text-gray-300"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 9l-7 7-7-7"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              *ngIf="!isViewMore"
            />
            <path
              _ngcontent-kuh-c63=""
              d="M5 15l7-7 7 7"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              *ngIf="isViewMore"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div id="divError" *ngIf="errorMessage" class="alert-error">
    <div id="divErrorIcon" class="icon">
      <img id="imgError" alt="alert error" src="assets/media/icons/error.png" />
    </div>
    <div id="divErrorMessage" class="title text-gray-600 dark:text-gray-400">
      {{ errorMessage }}
    </div>
    <div *ngIf="isNoPairFound" class="note text-gray-400 dark:text-gray-600">
      Please check your internet connection and try again. If the issue persists, you can also perform a clean reload of this page by pressing Ctrl+F5
    </div>
    <div *ngIf="isNoPairFound" class="btn" (click)="tryAgain()">Try again</div>
  </div>
</div>
